d3 = require("d3")
_  = require("lodash")

module.exports = class @Heat
	HEAT_COLORS  = ["heat-map-block-1","heat-map-block-2","heat-map-block-3","heat-map-block-4","heat-map-block-5","heat-map-block-6","heat-map-block-7"]

	BLOCK_WIDTH  = 50
	BLOCK_HEIGHT = 10
	DURATION     = 1000
	LEGEND_SPACE = 25


	constructor: () ->
		@g     = d3.select("#heatmap_legend")
		@scale = d3.scale.threshold().range( HEAT_COLORS )
		@events = {}
		@set_range(-100,100)
		@kpi = "score"

	get_colors:() => HEAT_COLORS

	relocate:(height) =>
		@g.attr("transform", "translate(20,#{height})")

	reset_active:(@active) =>

	reset_kpi:(@kpi) =>

	get_legend_title:() =>
		if @is_velocity_kpi() then "Velocity color scale" else "Audience Skew"

	get_kpi: () => @kpi || "score"

	is_velocity_kpi: () => @get_kpi() == "score"

	get_kpi_class: () => @get_kpi() + "-overlay"

	set_active:(@active) =>
		@redraw()

	set_range:( min,max, scores ) =>
		domain = if @is_velocity_kpi() then [-25,-10, 0,10,25,100] else [0.05, 0.25, 0.5, 0.75, 0.95, 1]
		if scores && scores.length > 8
			sorted = scores.sort((a,b)-> (+a) - (+b))
			# console.log("sorted: " + sorted)
			funcBig = (x) -> _.filter(sorted, (n) -> n >= x)
			funcSmall = (x) -> _.filter(sorted, (n) -> n < x)
			high = if @is_velocity_kpi() then funcBig(0) else funcBig(1)
			low  = if @is_velocity_kpi() then funcSmall(0) else funcSmall(1)
			d    = [-2,-1,0,1,2,3]
			chunkedHigh = _.chunk( high, high.length / 4 ).map((x)-> x[0]).slice(1,4)
			chunkedLow  = _.chunk( low, low.length / 3 ).map((x)-> x[0]).slice(1,3)
			# handle missing values
			if !(@is_velocity_kpi())
				if chunkedLow.length == 0
					chunkedLow = [0.1,0.2]
				if chunkedLow.length == 1
					chunkedLow = chunkedLow.concat([chunkedLow[0] + 0.1])
				if chunkedLow[0] == 0
					if chunkedLow[1] == 0
						chunkedLow = [0.1,0.2]
					else
						chunkedLow = [0.1, chunkedLow[1]]

			if chunkedLow.length == 0
				chunkedLow = [-2,-1]
			if chunkedLow.length == 1
				chunkedLow = chunkedLow.concat([chunkedLow[0] + 1])
			if chunkedLow[0] == -100
				if chunkedLow[1] == -100 || chunkedLow[1] == -1
					chunkedLow = [-2,-1]
				else
					chunkedLow = [chunkedLow[1],-1]

			if chunkedHigh.length == 0
				chunkedHigh = [1,2,3]
			if chunkedHigh.length == 1
				chunkedHigh = chunkedHigh.concat([chunkedHigh[0] + 1, chunkedHigh[0] + 2])
			if chunkedHigh.length == 2
				chunkedHigh = chunkedHigh.concat([chunkedHigh[1] + 1])

			domain = if @is_velocity_kpi() then chunkedLow.concat([0]).concat( chunkedHigh ) else chunkedLow.concat([1]).concat( chunkedHigh )

		@scale.domain( domain )
		@redraw()
		(@events["orbis.heat.changed"] || []).forEach((listener)->listener())

	addEventListener: (event_type,listener) =>
		@events[event_type] = [listener].concat(@events[event_type] || [])


	redraw: () =>
		colors = if @active then HEAT_COLORS else []
		scldom  = @scale.domain()
		numbers = if @active && @is_velocity_kpi() then [-100].concat(scldom) else if @active && !(@is_velocity_kpi()) then [0].concat(scldom) else []
		title = if @active then [@get_legend_title()] else []

		if @active
			@g.selectAll("#heatmap_legend > rect").data([]).exit().remove()
			@g.selectAll(".legend-number").data([]).exit().remove()
			@g.selectAll(".legend-title").data([]).exit().remove()
		else
			@g.selectAll("#heatmap_legend > rect").data(HEAT_COLORS).enter().append("rect")
			@g.selectAll(".legend-number").data(numbers).enter().insert("text", ".legend-block")
			@g.selectAll(".legend-title").data(title).enter().insert("text", ".legend-title")

		blocks = @g.selectAll("#heatmap_legend > rect").data( colors )

		# blocks
		blocks.enter()
			.append("rect")
			.attr("class", (d)->d)
			.attr("height", BLOCK_HEIGHT )
			.attr("y", -LEGEND_SPACE)
			.attr("x", 0 )
			.attr("width", 0)
			.transition().duration(DURATION)
			.attr("width", BLOCK_WIDTH - 1)
			.attr("x", (d,i)-> BLOCK_WIDTH * i )

		blocks.exit()
			.transition()
			.delay(DURATION)
			.duration(DURATION)
			.attr("x", 0)
			.attr("width", 0)
			.remove()

		# numbers
		legends = @g.selectAll(".legend-number").data( numbers )

		legends.enter()
			.insert("text", ".legend-block")
			.attr("text-anchor", "middle")
			.attr("class", "legend-number")
			.attr("y", -LEGEND_SPACE )
			.attr("x", 0 )
			.attr("width", 0)
			.attr("dy", BLOCK_HEIGHT )
			.transition().duration(DURATION)
			.attr("width", BLOCK_WIDTH - 1)
			.attr("x", (d,i)-> BLOCK_WIDTH * i + BLOCK_WIDTH/2 )
			.transition().duration(DURATION)
			.attr("dy", LEGEND_SPACE )

		legends.exit()
			.transition().duration(DURATION)
			.attr("dy", BLOCK_HEIGHT )
			.remove()

		# legends.text( (d) -> d3.format("f")(d) + "%" )
		if @is_velocity_kpi() then legends.text( (d) -> d3.format("f")(d) + "%" ) else legends.text((d) -> d.toFixed(2))

		# title

		the_title = @g.selectAll(".legend-title").data(title)

		the_title.enter()
			.insert("text", ".legend-title")
			.text((d)->d)
			.attr("text-anchor", "start")
			.attr("class", "legend-title")
			.attr("y", -LEGEND_SPACE )
			.attr("x", 0 )
			.style("opacity", 0)
			.attr("dy", BLOCK_HEIGHT )
			.transition().delay(DURATION).duration(DURATION)
			.attr("dy", -10 )
			.style("opacity", 1)

		the_title.exit()
			.transition().duration(DURATION)
			.attr("dy", BLOCK_HEIGHT )
			.style("opacity", 0)
			.remove()
