import React, { useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { compact, every, find, flatten, isEmpty, map, omitBy, reject } from 'lodash';
import ModalWithConfirmationButtons from '../../../../../common/Modal/ModalWithConfirmationButtons/ModalWithConfirmationButtons';
import WindowEventListener from '../../../../../common/WindowEventListener/WindowEventListener';
import Dropdown from '../../../../../common/Dropdown/Dropdown/Dropdown';
import DropdownWithHeader from '../../../../../common/Dropdown/DropdownWithHeader/DropdownWithHeader';
import TvCustomTimeframe from '../../TvCustomTimeframe/TvCustomTimeframe';
import Separator from '../../../../../common/Separator/Separator';
import ClickableIcon from '../../../../../common/ClickableIcon/ClickableIcon';
import MultiLevelTableSelector from '../../../../../common/MultiLevelTableSelector/MultiLevelTableSelector';
import MultiLevelResultsTable from '../../../../../common/MultiLevelResultsTable/MultiLevelResultsTable';
import ResultsTable from '../../../../../common/ResultsTable/ResultsTable';
import SelectToggle from '../../../../../common/SelectToggle/SelectToggle';
import SingleSelectToggle from '../../../../../common/SingleSelectToggle/SingleSelectToggle';
import Spinner from '../../../../../common/Spinner/Spinner';
import {
  LEVELS_OF_EXPOSURE,
  LEVEL_OF_EXPOSURE_TV_SHOWS_TOOLTIP,
  VIEWING_TYPES,
  VIEWERSHIP_MODES,
  VIEWING_EVENT_QUALIFIERS,
  dateToString,
  getSummaryTextBuilder,
  CHANNELS,
  VIEWING_TYPES_MAP,
  COUNTRIES,
} from '../../../../../../../data/audience-segment-builder-helper';
import { pickSelectedFromTree, linkParents } from '../../../../../../utils/MultiLevelTableUtils';
import {
  getAdvancedTvShowsMetaData,
  getAdvancedTvNetworksMetaData,
} from '../../../../../../services/AudienceInsightsService';

import './AdvancedTvShowsModal.scss';
import { isCanceledRequestError } from '../../../../../../infra/HttpClient';

const AdvancedTvShowsModal = ({
  isOpen,
  modalTitle,
  onSubmit,
  onCancel,
  tvShowsInput,
  tvGenresMetadataPromise,
  channel,
  geo,
}) => {
  const FULL_SCREEN_MAX_HEIGHT = '600px';
  const SMALL_SCREEN_MAX_HEIGHT = '515px';
  const MIN_VIEWED_PROGRAMS = 1;
  const MAX_VIEWED_PROGRAMS = 1000;
  const DEFAULT_VIEWING_TYPE_BY_CHANNEL = {
    tivo: { default: VIEWING_TYPES_MAP.linear?.value },
    peerlogix: { default: VIEWING_TYPES_MAP.streaming?.value },
    hisense: { default: VIEWING_TYPES_MAP.both?.value, au: VIEWING_TYPES_MAP.linear?.value },
  };
  const viewingTypeTooltipMessage = 'Currently streaming data does not have tv shows';
  const HISENSE_AU_VIEWING_TYPES = [
    { ...VIEWING_TYPES_MAP.both, disabled: true, tooltip: viewingTypeTooltipMessage },
    { ...VIEWING_TYPES_MAP.streaming, disabled: true, tooltip: viewingTypeTooltipMessage },
    { ...VIEWING_TYPES_MAP.linear, disabled: false },
  ];
  const geoParam = CHANNELS[channel]?.isGeoMandatory ? geo : '';
  const isHisenseAU = channel === CHANNELS.hisense?.name && geoParam === COUNTRIES.AU?.cc?.toLowerCase();
  const [isLoadingTvMetadata, setIsLoadingTvMetadata] = useState(true);
  const [viewingType, setViewingType] = useState(
    tvShowsInput.viewingType ||
      DEFAULT_VIEWING_TYPE_BY_CHANNEL[channel][geoParam] ||
      DEFAULT_VIEWING_TYPE_BY_CHANNEL[channel].default ||
      'both'
  );
  const [selectedNetworks, setSelectedNetworks] = useState(tvShowsInput.networks || []);
  const [tvShowsTableDataTree, setTvShowsTableDataTree] = useState([]);
  const [selectedTvShows, setSelectedTvShows] = useState([]);
  const [isTvShowsReset, setIsTvShowsReset] = useState(false);
  const [modalMaxHeight, setModalMaxHeight] = useState(FULL_SCREEN_MAX_HEIGHT);
  const [selectedGenres, setSelectedGenres] = useState(tvShowsInput.genres || []);
  const [selectedStartDate, setSelectedStartDate] = useState(tvShowsInput.startDate);
  const [selectedEndDate, setSelectedEndDate] = useState(tvShowsInput.endDate);
  const [selectedTimeframe, setSelectedTimeframe] = useState(tvShowsInput.timeframe);
  const [isTimeframeDisabled, setIsTimeframeDisabled] = useState(
    (isEmpty(tvShowsInput.networks) &&
      isEmpty(tvShowsInput.tv) &&
      isEmpty(tvShowsInput.episodes) &&
      isEmpty(tvShowsInput.genres)) ||
      isHisenseAU
  );
  const [levelOfExposure, setLevelOfExposure] = useState(tvShowsInput.exposure);
  const [viewershipModes, setViewershipModes] = useState(tvShowsInput.viewModes || []);
  const [viewEventQualifier, setViewEventQualifier] = useState(
    tvShowsInput.viewEventQual || channel === CHANNELS.hisense?.name
      ? VIEWING_EVENT_QUALIFIERS[0]
      : VIEWING_EVENT_QUALIFIERS[1]
  );
  const [minNumberOfViewedProgs, setMinNumberOfViewedProgs] = useState(tvShowsInput.minViewedProgs || '');

  const tvNetworksMetadataPromise = useMemo(
    channel && viewingType
      ? () => getAdvancedTvNetworksMetaData(`${channel}_${viewingType}`, geoParam)
      : Promise.resolve([]),
    [channel, viewingType, geoParam]
  );

  const setModalMaxHeights = () => {
    setModalMaxHeight(window.innerHeight > 900 ? FULL_SCREEN_MAX_HEIGHT : SMALL_SCREEN_MAX_HEIGHT);
  };

  useEffect(() => {
    setModalMaxHeights();
  });

  const DATA_LEVELS = ['tv', 'seasons', 'episodes'];
  const LEVEL_NAMES = ['TV Shows', 'Seasons', 'Episodes'];

  const NUM_PROGS_PATTERN = '([0-9]{0,3})|(' + MAX_VIEWED_PROGRAMS + ')';

  const populateDataTree = (tvShowsMetadata, isReset = false) => {
    const tvShowsTree = [];
    if (tvShowsMetadata?.length) {
      tvShowsMetadata.map((showMetadata) => {
        const show = {
          value: showMetadata.id,
          label: showMetadata.title,
          is_movie: showMetadata.is_movie,
          selected:
            ((!!find(tvShowsInput.tv, { value: showMetadata.id }) && !isTvShowsReset) ||
              !!find(selectedTvShows, { value: showMetadata.id })) &&
            !isReset,
        };
        if (showMetadata.seasons?.length) {
          show.children = showMetadata.seasons.map((seasonMetadata) => {
            const season = {
              value: `${showMetadata.id}-${seasonMetadata.id}`,
              label: seasonMetadata.title,
              parentId: showMetadata.id,
              isSeason: seasonMetadata.isSeason,
              selected:
                (show.selected ||
                  (!!find(tvShowsInput.seasons, { value: seasonMetadata.id }) && !isTvShowsReset) ||
                  !!find(selectedTvShows.seasons, { value: seasonMetadata.id })) &&
                !isReset,
            };
            if (seasonMetadata.episodes?.length) {
              season.children = seasonMetadata.episodes.map((episodeMetadata) => ({
                value: episodeMetadata.id,
                label: episodeMetadata.title,
                parentId: season.value,
                selected:
                  (season.selected ||
                    (!!find(tvShowsInput.episodes, { value: episodeMetadata.id }) && !isTvShowsReset) ||
                    !!find(selectedTvShows.episodes, { value: episodeMetadata.id })) &&
                  !isReset,
              }));
              season.selected = (season.selected || every(season.children, 'selected')) && !isReset;
            }
            return season;
          });
          show.selected = (show.selected || every(show.children, 'selected')) && !isReset;
        }
        tvShowsTree.push(show);
      });
    }
    tvShowsTree.forEach((element) => linkParents(element));
    setTvShowsTableDataTree(tvShowsTree);
  };

  const fetchTvSegmentMetadata = async (isReset = false) => {
    setIsLoadingTvMetadata(true);
    try {
      const resTvShows = await getAdvancedTvShowsMetaData(
        `${channel}_${viewingType}`,
        map(selectedNetworks, 'value'),
        map(selectedGenres, 'value'),
        geoParam
      );
      populateDataTree(resTvShows, isReset);
      setIsLoadingTvMetadata(false);
    } catch (error) {
      if (isCanceledRequestError(error)) return; // request has been canceled due to a new request
      throw error;
    }
  };

  useEffect(() => {
    fetchTvSegmentMetadata(); //TODO - refactor this into an api to be submitted to the selection tree
  }, [selectedNetworks, selectedGenres, viewingType]);

  const resetAllSelected = () => {
    setSelectedNetworks([]);
    setSelectedGenres([]);
    setTvShowsTableDataTree([]);
    setSelectedTvShows([]);
    setIsTvShowsReset(true);
    setIsTimeframeDisabled(true);
  };

  const onChangeViewingType = (type) => {
    resetAllSelected();
    setViewingType(type);
  };

  const onResetSelectedTvShows = () => {
    setSelectedTvShows([]);
    setIsTvShowsReset(true);
    fetchTvSegmentMetadata(true);
  };

  const changeIsTimeframeDisabled = ({ networks = selectedNetworks, genres = selectedGenres, shows }) => {
    const showsIsEmpty = !shows || Object.values(shows).every((array) => array.length === 0);
    const isTimeframeDisabledCurrent = (isEmpty(networks) && isEmpty(genres) && showsIsEmpty) || isHisenseAU;
    if (isTimeframeDisabledCurrent !== isTimeframeDisabled) setIsTimeframeDisabled(isTimeframeDisabledCurrent);
  };

  const onNetworksSelect = (networks) => {
    setSelectedNetworks(networks);
    changeIsTimeframeDisabled({ networks });
  };

  const onGenresSelect = (genres) => {
    setSelectedGenres(genres);
    changeIsTimeframeDisabled({ genres });
  };

  const onShowsDataTableSelect = (data, selectedShow) => {
    updateSelectedTvShows(selectedShow);
    setTvShowsTableDataTree([...data]);
    changeIsTimeframeDisabled({ shows: pickSelectedFromTree(data, DATA_LEVELS) });
  };

  const updateSelectedTvShows = (selectedShow) => {
    const selectedShowToUpdateIndex = selectedTvShows.findIndex((show) => selectedShow.value === show.value);
    let updatedTvShowsArr = [...selectedTvShows];
    if (selectedShowToUpdateIndex !== -1) {
      updatedTvShowsArr[selectedShowToUpdateIndex] = { ...selectedShow };
    } else {
      updatedTvShowsArr = [...selectedTvShows, selectedShow];
    }
    setSelectedTvShows(updatedTvShowsArr);
  };

  const resultsTable = useMemo(
    () =>
      compact([
        !isEmpty(selectedNetworks) && {
          type: 'networks',
          title: 'Networks',
          results: selectedNetworks,
        },
        !isEmpty(selectedGenres) && {
          type: 'genres',
          title: 'Genres',
          results: selectedGenres,
        },
      ]),
    [selectedNetworks, selectedGenres]
  );

  const onRemoveResultsTable = (item, type) => {
    const resultsWrapper = find(resultsTable, { type });
    const selectedResults = reject(resultsWrapper.results, { value: item.value });
    switch (type) {
      case 'networks':
        setSelectedNetworks(selectedResults);
        break;
      case 'genres':
        setSelectedGenres(selectedResults);
        break;
    }
  };

  const onResetResultsTable = () => {
    tvShowsInput.episodes = [];
    tvShowsInput.tv = [];
    resetAllSelected();
  };

  const onMinNumProgsChange = ({ target: { value, validity } }) => {
    if (validity?.valid && (value === '' || (value >= MIN_VIEWED_PROGRAMS && value <= MAX_VIEWED_PROGRAMS))) {
      setMinNumberOfViewedProgs(value);
    }
  };

  const handleSubmit = useCallback(() => {
    let { tv, seasons, episodes } = pickSelectedFromTree(tvShowsTableDataTree, DATA_LEVELS);
    episodes = [...episodes, ...flatten(map(seasons, 'children'))];
    const segment = {
      ...tvShowsInput,
      type: 'advancedTvShows',
      genres: selectedGenres,
      networks: selectedNetworks,
      tv,
      episodes,
      ...(selectedStartDate && !isTimeframeDisabled && { startDate: dateToString(selectedStartDate) }),
      ...(selectedEndDate && !isTimeframeDisabled && { endDate: dateToString(selectedEndDate) }),
      ...(selectedTimeframe && { timeframe: selectedTimeframe }),
      exposure: levelOfExposure,
      viewershipModes,
      viewEventQual: viewEventQualifier,
      minViewedProgs: minNumberOfViewedProgs,
      viewingType,
    };
    if (!selectedTimeframe) {
      delete segment.timeframe;
    }
    const segmentWithoutEmptyTvFilters = omitBy(
      segment,
      (val, key) => ['networks', 'genres', 'tv', 'episodes'].includes(key) && isEmpty(val)
    );
    onSubmit(segmentWithoutEmptyTvFilters);
  }, [
    tvShowsInput,
    tvShowsTableDataTree,
    selectedGenres,
    selectedNetworks,
    selectedStartDate,
    selectedEndDate,
    selectedTimeframe,
    levelOfExposure,
    viewershipModes,
    viewEventQualifier,
    minNumberOfViewedProgs,
    viewingType,
  ]);

  const handleCancel = () => onCancel('advancedTvShows');

  const isSubmitDisabled = useMemo(() => {
    if (isLoadingTvMetadata) return true;
    let { tv, seasons, episodes } = pickSelectedFromTree(tvShowsTableDataTree, DATA_LEVELS);
    return every([tv, seasons, episodes, selectedNetworks, selectedGenres], isEmpty);
  }, [tvShowsTableDataTree, selectedNetworks, selectedGenres, isLoadingTvMetadata]);

  return (
    <div className="advanced-tv-shows-modal-component">
      <WindowEventListener events="resize" eventHandlerFunction={setModalMaxHeights} />
      <ModalWithConfirmationButtons
        width="800px"
        maxHeightBeforeScroll={modalMaxHeight}
        modalTitle={modalTitle}
        isOpen={isOpen}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        onXClick={handleCancel}
        isSubmitDisabled={isSubmitDisabled}
        isDisabledEnterKeyPress={isSubmitDisabled}
        isLightTheme
        isYAutoScrollEnabled={true}
        containerPadding="24px 0px"
      >
        <div className="advanced-tv-shows-modal-content">
          {channel === CHANNELS.hisense?.name && (
            <div className="modal-row viewing-type">
              <div className="row-label">Viewing Type:</div>
              <div className="viewing-type-selector">
                <SingleSelectToggle
                  values={isHisenseAU ? HISENSE_AU_VIEWING_TYPES : VIEWING_TYPES}
                  selectedValue={viewingType}
                  onToggle={onChangeViewingType}
                  isModalTheme
                />
              </div>
            </div>
          )}
          {viewingType !== 'streaming' && (
            <div className="modal-row viewership-mode">
              <div className="row-label">Viewership Mode:</div>
              <Dropdown
                values={VIEWERSHIP_MODES}
                selectedValues={viewershipModes}
                isMulti
                summaryTextBuilder={getSummaryTextBuilder('All viewership modes')}
                onSelect={setViewershipModes}
                isDisabled={isHisenseAU}
              />
            </div>
          )}
          <div className="modal-row networks">
            <DropdownWithHeader
              promise={tvNetworksMetadataPromise}
              selectedValues={selectedNetworks}
              isMulti
              isSearchable
              summaryTextBuilder={getSummaryTextBuilder('All networks')}
              onSelect={onNetworksSelect}
              maxNumberOfSelectedOptions={7}
              reachMaxSelectedOptionsMsg="Sorry, this field is limited to 7 networks"
              headerTitle="Networks:"
              headerResetText="Reset"
            />
          </div>
          <div className="modal-row genres">
            <DropdownWithHeader
              promise={tvGenresMetadataPromise}
              selectedValues={selectedGenres}
              isMulti
              isSearchable
              summaryTextBuilder={getSummaryTextBuilder('All genres')}
              onSelect={onGenresSelect}
              headerTitle="Genres:"
              headerResetText="Reset"
              isDisabled={isHisenseAU}
            />
          </div>
          <div className="modal-row tv-shows">
            <div className="tv-shows-header">
              <div className="header-title-left">TV Shows:</div>
              {!isLoadingTvMetadata && (
                <div className="header-title-right" onClick={onResetSelectedTvShows}>
                  Reset
                </div>
              )}
            </div>
            <Spinner show={isLoadingTvMetadata} isSmall height="20px" />
            {!isLoadingTvMetadata && (
              <React.Fragment>
                <MultiLevelTableSelector
                  levelNames={LEVEL_NAMES}
                  tableData={tvShowsTableDataTree}
                  onChange={onShowsDataTableSelect}
                  showLevelSelector={false}
                />
                <ResultsTable
                  resultsTable={resultsTable}
                  onRemove={onRemoveResultsTable}
                  onReset={onResetResultsTable}
                />
                <MultiLevelResultsTable
                  levelNames={LEVEL_NAMES}
                  tableData={tvShowsTableDataTree}
                  onClear={onShowsDataTableSelect}
                  withoutHeader
                />
              </React.Fragment>
            )}
          </div>
          <div className="modal-row timeframe">
            <div className="row-label">Timeframe:</div>
            <TvCustomTimeframe
              startDateString={selectedStartDate}
              endDateString={selectedEndDate}
              timeframe={selectedTimeframe}
              selectedStartDateCB={(selectedDate) => setSelectedStartDate(dateToString(selectedDate))}
              selectedEndDateCB={(selectedDate) => setSelectedEndDate(dateToString(selectedDate))}
              selectedTimeframeCB={setSelectedTimeframe}
              isDisabled={isTimeframeDisabled}
              maxThreeMonths
            ></TvCustomTimeframe>
            <Separator className="modal-bottom-separator" />
          </div>
          <div className="modal-row level-of-exposure">
            <div className="row-label">
              <span className="label">Level of Exposure:</span>
              <ClickableIcon iconId="info-italic" tooltip={LEVEL_OF_EXPOSURE_TV_SHOWS_TOOLTIP} />
            </div>
            <div className="level-of-exposure-values">
              <SelectToggle
                values={LEVELS_OF_EXPOSURE}
                selectedValues={levelOfExposure}
                onChange={setLevelOfExposure}
                isDisabled={isHisenseAU}
              />
            </div>
          </div>
          {channel !== 'peerlogix' && (
            <div className="modal-row viewing-event-qualifier">
              <div className="row-label">Viewing Event Qualifier:</div>
              <Dropdown
                values={VIEWING_EVENT_QUALIFIERS}
                selectedValue={viewEventQualifier}
                onSelect={setViewEventQualifier}
                isDisabled={isHisenseAU}
              />
            </div>
          )}
          <div className="modal-row number-of-objects">
            <div className="row-label">Minimum Number of Programs Viewed:</div>
            <div className="number-of-objects-dropdowns">
              <span className="number-input">
                <input
                  type="number"
                  pattern={NUM_PROGS_PATTERN}
                  value={minNumberOfViewedProgs}
                  onChange={onMinNumProgsChange}
                  onWheel={onMinNumProgsChange}
                  min={MIN_VIEWED_PROGRAMS}
                  max={MAX_VIEWED_PROGRAMS}
                  disabled={isHisenseAU}
                />
              </span>
            </div>
          </div>
        </div>
      </ModalWithConfirmationButtons>
    </div>
  );
};

AdvancedTvShowsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  modalTitle: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  tvShowsInput: PropTypes.object,
  tvGenresMetadataPromise: PropTypes.object,
  channel: PropTypes.string,
  geo: PropTypes.string,
};

AdvancedTvShowsModal.defaultProps = {
  tvShowsInput: {},
  channel: '',
  geo: '',
};

export default AdvancedTvShowsModal;
