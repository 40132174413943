import { executeHttpRequest } from '../infra/HttpClient';
import EstimatedReachApi from '../api/EstimatedReachApi';

export async function getEstimatedReach(phrases, topics, geo, allGeos) {
  const phrasesIds = (phrases || []).map((phrase) => parseInt(phrase.id));
  const targetTopics = topics || [];
  const geos = (geo || []).length === 0 ? allGeos : geo;
  const geosIds = geos.map((geo) => geo.id.toString());
  return executeHttpRequest(EstimatedReachApi.getEstimatedReach(phrasesIds, targetTopics, geosIds));
}
