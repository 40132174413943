'use strict';

const queries = {
    web: {
        phrases: {
            columns: [
                {id: 'phrase', title: 'phrase', flex: '1-7', alwaysShown: true, template: 'partials/phrase-select.partial.html'},
                {id: 't', title: 'topic',tooltip: 'topic', ellipsis: true, flex: '0-7'},
                {id: 'velocity', title: 'velocity', sym: '%', flex: '0-5'},
                {id: 'assoc', title: 'consumption'},
                {id: 'specificity', title: 'specificity score', hidden: true},
                {id: 'reach', title: 'Estimated Volume'},
                {id: 'score', title: 'total score'},
                {id: 'uniqueness-index', title: 'Audience Skew', template: 'partials/audience-skew.partial.html'}
                ],
            sortCB: 'mixpanelTrackSort',
            largeHeader: true
        }
    },

    sg_telco: {
        phrases: {
            columns: [
                {id: 'phrase', title: 'phrase', flex: '1-7', alwaysShown: true, template: 'partials/phrase-select.partial.html'},
                {id: 'score', title: 'total score'},
                {id: 'velocity', title: 'velocity', sym: '%'},
                {id: 't', title: 'topic', ellipsis: true},
                {id: 'specificity', title: 'specificity score', hidden: true},
                {id: 'uniqueness-index', title: 'Audience Skew', template: 'partials/audience-skew.partial.html'}
            ],
            largeHeader: true
        }
    },

    sg_bidstream: {
        phrases: {
            columns: [
                {id: 'phrase', title: 'phrase', flex: '1-7', alwaysShown: true, template: 'partials/phrase-select.partial.html'},
                {id: 'score', title: 'total score'},
                {id: 'velocity', title: 'velocity', sym: '%'},
                {id: 'assoc', title: 'consumption'},
                {id: 't', title: 'topic', ellipsis: true},
                {id: 'specificity', title: 'specificity score', hidden: true},
                {id: 'uniqueness-index', title: 'Audience Skew', template: 'partials/audience-skew.partial.html'}
            ],
            largeHeader: true
        }
    },

    bidstream: {
        phrases: {
            columns: [
                {id: 'phrase', title: 'phrase', flex: '1-7', alwaysShown: true, template: 'partials/phrase-select.partial.html'},
                {id: 'score', title: 'total score'},
                {id: 'velocity', title: 'velocity', sym: '%'},
                {id: 't', title: 'topic', ellipsis: true},
                {id: 'specificity', title: 'specificity score', hidden: true},
                {id: 'uniqueness-index', title: 'Audience Skew', template: 'partials/audience-skew.partial.html'}
            ],
            largeHeader: true
        }
    },

    au_telco: {
        phrases: {
            columns: [
                {id: 'phrase', title: 'phrase', flex: '1-7', alwaysShown: true, template: 'partials/phrase-select.partial.html'},
                {id: 'score', title: 'total score'},
                {id: 'reach', title: 'Estimated Volume'},
                {id: 'velocity', title: 'velocity', sym: '%'},
                {id: 't', title: 'topic', ellipsis: true},
                {id: 'specificity', title: 'specificity score', hidden: true},
                {id: 'uniqueness-index', title: 'Audience Skew', template: 'partials/audience-skew.partial.html'}
            ],
            largeHeader: true
        }
    },

    facebook: {
        interests: {
            columns: [
                {id: 'id', title: 'interest', flex: '1-7', alwaysShown: true, template: 'partials/phrase-select.partial.html'},
                {id: 'score', title: 'total score'},
                {id: 'relevancy', title: 'relevancy score'},
                {id: 'velocity', title: 'velocity', sym: '%'},
                {id: 'facebook_category', title: 'Facebook category', template: 'partials/facebook-category.partial.html'}
            ],
            largeHeader: true
        },

        pages: {
            columns: [
                {id: 'name', title: 'name', flex: '1-7', alwaysShown: true, template: 'partials/phrase-select.partial.html'},
                {id: 'score', title: 'total score'},
                {id: 'shares', title: 'shares'},
                {id: 'likes', title: 'Reactions'},
                {id: 'comments', title: 'comments'},
                {id: 'top_topic', title: 'topic'},
                {id: 'ID', hidden: true, alwaysHidden: true}
            ],
            largeHeader: true
        }

    }
};

const tooltips = {

    score: {
        all: 'Final recommendation score based on Volume, Relevancy & Velocity',
        influencers: "Final recommendation score based on the influencer's popularity and relevancy",
        audience: "Final recommendation score based on the relevancy and the volume of the user's social activity"
    },

    relevancy: 'A measure indicating the tendency of a phrase to be mentioned in the context of the seed. 0 score means the phrase is never mentioned in the context of the seed, while 100 means it is always mentioned in the seed’s context.',
    reach: 'Estimated average daily phrase volume in US-based web consumption',

    mentions: {
        all: 'Total mentions of the phrase with the seed in posts during the selected time frame',
        handles: 'Total mentions of the handle with the seed in posts during the selected time frame',
        emojis: 'Total mentions of the emoji with the seed in posts during the selected time frame',
        hashtags: 'Total mentions of the hashtag with the seed in posts during the selected time frame'
    },

    mentions_per_day: 'Average daily mentions of the phrase in posts',
    velocity: 'A measure of how much the phrase has changed in popularity over a given period of time, compared to the previous period of time.',

    id: {
        interests: 'Facebook-targetable interests'
    },

    specificity: 'A measure of how specific the phrase is. Low values refer to phrases that appear in many topics, high values - few topics',
    assoc: 'How often the phrase is read together with the seed, compared to the other displayed phrases. The displayed phrase with the highest co-occurrence with the seed is given a score of 100.',
    estimated_reach: 'Estimated target audience size',
    facebook_category: 'Topic category on Facebook',

    net_sent: 'A measure indicating the overall sentiment expressed in content that mentions the phrase and the seed. The scale ranges from mostly negative to mostly positive.',
    shares: 'A measure of how many shares this page has',
    likes: 'A measure of how many reactions this page has',
    comments: 'A measure of how many comments this page has',

    t: 'The most common topic of the phrase in web consumption',
    top_topic: 'The most common topic of the phrase in web consumption',

    followers: {
        all: "User's number of followers",
        influencers: "Influencer's number of followers"
    },

    klout_score: {
        all: "User's rank according to Klout",
        influencers: "Influencer's rank according to Klout"
    },

    'uniqueness-index': 'Audience Skew score measures the extent of which a phrase is likely to be consumed by an audience, compared to the average consumer'
};

//add to query values like tooltip
for(let i in queries) {
    let channel = queries[i];

    for(let j in channel) {
        let query = channel[j];
        query.title = j;

        //tooltips
        query.columns.forEach(function(col) {
            if(tooltips[col.id]) {
                let tip = tooltips[col.id];
                if(typeof tip == 'string') {
                    col.tooltip = tip;
                } else {
                    if(tip[j]) {
                        col.tooltip = tip[j];
                    } else {
                        col.tooltip = tip.all;
                    }
                }
            }
        });

        //sort by score
        query.order = 'score';
        query.reverse = true;
        query.click = 0;
    }
}


module.exports = angular.module(__filename, []).constant('GRID_QUERIES', {
        QUERIES: queries,

        postProcessQuery: function(query, $state, context){
            const topicsIndex = query.columns.findIndex(col => col.id === 'top_topic' || col.id === 't');
            if (topicsIndex !== -1) {
                const shouldShowTopics = context.current._language_mold.shouldShowTopics($state, context);
                query.columns[topicsIndex].hidden = !shouldShowTopics;
                query.columns[topicsIndex].alwaysHidden = !shouldShowTopics;
            }
            const estimatedValueIndex = query.columns.findIndex(col => col.id === 'reach' || col.id === 'mentions_per_day');
            if (estimatedValueIndex !== -1) {
                const isUsOnly = _.isEqual(_.map(context.current.geo, 'cc'), ['US']);
                query.columns[estimatedValueIndex].hidden = !isUsOnly;
                query.columns[estimatedValueIndex].alwaysHidden = !isUsOnly;
            }
        }
});
