import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon/Icon';
import './RangeLabelWithIcons.scss';

const RangeLabelWithIcons = ({ startIconId, endIconId, startLabel, endLabel }) => (
  <div className="range-label-with-icons-component">
    <Icon iconId={startIconId} className="range-icon" width="16px" height="16px"></Icon>
    <span className="range-label">{startLabel}</span>
    <span className="hyphen">-</span>
    <Icon iconId={endIconId} className="range-icon" width="16px" height="16px"></Icon>
    <span className="range-label">{endLabel}</span>
  </div>
);

RangeLabelWithIcons.propTypes = {
  startIconId: PropTypes.string.isRequired,
  endIconId: PropTypes.string.isRequired,
  startLabel: PropTypes.string.isRequired,
  endLabel: PropTypes.string.isRequired,
};

RangeLabelWithIcons.defaultProps = {};

export default RangeLabelWithIcons;
