"use strict";

module.exports = angular.module(__filename, [
    require('data/filters-partition.srv').name,
    require('./filter-mold-service').name
]).factory("BubblesChannelsMold", ['filtersPartition', 'filterMoldService', function (filtersPartition, filterMoldService) {
    return function BubblesChannelsMold() {
        this.default = {label: "Web", value: "articles"};
        filterMoldService.getDefaultFilterMoldFunction.call(this, filtersPartition.bubblesChannel, true);
    }
}]);
