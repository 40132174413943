root = exports ? this

module.exports = class @WordWrapper

	lay_out_words:( lines, word_widths, total_width, line_height ) ->

		x 			= 0
		line_idx 	= 0
		word_idx 	= 0
		result 		= []
		line_center_offset = []
		while( x < lines[line_idx] and word_idx < word_widths.length and line_idx < lines.length )
			remaining = lines[line_idx] - x
			word_width = word_widths[word_idx]
			y = line_height * ( (line_idx+0.9) - (lines.length/2.0) )  # 0.9 is to leave 10% for the text baseline
			
			if ( word_width <= remaining ) # can this word enter in this line?
				l = { x:x, y:y, w: word_width, span: @spans[0][word_idx], line_idx: line_idx }
				result.push(l)
				x += word_width + @space
				word_idx++
			else # need an additional line
				line_center_offset[line_idx] = x / 2
				x = 0
				line_idx++

		line_center_offset[line_idx] = x / 2		

		return [result, line_center_offset]



	word_wrap:( text_element, sentence, radius, fontsize ) ->
		words = sentence.split(" ")

		@spans = d3.select( text_element ).selectAll("tspan").style("visibility","visible").data(words)
		@spans.attr("x", -5000 ).attr("dy", 0 ).text( (d) -> d ).style("font-size", fontsize )
		@spans.enter().append("tspan").attr("x", -5000 ).attr("dy", 0 ).text((d) -> d ).style("font-size", fontsize )
		@spans.exit().remove()

		@space 		= 2
		word_widths	= @spans[0].map( (x) -> x.getComputedTextLength() )
		total_width = @spans[0].reduce( ((p, x) => p + x.getComputedTextLength() + @space), -@space ) # initial neg value is to account for the extra space that would be summed
		line_height = fontsize #@spans[0].reduce( ((p, x) -> Math.max( p, x.offsetHeight )), 0 )

		radius_squared 		= Math.pow( radius, 2 )
		line_height_squared = Math.pow( line_height, 2 )

		line_widths    = []
		line_widths[0] = 	 Math.sqrt( 4 * radius_squared - 						line_height_squared )
		line_widths[1] = 2 * Math.sqrt( 	radius_squared - 						line_height_squared )
		line_widths[2] = 2 * Math.sqrt( 	radius_squared - Math.pow( 1.5, 2 ) * 	line_height_squared )

		# try 1 line
		[layout, line_center_offset] = @lay_out_words( [ line_widths[0] ], word_widths, total_width, line_height )

		if layout.length < word_widths.length 
			# words were left out, try 2 lines
			[layout, line_center_offset] = @lay_out_words( [ line_widths[1], line_widths[1] ], word_widths, total_width, line_height )

			if layout.length < word_widths.length
				# words were left out, try 3 lines
				[layout, line_center_offset] = @lay_out_words( [ line_widths[2], line_widths[0], line_widths[2] ], word_widths, total_width, line_height )

		$.each( layout, (idx, item) ->
			d3.select(item.span).attr("x", item.x - line_center_offset[item.line_idx] )
			d3.select(item.span).attr("y", item.y)
			d3.select(item.span).attr("dy", 0)
		)
		return [layout,line_center_offset]

	check_words: (text_element,sentence, radius, fontsize ) =>
		[layout,line_center_offset] = @word_wrap(text_element, sentence, radius, fontsize )
		if $("tspan[x=-5000]").length > 0 then return false
		lines = []
		words = sentence.split(" ")
		current_line = -1
		for node,i in layout
			if node.line_idx > current_line
				current_line = node.line_idx
				lines.push({line_idx:node.line_idx,center_offset:line_center_offset[node.line_idx],words:[],sentence:""})
			lines[lines.length - 1].sentence += words[i] + " "
			lines[lines.length - 1].words.push({
				x:node.x,y:node.y,word:words[i]
			})
		line.sentence = line.sentence.trim() for line in lines
		return lines






