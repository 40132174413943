import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ClickableIcon from '../ClickableIcon/ClickableIcon';
import Menu from '../Menu/Menu';
import classNames from 'classnames';
import './ClickableIconWithMenu.scss';
import '../Menu/Menu.scss';

const ClickableIconWithMenu = ({ className, iconId, menuActions, headerLabel }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className={classNames('clickable-icon-with-menu-component', className)}>
      <ClickableIcon iconId={iconId} onClick={() => setIsMenuOpen(true)} />

      <Menu
        isMenuOpen={isMenuOpen}
        closeMenu={() => setIsMenuOpen(false)}
        headerLabel={headerLabel}
        menuActions={menuActions}
      />
    </div>
  );
};

ClickableIconWithMenu.propTypes = {
  className: PropTypes.string,
  iconId: PropTypes.string.isRequired,
  menuActions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onActionClick: PropTypes.func.isRequired,
      hide: PropTypes.bool,
    })
  ).isRequired,
  headerLabel: PropTypes.string,
};

export default ClickableIconWithMenu;
