import 'angular-modal-service';

module.exports = angular.module(__filename, [
    'angularModalService'
]).service('confirmAction', ['ModalService', function (ModalService) {
    this.getConfirmation = showConfirmationModal;

    //////////////////////////////

    function showConfirmationModal(message, txtConfirm, txtCancel, txtTitle, applyClass, isLightTheme) {
        return ModalService.showModal({
            template: require('./confirmation.html'),
            bodyClass: applyClass,
            inputs: {message: message, txtConfirm: txtConfirm, txtCancel: txtCancel, txtTitle: txtTitle, isLightTheme: isLightTheme},
            controller: ['$scope', 'message', 'close', 'txtConfirm', 'txtCancel', 'txtTitle', 'isLightTheme', ConfirmationModalController]
        });
    }

    function ConfirmationModalController($scope, message, close, txtConfirm, txtCancel, txtTitle, isLightTheme) {
        $scope.txtTitle = txtTitle ? txtTitle : "Confirmation required";
        $scope.message = message;
        $scope.txtConfirm = txtConfirm || 'Yes';
        $scope.txtCancel = txtCancel || 'No';
        $scope.isLightTheme = isLightTheme;

        $scope.confirmSave = function (save) {
            close(save)
        };
        $scope.close = function () {
            close(false)
        };
    }
}]);
