import React from 'react';
import './ClickableLabel.scss';
import Icon from '../Icon/Icon';
import Tooltip from '../Tooltip/Tooltip';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { noop } from 'lodash';

const DEFAULT_ICON_SIZE = '8px';

const ClickableLabel = ({
  label,
  rightIconId,
  leftIconId,
  isDisabled,
  tooltip,
  onDisabledTooltip,
  onClick,
  className,
  rightIconStyle,
  leftIconStyle,
}) => (
  <div
    className={classNames('clickable-label-component', className, { disabled: isDisabled, noop: onClick === noop })}
    onClick={() => !isDisabled && onClick()}
    role="button"
  >
    <Tooltip content={isDisabled ? onDisabledTooltip : tooltip}>
      <div className="clickable-label-content">
        {leftIconId && (
          <Icon
            iconId={leftIconId}
            className="left-icon"
            height={leftIconStyle.height}
            width={leftIconStyle.width}
          ></Icon>
        )}
        <span style={{ marginRight: rightIconId && DEFAULT_ICON_SIZE, marginLeft: leftIconId && DEFAULT_ICON_SIZE }}>
          {label}
        </span>
        {rightIconId && (
          <Icon
            iconId={rightIconId}
            className="right-icon"
            height={rightIconStyle.height}
            width={rightIconStyle.width}
          ></Icon>
        )}
      </div>
    </Tooltip>
  </div>
);

ClickableLabel.propTypes = {
  label: PropTypes.string.isRequired,
  rightIconId: PropTypes.string,
  leftIconId: PropTypes.string,
  isDisabled: PropTypes.bool,
  tooltip: PropTypes.string,
  onDisabledTooltip: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  rightIconStyle: PropTypes.shape({
    height: PropTypes.string.isRequired,
    width: PropTypes.string.isRequired,
  }),
  leftIconStyle: PropTypes.shape({
    height: PropTypes.string.isRequired,
    width: PropTypes.string.isRequired,
  }),
};

ClickableLabel.defaultProps = {
  isDisabled: false,
  rightIconStyle: { height: '14px', width: '14px' },
  leftIconStyle: { height: '14px', width: '14px' },
  onClick: noop,
};

export default ClickableLabel;
