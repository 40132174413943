import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon, Checkbox, FormControl } from '@amobee/component-library';
import Separator from '../../Separator/Separator';
import './SelectorAccordion.scss';

const SelectorAccordion = ({
  isOpen,
  isChecked,
  isCheckboxIndeterminate = false,
  hasToggle = true,
  label,
  onCheck,
  content = '',
  rightContent = '',
  secondaryText = '',
  isSelectAll = false,
  hasExtraMargin = false,
}) => {
  const [openValue, setOpenValue] = useState(isOpen);

  return (
    <div
      className={classNames('select-accordion-component', {
        'select-all': isSelectAll,
        'extra-margin': hasExtraMargin,
      })}
    >
      <div className={classNames('select-accordion-header', { open: openValue })}>
        {hasToggle && (
          <span onClick={() => setOpenValue(!openValue)}>
            <Icon name={'TriangleDown'} className={classNames('triangle', openValue ? 'up' : 'down')} />
          </span>
        )}
        <FormControl>
          <Checkbox checked={isChecked} indeterminate={isCheckboxIndeterminate} onChange={() => onCheck(!isChecked)} />
        </FormControl>
        {isSelectAll && <Separator />}
        <div className="label">
          <div className="label-text">{label}</div>
          {secondaryText && <div className="label-text-secondary">{secondaryText}</div>}
        </div>
        {rightContent && <div className="right-content">{rightContent}</div>}
      </div>
      {openValue && content && !!content.length && (
        <div className={classNames('select-accordion-content visible')}>{content}</div>
      )}
    </div>
  );
};

SelectorAccordion.propTypes = {
  content: PropTypes.any,
  hasToggle: PropTypes.bool,
  isOpen: PropTypes.bool,
  isChecked: PropTypes.bool,
  isCheckboxIndeterminate: PropTypes.bool,
  onCheck: PropTypes.func.isRequired,
  rightContent: PropTypes.any,
  secondaryText: PropTypes.string,
  label: PropTypes.any.isRequired,
  isSelectAll: PropTypes.bool,
  hasExtraMargin: PropTypes.bool,
};

export default SelectorAccordion;
