var BaseWidget = require("../base_widget");

function BrandPerceptionsWidgetCtrl(util, consumptionTrendService) {
    var $scope = this.$scope;
    this.util = util;
    this.consumptionTrendService = consumptionTrendService;

    $scope.isMainBrand = function (name) {
        return name == $scope.brandName;
    };
}

BrandPerceptionsWidgetCtrl.$inject = ["util", "consumptionTrendService"];

BrandPerceptionsWidgetCtrl.prototype._doUpdate = function () {
    var self = this;
    var program_terms = self.$scope.program.competitors.map(function (c) {
        return c.name.toLowerCase()
    }).concat([self.$scope.brandName.toLowerCase()]).sort();

    var dashboard_terms = self.$scope.terms.map(function (t) {
        return t.text.toLowerCase()
    }).sort();

    if (!_.isEqual(program_terms, dashboard_terms)) return;

    var kwd_ids = this.util.getPhrasesToIdMap(self.$scope.terms);
    var consumptionParams = {
        timeframe: 30,
        terms: this.$scope.terms,
        topics: [],
        geo: this.$scope.geos,
        kwd_ids: kwd_ids,
        to_normalize: true
    };

    return this.consumptionTrendService.get(consumptionParams, null).then(function (data) {
        if (!data) return;
        self.$scope.resultArray = data.sums;
        self.$scope.maxVal = Math.max.apply(Math, data.sums.map(function (sum) {
            return sum.value
        }));
    })
};

module.exports = angular.module(__filename, [
    require("data/insights/consumption-trend-service").name
]).directive("brandPerceptionsWidget", [function () {
    return BaseWidget({
        restrict: "E",
        template: require("./brand-perceptions-widget.html"),
        scope: {
            terms: "=",
            program: "=",
            brandName: "=",
            geos: "="
        },
        controller: BrandPerceptionsWidgetCtrl
    });
}]);
