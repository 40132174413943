const BaseMapComponent = require('../base-map-component');

class AustraliaMapComponent extends BaseMapComponent {
    constructor() {
      super('australia');
      this.mapSizes = {
        state: 'state',
        sa3: "SA3",
        sa4: 'SA4'
      };

      this.zoomForScale = {
        state: 5,
        sa3: 50,
        sa4: 12
      };

      this.mapOptions = {
        scaleFactor: 0.55,
        center: [ 133.7751, -25.2744 ],
        maxScale: this.zoomForScale [ this.mapScale ]
      };
    }

    $onInit() {
      this.mapOptions = Object.assign({}, this.mapOptions, {maxScale: this.zoomForScale[this.mapScale]});
      this.setMapNameFromScale(this.mapScale);
    };

    $onChanges(changesObj) {
      if (changesObj.mapScale) {
        this.mapOptions = Object.assign({}, this.mapOptions, {maxScale: this.zoomForScale[changesObj.mapScale.currentValue]});
        this.setMapNameFromScale(changesObj.mapScale.currentValue);
      }

      if (changesObj.popupData) {
        this.popupTemplate = this.getPopupFunction(changesObj.popupData.currentValue);
      }

      if (changesObj.selectedRegions) {
        this.selectedRegions = changesObj.selectedRegions.currentValue;
      }
    };
}

module.exports = angular.module(__filename, [
  require('common/map-component/map-component').name
])
  .component('australiaMapComponent', {
      template: require('../base-map-component.html'),
      bindings: {
        data: '<',
        mapScale: '<',
        popupData: '<',
        allRegionsSelected: '<',
        terms: '<',
        selectedRegions: '<'
      },
      controller: AustraliaMapComponent,
  })
