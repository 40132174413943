"use strict";

module.exports = angular.module(__filename, [
    require('data/filters-partition.srv').name,
    require('./filter-mold-service').name
]).factory("AudienceMold", ['filtersPartition', 'filterMoldService', function (filtersPartition, filterMoldService) {
    return function AudienceMold() {
        this._value = [];
        this.default = [];

        var collection = filtersPartition.genders
            .concat(filtersPartition.age, filtersPartition.children, filtersPartition.income, filtersPartition.ethnicityWithAll, filtersPartition.races);

        filterMoldService.getDefaultFilterMoldFunction.call(this, collection);
    }
}]);
