module.exports = angular.module(__filename, []).service('appsModal', ['ModalService', function (ModalService) {
    this.showModal = showModal;

    function showModal(apps) {
        return ModalService.showModal({
            template: require('./apps-modal.html'),
            inputs: {apps: apps},
            controller: ['$q', '$scope', 'close', 'apps', 'TermsMold', AppsModalCtrl]
        });
    }

    function AppsModalCtrl($q, $scope, close, apps, TermsMold) {
        $scope.isNewSegment = apps == null;
        $scope.apps = _.extend({included: null}, apps);
        $scope.close = close;
        $scope.termsMold = new TermsMold();

        $scope.isValid = function () {
            return !_.isEmpty(removeEmptyFilters(this.apps));
        };

        function removeEmptyFilters(apps) {
            var results = {};
            if ((apps.included || []).length > 0) results.included = apps.included;
            return results;
        }

        $scope.save = function () {
            close(_.extend(removeEmptyFilters(this.apps), {type: "apps", operand: this.apps.operand}));
        }
    }
}]);
