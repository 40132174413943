import * as MixpanelInsights from '../../react/infra/mixpanel/MixpanelInsights';
import moment from 'moment';

var Template = require("./consumption-index-widget.html"),
    c = require("infra/utils/common"),
    ex = require("infra/utils/export"),
    BaseWidget = require("../base_widget"),
    TrendChart = require("../../common/charts/trend-chart"),
    BarChart = require("../../common/charts/vertical-bar-chart"),
    ConsumptionService = require("data/insights/consumption-trend-service"),
    examplesDataModel = require("data/insights/examples-data-model");

var consumptionExportTabName = 'Consumption Trends',
    consumptionChangeFromAvgExportTabName = '% Change From Average';

var consumptionConfiguration = {
    element: 'consumption-chart',
    parent: 'consumption-index-widget',
    detectTimeOrDate: true,
    force_max: true,
    agg_examples_mode: true,
    templates: {
      non_normalized: _.template("<%= parseInt(consumption).toLocaleString('en') %> interactions, <%= consumptionAvg > 0 ? '+' : ''%><%= consumptionAvg %>% from Avg"),
      normalized: _.template("Consumption <%= parseFloat(consumption.toFixed(1)) %>, <%= (consumptionAvg > 0) ? '+' : '' %><%= consumptionAvg %>% from Avg")
    },
    marginTop: 30,
    chartHeight: 'calc(100% - 15px)'
};

var consumptionAverageConfiguration = {
    title: 'Average',
    element: 'consumption-average-chart',
    parent: 'consumption-index-widget',
    margin: 90,
    pad: 0,
    grouped: true
};

var DEFAULT_SUMMARY_PARAMETERS = ex.DEFAULT_SUMMARY_PARAMETERS.concat([{
    key: 'associated',
    label: 'Association'
}]);

var CHART_ENTRIES = [{
    key: 'value',
    source: 'value',
    order: 1,
    class: '',
    units: ''
}];

var INDEX_CONSUMPTION_OPTION = {value: 'index', label: 'Indexed Consumption'};
var NUMBERS_CONSUMPTION_OPTION = {value: 'numbers', label: 'Absolute Numbers'};

function isWithDottedLine(parameters, chartData, firstPartyAudience) {
    if(!chartData.last_closed_time_mark) {
        return false;
    }

    if (parameters.channel === 'articles' && _.isEmpty(firstPartyAudience)) {
        return true;
    }
    const isHourlyAggregation = chartData.range.some(date => date.getHours() > 0);
    const isBidStream = (parameters.channel === 'articles' && firstPartyAudience.length > 0) ||
                        ['sg_bidstream', 'bidstream'].includes(parameters.channel);
    return (isBidStream || parameters.channel === 'sg_telco') && isHourlyAggregation;
}

function showTimelineInterval(dateRange) {
    if(_.isNumber(dateRange[0])) {
        return dateRange[0] <= 6 && dateRange[1] === 'day';
    }
    const startDate = moment(dateRange[0], 'DD_MM_YY_HH_mm');
    const endDate = moment(dateRange[1], 'DD_MM_YY_HH_mm');
    return moment(endDate).diff(startDate, 'days') <= 6;
}

ConsumptionIndexController.$inject = ["$scope", "$timeout", "$state", "consumptionTrendService", "util",
                                      "abiPermissions", "examplesDataModel", "insightsExportService",
                                      "geoService", "baseInsightsService", "TIMES",
                                      "filtersPartition", "notificator", "$location", "context"];

function ConsumptionIndexController($scope, $timeout, $state, consumptionTrendService, util, abiPermissions,
                                    examplesDataModel, insightsExportService, geoService,
                                    baseInsightsService, TIMES, filtersPartition, notificator, $location, context) {
    var self = this;
    this.service = consumptionTrendService;
    this.insightsExportService = insightsExportService;
    this.geoService = geoService;
    this.parameters = {};
    this.util = util;
    this.$state = $state;
    this.permissions = abiPermissions;
    this.baseInsightsService = baseInsightsService;
    this.TIMES = TIMES;
    this.filtersPartition = filtersPartition;
    this.notificator = notificator;
    this.$location = $location;
    this.context = context;
    $scope.noData = true;
    $scope.showDataInProcess = false;
    $scope.inProcess = false;
    $scope.trends = [];
    $scope.trendAverages = [];
    $scope.examplesData = examplesDataModel;
    $scope.legendTrends = [];
    this.chartData = {};
    this.chart = new TrendChart(this, "consumption-chart", consumptionConfiguration);

    this.emitEventForChartCircle = function (eventType, letter) {
        $scope.$emit(eventType, letter);
    };

    $scope.consumption_options = [INDEX_CONSUMPTION_OPTION, NUMBERS_CONSUMPTION_OPTION];

    $scope.setToNormalize = (option) => {
        if(option) {
            $scope.selected_consumption_option = option;
        }
        $scope.toNormalize = $scope.selected_consumption_option.value == INDEX_CONSUMPTION_OPTION.value;
    };

    this.barChart = new BarChart(this, CHART_ENTRIES, consumptionAverageConfiguration);
    MixpanelInsights.trackPageView('seasonality');
    if (abiPermissions.hasPermission(['normalize'])) {
        $scope.selected_consumption_option = $scope.insightsChannel.value == 'facebook' ? NUMBERS_CONSUMPTION_OPTION : INDEX_CONSUMPTION_OPTION;
        $scope.setToNormalize();
    }

    this.onResize = function () {
        var data = self.parameters.to_normalize ? self.chartData.averages : self.chartData.sums;
        self.barChart.setData(data, self.chartData.maxChart, self.wide);
        $timeout(function () {
            self.chart.draw(self.chartData, self.chartData.max, self.parameters.to_normalize ? 'normalized' : 'non_normalized');
            self.chart.addCircles($scope.examplesData.visible_examples);
        }, 0);
    };

    this.clearData = function (update) {
        $scope.inProcess = false;
        $scope.trends = [];
        $scope.trendAverages = [];
        $scope.examplesData.visible_examples = [];
        $scope.examplesData.filters = [];
        $scope.examplesData.filter_type = '';
        //TODO - clear all examples?
        this.chartData = {};
        if (update) {
            $timeout(function () {
                self.onResize();
            }, 0);
        }
        $scope.noData = true;
    };

    this.getExportReport = function (format, avgMode) {
        var report = {
            name: avgMode ? consumptionChangeFromAvgExportTabName : consumptionExportTabName,
            columns: [],
            table: []
        };
        var terms = self.util.getValidTerms($scope, self.parameters);
        if (c.isArray(terms)) {
            var titles = [format('Date', 'bold')];
            report.columns = [{width: 18}];
            _.each(terms, function (term) {
                titles.push(format(self.util.getTermDisplay(term), 'bold'));
                report.columns.push({width: 25});
            });
            report.table = [titles];
            const cellType = self.parameters.to_normalize ? 'numeric' : 'integer';
            var result = self.service.getValuesByDateTable(terms, format, cellType, avgMode);
            report.table = report.table.concat(result);
        }
        return report;
    };

    this.getExportExamples = function (format) {
        var report = {
            name: 'Content drivers',
            columns: [],
            table: []
        };
        report.table = [
            [format('Date', 'bold'), format('Seed', 'bold'), format('URL', 'bold')]
        ];
        report.columns = [{width: 18}, {width: 25}, {width: 100}];
        var result = self.service.getExamples($scope.trends, format);
        report.table = report.table.concat(result);
        return report;
    };

    $scope.showConsumptionOptionsDropdown = () => abiPermissions.hasPermission(['normalize']);

    $scope.$on('contentDriverMouseover', function (event, letter) {
        self.chart.highlightCircleFromLetter(letter, true);
    });

    $scope.$on('contentDriverMouseout', function (event, letter) {
        self.chart.highlightCircleFromLetter(letter, false);
    });

    $scope.$on('contentDriverClick', function (event, letter) {
        self.chart.resetHighlightedCircle();
        self.chart.letterClicked = letter;
        self.chart.highlightCircleFromLetter(letter, true);
    });

    $scope.$on('widgetPanelClick', function () {
        self.chart.resetHighlightedCircle();
    });

    this.$scope = $scope;
    insightsExportService.setTitle('Consumption');
}

ConsumptionIndexController.prototype._doUpdate = function (values, changedVals) {

    this.chart.resetHighlightedCircle();
    var $scope = this.$scope;
    var $location = this.$location;
    $scope.$emit('resetLetterClicked');
    $scope.examplesData.visible = true;
    $scope.examplesData.alphabetized = true;
    $scope.examplesData.filter_type = 'trend';
    $scope.insightsChannel = c.getCurrentChannel($scope.insightsChannel, $scope.$root.insightsChannelsFilter, this.permissions);

    var nonPhrases = c.validateNonPhrases($scope.terms, $scope.insightsChannel.value, this.notificator);
    if (changedVals.insightsChannel && this.permissions.hasPermission(['normalize'])) {
        $scope.selected_consumption_option = $scope.insightsChannel.value === 'facebook' ?  NUMBERS_CONSUMPTION_OPTION : INDEX_CONSUMPTION_OPTION;
        $scope.setToNormalize();
    }

    const hasHourlyConsumptionTrends = this.permissions.hasPermission(['hourly consumption trends']);
    if ($location.path() === "/insights/seasonality") { //Don't set timeframe vars if we're stepping out of widget
        let times = ["1W", "1M", "3M", "6M", "1Y"];
        if (hasHourlyConsumptionTrends) {
            times = ["3D"].concat(times);
        }
        $scope.$root.$broadcast('timeframe-vars-update', times, this.TIMES.INSIGHTS_OFFSET, false, true);
    }

    this.parameters = this.util.buildInsightsParameters($scope);
    this.parameters.hourly_consumption_trends = hasHourlyConsumptionTrends;
    this.parameters.timelineInterval = this.context.current.insights_timeline_interval;

    $scope.trends = this.parameters.trends;
    $scope.termClasses = {};

    $scope.examplesData.visible_examples = [];
    $scope.examplesData.examples.consumption = [];
    $scope.examplesData.terms = $scope.trends;
    $scope.legendTrends = nonPhrases ? $scope.trends.filter(({type}) => ['mention', 'hashTag', 'post'].indexOf(type) === -1) : $scope.trends;
    angular.copy($scope.legendTrends, $scope.examplesData.filters);
    _.each($scope.examplesData.terms, (trend) => $scope.termClasses[trend.text] = $scope.termClasses[trend.id] = trend.class);
    _.each($scope.examplesData.filters, (trend) => trend.show = true);

    consumptionAverageConfiguration.title = this.parameters.to_normalize ? 'Average' : 'Total';
    consumptionAverageConfiguration.precision = this.parameters.to_normalize ? 1 : 0;
    c.logUpdate('Consumption Trends', this.parameters);
    $scope.inProcess = true;

    this.parameters.measure = $scope.measure;
    this.insightsExportService.clear();
    let exportParameters = [];
    if(this.baseInsightsService.shouldSendAudience(this.parameters.channel, this.permissions)) {
        this.insightsExportService.addSummaryFields(ex.AUDIENCE_SUMMARY_FIELD);
        exportParameters = _.extend({}, this.parameters, {audience: _.map(c.getAudience($scope, this.parameters.channel), 'summary')});
    } else {
        exportParameters = _.clone(this.parameters);
    }
    if(this.parameters.channel === 'articles' && this.permissions.hasPermission('first party segments') && !_.isEmpty($scope.userFirstPartyAudience)) {
        this.insightsExportService.addSummaryFields(ex.FIRST_PARTY_SEGMENTS_SUMMARY_FIELD);
        exportParameters.first_party_audience = _.map($scope.userFirstPartyAudience, 'summary');
    } else {
        delete this.parameters.user_first_party_audience;
    }

    this.insightsExportService.addSummaryFields(ex.TIMEZONE_SUMMARY_FIELD, 1);
    exportParameters.timezone = this.context.current.insights_timeline_timezone;
    this.insightsExportService.setParams(exportParameters);
    const language = this.context.current._language_mold.getLanguage(this.$state, this.context).value;
    this.chart.setWithSimWebDate(this.parameters.channel === 'articles' && language === 'en' && !this.util.shouldApplySmoothing());
    if (showTimelineInterval(this.context.current._insights_timeframe_mold._value) && this.context.current.insights_timeline_interval === 'Hourly') {
        this.chart.setCustomTimezone(this.context.current.insights_timeline_timezone);
    } else {
        this.chart.setCustomTimezone(undefined);
    }
    let self = this;
    $scope.examplesData.promise = this.service.get(this.parameters, null);
    return $scope.examplesData.promise.then(function (data) {
        $scope.showDataInProcess = isWithDottedLine(self.parameters, data, self.context.current.firstPartyAudience);
        self.chart.setWithDottedLine($scope.showDataInProcess);

        _.each([false, true], (avgMode) => {
          self.insightsExportService.addReportFunction((format) => self.getExportReport(format, avgMode));
        });
        self.insightsExportService.setExampleFunction(self.getExportExamples);
        self.$scope.noData = false;
        self.chartData = data;
        self.$scope.trendAverages = data.averages;
        $scope.examplesData.icon = data.icon;
        $scope.examplesData.examples.consumption = data.examples;
        _.each($scope.examplesData.examples.consumption, function (ex, i) {
            ex.class = $scope.termClasses[ex.keyword];
        });
        $scope.examplesData.examples.consumption = _.filter($scope.examplesData.examples.consumption, function (example) {
            return $scope.termClasses[example.keyword]
        });
        if (self.$state.includes('*.seasonality')) {
            angular.copy($scope.examplesData.examples.consumption, $scope.examplesData.visible_examples);
        }
        self.onResize();
        self.$scope.inProcess = false;
    }, function (error) {
        self.clearData(true);
        if (error !== "No Terms") {
          $scope.$emit('insightsError', error);
        }
    });
};

module.exports = angular.module(__filename, [ConsumptionService.name])
    .directive("consumptionIndexWidget", [function () {
        return BaseWidget({
            restrict: "E",
            template: require("./consumption-index-widget.html"),
            scope: {
                topics: "=",
                terms: "=",
                active: "=",
                insightsChannel: '=insightsChannel',
                audience: '=',
                sgTelcoAudience: '=',
                userFirstPartyAudience: '=',
                timeframe: "=",
                geo: "=",
                sub_geos: "=subGeos",
                toNormalize: "=?",
                measure: "=?",
                graphTitle: "=?",
                showContentDrivers: "=?",
                cacheBaster: "=",
                test: "=",
                timelineInterval: "=",
                timelineTimezone: "=",
            },
            controller: ConsumptionIndexController
        });
    }]
);
