import React from 'react';
import TargetForecast from "../../react/components/apps/channelInsights/TargetForecast/TargetForecast";
import { getEstimatedReach } from '../../react/services/EstimatedReachService';
import Widget from "../../react/components/common/Widget/Widget";

const TargetForecastWidget = ({ phrases, topics, geo, isDynamic, incOrExc, allGeos }) => (
    <Widget name="TargetForecastWidget"
                      dataService={ getEstimatedReach }
                      dataServiceParams={ [phrases, topics, geo, allGeos] }
                      renderOnValueChange={ [isDynamic, incOrExc] }
                      onRender={ (data) => (
                <TargetForecast reach={ data.reach } impressions={ data.imps } incOrExc={incOrExc} phrases={phrases} topics={topics}></TargetForecast>
                                                                          )}></Widget>

);

export default TargetForecastWidget;
