import config from 'infra/config';
import c from 'infra/utils/common';

var API = config.SEARCH_API + '/insights';
var QUERY_TIME_FORMAT = 'YYYY-MM-DD';
const sentimentLabels = ['pos', 'neg', 'neu'];

module.exports = angular.module(__filename, [])
    .service("sentimentTrendService", ['baseInsightsService', 'util', 'abiPermissions', 'geoService',
      function (baseInsightsService, util, abiPermissions, geoService) {
        var latestData;

        function isResponseDataEmpty(data, notFound) {
            var insufficient = notFound;
            _.each(data.averages, function(point) {
                if (point.value == 0){
                  insufficient.push(point.label);
                }
            });
            return insufficient;
        }

        function getValue(value, decimals) {
            var roundedValue = c.getNumber(value, decimals);
            return roundedValue > 0 ? roundedValue : 0;
        }

        function validateDataPoint(point) {
            if (c.is(point) && c.isString(point.date)) {
                var pointDate = new Date(point.date);
                pointDate.setTime(pointDate.getTime() + (pointDate.getTimezoneOffset() + 60) * 60000);
                point.key = moment(pointDate).format(QUERY_TIME_FORMAT);
                point.date = c.parseDateHourMinute(point.key, 'YYYY-MM-DD').toDate();
                point.value = getValue(point.value, 0);
                let sentimentSum = 0;
                sentimentLabels.forEach((field) => {
                    point[field] = (point.value > 0) ? getValue(point[field], 0) : 0;
                    sentimentSum += point[field];
                  });
                sentimentSum = sentimentSum || 1; // Avoid dividing by 0
                const ratio = {}
                sentimentLabels.forEach((field) => {
                    ratio[field] = point[field] / sentimentSum;
                  });
                point.negative = ratio.neg * point.value;
                point.positive = ratio.pos * point.value;
                point.normal = ratio.neu * point.value;
                sentimentLabels.forEach((field) => point[field] = ratio[field] * 100 );
                point.p = point.positive;
                point.normal += point.negative;
                point.positive += point.normal;
                delete point['unnormalized_value'];
                return true;
            }
            return false;
        }

        function calculateAverage(averageRecord, field, factor) {
            averageRecord.description[field] = ((averageRecord[field] / averageRecord.total ) * 100).toFixed(1) + averageRecord.description[field];
            averageRecord[field] = factor * averageRecord[field];
        }

        function calculateSums(data, sums) {
            var averageCopy = angular.copy(data);
            averageCopy.description.positive = averageCopy.positive.toFixed(1) + "%";
            averageCopy.description.negative = averageCopy.negative.toFixed(1) + "%";
            averageCopy.description.normal = averageCopy.normal.toFixed(1) + "%";
            averageCopy.positive = (averageCopy.value * averageCopy.positive) / 100;
            averageCopy.negative = (averageCopy.value * averageCopy.negative) / 100;
            averageCopy.normal = (averageCopy.value * averageCopy.normal) / 100;
            sums.push(averageCopy);
        }

        function getEmptySentiments() {
            return {
                chart: [],
                range: [],
                averages: [],
                max: 100,
                error: null
            }
        }

        function getExamplePointsForTrends(examples, trends) {
            var datapoints_by_trend = [],
                example_points = {},
                last_letter = '';

            _.each(trends, function(trend) {
                var term = trend.term;
                term.series = trend.series;
                datapoints_by_trend.push(term);
            });

            _.each(examples, function(example) {
                var create_point = false;
                var trend = c.getTrend(datapoints_by_trend, example.keyword);
                example.trend_label = util.getTermDisplay(trend);
                example.trend_text = trend.text;
                example.withTermContent = true;
                if (example.letter != last_letter) {
                    last_letter = example.letter;
                    create_point = true;
                }

                if (create_point) {
                    var example_point = {
                        trend_text: trend.text,
                        trend_id: trend.id,
                        letter: example.letter,
                        key: example.date,
                        sentiment: example.sentiment,
                        display: example.trend_label
                    };
                    var datapoint = _.find(trend.series, {
                        "key": example.date
                    });

                    if (datapoint) {
                        if (!example_points.hasOwnProperty(trend.text)) {
                            example_points[trend.text] = [];
                        }
                        example_point.value = datapoint.value;
                        example_point.date = datapoint.date;
                        example_points[trend.text].push(example_point);
                    }
                }
            });

            return example_points;
        }

        return {
            getSummary: function(terms, to_normalize) {
              var result = [];
              if (c.isArray(terms)) {
                const raw = latestData;
                const data = to_normalize ? raw.averages : raw.sums;
                result = _.map(terms, function(term) {
                  const summaryRecord = _.find(data, {text: term.text});
                  if (summaryRecord.value == 0) {
                    return {id: term.id, display: term.display, positive: 0, neutral: 0, negative: 0, value: 0};
                  } else {
                    return { id: term.id,
                             display: term.display,
                             positive: summaryRecord.positive / summaryRecord.value,
                             neutral: summaryRecord.normal / summaryRecord.value,
                             negative: summaryRecord.negative / summaryRecord.value,
                             value: summaryRecord.value };
                  }
                });
              }
              return result;
            },
            getValuesByDateTable: function(terms, format, to_normalize) {
                var result = [];
                if (c.isArray(terms)) {
                    var sources = [],
                        targets = [],
                        map = {};
                    // TODO Add support to the parallel querying with different parameters
                    var raw = latestData;
                    if (c.is(raw) && c.isArray(raw.chart)) {
                        _.each(raw.chart, function(t) {
                            if (c.is(t.term) && c.isArray(t.series)) {
                                var id = (c.isNumber(t.term.id) && t.term.id < 0) ? t.term.text : t.term.id.toString();
                                sources.push(id);
                                _.each(t.series, function(column) {
                                    c.setDatedValueByIdAndKey(map, column.date, false,
                                        c.isNumber(column.pos) ? column.pos : 0, id, 'p');
                                    c.setDatedValueByIdAndKey(map, column.date, false,
                                        c.isNumber(column.neu) ? column.neu : 0, id, 'r');
                                    c.setDatedValueByIdAndKey(map, column.date, false,
                                        c.isNumber(column.neg) ? column.neg : 0, id, 'n');
                                    c.setDatedValueByIdAndKey(map, column.date, false,
                                        c.isNumber(column.value) ? (to_normalize ? column.value : parseInt(column.value)) : 0, id, 'v');
                                });
                                var summaryRecord = _.find(to_normalize ? raw.averages : raw.sums,
                                                           function(entry){
                                                             return entry.id.toString() == id || entry.text == id;
                                                           });
                                var fieldName = to_normalize ? 'Average' : 'Total';
                                if(!map.hasOwnProperty(fieldName)){
                                    map[fieldName] = {};
                                }
                                if(summaryRecord.value == 0){
                                    map[fieldName][id] = {p:0, r:0, n:0, v:0};
                                } else {
                                    map[fieldName][id] = {
                                        p: 100 * summaryRecord.positive / summaryRecord.value,
                                        r: 100 * summaryRecord.normal / summaryRecord.value,
                                        n: 100 * summaryRecord.negative / summaryRecord.value,
                                        v: to_normalize ? summaryRecord.value : parseInt(summaryRecord.value)
                                   };
                                }
                            }
                        });
                        _.each(terms, function(target) {
                            if (c.isNumber(target.id)) {
                                if (target.id > 0) {
                                    targets.push(target.id.toString());
                                }else {
                                  targets.push(target.text);
                                }
                            } else {
                                if (target.id != null) {
                                    targets.push(target.id.toString());
                                }

                            }
                        });
                        _.each(map, function(entry, key) {
                            var keyFormat = key == 'Average' || key == 'Total' ? 'text' : 'date';
                            var row = [format(key, keyFormat)];
                            _.each(targets, function(target) {
                                if (c.is(entry[target])) {
                                    row.push(format(entry[target]['p'] / 100, 'percent'),
                                        format(entry[target]['r'] / 100, 'percent'),
                                        format(entry[target]['n'] / 100, 'percent'),
                                        format(entry[target]['v'], to_normalize ? 'numeric' : 'integer'));
                                } else {
                                    row.push(format(0, 'percent'),
                                        format(0, 'percent'),
                                        format(0, 'percent'),
                                        format(0, to_normalize ? 'numeric' : 'integer'));
                                }
                            });
                            result.push(row);
                        });
                    }
                }
                return result;
            },

            getSentiments: function(cp, noExamples) {
                let result = getEmptySentiments();
                let termMap = {};
                let parameters = baseInsightsService.buildParams(termMap, cp);
                let foundTerms = {};
                const fbSentiment = cp.channel === "facebook" ||
                                    (abiPermissions.hasPermission("facebook channel") &&
                                     !abiPermissions.hasPermission("web channel"));
                if (_.isEmpty(termMap)) return Promise.reject("No Terms");
                parameters.to_normalize = cp.to_normalize;
                if (!noExamples) {
                    parameters.examples_mode = 'sentiment'; //TODO - needed?
                }
                parameters.sentiment = true;
                parameters.fb_sentiment = fbSentiment;
                if (fbSentiment) {
                    const singaporeGeos = [geoService.STATES.Singapore.id]
                    parameters.geo = singaporeGeos;
                    parameters.origins = singaporeGeos;
                }
                parameters.apply_smoothing = util.shouldApplySmoothing();

                return baseInsightsService.postAsyncData(API, parameters)
                    .cancellableThen(function(response) {
                        var dateMap = {},
                            maxValue = 0,
                            trends = [],
                            averages = [],
                            sums = [];
                        _.each(response.data, function(entry, trendName) {
                            if (c.isString(trendName)) {
                                var term = termMap[trendName] || _.find(termMap, ['id', parseInt(trendName)]);
                                if (c.is(term)) {
                                    foundTerms[term.text] = term;
                                    var termMax = 0;
                                    var average = {
                                        id: term.id,
                                        label: term.text,
                                        value: 0,
                                        positive: 0,
                                        normal: 0,
                                        negative: 0,
                                        count: 0,
                                        class: term.class,
                                        description: {
                                            positive: '%',
                                            normal: '%',
                                            negative: '%'
                                        }
                                    };
                                    var values = [];
                                    if (c.isArray(entry['datapoints'])) {
                                        _.each(entry['datapoints'], function(point, index) {
                                            if (validateDataPoint(point)) {
                                                if (point.value > 0 || index != 0) {
                                                    values.push(point);
                                                    if (point.value > 0) {
                                                        average.value += point.value;
                                                        ++average.count;
                                                    }
                                                    maxValue = (point.value > maxValue) ? point.value : maxValue;
                                                    termMax = (point.value > termMax) ? point.value : termMax;
                                                    dateMap[point.key] = point.date;
                                                }
                                            }
                                        });
                                    }
                                    average.positive = entry['avg_pos'];
                                    average.negative = entry['avg_neg'];
                                    average.normal = entry['avg_neu'];
                                    average.net_sentiment = entry['net_sentiment'];
                                    calculateSums(average, sums);
                                    if (values.length > 0) {
                                        average.value = average.value / values.length;
                                        average.total = average.positive + average.negative + average.normal
                                        const factor = average.value / average.total;
                                        calculateAverage(average, 'normal', factor);
                                        calculateAverage(average, 'positive', factor);
                                        calculateAverage(average, 'negative', factor);
                                    }
                                    averages.push(average);
                                    trends.push({
                                        term: term,
                                        series: values,
                                        max: termMax
                                    });
                                }
                            }
                        });
                        _.each(dateMap, (date) => result.range.push(date));
                        c.sortByNumeric(trends, 'max', false);
                        c.sortByNumeric(averages, 'value', true);
                        result.examples = response.data.abi_sentiment_examples;
                        _.each(result.examples, function (example) {
                            example.icon = c.getChannelIcon(parameters.channel, example.date);
                        });
                        delete response.data.abi_sentiment_examples;
                        var example_points = getExamplePointsForTrends(result.examples, trends);
                        _.each(trends, function(trend, i) {
                            if (example_points.hasOwnProperty(trend.term.text)) {
                                trends[i].examples = example_points[trend.term.text];
                            }
                        });
                        result.chart = trends;
                        result.averages = averages;
                        result.max = maxValue;
                        result.maxChart = parameters.to_normalize ? maxValue : _.max(_.map(sums, 'value'));
                        result.sums = sums;
                        var insufficient = isResponseDataEmpty(result, _.difference(Object.keys(termMap),Object.keys(foundTerms)));
                        latestData = result;
                        baseInsightsService.notifyInsufficient(insufficient);
                            if (insufficient.length == Object.keys(termMap).length) {
                                return Promise.reject({});
                            }
                        return latestData;
                    }).cancellableCatch(baseInsightsService.handleError);
            },

            getEmptySentiments: getEmptySentiments,
            getSentimentExamplesForExport: function(terms, format) {
                var result = [];
                _.each(latestData.examples, function(example, key) {
                    var exampleTrend = c.getTrend(terms, example.keyword);
                    var seed = exampleTrend.display;
                    var sentiment = '';
                    switch (example.sentiment) {
                        case 'pos':
                            sentiment = 'positive';
                            break;
                        case 'neg':
                            sentiment = 'negative';
                            break;
                        case 'neu':
                            sentiment = 'neutral';
                            break;
                    }
                    var row = [format(example.date.substr(0, 10), 'date'), format(seed, 'text'), format(sentiment, 'text'), format(example.url, 'url')];
                    result.push(row);
                });
                return result;
            }
        }
    }
  ]
);