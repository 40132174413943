import * as MixpanelInterests from '../../../react/infra/mixpanel/MixpanelInterests';

module.exports = angular.module(__filename, [
    require("../../am-switch-button/am-switch-button").name
]).directive("bubblesActionsBar", ['context', function (context) {
    return {
        restrict: "E",
        scope: {
            onKeyTrendsToggle: "="
        },
        template: require("./bubbles-actions-bar.html"),
        compile: function () {
            return {
                pre: function ($scope) {
                    $scope.isShowVelocity = false;
                    $scope.isRisingOnly = false;
                    $scope.isKeyTrends = context.current.keyTrends;

                    $scope.onRisingOnlyToggle = () => {
                        $scope.isRisingOnly = !$scope.isRisingOnly;
                        $scope.$root.$broadcast('bubbles-rising-only-toggle', $scope.isRisingOnly);
                    };

                    $scope.onShowVelocityToggle = () => {
                        $scope.isShowVelocity = !$scope.isShowVelocity;
                        if ($scope.isShowVelocity) {
                            $scope.$root.$broadcast('bubbles-on-lifestyle-change', null);
                            $scope.$apply();
                        }
                        $scope.$root.$broadcast('bubbles-show-velocity-toggle', $scope.isShowVelocity);
                        $scope.$apply();
                    };

                    $scope.onKeyTrendsToggleHandler = () => {
                        MixpanelInterests.trackKeyTrendsToggle();
                        $scope.isKeyTrends = !$scope.isKeyTrends;
                        $scope.onKeyTrendsToggle($scope.isKeyTrends);
                        $scope.$apply();
                    }

                    const unregisterBubblesOnLifestyleChange = $scope.$root.$on('bubbles-on-lifestyle-change', function(event, lifestyle) {
                        if (lifestyle && $scope.isShowVelocity) {
                            $scope.isShowVelocity = false;
                        }
                    });
                    $scope.$on('$destroy', function() {
                        unregisterBubblesOnLifestyleChange();
                    });
                }
            }
        }
    }
}]);
