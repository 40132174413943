import React, { useCallback, useEffect, useState, useRef } from 'react';
import { FormControl, Icon, Input, Option, Select } from '@amobee/component-library';
import { debounce, every, filter, hasIn, isEmpty, join, map, some, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SelectorAccordion from './SelectorAccordion/SelectorAccordion';
import './MultiLevelTableSelector.scss';
import { getUpdatedTree } from '../../../utils/MultiLevelTableUtils';

const stringsToObjectValues = (names) => names.map((name, index) => ({ label: name, value: name, index }));

const getJoinedNames = (names) =>
  names.length > 1 ? names.slice(0, names.length - 1).join(', ') + ` and ${names.slice(-1)[0]}` : names[0];

const getPlaceholder = (name, allNames) => {
  const levelName = allNames.length <= 1 ? 'All' : name === 'All' ? getJoinedNames(allNames) : name;
  return `Search ${levelName}`;
};

const getSearchMatchResult = (searchMatchArr, key) => (
  <div key={key}>
    {searchMatchArr.map((item, index) => (
      <span
        className={classNames('search-filtered', {
          'search-match-text': item.match,
        })}
        key={key + index}
      >
        {item.value}
      </span>
    ))}
  </div>
);

// Convert/Prepare string value to array of "matched/not matched" objects with string value for search match styling
const strToSearchMatchArr = (originalStr, searchVal) => {
  let str = originalStr;
  const resultArr = [];
  const getMatchIndex = (currStr) => currStr.toLowerCase().indexOf(searchVal.toLowerCase());

  if (originalStr && getMatchIndex(str) !== -1) {
    let match = true;

    while (match) {
      const matchIdx = getMatchIndex(str);

      if (matchIdx > 0) {
        resultArr.push({
          match: false,
          value: str.slice(0, matchIdx),
        });
        str = str.slice(matchIdx);
      }
      resultArr.push({
        match: true,
        value: str.slice(0, searchVal.length),
      });
      str = str.slice(searchVal.length);
      match = getMatchIndex(str) !== -1;
    }
    if (str.length) {
      resultArr.push({
        match: false,
        value: str,
      });
    }
  } else {
    resultArr.push({
      match: false,
      value: str,
    });
  }
  return resultArr;
};

const areAllChildrenSensitive = (children = []) => children.length && every(children, 'sensitive');

const areSomeChildrenSelected = (children = []) => {
  if (isEmpty(children)) return false;
  const checkedAmount = filter(children, 'selected').length;
  // if no children are selected, check if there are indeterminate children
  return checkedAmount > 0
    ? children.length !== checkedAmount
    : some(children, (child) => areSomeChildrenSelected(child.children));
};

const MultiLevelTableSelector = ({ levelNames, tableData, onChange, listLimit, disabled, showLevelSelector }) => {
  // 'All' is a default value added to provided names list
  const allLevelNames = stringsToObjectValues(['All', ...levelNames]);

  const [tableDataValue, setTableDataValue] = useState(tableData);
  const [searchValue, setSearchValue] = useState('');
  const [levelValue, setLevelValue] = useState(allLevelNames[1]);
  const [selectDataValue, setSelectDataValue] = useState({});
  const [placeholderValue, setPlaceholderValue] = useState(getPlaceholder(levelNames[0], levelNames));
  const [accordionElements, setAccordionElements] = useState([]);
  const [scrollStep, setScrollStep] = useState(1);
  const containerRef = useRef();
  const showSelectAll = levelNames.length === 1;

  const getUniqKey = (data) => join([...(data.parent_ids || []), data.value, data.label], ',');

  const populateAccordion = (items, searchText, levelName = levelValue.value, increaseLimit = false) => {
    let rowToRender = increaseLimit ? scrollStep * listLimit : listLimit;
    setScrollStep(scrollStep + 1);
    const indexOfLevelToShow = levelNames.indexOf(levelName);

    function getRecursionAccordionElements(dataArray, parentName = '', level = 0, isParentDisplayed = false) {
      let elements = [];

      if (level < indexOfLevelToShow) {
        dataArray = sortBy(dataArray, ({ selected }) => !selected);
      }
      dataArray.forEach((data) => {
        let innerChildren = [];
        let searchMatchResult = '';
        const isSearchAll = levelName === 'All' && searchText;
        const secondaryText = isParentDisplayed ? '' : parentName ? `${parentName}, ${data.label}` : data.label;
        let isShown = false;

        if (searchText && (level === indexOfLevelToShow || levelName === 'All')) {
          // Provide a search match array in next structure (consider 'se' to be search text and 'Search' the original text):
          // [{ match: true, value: 'Se' }, { match: false, value: 'arch' }]
          const searchMatchArr = strToSearchMatchArr(data.label, searchText);
          if (some(searchMatchArr, 'match')) {
            searchMatchResult = getSearchMatchResult(searchMatchArr, getUniqKey(data));
          }
        }
        if (!isEmpty(data.children)) {
          // Process the children data to include as html elements to parent element
          const isCurrentDisplayed =
            isParentDisplayed ||
            (!searchText && level >= indexOfLevelToShow) ||
            (!!searchMatchResult && levelName !== 'All');
          innerChildren = getRecursionAccordionElements(data.children, secondaryText, level + 1, isCurrentDisplayed);
        }
        // Show element if it has search matches or no search text provided
        if (
          (isParentDisplayed || searchMatchResult || !searchText) &&
          level >= indexOfLevelToShow &&
          elements.length <= rowToRender
        ) {
          const hasToggle = !isSearchAll && !!data.children;
          const indeterminate = areSomeChildrenSelected(data.children);
          const showWarning = data.sensitive || areAllChildrenSensitive(data.children);
          const rightContent = showWarning ? <Icon name="StatusWarning" color="#CAA900" /> : '';
          isShown = true;

          const newElement = (
            <SelectorAccordion
              label={searchMatchResult || data.label}
              key={getUniqKey(data)}
              isChecked={data.selected}
              isCheckboxIndeterminate={indeterminate}
              onCheck={(checked) => setSelectDataValue({ ...data, selected: checked })}
              hasToggle={hasToggle}
              content={innerChildren}
              secondaryText={isParentDisplayed ? '' : parentName}
              rightContent={rightContent}
              hasExtraMargin={showSelectAll}
            />
          );
          // Display episode without season directly under show- PHOB-21
          const childElement = data.isSeason !== undefined && !data.isSeason ? innerChildren : newElement;
          if (searchText && data.label.toLowerCase().startsWith(searchText.toLowerCase())) {
            elements.unshift(childElement);
          } else {
            elements.push(childElement);
          }
        }
        if (!isShown || isSearchAll) {
          elements = [...elements, ...innerChildren];
        }
      });

      return elements;
    }

    // If search text is empty we process only limited list to omit redundancy
    const result = getRecursionAccordionElements(searchText ? items : items.slice(0, rowToRender));
    setAccordionElements([...result.slice(0, rowToRender)]);
  };

  const onSelectChange = (e) => {
    const value = hasIn(e, 'target.value') ? e.target.value : e.value || e;
    setPlaceholderValue(getPlaceholder(value, levelNames));
    setLevelValue(value);
    setSearchValue('');
  };

  const handleScroll = () => {
    const container = containerRef.current;
    if (container.scrollTop + container.clientHeight >= container.scrollHeight * 0.95) {
      if (tableDataValue.length > listLimit * scrollStep) {
        populateAccordion(tableDataValue, searchValue, levelValue.value || levelValue, true);
      }
    }
  };

  const getDebouncePopulateAccordion = (time) => debounce(populateAccordion, time);

  // The renderAccordion debounce is required to omit redundant heavy calculations
  const renderAccordion = useCallback(getDebouncePopulateAccordion(10), []);
  const searchTreeDebounce = useCallback(getDebouncePopulateAccordion(200), []);
  const onSearchChange = (e) => setSearchValue(e?.target?.value || e?.value);

  useEffect(() => {
    containerRef.current.addEventListener('scroll', handleScroll);
    return () => {
      containerRef.current.removeEventListener('scroll', handleScroll);
    };
  }, [scrollStep, searchValue]);

  useEffect(() => renderAccordion(tableDataValue, searchValue, levelValue.value || levelValue), [
    levelValue,
    tableDataValue,
  ]);
  useEffect(() => searchTreeDebounce(tableDataValue, searchValue, levelValue.value || levelValue), [searchValue]);

  useEffect(() => setTableDataValue(tableData), [tableData]);

  useEffect(() => {
    if (!isEmpty(selectDataValue)) {
      let updatedData = [];
      if (selectDataValue.isSelectAll) {
        updatedData = map(tableDataValue, (item) => ({ ...item, selected: selectDataValue.selected }));
      } else {
        updatedData = getUpdatedTree(tableDataValue, selectDataValue.selected, selectDataValue);
      }
      setTableDataValue([...updatedData]);
      // Provide updated data to onChange handler
      onChange(updatedData, selectDataValue);
    }
  }, [selectDataValue]);

  return (
    <div className={classNames('selector-wrapper', { disabled })}>
      <div className="selector-header">
        <Icon name="Search" className="search-icon" />
        <FormControl className="search-control">
          {showLevelSelector && levelNames.length > 1 && (
            <Select initialValue={levelValue} onChange={onSelectChange} irreversible>
              {allLevelNames.map(({ value, label }) => (
                <Option key={value} label={label} value={value} />
              ))}
            </Select>
          )}
          <Input
            className="selector-search"
            placeholder={placeholderValue}
            value={searchValue}
            onChange={onSearchChange}
            variant="borderless"
          />
        </FormControl>
      </div>
      <div className="selector-body" ref={containerRef}>
        {showSelectAll && (
          <SelectorAccordion
            label={levelNames[0]}
            key={levelNames[0]}
            isChecked={every(tableDataValue, 'selected')}
            onCheck={(selected) => setSelectDataValue({ isSelectAll: true, selected })}
            hasToggle={false}
            isSelectAll
          />
        )}
        {accordionElements}
      </div>
      {!tableData.length && <h4 className="no-content-message">No content to display.</h4>}
    </div>
  );
};

MultiLevelTableSelector.propTypes = {
  levelNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  tableData: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  listLimit: PropTypes.number,
  disabled: PropTypes.bool,
  showLevelSelector: PropTypes.bool,
};

MultiLevelTableSelector.defaultProps = {
  levelNames: [],
  tableData: [],
  listLimit: 100,
  disabled: false,
  showLevelSelector: true,
};

export default MultiLevelTableSelector;
