import React from 'react';
import PropTypes from 'prop-types';
import { convertToArray } from '../../../utils/ArrayUtils';

class WindowEventListener extends React.Component {
  componentDidMount() {
    convertToArray(this.props.events).forEach((event) =>
      window.addEventListener(event, this.props.eventHandlerFunction)
    );
  }

  componentWillUnmount() {
    convertToArray(this.props.events).forEach((event) =>
      window.removeEventListener(event, this.props.eventHandlerFunction)
    );
  }

  render() {
    return null;
  }
}

WindowEventListener.propTypes = {
  events: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  eventHandlerFunction: PropTypes.func.isRequired,
};

export default WindowEventListener;
