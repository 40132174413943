import * as MixpanelInsights from '../../react/infra/mixpanel/MixpanelInsights';

const consumptionIndex = require("../../widgets/consumption-index/consumption-index-widget"),
      topicTrends = require("../../widgets/topic-trends/topic-trends-widget"),
      sentiments = require("../../widgets/sentiment-index/sentiment-widget"),
      topAssociationIndex = require("../../widgets/top-association/top-association-widget"),
      timingWidget = require("../../widgets/timing-widget/timing-widget"),
      landscapeComponent = require("../../widgets/landscape-widget/landscape-widget"),
      context = require('infra/context/context.js'),
      keywordSuggesterModule = require('data/keyword_suggester.srv.js'),
      barChart = require('common/bar-chart.drv/bar-chart.drv.js'),
      channelsMold = require("infra/context/filters/insightsChannels-mold"),
      examplesDataModel = require("../../data/insights/examples-data-model"),
      c = require("infra/utils/common"),
      $ = require("jquery");

const insightsModule = angular.module(__filename, [
    consumptionIndex.name,
    topicTrends.name,
    sentiments.name,
    topAssociationIndex.name,
    timingWidget.name,
    keywordSuggesterModule.name,
    context.name,
    barChart.name,
    channelsMold.name,
    examplesDataModel.name,
    landscapeComponent.name,
    require("data/insights/insights-export-service").name,
    require("../../widgets/referrals-widget/referrals-widget").name,
    require("../../widgets/consumption-channel/consumption-channel-widget").name,
    require("../../widgets/insights-geo-widget/insights-regions-widget").name
]);

ConsumptionController.$inject = ['$scope', 'context', '$state', 'geoService', 'abiPermissions',
                                 '$timeout', 'examplesDataModel', 'notificator', 'insightsExportService', '$rootScope',
                                 'baseInsightsService', 'TIMES'];
function ConsumptionController($scope, context, $state, geoService, abiPermissions, $timeout,
                               examplesDataModel, notificator, insightsExportService, $rootScope, baseInsightsService,
                               TIMES) {
    const self = this;
    const INSUFFICIENT_DATA_MESSAGE = "Sorry, insufficient content about <seed>. Please try more general interests or filters.";
    $scope.abiPermissions = abiPermissions;
    $scope.sgWebSourceFilter = $rootScope.sgWebSourceFilter;

    $scope.hasPermissions = function(channels) {
        let result = false;
        _.forEach(channels, function(value) {
            result = $scope.abiPermissions.hasPermission(value);
            if (result) {
                return false;
            }
        });
        return result;
    };

    $scope.context = context;
    $scope.examplesData = examplesDataModel;
    $scope.letterClicked = '';
    insightsExportService.clear();
    $rootScope.$broadcast('timeframe-vars-update', null, TIMES.INSIGHTS_OFFSET, false);

    $rootScope.Geos.setSubGeos($scope, () => context.current._insightsSubGeos_mold, c.getChannels($state, context), $state.current.name);

    $scope.supportedLanguages = () => context.current._language_mold.supportedLanguages($state, context);
    $scope.isSupportLanguage = () => context.current._language_mold.isSupportLanguage($state, context);
    $scope.shouldShowTopics = () => context.current._language_mold.shouldShowTopics($state, context);
    $scope.isResolveKeywords = () => context.current._language_mold.isResolveKeywords($state, context);

    $scope.tabsClicked = function (tab) {
        insightsExportService.clear();
        $scope.insightsError = false;
        context.current._language_mold.getLanguage({current:{name: "insights."+tab}}, context);
        setTimeout(() => {
            $state.go('^.' + tab);
            $scope.setBlankScreen(tab);
        }, 99);
    };
    let closeListener = context.onChange(MixpanelInsights.trackInsightsSearch);
    const landscapeChannelListener = $scope.$on('landscapeChannelChanged', function(event, landscapeChannel) {
        $scope.insightsChannelLandscape = landscapeChannel;
    });

    $scope.$on('$destroy', () => {
        closeListener();
        landscapeChannelListener();
    });

    $scope.shouldDisplayTab = (channels, rootScopeField) => {
        return !channels || (_.includes(channels, context.current.insightsChannels?.value) &&
                            _.includes(_.map($rootScope[rootScopeField], 'value'), context.current.insightsChannels?.value)) ;
    };

    $scope.setBlankScreen = function(tab) {
        if (tab === undefined) {
            tab = $state.current.name.split('.')[1];
        }
        if (tab === 'landscape') {
            $scope.blankScreenMsg = "Type in few brands and define the landscape KPIs to see how they benchmark against each other.";
            $scope.blankScreenImage = "zero_state_insights_landscape.svg";
        } else {
            $scope.blankScreenMsg = "Type in an interest to analyze its consumption drivers.";
            $scope.blankScreenImage = "zero_state_insights.svg";
        }
   };

    $scope.setBlankScreen();

    $scope.trackFilterChange = MixpanelInsights.trackInsightsSearchChange;

    $scope.areSocialTermsDisabled = function() {
        if ($state.includes('*.landscape')) {
            return $scope.insightsChannelLandscape?.value !== 'facebook';
        }
        return context.current.insightsChannels?.value !== 'facebook' || $state.includes('*.channels');
    };

    $scope.getChannelsFilter = function() {
        let channels;
        if ($state.includes('*.association')) {
          channels = $rootScope.insightsAssociationsChannelsFilter;
        } else if ($state.includes('*.regions')) {
          channels = $rootScope.regionsInsightsChannelsFilter;
        } else if ($state.includes('*.timing')) {
          channels = $rootScope.insightsTimingChannelsFilter;
        } else if ($state.includes('*.channels')) {
          channels = $rootScope.insightsReferralsChannelsFilter;
        } else if ($state.includes('*.topic')) {
          channels = $rootScope.insightsTopicsChannelsFilter;
        } else {
          channels = $rootScope.insightsChannelsFilter;
        }

        return channels;
    };

    $scope.arePostsDisabled = () => $state.includes('*.association');
    $scope.timeframeHasDatesMoreThenMonth = () => c.hasDatesOlderThanOneMonth(context.current.insights_timeframe);

    $scope.showBlankScreen = function (insufficient) {
        $scope.blankScreenMsg = '';
        return $scope.insightsError;
    };


    $scope.getGeoAllNoneSelectedText = function () {
        const channels = c.getChannels($state, context);
        return $state.includes('*.channels') &&
               (_.includes(channels, 'sg_telco') || _.includes(channels, 'sg_bidstream') || _.includes(channels, 'bidstream')) &&
               $rootScope.webConsumptionSource === "source" ? "Singapore" : "All Geos";
    };

    $scope.insightsGeos = function () {
        if ($state.includes('*.landscape') && abiPermissions.hasPermission('sg telco channel') &&
           !abiPermissions.hasPermission('web channel')) {
            return [geoService.STATES.Singapore];
        }
        return geoService.geosForChannel(geoService.geos, $state, context);
    };

    $scope.$watch('context.current.insightsChannels', function (newChannel, oldChannel) {
        $rootScope.Geos.setSubGeos($scope, () => context.current._insightsSubGeos_mold, c.getChannels($state, context), $state.current.name);
        if(!newChannel) return;

        const isOldChannelNotBelongToInsightsChannels = !_.includes($scope.getChannelsFilter().map(channel => channel.value), oldChannel.value);

        if(newChannel.value != oldChannel.value && isOldChannelNotBelongToInsightsChannels) {
            if(context.current.showCustomSources || context.current.bubblesShowCustomSources) {
                context.current.showCustomSources = false;
                context.current.bubblesShowCustomSources = false;
                notificator.notify({body: `Channel has switched to ${newChannel.label} as custom sources is not supported for this insight`});
            } else{
                notificator.notify({body: `Channel has switched to ${newChannel.label} as ${oldChannel.label} is not supported for this insight`});
            }
        }

        syncChannelsFilter();
    });

    // not sure works..
    $scope.$watch('context.current.regionsInsightsChannels', function () {
        $rootScope.Geos.setSubGeos($scope, () => context.current._insightsSubGeos_mold, c.getChannels($state, context), $state.current.name);
    });

    $scope.insufficientNotification = function (seeds) {
        notificator.notify({body: INSUFFICIENT_DATA_MESSAGE.replace("<seed>", seeds.join(", "))});
    };

    $scope.$on('contentDriversPaneToggled', function (event, filter) {
        $timeout(function () {
            _.each($scope.examplesData.visible_examples, function (example) {
                if (self.compareDataWithFilter(example, filter)) {
                    example.hide = !filter.show;
                }
            });
        }, 0);
    });

    $scope.$on('chartCircleMouseover', function (event, letter) {
        self.switchContentDriverAttrsByLetter(letter, "highlight", true);
    });

    $scope.$on('chartCircleMouseout', function (event, letter) {
        if ($scope.letterClicked !== letter) {
            self.switchContentDriverAttrsByLetter(letter, "highlight", false);
        }
    });

    $scope.$on('chartCircleClick', function (event, data) {
        self.switchContentDriverAttrsByLetter($scope.letterClicked, ["highlight", "selected"], false);
        $scope.letterClicked = data.letter;
        $scope.$broadcast('resetHighlightedCirclesExceptClicked');
        self.switchContentDriverAttrsByLetter($scope.letterClicked, ["highlight", "selected"], true);
        $scope.examplesData.open = true;

        _.each($scope.examplesData.filters, function (filter) {
            if (self.compareDataWithFilter(filter, data) || $scope.examplesData.filter_type == 'sentiment') {
                filter.show = true;
            }
        });
        _.each($scope.examplesData.visible_examples, function (example) {
            if (self.compareDataWithFilter(example, data) || $scope.examplesData.filter_type == 'sentiment') {
                example.hide = false;
            }
        });

        $scope.$root.$broadcast('openContentDrivers', "content_drivers");

        $timeout(function () {
            var contentWindow = $(".content");
            var currentArticle = $(".content-driver[letter=" + data.letter + "]").first();
            if(currentArticle.position() && contentWindow.position()) {
                contentWindow.animate({scrollTop: contentWindow.scrollTop() + (currentArticle.position().top - contentWindow.position().top)}, '30');
            }
        }, 10);
    });

    $scope.onWidgetPanelClick = function () {
        if ($scope.examplesData.visible) {
            self.switchContentDriverAttrsByLetter($scope.letterClicked, ["highlight", "selected"], false);
            $scope.letterClicked = '';
            $scope.$broadcast('widgetPanelClick');
        }
    };

    $scope.onContentDriverMouseover = function (letter, index) {
        if ($scope.examplesData.alphabetized) {
            self.switchContentDriverAttrsByLetter(letter, "highlight", true);
            $scope.$broadcast('contentDriverMouseover', letter);
        } else {
            self.switchContentDriverAttrsByIndex(index, "selected", true);
        }
    };

    $scope.onContentDriverMouseout = function (letter, index) {
        if ($scope.examplesData.alphabetized) {
            if ($scope.letterClicked != letter) {
                self.switchContentDriverAttrsByLetter(letter, "highlight", false);
                $scope.$broadcast('contentDriverMouseout', letter);
            }
        } else {
            self.switchContentDriverAttrsByIndex(index, "selected", false);
        }
    };

    $scope.onContentDriverClick = function (letter) {
        if ($scope.examplesData.alphabetized) {
            self.switchContentDriverAttrsByLetter($scope.letterClicked, ["highlight", "selected"], false);
            $scope.letterClicked = letter;
            $scope.$broadcast('resetHighlightedCirclesExceptClicked');
            self.switchContentDriverAttrsByLetter($scope.letterClicked, ["highlight", "selected"], true);
            $scope.$broadcast('contentDriverClick', letter);
        }
    };

    $scope.showAudienceFilter = function () {
        const channels = c.getChannels($state, context);
        return baseInsightsService.shouldSendAudience(context.current.insightsChannels?.value, abiPermissions) &&
               ($state.includes('*.seasonality') || $state.includes('*.association') || $state.includes('*.topic') ||
                ($state.includes('*.channels') &&
                 !((_.includes(channels, 'sg_telco') || _.includes(channels, 'sg_bidstream')) &&
                   $scope.$root.webConsumptionSource === "source"))) &&
               $scope.isResolveKeywords();
    };

    $scope.hideChannels = () => $state.includes('*.landscape');
    $scope.showGeoFilter = () => context.current.insightsChannels?.value !== 'sg_bidstream';

    $scope.showSgWebSourceFilter = function() {
        return $state.includes('*.landscape') &&
               (_.some($scope.context.current.geo, {cc: "SG"}) ||
                (_.isEmpty($scope.context.current.geo) && _.some($scope.insightsGeos(), {cc: "SG"}))) &&
               abiPermissions.hasPermission('web channel') && abiPermissions.hasPermission('sg telco channel');
    };

    $scope.getVisibleExamples = function() {
        return _.uniqBy($scope.examplesData.visible_examples, (e) => e.url.toLowerCase() + ($scope.examplesData.alphabetized ? e.letter : ""));
    };

    $scope.$on('contentDriversPaneOpenByClick', function (event) {
        $scope.$broadcast('contentDriversPaneOpen');
    });

    $scope.$on('resetLetterClicked', function (event) {
        $scope.letterClicked = '';
    });

    $scope.$on('insightsError', function (event, error) {
        $scope.insightsError = true;
        $scope.showBlankScreen(error);
        insightsExportService.clear();
    });

    $scope.$on('insightsInsufficientData', function (event, data) {
        if (data && !_.isEmpty(data)) {
            $scope.insufficientNotification(data);
        }
    });

    function handleInsightsChange(event, newVals, old) {
        let terms = [];
        angular.copy(context.current.terms, terms);
        terms = _.filter(terms, function (t) {
            return !$scope.arePostsDisabled() || t.type !== 'post';
        });
        if (terms.length === 0) {
            $scope.setBlankScreen();
            $scope.insightsError = true;
            insightsExportService.clear();
        } else {
            $scope.insightsError = false;
        }
    }

    function syncChannelsFilter() {
        const newChannel = context.current._insightsChannels_mold._value;
        context.current.lastSelected = _.findIndex($rootScope.bubblesChannelsFilter, {value: newChannel.value});
        context.current._bubblesChannels_mold._value = newChannel;
        context.current.gridChannels = newChannel;
        context.current.channels =  [newChannel];
    }


    let listenerTimeframe = $scope.$root.$on('context-updated', handleInsightsChange);
    let refreshViewListener = $rootScope.$on('refresh-view', function (event) {
        context.cacheBaster = Date.now();
    });

    $scope.$on('$destroy', listenerTimeframe);
    $scope.$on('$destroy', refreshViewListener);

    this.switchContentDriverAttrsByLetter = function (letter, attrs, value) {
        $timeout(function () {
            if (!c.isArray(attrs)) attrs = [attrs];
            _.each($scope.examplesData.visible_examples, function (example) {
                if (example.letter === letter) {
                    _.each(attrs, function (attr) {
                        example[attr] = value;
                    });
                }
            });
        }, 0);
    };

    this.switchContentDriverAttrsByIndex = function (index, attrs, value) {
        $timeout(function () {
            if (!c.isArray(attrs)) attrs = [attrs];
            _.each(attrs, (attr) => $scope.examplesData.visible_examples[index][attr] = value);
        }, 0);
    };

    $scope.$state = $state;

    this.compareDataWithFilter = (data, filter) =>
        ($scope.examplesData.filter_type === 'sentiment' && data.sentiment === filter.sentiment) ||
        ($scope.examplesData.filter_type === 'trend' && data.class === filter.class);

    $scope.$watch("context.current._insightsSubGeos_mold._value", (newValue, oldValue) => {
        if($scope.dataTrees?.subGeos?.isAllChecked) {
            context.current.insightsAllSubGeosSelected = $scope.dataTrees.subGeos.isAllChecked();
        }
        if(!_.isEqual(newValue, oldValue)) {
            $scope.trackFilterChange();
        }
    });


    $scope.$watch("firstLevelGeos", (newValue, _oldValue) => {
        if(!newValue) return;

        $scope.context.current.geo = newValue;
    })}

const association = require('./association.insights').stateConfig;
const consumption = require('./seasonality.insights').stateConfig;
const sentiment = require('./sentiment.insights').stateConfig;
const topic = require('./topic.insights').stateConfig;
const channels = require('./channels.insights').stateConfig;
const timing = require('./timing.insights').stateConfig;
const landscape = require('./landscape.insights').stateConfig;
const regions = require('./regions.insights').stateConfig;

insightsModule.stateConfig = {
    name: "insights",
    url: "/insights",
    template: require("./insights.html"),
    display: "Insights",
    context: {
        insights_timeframe: "TimeframeMold"
    },
    data: {
      permissions: ["insights"]
    },
    controller: ConsumptionController,
    redirectTo: ['$state', 'abiPermissions', 'toState', 'toParams', function($state, abiPermissions, toState, toParams) {
      const getPermissions = _.property("data.permissions");
      const firstAuthorizedState = function(states) {
        return _.find(states, function(stateName) {
          const state = $state.get(stateName);
          const permissions = getPermissions(state);
          return _.isUndefined(permissions) || abiPermissions.hasPermission(permissions);
        });
      };

      const tabs = _.map(toState.children, function(s) {return s.name; });
      return { state: firstAuthorizedState(tabs) };
    }],
    children: [
        consumption,
        topic,
        sentiment,
        association,
        channels,
        timing,
        landscape,
        regions
    ]
};

module.exports = insightsModule;
