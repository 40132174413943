import React from 'react';
import './SimpleProgressBar.scss';
import { string } from 'prop-types';

const SimpleProgressBar = ({ progressValue, label }) => (
  <div className="simple-progress-bar-component">
    <div className="bar-container">
      <div className="bar" style={{ width: progressValue + '%' }}></div>
    </div>
    {label && <div className="label">{label}</div>}
  </div>
);

SimpleProgressBar.propTypes = {
  progressValue: string,
  label: string,
};

export default SimpleProgressBar;
