import Cache from './Cache';

const getWithTTL = (key) => {
  const cachedVal = Cache.get(key);
  if (!cachedVal) return null;

  const [ttl, val] = cachedVal;
  if (ttl > Date.now()) return [val, ttl];

  Cache.delete(key);
  return null;
};

export default {
  get: (key) => {
    const valueWithTTL = getWithTTL(key);
    return valueWithTTL && valueWithTTL[0];
  },
  getWithTTL: getWithTTL,
  set: (key, val, ttl) => {
    Cache.set(key, [ttl, val]);
  },
  delete: (key) => Cache.delete(key),
  size: () => Cache.size(),
  clear: () => Cache.clear(),
};
