/*
	handles all dynamic trend editing function between dynamic-trends-edit, dynamic-trends, modalEditTarget files
*/

import config from 'infra/config';

module.exports = {

    init: function(scope, editTargetCtrl, CHART){
        this.editTargetCtrlScope = scope;
        this.editTargetCtrl = editTargetCtrl;
        this.CHART = CHART;
        this.dynamicTempData = {};
        this.origTargetData = this.editTargetCtrlScope.currentTarget;
        this.origQueriesDataArray = {};
    },


    getSeedsData: function(util){

        let query = this.editTargetCtrl.isDynamicTempDataEmpty()? this.editTargetCtrlScope.currentTarget.query : this.dynamicTempData.currentTarget.query; 

        //server bug fix: remove duplicates from returned server:
        let phrases = _.uniq(query.phrases, JSON.stringify),
            phrases_bl = util.removeDuplicates(query.phrases_bl),
            structureArr = [],
            CHART = this.CHART,
            index = -1;

        function getIndex(){
            if(index == CHART.TERM_CLASSES.length){
                index = 0;
            }else{
                index++;
            }
            return index;
        }

        phrases.forEach(function(phrase){
            structureArr.push ({text: phrase, id: query.kwd_ids[phrase.toLowerCase()],  type: "term", class: CHART.TERM_CLASSES[getIndex()]});
        });

        //booleanLogic
        phrases_bl.forEach(function(phrase){
            structureArr.push ({text: phrase.text, display: phrase.text, type: phrase.type, id: phrase.id, class: CHART.TERM_CLASSES[getIndex()]});
        });

        return structureArr;
    },


    getUpdatedQuery: function(){
        return this.editTargetCtrl.isDynamicTempDataEmpty()? this.editTargetCtrlScope.currentTarget.query : this.dynamicTempData.currentTarget.query; 
    },


    resetData: function() {
        this.editTargetCtrlScope.currentTarget = this.origTargetData;
        this.editTargetCtrlScope.queries.dynamic.dataArray = this.origQueriesDataArray;

  		this.dynamicTempData = {};
  		//this.origTargetData = {};
    },

};
