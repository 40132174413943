import config from 'infra/config';

const CONCEPT_KEY = 'phrases';
const CONCEPTS_BL_KEY = 'phrases_bl';
const date = require("infra/utils/date");

module.exports = require("angular").module(__filename, []).service('GridDataLayer', ['topicsTree', 'geoService', 'CHANNEL', '$state', 'context', 'abiPermissions', 'APP_NAME',
    function (topicsTree, geoService, CHANNEL , $state, context, permissions, APP_NAME) {

        function getTimeframeDates(timeframe) {
            let FORMAT = timeframe[1]=='hour' ? '' : 'YYYY-MM-DD';
            let parsed_tf = date.getTimeframe(timeframe, true);
            parsed_tf.start = parsed_tf.start.startOf('day').format(FORMAT);
            let endDate = parsed_tf.end.startOf('day').format(FORMAT);
            parsed_tf.end = endDate != parsed_tf.start ? endDate : parsed_tf.end.endOf('day').format(FORMAT);
            return parsed_tf;
        }

        function getTimeframe(timeframe) {
            let [quantity, unit] = timeframe;
            switch (unit) {
                case "day":
                    switch (quantity) {
                        case 1:
                            return "yesterday";
                        case 3:
                            return "half_week";
                        case 7:
                            return "weekly";
                    }
                    break;
                case "week":
                    switch (quantity) {
                        case 1:
                            return "weekly";
                    }
                    break;
                case "month":
                    switch (quantity) {
                        case 1:
                            return "monthly";
                        case 3:
                            return "quarterly";
                    }
                    break;
            }
            return "custom";
        }

        function replaceFieldName(item, oldName, newName) {
            if (item[oldName] != null && item[oldName] != undefined) {
                item[newName] = item[oldName];
                delete item[oldName];
            }
        }

        function extractDataAndTopics(termsResolved, metric) {
            let res = {}, topics = {};
            for (let keyName in termsResolved) {
                res[keyName] = metric ? termsResolved[keyName]['data'][metric] : termsResolved[keyName]['data']['data'];
                let term_topics = termsResolved[keyName]['data']['topics_distribution'] || {};
                Object.keys(term_topics).forEach(function (t) {
                    topics[t] = (topics[t] || 0) + term_topics[t]
                })
            }
            if (!_.isEmpty(topics)) {
                let total_topics = _.values(topics).reduce((a, b) => a + b);
                for (let topic in topics) {
                    topics[topic] = Number((topics[topic] * 100 / total_topics).toFixed(1));
                }
            }
            return {data: res, topics: topics};
        }

        function filterConfigTerms(terms) {
            return _.filter(terms, (t) => !(t.type == 'hashTag' || t.type == 'mention' || t.type == 'post'));
        }

        function tweakTelcoData(config, timeframe) {
            let channel = config.channels.value || '';
            if (channel.includes("telco")) {
              timeframe = date.getTimeframe(config.timeframe, false);
              if (channel == "au_telco" ||
                  (["sg_telco", "sg_bidstream", "bidstream"].includes(channel) &&
                    !permissions.hasPermission('sg telco audience filters'))) {
                  config.audience = [];
              }
            }
            return config, timeframe;
        }

        const webQuery = {
            endpoint: '/concepts/web',
            host: config.INTERESTS_API,
            method: 'POST',
            paramsDefaults: function (config) {
                /** remove hash and mentions **/
                config.terms = filterConfigTerms(config.terms);

                const topics = (config.topics.length == 0) ? topicsTree.topicsFlatten : config.topics;
                let timeframe = getTimeframeDates(config.timeframe);
                config, timeframe = tweakTelcoData(config, timeframe);

                return {
                    audience: _.flatMap(config.audience, 'value'),
                    timeframe_start: timeframe.start,
                    timeframe_end: timeframe.end,
                    geos: _.map(geoService.serverValue(config.geos), 'id'),
                    sub_geos: geoService.createRequestSubGeoParam(config.sub_geos),
                    topics: topicsTree.getIds(topics),
                    sensitive_content: topicsTree.isSensitive,
                    examples: config.examples,
                    information: config.information,
                    unique_examples: false,
                    key_trends: config.isKeyTrends ? 1 : 0,
                    clustering: config.clustering,
                    channels: config.channels.value,
                    language: context.current._language_mold.getLanguage($state, context).value,
                    user_first_party_audience: context.current.firstPartyAudience
                };
            },
            concepts: function (params, concept) {
                params[CONCEPT_KEY] = [concept];
                params[CONCEPTS_BL_KEY] = [];
            },
            blConcepts: function (params, concept) {
                params[CONCEPT_KEY] = [];
                params[CONCEPTS_BL_KEY] = [concept];
            },
            resolveData: function (termsResolved, metric) {
                Object.keys(termsResolved).forEach(function (term) {
                    termsResolved[term] = {
                        data: {
                            topics_distribution: termsResolved[term]['data']['topics'],
                            data: termsResolved[term]['data']['data'][term]
                        }
                    }
                });
                return extractDataAndTopics(termsResolved);
            },
            addQueryData: function (item) {}
        };

        const facebookPagesQuery = {
            endpoint: '/pages/facebook',
            host: config.SEARCH_API,
            method: 'POST',
            paramsDefaults: function (config) {
                const topics = (config.topics.length === 0) ? topicsTree.topicsFlatten : config.topics;
                const timeframe = getTimeframeDates(config.timeframe);
                return {
                    audience: _.flatMap(config.audience, 'value'),
                    timeframe_start: timeframe.start,
                    timeframe_end: timeframe.end,
                    geos: _.map(geoService.serverValue(config.geos), 'id'),
                    sub_geos: geoService.createRequestSubGeoParam(config.sub_geos),
                    topics: topicsTree.getIds(topics),
                    sensitive_content: topicsTree.isSensitive,
                    examples: config.examples,
                    information: config.information,
                    language: context.current._language_mold.getLanguage($state, context).value,
                    size: 200
                };
            },
            concepts: function (params, concept) {
                params[CONCEPT_KEY] = [concept];
                params[CONCEPTS_BL_KEY] = [];
            },
            blConcepts: function (params, concept) {
                params[CONCEPT_KEY] = [];
                params[CONCEPTS_BL_KEY] = [concept];
            },
            resolveData: function (termsResolved, metric) {
                Object.keys(termsResolved).forEach(function (term) {
                    termsResolved[term] = {
                        data: {
                            topics_distribution: termsResolved[term]['data'][term].topics,
                            data: termsResolved[term]['data'][term]['data']
                        }
                    }
                });
                return extractDataAndTopics(termsResolved);
            },
            addQueryData: function (item) {}
        };

        const facebookInterestsQuery = {
            endpoint: '/interests/facebook',
            host: config.INTERESTS_API,
            paramsDefaults: function (config) {
                const timeframe = getTimeframeDates(config.timeframe);

                return {
                    'audience[]': _.flatMap(config.audience, 'value'),
                    timeframe_start: timeframe.start,
                    timeframe_end: timeframe.end,
                    'geos[]': _.map(geoService.serverValue(config.geos), 'id'),
                    sub_geos: geoService.createRequestSubGeoParam(config.sub_geos),
                    'topics[]': topicsTree.getIds(config.topics),
                    examples: config.examples,
                    information: config.information,
                    channels: config.channels.value,
                    language: context.current._language_mold.getLanguage($state, context).value
                };
            },
            concepts: function (params, concept) {
                params['phrases[]'] = [concept];
                params['phrases_bl[]'] = [];
            },
            blConcepts: function (params, concept) {
                params['phrases[]'] = [];
                params['phrases_bl[]'] = [concept];
            },
            resolveData: function (termsResolved, metric) {
                Object.keys(termsResolved).forEach(function (term) {
                    termsResolved[term] = {
                        data: {
                            topics_distribution: termsResolved[term].data.topics,
                            data: termsResolved[term].data.data[term]
                        }
                    }
                });
                return extractDataAndTopics(termsResolved);
            },
            addQueryData: function (item) {}
        };

        return {
            version: '1.0.0', //when adding new params, changing the version will reset for all users
            queries: {
                facebook: {
                    interests: $.extend(true, {}, facebookInterestsQuery, {
                        metric: 'interests',
                        gridAdvanced: {}
                    }),
                    pages: $.extend(true, {}, facebookPagesQuery, {
                        metric: 'pages',
                        gridAdvanced: {}
                    })
                },
                articles: {
                    phrases: $.extend(true, {}, webQuery, {
                        metric: 'phrases',
                        gridAdvanced: {},
                        supportSubGeos: true
                    })
                },
                sg_telco: {
                    phrases: $.extend(true, {}, webQuery, {
                        metric: 'phrases',
                        gridAdvanced: {},
                        supportSubGeos: true
                    })
                },
                sg_bidstream: {
                    phrases: $.extend(true, {}, webQuery, {
                        metric: 'phrases',
                        gridAdvanced: {},
                        supportSubGeos: true
                    })
                },
                bidstream: {
                    phrases: $.extend(true, {}, webQuery, {
                        metric: 'phrases',
                        gridAdvanced: {},
                        supportSubGeos: true
                    })
                },
                au_telco: {
                    phrases: $.extend(true, {}, webQuery, {
                        metric: 'phrases',
                        gridAdvanced: {},
                        supportSubGeos: true
                    })
                }
            },
            hiddenColumns: ["Klout score", "Specificity Score"]
        };
    }
]);
