var d3 = require("d3"),
	c = require("infra/utils/common"),
	$ = require("jquery"),
	add_tooltip = require('../tooltip/tooltip.js');

VerticalBarChart.BAR_SPACE_WIDTH = 70;
VerticalBarChart.LABEL_SPACE_HEIGHT = 62;
VerticalBarChart.LABEL_SPACE_PAD = 15;
VerticalBarChart.LABEL_LINE_HEIGHT = 23;
VerticalBarChart.LABEL_CHARACTER_WIDTH = 7.8;
VerticalBarChart.MAX_LABEL_LENGTH = 12;
VerticalBarChart.STATIC_BAR_WIDTH = true;
VerticalBarChart.BAR_WIDTH = 15.7;
VerticalBarChart.BAR_VALUE_LABEL_PAD = 15;
VerticalBarChart.BAR_VALUE_LABEL_LEFT_MARGIN = 18;
VerticalBarChart.MINIMUM_BAR_HEIGHT = 2;
VerticalBarChart.TOOLTIP_DEFAULT_POSITION = {my: 'left center', at: 'right center'};
VerticalBarChart.TOOLTIP_LEFT_POSITION = {my: 'right center', at: 'left center'};

var LABEL_FONT = {
	family: 'Roboto',
	size: 12,
	stretch: 'normal',
	style: 'normal',
	variant: 'normal',
	weight: 'normal'
};

var unknown = "unknown-term";

function VerticalBarChart(callback, entries, configuration) {
	var self = this;
	this.popup = callback;
	this.map = {};
	this.filtered = {};
	this.configuration = c.is(configuration) ? configuration : {element: 'vertical-bar-chart'};
	this.space = (this.configuration.grouped) ? (VerticalBarChart.BAR_SPACE_WIDTH/3)*2 : VerticalBarChart.BAR_SPACE_WIDTH;
	this.labelPad = (this.configuration.grouped) ? VerticalBarChart.BAR_VALUE_LABEL_LEFT_MARGIN/2.5 : VerticalBarChart.BAR_VALUE_LABEL_LEFT_MARGIN;
	this.canvasWidth = 0;
	if (c.isArray(entries)) {
		this.entries = JSON.parse(JSON.stringify(entries));
		c.sortByNumeric(this.entries, 'order', false);
		_.each(this.entries, function (entry) {
			self.map[entry.key] = entry.class;
		});
	} else {
		this.entries = [];
	}

    function splitTextIntoLines(text, lines) {
      var return_lines = [], text_lines = [];
      var text_words = text.split(' ');
      _.each(text_words, function (word) {
        var line_attrs = {line: word.trim()};
        if (line_attrs.line.length > 0) {
          if (line_attrs.line.length > VerticalBarChart.MAX_LABEL_LENGTH) {
            line_attrs.original = line_attrs.line;
            line_attrs.line = line_attrs.line.substring(0, VerticalBarChart.MAX_LABEL_LENGTH - 2) + '..';
          } else if (text_lines.length > 0 &&
                     (text_lines[text_lines.length - 1].length + line_attrs.line.length + 1) <= VerticalBarChart.MAX_LABEL_LENGTH) {
            text_lines[text_lines.length - 1] = text_lines[text_lines.length - 1] + ' ' + line_attrs.line;
            line_attrs.line = null;
          }
          if (c.isString(line_attrs.line)) {
            text_lines.push(line_attrs);
          }
        }
      });
      for (var i = 0; i < lines; i++) {
        return_lines[i] = c.is(text_lines[i]) && c.isString(text_lines[i].line) ? text_lines[i] : {line: ''};
      }
      return return_lines;
    }

	this.setData = function(data, max, pad) {
		var self = this;
		this.clean();
		if (c.isArray(data) && c.isArray(this.entries)) {
			var stackData = [], terms = {};
			_.each (data, function(term, index) {
				var total = 0, y = 0, stacks = [];
				var display = term.display ? term.display : term.label;
				var stacked = {
					id: term.id,
					source: term.label,
					labels: splitTextIntoLines(display, 2),
					term: term.label // TODO Consider to remove
				};
				_.each (stacked.labels, (label) => label.width = self.getTextWidth(label.line, LABEL_FONT));
				if (stacked.term.length > VerticalBarChart.MAX_LABEL_LENGTH) {  // TODO Consider to remove
					var valid = c.cutText(stacked.term, VerticalBarChart.MAX_LABEL_LENGTH, '..');
					var mapped = self.filtered[valid];
					if (c.is(mapped) && c.isNumber(mapped.i)) {
						mapped.i = mapped.i + 1;
						valid = valid + mapped.i;
					}
					self.filtered[valid] = { i: 1, source: stacked.term };
					stacked.source = stacked.term;
					stacked.term = valid;
				}
				_.each (self.entries, function(entry) {
					var term_value = term[entry.key];
					if (!(term_value == 0 && (entry.key == 'positive' || entry.key == 'negative'))) {
						var description = (term.description && term.description[entry.key]) ?
											term.description[entry.key] : '' + term[entry.key];
						var entry_stack = {
							"name": entry.key,
							"term-class": term.class,
							"description": description
						};
						total += term_value;
						entry_stack.y0 = y;
						y += term_value;
						entry_stack.y1 = y;
						stacks.push(entry_stack);
					}
				});
        let precision = c.isNumber(self.configuration.precision) ? self.configuration.precision : 1;
				stacked.total = formatNumericLabel(total, 1, false, 5);
        stacked.original = formatNumericLabel(data[index].value, precision, false, 5);
					if (stacked.original >= 1000) {
						if (stacked.original >= 1000000) {
							stacked.original = Math.round(stacked.original/1000000) + 'M';
						} else {
							stacked.original = Math.round(stacked.original/1000) + 'K';
						}
					}
				stacked.stacks = stacks;
				stackData.push(stacked);
			});
			this.draw(this.container, stackData, max, pad);
		}
	};

  function formatNumericLabel(value, precision, forced, maxPrecision) {
    if (c.isNumber(value)) {
      var formatted = value == 0 ? '0' : value.toFixed(precision);
      if (formatted == 0 && !forced && maxPrecision > precision && formatted != value) {
        return formatNumericLabel(value, precision + 1, forced, maxPrecision);
      }
      return formatted;
   }
   return 0;
  }

	this.clean = function() {
		this.filtered = {};
		this.canvasWidth = 0;
		if (!c.is(this.container)) {
			this.container = d3.select('#' + this.configuration.element);
		}
		this.container.selectAll("*").remove();
	};

	this.getDimensions = function(data, pad) {
		let dimensions = {
			height: 0 - VerticalBarChart.LABEL_SPACE_HEIGHT - VerticalBarChart.LABEL_LINE_HEIGHT,
			width: (data.length * this.space),
			top: c.isNumber(pad) ? pad + VerticalBarChart.LABEL_SPACE_HEIGHT/2 : VerticalBarChart.LABEL_SPACE_HEIGHT/2,
			right: 0,
			bottom: VerticalBarChart.LABEL_LINE_HEIGHT * 2,
			left: 0
		};
    let element = c.isString(this.configuration.parent) ? this.configuration.parent : this.configuration.element;
		let element_height = c.getElementHeight(element);
    let margin = c.isNumber(this.configuration.margin) ? this.configuration.margin : 0;
		dimensions.height += (element_height - margin);
		return dimensions;
	};

  this.getTextWidth = function (text, font) {
    if (c.isString(text)) {
      var trimmed_text = text.trim();
      if (c.isString(trimmed_text)) {
        var ruler = $('#text-ruler');
        if (!c.isElement(ruler) && ruler.length <= 0) {
          $("body").append("<span id=\"text-ruler\"></span>");
          ruler = $('#text-ruler');
          ruler.css('visibility', 'hidden');
          ruler.css('white-space', 'nowrap');
        }

        c.setElementFont(ruler, font);
        ruler.html(trimmed_text);
        var ruler_width = ruler.width();
        ruler.html('');
        return c.isNumber(ruler_width) && ruler_width > 0 ? ruler_width : text.length * 7.8;
      }
    }
    return 0;
  };

	this.draw = function(element, data, max, pad) {
		if (c.isArray(data) && c.is(element)) {
			var self = this;
			var dimensions = this.getDimensions(data, pad);
			this.canvasWidth = dimensions.width + dimensions.left + dimensions.right;
			var x_scale = d3.scale.ordinal()
				.rangeRoundBands([0, dimensions.width], .1);
			var y_scale = d3.scale.linear()
				.rangeRound([dimensions.height, 0]);
			var xAxis = d3.svg.axis()
				.scale(x_scale)
				.orient("bottom");
			x_scale.domain(data.map((d) => d.term));
			y_scale.domain([0, max]);
			var svg = element.append("svg")
				.attr("class", 'canvas')
				.style("width", self.canvasWidth + 'px')
				.style("height", dimensions.height + dimensions.top + dimensions.bottom + 'px')
				.append("g")
				.attr("transform", "translate(" + dimensions.left + "," + dimensions.top + ")");
			var terms = svg.selectAll(".term")
				.data(data)
				.enter().append("g")
				.attr("class", "g")
				.attr("transform", (d) => "translate(" + x_scale(d.term) + ",0)");
			var x_text_pos = self.space - 3 - VerticalBarChart.BAR_WIDTH/2;
			var y_text_pos = dimensions.height + VerticalBarChart.LABEL_SPACE_PAD;
			if (this.configuration.grouped) {
				svg.append("text")
					.attr("class", "vertical-bar-term-label")
					.attr("x", (d) => (self.canvasWidth - (self.configuration.title.length * 6.2))/2)
					.attr("y", y_text_pos)
					.text(() => self.configuration.title);
			} else {
				terms.append("text")
					.attr("class", "vertical-bar-term-label")
					.attr("x", function(d) {
						var text_pos_diff = x_text_pos - d.labels[0].width;
						return text_pos_diff > 0 ? text_pos_diff/2 - 1 : 0;
					})
					.attr("y", y_text_pos)
					.text((d) => d.labels[0].line)
					.append("svg:title")
					.text((d) => d.source);
				terms.append("text")
					.attr("class", "vertical-bar-term-label")
					.attr("x", function(d) {
						var text_pos_diff = x_text_pos - d.labels[1].width;
						return text_pos_diff > 0 ? text_pos_diff/2 - 1 : 0;
					})
					.attr("y", y_text_pos + (VerticalBarChart.LABEL_LINE_HEIGHT*2)/3)
					.text((d) => d.labels[1].line)
					.append("svg:title")
					.text((d) => d.source);
			}
			var bar_width = VerticalBarChart.STATIC_BAR_WIDTH ? VerticalBarChart.BAR_WIDTH : x_scale.rangeBand();
			terms.selectAll("rect")
				.data((d) => d.stacks)
				.enter()
				.append("rect")
					.attr("width", bar_width)
					.attr("y", (d) => y_scale(d.y1))
					.attr("height", (d) => y_scale(d.y0) - y_scale(d.y1) <= 0 ? VerticalBarChart.MINIMUM_BAR_HEIGHT :
                                                                      y_scale(d.y0) - y_scale(d.y1))
					.attr("title", function(d) {
						var description_num = parseFloat(d.description);
						if(d.description.slice(-1) == '%'){
							return description_num.toFixed(1) + '%';
						}
						if(c.isNumber(description_num)){
							if(d.description.indexOf('.') != -1){
								description_num = description_num.toFixed(1);
							}
							return description_num.toLocaleString();
						}
						return d.description;
					})
					.attr("transform", "translate(" + ((self.space + 15.7)/2 - 15.7 - 6) + ",0)")
					.attr("class", function(d) {
						var class_name = self.map[d.name];
						class_name = class_name == '' ? d['term-class'] : class_name;
						return c.isString(class_name) ? class_name : unknown;
					});
			var tooltip_position = VerticalBarChart.TOOLTIP_DEFAULT_POSITION;
			if (self.configuration.hasOwnProperty('tooltip') && self.configuration.tooltip == "left"){
				tooltip_position = VerticalBarChart.TOOLTIP_LEFT_POSITION;
			}
			_.each(terms.selectAll("rect"),function(element,i){
				add_tooltip(element,'info',{position: tooltip_position,
                                    style: {classes: 'common-tooltip-info', tip: {width: 10, height: 5}}});
			});
			var label_pos = self.labelPad + bar_width/2;
			if(c.isNumber(self.configuration.precision) && self.configuration.precision == 0){
        label_pos = label_pos - 2;
      }
			terms.append("text")
				.attr("class", "vertical-bar-label")
				.attr("x", (d) => label_pos - (c.isString(d.original) ? d.original.length * 2.4 : 0))
				.attr("y", (d) => y_scale(d.total) - VerticalBarChart.BAR_VALUE_LABEL_PAD)
				.text((d) => d.original);
		}
	};
}

module.exports = VerticalBarChart;
