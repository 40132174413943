import { createSlice } from '@reduxjs/toolkit';

const spinnerSlice = createSlice({
  name: 'spinner',
  initialState: { showShortSpinner: false },
  reducers: {
    updateShowShortSpinner: (state, action) => {
      state.showShortSpinner = action.payload;
      return state;
    },
  },
});

export const { updateShowShortSpinner } = spinnerSlice.actions;

export default spinnerSlice;
