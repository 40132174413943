import React, { useCallback, useEffect, useState } from 'react';
import { cloneDeep, isEmpty, sortBy, map, find } from 'lodash';
import SelectToggle from '../../../../../common/SelectToggle/SelectToggle';
import ModalWithConfirmationButtons from '../../../../../common/Modal/ModalWithConfirmationButtons/ModalWithConfirmationButtons';
import PropTypes from 'prop-types';
import Dropdown from '../../../../../common/Dropdown/Dropdown/Dropdown';
import SingleSelectToggle from '../../../../../common/SingleSelectToggle/SingleSelectToggle';
import './LinkedinDemographicsModal.scss';

const LinkedinDemographicsModal = ({
  isOpen,
  modalTitle,
  onSubmit,
  onCancel,
  demographicsInput,
  disableGeos,
  linkedinMetaDataPromise,
}) => {
  const isUSRegion = (value) => value === 'urn:li:country:us';
  const isSingleSelected = (selectedArr) => selectedArr?.length === 1;
  const shouldDisableStatesSelection = (countries) => !(isSingleSelected(countries) && isUSRegion(countries[0].value));

  const [demographicsData, setDemographicsData] = useState(demographicsInput);
  const [isLinkedinMetaDataFetched, setIsLinkedinMetaDataFetched] = useState(false);
  const [genderValues, setGenderValues] = useState([]);
  const [ageValues, setAgeValues] = useState([]);
  const [selectedGenderValues, setSelectedGenderValue] = useState(demographicsData.gender?.[0].value || ['all']);
  const [selectedAgeValues, setSelectedAgeValue] = useState(demographicsData.age || []);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [regions, setRegions] = useState([]);
  const [selectedCountryValues, setSelectedCountryValues] = useState(demographicsData.country || []);
  const [allRegionsByState, setAllRegionsByState] = useState({});
  const [regionsByStateSelected, setRegionsByStateSelected] = useState([]);
  const [selectedStateValues, setSelectedStateValues] = useState(demographicsData.state || []);
  const [selectedRegionValues, setSelectedRegionValues] = useState(demographicsData.regions || []);
  const [isStatesDisabled, setIsStatesDisabled] = useState(
    !demographicsData.state && shouldDisableStatesSelection(demographicsData.country)
  );
  const [isRegionsDisabled, setIsRegionsDisabled] = useState(
    !demographicsData.regions && !isSingleSelected(demographicsData.state)
  );

  const ALL_GENDERS = { value: 'all', label: 'Both', icon: 'male_female' };
  const GENDER_CONFIG = {
    'urn:li:gender:MALE': { icon: 'male' },
    'urn:li:gender:FEMALE': { icon: 'female' },
    both: { icon: 'male_female' },
  };

  // Default values when it's not in initial stage
  const filterRegionsByState = (state, regionsVals = regions, allRegionsByStateVals = allRegionsByState) =>
    map(allRegionsByStateVals[state.value], (regionByStateEl) => find(regionsVals, { value: regionByStateEl }));

  const fetchLinkedinMetaData = async () => {
    const res = await linkedinMetaDataPromise;
    const genders = res.genders;
    genders.forEach((gender) => Object.assign(gender, GENDER_CONFIG[gender.value]));
    !genders.find((el) => el.value === 'all') && genders.push(ALL_GENDERS);
    setGenderValues(genders);
    setAgeValues(res.ages);
    setCountries(res.countries);
    setStates(res.us_states);
    setRegions(res.regions);
    setAllRegionsByState(res.regions_by_state);
    setIsLinkedinMetaDataFetched(true);
    if (isSingleSelected(demographicsData.state)) {
      setRegionsByStateSelected(filterRegionsByState(demographicsData.state[0], res.regions, res.regions_by_state));
    }
  };

  useEffect(() => {
    fetchLinkedinMetaData();
  }, [linkedinMetaDataPromise]);

  const onChangeAge = useCallback(
    (selected) => {
      const criteriaData = cloneDeep(demographicsData);
      if (isEmpty(selected)) {
        delete criteriaData.age;
      } else {
        criteriaData.age = sortBy(selected, 'value');
      }
      setDemographicsData(criteriaData);
      setSelectedAgeValue(selected);
    },
    [demographicsData, setDemographicsData]
  );

  const onChangeGender = useCallback(
    (selected) => {
      const criteriaData = cloneDeep(demographicsData);
      if (selected === 'all') {
        delete criteriaData.gender;
      } else {
        const { label, value } = genderValues.find((el) => el.value === selected);
        criteriaData.gender = [
          {
            label,
            display: label,
            value,
          },
        ];
      }
      setDemographicsData(criteriaData);
      setSelectedGenderValue(selected);
    },
    [demographicsData, setDemographicsData]
  );

  const onCountrySelect = useCallback(
    (selected) => {
      const criteriaData = cloneDeep(demographicsData);
      if (!selected.length || selected.length === countries.length) {
        delete criteriaData.country;
      } else {
        criteriaData.country = selected;
      }

      if (!shouldDisableStatesSelection(selected)) {
        setIsStatesDisabled(false);
      } else if (!isStatesDisabled || selectedStateValues) {
        setSelectedStateValues([]);
        setIsStatesDisabled(true);
        delete criteriaData.state;
        setSelectedRegionValues([]);
        setIsRegionsDisabled(true);
        delete criteriaData.regions;
      }

      setDemographicsData(criteriaData);
      setSelectedCountryValues(selected);
    },
    [demographicsData, setDemographicsData]
  );

  const onStateSelect = useCallback(
    (selected) => {
      const criteriaData = cloneDeep(demographicsData);
      if (!selected.length || selected.length === states.length) {
        delete criteriaData.state;
      } else {
        criteriaData.state = selected;
      }

      if (isSingleSelected(selected)) {
        setIsRegionsDisabled(false);
        const filteredRegions = filterRegionsByState(selected[0]);
        setRegionsByStateSelected(filteredRegions);
      } else if (!isStatesDisabled || selectedRegionValues) {
        setSelectedRegionValues([]);
        setIsRegionsDisabled(true);
        delete criteriaData.regions;
      }

      setDemographicsData(criteriaData);
      setSelectedStateValues(selected);
    },
    [demographicsData, setDemographicsData]
  );

  const onRegionSelect = useCallback(
    (selected) => {
      const criteriaData = cloneDeep(demographicsData);
      if (!selected.length || selected.length === regionsByStateSelected.length) {
        delete criteriaData.regions;
      } else {
        criteriaData.regions = selected;
      }

      setDemographicsData(criteriaData);
      setSelectedRegionValues(selected);
    },
    [demographicsData, setDemographicsData]
  );

  const handleSubmit = useCallback(() => {
    const segment = {
      ...demographicsData,
      type: 'linkedinDemographics',
    };
    onSubmit(segment);
  }, [demographicsData, setDemographicsData]);

  return (
    <div className="demographics-modal-component linkedin">
      <ModalWithConfirmationButtons
        width="720px"
        modalTitle={modalTitle}
        isOpen={isOpen}
        isSubmitDisabled={isEmpty(demographicsData)}
        isDisabledEnterKeyPress={true}
        onSubmit={handleSubmit}
        onCancel={onCancel}
        onXClick={onCancel}
        isLightTheme={true}
        hasScrollContainer={false}
        containerPadding="22px"
        titleMargin="0 0 22px"
      >
        <div className="demographics-modal-content">
          <div className="row">
            <div className="row-label">Gender</div>
            <div className="demographics-modal-cell gender-select">
              {isLinkedinMetaDataFetched && (
                <SingleSelectToggle
                  values={genderValues}
                  selectedValue={selectedGenderValues}
                  onToggle={onChangeGender}
                  isModalTheme
                />
              )}
            </div>
          </div>
          <div className="row">
            <div className="row-label">Age</div>
            <div className="demographics-modal-cell age-select">
              {isLinkedinMetaDataFetched && (
                <SelectToggle
                  values={ageValues}
                  selectedValues={selectedAgeValues}
                  onChange={onChangeAge}
                  className="font-regular"
                />
              )}
            </div>
          </div>
          <div className="row">
            <div className="row-label">Geo</div>
            <div className="demographics-modal-cell geo-select country">
              {isLinkedinMetaDataFetched && (
                <Dropdown
                  isMulti
                  isSearchable
                  values={countries}
                  selectedValues={selectedCountryValues}
                  onSelect={onCountrySelect}
                  showSelectAllOptions
                  summaryTextAllSelected="All Geos"
                  selectAllOptionsText="Select All"
                  isDisabled={disableGeos}
                  menuMaxHeight={181}
                  virtualizeThreshold={260}
                />
              )}
            </div>
          </div>
          <div className="row">
            <div className="row-label">State</div>
            <div className="demographics-modal-cell geo-select state">
              {isLinkedinMetaDataFetched && (
                <Dropdown
                  isMulti
                  isSearchable
                  values={states}
                  selectedValues={selectedStateValues}
                  onSelect={onStateSelect}
                  showSelectAllOptions
                  summaryTextAllSelected="All States"
                  selectAllOptionsText="Select All"
                  isDisabled={disableGeos || isStatesDisabled}
                  menuMaxHeight={181}
                />
              )}
            </div>
          </div>
          <div className="row">
            <div className="row-label">Area</div>
            <div className="demographics-modal-cell geo-select region">
              {isLinkedinMetaDataFetched && (
                <Dropdown
                  isMulti
                  isSearchable
                  values={regionsByStateSelected}
                  selectedValues={selectedRegionValues}
                  onSelect={onRegionSelect}
                  showSelectAllOptions
                  summaryTextAllSelected="All Areas"
                  selectAllOptionsText="Select All"
                  isDisabled={disableGeos || isRegionsDisabled}
                  menuMaxHeight={181}
                  virtualizeThreshold={150}
                />
              )}
            </div>
          </div>
        </div>
      </ModalWithConfirmationButtons>
    </div>
  );
};

LinkedinDemographicsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  modalTitle: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  disableGeos: PropTypes.bool.isRequired,
  linkedinMetaDataPromise: PropTypes.object.isRequired,
  demographicsInput: PropTypes.object,
};

LinkedinDemographicsModal.defaultProps = {
  demographicsInput: {},
};

export default LinkedinDemographicsModal;
