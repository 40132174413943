/* eslint-disable camelcase */
import config from '../../infra/config';
const HOST = config.USER_MGMT_API;
const AUTH_HOST = config.AUTHENTICATION_API;

export default {
  getLinkedinSegmentDemographicsDistribution: (audience, userId) => ({
    url: `${HOST}/linkedin/get_audience_distribution`,
    /* eslint-disable-next-line camelcase */
    payload: { audience, user_id: userId },
  }),

  createAudienceTargetTaxonomy: (segmentId, channel, marketId, advertiserId) => ({
    url: `${HOST}/audience_target/create_taxonomy`,
    payload: { channel, segment_id: segmentId, market_id: marketId, advertiser_id: advertiserId },
  }),

  createAudienceTargetUserList: (segmentId, channel, ids) => ({
    url: `${HOST}/audience_target/create_user_list/${channel}/${segmentId}`,
    payload: { ids },
  }),

  getAudienceTaxonomyCategory: (segmentId) => ({
    url: `${HOST}/audience_target/taxonomy_category/${segmentId}`,
  }),

  createAudienceInlineSegment: (name, segmentCriterias, channel, ssoAccessToken) => ({
    url: `${HOST}/audience_inline_segment`,
    payload: {
      data: { name, segments: segmentCriterias, channel },
    },
    headers: { 'SSO-Access-Token': ssoAccessToken },
  }),
  checkLinkedinUserAccessTokenStatus: (params) => ({ url: `${HOST}/linkedin/check_user_access_token_status`, params }),
  getLinkedinMetaData: (params) => ({ url: `${HOST}/linkedin/get_entities_meta_data`, params }),
  getFirstPartyDataByProgram: (programId) => ({ url: `${HOST}/first_party/${programId}` }),
  getFirstPartyDataBySSOAccessToken: (headers) => ({ url: `${HOST}/first_party_ulapi`, headers }),
  getFirstPartyDataByUser: (userId, headers) => ({ url: `${HOST}/first_party/user/${userId}`, headers }),
  getLinkedinTopContentForAudience: (audience, userId) => ({
    /* eslint-disable-next-line camelcase */
    payload: { audience, user_id: userId },
    method: 'post',
    url: `${HOST}/linkedin/get_top_content_for_audience`,
  }),
  getLinkedinCompaniesInfoByNames: (companiesNames, userId) => ({
    /* eslint-disable-next-line camelcase */
    payload: { companies: companiesNames, user_id: userId },
    method: 'post',
    url: `${HOST}/linkedin/get_organizations_info_by_name`,
  }),

  getPhrasesId: (phrases) => ({
    url: `${HOST}/phrases_id`,
    payload: { phrases, language: 'en' },
  }),

  getAbiAuthToken: (accessToken) => ({
    url: `${AUTH_HOST}/oauth2/token`,
    method: 'post',
    headers: { Authorization: `Bearer ${accessToken}` },
  }),

  getAbiAuthTokenForPlatformUser: (accessToken) => ({
    url: `${AUTH_HOST}/oauth2/platform_token`,
    method: 'post',
    headers: { Authorization: `Bearer ${accessToken}` },
  }),
};
