import 'ng-file-upload';
var JSZip = require('jszip');
import {
  createFirstPartySegment,
  createFirstPartySegmentWithFile,
  updateFirstPartySegmentWithFile,
  updateFirstPartySegment,
} from '../../react/services/AudienceInsightsService';

export default angular.module(__filename,
  ['ngFileUpload']).controller('editSegmentController',
    ['$scope', '$q', 'notificator', '$timeout', 'close', 'segment', 'userPrograms', 'newSegment', 'allSegments',
      function ($scope, $q, notificator, $timeout, close, segment, userPrograms, newSegment, allSegments) {
        initSegment();
        $scope.userPrograms = userPrograms;
        $scope.isNewSegment = newSegment;
        $scope.title = newSegment ? "Create a New Segment": "Edit '" + segment.name + "' Segment";
        $scope.uploadPercent = 0;
        $scope.showUploadPercent = false;
        $scope.saving = false;
        $scope.showNameExistsError = false;
        $scope.showNoProgramsError = false;
        $scope.isOutsideSource = segment.first_party_source_id && segment.first_party_source_id !== 1;
        $scope.isUlapiSegment = segment.first_party_source_id === 2;

        function initSegment () {
          $scope.segment = _.clone(segment);
          $scope.segment.programs = _.map($scope.segment.programs, function (program) {
            program.text = program.name;
            program.class = "program";
            return program;
          });
        }

        $scope.close = function() {
          if (!$scope.saving) {
            close();
          }
        };

        $scope.onValidate = function (programName) {
          let d = $q.defer();
          let program = _.find($scope.userPrograms, (p) => p.name == programName);
          if (program) {
            program.origin = program.name;
            program.class = "program";
          }
          d.resolve(program ? [program] : []);
          return d.promise;
        };

        $scope.saveSegment = function (segment) {
          const error_message = "Error saving " + segment.name;
          if(_.some(allSegments, (s) => _.trim(s.name) == _.trim(segment.name))){
            $scope.showNameExistsError = true;
            return;
          }
          if(_.isEmpty(segment.programs) && !$scope.isUlapiSegment){
            $scope.showNoProgramsError = true;
            return;
          }
          $scope.saving = true;
          $scope.showUploadPercent = !!$scope.segmentFile;
          let callbacks = {success: onSaveSuccess,
                           error: onSaveError,
                           progress: updateSaveProgress
          };
          let params = _.clone(segment);
          params.programs = _.map(segment.programs, 'id');
          if (newSegment) {
            params.creator_id = $scope.$root.user.id;
            if ($scope.segmentFile) {
              params.historical_to = new Date();
              params.historical_from = new Date(new Date().setDate(params.historical_to.getDate() - Math.floor(365*1.5))).toISOString().slice(0, 10)
              params.historical_to = params.historical_to.toISOString().slice(0, 10)
              createFirstPartySegmentWithFile(params, $scope.segmentFile, callbacks);
            } else {
              createFirstPartySegment(params).then(callbacks.success, callbacks.error);
            }
          } else {
            if ($scope.segmentFile) {
              params.historical_to = new Date();
              params.historical_from = new Date(new Date().setDate(params.historical_to.getDate() - Math.floor(365*1.5))).toISOString().slice(0, 10)
              params.historical_to = params.historical_to.toISOString().slice(0, 10)
              updateFirstPartySegmentWithFile(segment.id, params, $scope.segmentFile, callbacks);
            } else {
              updateFirstPartySegment(segment.id, params, callbacks);
            }
          }

          function onSaveSuccess (res) {
            if (res.data && res.data.result) res = res.data;
            $scope.showUploadPercent = false;
            $scope.saving = false;
            if(res.result == "success") {
              notificator.success({body: res.segment.name + " saved successfully"});
              close({reload: true});
            } else {
              notificator.error({body: res.message || error_message});
            }
          }
          function onSaveError (res) {
            $scope.showUploadPercent = false;
            $scope.saving = false;
            notificator.error({body: res?.data?.message || error_message});
          }
          function updateSaveProgress (event) {
            $scope.uploadPercent = _.min([parseInt(100.0 * event.loaded / event.total), 99]);
          }
        };

        const DEFAULT_FILE_PLACEHOLDER = "CSV file, TXT file or zip file containing one CSV or TXT file, max size: 2GB";
        $scope.getSegmentFileText = () => ($scope.segmentFile && $scope.segmentFile.name) || DEFAULT_FILE_PLACEHOLDER;

        $scope.handleFile = function () {
          const hasFile = Boolean($scope.segmentFile && $scope.segmentFile.name);
          const isZip = hasFile && $scope.segmentFile.name.endsWith("zip");
          $scope.checkingZip = false;
          $scope.fileError = hasFile &&
            !$scope.segmentFile.name.endsWith("csv") &&
            !$scope.segmentFile.name.endsWith("txt") &&
            !isZip;
          if (isZip) {
            $scope.checkingZip = true;
            let reader = new FileReader();
            reader.onload = (event) => checkZipFile(event.target.result);
            reader.readAsArrayBuffer($scope.segmentFile);
          }
        };

        function checkZipFile (readResult) {
          let file_found = false;
          try {
            let zip = new JSZip(readResult);
            $timeout(function () {
              if (_.isObject(zip.files)) {
                _.each(_.filter(zip.files, (file) => !file.name.startsWith("__MACOSX/")), function (entry) {
                  $scope.checkingZip = false;
                  $scope.fileError = file_found || !entry.name.endsWith("csv") || !entry.name.endsWith("txt");
                  file_found = true;
                });
              } else {
                $scope.fileError = true;
              }
              $scope.fileError = $scope.fileError || !file_found;
            });
          } catch (err) {
            $timeout(() => $scope.fileError = true);
          }
        }
      }
    ]);
