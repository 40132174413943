import React from 'react';
import PropTypes from 'prop-types';
import ClickableLabel from '../../../common/ClickableLabel/ClickableLabel';
import AppSecondaryBar from '../../../common/AppNavigation/AppSecondaryBar/AppSecondaryBar';

const AlertsSecondaryBar = ({ editSettings, exportAlerts }) => (
  <div className="alerts-secondary-bar-component">
    <AppSecondaryBar onExportClick={exportAlerts} isExportVisible={true}>
      <ClickableLabel
        label="Email settings"
        leftIconStyle={{ height: '16px', width: '16px' }}
        leftIconId="settings"
        onClick={editSettings}
      ></ClickableLabel>
    </AppSecondaryBar>
  </div>
);

AlertsSecondaryBar.propTypes = {
  editSettings: PropTypes.func.isRequired,
  exportAlerts: PropTypes.func.isRequired,
};

export default AlertsSecondaryBar;
