var $ = require("jquery");
var validate = require('./validate');

// Language types validators
var is = validate.is;
var isNumber = validate.isNumber;
var isArray = validate.isArray;
var isString = validate.isString;

var getElementId = function (id) {
    if (isString(id)) {
        if (id.indexOf('#') !== 0) {
            return '#' + id;
        } else if (id.length > 1) {
            return id;
        }
    }
    return null;
};

var getElementWidth = function (id) {
    var key = getElementId(id);
    if (isString(key)) try {
        var element = $(key);
        if (is(element)) {
            return element.width();
        }
    } catch (e) {
        console.log('Failed to clean the element', e);
    }
    return 0;
};

var setElementHeight = function (id, height, min, max) {
    var key = getElementId(id);
    if (isString(key)) try {
        var element = $(key);
        if (is(element)) {
            element.height(height);
            if (isNumber(min) && min > 0) {
                element.css('min-height', min + 'px');
            }
            if (isNumber(max) && max > 0) {
                element.css('max-height', max + 'px');
            }
        }
    } catch (e) {
        console.log('Failed to clean the element', e);
    }
    return 0;
};

var getElementHeight = function (id) {
    var key = getElementId(id);
    if (isString(key)) try {
        var element = $(key);
        if (is(element)) {
            return element.height();
        }
    } catch (e) {
        console.log('Failed to get the element height', e);
    }
    return 0;
};

var getElementSize = function (id) {
    var size = {width: 0, height: 0};
    var key = getElementId(id);
    if (isString(key)) try {
        var element = $(key);
        if (is(element)) {
            size.height = element.height();
            size.width = element.width();
        }
    } catch (e) {
        console.log('Failed to get the element size', e);
    }
    return size;
};

var getParentElementSize = function (id) {
    var size = {width: 0, height: 0};
    var key = getElementId(id);
    if (isString(key)) try {
        var element = $(key);
        if (is(element)) {
            element = element.parent();
            if (is(element)) {
                console.log('Parent id = ' + element.attr('id'));
                size.height = element.height();
                size.width = element.width();
            }
        }
    } catch (e) {
        console.log('Failed to get the element size of the parent', e);
    }
    return size;
};

var setElementVisible = function (id, show, empty) {
    var key = getElementId(id);
    if (isString(key)) try {
        var element = $(key);
        if (is(element)) {
            if (empty) {
                element.empty();
            }
            show ? element.show() : element.hide();
        }
    } catch (e) {
        console.log('Failed to clean the element', e);
    }
};

var setElementProperty = function (id, property, value) {
    if (isString(property) && isString(value)) {
        var key = getElementId(id);
        if (isString(key)) try {
            var element = $(key);
            if (is(element)) {
                element.css(property, value);
            }
        } catch (e) {
            console.log('Failed to set the element property', e);
        }
    }
};

var removeElement = function (id, index) {
    //var key = getElementId(id);
    if (isString(id)) try {
        if (isNumber(index) || isString(index)) {
            //key = key + index;
            id += index;
        }
        var element = $(document.getElementById(id));
        if (isArray(element)) {
            _.each(element, function (entry) {
                entry.remove();
            });
        } else if (is(element)) {
            element.remove();
        }
    } catch (e) {
        console.log('Failed to remove the element', e);
    }
};

var removeElementByClass = function (name) {
    if (isString(name)) try {
        var key = name;
        if (key.indexOf('.') !== 0) {
            key = '.' + key;
        }
        var element = $(key);
        if (isArray(element)) {
            _.each(element, function (entry) {
                entry.remove();
            });
        } else if (is(element)) {
            element.remove();
        }
    } catch (e) {
        console.log('Failed to remove the element', e);
    }
};

var setElementFont = function (e, f) {
    if (is(f)) try {
        if (isString(f.family))
            e.css('font-family', f.family + ', sans-serif');
        if (isNumber(f.size))
            e.css('font-size', f.size + 'px');
        if (isString(f.stretch))
            e.css('font-stretch', f.stretch);
        if (isString(f.style))
            e.css('font-style', f.style);
        if (isString(f.variant))
            e.css('font-variant', f.variant);
        if (isString(f.weight))
            e.css('font-weight', f.weight);
    } catch (x) {
        console.log('Failed to set the font on the element', x);
    }
};

module.exports = {
    getElementId: getElementId,
    getElementWidth: getElementWidth,
    setElementHeight: setElementHeight,
    getElementHeight: getElementHeight,
    getElementSize: getElementSize,
    getParentElementSize: getParentElementSize,
    setElementVisible: setElementVisible,
    setElementProperty: setElementProperty,
    removeElement: removeElement,
    removeElementByClass: removeElementByClass,
    setElementFont: setElementFont
};
