import React, { useState, useEffect } from 'react';
import { number } from 'prop-types';
import GaugeBar from '../GaugeBar/GaugeBar';
import './SegmentSize.scss';

const SegmentSize = ({ segmentSize, population }) => {
  const [percents, setPercents] = useState(0);

  const formatter = Intl.NumberFormat('en', {
    notation: 'compact',
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  });

  useEffect(() => {
    if (segmentSize) {
      setPercents(Math.min(segmentSize, 100));
    }
  }, [segmentSize]);

  return (
    <div className="segment-size-component">
      {population && (
        <div className="population">
          <span className="title">Estimated Population</span>
          <span className="value">{formatter.format(population)}</span>
        </div>
      )}
      {percents && (
        <div className="gauge">
          <GaugeBar lowLabel="Small" highLabel="Large" percentage={percents} />
        </div>
      )}
    </div>
  );
};

SegmentSize.propTypes = {
  segmentSize: number,
  population: number,
};

export default SegmentSize;
