import React from 'react';
import PropTypes from 'prop-types';
import SubmitButton from '../../../common/SubmitButton/SubmitButton';
import AppTopBar from '../../../common/AppNavigation/AppTopBar/AppTopBar';

const AlertsTopBar = ({ editAlert }) => (
  <div className="alerts-top-bar-component">
    <AppTopBar>
      <SubmitButton label="Create a new alert" onClick={editAlert}></SubmitButton>
    </AppTopBar>
  </div>
);

AlertsTopBar.propTypes = {
  editAlert: PropTypes.func.isRequired,
};

export default AlertsTopBar;
