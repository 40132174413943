import * as MixpanelAlerts from '../../../react/infra/mixpanel/MixpanelAlerts';

module.exports = angular.module(__filename, [
    require('../alerts-settings/alerts-settings').name,
    require('common/modals/confirmation/confirm-action.modal.service.js').name,
    require('data/settings/alerts-service').name,
    require('data/insights/alerts-insights-service').name,
    require('./alert-filters').name,
    require('./alert-dsp/alert-dsp-component').name
]).controller('alertDialogController', ['$scope', 'close', 'util', 'alertsService', 'ModalService', 'confirmAction', '$state', 'notificator',
    'alert', 'currentAlertsNames', 'settingsMissing', "filtersPartition", 'geoService', 'alertsInsightsService', '$window', '$timeout', 'abiPermissions', 'topicsTree',
    function ($scope, close, utils, alertsInterface, ModalService, confirmAction, $state, notificator, alert, currentAlertsNames,
              settingsMissing, filter, geoService, alertsInsightsService, $window, $timeout, abiPermissions, topicsTree) {
        $scope.alertsTypes = alertsInterface.types;
        $scope.sentimentDetection = alertsInterface.sentimentDetection;
        $scope.alertChangeDirection = alertsInterface.alertChangeDirection;

        $scope.netSentimentExplain = "pts. = points-change in net sentiment index. For example, going from 20 to -10 is a 30 points decrease.";

        var defaultAlert = {
            display_interests: [],
            benchmark_timeframe: 'last_month',
            channel: filter.alertChannels[0].value,
            geo: geoService.serverValue(),
            sentiment: $scope.sentimentDetection[0],
            change: $scope.alertChangeDirection[0],
            predefined: false,
            triggers: {
                compare_to_avg: {
                    selected: true,
                    stdev: 2
                },
                compare_to_previous: {
                    selected: false,
                    previous_measurement: 'Previous day'
                }
            }
        };

        $scope.hours = [];
        for (var i = 0; i < 24; i++) {
            var hh = (i < 10 ? '0' : '') + i;
            $scope.hours.push(hh + ':00');
        }

        $scope.timeframes = [
            {text: '1M', value: 'last_month', first: true, last: "alert.channel == 'tweets'", days: 31},
            {text: '3M', value: 'last_quarter', days: 92},
            {text: '6M', value: 'last_6_months', days: 184},
            {text: '1Y', value: 'last_year', last: 'true', days: 365}
        ];

        function isPredefined(alert) {
            return alert && alert.predefined;
        }

        $scope.alert = defaultAlert;
        var allowedAlertsTypes = _.reject($scope.alertsTypes, 'disabled');
        $scope.alert.type = allowedAlertsTypes.length == 1 ? allowedAlertsTypes[0].value : undefined;
        if (alert && !isPredefined(alert)) {
            $scope.alert = alert;
            $scope.alert.geo = alert.metadata.geo || defaultAlert.geo;

            // a UI(single selection geo dropdown) fix for all geos save

            $scope.alert.channel = alert.metadata.channel || defaultAlert.channel;
            $scope.alert.type = alert.metadata.type;
            $scope.alert.sentiment = _.find($scope.sentimentDetection, {id: alert.metadata.sentiment});
            $scope.alert.change = _.find($scope.alertChangeDirection, {value: $scope.alert.metadata.change}) || defaultAlert.change;

            $scope.title = 'Edit alert';
        } else {
            $scope.alert.lineItems = '';
            $scope.title = 'Create a new alert';
        }

        if ($scope.alert.geo.length > 1) $scope.alert.geo = [];
        if (isPredefined(alert)) {
            $scope.alert.predefined = true;
            $scope.alert.display_interests = alert.display_interests;
        }

        var originalChannel = $scope.alert.channel;
        var originalInterests = _.map($scope.alert.display_interests, 'id');
        $scope.originalTimeframe = $scope.alert.benchmark_timeframe;
        $scope.close = close;
        $scope.save = save;

        $scope.channels = filter.alertChannels;

        var uniqueId = 0;
        $scope.activationPercentage = function () {
            if (!$scope.alert.type) return;
            uniqueId++;
            var promises = [];
            var alert = angular.copy($scope.alert);
            var defaultSettings = {
                topics: [],
                geo: [],
                insightsChannel: {value: 'articles'},
                toNormalize: true
            };
            alert.metadata = buildMetadata(alert);
            alert.uniqueId = uniqueId;

            if (($scope.alertDialogForm && !$scope.alertDialogForm.$valid) || alert.display_interests.length == 0) return;

            $scope.showEstimation = false;
            $scope.isBusy = true;
            if (!validateAlert(alert)) {
                updateView(false);
                return;
            }

            alert.display_interests.forEach(function (term) {
                var parameters = utils.buildInsightsParameters(angular.extend(
                    {terms: [term], timeframe: getTimeframe(alert.benchmark_timeframe)}, defaultSettings, extractSettings(alert.metadata)
                ));

                if (alert.type == 'sentiment') {
                    parameters.channel = 'tweets';
                    parameters.sentiment = alert.metadata.sentiment;
                    parameters.change = alert.metadata.change;
                }
                parameters.triggers = alert.triggers;
                if (parameters.channel == 'tweets') parameters.to_normalize = false;

                var promise = alertsInsightsService.getInsights(parameters).then(function (res) {
                    return res.examples.filter(function (obj) {
                        return obj.letter !== 'A';
                    }).map(function (obj) {
                        return obj.date;
                    });
                });
                promises.push(promise);
            });

            return Promise.all(promises).then(success, failed).then(check).then(updateView).catch(doNothing);

            // functions ....
            function failed() {
                return 'Calculation failed..';
            }

            function success(activeDatesArray) {
                var totalDays = $scope.timeframes.filter(function (obj) {
                    return obj.value == alert.benchmark_timeframe
                })[0].days;
                var activeDatesFlat = [].concat.apply([], activeDatesArray);
                var activeDatesUnique = activeDatesFlat.filter(function (item, pos) {
                    return activeDatesFlat.indexOf(item) == pos;
                });

                return Math.floor(activeDatesUnique.length / totalDays * 100) + '%';
            }

            function check(value) {
                if (alert.uniqueId !== uniqueId) throw 'Do nothing...';
                return validateAlert(alert) ? value : false
            }

            function updateView(value) {
                $scope.estimatedActivation = value ? value : 'Cannot calculate..';
                $scope.showEstimation = true;
                $scope.isBusy = false;
                $scope.$digest();
            }

            function doNothing() {
            }

            function extractSettings(metadata) {
                var channel = (metadata.channel === "") ? null : {value: metadata.channel};
                var geo = metadata.geo && metadata.geo[0] ? metadata.geo : null;
                return {
                    geo: geo || [],
                    sensitive_content: metadata.sensitive_content,
                    insightsChannel: channel || {value: 'articles'}
                };
            }

            function getTimeframe(benchmarkTimeframe) {
                var endTimeframe = moment();
                var startTimeframe = endTimeframe.clone();
                var totalDays = $scope.timeframes.filter(function (obj) {
                    return obj.value == alert.benchmark_timeframe
                })[0].days;
                startTimeframe.subtract(totalDays - 1, 'days');
                return [startTimeframe.format('DD/MM/YY'), endTimeframe.format('DD/MM/YY')];
            }
        };

        // debounce activation calculation
        var debouncedAC = _.debounce($scope.activationPercentage, 2000, {'maxWait': 5000});
        $scope.$watch('alert', debouncedAC, true);

        function editSettings(alert) {
            angular.element(document.querySelector('#alert-dialog-overlay')).hide();

            ModalService.showModal({
                template: require('../alerts-settings/alerts-settings.html'),
                inputs: {settings: angular.copy($scope.settings), firstTime: true},
                controller: 'alertsSettingsController'
            }).then(function (modal) {
                modal.close.then(function () {
                    saveAlert(alert);
                });
            });
        }

        $scope.getButtonCss = (option, currentVal) => utils.getButtonCss(option, currentVal, $scope);

        $scope.filterTimeframe = function (option) {
            return $scope.alert.channel != 'tweets' || option.value == 'last_month';
        };

        $scope.onAlertTypeSelected = function (type) {
            $timeout(handleDialogScroll, 400);
            setDefaults($scope.alert, type);
        };

        var MARGIN_TOP = 5;
        $scope.isAlertShouldScroll = false;
        $scope.$watch(() => angular.element(document.querySelector('#dialog-form-container')).height(), handleDialogScroll);
        $scope.$watch('settings_open', handleDialogScroll);
        $scope.$watch('turn_dsp_open', handleDialogScroll);

        function handleDialogScroll() {
            var dialogHeight = (angular.element(document.querySelector('#alert-dialog-modal'))[0] || {}).offsetHeight;
            var dialogMargin = (($window.innerHeight / 100) * MARGIN_TOP) * 2;
            $timeout(() => $scope.isAlertShouldScroll = $window.innerHeight - dialogMargin <= dialogHeight);
            $timeout(() => {
                $timeout(() => angular.element(document.querySelector('#alert-dialog-modal')).css('overflow', 'inherit'), 800);
            }, 400);

            var dropdownSelectors = angular.element(document.querySelectorAll('am-select .scroll-container'));
            _.each(dropdownSelectors, selector => {
                var selectorMaxHeight = selector.getBoundingClientRect().top ? ($window.innerHeight - selector.getBoundingClientRect().top) : 1000;
                selectorMaxHeight -= 20;
                selector.style["max-height"] = `${selectorMaxHeight}px`;
            })
        }

        $scope.channelChanged = function (channel) {
            originalChannel = channel;
        };

        $scope.advanceSettingsLabel = function (prefix = '') {
            return $scope.alert.change.value == 'dec' && $scope.alert.type == "sentiment" ? "below" : "above";
        };

        $scope.isNetSentimentAlert = function () {
            return $scope.alert.type == 'sentiment' && ($scope.alert.sentiment || {}).id == 'net';
        };

        function save(alert) {
            if (!validateAlert(alert)) return;

            if (settingsMissing) {
              editSettings(alert);
            } else {
              saveAlert(alert);
            }
        }

        function saveAlert(alert) {
            $scope.saving = true;
            var alertToSave = _.cloneDeep(alert);
            alertToSave.display_interests = _.mapPick(alertToSave.display_interests, ['display', 'id', 'origin', 'text', 'type', 'required', 'included', 'excluded', 'class']);
            alertToSave.geo = geoService.handleSupportedCountries(alertToSave.geo);
            alertToSave.metadata = buildMetadata(alertToSave);
            handleTurnFields(alertToSave);
            if (alertToSave.id) {
                alertToSave.run_now = shouldRunNow(alertToSave);
                update(alertToSave);
            } else {
                alertToSave.run_now = true;
                create(alertToSave);
            }
        }

        function shouldRunNow(alert) {
            return $scope.originalTimeframe != alert.benchmark_timeframe
                || originalChannel != alert.channel
                || _.difference(_.map(alert.display_interests, 'id'), originalInterests).length > 0;
        }

        function handleTurnFields(alert) {
            _.isEmpty(alert.dsp_market) ? (alert.dsp_market = null) : (alert.dsp_market = _.omit(alert.dsp_market, 'market'))
            _.isEmpty(alert.dsp_advertiser) ? (alert.dsp_advertiser = null) : (alert.dsp_advertiser = _.omit(alert.dsp_advertiser, 'advertiser'))
            _.isEmpty(alert.dsp_insertion_order) ? (alert.dsp_insertion_order = null) : (alert.dsp_insertion_order = _.omit(alert.dsp_insertion_order, 'insertion'))
            _.isEmpty(alert.dsp_package) ? (alert.dsp_package = null) : (alert.dsp_package = _.omit(alert.dsp_package, 'package'))
            alert.dsp_line_items = _.isEmpty(alert.lineItems) ? null : _(alert.lineItems.split(',')).map(_.trim).compact().value();
        }

        function buildMetadata(alert) {
            var metadata = {};
            metadata.type = alert.type;
            metadata.timeframe = alert.benchmark_timeframe;
            metadata.geo = alert.geo;
            metadata.channel = alert.channel;
            metadata.kwd_ids = getKwdIds(alert.display_interests);
            metadata['phrases[]'] = utils.getTerms(alert.display_interests);
            metadata['boolean_logics[]'] = utils.getTerms(alert.display_interests, true);
            metadata.sensitive_content = topicsTree.isSensitive;
            return addPropertiesByType(metadata, alert);
        }

        function setDefaults(alert, type) {
            if (type === "sentiment") {
                alert.channel = 'tweets';
                alert.sentiment = alert.sentiment || defaultAlert.sentiment;
                alert.change = alert.change || defaultAlert.change;
            } else {
                alert.channel = originalChannel;
            }
        }

        function addPropertiesByType(metadata, alert) {
            var type = _.find($scope.alertsTypes, {value: metadata.type});
            _.each(type.additionalProps || [], prop => metadata[prop] = alert[prop]);

            if (metadata.type === "sentiment") {
                metadata['sentiment'] = metadata['sentiment'].id;
                metadata['change'] = metadata['change'].value;
            }

            return metadata;
        }

        function getKwdIds(interests) {
            var kwd_ids = utils.getPhrasesToIdMap(interests);
            var result = {};
            Object.keys(kwd_ids).forEach(function (kw) {
                // mongo key with dot
                result[kw.replace(new RegExp(/\./, 'g'), "\uff0e")] = kwd_ids[kw];
            });

            return result;
        }

        function validateAlert(alert) {
            var nonPhrases = false; // false -> valid
            var customMsg;
            $scope.nameNotUniqueError = alert.name && currentAlertsNames.indexOf(alert.name.toLowerCase()) != -1;
            $scope.noInterestsError = alert.display_interests.length == 0;
            if (alert.benchmark_timeframe != 'last_month') {
                customMsg = 'Sorry, only 1M benchmark timeframe can be used for @mentions, @posts and #hashtags monitoring';
                nonPhrases = utils.common.validateNonPhrases(alert.display_interests, alert.channel, notificator, customMsg);
            } else {
                customMsg = 'Sorry, @mentions, @posts and #hashtags are supported only for social channel';
                nonPhrases = utils.common.validateNonPhrases(alert.display_interests, alert.channel, notificator, customMsg);
            }
            return validateTriggers(alert.triggers) && !$scope.noInterestsError && !$scope.invalidEmailsError && !$scope.nameNotUniqueError && !nonPhrases;
        }

        function validateTriggers(triggers) {
            $scope.noTriggersSelected = !triggers.compare_to_avg.selected && !triggers.compare_to_previous.selected;
            if ($scope.noTriggersSelected) return false;

            if (triggers.compare_to_avg.selected) {
                $scope.compare_to_avg_missingValueError = !triggers.compare_to_avg.percentage && !triggers.compare_to_avg.stdev;
            } else {
                $scope.compare_to_avg_missingValueError = false;
            }

            if (triggers.compare_to_previous.selected) {
                $scope.compare_to_previous_missingValueError = !triggers.compare_to_previous.percentage && !triggers.compare_to_previous.stdev;
            } else {
                $scope.compare_to_previous_missingValueError = false;
            }

            if ($scope.alert.type == 'sentiment') {
                $scope.previous_positive_values = (triggers.compare_to_previous.percentage < 0 || triggers.compare_to_previous.stdev < 0);
                $scope.avg_positive_values = (triggers.compare_to_avg.percentage < 0 || triggers.compare_to_avg.stdev < 0);
            } else {
                $scope.previous_positive_values = false;
                $scope.avg_positive_values = false;
            }

            $scope.avg_positive_values &= triggers.compare_to_avg.selected;
            $scope.previous_positive_values &= triggers.compare_to_previous.selected;

            return !$scope.compare_to_avg_missingValueError && !$scope.compare_to_previous_missingValueError
                && !$scope.previous_positive_values && !$scope.avg_positive_values;
        }

        function openSuccessMessage(alert) {
            if (isPredefined(alert)) {
                var confirmationMsg = alert.name + " Alert was added successfully!";
                var txtConfirm = "View Alerts";
                var txtCancel = "Back to discovery";
                confirmAction.getConfirmation(confirmationMsg, txtConfirm, txtCancel, "Alert Notification").then(function (modal) {
                    modal.close.then(function (confirmation) {
                        if (confirmation) $state.go('alerts');
                    });
                });
            }
        }

        function create(alert) {
            alertsInterface.create(alert).then(function (data) {
                notificator.success({body: data.name + ' was saved successfully'});
                openSuccessMessage(alert);
                MixpanelAlerts.trackAlertCreated(data);
                close({reload: true});
            }, handleErrors);
        }

        function update(alert) {
            alertsInterface.update(alert.id, alert).then(function (data) {
                notificator.success({body: data.name + ' was updated successfully'});
                MixpanelAlerts.trackAlertUpdated(data);
                close({reload: true});
            }, handleErrors);
        }

        function handleErrors(res) {
            $scope.saving = false;
            $scope.dspErrors = res.data
        }
    }
]);
