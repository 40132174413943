import React, { useEffect, useRef, useState } from 'react';
import { string, number, arrayOf, array, shape } from 'prop-types';
import { merge, sum, map, forEach, orderBy, maxBy, isEmpty } from 'lodash';
import * as d3 from 'd3-React';
import { roundPercents } from '../../../utils/NumberUtils';
import './DonutChart.scss';

const DEFAULT_OPTIONS = {
  donutColors: ['#009EBD', '#40B6CE', '#80CFDE', '#BFE7EF', 'red', 'grey', 'yellow', 'green', 'pink'],
  labelColor: 'rgba(255,255,255,0.7)',
  labelFontSize: '14px',
  labelFontWeight: 'bold',
  labelFontFamily: 'Roboto',
  highlightedLabelColor: 'white',
};

const DonutChart = ({ data, options }) => {
  const parentDiv = useRef();
  const [donutChartSize, setDonutChartSize] = useState(null);
  const optionsMerged = merge({}, DEFAULT_OPTIONS, options);

  const getElementWidthAndHeight = () => {
    const { width, height } = parentDiv.current.getBoundingClientRect();
    return { width, height };
  };

  useEffect(() => {
    setDonutChartSize(getElementWidthAndHeight());
    const observer = new ResizeObserver(() => {
      setDonutChartSize(getElementWidthAndHeight());
    });
    observer.observe(parentDiv.current);
    return () => parentDiv.current && observer.unobserve(parentDiv.current);
  }, []);

  const dataValueSum = sum(map(data, 'value'));
  const percents = roundPercents(map(data, (d) => (d.value * 100) / dataValueSum));
  forEach(data, (d, i) => (d.percents = percents[i]));
  data = data.filter((d) => d.percents > 0);
  const dataSortedByValue = orderBy(data, 'value', 'desc');
  forEach(dataSortedByValue, (d, i) => (d.color = optionsMerged.donutColors[i]));
  const maxValue = maxBy(data, 'value');
  maxValue.highlight = true;
  const legendRectSize = 12;
  const legendSpacing = 6;
  let textElement, svg, legend, labelRadius;

  const changeArcLabelColor = (label, color) => {
    const arcLabel = textElement['_groups'][0].filter((a) => a.__data__.data && a.__data__.data.label === label)[0];
    d3.select(arcLabel).select('text').style('fill', color);
  };

  const addLegendInfoIcon = (legend) => {
    const otherLabel = legend.nodes().filter((l) => l.__data__.label === 'Other');
    if (isEmpty(otherLabel)) return;
    const pos = d3.select(otherLabel[0]).select('text').node().getBoundingClientRect();
    const infoIcon = d3.select(otherLabel[0]).append('foreignObject');
    infoIcon
      .attr('width', 16)
      .attr('height', 16)
      .attr('x', pos.width + legendRectSize + legendSpacing + 4)
      .attr('y', -1)
      .append('xhtml:body')
      .style('font-size', optionsMerged.labelFontSize)
      .style('font-weight', optionsMerged.labelFontWeight)
      .style('font-family', optionsMerged.labelFontFamily)
      .html(`<i class="icon-info" data-tooltip="${otherLabel[0].__data__.tooltip}"></i>`)
      .on('mouseover', function (d) {
        d3.select(infoIcon['_groups'][0][0]).select('i').style('color', 'white');
        changeArcLabelColor('Other', 'white');
      })
      .on('mouseout', function (d) {
        d3.select(infoIcon['_groups'][0][0]).select('i').style('color', optionsMerged.labelColor);
        changeArcLabelColor('Other', optionsMerged.labelColor);
      });
  };

  const drawLegend = () => {
    const legendRoot = svg.append('svg:g').classed('legend-element', true);

    const legend = legendRoot
      .selectAll('.legend')
      .data(dataSortedByValue)
      .enter()
      .append('g')
      .attr('class', 'legend')
      .attr('transform', function (d, i) {
        const height = legendRectSize + legendSpacing;
        const vertPos = i * height;
        return `translate(0,${vertPos})`;
      });

    legend
      .append('rect')
      .attr('width', legendRectSize)
      .attr('height', legendRectSize)
      .style('fill', (d) => d.color)
      .style('stroke', (d) => d.color);

    legend
      .append('text')
      .attr('x', legendRectSize + legendSpacing)
      .attr('y', legendRectSize)
      .text((d) => d.label)
      .style('font-size', optionsMerged.labelFontSize)
      .style('font-weight', optionsMerged.labelFontWeight)
      .style('font-family', optionsMerged.labelFontFamily)
      .style('fill', optionsMerged.labelColor);

    legendRoot.attr('transform', function () {
      const horPos = donutChartSize.width - legendRoot['_groups'][0][0].getBoundingClientRect().width;
      return `translate(${horPos},0)`;
    });
    addLegendInfoIcon(legend);
    return legend;
  };

  const changeLegendLabelColor = (label, color) => {
    const index = legend.nodes().findIndex((l) => l.__data__.label === label);
    d3.select(legend.nodes()[index]).select('text').style('fill', color);
  };

  const wrapText = (text, width) => {
    text.each(function () {
      const text = d3.select(this);
      const words = text.text().split(/\s+/).reverse();
      let word;
      let line = [];
      let lineHeight = 0;
      const y = text.attr('y');
      const dy = 1;
      let tspan = text
        .text(null)
        .append('tspan')
        .attr('x', 0)
        .attr('y', y)
        .attr('dy', dy + 'em');
      let isPercentElement = false;
      while ((word = words.pop())) {
        line.push(word);
        tspan.text(line.join(' '));
        if (isPercentElement || tspan.node().getComputedTextLength() > width) {
          line.pop();
          tspan.text(line.join(' '));
          line = [word];
          tspan = text
            .append('tspan')
            .attr('x', 0)
            .attr('y', y)
            .text(word)
            .attr('dy', dy + lineHeight + 'em');
          lineHeight += 1;
        }
        isPercentElement = false;
      }
    });
  };

  const calcLabelPosition = (arc, d) => {
    const [arcPosX, arcPosY] = arc.centroid(d);
    const hypotenuse = Math.sqrt(arcPosX * arcPosX + arcPosY * arcPosY);
    const numOfDigits = d['value'].toString().length;
    const spaceFromDonut = labelRadius + 8 * numOfDigits;
    return [(arcPosX / hypotenuse) * spaceFromDonut, (arcPosY / hypotenuse) * (labelRadius + 8)];
  };

  useEffect(() => {
    if (!isEmpty(donutChartSize)) {
      d3.select(parentDiv.current).select('svg').remove();
      svg = d3.select(parentDiv.current).append('svg:svg');
      const vis = svg.data([data]).append('svg:g');

      legend = drawLegend();
      const legendPos = svg.select('.legend-element')['_groups'][0][0].getBoundingClientRect();
      const legendWidth = legendPos.width;

      const LEGEND_WIDTH_OFFSET = 30;
      const radius =
        Math.min(donutChartSize.width - legendWidth + LEGEND_WIDTH_OFFSET, donutChartSize.height) / 2 -
        LEGEND_WIDTH_OFFSET;

      const LABEL_RADIUS_OFFSET = 5;
      labelRadius = radius + LABEL_RADIUS_OFFSET;
      const DONUT_SECTION_GAP = 0.025;
      const donut = d3.pie().padAngle(DONUT_SECTION_GAP);

      const ARC_REGULAR_INNER_RADIUS_RATIO = 0.88;
      const arc = d3
        .arc()
        .innerRadius(radius * ARC_REGULAR_INNER_RADIUS_RATIO)
        .outerRadius(radius);

      const ARC_HIGHLIGHTED_INNER_RADIUS_RATIO = 0.86;
      const ARC_HIGHLIGHTED_OUTER_RADIUS_RATIO = 1.02;
      const highlightedArc = d3
        .arc()
        .innerRadius(radius * ARC_HIGHLIGHTED_INNER_RADIUS_RATIO)
        .outerRadius(radius * ARC_HIGHLIGHTED_OUTER_RADIUS_RATIO);

      const arcs = vis
        .selectAll('g.arc')
        .data(donut.value((d) => d.value))
        .enter()
        .append('svg:g')
        .attr('transform', `translate(${radius},${radius})`);

      arcs
        .append('svg:path')
        .attr('fill', (d, i) => d.data.color)
        .attr('d', (d) => (d.data.highlight ? highlightedArc(d) : arc(d)))
        .attr('startradius', 100)
        .on('mouseover', (d) => changeLegendLabelColor(d.target.__data__.data.label, 'white'))
        .on('mouseout', (d) => changeLegendLabelColor(d.target.__data__.data.label, optionsMerged.labelColor));

      textElement = arcs.append('svg:g').attr('class', 'text-element');
      textElement
        .attr('visibility', (d) => (d.data.highlight ? 'hidden' : 'visible'))
        .append('svg:text')
        .attr('dy', '0.35em')
        .attr('text-anchor', 'middle')
        .text((d, i) => d.data.percents + '%')
        .style('fill', optionsMerged.labelColor)
        .style('font-size', optionsMerged.labelFontSize)
        .style('font-weight', optionsMerged.labelFontWeight)
        .style('font-family', optionsMerged.labelFontFamily)
        .attr('transform', function (d) {
          const [x, y] = calcLabelPosition(arc, d);
          return `translate(${x},${y})`;
        });

      const hoverLabelsElem = svg.data([data]).append('svg:g').classed('hover-label', true);
      const hoverLabelsArcs = hoverLabelsElem
        .selectAll('g.arc')
        .data(donut.value((d) => d.value))
        .enter()
        .append('svg:g')
        .attr('transform', `translate(${radius},${radius})`);
      const hoverTextElement = hoverLabelsArcs.append('svg:g').attr('class', 'text-element');
      hoverTextElement
        .attr('visibility', (d) => (d.data.highlight ? 'hidden' : 'visible'))
        .append('svg:text')
        .text((d, i) => d.data.percents + '%')
        .style('font-size', optionsMerged.labelFontSize)
        .style('font-weight', optionsMerged.labelFontWeight)
        .style('font-family', optionsMerged.labelFontFamily)
        .style('fill', 'transparent')
        .style('pointer-events', 'none')
        .attr('dy', '0.35em')
        .attr('text-anchor', 'middle')
        .attr('transform', function (d) {
          const [x, y] = calcLabelPosition(arc, d);
          return `translate(${x},${y}) scale(2.5,2.5)`;
        });
      svg.select(".text-element[visibility='hidden']")['_groups'][0][0].remove();

      let xOffset = donutChartSize.width / 2 - radius;
      const Y_OFFSET_SHIFT = 10;
      const yOffset = (donutChartSize.height - radius * 2) / 2 + Y_OFFSET_SHIFT;
      const donutPos = vis['_groups'][0][0].getBoundingClientRect();
      const svgPos = svg['_groups'][0][0].getBoundingClientRect();
      if (donutPos.top + yOffset < legendPos.bottom && donutPos.right + xOffset > legendPos.left) {
        xOffset = legendPos.left - donutPos.right;
        xOffset = Math.max(xOffset, svgPos.left - donutPos.left);
      }

      vis.attr('transform', `translate(${xOffset},${yOffset})`);
      hoverLabelsElem.attr('transform', `translate(${xOffset},${yOffset})`);

      const circleForInsideText = svg
        .data([[{ value: 1 }]])
        .append('svg:donutcircle:g')
        .attr('transform', `translate(${xOffset},${yOffset})`);

      const arcForInsideText = circleForInsideText
        .selectAll('g.arc')
        .data(donut.value(() => 1))
        .enter()
        .append('svg:g')
        .attr('transform', `translate(${radius},${radius})`);

      arcForInsideText.append('svg:path').attr('d', arc).attr('visibility', 'hidden');

      arcForInsideText
        .append('text')
        .attr('dy', '-.35em')
        .style('text-anchor', 'middle')
        .attr('fill', optionsMerged.highlightedLabelColor)
        .style('font', '22px Roboto')
        .style('font-weight', 'bold')
        .text(maxValue.percents + '%');

      arcForInsideText
        .append('text')
        .attr('dy', '2em')
        .style('text-anchor', 'middle')
        .attr('fill', optionsMerged.highlightedLabelColor)
        .style('font', '16px Roboto')
        .text(maxValue.label)
        .call((d) => wrapText(d, '60'));
    }
  }, [data, setDonutChartSize, donutChartSize]);

  return <div className="donut-chart" ref={parentDiv} />;
};

DonutChart.propTypes = {
  data: arrayOf(
    shape({
      displayValue: string,
      label: string,
      value: number,
    })
  ),
  options: shape({
    donutColors: array,
    labelColor: string,
    labelFontSize: string,
    labelFontWeight: string,
    labelFontFamily: string,
    highlightedLabelColor: string,
    tooltip: string,
  }),
};

DonutChart.defaultProps = {
  options: {},
};

export default DonutChart;
