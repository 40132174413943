
//HOW TO USE:
//SAMPLE OF THE DIRECTIVE HTML IMPLEMETATION:
/*

<am-autocomplete options="marketListHelper.marketList"
                directive-width="220"
                option-line-height="30"
                place-holder="{{marketListHelper.savedMarket}}"
                external-input-value="{{marketListHelper.savedMarket}}"
                err-Msg="*please choose a market"
                no-match-msg="Market not found"
                on-err="marketListHelper.onMarketErr"
                on-select="marketListHelper.onMarketSelect"
                async-options="marketListHelper.onServerRequest"
                external-input-value="marketListHelper.savedMarket"
                display-property="market"
                input-class="{{marketListHelper.marketErr}}"
                open-dir="top"
                clear="{{clear}}"
                clear-input="false">
              </am-autocomplete>

 params:
   options: - data array.
   display-property:  whet key in the options data to display.
   open-dir: top (OPTIONAL) - will open the autocomplete above the input field.
   option-line-height: MUST ONLY IF open-dir=top , will tell the directive how many px to open aboce the input field.
   items-in-view: how many items will be shown un the list viewable area. EX: items-in-view=10 shoulde be updated in autocomplete-options-dropdown css also (max-height: calc(35px * 10);)
   place-holder: as is... display any value inside the input field background
   external-input-value: display any value inside the input field, WILL RUN OVER THE place-holder param.
   is-disabled: OPTIONAL, listen to an external param to disable all input functionality and display, will also fill the input-class param with "input-disable"
   err-Msg: will be display below the input field when no results found, leave empty if not needed.
   no-match-msg: if now matched search term found in the data.
   on-err: OPTIONAL, callback function to call when no results found.
   on-select: OPTIONAL, callback function to call when an option is selected.
   async-options: OPTIONAL, callback function will be called on each keystroke will return a promise with the option data.
   external-input-value: OPTIONAL: if you need to update the actual input value and not the placeholder.
   input-class: css class for the input field, can be changed progrematicly EX: input-class="{{marketListHelper.marketErr}}" --> marketListHelper.marketErr = ""  or marketListHelper.marketErr = "error"
   clear: true/false -> clear the current selection and the input.
   clear-input: true --> if the input should not display the selected value.
   directiveId: OPTIONAL, to enable control from outside on the following directive HTML childes:
                $('#'+$scope.directiveId+"-input")
                $('#'+$scope.directiveId+"-errMsg")
                $('#'+$scope.directiveId+"-noMatchMsg")
                $('#'+$scope.directiveId+"-container")
                $('#'+$scope.directiveId+"-list")


 ADDITIONAL OPTIONS:
 specialTitle: true  : add this to any item in yor data json to enable custom design to it:
                      EX:
                       {"market" : "Activate to all markets", "id": 0, "specialTitle":true}
                      CSS:
                      .autocomplete-options-list {
                          .specialTitle{
                              font: normal normal normal 16.33px/normal Arial;
                              line-height: 34px;
                              border-bottom: 1px solid white;
                          }
                      }
*/

const LARGE_OPTIONS_SET = 200;
const TRIM_AFTER_LETTER = 3;

function amAutocompleteController(Keys, $window, $timeout, $scope, $sce) {
                $scope.highlightedOption = null;
                $scope.showOptions = false;
                $scope.matchingOptions = [];
                $scope.hasMatches = true /////////false; --> guyB: new: hides the red err message when loading
                $scope.selectedOption = null;
                $scope.currentScroll = 0;
                $scope.showErrMsg = false;
                $scope.specialTitleCounter = 0;
                $scope.showSearch = true;
                $scope.fullList = true;
                $scope.gotFullList = false;
                $scope.TRIM_AFTER_LETTER = TRIM_AFTER_LETTER;

                $scope.isLargeSet = ()=>$scope.options.length >= LARGE_OPTIONS_SET;

                $scope.isOptionSelected = function(option) {
                    return option === $scope.highlightedOption;
                };

                $scope.isTitleOption = function(option) {
                    return option.specialTitle;
                };

                //colors the typed letters inside the full option words.
                $scope.markSearch = function(text){
                  return $sce.trustAsHtml(
                    text.replace(new RegExp($scope.searchTerm, 'gi'), '<span class="markLetters">$&</span>'));
                };

                $scope.processSearchTerm = function() {
                    // support options as promise or values
                    // promise.reslove to avoid search before options is resolved incase of promise.

                    if($scope.isLargeSet() && $scope.searchTerm.length <= 1){
                        $scope.showOptions = false; //on large sets, start searching from 2nd letter
                        $timeout(()=>$scope.$apply());
                        return;     
                    }

                    Promise.resolve($scope.options).then(options => {
                      $scope.options = options;
                      $scope.fullList = false;
                      $scope.gotFullList = false;

                      if (!_.isEmpty($scope.searchTerm)) {
                        $scope.currentScroll = 0;
                        $scope.showSearch = false;
                        if ($scope.selectedOption) {
                          if ($scope.searchTerm != $scope.selectedOption[$scope.displayProperty]) {
                            $scope.selectedOption = null;
                          } else {
                            $scope.closeAndClear();
                            return;
                          }
                        }

                        if($scope.openDir == "top"){
                          $scope.setOptionTopPos();
                        }

                        var matchingOptions = $scope.findMatchingOptions($scope.searchTerm);

                        if($scope.asyncOptions){
                          $scope.asyncOptions($scope.searchTerm).then(function(options){
                            $scope.options = options;
                            $scope.gotOptions($scope.findMatchingOptions($scope.searchTerm));
                          });
                        }else{
                          $scope.gotOptions($scope.findMatchingOptions($scope.searchTerm));
                        }

                      } else {
                        $scope.showSearch = true;
                        $scope.showList();
                      }
                    });
                };

                const takeFirstNMatchingOptions = (options) => _.isNumber($scope.matchingOptionsLimit) ? _.take(options, $scope.matchingOptionsLimit) : options;

                $scope.findMatchingOptions = function(term) {
                    if (!$scope.options) {
                        throw 'You must define a list of options for the autocomplete ' +
                        'or it took too long to load';
                    }
                    $scope.specialTitleCounter = 0;

                    let filteredOptions = $scope.options.filter(function(option) {
                        var searchProperty = option[$scope.displayProperty];
                        if (searchProperty) {
                            var lowerCaseOption = searchProperty.toLowerCase();
                            var lowerCaseTerm = term.toLowerCase();
                            option['specialTitle'] && lowerCaseOption.indexOf(lowerCaseTerm) != -1? $scope.specialTitleCounter += 1 : null;
                            return lowerCaseOption.indexOf(lowerCaseTerm) != -1; //the option index or -1
                        }
                        return false;
                    });

                    return takeFirstNMatchingOptions(filteredOptions);
                };

                $scope.findExactMatchingOption = function(term) {
                    return $scope.options?.find(function(option) {
                        var lowerCaseOption = option[$scope.displayProperty].toLowerCase();
                        var lowerCaseTerm = term ? term.toLowerCase() : '';
                        return lowerCaseOption == lowerCaseTerm;
                    });
                };

                $scope.gotOptions = function(matchingOptions) {

                     $scope.matchingOptions = matchingOptions;

                     if (!$scope.matchingOptions.indexOf($scope.highlightedOption) != -1) {
                        $scope.clearHighlight();
                     }

                     $scope.prepareToshow();
                };

                $scope.prepareToshow = function() {

                        if($scope.openDir == "top"){
                            $scope.setOptionTopPos();
                        }


                        $scope.hasMatches = $scope.matchingOptions.length > 0;

                        if(!$scope.hasMatches && !$scope.gotFullList){
                            $scope.showOptions = true;
                            $scope.onErr($scope.noMatchMsg);
                            $scope.showErrMsg = true;
                        }else{
                            $scope.showOptions = true;
                            $scope.showErrMsg = false;
                            $scope.highlightNext(); //guyB - always highlight the first option when opened.
                        }
                        $scope.setOptionWidth();
                };

                $scope.keyDown = function(e) {
                    switch(e.which) {
                        case Keys.pageDown:
                            e.preventDefault();
                            if ($scope.showOptions) {
                                $scope.scrollCalculation("pageDown");
                            }
                            break;
                        case Keys.pageUp:
                            e.preventDefault();
                            $scope.scrollCalculation("pageUp");
                            break;
                        case Keys.upArrow:
                            e.preventDefault();
                            if ($scope.showOptions) {
                                $scope.highlightPrevious();
                                $scope.scrollCalculation("up");
                            }
                            break;
                        case Keys.downArrow:
                            e.preventDefault();
                            if ($scope.showOptions) {
                                $scope.highlightNext();
                                $scope.scrollCalculation("down");
                            } else {
                                if(!_.isEmpty($scope.searchTerm) || $scope.gotFullList){
                                    $scope.showOptions = true;
                                    if ($scope.selectedOption) {
                                        $scope.highlightedOption = $scope.selectedOption;
                                    }
                                }else if(!$scope.isLargeSet()){
                                    $scope.showList();
                                }
                            }
                            break;
                        //ORIG
                        /*
                        case Keys.enter:
                            e.preventDefault();
                            break;
                        */
                        case Keys.backSpace:
                             $scope.clearHighlight();
                             $scope.keyPress(e);
                            break;

                        case Keys.tab:
                        case Keys.enter:
                            if ($scope.highlightedOption) {
                                $scope.selectOption($scope.highlightedOption);
                            } else {
                                var exactMatches = $scope.findExactMatchingOption($scope.searchTerm);
                                if (exactMatches) {
                                    $scope.selectOption(exactMatches);
                                }
                            }
                            break;
                        case Keys.escape:
                            $scope.closeAndClear();
                            break;
                    }
                };

                const debounceProcess = _.debounce($scope.processSearchTerm, $scope.debounce || 0)

                $scope.keyPress = function(e) {
                    switch(e.which) {
                        case Keys.upArrow:
                        case Keys.downArrow:
                        case Keys.pageDown:
                        case Keys.pageUp:
                        case Keys.enter:
                        case Keys.escape:
                            break;
                        default:
                         $timeout(() => debounceProcess($scope.searchTerm));
                            break;
                    }
                };

                $scope.onInputChanged = (option) => {
                  $scope.onChange && $scope.onChange(option);
                  debounceProcess($scope.searchTerm);
                }

                $scope.scrollCalculation = function(dir) {
                    if(!$scope.showOptions){return;};

                    var scrollValue = "";
                    var currentIndex = $scope.getCurrentOptionIndex();
                    var lineHeight = $scope.getOptionHeight();
                    var specialLineHeight= $scope.getSpecialOptionHeight();
                    var scrollHeight = $('.autocomplete-options-dropdown')[0].scrollHeight;
                    var itemsViewHeight = lineHeight * $scope.itemsInView;
                    var specialHeight = 0;

                    if(($scope.specialTitleCounter>0)){
                         specialHeight = specialLineHeight*$scope.specialTitleCounter;
                    }

                    $scope.currentScroll = $('.autocomplete-options-dropdown').scrollTop();

                    if(dir == "up"){
                        scrollValue = ($scope.currentScroll - currentIndex * lineHeight);
                        if(scrollValue > 0){
                            $scope.setListScrollTopPos($scope.currentScroll - scrollValue);
                        }
                    }else if(dir == "down"){
                        scrollValue = ( ((currentIndex+1) * lineHeight) + specialHeight) - ($scope.currentScroll + itemsViewHeight);
                        if(scrollValue > 0){
                            $scope.setListScrollTopPos($scope.currentScroll + scrollValue);
                        }
                    }else if(dir == "pageUp"){
                        scrollValue = (itemsViewHeight-lineHeight);
                        $scope.setListScrollTopPos($scope.currentScroll - scrollValue);
                        setTimeout(function(){
                            if(currentIndex>0){
                                var theIndexToMark = parseInt($scope.currentScroll/lineHeight) - parseInt($scope.itemsInView) + 1;
                                $scope.highlightedOption = $scope.matchingOptions[theIndexToMark];
                                $scope.$apply();
                            }
                         },75);
                    }else if(dir == "pageDown"){
                        scrollValue = (itemsViewHeight-lineHeight);
                        $scope.setListScrollTopPos($scope.currentScroll + scrollValue);
                         setTimeout(function(){
                            var theIndexToMark = parseInt($scope.currentScroll/lineHeight) + parseInt($scope.itemsInView) - 1;
                            $scope.highlightedOption = $scope.matchingOptions[theIndexToMark];
                            $scope.$apply();
                         },75);
                    }
                }


                $scope.setListScrollTopPos = function(pos) {
                    $('.autocomplete-options-dropdown').scrollTop(pos);
                }

                $scope.getOptionHeight = function() {
                   return parseInt($(".autocomplete-option:not(.specialTitle)").css("line-height"));
                }

                $scope.getSpecialOptionHeight = function() {
                   return parseInt($(".specialTitle").css("height"));
                }

                $scope.highlightNext = function() {
                    if (!$scope.highlightedOption) {
                        $scope.highlightedOption = $scope.matchingOptions[0];
                    } else {
                        var currentIndex = $scope.getCurrentOptionIndex();

                        var nextIndex = currentIndex + 1 == $scope.matchingOptions.length
                            ? currentIndex : currentIndex + 1;

                        $scope.highlightedOption = $scope.matchingOptions[nextIndex];
                    }
                };

                $scope.highlightPrevious = function() {
                    if (!$scope.highlightedOption) {
                        $scope.highlightedOption = $scope.matchingOptions[$scope.matchingOptions.length - 1];
                    } else {
                        var currentIndex = $scope.getCurrentOptionIndex();

                        var previousIndex = currentIndex == 0 ? 0 : currentIndex - 1;

                        $scope.highlightedOption = $scope.matchingOptions[previousIndex];
                    }
                };

                $scope.onOptionHover = function(option) {
                    $scope.highlightedOption = option;
                };


                $scope.showList = function() {
                    Promise.resolve($scope.options).then(options => {
                      $scope.options = takeFirstNMatchingOptions(options);
                      if(($scope.matchingOptions.length == 0 || _.isEmpty($scope.searchTerm)) && !$scope.showOptions && !$scope.gotFullList){

                        $scope.specialTitleCounter = 0;

                        //to enable quick rendering, only 2 itemsInView are rendered while fetching all the rest of the list
                        $scope.matchingOptions = $.each($scope.options.slice(0,$scope.itemsInView*2), function(option) {
                          return option[$scope.displayProperty];
                        });

                        $timeout(function() {
                          $scope.getAllOptions();
                        }, $scope.debounce);

                        /* //ORIG
                        $scope.matchingOptions = $scope.options.filter(function(option) {
                        option['specialTitle']? $scope.specialTitleCounter += 1 : null;
                        return option[$scope.displayProperty];
                        });
                        */
                      }else if($scope.showOptions){
                        $scope.getAllOptions();
                      }else if($scope.gotFullList && !$scope.showOptions){
                        $scope.showOptions = true;
                      }

                      $scope.prepareToshow();
                      $scope.setInputfocus();
                    })
                };

                $scope.getAllOptions = function() {
                    $scope.gotFullList = true;
                    $scope.matchingOptions = $scope.options.filter(function(option) {
                        option['specialTitle']? $scope.specialTitleCounter += 1 : null;
                        return option[$scope.displayProperty];
                    });
                };


                $scope.$on('simple-autocomplete:clearInput', function() {
                    $scope.searchTerm = '';
                });

                $scope.setInputfocus = function() {
                    $scope.inputElement.focus();
                };

                $scope.clearInputVal = function() {
                    $scope.searchTerm = '';
                    if(!$scope.gotFullList){
                      $scope.matchingOptions = [];
                    }
                    $scope.selectedOption = null;
                    $scope.showSearch = true;
                };

                $scope.clearHighlight = function() {
                    $scope.highlightedOption = null;
                };

                $scope.closeAndClear = function() {
                    $scope.fullList = _.isEmpty($scope.searchTerm);
                    $scope.showOptions = false;
                    $scope.showErrMsg = false;
                    $scope.freshTerm = false;
                    $scope.clearHighlight();
                };

                $scope.selectOption = function(option) {
                    $scope.freshTerm = false;
                    $scope.selectedOption = option;
                    $scope.onSelect(option);
                    $scope.onChange && $scope.onChange(option);

                    if ($scope.clearInput != 'False' && $scope.clearInput != 'false') {
                        $scope.searchTerm = '';
                    } else {
                        $scope.searchTerm = option[$scope.displayProperty];
                        $scope.showSearch = false;
                    }

                    $scope.closeAndClear();
                };

                $scope.onBlur = function() {
                    if ($scope.freshTerm) {
                      $scope.searchTerm = $scope.placeHolder;
                    }
                    $scope.placeHolder = $scope.originalPlaceHolder;
                    $scope.checkSelection($scope.freshTerm);
                    $scope.closeAndClear();
                };

                $scope.onFocus = function() {
                    $scope.placeHolder = $scope.searchTerm;
                    $scope.searchTerm = "";
                    $scope.freshTerm = true;
                };

                $scope.checkSelection = function(freshTerm) {
                    if(!freshTerm && $scope.selectedOption == null){
                        $scope.clearInputVal();
                    }
                };

                $scope.getCurrentOptionIndex = function() {
                    return $scope.matchingOptions.indexOf($scope.highlightedOption);
                };

}

amAutocompleteController.$inject = ['autocomplete-keys', '$window', '$timeout', '$scope', '$sce'];




module.exports = angular.module(__filename, [])
    .directive('amAutocomplete', ['autocomplete-keys', '$window', '$timeout', function(Keys, $window, $timeout) {

        return {
            restrict: 'E',
            template: require('./am-autocomplete.drv.html'),
            scope: {
                searchTerm: '=?ngModel',
                options: '=',
                optionLineHeight: '@',
                onSelect: '=',
                asyncOptions: '=',
                changeInputText: '=',
                onErr: '=',
                ngReadonly: '=',
                isRequired: '=',
                isDisabled: '=',
                onChange: '=',
                externalInputValue: '=',
                clear: '=',
                debounce: '@',
                displayProperty: '@',
                inputClass: '@',
                clearInput: '@',
                placeHolder: '@',
                errMsg: '@',
                noMatchMsg: '@',
                openDir: '@',
                itemsInView: '@',
                directiveWidth: '@',
                directiveId: '@',
                name: '@',
                matchingOptionsLimit: '<'
            },
            controller: amAutocompleteController,
            link: function(scope, elem, attrs) {
                scope.debounceGroup = attrs.debounceGroup;
                scope.optionWidth = scope.directiveWidth + 'px';
                scope.originalPlaceHolder = scope.placeHolder;
                var inputElement = elem.find('.autocomplete-input')[0];
                 scope.inputElement = inputElement;

                scope.setOptionWidth = function() {
                    $timeout(function() {
                        var pixelWidth = inputElement.offsetWidth > scope.directiveWidth ? scope.directiveWidth : inputElement.offsetWidth - 2;
                        scope.optionWidth = pixelWidth + 'px';
                    });
                };

                //will move the top position according to # of matches found - due to opening above the searchbox
                scope.setOptionTopPos = function() {
                    $timeout(function() {
                        var dropDown = $('.autocomplete-options-dropdown');
                        var optionList = dropDown[0];
                        var borderWIdth = dropDown.css("border-bottom-width")?  parseInt(dropDown.css("border-bottom-width")) : 0;
                        scope.optionTopPos = optionList? -1*(optionList.offsetHeight)-scope.optionLineHeight+borderWIdth : 0;
                    }, 75);
                };

                scope.$watch('externalInputValue', function(v) {
                    if(!v){
                      v = "";
                    }
                    scope.searchTerm = v;
                    scope.showSearch = !(v.length > 0);
                    if(v.length > 0){
                        var option = scope.findExactMatchingOption(v);
                        if(option){
                            scope.selectedOption = option;
                        }
                    }
                });

                scope.$watch('options', () => {
                  scope.gotFullList = false;
                })

                scope.$watch('clear', v => {
                  if (v) {
                    scope.clearInputVal();
                  }
                }, true);

            }


        }
    }]
)

 .factory('autocomplete-keys', function() {
        return {
            pageDown: 34,
            pageUp: 33,
            upArrow: 38,
            downArrow: 40,
            enter: 13,
            escape: 27,
            backSpace: 8,
            tab: 9
        };
    });
