const defualtScaleOptions = {
  max: 50,
  currentShift: 0,
  maxOut: 1
};

module.exports = class Zoom {
  constructor(args) {
    $.extend(this, {
      $buttons:   $(".zoom-button"),
      $info:      $("#zoom-info"),
      scale:      Object.assign({}, defualtScaleOptions, args.scale || {}),
      $container: $(args.$container),
      datamap:    args.datamap,
      onUpdate:   args.onUpdate
    });

    this.init();
  }

  init() {
    const paths = this.datamap.svg.selectAll("path"),
        subunits = this.datamap.svg.selectAll(".datamaps-subunit");

    // preserve stroke thickness
    paths.style("vector-effect", "non-scaling-stroke");

    // disable click on drag end
    subunits.call(
      d3.behavior.drag().on("dragend", () => d3.event.sourceEvent.stopPropagation())
    );

    this.scale.set = this._getScalesArray();
    this.d3Zoom = d3.behavior.zoom().scaleExtent([ this.scale.maxOut, this.scale.max ]);

    this._displayPercentage(1);
    this.listen();
  }

  listen() {
    this.$buttons.off("click").on("click", this._handleClick.bind(this));

    this.datamap.svg
      .call(this.d3Zoom.on("zoom", this._handleScroll.bind(this)))
      .on("dblclick.zoom", null); // disable zoom on double-click
  }

  reset() {
    this._shift("reset");
  }

  setNewMap(map, container) {
    this.datamap = map
    this.$container = container
    this.init()
  }

  _handleScroll() {
    const translate = d3.event.translate,
        scale = d3.event.scale,
        limited = this._bound(translate, scale);

    this.scrolled = true;

    this._update(limited.translate, limited.scale);
  }

  _handleClick(event) {
    const direction = $(event.target).data("zoom");
    this._shift(direction);
  }

  _shift(direction) {
    const center = [ this.$container.width() / 2, this.$container.height() / 2 ],
        translate = this.d3Zoom.translate(),
        view = {
          x: translate[0],
          y: translate[1],
          k: this.d3Zoom.scale()
        };
    let bounded;

    const translate0 = [
      (center[0] - view.x) / view.k,
      (center[1] - view.y) / view.k
    ];

    if (direction == "reset") {
      view.k = 1;
      this.scrolled = true;
    } else {
      view.k = this._getNextScale(direction);
    }

    const l = [ translate0[0] * view.k + view.x, translate0[1] * view.k + view.y ];

    view.x += center[0] - l[0];
    view.y += center[1] - l[1];

    bounded = this._bound([ view.x, view.y ], view.k);

    this._animate(bounded.translate, bounded.scale);
  }

  _bound(translate, scale) {
    const width = this.$container.width(),
        height = this.$container.height();

    translate[0] = Math.min(
      width * (scale - this.scale.maxOut),
      Math.max( width * (this.scale.maxOut - scale), translate[0] )
    );

    translate[1] = Math.min(height * (scale - this.scale.maxOut), Math.max(height * (this.scale.maxOut - scale), translate[1]));
    return { translate: translate, scale: scale };
  }

  _update(translate, scale, shouldEmitEvent = true) {
    this.d3Zoom
      .translate(translate)
      .scale(scale);
    this.datamap.svg.selectAll("g")
      .attr("transform", "translate(" + translate + ")scale(" + scale + ")");

    this._displayPercentage(scale);

    shouldEmitEvent && this.onUpdate && this.onUpdate(translate, scale);
  }

  _animate(translate, scale) {
    var d3Zoom = this.d3Zoom;

    d3.transition().duration(350).tween("zoom", () => {
      const iTranslate = d3.interpolate(d3Zoom.translate(), translate),
          iScale = d3.interpolate(d3Zoom.scale(), scale);
      return (t) => this._update(iTranslate(t), iScale(t));
    });
  }

  _displayPercentage(scale) {
    const value = Math.round(Math.log(scale) / Math.log(this.scale.max) * 100);
    this.$info.text(value + "%");
  }

  _getScalesArray(){
    const array = [],
        scaleMaxLog = Math.log(this.scale.max);

    for (var i = 0; i <= 10; i++) {
      array.push(Math.pow(Math.E, 0.1 * i * scaleMaxLog));
    }

    return array;
  }

  _getNextScale(direction) {
    const scaleSet = this.scale.set,
        currentScale = this.d3Zoom.scale(),
        lastShift = scaleSet.length - 1, temp = [];
    let shift;

    if (this.scrolled) {

      for (shift = 0; shift <= lastShift; shift++) {
        temp.push(Math.abs(scaleSet[shift] - currentScale));
      }

      shift = temp.indexOf(Math.min.apply(null, temp));

      if (currentScale >= scaleSet[shift] && shift < lastShift) {
        shift++;
      }

      if (direction == "out" && shift > 0) {
        shift--;
      }

      this.scrolled = false;

    } else {

      shift = this.scale.currentShift;

      if (direction == "out") {
        shift > 0 && shift--;
      } else {
        shift < lastShift && shift++;
      }
    }

    this.scale.currentShift = shift;

    return scaleSet[shift];
  }
}

// module.exports = Zoom
