module.exports = {
    alias: function (scope, alias, expr) {
        if (typeof(expr) === "function") {
            Object.defineProperty(scope, alias, {
                get: function () {
                    return expr(scope);
                },
                enumerable: true
            });
        } else {
            Object.defineProperty(scope, alias, {
                get: function () {
                    return scope.$eval(expr);
                },
                enumerable: true
            });
        }
    },
  ngModelSkipDirty: function(ngModel, fn) {
    return function() {
      // This is a dirty hack so the initialization of ngModel from options won't make the model and the form "dirty"
      // we do this since when a model is marked as dirty, the form is marked dirty as well, even though we haven't actually
      // changed the content of it (since it was initalized here from "options")
      var originalSetDirtyMethod = ngModel.$setDirty;
      var originalViewChangeListeners = ngModel.$viewChangeListeners; //fired with ng-change
      ngModel.$setDirty = angular.noop;
      ngModel.$viewChangeListeners = [];
      var result = fn.apply(this, arguments);
      ngModel.$setDirty = originalSetDirtyMethod;
      ngModel.$viewChangeListeners = originalViewChangeListeners;
      return result;
    }
  }

};
