import config from 'infra/config';
import c from 'infra/utils/common';

var TOP_ASSOCIATION_API = config.SEARCH_API + '/get_associations',
    USER_ASSOCIATION_API = config.SEARCH_API + '/associations_by_reference',
    ADD_ASSOCIATION_API = config.SEARCH_API + '/keywords/translate';

var DEFAULT_CLASS = 'trending-default';
var NORMAL_TYPE_ID = 'normal';
var MISSING = 'NA';
var MISSING_VALUE = 'NA';
var DEFAULT_SORT_KEY = 'absolute';

module.exports = angular.module(__filename, [])
    .service("associationTrendService", ['$q', '$state', 'context', 'baseInsightsService', 'topicsTree', 'geoService', 'util', 'abiPermissions',
     function ($q, $state, context, baseInsightsService, topicsTree, geoService, util, permissions) {

        var latestDataNormal;
        var latestDataSplit;
        function set(entry, name, factor, recalculate) {
            if (factor != 1) {
                var original = name + '_original';
                if (!c.isNumber(entry[original])) {
                    entry[original] = entry[name];
                }
                entry[name] = (recalculate ? entry[name] : entry[original]) * factor;
            }
        }

        function normalizeData(results, type, recalculate) {
            if (c.isArray(results.userData)) {
                if ((c.isNumber(results.abs_factor) && results.abs_factor != 1)
                    || (c.isNumber(results.factor) && results.factor != 1)) {
                    _.each(results.userData, function (entry) {
                        if (c.is(entry.values)) {
                            _.each(entry.values, function (item) {
                                if (NORMAL_TYPE_ID == type) {
                                    set(item, 'value', results.factor, recalculate);
                                    set(item, 'abs_value', results.abs_factor, recalculate);
                                } else {
                                    set(item, 'left', results.factor, recalculate);
                                    set(item, 'abs_left', results.abs_factor, recalculate);
                                    set(item, 'right', results.factor, recalculate);
                                    set(item, 'abs_right', results.abs_factor, recalculate);
                                }
                            });
                        }
                    });
                }
            }
        }

        function recalculateMax(results, type) {
            if (c.isArray(results.userData)) {
                _.each(results.userData, function (entry) {
                    if (c.isArray(entry.values)) {
                        _.each(entry.values, function (item) {
                            if (NORMAL_TYPE_ID == type) {
                                results.max = Math.max(item['value'], results.max);
                                results.abs_max = Math.max(item['abs_value'], results.abs_max);
                            } else {
                                results.max = Math.max(item['left'], results.max);
                                results.max = Math.max(item['right'], results.max);
                                results.abs_max = Math.max(item['abs_left'], results.abs_max);
                                results.abs_max = Math.max(item['abs_right'], results.abs_max);
                            }
                        });
                    }
                });
            }
        }

        function normalize(results, type, recalculate) {
            if (c.is(results)) {
                results.max = 0;
                results.abs_max = 0;
                recalculateMax(results, type);
                results.factor = results.max == 0 ? 1 : 100 / results.max;
                results.abs_factor = results.abs_max == 0 ? 1 : 100 / results.abs_max;
                normalizeData(results, type, recalculate);
            }
        }

        function getValue(o, k1, k2) {
            return c.isNumber(o[k1]) ? o[k1] : (c.isString(k2) ? (c.isNumber(o[k2]) ? o[k2] : MISSING_VALUE) : MISSING_VALUE);
        }

        function pushAssociationValues(sources, result, association, type, format) {
            if (c.isString(association.label)) {
                if (c.isArray(association.values)) {
                    _.each(association.values, function (value) {
                        var row = [association.label];
                        // TODO Put NA if specific term value is missing
                        row.push(c.isNull(value.id) ? MISSING : (c.isNull(sources[value.id]) ? value.id
                            : (c.isString(sources[value.id].text) ? sources[value.id].text : MISSING)));
                        if (type == NORMAL_TYPE_ID) {
                          row.push(format(getValue(value, 'abs_value', 'abs_value_original'), 'numeric'));
                          if (_.has(value, 'skew')) {
                            row.push(format(value.skew, 'numeric'));
                          }
                          if(_.has(value, 'share')){
                              row.push(format(value.share,'percent'));
                          }
                        } else {
                          row.push(format(getValue(value, 'abs_left', 'abs_left_original'), 'numeric'));
                          row.push(format(getValue(value, 'abs_right', 'abs_right_original'), 'numeric'));
                        }
                        result.push(row);
                    });
                }
            }
        }

        function v(value) {
            return c.isNumber(value) ? value : 0;
        }

        function validateData(results, key, type, map) {
            if (c.isArray(results[key]) && c.is(map)) {
                _.each(results[key], function (entry) {
                    var values = [];
                    entry.maxStrength = 0;
                    entry.maxConsumption = 0;
                    entry.maxSkew = 0;
                    if (c.is(entry.values)) {
                        var total = _.sum(_.map(entry.values, function(item){return v(item.abs_value)}));
                        var has_share = _.size(entry.values) > 1;
                        _.each(entry.values, function (item, termId) {
                            if (c.isString(termId)) {
                                item.id = termId;
                                var term = map[termId];
                                item.class = (c.is(term) && c.isString(term.class)) ? term.class : DEFAULT_CLASS;
                                if (c.is(term) && c.isString(term.text)) {
                                    item.label = term.text;
                                }
                                item.display = (c.is(term) && c.isString(term.display)) ? term.display : "";
                                if (NORMAL_TYPE_ID == type) {
                                    item.value = v(item.value);
                                    results.max = Math.max(item.value, results.max);
                                    entry.maxStrength = Math.max(item.value, entry.maxStrength);
                                    item.abs_value = v(item.abs_value);
                                    if(has_share) {
                                        item.share = total == 0 ? 0 : item.abs_value / total;
                                    }
                                    results.abs_max = Math.max(item.abs_value, results.abs_max);
                                    entry.maxConsumption = Math.max(item.abs_value, entry.maxConsumption);
                                    entry.maxSkew = Math.max(item.skew, entry.maxSkew);
                                } else {
                                    item.left = v(item.left);
                                    results.max = Math.max(item.left, results.max);
                                    item.right = v(item.right);
                                    results.max = Math.max(item.right, results.max);
                                    item.abs_left = v(item.abs_left);
                                    results.abs_max = Math.max(item.abs_left, results.abs_max);
                                    item.abs_right = v(item.abs_right);
                                    results.abs_max = Math.max(item.abs_right, results.abs_max);
                                    entry.maxStrength = Math.max(item.left + item.right, entry.maxStrength);
                                    entry.maxConsumption = Math.max(item.abs_left + item.abs_right, entry.maxConsumption);
                                }
                                values.push(item);
                            }
                        });
                    }
                    entry.values = values;
                });
            } else {
                results[key] = [];
            }
        }

        function getSortKey(measure) {
          var map = {'relative': 'maxStrength',
                     'absolute': 'maxConsumption',
                     'skew':     'maxSkew'};
          return map[measure] || map[DEFAULT_SORT_KEY];
        }

        function sort(results, measure) {
            var sortKey = getSortKey(measure);
            if (!c.isString(results.sorted) || results.sorted != sortKey) {
                c.sortByNumeric(results.userData, sortKey);
                results.sorted = sortKey;
            }
        }

        function tweakSGTelcoData(timeframe, geo){
            timeframe = c.getTimeframe(timeframe);

            geo = geoService.geosForChannel(geo, $state, context);
            return [timeframe, geo];
        }

       function getTopAssociationParameters(timeframe, terms_ids, keyword, geo, topics, kwd_ids, boolean_logics,
                                            channel, sub_geos, audience, user_first_party_audience) {
            let params = {
                keyword: keyword,
                values_type: 'relative',
                origins: geoService.objToIdArray(geo),
                sub_geos: context.current.insightsAllSubGeosSelected ? [] : geoService.createRequestSubGeoParam(sub_geos),
                topics: topicsTree.getIds(topics),
                sensitive_content: topicsTree.isSensitive,
                timeframe: timeframe.type,
                timeframe_start: timeframe.start,
                timeframe_end: timeframe.end,
                boolean_terms: boolean_logics,
                terms_ids: kwd_ids,
                channel: channel,
                language: context.current._language_mold.getLanguage($state, context).value
            };
            if (user_first_party_audience) {
                params.user_first_party_audience = user_first_party_audience;
            }
            if(audience !== undefined && baseInsightsService.shouldSendAudience(channel, permissions)){
                params.audience = _.flatMap(util.removeFullySelectedAudienceSegments(audience, true), 'value');
            }
            if (terms_ids.length > 0) {
                params.compare_to = terms_ids;
            }
            return params;
        }

        function getUserAssociationParameters(type, timeframe, terms, references, geo, topics, kwd_ids, boolean_logics,
                                              channel, sub_geos, audience, user_first_party_audience, sg_web_source) {

            const referenceIds = _.map(c.rejectBooleanLogics(references), c.getTermId);
            const keywordIds = _.map(terms, c.getTermId);
            let params = {
                  boolean_references: util.getTerms(references, true),
                  references_terms_ids: util.getPhrasesToIdMap(references),
                  references: referenceIds,
                  keywords: keywordIds,
                  origins: geoService.objToIdArray(geo),
                  sub_geos: c.isArray(sub_geos) && !context.current.insightsAllSubGeosSelected ? geoService.createRequestSubGeoParam(sub_geos) : [],
                  topics: topicsTree.getIds(topics),
                  sensitive_content: topicsTree.isSensitive,
                  view: type,
                  values_type: 'relative',
                  timeframe: timeframe.type,
                  timeframe_start: timeframe.start,
                  timeframe_end: timeframe.end,
                  boolean_terms: boolean_logics,
                  terms_ids: kwd_ids,
                  channel: channel,
                  language: context.current._language_mold.getLanguage($state, context).value
            };
            if (user_first_party_audience) {
                params.user_first_party_audience = user_first_party_audience;
            }
            if (sg_web_source) {
                params.sg_web_source = sg_web_source;
            }
            if(audience !== undefined && baseInsightsService.shouldSendAudience(channel, permissions)){
                params.audience = _.flatMap(util.removeFullySelectedAudienceSegments(audience, true), 'value');
            }
            return params;
        }

        function setLocalResultVars(result, type) {
            if (type == NORMAL_TYPE_ID) {
                latestDataNormal = result;
            } else {
                latestDataSplit = result;
            }
        }

        function augmentMissingLabels(userData, references) {
            var labels = {};
            _.each(references, function(ref) { labels[ref["id"]] = ref["display"] });
            _.each(userData, function(ud) {
                if (ud["label"] === null) {
                    ud["label"] = labels[ud["id"]];
                }
            });
        }

        return {
            getSortKey: getSortKey,
            getUserAssociation: function (term) {
                var defer = $q.defer();
                var result = {
                    validated: [],
                    error: null
                };
                if (c.isString(term)) {
                    var parameters = {
                        "keywords[]": term.split(','),
                        source: 'insights'
                    };
                    baseInsightsService.postAsyncData(ADD_ASSOCIATION_API, parameters).then(function (associations) {
                        _.each(associations.data, function (entry, index) {
                            result.validated.push({
                                id: entry,
                                label: index
                            });
                        });
                        defer.resolve(result);
                    }, function (response) {
                        result.error = c.getServerError(response);
                        defer.resolve(result);
                    });
                } else {
                    defer.resolve(result);
                }
                return defer.promise;
            },
            recalculate: (results, type) => normalize(results, type, true),
            getValuesByAssociationTable: function (params, type, measure, format) {
                var result = [], sources = {};
                var terms = c.isArray(params.boolean_logics) ? params.terms.concat(params.boolean_logics) : params.terms;
                var raw = (NORMAL_TYPE_ID == type) ? latestDataNormal : latestDataSplit;

                _.each(terms, function (target) {
                    if (c.isNumber(target.id)) {
                        sources[target.id.toString()] = target;
                    }
                });

                if (c.is(raw)) {
                    var sortKey = getSortKey(measure);
                    c.sortByNumeric(raw.userData, sortKey);
                    if (c.isArray(raw.userData)) _.each(raw.userData, function (association) {
                        pushAssociationValues(sources, result, association, type, format);
                    });
                }
                return result;
            },
            getAssociations: function (params, limit) {
                var defer = $q.defer();
                var termsMap = {}, result, termIds = [], keyword;

                _.each(params.terms, function (entry, index) {
                    var entryId = c.getTermId(entry);
                    if (index == 0) {
                        keyword = entryId;
                    } else {
                        termIds.push(entryId);
                    }
                    if (c.is(termsMap)) termsMap[entryId.toString()] = entry;
                });

                _.each(params.boolean_logics, function (entry) {
                    termsMap[entry.text] = entry;
                });

                var dat = tweakSGTelcoData(params.timeframe, params.geo);
                var parameters = getTopAssociationParameters(dat[0], termIds, keyword, dat[1],
                                                             params.topics, params.kwd_ids, params.boolean_logics,
                                                             params.channel, params.sub_geos, params.audience,
                                                             params.user_first_party_audience);
                var basePromise = baseInsightsService.postAsyncData(TOP_ASSOCIATION_API, parameters);
                defer.promise.cancel = basePromise.cancel;
                basePromise.cancellableThen(function(topData) {
                    result = {suggestions: topData.data.slice(0, limit)};
                    validateData(result, "suggestions", params.type, termsMap);
                    sort(result, params.measure);
                    defer.resolve(result);
                }).cancellableCatch(function(err) {
                    baseInsightsService.handleError(err).catch(reason => {});
                    return defer.reject(err);
                });
              return defer.promise;
            },

            getUserAssociationsByReference: function (type, measure, references, params, limit) {
                var defer = $q.defer();
                var result = {
                        userData: [],
                        factor: 1,
                        max: 0,
                        abs_factor: 1,
                        abs_max: 0,
                        error: null
                    };

                var dat = tweakSGTelcoData(params.timeframe, params.geo);
                var parameters = getUserAssociationParameters(type, dat[0], params.terms, references, dat[1], params.topics,
                    params.kwd_ids, params.boolean_logics, params.channel, params.sub_geos, params.audience,
                    params.user_first_party_audience, params.sg_web_source);

                var basePromise = baseInsightsService.postAsyncData(USER_ASSOCIATION_API, parameters);
                defer.promise.cancel = basePromise.cancel;
                basePromise.cancellableThen(function(response) {
                    result.userData = response.data.slice(0, limit);
                    augmentMissingLabels(result.userData, references);
                    var map = {};
                    _.each(params.terms, function (entry, index) {
                        var entryId = c.getTermId(entry);
                        if (c.is(map)) map[entryId.toString()] = entry;
                    });

                    _.each(params.boolean_logics, function (entry) {
                        map[entry.text] = entry;
                    });
                    validateData(result, "userData", type, map);
                    normalize(result, type);
                    sort(result, measure);
                    setLocalResultVars(result, type);
                    let termNames = _.map(_.union(params.terms, params.boolean_logics),
                                          function(t) { return {text: t.text, class: t.class};});
                    defer.resolve(result);
                }).cancellableCatch(function(err) {
                    baseInsightsService.handleError(err).catch(reason => {});
                    return defer.reject(err);
                });

                return defer.promise;
            }
        }
    }
]);
