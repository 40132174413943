import React from 'react';
import PropTypes from 'prop-types';
import './BITopBarCommonElements.scss';
import ClickableIconWithMenu from '../../ClickableIconWithMenu/ClickableIconWithMenu';

const BITopBarCommonElements = ({ showUserActions, onSignOutClick, userEmail }) => {
  const userActions = [{ label: 'Logout', onActionClick: onSignOutClick }];

  return (
    <div className="bi-top-bar-common-elements-component">
      {showUserActions && <ClickableIconWithMenu iconId="user" menuActions={userActions} headerLabel={userEmail} />}
    </div>
  );
};

BITopBarCommonElements.propTypes = {
  showUserActions: PropTypes.bool,
  onSignOutClick: PropTypes.func.isRequired,
  userEmail: PropTypes.string,
};

BITopBarCommonElements.defaultProps = {
  showUserActions: true,
};

export default BITopBarCommonElements;
