const BaseMapComponent = require('../base-map-component');

class UsaMapComponent extends BaseMapComponent {
    constructor() {
      super('usa');
      this.mapSizes = {
        dma: 'dma_by_coastline-0.003',
        state: 'states_by_coastline-0.002'
      };

      this.mapOptions = {
        scaleFactor: 0.7,
        maxScale: 10,
        heightOffset: 2
      };

      this.customProjection = d3.geo.albersUsa;
    }

    $onInit() {
      this.setMapNameFromScale(this.mapScale);
    };

    $onChanges(changesObj) {
      if (changesObj.mapScale) {
        this.setMapNameFromScale(changesObj.mapScale.currentValue);
      }

      if (changesObj.popupData) {
        this.popupTemplate = this.getPopupFunction(changesObj.popupData.currentValue);
      }

      if (changesObj.selectedRegions) {
        this.selectedRegions = changesObj.selectedRegions.currentValue;
      }
    };
}

module.exports = angular.module(__filename, [
  require('common/map-component/map-component').name
])
  .component('usaMapComponent', {
      template: require('../base-map-component.html'),
      bindings: {
        data: '<',
        mapScale: '<',
        popupData: '<',
        allRegionsSelected: '<',
        terms: '<',
        selectedRegions: '<'
      },
      controller: UsaMapComponent
  });
