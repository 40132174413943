var d3 = require("d3"),
    c = require("infra/utils/common"),
    TopBar = require("./top-bar"),
    add_tooltip = require('../tooltip/tooltip.js');

var BARS_HORIZONTAL_MARGIN = 60;
var BARS_VERTICAL_MARGIN = 8;
var CONTENT_VERTICAL_MARGIN = BARS_VERTICAL_MARGIN * 3;
var REMOVE_BUTTON_WIDTH = 9;
var REMOVE_BUTTON_LEFT_MARGIN = 5;
var MAX_LABEL_LENGTH = 21;
var ICON_WIDTH = 21;
var ICON_HEIGHT = 18;
var ICON_MARGIN = 18;
var ICON_CONTENT_DRIVERS_MARGIN = 5;
var SMALL_ICON_WIDTH = 12;
var SMALL_ICON_HEIGHT = 11;
var DEFAULT_FILL = "rgba(255,255,255,0.3)";
var HOVER_FILL = "#ffffff";
var TRIANGLE_RIGHT_ICON = "/images/icons/Triangle_Right.svg";
var TRIANGLE_DOWN_ICON = "/images/icons/Triangle_Down.svg";
TopBarGroup.LABEL_WIDTH = 230;
TopBarGroup.LABEL_LEFT_MARGIN = REMOVE_BUTTON_LEFT_MARGIN + REMOVE_BUTTON_WIDTH + 15;
TopBarGroup.BARS_LEFT_POS = TopBarGroup.LABEL_WIDTH + BARS_HORIZONTAL_MARGIN;
TopBarGroup.DEFAULT_CLASS = 'trending-default';
TopBarGroup.SPLIT_BAR_DIVIDER_OFFSET = 10;

var BUTTON_ATTRS = {
    "graph" : {
        "path" : [
            { 'd' : "M2.9995,13.75 C2.8565,13.75 2.7115,13.709 2.5835,13.624 C2.2395,13.395 2.1455,12.929 2.3755,12.584 L6.8835,5.823 L9.8635,8.803 L14.3635,1.603 C14.5835,1.251 15.0465,1.146 15.3975,1.364 C15.7485,1.584 15.8555,2.046 15.6365,2.397 L10.1365,11.197 L7.1165,8.177 L3.6245,13.416 C3.4795,13.633 3.2415,13.75 2.9995,13.75 L2.9995,13.75 Z M0.9995,0 L-0.0005,0 L-0.0005,16 L15.9995,16 L15.9995,15 L0.9995,15 L0.9995,0 Z" }
        ]
    },
    "content_drivers" : {
        "path" : [
            {'d' : "M16,2 C16,1.6 15.7,1.2 15.3,1.2 L6.8,1.2 C6.3,1.2 6,1.6 6,2 C6,2.4 6.3,2.8 6.8,2.8 L15.2,2.8 C15.7,2.8 16,2.4 16,2 L16,2 Z M0,1 L0,3 C0,3.6 0.4,4 1,4 L4,4 C4.6,4 5,3.6 5,3 L5,1 C5,0.4 4.6,0 4,0 L1,0 C0.4,0 0,0.4 0,1 L0,1 Z M16,14 C16,13.6 15.7,13.2 15.3,13.2 L6.8,13.2 C6.3,13.2 6,13.6 6,14 C6,14.4 6.3,14.8 6.8,14.8 L15.2,14.8 C15.7,14.8 16,14.4 16,14 L16,14 Z M16,8 C16,7.6 15.7,7.2 15.3,7.2 L6.8,7.2 C6.3,7.2 6,7.6 6,8 C6,8.4 6.3,8.8 6.8,8.8 L15.2,8.8 C15.7,8.8 16,8.4 16,8 L16,8 Z M0,7 L0,9 C0,9.6 0.4,10 1,10 L4,10 C4.6,10 5,9.6 5,9 L5,7 C5,6.4 4.6,6 4,6 L1,6 C0.4,6 0,6.4 0,7 L0,7 Z M1,9 L4,9 L4,7 L1,7 L1,9 Z M0,13 L0,15 C0,15.6 0.4,16 1,16 L4,16 C4.6,16 5,15.6 5,15 L5,13 C5,12.4 4.6,12 4,12 L1,12 C0.4,12 0,12.4 0,13 L0,13 Z M1,15 L4,15 L4,13 L1,13 L1,15 Z"}
        ]
   }
};


function paintButton(button,type,no_background_rect){
    if(!BUTTON_ATTRS.hasOwnProperty(type)) return;
    if(!no_background_rect){
        var rect = button.append('rect')
                         .attr('x',0)
                         .attr('y',0)
                         .attr('width',ICON_WIDTH)
                         .attr('height',ICON_HEIGHT)
                         .classed('graph-button-rect',true)
    }
    $.each(BUTTON_ATTRS[type], function(elem_type,attr_arr){
        $.each(attr_arr,function(index,attrs){
            var element = button.append(elem_type).attr("fill",DEFAULT_FILL);
            $.each(attrs,function(key,value){
               element.attr(key,value);
            });
        });
        button.on('mouseover',function(){
            d3.select(this).selectAll(elem_type + ':not(.graph-button-rect)').attr("fill",HOVER_FILL);
        });
        button.on('mouseout',function(){
            d3.select(this).selectAll(elem_type + ':not(.graph-button-rect)').attr("fill",DEFAULT_FILL);
        });
    });
}

function enrichBarDisplayData(data, barWidth){
  var num_terms = data.length;
  var current_start_position = 0;
  _.each(data, function (record,index){
    record.width = index == num_terms - 1 ? barWidth - current_start_position : Math.round((barWidth + 1 - num_terms) * record.share) ; //Last item gets remainder of bar width
    var name = record.display ? record.display : record.id;
    record.title = name ? name + " " + (record.share * 100).toFixed(1) + "%" : "";
    record.start = current_start_position;
    current_start_position += record.width + (record.width > 0 ? 2 : 0);
  });
  return data;
}


function TopBarGroup(g, containing_object) {
  this.configuration = containing_object.configuration.topBarGroup || {};
  this.g = g;
  this.x = containing_object.x;
  this.barWidth = containing_object.barWidth;
  this.split_bars = {};
  this.share_bar_tooltips = {};
  this.element = d3.select(this.g);
  this.remove = containing_object.remove;
  this.type = containing_object.type;
  this.expandFunction = containing_object.onBarGroupExpand;
  this.startPosition = TopBar.DEFAULT_VIEW_TYPE != this.type ? TopBarGroup.BARS_LEFT_POS + this.barWidth - TopBar.DIVIDER_OFFSET - TopBarGroup.SPLIT_BAR_DIVIDER_OFFSET : TopBarGroup.BARS_LEFT_POS;
  this.width = TopBarGroup.BARS_LEFT_POS + (this.x.range()[1] - this.x.range()[0]) + BARS_HORIZONTAL_MARGIN;
  this.disabled = false;
  var self = this;

  if (this.configuration.graph || this.configuration.examples) {
        this.background = this.element.append("rect")
            .classed("selected-row", function(d) { return d.selected; })
            .on('click',function(d){
                if(d3.select(this).classed("selected-row")) {
                    d3.event.stopPropagation();
                }
            });
        this.element
            .on("mouseover", function() {
                self.background.classed("hover", true);
            }).on("mouseout", function() {
                self.background.classed("hover", false);
            });
    }

    if (this.configuration.graph) {
        this.graphButton = this.element
            .append("svg")
            .classed("graph-button", true)
            .attr('width', ICON_WIDTH).attr('height', ICON_HEIGHT)
            .on('click', function (d) {
                d3.selectAll(".selected-row").classed("selected-row", false).each(function(d) { d.selected = false; });
                self.configuration.graph.showGraph(d);
                self.background.classed("selected-row", d.selected = true);
                d3.event.stopPropagation();
            });

        paintButton(this.graphButton, 'graph');
    }

    if (this.configuration.examples) {
      this.contentDriversButton = this.element
        .append("svg")
        .classed("graph-button", true)
        .attr('width', ICON_WIDTH).attr('height', ICON_HEIGHT)
        .on('click', function(d) {
          d3.selectAll(".selected-row").classed("selected-row", false).each(function(d) { d.selected = false; });
          self.configuration.examples.showExamples(d);
          self.background.classed("selected-row", d.selected = true);
          d3.event.stopPropagation();
        });

      paintButton(this.contentDriversButton,'content_drivers');
    }

    if (this.configuration.clickableLabel) {
        this.labelLinkElement = this.element
            .append("a")
            .attr("xlink:href", (d) => d.url)
            .attr("target", "_blank");
    }

    this.label = (this.labelLinkElement || this.element)
        .append("text")
        .attr("text-anchor", "start")
        .classed("cluster-label", true)
        .on('click',function(d){
            if (self.background && self.background.classed("selected-row")) {
                d3.event.stopPropagation();
            }
        });

    if (this.remove) {
      this.remove_button = this.element
          .append("text")
          .html("&times;")
          .attr("text-anchor", "start")
          .classed("remove-button", true)
          .on('click', function(d) {
            if (self.disabled) return;
            self.disabled = true;
            if(self.background) {
              self.background.classed("selected-row", d.selected = false);
            }
            self.remove(d);
          })
    }

    if (this.configuration.iconLabels) {
      this.icon_label = this.element
        .append("svg:image")
        .attr('width', ICON_WIDTH).attr('height', ICON_HEIGHT)
        .attr("xlink:href",function(d){
          return self.configuration.iconLabels[d.label];
        })
    }

    this.content = this.element.append("g")
      .classed("cluster-content", true)
      .attr("transform", "translate(0," + CONTENT_VERTICAL_MARGIN + ")");

    this.bars_group = this.content.append("g")
      .attr("transform", function(d, i) {
        return "translate(" + TopBarGroup.BARS_LEFT_POS + ",0)";
      });
}

TopBarGroup.prototype.setData = function(data, type, measure) {

  this.data = data.values;
  this.children = data.children;
  this.open = data.open;
  var self = this;

  this.label.html(c.cutText(data.label || '', MAX_LABEL_LENGTH, '...'));
  if(data.label && data.label.length > MAX_LABEL_LENGTH) {
    this.label.attr("title", data.label);
    add_tooltip(this.label, 'info', {style: {classes: 'common-tooltip-info', tip: {width: 10, height: 5}}});
  }

  if(measure === 'sov'){
    this.data = enrichBarDisplayData(this.data, this.barWidth);
    var split_bar_element = this.bars_group.append("g").attr("class", "split-bar");
    var bars = split_bar_element.selectAll(".share-bar")
      .data(this.data, function (x) {
        return x.id;
      });

    bars.transition().duration(TopBar.TRANSITION_DURATION)
      .each(function (d) {
        self._setBarElement(this, d);
      });

    bars.enter().append("rect")
      .attr("class", "share-bar")
      .each(function (d) {
        self._setBarElement(this, d);
      });

    bars.exit().each(function (d){
      let api = self.share_bar_tooltips[d.id];
      if (api) api.destroy(true);
    }).remove();

    this.height = TopBarGroup.height(1);
  } else {
    var split_bars = this.bars_group.selectAll(".split-bar")
      .data(this.data, function (x) {
        return x.id == -1 ? x.label : x.id;
      });

    split_bars.attr("transform", function (d, i) {
      return "translate(" + (d.parent_id ? -10 : 0) + "," + (i * (TopBar.HEIGHT + BARS_VERTICAL_MARGIN)) + ")";
    }).each(function (d) {
      self.split_bars[d.id].setData(d, type, measure);
    });

    split_bars.enter().append("g")
      .attr("class", "split-bar")
      .attr("transform", function (d, i) {
        return "translate(" + (d.parent_id ? -10 : 0) + "," + (i * (TopBar.HEIGHT + BARS_VERTICAL_MARGIN)) + ")";
      }).each(function (d) {
        var split_bar;
        split_bar = new TopBar(this, self.x, self.barWidth, type, self.configuration.topBar);
        split_bar.setData(d, type, measure);
        return self.split_bars[d.id] = split_bar;
      });

    split_bars.exit()
      .each(function (d) {
        self.split_bars[d.id].destroy();
        return delete self.split_bars[d.id];
      }).remove();

    this.height = TopBarGroup.height(this.data.length);

    if(data.is_parent){
      if(this.expandButton){
          this.expandButton.remove();
      }

      this.expandButton = this.element.append("image").attr("class","expand-children")
        .attr("width", SMALL_ICON_WIDTH).attr("height", SMALL_ICON_HEIGHT)
        .attr("xlink:href", self.open ? TRIANGLE_DOWN_ICON : TRIANGLE_RIGHT_ICON)
        .attr("y", (this.height - SMALL_ICON_HEIGHT) / 2)
        .attr("x", ICON_WIDTH - SMALL_ICON_WIDTH);

      this.label.classed("parent-label", true);

      function onClickExpand(){
        self.open = !self.open;
        if(typeof self.expandFunction == 'function'){
          self.expandFunction(data.id, self.open);
        }
        self.element.classed("open", self.open);
        self.expandButton.classed("open", self.open).attr("xlink:href", self.open ? TRIANGLE_DOWN_ICON : TRIANGLE_RIGHT_ICON);
        d3.event.stopPropagation();
      }

      this.expandButton.on("click", onClickExpand);
      this.label.on("click", onClickExpand);
      if(this.background) {
        this.background.on("click", onClickExpand);
        this.background.classed("parent-background", true);
      }
    } else {
      this.content.attr("transform", "translate(-10," + CONTENT_VERTICAL_MARGIN + ")");
    }
  }

  if (TopBar.isSplitted(type)) {
    this.divider = this.element.append("path")
      .classed("split-bar-cluster-divider", true)
      .attr("stroke-width", TopBar.DIVIDER)
      .attr("transform", "translate(" + (this.startPosition + TopBar.DIVIDER/2 + this.x(0)) + ",0)");
    this.divider.transition().duration(TopBar.TRANSITION_DURATION)
      .attr("d", "M0,0V" + (this.height))
      .attr("stroke-width", TopBar.DIVIDER)
      .attr("transform", "translate(" + (this.startPosition + TopBar.DIVIDER/2 + this.x(0)) + ",0)");
  }

  this.label.style('text-anchor', 'start')
  .attr("x", TopBarGroup.LABEL_LEFT_MARGIN)
  .attr("text-anchor", "start")
  .attr("y", (this.height / 2 + 2));

  if (this.remove) {
    this.remove_button
    .style('text-anchor', 'start')
    .attr("x", REMOVE_BUTTON_LEFT_MARGIN)
    .attr("text-anchor", "start")
    .attr("y", this.height / 2);
  }

  if (this.graphButton) {
    this.graphButton
    .attr("y", (this.height - ICON_HEIGHT) / 2)
    .attr("x", TopBarGroup.LABEL_WIDTH - ICON_WIDTH - ICON_MARGIN);
  }

  if (this.contentDriversButton) {
    this.contentDriversButton
    .attr("y", (this.height - ICON_HEIGHT) / 2)
    .attr("x", TopBarGroup.LABEL_WIDTH - ICON_CONTENT_DRIVERS_MARGIN);
  }

  if (this.expandButton) {
    this.label.attr("x", TopBarGroup.LABEL_LEFT_MARGIN + SMALL_ICON_WIDTH);
  }

  if (this.icon_label) {
    this.icon_label
    .attr("x", 0)
    .attr("y", (this.height - ICON_HEIGHT) / 2);
  }

  if (this.background) {
    this.background
    .attr("width", TopBarGroup.LABEL_WIDTH + ICON_WIDTH + 10)
    .attr("height", this.height - 20)
    .attr("y", 10)
    .attr("rx", 4)
    .attr("ry", 4);

    this.background.classed("group-background", true);
  }

};

TopBarGroup.prototype._setBarElement = function(bar, record){
  let self = this;
  d3.select(bar)
    .attr("x", record.start)
    .attr("class", "share-bar " + (c.isString(record.class) ? record.class : TopBarGroup.DEFAULT_CLASS))
    .attr("height", TopBar.HEIGHT)
    .attr("width", record.width)
    .attr("title", record.title)
    .on("mouseover", function(){d3.event.stopPropagation()})
    .on("mouseout", function(){d3.event.stopPropagation()});
  if (record.title !== "") {
    add_tooltip(bar, 'info', {style: {classes: 'common-tooltip-info'}, prerender: true,
      events: {
        render: function(event, api) {
          self.share_bar_tooltips[record.id] = api;
        }
     }
    });
  }
};

TopBarGroup.prototype.destroy = function() {
  _.each(this.split_bars, (bar) => bar.destroy());
  _.each(this.share_bar_tooltips, (api) => api.destroy(true));
  this.element.selectAll("*").remove();
};

TopBarGroup.height = function(size) {
  return (size * TopBar.HEIGHT) + ((size - 1) * BARS_VERTICAL_MARGIN + (CONTENT_VERTICAL_MARGIN * 2));
};

TopBarGroup.prototype.setDomain = function(x) {
  this.x = x;
};

module.exports = TopBarGroup;
