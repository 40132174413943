var ex = require("infra/utils/export");

function ChannelsController($scope, insightsExportService, $timeout) {
    insightsExportService.setTitle('Channels');

    $scope.showConsumptionChannelWidget = () => $scope.context.current.insightsChannels.value === 'articles';

    if($scope.$root.contentDriversOpen) {
        $timeout(() => $(".widget-panel.channels-panel").addClass("content-drivers-open"));
    }
}

ChannelsController.$inject = ['$scope', 'insightsExportService', '$timeout'];

module.exports.stateConfig = {
    name: "channels",
    url: "/channels",
    template: require("./channels.insights.html"),
    display: "Insights",
    data: {
      permissions: ['channels']
    },
    controller: ChannelsController,
    context: {}
};
