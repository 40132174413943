import 'angular-modal-service';
import config from 'infra/config';
var sparkline = require("gadgets/sparkline"),
    jqSparkline = require("gadgets/jquery-sparkline");

angular.module(__filename, [
    sparkline.name,
    'angularModalService',
    require('data/settings/alerts-export-service').name,
    require('data/insights/alerts-insights-service').name,
    require('common/modals/confirmation/confirm-action.modal.service.js').name,
    require('./alert-dialog/alert-dialog').name,
    require('./alerts-settings/alerts-settings').name,
    require('./alert-consumption-graph/alert-consumption-graph').name
]).controller('AlertsV2Controller', ['$scope', 'alertsV2Service', 'ModalService', 'confirmAction', 'programBooleanLogicService',
    'consumptionTrendService', 'util', /*'alertsExportService',*/ /*'alertsInsightsService',*/ 'examplesDataModel', 'notificator', '$http',
    function ($scope, alertsInterface, ModalService, confirmAction, pblService, consumptionService,
              util, /*exportService,*/ /*alertsInsightsService,*/ examplesDataModel, notificator, $http) {
        $scope.alertExcel = function(id) { $scope.alertID = id};
        $scope.sentimentDetection = alertsInterface.consts.SENTIMENT_TYPES;
        populate();
        $scope.p_id = $scope.$root.context.program.id;
        var removeListener = $scope.$root.$on('context-updated', function (event, newVal) {
            if (newVal.p_id && $scope.p_id != newVal.p_id) {
                $scope.gotData = false;
                angular.element(document.querySelector('#alert-dialog-overlay')).remove();
                $scope.alerts = [];
                populate();
                $scope.p_id = newVal.p_id;
                $scope.examplesData = examplesDataModel;
            }
        });

        // $scope.getAlertGraphData = getAlertGraphData;

        $scope.$on('$destroy', removeListener);

        // $scope.$root.createExcelWorkbook = function () {
        //     var excel = exportService.exportToExcel($scope.alerts, $scope.settings, interestsValues);
        //     exportService.downloadExcel(excel);
        // };
        //
        // $scope.createAlertExcelWorkbook = function() {
        //     var localAlert = $scope.alerts.filter(function (alrt) { return alrt.id == $scope.alertID})
        //     var url = config.USER_MGMT_API + '/programs/' + $scope.p_id + '/alerts/' + $scope.alertID + '/report';
        //     return $http.get(url).then(function (response) {
        //         var excel = exportService.exportAlertToExcel(localAlert, $scope.settings, response.data)
        //         exportService.downloadExcel(excel)
        //     }, function failedRequest() {
        //         // Some recovery code
        //         // return
        //     })
        // };

        var sparklineOptions = {
            width: "200px",
            height: "60px",
            lineColor: "#999999",
            fillColor: "transparent",
            minSpotColor: "",
            maxSpotColor: "",
            highlightSpotColor: null,
            highlightLineColor: null,
            chartRangeMinX: "-1",
            chartRangeMaxX: "8",
            valueSpots: {"0:200": "#999999"},
            spotRadius: "2.6",
            spotColor: "#999999"
        };

        // $scope.getSparklineOptions = function (values, alertLastRunTime) {
        //     if (!$scope.settings) return {};
        //
        //     var totals = _(values).map((item) => parseFloat(item.total));
        //     var maxValue = totals.max(),
        //         minValue = totals.min();
        //
        //     var options = angular.copy(sparklineOptions);
        //     options.chartRangeMin = (minValue - 5).toString();
        //     options.chartRangeMax = (maxValue + 5).toString();
        //
        //     if ($scope.settings.interval.type == 'Weekly') {
        //         if (!alertLastRunTime) return options;
        //         var runDate = moment(alertLastRunTime).subtract(1, 'days').format('YYYY-MM-DD');
        //         var runDateIndex = _.findIndex(values, {date: runDate});
        //         if (runDateIndex == values.length - 1) {
        //             options.spotColor = "#2c76e0";
        //         } else {
        //             var blueDot = parseFloat(values[runDateIndex].total).toString();
        //             options.valueSpots[blueDot] = "#2c76e0";
        //         }
        //
        //         if (runDateIndex - 1 >= 0) {
        //             var orangeDot = parseFloat(values[runDateIndex - 1].total).toString();
        //             options.valueSpots[orangeDot] = "#fc942e";
        //         }
        //     } else {
        //         options.spotColor = "#2c76e0";
        //         var pointValue = parseFloat(values.slice(-2)[0].total).toString();
        //         options.valueSpots[pointValue] = "#fc942e";
        //     }
        //
        //     return options;
        // };

        $scope.tooltipFormatter = function (sparkline, options, fields) {
            var value = fields.y + parseFloat(options.tag.attributes.gap.value);
            return '<span><span style="color: ' + fields.color + ';">&#9679;</span> ' + value.toFixed(2) + '</span>';
        };

        $scope.clickSparkline = function () {
            // do nothing
        };

        // $scope.openGraph = function (interest, alert) {
        //     getAlertGraphData(interest, alert).then(function (interestData) {
        //         ModalService.showModal({
        //             template: require('./alert-consumption-graph/alert-consumption-graph.html'),
        //             inputs: {interest: angular.copy(interestData), alert},
        //             controller: 'alertsV2ConsumptionGraph'
        //         }).then(function (modal) {
        //             modal.close.then();
        //         });
        //     });
        // };

        // var prev_interest = "";
        // $scope.openExamples = function (interest, alert) {
        //     $scope.examplesData = examplesDataModel;
        //     $scope.examplesData.visible_examples = [];
        //     $scope.examplesData.visible = true;
        //     $scope.examplesData.alphabetized = false;
        //     $scope.examplesData.filter_type = 'trend';
        //     $scope.contentDriversTargets = true;
        //     $scope.selected_interest = interest;
        //
        //     if(prev_interest != interest.display){
        //         $scope.$root.$broadcast('openContentDrivers', "content_drivers");
        //         prev_interest = interest.display;
        //     }else{
        //         $scope.$root.$broadcast('closeContentDrivers', "content_drivers");
        //         prev_interest = "";
        //     }
        //
        //     $scope.examplesData.promise = getAlertGraphData(interest, alert);
        //     $scope.examplesData.promise.then(function (interestData) {
        //         $scope.examplesData.icon = util.common.getChannelIcon(alert.channel);
        //         interestData.visible_examples.forEach(function (exmpl) {
        //             if (!exmpl.domain) exmpl.domain = exmpl.user
        //         });
        //         $scope.examplesData.visible_examples = interestData.visible_examples;
        //     });
        // };

        $scope.getAlertType = function (alert) {
            var type = alert.type;
            if (alert.type == 'sentiment') {
                type = _.find($scope.sentimentTypes, {id: alert.sentiment_type}).label;
            }

            return _.capitalize(type);
        };

        $scope.capitalize = function (value) {
            return _.capitalize(value);
        };

        $scope.deleteAlert = deleteAlert;
        $scope.disableAlert = disableAlert;
        $scope.editAlert = openAlertDialog;
        $scope.interestChangeValue = getInterestChangeValue;
        $scope.interestsSummary = interestsSummary;
        $scope.isInterestActive = isInterestActive;
        $scope.isAlertEmpty = isAlertEmpty;
        $scope.alertTooltip = alertTooltip;
        $scope.editSettings = editSettings;
        // $scope.interestSparkline = interestSparkline;


        var separator = $('#alerts-separator');
        separator.hide();
        var container = $('#alerts-content');
        container.scroll(showSeparatorOnScroll);

        function showSeparatorOnScroll() {
            if (container.scrollTop() == 0) {
                separator.hide();
            } else {
                separator.show();
            }
        }

        var programBooleanLogics;

        function populate() {
          $scope.gotData = false;
            pblService.list().then(function (data) {
                programBooleanLogics = data;
                populateAlerts();
            });
        }

        function populateAlerts() {
            var settingsPromise = getAlertsSettings();
            var alertsBeforePopulate = {};
            ($scope.alerts || []).forEach(function (alert) {
                alertsBeforePopulate[alert.id] = alert.interests_open || false;
            });
            alertsInterface.alerts.list().then(function (data) {
                console.log('data->', data);
                $scope.alerts = data && data.alerts;
                if (!$scope.alerts || !$scope.alerts.length) {
                    settingsPromise.then(() => openAlertDialog());
                }

                $scope.alerts.forEach(function (alert) {
                    var alertInterests = angular.copy(alert.interests);
                    alert.interests.filter(function (term) {
                        return term.type === 'programBL';
                    }).forEach(function (term) {
                        var pbl = programBooleanLogics.find((bl) => +term.id === +bl.id);

                        var index = _.findIndex(alert.interests, function (t) {
                            return t.id === term.id;
                        });

                        if (pbl) {
                            alert.interests[index] = pbl;
                        } else {
                            alert.interests.splice(index, 1);
                        }
                    });

                    if (!angular.equals(alertInterests, alert.interests)) {
                        alertsInterface.alerts.update(alert.id, alert);
                    }

                    alert.interests_open = alertsBeforePopulate[alert.id];
                    if (Object.keys(alertsBeforePopulate).length > 0 && alert.interests_open == undefined) {
                        alert.interests_open = true;
                    }
                });
                populateInterestsValues();
                $scope.gotData = true;
           });
        }

        function getAlertsSettings() {
            return alertsInterface.alertsNotificationSettings.list().then(function (data) {
                $scope.settings = data;
                $scope.settingsMissing = !data;
            });
        }

        function editSettings() {
            ModalService.showModal({
                template: require('./alerts-settings/alerts-settings.html'),
                inputs: {settings: angular.copy($scope.settings['alerts_notification_settings'][0]), firstTime: false},
                controller: 'alertsV2SettingsController'
            }).then(function (modal) {
                modal.close.then(function (result) {
                    if (result && result.reload) getAlertsSettings();
                });
            });
        }

        var interestsValues;

        function populateInterestsValues() {
            interestsValues = {};
            $scope.alerts.forEach(function (alert) {
                // interestsValues[alert.id] = {};
                // alert.results.forEach(function (result) {
                //     var index = _.findIndex(alert.display_interests, {text: result.phrase});
                //     if (index != -1) {
                //         interestsValues[alert.id][result.phrase] = result;
                //         var isActive = isInterestAlertActive(alert, result);
                //         alert.display_interests[index].active = isActive;
                //         interestsValues[alert.id][result.phrase].active = isActive;
                //         if (isActive) alert.active = true;
                //     }
                // });
            });
        }

        function isInterestAlertActive(alert, values) {
            var avg_active = false,
                previous_active = false,
                triggers = alert.triggers,
                alertChange = alert.is_increase;

            if (triggers.compare_to_average.selected) {
                avg_active = compareValue(values.average.percentage_change, triggers.compare_to_average.percentage, alertChange)
                    || compareValue(values.average.std_change, triggers.compare_to_average.stdev, alertChange);
            }

            if (triggers.compare_to_previous.selected) {
                previous_active = compareValue(values.previous.percentage_change, triggers.compare_to_previous.percentage, alertChange)
                    || compareValue(values.previous.std_change, triggers.compare_to_previous.stdev, alertChange);
            }

            return avg_active || previous_active;
        }

        function compareValue(value, compareTo, isIncrease) {
            if (!compareTo) return false;
            if (!isIncrease) value *= -1;
            return value >= compareTo;
        }

        function getInterestChangeValue(alert, interest, field) {
            // if (!interestsValues[alert][interest]) return undefined;
            //
            // return interestsValues[alert][interest][field];
        }

        function isInterestActive(alert, interest) {
            // return !(interestsValues[alert][interest] && interestsValues[alert][interest].active);
        }

        function isAlertEmpty(alert) {
          return _.isEmpty(alert.interests);
        }

        function interestsSummary(alert) {
            if (alert.is_active) return 'Alert is disabled';
            if (isAlertEmpty(alert)) return 'Alert has no seeds, Please edit or delete the alert';

            var summary;
            var alertValues = interestsValues[alert.id];
            var activeInterests = Object.keys(alertValues).filter(function (key) {
                return alertValues[key].active;
            });

            var length = activeInterests.length;

            switch (length) {
                case 0:
                    summary = 'No active interests';
                    break;
                case 1:
                    summary = activeInterests[0] + ' is active';
                    break;
                case 2:
                    summary = activeInterests.join(' and ') + ' are active';
                    break;
                default:
                    summary = activeInterests.slice(0, 2).join(', ') + ' and ' + (length - 2) + ' more active interests';
            }

            return summary
        }

        function alertTooltip(alert) {
            return alert.active ? "Interest's trigger is active" : "Interest's trigger is inactive";
        }

        function deleteAlert(id, index) {
            var msg = "Are you sure you want to delete the alert \"" + $scope.alerts[index].name + "\"?";
            confirmAction.getConfirmation(msg).then(function (modal) {
                modal.close.then(function (confirmation) {
                    if (confirmation) {
                        alertsInterface.alerts.delete(id)
                          .then(() => $scope.alerts.splice(index, 1));
                    }
                });
            });
        }

        function disableAlert(alert, index) {
            var newStatus = alert.is_active ? " enable " : " disable ";
            var msg = "Are you sure you want to" + newStatus + "the alert \"" + $scope.alerts[index].name + "\"?";

            confirmAction.getConfirmation(msg).then(function (modal) {
                modal.close.then(function (confirmation) {
                    if (confirmation) {
                        alert.is_active = !alert.is_active;
                        alertsInterface.alerts.update(alert.id, alert).then(
                            function (response) {
                                msg = alert.is_active ? "Deactivation" : "Activation";
                                if (response.failed) {
                                    notificator.error({body: msg + ' failed, please try again later'});
                                    alert.is_active = !alert.is_active; // back to old state
                                }
                                if (alert.is_active) alert.interests_open = false;
                            }
                        );
                    }
                });
            });
        }

        function openAlertDialog(alert) {
            var current_alerts_names = $scope.alerts.map(alert => alert.name.toLowerCase());
            if (alert) current_alerts_names.splice(current_alerts_names.indexOf(alert.name.toLowerCase()), 1);

            ModalService.showModal({
                template: require('./alert-dialog/alert-dialog.html'),
                inputs: {alert: angular.copy(alert), currentAlertsNames: current_alerts_names, settingsMissing: $scope.settingsMissing},
                controller: 'alertV2DialogController',
                scope: $scope
            }).then(function (modal) {
                modal.close.then(function (result) {
                    if (result && result.reload) populate();
                });
            });
        }

        // var defaultSettings = {
        //     topics: [],
        //     geos: [],
        //     insightsChannel: {value: 'articles'},
        //     toNormalize: true
        // };

        // function extractSettings(alert) {
        //     var channel = (alert.channel === "") ? null : {value: alert.channel};
        //     var geo = alert.geos && alert.geos[0] ? alert.geos : null;
        //     return {
        //         geos: geo || [],
        //         sensitive_content: alert.sensitive_content,
        //         insightsChannel: channel || {value: 'articles'}
        //     };
        // }

        // function getTimeframe(benchmarkTimeframe) {
        //     var endTimeframe = $scope.settings.interval.type == 'As it happens' ? moment() : moment().subtract(1, 'days');
        //     var startTimeframe = endTimeframe.clone();
        //     switch (benchmarkTimeframe) {
        //         case 'last_month':
        //             startTimeframe.subtract(30, 'days');
        //             break;
        //         case 'last_quarter':
        //             startTimeframe.subtract(91, 'days');
        //             break;
        //         case 'last_6_months':
        //             startTimeframe.subtract(182, 'days');
        //             break;
        //         case 'last_year':
        //             startTimeframe.subtract(364, 'days');
        //             break;
        //     }
        //
        //     return [startTimeframe.format('DD/MM/YY'), endTimeframe.format('DD/MM/YY')];
        // }

        // function crunchSparklineNumbers(data) {
        //     var dataPoints = data.graphData.chart[0].series;
        //     var avgValue = calcAverage(dataPoints);
        //     var gap = (avgValue - 50).toFixed(2);
        //
        //     var r = {
        //         todayValue: dataPoints[0].value,
        //         yesterdayValue: dataPoints[1].value,
        //         avgValue: avgValue,
        //         gap: gap
        //     };
        //
        //     var graphValues = [];
        //     dataPoints.slice(0, 8).forEach(function (item) {
        //         graphValues.unshift({total: (item.value - gap).toFixed(2), date: moment(item.date).format('YYYY-MM-DD')});
        //     });
        //
        //     r.graphValues = graphValues;
        //     return r
        // }

        // function calcAverage(dataPoints) {
        //     var length = dataPoints.length;
        //     var sum = _.reduce(dataPoints, (sum, point) => sum + point.value, 0);
        //     return (sum / length).toFixed(2)
        // }

        // function pointsStatistics(r) {
        //     return {
        //         avg_change: (r.todayValue - r.avgValue).toFixed(2) + ' pts.',
        //         prev_change: (r.todayValue - r.yesterdayValue).toFixed(2) + ' pts.'
        //     }
        // }

        // function percentageStatistics(r) {
        //     return {
        //         avg_change: (r.avgValue > 0 ? (100 * (r.todayValue - r.avgValue) / r.avgValue).toFixed(2) : '1000+') + '%',
        //         prev_change: (r.yesterdayValue > 0 ? (100 * (r.todayValue - r.yesterdayValue) / r.yesterdayValue).toFixed(2) : '1000+') + '%'
        //     }
        // }

        // function interestSparkline(interest, alert, benchmarkTimeframe) {
        //     getAlertGraphData(interest, alert, true).then(crunchSparklineNumbers).then(function (r) {
        //         interest.avg = r.avgValue;
        //         interest.gap = r.gap;
        //         Object.assign(interest, alert.sentiment_type == 'neutral' ? pointsStatistics(r) : percentageStatistics(r));
        //         interest.values = r.graphValues;
        //     }).catch(_.noop);
        // }

        // function getAlertGraphData(interest, alert, sparkline) {
        //     sparkline ? interest.sparkline = false : $scope.waiting = true;
        //     var parameters = util.buildInsightsParameters(angular.extend(
        //         {terms: [interest], timeframe: getTimeframe(alert.benchmark_timeframe)}, defaultSettings, extractSettings(alert)
        //     ));
        //     parameters.triggers = alert.triggers;
        //     if (parameters.channel == 'tweets') parameters.to_normalize = false;
        //     parameters.sentiment_type = alert.sentiment_type;
        //     parameters.is_increase = alert.is_increase;
        //     parameters.type = alert.type;
        //     return alertsInsightsService.getInsights(parameters).then(function (data) {
        //         interest.examples = data.examples;
        //         interest.visible_examples = data.visible_examples;
        //         interest.graphData = data.graphData;
        //         interest.activationThreshold = data.activation;
        //         $scope.waiting = false;
        //         sparkline ? interest.sparkline = true : $scope.waiting = false;
        //         return interest;
        //     });
        // }
    }
]).stateConfig = {
    name: 'alerts-v2',
    url: '/alerts-v2',
    template: require('./alerts-v2.html'),
    controller: 'AlertsV2Controller',
    display: 'Alerts V2',
    data: {
      permissions: ['alerts']
    }
};

module.exports = angular.module(__filename);
