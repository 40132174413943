import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import DateRangePickerWithSingleSelectToggle from '../../../../common/DateRangePickerWithSingleSelectToggle/DateRangePickerWithSingleSelectToggle';
import moment from 'moment';
import { compact } from 'lodash';

import './TvCustomTimeframe.scss';

const MIN_START_DATE = new Date('2019-08-01');
const DEFAULT_TIMEFRAME = '3 months';

const TvCustomTimeframe = ({
  isLightTheme,
  startDateString,
  endDateString,
  timeframe,
  selectedStartDateCB,
  selectedEndDateCB,
  selectedTimeframeCB,
  isDisabled,
  isAudienceInline,
  maxThreeMonths,
}) => {
  const startDate = timeframe ? getStartDateByTimeframe(timeframe) : convertToDate(startDateString);
  const endDate = timeframe ? moment().toDate() : convertToDate(endDateString);
  const [selectedTimeframe, setSelectedTimeframe] = useState(
    timeframe || (startDate && endDate ? null : DEFAULT_TIMEFRAME)
  );
  const [selectedStartDate, setSelectedStartDate] = useState(startDate || getStartDateByTimeframe(selectedTimeframe));
  const [selectedEndDate, setSelectedEndDate] = useState(
    endDate || getEndDateByTimeframe(selectedTimeframe, selectedStartDate)
  );

  const timeframes = compact([
    { label: '1W', value: '1 week' },
    { label: '1M', value: '1 month' },
    { label: '3M', value: '3 months' },
    !maxThreeMonths && { label: '6M', value: '6 months' },
  ]);

  useEffect(() => {
    if (!startDateString && !endDateString && !timeframe) {
      selectedTimeframeCB(DEFAULT_TIMEFRAME);
    }
  }, [startDateString, endDateString, timeframe]);

  const onSelectTimeframe = useCallback((selectedTimeframe) => {
    const startDate = getStartDateByTimeframe(selectedTimeframe);
    const endDate = getEndDateByTimeframe(selectedTimeframe, startDate);
    setSelectedTimeframe(selectedTimeframe);
    setSelectedStartDate(startDate);
    setSelectedEndDate(endDate);
    selectedTimeframeCB(selectedTimeframe);
  }, []);

  const onStartDateSelect = useCallback((selectedDate) => {
    setSelectedTimeframe(null);
    setSelectedStartDate(selectedDate);
    selectedTimeframeCB(null);
  }, []);

  const onEndDateSelect = useCallback((selectedDate) => {
    setSelectedTimeframe(null);
    setSelectedEndDate(selectedDate);
    selectedTimeframeCB(null);
  }, []);

  useEffect(() => {
    selectedStartDateCB(selectedStartDate);
  }, [selectedStartDate]);

  useEffect(() => {
    selectedEndDateCB(selectedEndDate);
  }, [selectedEndDate]);

  function convertTimeframeToUnitValue(timeframe) {
    if (!timeframe) return [null, null];
    const [unit, value] = timeframe.split(' ');
    return unit && value ? [unit, value] : [null, null];
  }

  function getStartDateByTimeframe(timeframe) {
    if (!timeframe) return null;
    const [unit, value] = convertTimeframeToUnitValue(timeframe);
    const selectedStartDate = moment().subtract(unit, value).toDate();
    return selectedStartDate < MIN_START_DATE ? MIN_START_DATE : selectedStartDate;
  }

  function getEndDateByTimeframe(timeframe, startDate) {
    if (!timeframe) return null;
    let date;
    if (startDate) {
      const [unit, value] = convertTimeframeToUnitValue(timeframe);
      date = moment(startDate).add(unit, value);
    } else {
      date = moment();
    }
    return date.toDate();
  }

  function convertToDate(str) {
    if (!str) return null;
    const date = new Date(str);
    return date instanceof Date && !isNaN(date) ? date : null;
  }

  return (
    <div className="tv-custom-timeframe-component">
      <DateRangePickerWithSingleSelectToggle
        isLightTheme={isLightTheme}
        startDate={selectedStartDate}
        endDate={selectedEndDate}
        minDate={MIN_START_DATE}
        onStartDaySelected={onStartDateSelect}
        onEndDaySelected={onEndDateSelect}
        selectToggleValues={timeframes}
        selectedToggleValue={selectedTimeframe}
        onToggleSelect={onSelectTimeframe}
        isDisabled={isDisabled}
        isAudienceInline={isAudienceInline}
      />
    </div>
  );
};

TvCustomTimeframe.propTypes = {
  isLightTheme: PropTypes.bool,
  startDateString: PropTypes.string,
  endDateString: PropTypes.string,
  timeframe: PropTypes.string,
  selectedStartDateCB: PropTypes.func.isRequired,
  selectedEndDateCB: PropTypes.func.isRequired,
  selectedTimeframeCB: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  isAudienceInline: PropTypes.bool,
  maxThreeMonths: PropTypes.bool,
};

TvCustomTimeframe.defaultProps = {
  isLightTheme: true,
  isDisabled: false,
  isAudienceInline: false,
  maxThreeMonths: false,
};

export default TvCustomTimeframe;
