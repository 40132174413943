import config from '../../infra/config';

const HOST = config.PROXY_TOWER_API;

export default {
  getSegmentIds: (audience) => ({
    url: `${HOST}/api/1/get-segment-ids`,
    payload: { ...audience },
  }),
  getRawDemographicsDataByLogicalStatement: (payload, params) => ({
    method: 'post',
    params,
    payload,
    url: `${HOST}/api/v2/audience/demographics`,
  }),
  getRawInterestsDataV1: (payload) => ({
    method: 'post',
    payload,
    url: `${HOST}/api/1/ui-related-keywords2`,
  }),
  getRawInterestsDataV2: (payload, searchType, uriSuffix) => ({
    method: 'post',
    payload,
    url: `${HOST}/api/v2/audience/interests/${searchType}${uriSuffix}`,
  }),
};
