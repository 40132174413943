module.exports = class ConceptManager

	seed_idx: {}
	concept_idx: {}

	constructor: () ->
		@clean()

	reset: () =>
		@clean()

	clean: () =>
		seeds = d3.keys( @seed_idx )
		for seed in seeds
			delete @seed_idx[seed]
		@concept_idx = {}
		@seed_idx = {}
		return seeds

	remove_seed:(keyword) =>
		if $.inArray( keyword, @get_seeds() ) >= 0  # if the keyword is not stored, nothing to do here

			brethren = @seed_idx[keyword].ids

			while ( brethren.length > 0 )
				key = parseInt(brethren.shift())
				@concept_idx[ key ] = null
				delete @concept_idx[ key ]

			@seed_idx[keyword] = null
			delete @seed_idx[keyword]

	typeIsArray = Array.isArray || ( value ) -> return {}.toString.call( value ) is '[object Array]'

	seeds_to_array:(given_seeds) ->
		if typeIsArray(given_seeds)
			candidate_seeds = given_seeds.map (kwd) -> kwd.toLowerCase()
		else
			candidate_seeds = [given_seeds.toLowerCase()]
		return candidate_seeds

	remove_concept: (target_id) =>

		seed_id 	= @concept_idx[target_id].seed
		location 	= @seed_idx[seed_id].ids.indexOf( target_id )
		@seed_idx[seed_id].ids.splice( location, 1)
		deleted_concepts = []
		$.each( @concept_idx, (key, value) =>
			if value.id == target_id
				@concept_idx[ key ] = null
				delete @concept_idx[ key ]
		)
		return deleted_concepts;

	get_seeds: () ->
		seeds = d3.keys(@seed_idx)
		return seeds

	# this could be cached in the concept, but it will need to be refreshed if the seed is removed...
	is_vip_concept:(concept) => concept.seed == concept.word.toLowerCase()

	# prefer VIP concepts in spite of their volume
	compare_concepts:(left, right) =>
		left_vip 	= @is_vip_concept(left)
		right_vip	= @is_vip_concept(right)
		return right.volume - left.volume if (left_vip and right_vip)
		return -1 if left_vip
		return +1 if right_vip
		return right.volume - left.volume


	relocate_concept:( concept, new_seed ) =>
		old_seed = concept.seed
		old_index = $.inArray( concept.id, @seed_idx[old_seed].ids )
		@seed_idx[old_seed].ids.splice( old_index, 1 ) # remove the concept from the old seed records

		concept.seed 		= new_seed
		concept.color 	= @seed_idx[new_seed].color
		@seed_idx[new_seed].ids.unshift( concept.id )		# add the concept to the front of the new seed records


	add_concepts: ( seed, concepts, color ) =>
		new_ids = []
		the_values = d3.values(concepts).sort( @compare_concepts ) #

		$.each( the_values, ( idx, metadata ) =>
			value 	= parseFloat(metadata.volume)
			id 		= metadata.id
			if ( value > 0  )
				if @concept_idx[id]?
					# if this is a seed concept that appears also in other seed (i.e. 'iPhone' concept that appears both in 'Apple' seed and 'iPhone' seed),
					# the data of the concept should be that data as retrieved from the 'iPhone' seed rather than the 'Apple' seed, as it will be deleted from
					# the 'Apple' seed and will appear only in 'iPhone'. In order to let the relocate_concept method find the concept, delete it from the 'Apple' seed
					# and relocate it in the 'iPhone' seed, we should leave the old seed & color values, but put all the scores from the 'iPhone' seed
					@concept_idx[id] = $.extend(metadata, {value: value, seed: @concept_idx[id].seed, color: color }) if metadata.isSeed
				else
					new_ids.push( id )
					@concept_idx[id] = $.extend(metadata, {id: id, value: value, color: color, seed: seed })
			)
		@seed_idx[seed] =
			ids: new_ids
			color: color

		# regroup seed concepts into their own group
		seed_concepts = $.grep( d3.values( @concept_idx ), (concept) -> concept.word.toLowerCase() == seed.toLowerCase())
		$.each( seed_concepts, (idx, sc) =>
			@relocate_concept( sc, seed) )
