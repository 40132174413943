import React from 'react';
import PropTypes from 'prop-types';
import './ConfirmationButtons.scss';
import ClickableLabel from '../ClickableLabel/ClickableLabel';
import SubmitButton from '../SubmitButton/SubmitButton';

const ConfirmationButtons = ({
  cancelLabel,
  onCancelClick,
  isCancelDisabled,
  submitLabel,
  onSubmitClick,
  isSubmitDisabled,
  submitButtonTooltip,
  isLightTheme,
  isCancelTransparent,
  buttonsWidth,
}) => {
  if (cancelLabel && !onCancelClick) throw new Error('onCancelClick must be defined when cancelLabel exists');
  if (onCancelClick && !cancelLabel) throw new Error('cancelLabel must be defined when onCancelClick exists');

  return (
    <div className="confirmation-buttons-component">
      {onCancelClick &&
        (isLightTheme ? (
          <SubmitButton
            label={cancelLabel}
            onClick={onCancelClick}
            isDisabled={isCancelDisabled}
            isTransparent={isCancelTransparent}
            isLightTheme={isLightTheme}
            width={buttonsWidth}
            className="confirm-cancel"
          />
        ) : (
          <ClickableLabel
            className="cancel-label"
            label={cancelLabel}
            onClick={onCancelClick}
            isDisabled={isCancelDisabled}
          ></ClickableLabel>
        ))}
      <SubmitButton
        label={submitLabel}
        onClick={onSubmitClick}
        isDisabled={isSubmitDisabled}
        tooltip={submitButtonTooltip}
        isLightTheme={isLightTheme}
        width={buttonsWidth}
      ></SubmitButton>
    </div>
  );
};

ConfirmationButtons.propTypes = {
  cancelLabel: PropTypes.string,
  onCancelClick: PropTypes.func,
  isCancelDisabled: PropTypes.bool,
  submitLabel: PropTypes.string.isRequired,
  onSubmitClick: PropTypes.func.isRequired,
  isSubmitDisabled: PropTypes.bool,
  submitButtonTooltip: PropTypes.string,
  isLightTheme: PropTypes.bool,
  isCancelTransparent: PropTypes.bool,
  buttonsWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ConfirmationButtons.defaultProps = {
  isCancelDisabled: false,
  isSubmitDisabled: false,
  isLightTheme: false,
  isCancelTransparent: true,
};

export default ConfirmationButtons;
