import * as MixpanelSettings from '../../react/infra/mixpanel/MixpanelSettings';
import { getFirstPartyDataByUser, deleteFirstPartySegment } from '../../react/services/AudienceInsightsService';

var c = require('infra/utils/common');

const FIRST_PARTY_PERMISSION = 'first party segments';

angular.module(__filename, [
    require('./edit-segment.js').name
  ]
).controller('FirstPartySegmentsController',
  ['$scope', 'programService', 'ModalService', 'confirmAction', 'util', '$timeout', 'abiPermissions',
  function($scope, programService, ModalService, confirmAction, util, $timeout, abiPermissions) {
    const BI_SEGMENT_SOURCE = 1;
    $scope.columns = [
      {label: 'Segment', field: 'name', sortable: true},
      {label: 'Program', field: 'programs', sortable: true},
      {label: 'Created By', field: 'creator.first_name', sortable: true},
      {label: 'Created On', field: 'created_at', sortable: true},
      {label: 'Last Update', field: 'updated_at', sortable: true}
    ];
    populate();

    MixpanelSettings.trackPageView('first_party');

    function populate() {
      getFirstPartyDataByUser($scope.$root.user.id).then((data) => $timeout(function () {
        const hasMySegmentsProgramPermission = abiPermissions.hasPermission('my segments program');
        $scope.segments = _.filter(data, (segment) => hasMySegmentsProgramPermission ||
                                                      segment.first_party_source_id === BI_SEGMENT_SOURCE);
        $scope.gotData = true;
      }));
      programService.userProgramsList($scope.$root.user.id).then(function (data) {
        let programs = _.reject(data, (prog) => util.isMyInterestsProgram(prog) || util.isMySegmentsProgram(prog));
        $scope.user_programs = _.map(programs, function (p) {
          let program = _.clone(p);
          program.text = program.name;
          return program;
        });
      });
    }

    $scope.deleteSegment = function (id, segment_value) {
      const idMap = _.map($scope.segments, 'id');
      const selectedIndex = idMap.indexOf(id);

      const confirmationMsg = "Are you sure you want to delete the first party segment " + $scope.segments[selectedIndex].name + "?";
      const txtConfirm = 'Yes';
      const txtCancel = 'No';

      confirmAction.getConfirmation(confirmationMsg, txtConfirm, txtCancel).then(function (modal) {
        modal.close.then(function (confirmation) {
          if (confirmation) executeDelete(id, segment_value);
        });
      });

      function executeDelete(id, segment_value) {
        deleteFirstPartySegment(id, segment_value).then(function () {
          $scope.segments.splice(selectedIndex, 1);
        });
      }
    };

    $scope.editSegment = function (segment) {
      OpenEditSegmentModal({newSegment: !segment,
                            segment: segment || {},
                            userPrograms: $scope.user_programs,
                            allSegments: segment ? _.reject($scope.segments, {name: segment.name}) : $scope.segments});
    };

    $scope.programsText = function (segment) {
      if(segment.first_party_source_id === BI_SEGMENT_SOURCE) {
        return segment.programs.length > 1 ? segment.programs.length + " Programs" : (segment.programs[0] || {}).name;
      } else {
        return segment.programs.length > 0 ? (segment.programs.length + 1) + " Programs" : "My Segments";
      }
    };

    $scope.canDeleteSegment = (segment) => segment.first_party_source_id === BI_SEGMENT_SOURCE;

    function OpenEditSegmentModal(inputs) {
      ModalService.showModal({
        template: require('./edit-segment.html'),
        inputs: inputs,
        controller: 'editSegmentController'
      }).then(function (modal) {
        modal.close.then(function (result) {
          if (result !== undefined && result.reload) {
            populate();
          }
        });
      });
    }

    $scope.sortByPrograms = function(segment) {
      const programsTextToSort = $scope.programsText(segment);
      return $scope.reverse ? -programsTextToSort : programsTextToSort;
    };
    
    $scope.predicate = $scope.columns[0].field;
    $scope.reverse = false;
    $scope.order = function (predicate) {
      $scope.reverse = ($scope.predicate === predicate) ? !$scope.reverse : false;
      $scope.predicate = predicate;
    };
    
  }
]).stateConfig = {
  name: 'first_party',
  url: '/first_party',
  template: require('./first_party.html'),
  controller: 'FirstPartySegmentsController',
  data: {
    permissions: [FIRST_PARTY_PERMISSION]
  },
  redirectTo: ['abiPermissions', function(abiPermissions) {
    if (!abiPermissions.hasPermission(FIRST_PARTY_PERMISSION)) {
      return 'settings.programs';
    }
  }],
  display: '1st Party Segments'
};

module.exports = angular.module(__filename);
