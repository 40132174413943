import React from 'react';
import PropTypes from 'prop-types';
import HeaderBar from '../HeaderBar/HeaderBar';
import AppCommonElements from '../AppCommonElements/AppCommonElements';

const AppSecondaryBar = ({
  children,
  onCalendarClick,
  onExportClick,
  onFilterClick,
  isCalendarVisible,
  isExportVisible,
  isFilterVisible,
  isDropdownVisible,
  dropdownValues,
  selectedDropdownValue,
  onDropdownChange,
}) => (
  <div className="app-secondary-bar-component">
    <HeaderBar>
      {children}
      <AppCommonElements
        onCalendarClick={onCalendarClick}
        onExportClick={onExportClick}
        onFilterClick={onFilterClick}
        isCalendarVisible={isCalendarVisible}
        isExportVisible={isExportVisible}
        isFilterVisible={isFilterVisible}
        isDropdownVisible={isDropdownVisible}
        dropdownValues={dropdownValues}
        selectedDropdownValue={selectedDropdownValue}
        onDropdownChange={onDropdownChange}
      />
    </HeaderBar>
  </div>
);

AppSecondaryBar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  onCalendarClick: PropTypes.func,
  onExportClick: PropTypes.func,
  onFilterClick: PropTypes.func,
  isCalendarVisible: PropTypes.bool,
  isExportVisible: PropTypes.bool,
  isFilterVisible: PropTypes.bool,
  isDropdownVisible: PropTypes.bool,
  dropdownValues: PropTypes.array,
  selectedDropdownValue: PropTypes.object,
  onDropdownChange: PropTypes.func,
};

export default AppSecondaryBar;
