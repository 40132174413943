import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import BaseModal from '../../../common/Modal/BaseModal/BaseModal';
import './ComplianceModal.scss';

const ComplianceModal = ({
  isOpen,
  phrasesByTab,
  topics,
  text,
  saveWithNonCompliant,
  saveWithoutNonCompliant,
  onCloseCompliance,
}) => {
  const [showMoreTab, setShowMoreTab] = useState('');

  if (!phrasesByTab && !topics) {
    return null;
  }

  return (
    <div className="compliance-modal-component">
      <BaseModal
        isOpen={isOpen}
        isLightTheme={true}
        onXClick={onCloseCompliance}
        onEscKeyPress={onCloseCompliance}
        width="550px"
        modalTitle="Review before saving"
      >
        <div className="compliance-modal-component">
          {text}
          <hr />
          {topics && topics.length > 0 && (
            <div className="main-tabs">
              <div className="phrases-tab-title">Sensitive content in Topics:</div>
              <PhrasesTab
                phrases={topics}
                tab="topics"
                showMore={() => setShowMoreTab('topics')}
                showLess={() => setShowMoreTab('')}
                isShowMore={showMoreTab === 'topics'}
              />
            </div>
          )}
          <div className="main-tabs">
            {Object.keys(phrasesByTab).map((tab, i) =>
              phrasesByTab[tab].length ? (
                <div key={i}>
                  <div className="phrases-tab-title">Sensitive content in {_.capitalize(tab)}:</div>

                  <PhrasesTab
                    phrases={phrasesByTab[tab]}
                    tab={tab}
                    showMore={(tab) => setShowMoreTab(tab)}
                    showLess={() => setShowMoreTab('')}
                    isShowMore={showMoreTab == tab}
                  />
                </div>
              ) : null
            )}
          </div>

          <hr />
          <div className="right">
            <button className="main-button blue on-save" onClick={() => saveWithNonCompliant()}>
              Save
            </button>
            &nbsp; &nbsp;
            <button
              className="main-button blue on-save-and-remove"
              onClick={() => saveWithoutNonCompliant(phrasesByTab)}
            >
              Remove & Save
            </button>
          </div>
        </div>
      </BaseModal>
    </div>
  );
};

const PhrasesTab = ({ phrases, tab, showMore, showLess, isShowMore }) => {
  let str = phrases.join(', ');
  const MAX_CHARS = 120;
  if (str.length < MAX_CHARS) {
    return <div className="phrases-tab">{str}</div>;
  }

  if (isShowMore) {
    return (
      <div className="phrases-tab show-more">
        {str}{' '}
        <a className="show-more-link" onClick={() => showLess()}>
          Show less
        </a>
      </div>
    );
  }
  str = str.substring(0, MAX_CHARS);
  str = str.substring(0, str.lastIndexOf(',') + 1);

  return (
    <div className="phrases-tab">
      {str}{' '}
      <a className="show-more-link" onClick={() => showMore(tab)}>
        Show more
      </a>
    </div>
  );
};

PhrasesTab.propTypes = {
  phrases: PropTypes.array,
  tab: PropTypes.string,
  showMore: PropTypes.func,
  showLess: PropTypes.func,
  isShowMore: PropTypes.bool,
};

ComplianceModal.propTypes = {
  isOpen: PropTypes.bool,
  phrasesByTab: PropTypes.object,
  topics: PropTypes.array,
  text: PropTypes.string,
  saveWithNonCompliant: PropTypes.func,
  saveWithoutNonCompliant: PropTypes.func,
  onCloseCompliance: PropTypes.func,
};

ComplianceModal.defaultProps = {
  isOpen: false,
};

export default ComplianceModal;
