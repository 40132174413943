import { clone, extend, map, isEqual } from 'lodash';
import { getContext } from '../../middleware/AngularExportedMiddleware';
import * as MixpanelCommon from './MixpanelCommon';

const appName = 'Dashboard';
const widgetName = 'Dashboard';
let context = getContext();
let lastTerms = context ? [context.program.brand.name].concat(map(context.program.competitors, 'name')) : [];

export const dashboard = () => {
  context = getContext(); // refresh context value
  lastTerms = context ? [context.program.brand.name].concat(map(context.program.competitors, 'name')) : [];

  const result = {
    appName,
    widget: widgetName,
    geo: map(context && context.current ? context.current.geo : [], 'label'),
  };

  if (context && context.program.brand && context.program.brand.name) {
    const terms = [context.program.brand.name.toLowerCase()];
    result.terms = terms.concat(map(context.program.competitors, 'name'));
  } else {
    result.terms = 'no interests';
  }

  extend(result, MixpanelCommon.buildProgram(context));
  return result;
};

let lastSearch = clone(dashboard());

export const trackPageView = () => MixpanelCommon.trackPageView(appName, widgetName);

export const trackDashboardSearch = () => {
  const props = clone(dashboard());

  if (isEqual(props, lastSearch)) return;

  lastSearch = clone(props);
  MixpanelCommon.sendToMixpanel('Dashboard - Search', props);

  if (isEqual(props.terms, lastTerms)) return;

  // false since boolean_logics are not available in Dashboard. Test coverage show this code inaccessible.
  MixpanelCommon.trackSearch(appName, widgetName, props.terms, false);
  lastTerms = props.terms;
};
