import config from 'infra/config';

var KWDS_VELOCITY_API = config.TRENDLINE_API + "/kwds_velocity";

module.exports = require( 'angular' ).module( __filename, [] )
.service( 'rtAssociationsService', [ '$http' , 'topicsTree' , 'geoService', 'errorMgmt',
	function( $http, topicsTree, geoService, errorMgmt) {

		function getKwdsVelocity(keyword_id, top_assoc_k_id, geos) {
			return $http({
				url: KWDS_VELOCITY_API,
				method: 'GET',
				cache: true,
				params: {
					"phrases[]": top_assoc_k_id,
					"cc_filter[]": _.map(geoService.serverValue(geos),'id'),
					keyword_id: keyword_id,
					timeframe_start: moment().subtract(1, "month").startOf('day').format(),
					timeframe_end: moment().startOf('hour').format(),
					sensitive_content: topicsTree.isSensitive
				}
			})
		};

		function responseFailed ( err ) {
			errorMgmt.widgetServiceError( 'Top Associations', err );
			return [];
		};
		return {
				getVelocity: (brandId, associations, geos) => {
					const top_assoc_k_id = _.map(associations, 'id');
					return getKwdsVelocity(brandId, top_assoc_k_id, geos).then(res => {
							var velocity = res.data;
							var top_assoc_res = [];
							return associations.map(assoc => ({phrase: assoc.label, score: velocity[assoc.id]}));
					}, responseFailed);
				}
		}
	}]);
