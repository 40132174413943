module.exports = angular.module(__filename, [require('./program-service').name]).
    service('userMgmt', ['programService', 'mgmtServiceFactory', 'ssoService', function (programService, mgmtServiceFactory, ssoService) {
        var service = mgmtServiceFactory.createService('user');

        return {
            options: getUserOptions,
            list: service.list,
            user: service.get,
            add: addUser,
            update: updateUser,
            bulkEdit: bulkEditUsers,
            deleteUser: deleteUser,
            resetLegacyPassword: resetLegacyUserPassword,
            resetPasswordRequest: resetPasswordRequest,
            buildUser: buildUser
        };

        ///////////////////////////
        function buildUser(user) {
            var built = {
                id: user.id,
                first_name: user.first_name,
                last_name: user.last_name,
                name: user.first_name + ' ' + user.last_name,
                email: user.email,
                userType: user.user_type.name,
                account: user.account,
                programs: user.programs.map(function (obj) {
                    return programService.buildProgram(obj);
                }),
                permissions: user.permissions,
                languages: user.languages
            };
            built.programs = _.sortBy(built.programs, [(p) => p.name != "my interests",
                                                       (p) => p.name != "my segments",
                                                       (p) => p.name.toLowerCase()]);
            return built;
        }

        function headers(tokenOrTokens) {
          return { 'headers' : { 'SSO-Access-Token' : tokenOrTokens[0].accessToken} };
        }

        function getUserOptions(id) {
            return service.customRequest('GET', '/' + id + '/options');
        }

        function addUser(user) {
          if (ssoService.isUsingSso()) {
            return ssoService.authorize().then(function(tokenOrTokens) {
              return service.create(user, headers(tokenOrTokens)).then(buildUser);
            });
          } else {
            return service.create(user).then(buildUser);
          }
        }

        function updateUser(id, params) {
          delete params["email"]; // we don't support editing emails
          if (ssoService.isUsingSso()) {
            return ssoService.authorize().then(function(tokenOrTokens) {
              return service.update(id, params, headers(tokenOrTokens)).then(buildUser);
            })
          } else {
            return service.update(id, params).then(buildUser);
          }
        }

        function deleteUser(id) {
          if (ssoService.isUsingSso()) {
            return ssoService.authorize().then(function(tokenOrTokens) {
              return service.delete(id, headers(tokenOrTokens));
            });
          } else {
            return service.delete(id);
          }
        }

        function bulkEditUsers(bulkRequest) {
          return service.customRequest('PUT', '/bulk', bulkRequest);
        }

        function resetLegacyUserPassword(id, password) {
            return service.customRequest('POST', '/' + id + '/reset_password', password);
        }

        function resetPasswordRequest(email) {
            return service.customRequest('POST', '/sso/reset_password', {email: email});
        }
    }]
);
