import { isEmpty } from 'lodash';

export async function createPromiseEvaluatedToComponentProps(
  audienceSegment,
  channel,
  userId,
  audienceDistributionService,
  isRequestCancelledService,
  trackTooNarrow,
  hideAudiencePreview,
  householdDistributionService,
  showAudienceIpCount
) {
  const isEmptyAudienceSegment = isEmpty(audienceSegment);
  const householdData = showAudienceIpCount ? await householdDistributionService(audienceSegment, channel) : 0;
  // hideAudiencePreview - in case of hisense AU we don't want to send the demographic call to audience service
  if (isEmptyAudienceSegment || hideAudiencePreview) {
    const response = {
      hasData: false,
      isTooWide: false,
      isTooNarrow: false,
      isEntitiesLimit: false,
      isError: false,
      householdPopulationDist: householdData,
    };
    if (isEmptyAudienceSegment) {
      response.summary = 'All Population';
    }
    return response;
  }

  const data = await audienceDistributionService(audienceSegment, { channel, userId });
  return convertPromiseResultsToComponentProps(data, householdData, isRequestCancelledService, trackTooNarrow);
}

export function convertPromiseResultsToComponentProps(data, householdData, isRequestCancelledService, trackTooNarrow) {
  const MIN_AUDIENCE_SIZE = 1000;

  if (!data) return { hasData: false, isTooWide: false, isTooNarrow: false, isEntitiesLimit: false, isError: true };

  if (isRequestCancelledService(data)) return false; // cancel

  if (data.status === 'ok')
    return {
      hasData: true,
      isTooWide: false,
      isTooNarrow: false,
      isEntitiesLimit: false,
      isError: false,
      ageDist: data.distribution.age,
      genderDist: data.distribution.gender,
      populationDist:
        data.audienceSize.population < MIN_AUDIENCE_SIZE ? MIN_AUDIENCE_SIZE : data.audienceSize.population,
      householdPopulationDist: householdData,
    };

  switch (data.error.reason) {
    case 'no_results':
      return { hasData: false, isTooWide: false, isTooNarrow: false, isEntitiesLimit: false, isError: false };
    case 'selectionTooNarrow':
      trackTooNarrow();
      return { hasData: false, isTooWide: false, isTooNarrow: true, isEntitiesLimit: false, isError: false };
    case 'selectionTooWide':
      return { hasData: false, isTooWide: true, isTooNarrow: false, isEntitiesLimit: false, isError: false };
    case 'too_many_entities':
      return { hasData: false, isTooWide: false, isTooNarrow: false, isEntitiesLimit: true, isError: false };
    default:
      return { hasData: false };
  }
}
