import config from '../../infra/config';
const HOST = config.ACTIVATION_API;

export default {
  checkPhrases: (phrases) => ({
    url: `${HOST}/phrase_compliance`,
    payload: { phrases },
    method: 'post',
  }),

  getSensitivityDataForAudience(audience) {
    return {
      url: `${HOST}/audience_segments/get_sensitivity_data_for_audience`,
      payload: audience,
      method: 'post',
    };
  },
};
