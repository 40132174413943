/*
spinner 
-------

* adds more than 1 if you click a long time

mandatory: value titletext, min
optional: max,def
*/

var DEBOUNCE = 2000;
var VALIDATE = 1000;

module.exports = angular.module(__filename, []).directive("plusMinus", [function() {
    return {
        restrict: 'E',
        require: 'ngModel',
        scope:{
            value: '=',
            def:   '@',
            titletext:'@'},
        template: require('./plus-minus.html'),
        
        link: function(scope, elem, attrs, ngModel){
            var input = $(elem).find("input"), 
                        timeout=null,
                        clicks=0,
                        input = $(elem).find("input[type=text]");

            var val, min = attrs.min ? 1*attrs.min : 0, 
                     max = attrs.max ? 1*attrs.max : 10000,
                     def = attrs.def ? 1*attrs.def : min;

            scope.value = 1*scope.value;
            scope.def = def;
          
            scope.change = function(add){

                clicks++;
                input.attr("unbind", true);
                var val = validate(inc(add));
                input.val(val).attr("class", def == val ? 'def' : '');
                
                clearTimeout(timeout);
                timeout = setTimeout(function(){scope.change(add)},100);
            }

            function inc(add){
                
                //the longer you click the higher the increase

                var val = 1*input.val(), 
                    incAmount = Math.pow(10, Math.floor(clicks/10));

                val+= add*incAmount;

                if(incAmount>1){
                    if(add>0){ //round
                        val += (incAmount-val%incAmount);
                    }else{
                        val -= val%incAmount;
                    }
                }
   
                return val;
            }

            scope.updateModel = function(){

                clicks=0;
                clearTimeout(timeout);       
                timeout = setTimeout(function(){
                    input.attr("unbind", false);
                    scope.value = 1*input.val();
                    scope.$apply();
                }, DEBOUNCE);
            }

            scope.validateTimed = function(){
                clearTimeout(timeout); 
                timeout = setTimeout(function(){
                    var val = validate(input.val());
                    input.val(val).attr("class", def == val ? 'def' : '');
                }, VALIDATE);
            }

            function validate(val){

                if(isNaN(val) || ""==val){
                    val = def;
                }else if(val < min){
                    val = min;
                }else if(max != null && val > max){
                     val = max;  
                }

                return val*1;
            }
        }
    }
}]);

