module.exports = angular.module(__filename, []).service('websitesModal', ['ModalService', 'notificator', function (ModalService, notificator) {
    this.showModal = showModal;

    function showModal(websites, isNewSegment, disableGeo, channel, params) {
        return ModalService.showModal({
            template: require('./websites-modal.html'),
            inputs: {websites: websites, isNewSegment: isNewSegment, channel: channel, params: params},
            controller: ['$scope', 'close', 'websites', 'TermsMold', 'isNewSegment', 'channel', 'params','notificator', WebsitesModalCtrl]
        });
    }

    function WebsitesModalCtrl($scope, close, websites, TermsMold, isNewSegment, channel, params, notificator) {
        $scope.isNewSegment = websites == null;
        $scope.websites = _.extend({required: null, included: null, excluded: null}, websites);
        $scope.close = close;
        $scope.termsMold = new TermsMold();
        $scope.websitesLimit = params.debugUser && channel === 'au_telco' ? 150 : 50;

        $scope.isValid = function() {
          return $scope.websites && $scope.websites.required && getValidWebsites($scope.websites.required).length > 0 ||
                 $scope.websites && $scope.websites.included && getValidWebsites($scope.websites.included).length > 0 ||
                 $scope.websites && $scope.websites.excluded && getValidWebsites($scope.websites.excluded).length > 0;
        };

        function removeEmptyFilters(websites) {
            var results = {};
            if ((websites.required || []).length > 0) results.required = websites.required;
            if ((websites.included || []).length > 0) results.included = websites.included;
            if ((websites.excluded || []).length > 0) results.excluded = websites.excluded;
            return results;
        }

        function onTagsChange(websitesType, tags){
            $scope.websites[websitesType] = tags;
            $scope.$apply();
        }

        function getValidWebsites(websitesArray){
            return (websitesArray || []).filter(val => !val.invalid);
        }

        $scope.notifyLimitExceeded = () => {
            let msg = `There is a limit of ${$scope.websitesLimit} entries`;
            notificator.notify({ body: msg });
        };

        $scope.save = function () {
            this.websites.required = getValidWebsites(this.websites.required);
            this.websites.included = getValidWebsites(this.websites.included);
            this.websites.excluded = getValidWebsites(this.websites.excluded);
            close(_.extend(removeEmptyFilters(this.websites), {type: "websites", operand: this.websites.operand}));
        };

        $scope.onRequiredTagsChange = (tags) => {
            onTagsChange('required', tags);
        };
        $scope.onIncludedTagsChange = (tags) => {
            onTagsChange('included', tags);
        };
        $scope.onExcludedTagsChange = (tags) => {
            onTagsChange('excluded', tags);
        };

        $scope.validateDomain = (newTagsText) => $scope.termsMold.validateDomain(newTagsText);
    }
}]);
