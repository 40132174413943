/* eslint-disable camelcase */
import _ from 'lodash';
import config from '../../infra/config';
const HOST = config.ACTIVATION_API;

export default {
  createAmplifiedAudience: (
    segmentId,
    segmentName,
    channel,
    market,
    advertiser,
    amplifyThreshold,
    target,
    ssoAccessToken,
    userListQuery,
    geo,
    isFirstParty,
    tremorSyndicateAmplified,
    isCustomPriceAmplified,
    optimizationType
  ) => ({
    url: `${HOST}/dsp/${channel}/amplified_segment`,
    payload: {
      market,
      advertiser,
      target,
      segment_id: segmentId,
      segment_name: segmentName,
      amplify_threshold: amplifyThreshold,
      user_list_query: userListQuery,
      geo,
      is_first_party: isFirstParty,
      tremor_syndicate_amplified: _.map(tremorSyndicateAmplified, (item) => item.value),
      is_custom_price: isCustomPriceAmplified,
      optimization_type: optimizationType,
    },
    headers: { 'SSO-Access-Token': ssoAccessToken },
  }),

  getAmplificationMarketContext: (ssoToken) => ({
    url: `${HOST}/dsp/amplification-markets/user`,
    headers: { 'SSO-Access-Token': ssoToken },
  }),

  getAmplificationAdvertiserContext: (marketId, ssoToken) => ({
    url: `${HOST}/dsp/amplification-advertisers/user?market_id=${marketId}`,
    headers: { 'SSO-Access-Token': ssoToken },
  }),

  createDeterministicAudience: (
    segmentId,
    segmentName,
    channel,
    market,
    advertiser,
    ssoAccessToken,
    userListQuery,
    tremorSyndicateDeterministic,
    isCustomPriceDeterministic,
    expirationDate
  ) => ({
    url: `${HOST}/dsp/${channel}/deterministic_segment`,
    payload: {
      market,
      advertiser,
      segment_id: segmentId,
      segment_name: segmentName,
      user_list_query: userListQuery,
      tremor_syndicate_deterministic: _.map(tremorSyndicateDeterministic, (item) => item.value),
      is_custom_price: isCustomPriceDeterministic,
      ...(expirationDate !== null && { expiration_date: expirationDate }),
    },
    headers: { 'SSO-Access-Token': ssoAccessToken },
  }),

  createUserListForDeterministicAudience: (
    channel,
    segmentId,
    market,
    advertiser,
    categoryId,
    dataContractId,
    dataProviderId,
    dspSegmentId,
    userListQuery,
    isDynamic,
    ssoAccessToken,
    audienceName,
    tremorSyndicateDeterministic,
    isCustomPriceDeterministic
  ) => ({
    url: `${HOST}/dsp/${channel}/user_list`,
    payload: {
      market,
      advertiser,
      segment_id: segmentId,
      category_id: categoryId,
      data_contract_id: dataContractId,
      data_provider_id: dataProviderId,
      dsp_segment_id: dspSegmentId,
      user_list_query: userListQuery,
      is_dynamic: isDynamic,
      audience_name: audienceName,
      tremor_syndicate_deterministic: _.map(tremorSyndicateDeterministic, (item) => item.value),
      is_custom_price: isCustomPriceDeterministic,
    },
    headers: { 'SSO-Access-Token': ssoAccessToken },
  }),

  getAudienceByDspSegmentId: (dsp_segment_id, headers) => ({
    url: `${HOST}/audience_segment_dsp_id/${dsp_segment_id}`,
    headers,
  }),

  createAlwaysOnAudience: (
    segmentId,
    segmentName,
    dataContractId,
    dataContractText,
    categoryId,
    amplifyThreshold,
    target,
    ssoAccessToken,
    userListQuery,
    geo
  ) => ({
    url: `${HOST}/dsp/always_on`,
    payload: {
      target,
      segment_id: segmentId,
      segment_name: segmentName,
      data_contract_id: dataContractId,
      data_contract_text: dataContractText,
      category_id: categoryId,
      amplify_threshold: amplifyThreshold,
      user_list_query: userListQuery,
      geo,
    },
    headers: { 'SSO-Access-Token': ssoAccessToken },
  }),

  getDataContract: (data_contract_id, headers) => ({ url: `${HOST}/dsp/data_contract/${data_contract_id}`, headers }),

  getTaxonomyCategory: (data_contract_id, category_id, headers) => ({
    url: `${HOST}/dsp/category/${data_contract_id}/${category_id}`,
    headers,
  }),

  getUserAllActivatedSegments: (userId, headers) => ({
    url: `${HOST}/user_active_audience_segments/${userId}`,
    headers,
  }),

  getMarketDetails: (market_id, headers) => ({
    url: `${HOST}/dsp/market/${market_id}`,
    headers,
  }),
};
