import React from 'react';
import PropTypes from 'prop-types';
import ClickableLabel from '../../../../common/ClickableLabel/ClickableLabel';
import AppSecondaryBar from '../../../../common/AppNavigation/AppSecondaryBar/AppSecondaryBar';
import Separator from '../../../../common/Separator/Separator';

const AudienceSecondaryBar = ({
  hasNoSegments,
  disableLoadAudience,
  onNewAudienceClick,
  onLoadAudienceClick,
  onSaveAudienceClick,
  isDropdownVisible,
  audienceChannels,
  selectedChannel,
  onChannelChange,
  isAudienceActivated,
  createClonedAudience,
  isCloneAudienceVisible,
}) => (
  <div className="audience-secondary-bar-component">
    <AppSecondaryBar
      isDropdownVisible={isDropdownVisible && audienceChannels && audienceChannels.length > 0}
      dropdownValues={audienceChannels}
      selectedDropdownValue={selectedChannel}
      onDropdownChange={onChannelChange}
    >
      <div className="clickables-container" style={{ display: 'flex' }}>
        <ClickableLabel
          label="Save Audience"
          leftIconId="download"
          onClick={onSaveAudienceClick}
          isDisabled={hasNoSegments || isAudienceActivated}
        ></ClickableLabel>
        <Separator />
        <ClickableLabel
          label="Load Audience"
          leftIconId="upload"
          onClick={onLoadAudienceClick}
          isDisabled={disableLoadAudience}
          onDisabledTooltip="There are no saved audiences in this program"
        ></ClickableLabel>
        <Separator />
        <ClickableLabel
          label="New Audience"
          leftIconId="plus-in-a-box"
          onClick={onNewAudienceClick}
          isDisabled={hasNoSegments}
          leftIconClassName="icon-new-segment"
        ></ClickableLabel>
        {isCloneAudienceVisible && <Separator />}
        {isCloneAudienceVisible && (
          <ClickableLabel
            label="Clone Audience"
            leftIconId="copy"
            onClick={createClonedAudience}
            isDisabled={!isAudienceActivated}
          ></ClickableLabel>
        )}
      </div>
    </AppSecondaryBar>
  </div>
);

AudienceSecondaryBar.propTypes = {
  hasNoSegments: PropTypes.bool.isRequired,
  disableLoadAudience: PropTypes.bool,
  onNewAudienceClick: PropTypes.func.isRequired,
  onLoadAudienceClick: PropTypes.func.isRequired,
  onSaveAudienceClick: PropTypes.func.isRequired,
  isDropdownVisible: PropTypes.bool.isRequired,
  audienceChannels: PropTypes.array,
  selectedChannel: PropTypes.object,
  onChannelChange: PropTypes.func,
  isAudienceActivated: PropTypes.bool.isRequired,
  createClonedAudience: PropTypes.func.isRequired,
  isCloneAudienceVisible: PropTypes.bool.isRequired,
};

export default AudienceSecondaryBar;
