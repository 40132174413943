/**
 * Created by pery on 15/11/2015.
 */
/**
 * am-table
 */
module.exports = angular.module(__filename, [
    require('./selected-column.js').name,
    require('./removed-column.js').name
]).directive('amTable', function () {
    return {
        require: 'amTable',
        priority: 1002,
        compile: function (tElement, tAttrs) {
            var columns = [],
                dataRow,
                rows = [];

            angular.forEach(tElement.find('tr'), function (tr) {
                rows.push(angular.element(tr))
            });
            dataRow = rows.filter(function (tr) {
                return !tr.hasClass('ng-table-group');
            })[0];

            if (!dataRow) {
                return;
            }
            angular.forEach(dataRow.find('td'), function (item) {
                var el = angular.element(item);
                if ('true' === el.attr('ignore-cell')) return;
                if (el.attr('selected-column')) return;
                if (el.attr('removed-column')) return;

                columns.push({
                    title: el.attr('column-title'),
                    description: el.attr('description'),
                    dataKey: el.attr('sort-key'),
                    hiddenble: el.attr('hiddenble'), //scope
                    class: el.attr('class'),
                    dataType: el.attr('data-type'),
                    bind: el.attr('ng-bind') || el.find('[ng-bind]').attr('ng-bind')
                });
            });

            return {
                pre: function (scope, element, attrs, tableCtrl) {
                    tableCtrl.columns = columns;
                },
                post: function (scope, element, attrs, amTableCtrl) {
                    columns.forEach(function (col) {
                        col.hiddenble = scope.$parent.$eval(col.hiddenble);
                    });
                    amTableCtrl.noHiddebleColumn = _.some(columns, function (col) {
                        return col.hiddenble;
                    });
                }
            }
        }
    }
});
