"use strict";

module.exports = angular.module(__filename, [
    require('data/filters-partition.srv').name,
    require('./filter-mold-service').name
]).factory("InsightsChannelsMold", ['filtersPartition', 'filterMoldService', function (filtersPartition, filterMoldService) {
    return function InsightsChannelsMold() {
        this._value = [{label: "All", value: "articles"}];
        this.default = {label: "All", value: "articles"};
        filterMoldService.getDefaultFilterMoldFunction.call(this, filtersPartition.insightsChannels);
    }
}]);
