var tmpl = require("./brand-index-widget.html"),
    BaseWidget = require("../base_widget"),
    TimelineChart = require("common/charts/timeline-chart"),
    consumptionTrendService = require("data/insights/consumption-trend-service"),
    drilldown = require("../drilldown-component/drilldown"),
    programSettingsController = require("../../pages/programs/program_settings/program-settings"),
    programService = require('data/settings/program-service'),
    ModalService = require("angular-modal-service");

BrandIndexWidgetCtrl.$inject = ["$element", "consumptionTrendService", "ModalService", "programService", "notificator", "util", "$timeout"];

function BrandIndexWidgetCtrl($element, consumptionTrendService, ModalService, programService, notificator, util, $timeout) {
    this.consumptionIndexService = consumptionTrendService;
    this.util = util;
    this.$timeout = $timeout;
    this.chart = new TimelineChart($element.find("brand-index-graph")[0], {});
    this.$scope.editProgram = function (program) {
        programService.program(program.id).then(function (program) {
            ModalService.showModal({
                template: require('../../pages/programs/program_settings/program-settings.html'),
                inputs: {program: program, initialView: 'dashboard interests'},
                controller: 'programSettingsController'
            });
        }, function () {
            console.log("failed Edit");
            notificator.error({body: "Edit canceled! Could not load program, please check internet connection"});
        });
    };
}

BrandIndexWidgetCtrl.prototype._doUpdate = function (newVals, oldVals) {
    var program_terms = this.$scope.program.competitors.map(function (c) {
        return c.name.toLowerCase()
    }).concat([this.$scope.brandName.toLowerCase()]).sort();

    var dashboard_terms = this.$scope.terms.map(function (t) {
        return t.text.toLowerCase()
    }).sort();

    if (!_.isEqual(program_terms, dashboard_terms)) return;

    var brand_id = 0;
    var competitors_ids = [];

    for (var i in this.$scope.terms) {
        if (this.$scope.terms[i].text.toLowerCase() === this.$scope.brandName.toLowerCase()) {
            brand_id = this.$scope.terms[i].id;
            this.$scope.brandName = this.$scope.terms[i].text;
        } else {
            competitors_ids.push(this.$scope.terms[i].id);
        }
    }

    var classes = {};
    this.$scope.terms.forEach(function (term) {
        if (term.class == 'term-A') {
            classes[term.text] = 'term-brand';
        } else {
            classes[term.text] = term.class;
        }
    });

    var self = this;
    this.$scope.sentiment = null;
    this.$scope.consumptionChangeText = null;
    var kwd_ids = this.util.getPhrasesToIdMap(this.$scope.terms);

    var consumptionParams = {
        timeframe: 30,
        terms: this.$scope.terms,
        topics: [],
        geo: this.$scope.geos,
        kwd_ids: kwd_ids,
        to_normalize: true,
        sentiment: true,
        brand: self.$scope.brandName
    };
    return this.consumptionIndexService.getBrandConsumption(consumptionParams).then(function (res) {
      self.$scope.consumption = res.brandIndex;
      if (self.$scope.consumption) {
          // Handle arrows up/down for consumption and relative_consumption
          // Arrows should only appear if "view value" is non zero (value >= 1 && value <= -1)
          self.$scope.consumption.change_math_floor = Math.floor(self.$scope.consumption.change.toFixed(0));
          self.$scope.consumption.relative_consumption_math_floor = Math.floor(self.$scope.consumption.relative_consumption.toFixed(0));
      }
      var result = res.graph.chart;

      var sorted_keys = Object.keys(result).sort(function (a, b) {
          return (result[a].term.text === self.$scope.brandName ? -1 : 1);
      });

      var series = {};
      for (var i = 0; i < sorted_keys.length; i++) {
          var key = result[sorted_keys[i]].term.text;
          var values = result[sorted_keys[i]].series;
          var series_map = {};
          for (var j = 0; j < values.length; j++) {
              series_map[values[j].date] = values[j].value
          }
          series[key] = series_map;
      }

      self.$scope.sentiment = {
          good: (result[sorted_keys[0]].avg_pos || 0).toFixed(0),
          bad: (result[sorted_keys[0]].avg_neg || 0).toFixed(0)
      };
      self.series = series;
      self.classes = classes;
      self.$timeout(()=>{
            self.chart.resize();
            self.chart.draw(self.series, self.classes);
        })
    });
};

BrandIndexWidgetCtrl.prototype.onResize = function () {
    if (this.series) {
        this.chart.resize();
        this.chart.draw(this.series, this.classes);
        if (this.news) {
            this.chart.drawPoints(this.news);
        }
    }
};

module.exports = angular.module(__filename, [
    drilldown.name
]).directive("brandIndexWidget", [function () {
    return BaseWidget({
        restrict: "E",
        template: tmpl,
        scope: {
            terms: "=",
            program: "=",
            brandName: "=",
            geos: "="
        },
        controller: BrandIndexWidgetCtrl
    });
}]).filter("change", [function () {
    return function (value) {
        if (value) {
            return Math.abs(value.toFixed(0)) + "%";
        } else {
            return "0%";
        }
    }
}]);
