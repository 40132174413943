"use strict";
var c = require("infra/utils/common");

module.exports = angular.module(__filename, [
    require('data/filters-partition.srv').name,
    require('./filter-mold-service').name
 ]).factory("LanguageMold", ['filtersPartition', 'filterMoldService', 'abiPermissions' , '$timeout', '$rootScope', 'notificator', 'APP_NAME', 'TABS',
    function (filtersPartition, filterMoldService , abiPermissions , $timeout , $rootScope, notificator, APP_NAME, TABS) {
    return function LanguageMold() {
        this._value = [];
        
        this.default = filtersPartition.language[0];
        this.prevValue = null;
        this.prevPage = null;
        this._selectRefreshed = false;
        this._selectRefreshedOnce = false;
        this.pagesToResetArr = [APP_NAME.INTERESTS, APP_NAME.INSIGHTS, APP_NAME.DASHBOARD];

        this.getLanguage = function($state, context){
            /*
            on each tab/channel, we check if selected language is supported.
            if not, we clear seeds

            to use
            ======
            each tab needs context.current._language_mold.getLanguage($state, context);

            apology
            =======
            clearning seeds inside a "get" function
            is NOT great design patterns but 
            other (numerous) solutions failed because:

            1. many components like input bar dont have ordered set+get 
            2. many events are triggered on every tab/channel change
            3. there were too many edge states to handle
            */

            if((!context.current.language) || (!this.shouldCheckLanguageSupport($state))){
                return this.default;
            }
          

            this.prevValue = this.prevValue || $.extend(true, {}, this._value);
            let newValue = this.isSupportLanguage($state, context) ? this._value: this.default;
            this.prevPage = $state.current.name;

            if (!this._value || this.prevValue.value != newValue.value) {
                if (!this._value) {
                    newValue = this.default;
                    this._value = this.default;
                }

                this.prevValue = $.extend(true, {}, newValue);
                $rootScope.$broadcast('switch-language');

                if (context.current.terms.length) {
                    notificator.notify({body: "Seeds reset because language setup has changed"});
                }
            }
       
            return newValue;
        };

        this.getLanguages = (langArr, languages) => languages.filter((lan) => $.inArray(lan.value, langArr) !== -1);

        this.getUserLanguages = () => $rootScope.user.languages ? Array.from($rootScope.user.languages) : [];

        this.resetRefreshSelect = function () {
            this._selectRefreshedOnce = true;
            this._selectRefreshed = false;
        };

        this.refreshSelect = function () {
            this._selectRefreshed = true;
        };

        this.languageSelectRefreshHandler = function ($state, context) {
            //for some reson the language select does not mark the language due to timing isues + context incorrect value.
            //this function will enforce that the context will be correct when called (currently when unified is selected) (SEE: INTERESTS.JS languageSelectRefreshHandler FUNCTION)
           if (!this._selectRefreshed) {
               this.setContextLanguage($state, context);
               this.refreshSelect();
           }
        };

        this.supportedLanguages = function ($state, context) {
            const page = $state.current.name,
                  channels = c.getChannels($state, context);
            let languages = this.getUserLanguages();

            if (page.includes(APP_NAME.INTERESTS)) {
                if (page.includes("board")) {
                    const allStreamsChannels = _.map($rootScope.streamsChannelsFilter, 'value');
                    const hasOnlyWebChannel = _.isEqual(allStreamsChannels, ["articles"]);
                    if (!hasOnlyWebChannel && c.isAllChannelsSelected($state, context, $rootScope)) {
                        //unified --> none --> means default english !
                        languages = [];
                    } else if (!hasOnlyWebChannel && !channels.includes("articles")) {
                        languages = [];
                    } else {
                        //web, no need to change, we support ALL languages.
                        //BUG FIX: for some reason the language select does not mark the language due to timing issues + context incorrect value.
                        if (!this._selectRefreshedOnce) {
                            this.resetRefreshSelect();
                        }
                    }
                } else if ((channels.includes("articles") || channels.length === 0) &&
                            abiPermissions.hasPermission("web language targeting")) { //grid
                    //en/es
                    languages = this.getLanguages(['es', 'en'], languages);
                } else {
                    languages = [];
                }
            }
            return languages;
        };

        this.isSupportLanguage = function ($state, context) {
            if (this.getUserLanguages().length < 2) {
                return false;
            }
            const page = $state.current.name,
                  channels = c.getChannels($state, context);

            if (!this.prevPage) {
                this.prevPage = page;
            }

            if (page.includes(APP_NAME.INTERESTS)) {
                if (context.current.language) {
                    const supportLanguage = this.isInLanguageArr($state, context);
                    return supportLanguage !== -1 && (channels.length === 0 || channels.includes('articles'));
                } else {
                    //mainly after reset, the context is not set
                    this.setContextLanguage($state, context);
                    return channels.length === 0 ||
                           channels.includes('articles') && abiPermissions.hasPermission("web language targeting");
                }
            }
            return (page.includes(TABS.INSIGHTS_SEASONALITY) || page.includes(TABS.INSIGHTS_ASSOCIATION)) && channels.includes('articles');
        };

        this.setContextLanguage = function($state, context) {
            context.current.language = this.getLanguage($state, context);
        };

        this.isCompleteKeywords = ($state, context) => this.getLanguage($state, context).value ==='en';

        this.isResolveKeywords = function($state, context){
            const lan = this.getLanguage($state, context).value,
                  page = $state.current.name;

            return lan ==='en' || (lan ==='es' && (page.includes(APP_NAME.INTERESTS) || (page.includes(APP_NAME.INSIGHTS))));
        };

        this.shouldShowTopics = function($state, context) {
            const page = $state.current.name;

            if (page.includes(APP_NAME.INSIGHTS)) {
                return this.isResolveKeywords($state, context);
            }
            return this.getLanguage($state, context).value === 'en' ||
                   this.isSpanishWeb($state, context);
        };

        this.isShowAudience = ($state, context) => this.getLanguage($state, context).value === 'en' ||
                                                   this.isSpanishWeb($state, context);

        //only these pages has some language support, so when leaving them to other pages, DONT reset
        this.shouldCheckLanguageSupport = ($state) => $.inArray($state.current.name.split(".")[0], this.pagesToResetArr) !== -1;

        this.getDefaultLanguage = () => this.default;

        this.isInLanguageArr = function ($state, context) {
            const supportedLanguagesArr = this.supportedLanguages($state, context).map((obj) => obj.value);
            return $.inArray([context.current.language][0].value, supportedLanguagesArr);
        };

        this.isSpanishWeb = function($state, context) {
          const page = $state.current.name,
                channels = c.getChannels($state, context),
                language = this.getLanguage($state, context).value;

          return language == 'es' &&
                 channels.includes('articles') &&
                 page.includes(APP_NAME.INTERESTS) &&
                 abiPermissions.hasPermission("web language targeting");
        };

        //for non EN languages, each $scope.userTerms id is NOT a number, but "text_id" EX: "Trump_id" 
        //gets an array of scope.userTerms OR one [association] to delete
        this.formatUserTerms = function($state, context, userTerms) {
            let formatedUserTerms = [];
            const idStr = this.booleanLogicFormat($state, context);

            if (!this.shouldNotFormatData($state, context)) {
                _.each(userTerms, function (entry) {
                        entry.id = entry.id.includes(idStr)? entry.id : entry.id + idStr;
                        formatedUserTerms.push(entry);
                });
                userTerms = formatedUserTerms;
            }
            return userTerms;
        };
       

        //for non EN languages, each $scope.userTerms id is NOT a number, but "text_id" EX: "Trump_id" 
        //just concat the returnd value to any BLobj proccessed. 
        //EX:  let idStr = $scope.context.current._language_mold.booleanLogicFormat($state, $scope.context);
        //id: selectedSeed.id + idStr
        this.booleanLogicFormat = ($state, context) => this.shouldNotFormatData($state, context) ? '' : '_id';

        this.shouldNotFormatData = function($state, context) {
            const language = this.getLanguage($state, context).value;
            return language === 'en' || language === 'es';
        };

        filterMoldService.getDefaultFilterMoldFunction.call(this, filtersPartition.language);
     }
 }]);
