import { difference, map } from 'lodash';
import moment from 'moment';
import { getStateCurrent, getDspService } from '../../middleware/AngularExportedMiddleware';
import * as MixpanelCommon from './MixpanelCommon';

const generatedDate = moment().format('YYYY-MM-DD HH:mm');

let limitTopics = [];
let manualPhrases = [];
let limitTopicsFlag;
let dynamicTrendsFlag;
let ActivatedToFlag;
let selectAllPhrasesFlag;
let excludeFlag = false;
let viewTarget = false;

// tracking functions
export const trackNewTarget = (params) => MixpanelCommon.sendToMixpanel('Target - New Target', targetsProps(params));

export const trackDeleteTarget = (deleteTarget) => {
  const props = targetsProps(deleteTarget);
  /* eslint-disable camelcase*/
  const deleteProps = {
    activated_to: deleteTarget.activated_to ? deleteTarget.activated_to[0] : null,
    deleteDate: generatedDate,
  };
  MixpanelCommon.sendToMixpanel('Target - Delete Target', { ...props, ...deleteProps });
};

export const trackSelectAll = (currentTarget) => {
  if (selectAllPhrasesFlag && currentTarget && currentTarget.name) {
    MixpanelCommon.sendToMixpanel('Audience-Explorer - Interests - Phrases - Select all', targetsProps(currentTarget));
    selectAllPhrasesFlag = false;
  }
};

export const trackContentDriversPaneOpen = () => MixpanelCommon.trackContentDrive();

// utils functions
export const targetsProps = (target) => {
  const widgetName = MixpanelCommon.extractWidget(getStateCurrent());
  const props = {
    appName: 'Audience',
    widget: widgetName || 'audience',
    'target name': target.name,
    'target event': !!target.name,
  };

  if (target.id) {
    return { ...props, 'target id': target.id };
  }
  return props;
};

// for each toggle button, this will save the state
// to be checked when the done button is clicked (see next function: trackUpdatedData)
export const collectUpdatedTargetData = (action, data) => {
  switch (action) {
    case 'Add Phrase': {
      manualPhrases = manualPhrases.concat(data);
      break;
    }
    case 'Limit to categories': {
      limitTopicsFlag = data;
      break;
    }
    case 'Dynamic Trends': {
      dynamicTrendsFlag = data;
      break;
    }
    case 'Activated To': {
      ActivatedToFlag = data;
      break;
    }
    case 'Exclude Btn': {
      excludeFlag = data;
      break;
    }
    case 'View Target Btn': {
      viewTarget = Boolean(data);
      break;
    }
    default:
      break;
  }
};

export const trackUpdatedData = (scope) => {
  const checkedArray =
    scope.dataTrees && scope.dataTrees.manualTopicsTree ? scope.dataTrees.manualTopicsTree.checkedArray : [];
  const updatedTarget = scope.currentTarget;
  const props = targetsProps(updatedTarget);

  if (manualPhrases.length > 0) {
    MixpanelCommon.sendToMixpanel('Target - Manually add phrase', { ...props, 'added phrases': manualPhrases.length });
  }

  if (limitTopicsFlag || !compareArrays(limitTopics, updatedTarget.topics)) {
    const limitTopicsArr = map(checkedArray, (item) => `${item.name}`);
    MixpanelCommon.sendToMixpanel('Target - Limit to categories', { ...props, topics: limitTopicsArr });
  }

  if (dynamicTrendsFlag) {
    MixpanelCommon.sendToMixpanel('Target - Dynamically add Trends', props);
  }

  if (excludeFlag) {
    MixpanelCommon.sendToMixpanel('Target - Exclude btn', { ...props, Exclude: excludeFlag });
  }

  if (viewTarget) {
    MixpanelCommon.sendToMixpanel('Target - View Target btn', { ...props, ViewTarget: viewTarget });
  }

  if (ActivatedToFlag) {
    let activatedTo = updatedTarget.activated_to[0];

    if (updatedTarget.activated_to[0] === 'turn') {
      const dspService = getDspService();
      dspService.getMarketFromId(updatedTarget.market_id, { isAllMarketsSupported: true }).then((market) => {
        const marketName = market.name;

        activatedTo = 'DSP';
        MixpanelCommon.sendToMixpanel('Target - Market', {
          ...props,
          'Activate to': activatedTo,
          'market ID': updatedTarget.market_id,
          'market name': marketName,
        });
        MixpanelCommon.sendToMixpanel('Target - Activate to', {
          ...props,
          'Activate to': activatedTo,
          'market ID': updatedTarget.market_id,
          'market name': marketName,
        });
      });
    } else {
      const activatedToObj = activatedTo !== undefined ? { 'Activate to': activatedTo } : { 'Not Activated': 'true' };
      MixpanelCommon.sendToMixpanel('Target - Activate to', { ...props, ...activatedToObj });
    }
  }
};

export const trackKeyTrends = (updatedTarget) => {
  const props = targetsProps(updatedTarget);
  MixpanelCommon.sendToMixpanel('Target - Dynamically add Trends, Key trends changed', props);
};

export const initLimitTopics = (target) => (limitTopics = target.topics);

export const resetUpdatedData = () => {
  limitTopics = manualPhrases = [];
  limitTopicsFlag = dynamicTrendsFlag = ActivatedToFlag = excludeFlag = viewTarget = false;
};

export const compareArrays = (arr1, arr2) => difference(arr1, arr2).length === 0 && difference(arr2, arr1).length === 0;

export const toggleSelectAll = (isSelected) => (selectAllPhrasesFlag = isSelected);
