import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { cloneDeep, compact, every, find, includes, isEmpty, isEqual, last, pick, range, sortBy } from 'lodash';
import { Slider } from '@amobee/component-library';
import SelectToggle from '../../../../../common/SelectToggle/SelectToggle';
import {
  GENDER_VALUES,
  AUDIENCE_AGE_VALUES,
  DISPLAY_INCOME_VALUES,
  ETHNICITY_VALUES,
  SG_ETHNICITY_VALUES,
  INCOME_VALUES,
} from '../../../../../../../data/audience-segment-builder-helper';
import { US_STATES } from '../../../../../../services/GeoService';
import ModalWithConfirmationButtons from '../../../../../common/Modal/ModalWithConfirmationButtons/ModalWithConfirmationButtons';
import Dropdown from '../../../../../common/Dropdown/Dropdown/Dropdown';
import SingleSelectToggle from '../../../../../common/SingleSelectToggle/SingleSelectToggle';
import './DemographicsModal.scss';

const DemographicsModal = ({
  isOpen,
  modalTitle,
  onSubmit,
  onCancel,
  demographicsInput,
  allGeos,
  disableGeos,
  defaultGeo,
  isGeoOnly,
  channel,
  hasUsGeoPermission,
  hasSgTelcoEthnicityPermission,
  type,
}) => {
  const [demographicsData, setDemographicsData] = useState(demographicsInput);
  const US = find(allGeos, { id: '840' });
  const UK = find(allGeos, { id: '826' });
  const SG = find(allGeos, { id: '702' });
  const AU = find(allGeos, { id: '036' });
  const geosSelectedAll = allGeos.find((geo) => geo.value === 'all');
  const SG_CHANNELS = ['snbb', 'data_spark', 'sg_bidstream'];
  const isSgChannel = SG_CHANNELS.includes(channel);
  const US_ONLY_CHANNELS = ['smart_tv_inscape', 'tivo', 'peerlogix'];
  const HAVE_US_GEO_CHANNELS = US_ONLY_CHANNELS + ['hisense', 'articles'];
  const isUsOnly = US_ONLY_CHANNELS.includes(channel);
  const isUsDefaultSelected = isUsOnly || defaultGeo?.id === US?.id;
  const isRelevantForUs = (US && demographicsData.geo?.[0]?.id === US.id) || !demographicsData.geo;
  const isGeoDisabled = isSgChannel || channel === 'au_telco' || disableGeos || isUsOnly;
  const showEthnicity =
    (isSgChannel && hasSgTelcoEthnicityPermission) ||
    (hasUsGeoPermission && !includes(['au_telco', 'data_spark'], channel)) ||
    isUsDefaultSelected;
  const showIncome = (!isSgChannel && channel !== 'au_telco' && hasUsGeoPermission) || isUsDefaultSelected;
  const showStateFilter =
    !isGeoOnly && HAVE_US_GEO_CHANNELS.includes(channel) && (isUsDefaultSelected || hasUsGeoPermission);
  const [selectedGenderValues, setSelectedGenderValue] = useState(demographicsData.gender?.[0].value || ['all']);
  const [selectedGeoValue, setSelectedGeoValue] = useState(
    demographicsData.geo?.[0] || (disableGeos && defaultGeo ? defaultGeo : geosSelectedAll)
  );
  const [selectedStates, setSelectedStates] = useState(demographicsData.states || []);
  const [isStateDisabled, setIsStateDisabled] = useState(
    !isUsOnly && ![demographicsData.geo?.[0].id, defaultGeo?.id].includes(US?.id)
  );
  const [selectedEthnicityValues, setSelectedEthnicityValue] = useState(demographicsData.ethnicity || []);
  const [fromIncomeIndex, setFromIncomeIndex] = useState(
    isEmpty(demographicsData.income)
      ? 0
      : INCOME_VALUES.findIndex((value) => isEqual(value, demographicsData.income[0]))
  );
  const [toIncomeIndex, setToIncomeIndex] = useState(
    isEmpty(demographicsData.income)
      ? DISPLAY_INCOME_VALUES.length - 1
      : INCOME_VALUES.findIndex((value) => isEqual(value, last(demographicsData.income))) + 1
  );

  const onChangeAge = useCallback(
    (selected) => {
      const criteriaData = cloneDeep(demographicsData);
      if (isEmpty(selected)) {
        delete criteriaData.age;
      } else {
        criteriaData.age = sortBy(selected, 'value');
      }
      setDemographicsData(criteriaData);
    },
    [demographicsData, setDemographicsData]
  );

  const onChangeGender = useCallback(
    (gender) => {
      const criteriaData = cloneDeep(demographicsData);
      if (gender === 'all') {
        delete criteriaData.gender;
      } else {
        const { summary, label, value } = GENDER_VALUES.find((el) => el.value === gender);
        criteriaData.gender = [
          {
            label: summary,
            summary,
            display: label,
            value,
          },
        ];
      }
      setDemographicsData(criteriaData);
      setSelectedGenderValue(gender);
    },
    [demographicsData, setDemographicsData]
  );

  useEffect(() => {
    if (isSgChannel || channel === 'au_telco' || isUsOnly) {
      const criteriaData = cloneDeep(demographicsData);
      const geo = isSgChannel ? SG : channel === 'au_telco' ? AU : US;
      criteriaData.geo = [geo];
      setDemographicsData(criteriaData);
      setSelectedGeoValue(geo);
    }
  }, []);

  const onGeosSelect = useCallback(
    (geo) => {
      const criteriaData = cloneDeep(demographicsData);
      if (geo.value === 'all') {
        delete criteriaData.geo;
        delete criteriaData.states;
        setSelectedStates([]);
      } else {
        criteriaData.geo = [geo];
      }
      if (criteriaData.geo && US && geo.id !== US.id) {
        delete criteriaData.ethnicity;
        delete criteriaData.states;
        setSelectedEthnicityValue([]);
        setSelectedStates([]);
      }
      setIsStateDisabled(US && geo.id !== US.id);
      setDemographicsData(criteriaData);
      setSelectedGeoValue(geo);
    },
    [demographicsData, setDemographicsData]
  );

  const onStateSelect = useCallback(
    (states) => {
      const criteriaData = cloneDeep(demographicsData);
      if (!states.length || states.length === US_STATES.length) {
        delete criteriaData.states;
      } else {
        criteriaData.states = states;
      }

      setDemographicsData(criteriaData);
      setSelectedStates(states);
    },
    [demographicsData, setDemographicsData]
  );

  const isEthnicityDisabled = useMemo(() => {
    const isInputGeoWithoutEthnicity =
      !isUsDefaultSelected && ![US?.id, UK?.id].includes(demographicsInput.geo?.[0]?.id);
    const isGeoPredefinedWithoutEthnicity =
      isGeoDisabled && (isInputGeoWithoutEthnicity || demographicsInput.ethnicity);
    const isSelectedGeoWithoutEthnicity = ![US?.id, UK?.id, geosSelectedAll?.id].includes(selectedGeoValue?.id);
    return !isSgChannel && (isGeoPredefinedWithoutEthnicity || isSelectedGeoWithoutEthnicity);
  }, [isGeoDisabled, isUsDefaultSelected, demographicsInput, selectedGeoValue]);

  const onEthnicitySelect = useCallback(
    (ethnicity) => {
      const criteriaData = cloneDeep(demographicsData);
      if (!ethnicity.length || ethnicity.length === SG_ETHNICITY_VALUES.length) {
        delete criteriaData.ethnicity;
      } else {
        criteriaData.ethnicity = ethnicity;
        if (!isSgChannel) {
          criteriaData.geo = [US];
          setSelectedGeoValue(US);
          setIsStateDisabled(true);
        }
      }

      setDemographicsData(criteriaData);
      setSelectedEthnicityValue(ethnicity);
    },
    [demographicsData, setDemographicsData]
  );

  const onChangeIncome = useCallback(
    ([from, to]) => {
      if (from >= to || (from === fromIncomeIndex && to === toIncomeIndex)) {
        return;
      }
      const criteriaData = cloneDeep(demographicsData);
      if (from === 0 && to === DISPLAY_INCOME_VALUES.length - 1) {
        delete criteriaData.income;
      } else {
        criteriaData.income = INCOME_VALUES.slice(from, to);
      }

      if (
        !demographicsData.geo &&
        (from !== 0 || to !== DISPLAY_INCOME_VALUES.length - 1) &&
        !isGeoDisabled &&
        !isSgChannel
      ) {
        criteriaData.geo = [US];
        setSelectedGeoValue(US);
      }

      setFromIncomeIndex(from);
      setToIncomeIndex(to);
      setDemographicsData(criteriaData);
    },
    [demographicsData, setDemographicsData, setFromIncomeIndex, setToIncomeIndex]
  );

  function removeEmptyFilters(demographics) {
    const outputDemographics = cloneDeep(demographics);
    if (outputDemographics.age && outputDemographics.age.length >= AUDIENCE_AGE_VALUES.length) {
      delete outputDemographics.age;
    }
    const ethnicityValues = !isSgChannel ? ETHNICITY_VALUES : SG_ETHNICITY_VALUES;
    if (outputDemographics.ethnicity && outputDemographics.ethnicity.length >= ethnicityValues.length) {
      delete outputDemographics.ethnicity;
    }
    if (outputDemographics.income && outputDemographics.income.length >= INCOME_VALUES.length) {
      delete outputDemographics.income;
    }
    return outputDemographics;
  }

  function isEmptyDemographics(demographics) {
    const filteredDemographics = pick(removeEmptyFilters(demographics), [
      'age',
      'ethnicity',
      'income',
      'gender',
      'geo',
      'states',
    ]);
    return isEmpty(filteredDemographics) || every(filteredDemographics, (val, key) => isEmpty(val));
  }

  const handleSubmit = useCallback(() => {
    const segment = {
      ...removeEmptyFilters(demographicsData),
      type,
    };
    onSubmit(segment);
  }, [demographicsData, setDemographicsData]);

  return (
    <div className="demographics-modal-component common">
      <ModalWithConfirmationButtons
        width="760px"
        modalTitle={modalTitle}
        isOpen={isOpen}
        isSubmitDisabled={isRelevantForUs && isEmptyDemographics(demographicsData)}
        isDisabledEnterKeyPress={true}
        onSubmit={handleSubmit}
        onCancel={onCancel}
        onXClick={onCancel}
        isLightTheme={true}
        hasScrollContainer={false}
        containerPadding="22px"
        titleMargin="0 0 22px"
      >
        <div className="demographics-modal-content">
          {!isGeoOnly && (
            <div className="row">
              <div className="row-label">Gender</div>
              <div className="demographics-modal-cell gender-select">
                <SingleSelectToggle
                  values={GENDER_VALUES}
                  selectedValue={selectedGenderValues}
                  onToggle={onChangeGender}
                  isModalTheme
                />
              </div>
            </div>
          )}
          {!isGeoOnly && (
            <div className="row">
              <div className="row-label">Age</div>
              <div className="demographics-modal-cell age-select">
                <SelectToggle
                  values={AUDIENCE_AGE_VALUES}
                  selectedValues={demographicsData.age || []}
                  onChange={onChangeAge}
                  className="font-regular"
                />
              </div>
            </div>
          )}
          {!(defaultGeo && defaultGeo.id !== US?.id) && (
            <div className={classNames('row', { 'extended-row': showStateFilter })}>
              <div className="row-label">Geo</div>
              <div className="demographics-modal-cell geo-select">
                <div className="geo-dropdown">
                  <Dropdown
                    isMulti={false}
                    isSearchable={false}
                    values={allGeos}
                    selectedValue={selectedGeoValue}
                    onSelect={onGeosSelect}
                    summaryTextAllSelected="All Geos"
                    shouldCloseOnSelect
                    isDisabled={isGeoDisabled}
                  />
                </div>
                {showStateFilter && (
                  <div className="state-dropdown">
                    <Dropdown
                      isMulti
                      isSearchable
                      values={US_STATES}
                      selectedValues={selectedStates}
                      onSelect={onStateSelect}
                      summaryTextAllSelected="All States"
                      isDisabled={isStateDisabled}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
          {!isGeoOnly && showEthnicity && (
            <div className="row">
              <div className="row-label">Ethnicity</div>
              <div className="demographics-modal-cell ethnicity-select">
                <Dropdown
                  isMulti
                  isSearchable={false}
                  values={isSgChannel ? SG_ETHNICITY_VALUES : ETHNICITY_VALUES}
                  selectedValues={selectedEthnicityValues}
                  onSelect={onEthnicitySelect}
                  summaryTextAllSelected="All Ethnicities"
                  showSelectAllOptions
                  isDisabled={isEthnicityDisabled}
                />
              </div>
            </div>
          )}
          {!isGeoOnly && showIncome && (
            <div className="row">
              <div className="row-label">Income</div>
              <div className="demographics-modal-cell income-select">
                <Slider
                  min={0}
                  max={DISPLAY_INCOME_VALUES.length - 1}
                  onChange={onChangeIncome}
                  ticks={range(0, DISPLAY_INCOME_VALUES.length)}
                  initialValue={[fromIncomeIndex, toIncomeIndex]}
                  tickFormatter={(i) => DISPLAY_INCOME_VALUES[i].label}
                  value={[fromIncomeIndex, toIncomeIndex]}
                />
              </div>
            </div>
          )}
        </div>
      </ModalWithConfirmationButtons>
    </div>
  );
};

DemographicsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  modalTitle: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  disableGeos: PropTypes.bool.isRequired,
  defaultGeo: PropTypes.object,
  isGeoOnly: PropTypes.bool,
  demographicsInput: PropTypes.object,
  allGeos: PropTypes.array.isRequired,
  channel: PropTypes.string.isRequired,
  hasUsGeoPermission: PropTypes.bool.isRequired,
  hasSgTelcoEthnicityPermission: PropTypes.bool.isRequired,
  type: PropTypes.string,
};

DemographicsModal.defaultProps = {
  demographicsInput: {},
  isGeoOnly: false,
};

export default DemographicsModal;
