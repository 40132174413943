import React, { useEffect, useState } from 'react';
import { string, object, array, number } from 'prop-types';
import Tooltip from '../Tooltip/Tooltip.js';
import './ResponsiveBarChart.scss';

const DEFAULT_OPTIONS = {
  xAxisColor: 'rgba(255, 255, 255, 0.3)',
  barWidth: 20,
  barColor: '#cacaca',
  labelColor: '#cacaca',
  labelFont: '14px',
  valueColor: '#cacaca',
  valueFont: '14px',
  highlightedBarColor: '#44b0c7',
  highlightedLabelColor: '#fff',
  highlightedLabelFont: '14px',
  highlightedValueColor: '#fff',
  highlightedValueFont: '22px bold',
};

const ResponsiveBarChart = ({ data, options, highlightedValue, indexLine }) => {
  const [barData, setBarData] = useState([]);
  const [barUnit, setBarUnit] = useState(0);
  const barOptions = Object.assign({}, DEFAULT_OPTIONS, options);

  useEffect(() => {
    if (data) {
      const values = data.map((el) => el.value);
      const barUnitVal = 100 / Math.max(...values);
      setBarUnit(barUnitVal);
      let newData = data.map((el) => ({
        ...el,
        barHeight: el.value * barUnitVal,
      }));

      if (highlightedValue) {
        const max = Math.max(...values);
        const min = Math.min(...values);
        const compareVal = { min, max }[highlightedValue];
        if (max !== min) {
          newData = newData.map((el) => ({
            ...el,
            highlight: compareVal === el.value,
          }));
        }
      }

      setBarData(newData);
    }
  }, [data]);

  barOptions.highlightedValuePosition = barOptions.highlightedValueFont.substr(
    0,
    barOptions.highlightedValueFont.indexOf(' ')
  );

  return (
    <div
      className="responsive-bar-chart"
      style={{
        '--x-axis-color': data?.length ? barOptions['xAxisColor'] : 'transparent',
      }}
    >
      {barData?.length && !!indexLine && (
        <div
          className="index-line"
          style={{
            ...(indexLine && { '--index-line-height': 'calc(' + +indexLine * barUnit + '%)' }),
          }}
        />
      )}
      {barData?.length && barData.map((el) => <Cell key={el.label} options={barOptions} data={el} />)}
    </div>
  );
};

ResponsiveBarChart.propTypes = {
  data: array,
  options: object,
  highlightedValue: string,
  indexLine: number,
};

const Cell = ({ options, data }) => {
  const { value, displayValue, label, barHeight, highlight, tooltip } = data;

  return (
    <div className="cell">
      <div className="bar-outer">
        <Tooltip content={!options['noTooltip'] && tooltip ? tooltip : null}>
          <div
            className="bar"
            data-value={displayValue || value}
            style={{
              height: `calc(${barHeight}%)`,
              width: options['barWidth'],
              fontSize: options['valueFont'],
              ...(highlight && { font: options['highlightedValueFont'] }),
              color: highlight ? options['highlightedValueColor'] : options['valueColor'],
              background: highlight ? options['highlightedBarColor'] : options['barColor'],
              '--position-offset': `calc(-7px - ${
                highlight ? options['highlightedValuePosition'] : options['valueFont']
              })`,
            }}
          />
        </Tooltip>
      </div>
      <div
        className="label"
        style={{
          font: highlight ? options['highlightedLabelFont'] : options['labelFont'],
          color: highlight ? options['highlightedLabelColor'] : options['labelColor'],
        }}
      >
        <span>{label}</span>
      </div>
    </div>
  );
};

Cell.propTypes = {
  data: object,
  options: object,
};

export default ResponsiveBarChart;
