import 'angular-modal-service';
import * as MixpanelSettings from '../../react/infra/mixpanel/MixpanelSettings';

angular.module(__filename, [
    require('data/settings/program-service').name,
    require('data/keyword_suggester.srv.js').name,
    require('common/modals/confirmation/confirm-action.modal.service.js').name,
    'angularModalService',
]);

PhrasesController.$inject = ['$scope', 'keywords'];
function PhrasesController($scope, keywords) {
    MixpanelSettings.trackPageView('phrases');
    $scope.keywordsSearch = _.debounce(search, 400, {trailing: true, leading: false});
    $scope.keywordsSave = save;

    $scope.submitText = function (phrase) {
        return phrase.id && phrase.action != 'Undeprecate' ? 'save' : 'add phrase';
    };

    function search(querySearch) {
        return keywords.search(querySearch.replace('’', '`')).then(function (pharses) {
            $scope.pharses = pharses;
        });
    }

    function save(phrase, phraseForm) {
        return keywords.save(phrase).then(function () {
            phraseForm.$setPristine();
        });
    }

    $scope.phrasesType = ['GENERAL', 'BRAND', 'EMOTION', 'PRODUCT', 'PERSON', 'ORGANIZATION', 'LOCATION'];
}

module.exports.stateConfig = {
    name: "phrases",
    url: "/phrases",
    template: require("./phrases.html"),
    display: "Settings",
    //abstract:true,
    context: {},
    children: [],
    controller: PhrasesController,
    data: {
      permissions: [],
      roles: ['super admin', 'support']
    }
};
