module.exports = angular.module(__filename, [])
    .service('alertsV2Service', ['mgmtServiceFactory', 'abiPermissions', 'context', function (mgmtServiceFactory, abiPermissions, context) {
      // CRUD endpoints
      const alertsService = mgmtServiceFactory.createService('alert_v2');
      const alertsNotificationSettingsService = mgmtServiceFactory.createService('alerts_notification_settings');
      
      // constants
      const ALERT_TYPES = [
            {image: "images/images/alerts/interest_consumption.jpg", value: 'consumption', label: 'Interests consumption'},
            {image: "images/images/alerts/audience.jpg", value: 'tba', label: 'Audience', disabled: true, tagLabel: "Coming soon"},
            {image: "images/images/alerts/connect_yours.jpg", value: 'tba2', label: 'Connect your data', disabled: true, tagLabel: "Coming soon"}
        ];

        const SENTIMENT_TYPES = [
            {label: 'Net Sentiment', id: "neutral"},
            {label: 'Positive Sentiment', id: "positive"},
            {label: 'Negative Sentiment', id: "negative"}
        ];

        const TREND_DIRECTIONS_BOOL = [
            {label: 'Increases', value: true},
            {label: 'Decreases', value: false}
        ];
        
        return {
            alerts: {
              ..._.pick(alertsService, ['list', 'create', 'update', 'delete'])
            },
            alertsNotificationSettings: {
              ..._.pick(alertsNotificationSettingsService, ['list', 'create', 'update'])
            },
            consts: {
              ALERT_TYPES,
              SENTIMENT_TYPES,
              TREND_DIRECTIONS_BOOL
            }
        };
    }]
);
