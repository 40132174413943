import React from 'react';
import PropTypes from 'prop-types';
import './BarWithValue.scss';

import classNames from 'classnames';
import Tooltip from '../Tooltip/Tooltip';

const BarWithValue = ({
  className,
  percentage,
  score,
  tooltip,
  isHoverEnabled,
  isBarBackgroundVisible,
  isSelected,
}) => (
  <div
    className={classNames('bar-with-value-component', className, {
      'hover-enabled': isHoverEnabled && !isSelected,
      selected: isSelected,
    })}
  >
    <Tooltip content={tooltip}>
      <div className="bar-wrapper" style={{ width: `${percentage}%` }}>
        <div className="bar"></div>
      </div>
    </Tooltip>
    {isBarBackgroundVisible && (
      <div className="bar-background-wrapper" style={{ width: `calc(100% - ${percentage}%)` }}>
        <div className="bar-background"></div>
      </div>
    )}
    <span className="values">
      {percentage}% | {score}
    </span>
  </div>
);

BarWithValue.propTypes = {
  className: PropTypes.string,
  percentage: PropTypes.number.isRequired,
  score: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  isHoverEnabled: PropTypes.bool,
  isBarBackgroundVisible: PropTypes.bool,
  isSelected: PropTypes.bool,
};

BarWithValue.defaultProps = {
  isHoverEnabled: false,
  isBarBackgroundVisible: false,
  isSelected: false,
};

export default BarWithValue;
