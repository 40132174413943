var d3 = require("d3"),
    c = require("infra/utils/common");

module.exports = require("angular").module(__filename, [])
  .directive("amDailyHeatmapChart", [function() {
    return {
      restrict: 'E',
      scope: {
        data: '<',
        chartHeight: '<',
        chartWidth: '<'
      },
      template: require('./daily-heatmap-chart.html'),
      link: function ($scope, $element, $attrs, $ctrl) {
          var MARGIN = { height: 65, width: 175 },
              GRID_SPACE = 1,
              AVG_SPACE = 12,
              PANEL_HEIGHT = 60,
              ADDED_MARGIN = 60,
              MIN_CARD_SIZE = 25,
              WEEK_CHART_SPACE = 15,
              MIN_WEEK_CARD_WIDTH = 100;

          var days = c.getWeekDays(),
              truncDays = c.getTruncatedWeekDays();

        $scope.$watch('[chartWidth,chartHeight]', function () {
          $scope.update();
        });

        $scope.$watchCollection(function(){
          if(!c.is($scope.data)) return false;
          return $scope.data.labels;
        }, function () {
          $scope.update();
        });

        $scope.$watchCollection(function(){
          if(!c.is($scope.data) || !c.isArray($scope.data.seeds)) return false;
          return _.flatten(_.map($scope.data.seeds, function(record){return _.map(_.sortBy(record.day_totals, _.first), _.last)}));
        }, function () {
          $scope.update();
        });

        $scope.update = function () {
          if ($scope.chartWidth === undefined || !c.is($scope.data) || !$scope.data.hasOwnProperty('seeds') || !c.isArray($scope.data.seeds)) {
            return;
          }
          $scope.heatmapData = [];
          $scope.heatmapWeekdayData = [];
          $scope.heatmapWeekendData = [];
          $scope.heatmapColors = [];
          $scope.heatmapWeekdayColors = [];
          $scope.heatmapWeekendColors = [];
          $scope.heatmapAverages = [];
          var num_seeds = $scope.data.seeds.length;
          _.each($scope.data.seeds,function(record){
            $scope.heatmapData = $scope.heatmapData.concat(_.map(_.sortBy(record.day_totals, _.first.vals), _.last.vals));
            $scope.heatmapWeekdayData.push(record.weekday_total);
            $scope.heatmapWeekendData.push(record.weekend_total);
            $scope.heatmapColors = $scope.heatmapColors.concat(_.map(_.sortBy(record.day_totals_colors, _.first), _.last));
            $scope.heatmapWeekdayColors.push(record.weekday_total_color);
            $scope.heatmapWeekendColors.push(record.weekend_total_color);
          });
          $scope.seedNames = _.map($scope.data.labels, function(label){
            var result = {label: c.cutText(label,13)};
            if(result.label != label){
              result.tooltip = label;
            }
            return result;
          });
          $scope.heatmapAverages = $scope.data.average_days;
          $scope.heatmapWeekdayAverage = $scope.data.average_weekday;
          $scope.heatmapWeekendAverage = $scope.data.average_weekend;
          $scope.heatmapAveragesColors = $scope.data.average_days_colors;
          $scope.heatmapWeekdayAverageColor = $scope.data.average_weekday_color;
          $scope.heatmapWeekendAverageColor = $scope.data.average_weekend_color;
          //Same calculation as hourly heatmap
          var cardSizeByWidth = Math.floor(($scope.chartWidth - MARGIN.width - (23 * GRID_SPACE)) / 24);
          var cardSizeByHeight = Math.floor(($scope.chartHeight - MARGIN.height - ((num_seeds - 1) * GRID_SPACE) - (2 * AVG_SPACE) - PANEL_HEIGHT - ADDED_MARGIN) / num_seeds);
          $scope.cardHeight = Math.max(MIN_CARD_SIZE, Math.min(cardSizeByWidth, cardSizeByHeight));
          $scope.posLeft = 145;
          if($scope.cardHeight < cardSizeByWidth){
            $scope.posLeft += 12 * (cardSizeByWidth - $scope.cardHeight - GRID_SPACE); //Bringing heatmap to center of widget
          }
          $scope.titleMargin = $scope.posLeft - 107;
          $scope.weekCardWidth = Math.max(MIN_WEEK_CARD_WIDTH, Math.round((24 * $scope.cardHeight - 7 * GRID_SPACE) / 9));
          $scope.dayCardWidth = Math.floor((24 * ($scope.cardHeight + GRID_SPACE) - WEEK_CHART_SPACE - (7 * GRID_SPACE) - 2 * $scope.weekCardWidth) / 7);
          $scope.dayLabels = $scope.dayCardWidth < 85 ? truncDays : days;
        };

        $scope.update();
      }
    }
  }]);
