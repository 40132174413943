import React from 'react';
import PropTypes from 'prop-types';
import './ImageToggleButton.scss';

import classNames from 'classnames';
import Icon from '../Icon/Icon';
import Tooltip from '../Tooltip/Tooltip';
import BarWithValue from '../BarWithValue/BarWithValue';

const ImageToggleButton = ({
  image,
  imageWidth,
  imageHeight,
  label,
  iconId,
  onToggle,
  isSelected,
  isDisabled,
  tagLabel,
  isDownTriangleVisible,
  tooltip,
  barPercentage,
  barScore,
}) => (
  <Tooltip content={tooltip}>
    <div
      className={classNames('image-toggle-button-component', { selected: isSelected, disabled: isDisabled })}
      onClick={() => !isDisabled && onToggle()}
      aria-disabled={isDisabled}
    >
      {tagLabel && (
        <div className={classNames('tag-label-text', { 'smaller-tag': parseInt(imageHeight, 10) < 100 })}>
          {tagLabel}
        </div>
      )}
      <div className="dark-overlay" style={{ width: imageWidth, height: imageHeight }}></div>
      <img className="image-button" src={image} width={imageWidth} height={imageHeight}></img>
      <div className="image-items" style={{ width: imageWidth, height: imageHeight }}>
        <div className="center-items">
          <span className="label">{label}</span>
          {iconId && (
            <Icon
              className={classNames('center-icon', { 'smaller-margin': parseInt(imageHeight, 10) < 100 })}
              iconId={iconId}
              width="14px"
              height="14px"
            ></Icon>
          )}
        </div>
        {barPercentage && barScore && (
          <div className="bar-with-value">
            <BarWithValue
              className="bar-hover"
              percentage={barPercentage}
              score={barScore}
              isHoverEnabled={true}
              isSelected={isSelected}
              isBarBackgroundVisible={true}
            ></BarWithValue>
          </div>
        )}
      </div>
      {isDownTriangleVisible && isSelected && (
        <Icon className="triangle-down-icon" iconId="triangle-down" width="15px" height="22px"></Icon>
      )}
    </div>
  </Tooltip>
);

ImageToggleButton.propTypes = {
  image: PropTypes.string.isRequired,
  imageWidth: PropTypes.string,
  imageHeight: PropTypes.string,
  label: PropTypes.string.isRequired,
  iconId: PropTypes.string,
  onToggle: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
  isDisabled: PropTypes.bool,
  tagLabel: PropTypes.string,
  isDownTriangleVisible: PropTypes.bool,
  tooltip: PropTypes.string,
  barPercentage: PropTypes.number,
  barScore: PropTypes.string,
};

ImageToggleButton.defaultProps = {
  imageWidth: '100%',
  imageHeight: '100%',
  iconId: null,
  isSelected: false,
  isDisabled: false,
  tagLabel: null,
  isDownTriangleVisible: false,
  tooltip: null,
};

export default ImageToggleButton;
