class BaseMapComponent {
  constructor(country) {
    this.country = country
  }

  setMapNameFromScale(scale) {
    this.mapName = `${this.country}/${this.mapSizes[scale]}`;
  }

  getPopupFunction(formula) {
    switch (formula) {
      case 'skew':
        return this.skewPopup.bind(this);
      case 'distribution':
        return this.distributionPopup.bind(this);
      case 'consumption':
        return this.consumptionPopup.bind(this);
      default:
        throw new Error(`${formula} has no implementation of popup`)
    }
  }

  skewPopup(geo, data) {
    if (!data) return '';
    let zoneName = this.formatZoneName(geo.properties.name);
    if (_.isEmpty(zoneName) || zoneName == 0) return '';
    let zoneScore = '0.0',
        body = '',
        footer = '';

    if (!_.isEmpty(data)) {
      const zoneData = data.zoneData;
      let moreOrLess;
      if (zoneData.multiZoneText) zoneName = zoneData.multiZoneText;
      zoneScore = zoneData.score.toFixed(2);

      if (zoneScore > 1) {
        moreOrLess = `<b>x${zoneScore}</b> times more`;
      } else if (zoneScore == 1) {
        moreOrLess = 'as'
      } else {
        moreOrLess = `<b>x${(1 / zoneScore).toFixed(2)}</b> times less`;
      }

      body = `<span class="area-popup-body">
                  ${zoneData.term} is ${moreOrLess} likely to be consumed in
                  <br/>${zoneName} ${zoneScore == 1 ? 'as' : 'than'} in 
                  ${this.allRegionsSelected ? 'the entire country'  : 'all of the selected regions'}.
                  </span>`;
      footer = this.getPopupFooter(zoneData.terms, (score) => score.toFixed(2));
    } else {
      if (_.isEmpty(this.terms)) return '';
      const seedText = (this.terms.length > 1) ? 'The seed interests are' : 'The seed interest is';
      body = `<span class="area-popup-body">${seedText} unlikely to be consumed in ${zoneName}</span>`;
    }

    const title = this.getPopupTitle(zoneName, zoneScore);
    return this.getPopupTemplate(title, body, footer);
  }

  distributionPopup(geo, data) {
    let zoneName = this.formatZoneName(geo.properties.name);
    if (_.isEmpty(zoneName) || zoneName == 0) return '';
    let body = '',
        footer = '',
        zoneScore = 'none';

    if (!_.isEmpty(data)) {
      const zoneData = data.zoneData;
      if (zoneData.multiZoneText) zoneName = zoneData.multiZoneText;
      zoneScore = (zoneData.score * 100).toFixed(2) + '%';
      body = `<span class="area-popup-body">
                  ${zoneScore} of the content about ${zoneData.term} in<br/>${
          this.allRegionsSelected
              ? _.capitalize(this.country)
              : 'all of the selected regions'} is consumed in ${zoneName}.
                  </span>`;
      footer = this.getPopupFooter(zoneData.terms, (score) => (score * 100).toFixed(2) + "%");
    }

    const title = this.getPopupTitle(zoneName, zoneScore);
    return this.getPopupTemplate(title, body, footer);
  }

  consumptionPopup(geo, data) {
    if (!data) return '';
    let zoneName = this.formatZoneName(geo.properties.name);
    if (_.isEmpty(zoneName) || zoneName == 0) return '';
    let footer = '',
        noneText = '';

    if (!_.isEmpty(data)) {
      const scoreFunc = (score) => (score * 100).toFixed(2);
      const zoneData = data.zoneData;
      if (zoneData.multiZoneText) zoneName = zoneData.multiZoneText;
      const termLabel = `${zoneData.term}: <b>${scoreFunc(zoneData.score)}</b>`;
      const allTerms = [termLabel].concat(_.map(zoneData.terms, term =>`${term.term}: <b>${scoreFunc(term.score)}</b>`));
      footer = `<span class="area-popup-footer">${allTerms.join(' ; ')}</span>`;
    } else {
      noneText = ' none';
    }

    const title = `<span class="area-popup-title">${zoneName} consumption:${noneText}</span>`;
    return this.getPopupTemplate(title, '', footer);
  }

  getPopupTemplate(title, body, footer) {
    return `<div class="map-popup">
      ${title}
      ${body}
      ${footer}
    </div>`
  }

  getPopupTitle(name, score) {
    return `<span class="area-popup-title">${name}: ${score}</span>`
  }

  getPopupFooter(terms, fixScoreFunc) {

    const footer = _.map(terms, (term) => {
      const termScore = term.score || 0;
      return `${term.term}: <b>${fixScoreFunc(termScore)}</b>`
    });
    return `<span class="area-popup-footer">${footer.join(' ; ')}</span>`
  }

  formatZoneName(name) {
    return _.startCase(_.toLower(name));
  }
}


module.exports = BaseMapComponent
