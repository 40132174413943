import urlConfig from 'infra/config';
import c from 'infra/utils/common';

module.exports = angular.module(__filename, []).service('geoService', ['$http', '$q', '$timeout', 'errorMgmt', 'topicsTree','authenticationConfig', 'abiPermissions', 'TABS',
    function ($http, $q, $timeout, errorMgmt, topicsTree, authenticationConfig , abiPermissions, TABS) {
        let GEOS = [
            {label: 'United States', id: '840', cc: 'US'},
            {label: 'UK', id: '826', cc: 'GB'},
            {label: 'Australia', id: '036', cc: 'AU'},
            {label: 'Canada', id: '124', cc: 'CA'},
            {label: 'Indonesia', id: '360', cc: 'ID'},
            {label: 'Ireland', id: '372', cc: 'IE'},
            {label: 'New Zealand', id: '554', cc: 'NZ'},
            {label: 'Philippines', id: '608', cc: 'PH'},
            {label: 'Singapore', id: '702', cc: 'SG', sub_type_prefix: 'user.home.'}
        ];
        let GEO_IDS = _.map(GEOS, 'id');

        let STATES = _.keyBy(GEOS, 'label'),
            STATES_CHANNELS = {
            au_telco: [STATES.Australia],
            sg_telco: [STATES.Singapore],
            sg_bidstream: [STATES.Singapore],
            bidstream: [STATES.Singapore]
        };

        let ALL_GEOS = GEOS.slice(0);
        let numOfallSupportedGeos = 0;
        let GEOS_HASH = _.keyBy(GEOS, 'id');
        let GEO_API = urlConfig.SEARCH_API + "/dashboard/geo";
        let COUNTRIES_URL = urlConfig.USER_MGMT_API + '/bi_supported_countries?disable_notifications=1';
        let ALL_COUNTRIES_URL = urlConfig.USER_MGMT_API + '/bi_all_supported_countries?disable_notifications=1';
        let ORG_COUNTRIES_URL = urlConfig.USER_MGMT_API + '/account_countries?disable_notifications=1';
        let EXPORT_COUNTRIES_URL = urlConfig.USER_MGMT_API + '/bi_supported_countries_all_users?disable_notifications=1';

        let subGeosPromise;

        // After authentication get all geos.
        authenticationConfig.doAfterAuthentication.push(['authInfo', '$q', function (authInfo, $q) {
            getAllGeos();
            getGeosForUser(authInfo.id).then((userGeos) =>{
                const userGeoKeys = Object.keys(userGeos);
                const US_GEO = STATES['United States'];
                const AU_GEO = STATES.Australia;
                if (userGeoKeys.includes(US_GEO.id)) {
                    STATES_CHANNELS.hasOwnProperty('articles') ? STATES_CHANNELS.articles.push(US_GEO) : STATES_CHANNELS.articles = [US_GEO];
                }
                if (userGeoKeys.includes(AU_GEO.id) && abiPermissions.hasPermission('insights geo au map')) {
                    STATES_CHANNELS.hasOwnProperty('articles') ? STATES_CHANNELS.articles.push(AU_GEO) : STATES_CHANNELS.articles = [AU_GEO];
                }
            });
        }]);
        function getGeos(user_id) {
            return $http({
                url: COUNTRIES_URL + "&user_id=" + user_id,
                method: 'GET',
                cache: false,
                async: false,
                timeout: 6000
            }).then(function success(res) {
                GEOS = res.data;
                GEO_IDS = _.map(GEOS, 'id');
                numOfallSupportedGeos = GEOS.length;
                abiPermissions.addGeosPermissions(_.map(GEOS, g => `geos.${g.cc}`));
                return GEOS_HASH = _.keyBy(GEOS, 'id');
            }, function failure(err) {
                console.log("GEO fetch from server failed")
            });
        }

        function getAllGeos() {
            return $http({
                url: ALL_COUNTRIES_URL,
                method: 'GET',
                cache: false,
                async: false,
                timeout: 6000
            }).then(function success(res) {
                ALL_GEOS = res.data;
                GEO_IDS = _.map(ALL_GEOS, 'id');
                numOfallSupportedGeos = ALL_GEOS.length;
                return GEOS_HASH = _.keyBy(ALL_GEOS, 'id');
            }, function failure(err) {
                console.log("GEO fetch from server failed")
            });
        }

        function getGeosForUser(user_id) {
            return $http({
                url: COUNTRIES_URL + "&user_id=" + user_id,
                method: 'GET',
                cache: false,
                async: false,
                timeout: 6000
            }).then(function success(res) {
                return _.keyBy(res.data, 'id');
            }, function failure(err) {
                console.log("GEO fetch from server failed")
            });
        }
        function getGeoOptions(account_id) {
          return $http({
            url: ORG_COUNTRIES_URL+"&account_id="+account_id,
            method: 'GET',
            cache: false,
            async: false,
            timeout: 6000
          }).then(function success(res) {
            return res.data;
          }, function failure(err) {
            console.log("GEO fetch from server failed")
          });
        }

        function getGeosForOrg(account_id) {
          return $http({
            url: ORG_COUNTRIES_URL+"&account_id="+account_id,
            method: 'GET',
            cache: false,
            async: false,
            timeout: 6000
          }).then(function success(res) {
            return _.keyBy(res.data, 'id');
          }, function failure(err) {
            console.log("GEO fetch from server failed")
          });
        }

        function geosForChannel(geos, $state, context, ids) {
            let channelGeos = geos, channels = c.getChannels($state, context);

            if (channels.length === 1 && (['sg_telco', 'sg_bidstream', 'bidstream'].includes(channels[0]))) {
                channelGeos = [STATES.Singapore];
            } else if(channels.length === 1 && channels[0] === 'au_telco') {
                channelGeos = [STATES.Australia];
            } else if(channels.length === 2 && channels.includes('au_telco') && channels.includes('sg_telco')) {
                channelGeos = handleSupportedCountries([STATES.Singapore, STATES.Australia]);
            } else {
                channelGeos = handleSupportedCountries(channelGeos);
            }
            if (ids && channelGeos.length && channelGeos[0].id) {
                channelGeos = _.map(channelGeos, "id");
            }
            return channelGeos;
        }

        function subGeosForChannel(sub_geos, $state, context) {
            if (!sub_geos) return [];
            const channels = c.getChannels($state, context);
            //some channels support only 1+ geo
            return showSubGeos(channels) ? sub_geos.slice(0) : [];
        }

        // TODO: BROKEN, DOESN'T WORK WITHOUT CONTEXT.
        function showSubGeos(channels, stateName = null) {
          return channels && channels.length === 1 &&
                 (['au_telco', 'sg_telco', 'bidstream', 'articles', 'facebook'].includes(channels[0]));
        }

        function getGeosForExport() {
          return $http({
            url: EXPORT_COUNTRIES_URL,
            method: 'GET',
            cache: false,
            async: false,
            timeout: 6000
          }).then(function success(res) {
            return res.data;
          }, function failure(err) {
            console.log("GEO fetch from server failed")
          });
        }

        function getSubGeos(){
            let deferred = $q.defer();

            $http({
                url: urlConfig.USER_MGMT_API+"/bi_all_supported_regions",
                method: "get",
                contentType: "application/json",
                params: {
                    withDma: abiPermissions.hasPermission('show DMAs')
                }
            }).success((tree) =>  {
                deferred.resolve(convertSubGeos(tree));
            }).error(() =>{
                deferred.resolve({});
            });

            return deferred.promise;
        }

        function convertSubGeos(tree){
            function convertSGTelcoModel(tree) {
                const sg = _.first(STATES_CHANNELS.sg_telco);
                const sgGeos = tree[sg.id];
                const geoSortFunc = (a,b) => a.name.localeCompare(b.name);

                sgGeos.sort(geoSortFunc)
                    .forEach(obj => {
                        obj.type = `${sg.sub_type_prefix}${obj.type}`;
                        obj.id = obj.name.toLowerCase();
                        if (obj.children) obj.children
                            .sort(geoSortFunc)
                            .forEach(child => {
                                child.type = `${sg.sub_type_prefix}${child.type}`;
                                child.id = child.name.toLowerCase();
                            });

                    });
                return tree;
            }

            return convertSGTelcoModel(tree);
        }

        function buildSubGeoTree(geoIdToChildrenMap, channel, stateName){
            function verifySelectableField(node) {
                node.selectable = node.selectable !== false;
                (node.children || []).forEach(verifySelectableField);
            }

            function addKeyToTreeObjects(treeParent, treeKey) {
                treeParent.forEach((obj) => {
                    obj.treeKey = treeKey;
                    if (obj.children) addKeyToTreeObjects(obj.children, treeKey);
                });
            }

            function addGeoKeyToChildren(tree) {
                const clonedTree = _.cloneDeep(tree);
                clonedTree.forEach(({children, id}) => {
                    if (children) addKeyToTreeObjects(children, id);
                });

                return clonedTree;
            }

            function geosWithChildrenForState(){
                if (stateName === TABS.INSIGHTS_REGIONS){
                    return _.keyBy([STATES['United States'], STATES.Australia], 'id');
                }
                return _.keyBy([STATES['United States']], 'id');
            }

            function buildSubGeoTreeFromMap(){
                const geos = stateName === TABS.INSIGHTS_REGIONS ? STATES_CHANNELS[channel] : GEOS;
                const geosWithChildren = geosWithChildrenForState();

                return geos.map(geo => {
                    let children =  geoIdToChildrenMap[geo.id];
                    let shouldAddChildren = children && geosWithChildren[geo.id];
                    return {...geo, ...(shouldAddChildren ? {children} : {})};
                })
            }

            let subGeoTree = buildSubGeoTreeFromMap();
            subGeoTree.forEach(verifySelectableField);
            return addGeoKeyToChildren(subGeoTree);
        }

        function setSubGeos(scope, getSubGeosMoldFunc, channels, stateName = null) {
            const channel = channels[0];
            let subGeosTreeHelperInstance = null;
            const subGeosMold = getSubGeosMoldFunc();

            //links context.current.sub_geos > scope.dataTrees.subGeos
            //in pages that need sub geos,
            //place this at page init & channel change event.

            //page dataTree
            const treeExists = scope.dataTrees && scope.dataTrees.subGeos;
            if(!treeExists){
                if(!scope.dataTrees){
                    scope.dataTrees = {};
                }
                scope.dataTrees.subGeos = {customFilter: true};
                if (subGeosMold) {
                    scope.dataTrees.subGeos.saveChecked = function(checkedArray){
                        if (scope.$root?.subGeosTree && subGeosTreeHelperInstance) {
                            const checkedArrayWithoutSelectAllOption = checkedArray.filter(({id}) => id !== 'subGeos');
                            const valToSet = subGeosTreeHelperInstance.checkedArrayToContextSubGeos(checkedArrayWithoutSelectAllOption);
                            getSubGeosMoldFunc().replace(valToSet);
                            scope.firstLevelGeos = getFirstLevelGeos(subGeosTreeHelperInstance, checkedArrayWithoutSelectAllOption);
                        }
                    }
                }
            }

            // root subGeosTree has all states
            if (!scope.$root.subGeosTree) {
                scope.$root.subGeosTreeLoading = true;
                getSubGeosPromise().then((mapping) => {
                    scope.$root.geoIdToChildrenMap = mapping;
                    scope.$root.subGeosTree = buildSubGeoTree(scope.$root.geoIdToChildrenMap, channel, stateName);
                    subGeosTreeHelperInstance = indexedSubGeosTreeHelper(scope.$root.subGeosTree);
                    subGeosTreeForChannel(scope, subGeosMold, channel, subGeosTreeHelperInstance, stateName);
                    scope.$root.subGeosTreeLoading = false;
                });
            } else if (!scope.$root.subGeosTreeLoading) {
                scope.$root.subGeosTree = buildSubGeoTree(scope.$root.geoIdToChildrenMap, channel, stateName);
                subGeosTreeHelperInstance = indexedSubGeosTreeHelper(scope.$root.subGeosTree);
                subGeosTreeForChannel(scope, subGeosMold, channel, subGeosTreeHelperInstance, stateName);
            }
        }

        function shouldResetChekedArray(subGeosTreeHelperInstance, subGeosMoldVal, isCountrySingleSelect) {
            const contextSubGeosHelper = subGeosTreeHelperInstance.contextSubGeosHelper(subGeosMoldVal);
            return !contextSubGeosHelper.isContextGeosAvailable() ||
                (isCountrySingleSelect && getFirstLevelGeos(subGeosTreeHelperInstance, contextSubGeosHelper.getOnlyTopCheckedSubGeos()).length > 1)
        }

        function subGeosTreeForChannel(scope, subGeosMold, channel, subGeosTreeHelperInstance, stateName = null){
            //set sub geos dataTree for this page by channel
            if(!showSubGeos([channel], stateName)) {
                if (subGeosMold) subGeosMold.replace([]);
                return;
            }

            if (scope.$root.subGeosTree.length === 0) return;
            const isCountrySingleSelect = stateName === TABS.INSIGHTS_REGIONS;
            let subGeosMoldVal = (subGeosMold || {})._value || [];
            let checkedArray;

            if (shouldResetChekedArray(subGeosTreeHelperInstance, subGeosMoldVal, isCountrySingleSelect)) {
                checkedArray = scope.$root.subGeosTree.slice(0,1);
                if (subGeosMold) subGeosMold.replace(checkedArray);
            } else {
                checkedArray = subGeosTreeHelperInstance.contextSubGeosHelper(subGeosMoldVal).getOnlyTopCheckedSubGeos();
            }

            if (channel === 'sg_telco') {
                scope.subGeosFilterTitle = 'Audience Location';
                scope.subGeosExportTitle = 'Audience Location';
            } else {
                scope.subGeosFilterTitle = 'Geo';
                scope.subGeosExportTitle = 'Sub Geos';
            }

            const tree = {
                name: scope.$root.subGeosTree[0].label,
                allSelectedLabel: (scope.$root.subGeosTree.length === 1 || isCountrySingleSelect) ? scope.$root.subGeosTree[0].label : 'All',
                showSearch: true,
                children: []
            };

            scope.$root.subGeosTree.forEach(({id, label, children}) => {
               tree.children.push({id, name: label, children});
            });

            scope.dataTrees.subGeos.isParentSingleSelect = isCountrySingleSelect;
            scope.dataTrees.subGeos.children = null;
            scope.dataTrees.subGeos = $.extend(true, scope.dataTrees.subGeos, tree);
            scope.dataTrees.subGeos.checkedArray = checkedArray;
            scope.dataTrees.subGeos.isTopLevelSummarize = true;

            scope.firstLevelGeos = getFirstLevelGeos(subGeosTreeHelperInstance, checkedArray);

            $timeout(() => scope.dataTrees.subGeos.show())
        }

        function getFirstLevelGeos(subGeosTreeHelperInstance, selectedGeos) {
            const firstLevelGeos = new Set();

            selectedGeos.forEach(({id, type, treeKey}) => {
                if (!type && GEOS_HASH[id]) return firstLevelGeos.add(GEOS_HASH[id]);
                const parentGeo = GEOS_HASH[treeKey];
                if (parentGeo) firstLevelGeos.add(parentGeo)
            })

            return [...firstLevelGeos];
        }

        function createRequestSubGeoParam(subGeos, channel = null) {
            return c.isArray(subGeos) ?
                getOnlySelectableTopSubGeos(subGeos, channel)
                : [];
        }

        let config = {whitelistGeos: []};
        let _allValidGeosList = [];

        /*API*/
        return Object.defineProperties({
            get,
            serverValue,
            setWhiteListGeos,
            isAllGeos,
            objToIdArray,
            objToCcArray,
            getGeos,
            setSubGeos,
            genSummaryByIds,
            getGeoOptions,
            getGeosForOrg,
            getGeosForUser,
            geosForChannel,
            showSubGeos,
            createRequestSubGeoParam,
            subGeosForChannel,
            handleSupportedCountries,
            getGeosForExport,
            indexedSubGeosTreeHelper,
            getOnlySelectableTopSubGeos,
            STATES,
            getSubGeosPromise,
            getFirstLevelGeos
        }, {
            geos: {
                get: function () {
                    return _allValidGeosList;
                }
            },

            allGeos: {
              get : function(){
                return ALL_GEOS;
              }
            }
        });
        /*-------------------*/

        function genSummaryByIds(geos){
            const arr = !geos || geos.length === 0 || geos.length === GEOS.length ? ['All Geos'] :
                  geos.map((geo) => GEOS_HASH[geo].label);
            return arr.join(", ");
        }

        function isAllGeos(array) {
            return array && array.length === numOfallSupportedGeos && _.every(array, (cc) => _.includes(GEO_IDS, cc.id));
        }

        function objToIdArray(geos) {
            return objToPropertyArray(geos, 'id');
        }

        function objToCcArray(geos) {
            return objToPropertyArray(geos, 'cc');
        }

        function objToPropertyArray(geos, property) {
            const _geos = handleSupportedCountries(geos);
            return _geos.map((cc) => cc[property]);
        }

        function handleSupportedCountries(geos) {
            const _geos = geos && geos.length ? geos : GEOS;
            return (isAllGeos(_geos)) ? GEOS : _geos;
        }

        function setWhiteListGeos(whitelist_geos) {
            config.whitelistGeos = whitelist_geos;
            if (!_.isEmpty(whitelist_geos)) {
                return _allValidGeosList = _.at(GEOS_HASH, whitelist_geos);
            }
            return _allValidGeosList = GEOS;
        }

        function indexedSubGeosTreeHelper(subGeosTree, geoId) {
            function buildIdToSubGeoMap() {
                let idToSubGeoMap = {};

                function addNodeToMap(node) {
                    idToSubGeoMap[`${node.treeKey}_${node.id}`] = node;
                    (node.children || []).forEach(addNodeToMap);
                }

                _.each(subGeosTree, (node) => {
                    if (!geoId || node.id === geoId) addNodeToMap(node);
                });
                return idToSubGeoMap;
            }

            let idToSubGeoMap = buildIdToSubGeoMap();

            function getIdToSubGeoMap(){
                return idToSubGeoMap;
            }

            function getNodeFromMap(node){
                return idToSubGeoMap[`${node.treeKey}_${node.id}`] || {};
            }

            function flattenStateTreeIds(tree, properties = 'id') {
                let flattenedStateTree = [];
                _.each(tree, function (subTree) {
                    flattenedStateTree.push(_.pick(subTree, properties));
                    if (_.isArray(subTree.children)) {
                        flattenedStateTree = _.concat(flattenedStateTree, flattenStateTreeIds(subTree.children, properties));
                    }
                });

                return flattenedStateTree;
            }

            function contextSubGeosHelper(contextCheckedSubGeos) {
                const outputNode = ({id, name, label, type, treeKey}) => ({id, name: name || label, type, treeKey});

                function getAllCheckedSubGeos() {
                    let resMap = {};

                    function addNodeToResult(node) {
                        resMap[node?.id] = outputNode(node);
                        (node?.children || []).forEach(addNodeToResult);
                    }

                    contextCheckedSubGeos
                        .map(getNodeFromMap)
                        .forEach(addNodeToResult);

                    return _.values(resMap);
                }

                function getOnlyTopCheckedSubGeos() {
                    let removeSet = {};

                    _.flatten(contextCheckedSubGeos.map(geo => (getNodeFromMap(geo).children)))
                        .filter((subGeo) => subGeo !== undefined)
                        .forEach(({id, treeKey}) => removeSet[`${treeKey}_${id}`] = 1);

                    return contextCheckedSubGeos
                        .filter(({id, treeKey}) => !removeSet[`${treeKey}_${id}`])
                        .map(getNodeFromMap)
                        .map(outputNode);
                }

                function getOnlyTopTypedCheckedSubGeos() {
                    return getOnlyTopCheckedSubGeos().filter(({type}) => type);
                }

                function stateTreeContainsContextSubGeos(stateIds) {
                    const subGeos = contextCheckedSubGeos.filter(geo => geo.type);
                    const subGeosTreeForStateIds = subGeosTree
                        .filter(({id}) => stateIds.includes(id))
                        .flatMap(({children}) => children)
                    return _.isEmpty(_.differenceBy(subGeos, flattenStateTreeIds(subGeosTreeForStateIds), 'id'));
                }

                function isContextGeosAvailable(){
                    return _.isEmpty(_.differenceBy(contextCheckedSubGeos, flattenStateTreeIds(subGeosTree), 'id'));
                }

                function isAllStateSubGeosSelected(state) {
                    const stateChildren = getNodeFromMap(state).children || [];
                    return _.isEmpty(contextCheckedSubGeos) || contextCheckedSubGeos.some(({id}) => id === state.id) || _.isEmpty(_.differenceBy(stateChildren, contextCheckedSubGeos, 'id'));
                }

                function getGeoFromContextSubGeos() {
                    if (_.isEmpty(contextCheckedSubGeos)) return null;
                    const properties = ['id', 'type'];
                    const mappedContextCheckedSubGeos = _.map(contextCheckedSubGeos, (subGeo) => _.pick(subGeo, properties));
                    const geoKey = subGeosTree.find(({children}) => _.isEmpty(_.differenceWith(mappedContextCheckedSubGeos, flattenStateTreeIds(children, properties), _.isEqual)));
                    
                    return _.find(GEOS, ['id', geoKey]);
                }

                return {
                    getAllCheckedSubGeos,
                    getOnlyTopCheckedSubGeos,
                    getOnlyTopTypedCheckedSubGeos,
                    stateTreeContainsContextSubGeos,
                    isAllStateSubGeosSelected,
                    getGeoFromContextSubGeos,
                    isContextGeosAvailable
                };
            }

            function checkedArrayToContextSubGeos(checkedArray) {
                let removeSet = {};

                function addToRemoveSet(node) {
                    removeSet[`${node.treeKey}_${node.id}`] = 1;
                    (node.children || []).forEach(addToRemoveSet);
                }

                checkedArray.forEach((checkedItem) => {
                    let node = getNodeFromMap(checkedItem);
                    if (node && !removeSet[`${node.treeKey}_${node.id}`] && node.children && node.selectable) {
                        node.children.forEach(addToRemoveSet);
                    }
                });

                return checkedArray.filter((node) => !removeSet[`${node.treeKey}_${node.id}`])
                                   .map((node) => ({id: node.id, type: node.type, selectable: getNodeFromMap(node).selectable, treeKey: node.treeKey}));
            }

            return {
                contextSubGeosHelper,
                checkedArrayToContextSubGeos,
                flattenStateTreeIds,
                getIdToSubGeoMap,
                getNodeFromMap
            };
        }

        function getOnlySelectableTopSubGeos(contextCheckedSubGeos, channel = null) {
            return contextCheckedSubGeos
                .filter(({selectable, type}) => selectable && type)
                .map ((subGeo) => ({
                    id: subGeo.id,
                    type: getSubGeoTypeByChannel(subGeo, channel)
                }));
        }

        function getSubGeoTypeByChannel(subGeo, channel) {
            return (channel === 'articles' && subGeo.type === 'geo_state') ? 'geo_state_id' : subGeo.type;
        }

        function get(term, geos) {
            return $http({
                url: GEO_API,
                method: 'GET',
                cache: true,
                params: {
                    phrase: term,
                    "cc_filter[]": _.map(serverValue(geos), 'id'),
                    timeframe_start: moment().subtract(1, "month").startOf('day').format(),
                    timeframe_end: moment().startOf('hour').format(),
                    sensitive_content: topicsTree.isSensitive
                }
            }).then(function (res) {
                if (!res) return false;
                let country_map = {};
                let most_consumed = [];
                let data = _.sortBy(res.data, 'val').reverse();

                for (let i = 0; i < Math.min(data.length, 15); i++) {
                    let row = data[i];
                    if (i < 5) {
                        country_map[row['cc']] = {fillKey: 'HIGH'};
                        if (i === 0) {
                            most_consumed.push(row['name']);
                        }
                    } else {
                        country_map[row['cc']] = {fillKey: 'LOW'};
                    }
                }

                return {countries_codes: country_map, countries_labels: most_consumed.join(', ')};
            }, function (err) {
                errorMgmt.widgetServiceError('Geo', err);
            });
        }

        /** just shortcut function*/
        function serverValue(geos) {
            return _.or(geos, _allValidGeosList);
        }

        function getSubGeosPromise () {
            if (subGeosPromise) return subGeosPromise;
            return subGeosPromise = getSubGeos();
        }
    }
]);
