module.exports = angular.module(__filename, []).controller('connectSourceController',
  ['$scope', 'close', 'source', 'program_id', 'programSourcesService', 'notificator', 'abiPermissions',
    function ($scope, close, programSource, p_id, service, notificator, abiPermissions) {
        $scope.close = close;
        $scope.isNumber = _.isNumber;
        $scope.save = save;
        $scope.sourceIcon = sourceIcon;
        $scope.selectType = selectType;
        $scope.add = addSource;
        $scope.remove = removeSource;
        $scope.isInputValid = isInputValid;
        $scope.isDisabled = isDisabled;

        const icons = {
            facebook: 'icon-sources-facebook-tiny',
            youtube: 'icon-sources-youtube-tiny',
        };

        const TYPES = [
            {
                value: 'facebook',
                channel: 'facebook',
                icon: 'icon-sources-facebook',
                placeholder: 'www.facebook.com/name OR name',
                permission: 'facebook channel',
                validateInput: function () {
                    const urlRegex = /^(https?:\/\/)?www\.facebook\.com\/(?=([a-zA-Z0-9\-\.]{3,}))\/?/;
                    const userRegex = /^(?!(https?:\/\/)?www\.facebook\.com)([a-zA-Z0-9\-\.]{3,})$/;

                    let valid = urlRegex.test($scope.url);
                    if (valid) {
                        const extractRegex = /^((https?:\/\/)?www\.facebook\.com\/)?([a-zA-Z0-9\-\.]{3,})/;
                        this.username = _.last($scope.url.match(extractRegex));
                    } else {
                        valid = userRegex.test($scope.url);
                        this.username = valid ? $scope.url : null;
                    }

                    $scope.editSourceForm.url_input.$valid = valid;
                },
                extract: function () {
                    return 'www.facebook.com/' + this.username;
                }
            },
            {
                value: 'youtube',
                channel: 'videos',
                icon: 'icon-sources-youtube',
                placeholder: 'www.youtube.com/channel/...',
                permission: 'video channel',
                validateInput: function () {
                    const urlRegex = /^((https?:\/\/)?www\.youtube\.com\/((?:user\/)?[a-zA-Z0-9_]{1,20}|channel\/.{24}))$/;
                    const userRegex = /^[a-zA-Z0-9_]{1,20}$/;

                    let valid = urlRegex.test($scope.url);
                    if (valid) {
                        this.username = _.last($scope.url.match(urlRegex));
                    } else {
                        valid = userRegex.test($scope.url);
                        this.username = valid ? $scope.url : null;
                    }

                    $scope.editSourceForm.url_input.$valid = valid;
                },
                extract: function () {
                    return 'www.youtube.com/' + this.username;
                }
            }
        ];

        $scope.types = _.filter(TYPES, (type) => abiPermissions.hasPermission(type.permission));

        $scope.title = programSource ? 'Edit Source' : 'Add a New Source';
        $scope.programSource = programSource || {sources: []};

        if (programSource) {
            selectType(_.find($scope.types, {value: _.first(programSource.sources).type}));
        }

        function sourceIcon(type) {
            return icons[type];
        }

        function isDisabled(type) {
            return type.disabled || $scope.programSource.sources.length > 0;
        }

        function clearSelected() {
            $scope.url = null;
            $scope.placeholder = null;
            $scope.selectedType = null;
            _.each($scope.types, function (t) {
                t.selected = false;
            });
        }

        function selectType(type) {
            clearSelected();

            type.selected = true;
            $scope.selectedType = type;
            $scope.placeholder = type.placeholder;
        }

        function isInputValid() {
            return $scope.selectedType && $scope.url && $scope.editSourceForm.url_input.$valid;
        }

        function addSource() {
            var url = $scope.selectedType.extract();
            if (_.find($scope.programSource.sources, {url: url})) {
                notificator.notify({body: 'URL already exist'});
            } else if ($scope.programSource.sources.length >= 100) {
                notificator.notify({body: 'Max 100 URLs for source'});
            } else {
                var source = {type: $scope.selectedType.value, url: url, channel: $scope.selectedType.channel};
                $scope.programSource.sources.push(source);
                $scope.url = null;
            }
        }

        function removeSource(index) {
            $scope.programSource.sources.splice(index, 1);
        }

        function save() {
            service.save(p_id, $scope.programSource).then(function () {
                var msg = 'Program source saved successfully' + (programSource ? '' : '. Content from the source will be available within 15 minutes');
                notificator.success({body: msg});
                close({reload: true});
            });
        }
    }
]);
