"use strict";
 
manualPhrasesCtrl.$inject = ['$scope', '$q', 'context', '$timeout', 'parentScope', 'close'];
function manualPhrasesCtrl($scope, $q, context, $timeout, parentScope, close) {

    $scope.manualPhrases = [];
    $scope.tab = parentScope.tab;

    $scope.isWebsites = $scope.tab == 'websites';
    $scope.isResolveKeywords = !$scope.isWebsites;
    
    $scope.add = function(){
        $scope.adding = true;
        setTimeout(()=>{
            parentScope.addManualPhrases($scope.manualPhrases);
            $scope.adding = false;
            this.close();
        });
    }

    $scope.close = function () {
        parentScope.manualPhrasesClosed();
        close({});
    };

    $scope.$on('commit-in-process', (event, data)=> {
        $scope.commitInProcess = data.commitInProcess;
    });


    $timeout(function(){
    	$(".manual-phrases textarea").focus();
    });
}

module.exports = manualPhrasesCtrl;
 
