/*
common target functions and event handlers
to use:
=======
1. include this on every page that has targets
2. include targets-common-partial.html
3. if page needs, include targets-delete-partial.html
4. call getTargets
5. handle updateTargets event
6. page needs to define functions like createTargetWindow
7. if page has contet drivers (right target pane)
     * include entire content-drivers files and logic
     * scope.contentDriversTargets = true;
*/

import config from 'infra/config';
import c from 'infra/utils/common';
import ComplianceService from 'react/services/ComplianceService';
import * as TargetComplianceService from 'react/services/TargetComplianceService';
import * as MixpanelTargets from '../../react/infra/mixpanel/MixpanelTargets';
import moment from 'moment';

module.exports = angular.module(__filename, [require("data/target-data").name])
    .service('TargetsCommon', ['$http', '$timeout', 'context', 'CHANNEL', 'TARGET_CONSTS', 'MARKET_CONTEXT', 'TargetData', 'notificator', 'ModalService', 'abiPermissions', 'ssoService',
        function ($http, $timeout, context, CHANNEL, TARGET_CONSTS, MARKET_CONTEXT, TargetData, notificator, ModalService, abiPermissions, ssoService) {

            function updateSelectedWithFirstParty(state, scope, selected) {
              let target_type = TargetData.getTargetType(state, scope.tab);
              if ([TARGET_CONSTS.DEFAULT_TARGET_TYPE, TARGET_CONSTS.AUDIENCE_TARGET_TYPE].includes(target_type)) {
                let first_party_active = Boolean(scope.trendsIsFirstParty || scope.audienceIsFirstParty);
                _.each(selected, (q) => q.first_party = first_party_active);
              }
            }

            return {
                HOSTS: {
                    [TARGET_CONSTS.DEFAULT_TARGET_TYPE]:         config.USER_MGMT_API+'/targets',
                    [TARGET_CONSTS.AUDIENCE_TARGET_TYPE]:        config.USER_MGMT_API+'/targets',
                    [TARGET_CONSTS.INVENTORY_LISTS_TARGET_TYPE]: config.USER_MGMT_API+'/inventory_lists'
                },

                loadingGetTargets: false,
                TargetData: TargetData,
                mixpanelTargets: MixpanelTargets,

                targetURL: function(target = {target_type: TARGET_CONSTS.DEFAULT_TARGET_TYPE}){
                    return this.HOSTS[target.target_type] + (target._id ? '/'+target._id : '');
                },

                getTargets: function(params) {
                    //so it wont get called while still loading
                    if (this.loadingGetTargets) {
                        return;
                    }
                    this.loadingGetTargets = true;

                    let targetType = TargetData.getTargetType(params.$state, params.tab);
                    let url = this.HOSTS[targetType] + '/all';

                    if (undefined === params.all) { //only targets from current program+channel+target_type
                        let channel = c.getChannels(params.$state, context)[0];
                        url += '?program_id=' + context.current.p_id + '&channel=' + CHANNEL.name(channel) +
                               '&target_type=' + targetType;
                    }

                    let targetsPromise = new Promise((resolve,reject)=> {
                        $http.get(url).then((res) => {
                            resolve(res.data);
                         }, (err) => {
                            console.log(err);
                            notificator.error({body: 'Failed to fetch targets'});
                            resolve([]);
                        });
                    });

                    let marketsPromise = this.getMarkets();

                    Promise.all([targetsPromise, marketsPromise]).then(data => {
                        let [targets, markets] = data;

                        TargetData.markets = $.extend(true, {}, markets);

                        this.initTargets(targets);
                        params.scope.targets = Array.from(targets);

                        this.updateTargetsHandler({action: 'update-target-list', target_type: targetType}, params.scope);
                        if (undefined !== params.cb) {
                            params.cb();
                        }

                        this.loadingGetTargets = false;
                    });
                },

                getMarkets: function(){
                    return new Promise((resolve,reject)=> {
                        if (TargetData.markets) {
                            return resolve(TargetData.markets);
                        }
                        $http.get(config.ACTIVATION_API + '/dsp/markets')
                            .then(res => {
                                let markets = res.data.reduce(
                                    (resMarkets, market) => {
                                        resMarkets[market.marketId] = market.name;
                                        return resMarkets;
                                    },{});
                                resolve(markets);
                            },(res) => {
                                console.log(res);
                                resolve([]);
                            });
                        })
                },

                openTargets: function({scope}){
                    ModalService.showModal({
                        template: require('../../pages/targets/targets-modal.html'),
                        controller: require('../../pages/targets/targets-modal.js'),
                        controllerAs: 'targetsController',
                        inputs: {
                            parent: scope
                        }
                    }).then(function (modal) {
                        modal.close.then(function () {});
                    });
                },

                hasAssignedObjects: function(target) {
                  return this.getAssignedObjects(target).then(
                     (res) => res.data.assigned_concepts.length + res.data.assigned_line_items.length > 0,
                     (err) => false
                  );
                },

                getAssignedObjects: function(target) {
                  const url = this.targetURL(target) + "/assigned_objects";
                  return $http.get(url);
                },

                initTargets: function(targets){
                    const that = this;
                    $.each(targets, function(i, target){
                        that.TargetData.targetToUI({target});
                    });
                },

                showUpdatedList: function($scope, targets) {
                  //show list and moves scroll
                  $scope.queries.targetlist.saveScroll();
                  $scope.queries.targetlist.show(targets);
                  $scope.queries.targetlist.loadScroll();
                },

                openAudienceContextualTarget: function({phrasesPromise, channel, query, audience_name, $scope, program_id, segment}){
                    //opens a new target popup with pre set phrases

                    channel = CHANNEL.name(channel);
                    const tab = 'phrases';
                    
                    const target = {
                        name: audience_name + " " + moment().format('MMM Do, YYYY hh:mm:ss'),
                        channel,
                        target_type: TARGET_CONSTS.AUDIENCE_TARGET_TYPE,
                        program_id: program_id.toString(),
                        query,
                        results: {phrases : []},
                        seeds: [],
                        first_party: segment.some(segment => segment.type === '1st party')
                    }

                    this.edit({target, tab, $scope, phrasesPromise});
                },

                edit: function({target, tab, $scope, phrasesPromise}){
                  if (target.target_type === TARGET_CONSTS.AUDIENCE_TARGET_TYPE && !target._id  || target.target_type === TARGET_CONSTS.INVENTORY_LISTS_TARGET_TYPE) {
                    //target already loaded in targetToCurrentTarget
                    this.TargetData.targetToUI({target: target, action: 'edit-target', context: context});
                    this.openEditDialog({target, tab, $scope, phrasesPromise});
                    return;
                  }

                  $scope.targetLoading = true;

                  $http.get(this.targetURL(target)).then((res)=>{
                    this.TargetData.targetToUI({target: res.data, action: 'edit-target', context: context});

                    $scope.editTargetOpened = true;
                    $scope.targetLoading = false;

                    this.openEditDialog({target: res.data, tab, $scope});

                  },(res) => {
                    console.log(res);
                    let error = res.data.errors ? _.castArray(res.data.errors).join(" ") :
                                                  "Error fetching target. '" + target.name + "'";
                    notificator.error({body: error});
                  });
                },


                openEditDialog({target, tab, $scope, phrasesPromise}){
                    ModalService.showModal({
                        template: require('../interests/modal/modalEditTarget.html'),
                        controller: require('../interests/modal/modalEditTarget.js'),
                        controllerAs: 'editTargetCtrl',
                        inputs: {
                          currentTarget: target,
                          parentTab : tab,
                          dataType: '',
                          phrasesPromise,
                          setContextualUniquenessError: $scope.setContextualUniquenessError,
                          hasAddedPhrases: $scope.hasAddedPhrases
                        }
                    }).then(function (modal) {
                         modal.close.then(function () {
                             $scope.hasAddedPhrases = false;
                             $scope.editTargetOpened = false;
                        });
                    });
                },


                deleteConfirm: function($scope){
                    const id = $scope.deleteTarget._id,
                          name = $scope.deleteTarget.name;

                    let deleteTarget = $.extend(true, {}, $scope.deleteTarget);

                    $scope.deleteInProgress = true;
                    $scope.targetLoading = true;
                    ssoService.getAccessToken().then(accessToken => {
                      let headers = { 'headers' : { 'SSO-Access-Token' : accessToken.accessToken } };
                      $http.delete(this.targetURL(deleteTarget), headers).then(function(){
                          $scope.deleteTarget = null;
                          $scope.deleteInProgress = false;
                          $scope.$root.$broadcast('updateTargets', {action: 'remove-target', _id: id});
                          notificator.success({body: 'Target "' + name + '" deleted'});
                          MixpanelTargets.trackDeleteTarget(deleteTarget);
                          $scope.targetLoading = false;

                      }, (res) => {
                          console.log(res);
                          let error = res.data.errors ? _.castArray(res.data.errors).join(" ") :
                                                        "Error deleting target '" + name + "'";
                          notificator.error({body: error});
                          $scope.targetLoading = false;
                          $scope.deleteInProgress = false;
                          $scope.deleteTarget = null;
                      });
                    });
                },

                createTargetWindow: function(target){
                    ModalService.showModal({
                        template: require('../../pages/targets/create-target.html'),
                        controller: require('../../pages/targets/create-target.js'),
                        controllerAs: 'createTargetCtrl',
                        inputs: {
                            params: target
                        }
                    });
                },

                validateAddToTarget: function(state, scope) {
                  const selected = $.extend(true, [], scope.query.selected);
                  updateSelectedWithFirstParty(state, scope, selected);
                  const isFirstParty = _.some(selected, {'first_party' : true});
                  const cantAddFirstParty = ((scope.currentTarget.audience_type === "general_audience") &&
                                               scope.currentTarget.activated_to.includes("turn") &&
                                               (scope.currentTarget.advertiser_id === undefined ||
                                                 scope.currentTarget.advertiser_id === MARKET_CONTEXT.ALL_ADVERTISERS_ID) &&
                                               isFirstParty);
                  return !cantAddFirstParty;
                },

                checkCompliance: function(scope, phrases){
                    return new Promise(resolve => {
                        try{
                            ComplianceService.checkPhrases({
                                phrases: phrases,
                                feature: 'target',
                                checkGDPR: true,
                                checkNAI: false}).then((res)=>{

                                const notRequired = false === TargetComplianceService.isTargetRequireCompliance(scope.currentTarget);
                                const passedOrNotRequired = res.passed ||notRequired;

                                scope.targetValidating = false;
                                scope.openComplianceModal = !passedOrNotRequired;
                                scope.complianceResults = res.results;
                                scope.nonCompliantPhrases = TargetComplianceService.getNonCompliantPhrases(res.results);
                                scope.nonCompliantPhrasesByTab = passedOrNotRequired ? {[scope.tab]: []} :
                                    {[scope.tab]: Object.keys(scope.nonCompliantPhrases)};
                                scope.nonCompliantText = res.text;

                                if(notRequired && false === res.passed){
                                    TargetComplianceService.addNonCompliantPhrases(scope.nonCompliantPhrases);
                                }

                                $timeout(() => scope.$apply());
                                resolve(passedOrNotRequired);
                            });
                        } catch(er) {
                            scope.targetValidating = false;
                            resolve(false);
                        }
                    });
                },

                addToCurrentTarget: async function ({state, scope, skipCompliance = false}) {
                    //add to current target or create new target with these phrases

                    scope.targetValidating = true;
                    scope.hasAddedPhrases = true;
                    scope.$root.$broadcast('openContentDrivers', "target_drawer");

                    let selected = $.extend(true, [], scope.query.selected);

                    if (skipCompliance) {
                        scope.targetValidating = false;
                    } else {
                        const phrases = selected.map((s)=>TargetComplianceService.entityText(s, scope.tab));
                        const isCompliant = await this.checkCompliance(scope, phrases);

                        if (!isCompliant) {
                            scope.targetValidating = false;
                            return;
                        }
                    }

                    updateSelectedWithFirstParty(state, scope, selected);

                    if (Array.isArray(selected) && selected.length === 0) {
                        scope.targetValidating = false;
                        return;
                    }

                    if (context.current.language) {
                        selected.forEach((phrase) => phrase.lan = context.current.language.value);
                    }

                    if (!scope.currentTarget.name) {
                        scope.createTargetWindow(selected);
                    } else {
                        //check if phrase already exists in target
                        let alreadyExists = '', i, j,
                            res = scope.currentTarget.results && scope.currentTarget.results[scope.tab] ?
                                    scope.currentTarget.results[scope.tab] : [],
                            len = res.length,
                            lenSelected = selected.length;

                        for (i = 0; i < lenSelected; i++) {
                            let phrase = selected[i];
                            for (j = 0; j < len; j++) {
                                if (res[j].id === phrase.id && !res[j].dynamic) {
                                    alreadyExists += phrase[scope.query.columns[0].id] + " , ";
                                    phrase.alreadyExists = true;
                                }
                            }
                        }

                        if (alreadyExists) {
                            notificator.notify({body: alreadyExists.substring(0, alreadyExists.length - 3) + " already exists in this target"});
                        }

                        selected = selected.filter((phrase) => !phrase.alreadyExists);
                        if (!selected.length) {
                            return;
                        }

                        //add to results
                        if (!scope.currentTarget.results) {
                            scope.currentTarget.results = {};
                        }
                        if (!scope.currentTarget.results[scope.tab]) {
                            scope.currentTarget.results[scope.tab] = [];
                        }
                        scope.currentTarget.results[scope.tab] =
                            scope.currentTarget.results[scope.tab].concat(selected);

                        this.TargetData.targetToServer({target: scope.currentTarget});

                        const url = this.targetURL(scope.currentTarget);

                        scope.targetLoading = true;
                        ssoService.getAccessToken().then(accessToken => {
                          let headers = { 'headers' : { 'SSO-Access-Token' : accessToken.accessToken } };
                          $http.put(url, scope.currentTarget, headers).then((res)=>{

                              MixpanelTargets.trackSelectAll(scope.currentTarget);
                              this.updateTargetsHandler({
                                  target: res.data,
                                  action: 'update-target',
                                  resultsAlreadySet: true}, scope);

                              notificator.success({
                                  body: selected.length + ' '+this.TargetData.typePlural(scope.tab, selected.length)+' added to target "' + scope.currentTarget.name + '"'
                              })

                              this.clearSelection(scope);
                              scope.targetLoading = false;
                          }, (res) => {
                              console.log(res);
                              let error = res.data.errors ? _.castArray(res.data.errors).join(" ") : "Error removing from target. '"+scope.currentTarget.name+"'";
                              notificator.error({body: error});
                          });
                        });


                    }
                },

                removeFromTarget: function(currentTarget, tab, words, scope) {
                    const url = this.targetURL(currentTarget);

                    words.forEach((word) => {
                        currentTarget.results[tab] = (currentTarget.results[tab] || []).filter(res => res.id !== word.id);
                    });

                    this.TargetData.targetToServer({target: currentTarget});

                    scope.$parent.targetLoading = true;
                    ssoService.getAccessToken().then(accessToken => {
                      let headers = { 'headers' : { 'SSO-Access-Token' : accessToken.accessToken } };
                      $http.put(url, currentTarget, headers).then((res) => {
                          scope.$root.$broadcast('updateTargets', {
                              target: res.data,
                              action: 'update-target',
                              resultsAlreadySet: true
                          });

                          notificator.success({
                              body: words.length + ' '+this.TargetData.typePlural(tab, words.length)+' removed from target "' + currentTarget.name + '"'
                          })
                          scope.$parent.targetLoading = false;
                      }, (res) => {
                          console.log(res);
                          let error = res.data.errors ? _.castArray(res.data.errors).join(" ") : "Error removing from target. '"+currentTarget.name+"'";
                          notificator.error({body: error});
                      });
                    });

                },

                removeAllFromTarget: function(currentTarget, scope){
                    ModalService.showModal({
                        template: require('../../pages/targets/remove-all-from-target.html'),
                        controller: require('../../pages/targets/remove-all-from-target.js'),
                        controllerAs: 'removeAllFromTargetCtrl',
                        inputs: {
                            target: currentTarget,
                            parentScope: scope
                        }
                    });
                },

                removeAllFromTargetCB: function(currentTarget, scope){
                    let url = this.targetURL(currentTarget);

                    Object.keys(currentTarget.results).forEach((k) =>
                    {
                        if (k === 'phrases') {
                            currentTarget.results[k] = currentTarget.results[k].filter(phrase => phrase.dynamic)
                        } else {
                            currentTarget.results[k] = [];
                        }
                    });

                    let tab = this.targetTabName(currentTarget);

                    this.TargetData.targetToServer({target: currentTarget});

                    let rootScope = scope.$root;

                    scope.targetLoading = true;
                    ssoService.getAccessToken().then(accessToken => {
                      let headers = { 'headers' : { 'SSO-Access-Token' : accessToken.accessToken } };
                      $http.put(url, currentTarget, headers).then((res) => {
                          rootScope.$broadcast('updateTargets', {
                              target: res.data,
                              action: 'update-target',
                              resultsAlreadySet: true
                          });

                          setTimeout(() => rootScope.$broadcast('remove-all-from-target-cb'));
                          notificator.success({
                              body: 'All '+tab+' removed from target "' + currentTarget.name + '"'
                          });

                          scope.targetLoading = false;
                      }, (res) => {
                          console.log(res);
                          let error = res.data.errors ? _.castArray(res.data.errors).join(" ") : "Error removing all from target. '"+$scope.currentTarget.name+"'";
                          notificator.error({body: error});
                      });
                    });
                },

                targetTabName: (target) => Object.keys(target.results||{}).length === 1 ?
                                            Object.keys(target.results)[0] : 'concepts',

                targetInContext: (target_type, channel) => "target_" + target_type + "_" + channel,

                updateTargetsHandler: function (params, scope) {
                    //main handler of all update events
                    //once it updates, sends 'updateTargetsCB'

                    params = params || {};

                    const channel = CHANNEL.name(context.current.gridChannels);

                    if (params.action === "remove-target") {
                        function otherId(row){return row._id !== params._id;}
                        scope.targets = scope.targets.filter(otherId);

                        if (params._id === scope.currentTarget._id) {
                            let target_type = scope.currentTarget.target_type;
                            scope.currentTarget = {};
                            context.current[this.targetInContext(target_type, channel)] = '';
                        }

                        params.targets = scope.targets.slice(0);
                    } else if (params.action === "update-target") {
                        this.TargetData.targetToUI({target: params.target});

                        let ind = scope.targets.findIndex(t => t._id===params.target._id);

                        scope.targets[ind]  = $.extend(true, {}, params.target);

                        //dont set results if resultsAlreadySet
                        //it helps performance and avoids target phrases blink

                        //update only when changed
                        Object.keys(params.target).forEach((key) => {
                            if (key !== 'results' || !params.resultsAlreadySet) {
                                scope.currentTarget[key] = params.target[key];
                            }
                        });

                        params.targets = Array.from(scope.targets);

                    } else if (params.action === 'create-target') {
                        this.TargetData.targetToUI({target: params.target});
                        scope.currentTarget = $.extend(true, {}, params.target);
                        scope.targets.push(params.target);
                        context.current[this.targetInContext(params.target.target_type, channel)] = scope.currentTarget._id;
                        this.clearSelection(scope);
                    } else if (params.action === 'switch-target') {
                        scope.targetLoading = true;
                        scope.currentTarget = $.extend(true, {}, params.target);
                        context.current[this.targetInContext(params.target.target_type, channel)] = scope.currentTarget._id;
                    } else if(params.action === 'update-target-list') {
                        scope.currentTarget = {}; //reset target
                    }

                    //default: get target from context id
                    if (!scope.currentTarget.name) {
                        let targetType = params.target_type || TARGET_CONSTS.DEFAULT_TARGET_TYPE;
                        let targetId = context.current[this.targetInContext(targetType, channel)];
                        let targetIndex = scope.targets.findIndex(target => target._id === targetId);
                        scope.currentTarget = targetIndex >= 0 ? scope.targets[targetIndex] : {};
                    }

                    scope.currentTargetLoaded = true;

                    const url = this.targetURL(scope.currentTarget);
                    TargetData.targetToCurrentTarget(scope, url, params.action);

                    //update all listeners that targets changed
                    scope.$root.$broadcast('updateTargetsCB', params);
                }, //end of updateTargetsHandler



                toggleSelected: function (scope, row, $event) {
                    //shift+click checks/unchecks all checked
                    if ($event) {
                        let index = scope.query.viewArray.indexOf(row),
                            prev = scope.prevIndexSelected === undefined ? index : scope.prevIndexSelected,
                            start = Math.min(index, prev),
                            end = Math.max(index, prev);

                        if ($event.shiftKey) {
                            for (let i = start; i <= end; i++) {
                                scope.query.viewArray[i].selected = row.selected;
                            }
                        }
                        scope.prevIndexSelected = index;
                    }

                    scope.query.selected = (scope.query.dataArray || []).filter((row) => row.selected);
                },

                clearSelection: function(scope) {
                    if (scope.contentDriversTargets) {
                        scope.query.selected = [];
                        scope.query.dataArray.forEach(function (cell) {
                            cell.selected = false;
                        });
                        scope.selectAll = false;
                        scope.$root.$broadcast('openContentDrivers', "target_drawer");
                    }
                },

                hasPermission: abiPermissions.hasPermission
            }
        }
    ]
);