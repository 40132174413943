import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './Separator.scss';

const Separator = ({ hasPadding, className }) => (
  <div className={classNames('separator-component', className, { 'has-padding': hasPadding })}></div>
);

Separator.propTypes = {
  hasPadding: PropTypes.bool,
  className: PropTypes.string,
};

export default Separator;
