import {
  compact,
  toLower,
  uniqBy,
  findIndex,
  map,
  reject,
  filter,
  uniq,
  debounce,
  memoize,
  noop,
  lowerCase,
} from 'lodash';
import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { getSuggestedKeywords } from '../../../services/KeywordSuggestionsService';
import { getTermTextByDisplay, SIGNS } from '../../../utils/TermsUtils';
import InputTag from '../Tags/Tags/InputTag/InputTag';
import './InputBar.scss';
import { CHAR_CODES } from '../../../utils/generalUtils';

const MIN_HEIGHT = 35;
const CONTAINER_HEIGHT = 280;

const InputBar = ({
  limit,
  tags,
  expandable,
  onValidate,
  placeholder,
  onRemoveTag,
  onTagsChange,
  invalidTagMsg,
  showTagPlusButton,
  disableAutocomplete,
  notifyLimitExceeded,
  onTagPlusButtonClick,
}) => {
  const [showSuggestions, updateShowSuggestions] = useState(false);
  const [suggestions, updateStateSuggestions] = useState([]);
  const [isInputMode, updateIsInputMode] = useState(false);
  const [keyCodeEvent, updateKeyCodeEvent] = useState({});
  const [currentTag, updateCurrentTag] = useState('');
  const [stateTags, updateStateTags] = useState([]);
  const textAreaRef = useRef();
  const inputBarRef = useRef();
  const dragIconRef = useRef();
  const stateRef = useRef();

  const suggestedType = 'mentions';
  let initialHeight = null;
  let initialMouseY = null;
  let expand = false;

  useEffect(() => updateStateTags(tags || []), [tags]);

  useEffect(() => {
    if (isInputMode) {
      textAreaRef.current.value = convertTagsToText(stateTags);
      textAreaRef.current.focus();
    }
    stateRef.current = { isInputMode };
  }, [isInputMode]);

  useEffect(() => {
    // subscribing here (and not in onKeyDown) in order to catch the keydown event first.
    textAreaRef.current.addEventListener('keydown', onKeyDown);
    return () => {
      textAreaRef.current?.removeEventListener('keydown', onKeyDown);
    };
  }, [onTagsChange, showSuggestions, stateTags]);

  useEffect(() => {
    if (!expandable) {
      return;
    }

    expand = false;
    initialMouseY = null;
    initialHeight = inputBarRef.current.offsetHeight;

    dragIconRef.current.addEventListener('mousedown', handleMouseClick);
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      dragIconRef.current.removeEventListener('mousedown', handleMouseClick);
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  const handleMouseMove = useCallback((event) => {
    if (!expand) {
      initialMouseY = 0;
      return;
    }
    if (!initialMouseY) {
      initialMouseY = event.clientY;
      initialHeight = inputBarRef.current.offsetHeight;
    }
    const newHeight = initialHeight + event.clientY - initialMouseY;
    if (newHeight > MIN_HEIGHT) {
      inputBarRef.current.style.height = newHeight + 'px';
    }
  }, []);

  const handleMouseUp = useCallback(() => {
    expand = false;
    initialMouseY = 0;
    document.removeEventListener('mousemove', handleMouseMove);
  });

  const handleMouseClick = useCallback(() => {
    expand = true;
    document.addEventListener('mousemove', handleMouseMove);
  });

  const removeTag = (tagValue, event) => {
    event.stopPropagation();
    const newTags = stateTags.filter((tag) => tag.text !== tagValue);
    textAreaRef.current.value = convertTagsToText(newTags);
    onTagsChange(newTags);
    updateStateTags(newTags);
    updateIsInputMode(false);
    if (onRemoveTag) {
      onRemoveTag(tagValue);
    }
  };

  const addTag = (tagValue, event) => {
    event.stopPropagation();
    if (onTagPlusButtonClick) {
      onTagPlusButtonClick(tagValue);
    }
  };

  const onKeyDown = (event) => {
    event.stopPropagation();
    if (event.keyCode === CHAR_CODES.ENTER && !showSuggestions) {
      commit();
    } else {
      updateKeyCodeEvent(event);
    }
  };

  const commitInputTextToViewValue = () => {
    let tagsTextsCandidate = textAreaRef.current.value.split(',').map((text) => getTermTextByDisplay(text));

    tagsTextsCandidate = compact(tagsTextsCandidate);

    if (suggestedType === 'posts') {
      const lastIndex = tagsTextsCandidate.length - 1;
      tagsTextsCandidate[lastIndex] = SIGNS.POST + tagsTextsCandidate[lastIndex].trim();
    }

    tagsTextsCandidate = uniqBy(tagsTextsCandidate, toLower);

    const toViewValue = [];
    tagsTextsCandidate.forEach((tagText, i) => {
      const foundIndex = findIndex(stateTags, { text: tagText });
      if (foundIndex === -1) {
        toViewValue[i] = { text: tagText || null, id: null, invalid: false, isWaiting: true };
      } else {
        toViewValue[i] = stateTags[foundIndex];
      }
    });

    updateStateTags(toViewValue);

    return toViewValue;
  };

  const commit = () => {
    let tags = commitInputTextToViewValue();
    const newTags = reject(tags, 'id');
    const usedTags = filter(tags, 'id');
    const newTagsText = map(newTags, 'text').map(getTermTextByDisplay);
    updateIsInputMode(false);
    updateShowSuggestions(false);

    checkLimit(newTags);
    onValidate(newTagsText, map(usedTags, 'class')).then((tagsToAdd) => {
      tags = commitInputTextToViewValue();
      tagsToAdd.map((tag) => {
        const index = findIndex(tags, { text: tag.origin });
        if (index > -1) {
          tags[index] = tag;
        }
      });
      const newStateTags = uniq(tags);
      updateStateTags(newStateTags);
      onTagsChange(newStateTags);
    });
  };

  const checkLimit = (tagsToCheck) => {
    if (tagsToCheck.length > limit) {
      tagsToCheck.splice(limit, tagsToCheck.length - limit);
      notifyLimitExceeded(tagsToCheck);
    }
  };

  const updateSuggestions = (text) => {
    getSuggestions(text).then((res) => {
      if (stateRef.current.isInputMode) {
        updateStateSuggestions(res);
        updateShowSuggestions(true);
      }
    });
  };

  const handleTextareaChange = (event) => {
    if (!disableAutocomplete) {
      debouncedSuggestions(event.target.value.split(',').pop());
      updateCurrentTag(event.target.value);
    }
  };

  const onSelectSuggestion = (suggestion) => {
    if (suggestion) {
      const tagsNamesArray = textAreaRef.current.value.split(',');
      tagsNamesArray[tagsNamesArray.length - 1] = suggestion.text;
      textAreaRef.current.value = tagsNamesArray.join(',');
    }
    commit();
  };

  const debouncedSuggestions = useMemo(() => debounce(updateSuggestions, 300), []);
  const getSuggestions = useMemo(() => memoize(getSuggestedKeywords), []);

  const closeSuggestions = useCallback(() => {
    updateShowSuggestions(false);
    updateStateSuggestions([]);
  }, []);

  const tagModeClass = isInputMode ? 'input-mode' : 'tags-mode';
  const tooltipOptions = {
    placement: 'bottom',
    theme: 'default-tooltip input-bar-tooltip',
    popperOptions: { modifiers: { preventOverflow: { enabled: false }, flip: { enabled: false } } },
  };

  return (
    <div
      className={classNames('input-bar-component', 'grayscale', tagModeClass)}
      onClick={() => updateIsInputMode(true)}
      ref={inputBarRef}
    >
      <div className="tags-input">
        <div className="tags">
          {stateTags && stateTags.length ? (
            stateTags.map((tag) => (
              <span className={classNames({ 'invalid-tag': tag.invalid })} key={tag.text}>
                <InputTag
                  value={tag.text}
                  onAdd={showTagPlusButton && showTagPlusButton(tag) ? addTag : null}
                  onRemove={removeTag}
                  tooltipOptions={tooltipOptions}
                  showLoader={tag.isWaiting}
                  label={tag.invalid ? <InvalidLabel label={tag.text} invalidLabelMsg={invalidTagMsg} /> : tag.text}
                />
              </span>
            ))
          ) : (
            <span className="place-holder">{placeholder}</span>
          )}
        </div>
        <textarea
          ref={textAreaRef}
          className="textarea-input"
          onChange={handleTextareaChange}
          onBlur={() => updateIsInputMode(false)}
          onFocus={() => updateIsInputMode(true)}
        />
      </div>
      {expandable && (
        <span className="drag-icon" ref={dragIconRef}>
          <i className="icon-drag_box" />
        </span>
      )}

      {showSuggestions && (
        <ScrollableSuggestions
          commit={commit}
          suggestions={suggestions}
          keyCodeEvent={keyCodeEvent}
          disableMentionsPosts={false}
          onSelect={onSelectSuggestion}
          suggestedType={suggestedType}
          closeSuggestions={closeSuggestions}
          currentTag={currentTag.split(',').pop()}
        />
      )}
    </div>
  );
};

const convertTagsToText = (tags) => tags.reduce((text, currentTag) => text + currentTag.text + ', ', '');

InputBar.propTypes = {
  tags: PropTypes.any,
  limit: PropTypes.number,
  onValidate: PropTypes.func,
  expandable: PropTypes.bool,
  onRemoveTag: PropTypes.func,
  onTagsChange: PropTypes.func,
  placeholder: PropTypes.string,
  invalidTagMsg: PropTypes.string,
  showTagPlusButton: PropTypes.func,
  disableAutocomplete: PropTypes.bool,
  notifyLimitExceeded: PropTypes.func,
  onTagPlusButtonClick: PropTypes.func,
};

InputBar.defaultProps = {
  onValidate: noop,
  onTagsChange: noop,
  notifyLimitExceeded: noop,
};

export default InputBar;

const InvalidLabel = ({ label, invalidLabelMsg }) => (
  <span className="invalid-label-component">
    <span className="invalid-label">{label}</span> <span className="err-msg">{invalidLabelMsg}</span>
  </span>
);

InvalidLabel.propTypes = {
  label: PropTypes.string,
  invalidLabelMsg: PropTypes.string,
};

const ScrollableSuggestions = ({ currentTag, suggestions, onSelect, closeSuggestions, keyCodeEvent }) => {
  const [selectedIndex, updateSelectedIndex] = useState(-1);
  const suggestionsContainerRef = useRef(null);
  useEffect(() => {
    switch (keyCodeEvent.keyCode) {
      case CHAR_CODES.UP:
        navigationKey(-1);
        break;
      case CHAR_CODES.DOWN:
        navigationKey(1);
        break;
      case CHAR_CODES.ENTER:
        onSelect(suggestions[selectedIndex]);
        break;
    }
  }, [keyCodeEvent]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [suggestionsContainerRef]);

  function handleClickOutside(event) {
    if (suggestionsContainerRef.current && !suggestionsContainerRef.current.contains(event.target)) {
      closeSuggestions();
    }
  }

  function handleClick(e, suggest) {
    e.stopPropagation();
    e.preventDefault();
    onSelect(suggest);
  }

  function navigationKey(direction) {
    let nextSelectedIndex = selectedIndex + direction;
    if (nextSelectedIndex >= suggestions.length) nextSelectedIndex = 0;
    if (nextSelectedIndex < 0) nextSelectedIndex = suggestions.length - 1;
    updateSelectedIndex(nextSelectedIndex);
    handleSuggestionsScroll(nextSelectedIndex);
  }

  function handleSuggestionsScroll(nextSelectedIndex) {
    const resultsElement = suggestionsContainerRef.current.querySelector('.result');
    if (resultsElement) {
      const rowHeight = resultsElement.offsetHeight;
      const scrollContainer = suggestionsContainerRef.current.querySelector('.results-scrollable');
      scrollContainer.scrollTop = (nextSelectedIndex + 2) * rowHeight - CONTAINER_HEIGHT;
    }
  }

  function splitTextByHighlight(text, highlight) {
    return text.split(new RegExp(`(${highlight})`, 'gi')) || [];
  }

  return (
    <div className={'results'} ref={suggestionsContainerRef}>
      <div className={'results-scrollable'}>
        {currentTag.trim().indexOf('@') !== 0 &&
          suggestions.map((suggest, index) => (
            <div
              className={classNames('result', selectedIndex === index ? 'selected' : '')}
              key={suggest.text}
              onClick={(e) => handleClick(e, suggest)}
            >
              {splitTextByHighlight(suggest.text, currentTag.trim()).map((part, index) => (
                <span
                  key={index}
                  className={classNames({ highlight: lowerCase(part) === lowerCase(currentTag.trim()) })}
                >
                  {part}
                </span>
              ))}
            </div>
          ))}
      </div>
    </div>
  );
};

ScrollableSuggestions.propTypes = {
  disableMentionsPosts: PropTypes.bool,
  closeSuggestions: PropTypes.func,
  suggestedType: PropTypes.string,
  suggestions: PropTypes.array,
  currentTag: PropTypes.string,
  keyCodeEvent: PropTypes.any,
  onSelect: PropTypes.func,
};
