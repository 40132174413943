import config from 'infra/config';
import c from 'infra/utils/common';

var TIMING_API = config.SEARCH_API + '/timing';

module.exports = angular.module(__filename, [])
  .service("timingService", ['$q', 'baseInsightsService', function($q, baseInsightsService) {

    var timing = {};
    var NUM_BUCKETS = 7;
    var MID_BUCKET = (NUM_BUCKETS + 1) / 2;
    var colors = _.range(1, NUM_BUCKETS + 1);
    var bottom_range_colors = _.range(1, MID_BUCKET + 1);
    var top_range_colors = _.range(MID_BUCKET, NUM_BUCKETS + 1);
    var SKEW_MID_VAL = 1;
    var SKEW_MID_OFFSET = 0.02;

    return {
      get: getTiming,
      getTimingForSeed: getTimingForSeed,
      getHeatmapResult: getHeatmapResult
    };

    function getJenksSegments(domain, range) {
      function initializeMatrix(rows, cols, initializeFirstRowVals) {
        var mat = [];
        for (var i = 0; i < rows; i++) {
          mat[i] = new Array(cols).fill(Infinity);
        }
        for (var row = 0; row < mat.length; row++) {
          mat[row][0] = 0;
        }
        mat[0].fill(initializeFirstRowVals);
        mat[0][0] = 0;

        return mat;
      }

      var nbClass = range.length;

      if (domain.length === 0) {
        return function() { return range[0];};
      }

      var dataList = domain.sort(function(a,b) { return a - b; });

      var mat1 = initializeMatrix(dataList.length + 1, nbClass + 1, 1);
      var mat2 = initializeMatrix(dataList.length + 1, nbClass + 1, 0);

      var v = 0.0;

      // and this part - I'm a little clueless on - but it works
      // pretty sure it iterates across the entire dataset and compares each
      // value to
      // one another to and adjust the indices until you meet the rules:
      // minimum deviation
      // within a class and maximum separation between classes
      for ( var l = 2, ll = dataList.length + 1; l < ll; l++) {
        var s1 = 0.0;
        var s2 = 0.0;
        var w = 0.0;
        for ( var m = 1, ml = l + 1; m < ml; m++) {
          var i3 = l - m + 1;
          var val = parseFloat(dataList[i3 - 1]);
          s2 += val * val;
          s1 += val;
          w += 1;
          v = s2 - (s1 * s1) / w;
          var i4 = i3 - 1;
          if (i4 != 0) {
            for ( var p = 2, pl = nbClass + 1; p < pl; p++) {
              if (mat2[l][p] >= (v + mat2[i4][p - 1])) {
                mat1[l][p] = i3;
                mat2[l][p] = v + mat2[i4][p - 1];
              }
            }
          }
        }
        mat1[l][1] = 1;
        mat2[l][1] = v;
      }

      var k = dataList.length;
      var kclass = [];

      // fill the kclass (classification) array with zeros:
      for (var i = 0; i < nbClass; i++) {
        kclass.push(0);
      }

      // this is the last number in the array:
      kclass[nbClass] = dataList[dataList.length - 1];
      // this is the first number - can set to zero, but want to set to lowest
      // to use for legend:
      kclass[0] = dataList[0];
      var countNum = nbClass;
      while (countNum >= 2) {
        var id = (mat1[k][countNum]) - 2;
        kclass[countNum - 1] = dataList[id];
        k = (mat1[k][countNum] - 1);
        // spits out the rank and value of the break values:
        // console.log("id="+id,"rank = " + String(mat1[k][countNum]),"val =
        // " + String(dataList[id]))
        // count down:
        countNum -= 1;
      }
      // check to see if the 0 and 1 in the array are the same - if so, set 0
      // to 0:
      if (kclass[0] == kclass[1]) {
        kclass[0] = 0
      }

      var segments = kclass.slice(1);

      return function(value) {
        var currSeg = 0;
        while (value > segments[currSeg]) {
          currSeg++;
        }

        return range[currSeg];
      };
    }

    function getQuantileScale(domain, range){
      return d3.scale.quantile()
             .domain([d3.min(domain), d3.max(domain)])
             .range(range);
    }

    function applyValidGetScaleFunction(values, colors){
      return values.length >= colors.length * 1.5 ? getJenksSegments(values, colors) : getQuantileScale(values, colors);
    }

    function getSkewScaleFunction(values){
      var top_range = _.some(values, (v) => v >= SKEW_MID_VAL && v <= SKEW_MID_VAL + SKEW_MID_OFFSET) ?
                      top_range_colors : _.difference(top_range_colors, [MID_BUCKET]);
      var bottom_range = _.some(values, (v) => v <= SKEW_MID_VAL && v >= SKEW_MID_VAL - SKEW_MID_OFFSET) ?
                         bottom_range_colors : _.difference(bottom_range_colors, [MID_BUCKET]);
      var top_scale = applyValidGetScaleFunction(_.filter(values, (v) => v >= SKEW_MID_VAL), top_range);
      var bottom_scale = applyValidGetScaleFunction(_.filter(values, (v) => v <= SKEW_MID_VAL), bottom_range);
      return function(value){
          if (value >= SKEW_MID_VAL - SKEW_MID_OFFSET && value <= SKEW_MID_VAL + SKEW_MID_OFFSET){
            return MID_BUCKET;
          }
          var return_value = value >= SKEW_MID_VAL ? top_scale(value) : bottom_scale(value);
          return return_value ? return_value : MID_BUCKET;
      }
    }

    function getScaleFunction(values, measure){
      if (measure == 'skew') return getSkewScaleFunction(values);
      return applyValidGetScaleFunction(values, colors);
    }

    function getTiming(params) {
      var termMap = {};
      var parameters = baseInsightsService.buildParams(termMap, params, 1, true);
      if (params.measure) parameters.measure = params.measure;
      
      delete parameters.audience;
      if (_.isEmpty(termMap)) return { noData: true };

      let promise = baseInsightsService.postAsyncData(TIMING_API, parameters);
      return { promise, termMap };
    }

    function getHeatmapResult(data, termMap, measure){
         timing = buildHeatmapResults(data, termMap, measure);
         return timing;
    }

    function getDayHourByFieldType(fieldType, object) {
      let res = {};

      if (fieldType === 'days_hours' && _.isArray(object)) {
        res.day = _.first(object);
        res.hour = _.last(object);
      } else if (_.isNumber(object)) {
        if (fieldType === 'day_totals') {
          res.day = object;
        } else if (fieldType === 'hour_totals') {
          res.hour = object;
        }
      }

      return res;
    }

    function getNormalizedRecord(entry, seedDisplay, measure) {
      var sum_all_values = _.reduce(entry['days_hours'], function(memo,rec){ return memo + rec[1];}, 0);
      if(sum_all_values == 0){
          return null;
      }
      var result = {'days_hours' : [], 'day_totals' : [], 'hour_totals': []};
      //Normalizing all values to percentage of all data
      var day_totals_color_scale = angular.noop;
      _.each(['days_hours','day_totals','hour_totals'], (field) => {
        result[field] = _.map(entry[field], (rec) => {
          const normalizedVal = measure == 'skew' ? rec[1] : parseFloat((rec[1] * 100.0 / sum_all_values));
          return {title: getTooltipTitle(normalizedVal, getDayHourByFieldType(field, rec[0]), measure, seedDisplay), vals: [rec[0], parseFloat(normalizedVal.toFixed(2))]}
        });
        var values = _.map(result[field], (entry) => _.last(entry.vals));
        var color_scale = getScaleFunction(values, measure);
        if('day_totals' === field){
          day_totals_color_scale = color_scale;
        }
        result[field + '_colors'] = _.map(result[field], (rec) => {
          return [rec.vals[0], color_scale(rec.vals[1])]
        });
      });
      var day_totals = _.map(_.sortBy(result.day_totals, (rec) => _.first(rec.vals)), (rec) => _.last(rec.vals));
      let weekday_avg = c.average(day_totals.slice(0,5)),
          weekend_avg = c.average(day_totals.slice(5));

      result.weekday_total = {title: getTooltipTitle(weekday_avg, {custom: 'on weekdays'}, measure, seedDisplay), val: weekday_avg};
      result.weekend_total = {title: getTooltipTitle(weekend_avg, {custom: 'on weekends'}, measure, seedDisplay), val: weekend_avg};
      result.weekday_total_color = day_totals_color_scale(result.weekday_total.val);
      result.weekend_total_color = day_totals_color_scale(result.weekend_total.val);
      return result;
    }

    function buildHeatmapResults(response, termMap, measure){
      var seed_data = [], foundTerms = {}, result = {};
      _.each(response.data, function (entry, trendName) {
        let term = termMap[trendName] ||
                   _.find(termMap, function(rec){return rec['text'].toLowerCase() == trendName}) ||
                   _.find(termMap, ['id', parseInt(trendName)]);
        if (c.isString(trendName) && c.is(term) && c.isArray(entry['day_totals']) && c.isArray(entry['days_hours']) && c.isArray(entry['hour_totals'])) {
          let record = getNormalizedRecord(entry, (termMap[trendName].display || termMap[trendName].text), measure);
          if(record !== null) {
            foundTerms[term.text] = term;
            record.term = term;
            seed_data.push(record);
          }
        }
      });
      var insufficient = isResponseDataEmpty(seed_data, _.difference(Object.keys(termMap),Object.keys(foundTerms)));
      baseInsightsService.notifyInsufficient(insufficient);
      if(insufficient.length == Object.keys(termMap).length){
        return Promise.reject({});
      }
      result.seeds = seed_data;
      result.average_hours = _.map(_.zip.apply([], _.map(seed_data, (rec) => rec.hour_totals)), (values) => {
        let avg = c.average(_.map(values, (rec) => _.last(rec.vals)));
        let hour = _.first(_.first(values).vals);
        return {title: getTooltipTitle(avg, getDayHourByFieldType('hour_totals', hour), measure), vals: avg};
      });
      result.average_days = _.map(_.zip.apply([], _.map(seed_data, (rec) => rec.day_totals)), (values) => {
        let avg = c.average(_.map(values, (rec) => _.last(rec.vals)));
        let day = _.first(_.first(values).vals);
        return {title: getTooltipTitle(avg, getDayHourByFieldType('day_totals', day), measure), vals: avg};
      });
      let avg_weekday = c.average(_.map(seed_data, (rec) => rec.weekday_total.val)),
          avg_weekend = c.average(_.map(seed_data, (rec) => rec.weekend_total.val));
      result.average_weekday = {title: getTooltipTitle(avg_weekday, {custom: 'on weekdays'}, measure), val: avg_weekday};
      result.average_weekend = {title: getTooltipTitle(avg_weekend, {custom: 'on weekends'}, measure), val: avg_weekend};
      let average_hours = _.map(result.average_hours, (rec) => rec.vals);
      let average_days = _.map(result.average_days, (rec) => rec.vals);
      var hours_color_scale = getScaleFunction(_.clone(average_hours), measure);
      result.average_hours_colors = _.map(average_hours, (average) => hours_color_scale(average));
      var days_color_scale = getScaleFunction(_.clone(average_days), measure);
      result.average_days_colors = _.map(average_days, (average) => days_color_scale(average));
      result.average_weekday_color = days_color_scale(result.average_weekday.val);
      result.average_weekend_color = days_color_scale(result.average_weekend.val);
      return result;
    }

    function isResponseDataEmpty(data, notFound) {
      var insufficient = notFound;
      _.each(data, function(record) {
        if (!record['days_hours'] || !_.some(record['days_hours'], (el) => el.vals[1] > 0)) {
          insufficient.push(record.term.display);
        }
      });

      return insufficient;
    }

    function getTimingForSeed(term){
      return _.find(timing.seeds, function(record){return (term.id == -1 && record.term.text == term.text) || (term.id != -1 && record.term.id == term.id)});
    }

    function getTooltipTitle(score, timePeriod, measure, seed = '') {
      if (measure !== 'skew') return c.outputWithPercent(score);
      let degreeText,
          timePeriodText,
          comparisonText = ' than during the entire week';
      const strScore = score.toFixed(2),
          hours = c.getHeatmapHours(),
          weekDays = c.getWeekDays(),
          seedText = _.isEmpty(seed) ? 'On average, the seeds are' : seed + ' is';

      if (strScore > 1) {
        degreeText = `x${strScore} times <u>more</u> `;
      } else if (strScore == 1) {
        degreeText = 'as ';
        comparisonText = ' as during the entire week';
      } else if (strScore == 0 ) {
        degreeText = 'un';
        comparisonText = '';
      } else {
        degreeText = `x${(1/strScore).toFixed(2)} times <u>less</u> `;
      }

      let hourText = (_.has(timePeriod, 'hour')) ? ` at ${hours[timePeriod.hour-1]}` : '';
      let dayText = (_.has(timePeriod, 'day')) ? ` on ${weekDays[timePeriod.day-1]}s` : '';
      timePeriodText = _.has(timePeriod, 'custom') ? ' ' + timePeriod.custom : hourText + dayText;

      return `${seedText} ${degreeText}likely to be consumed${timePeriodText}${comparisonText}.`
    }
}]);
