import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { compact, difference, find, isEmpty, join, keys, map, debounce, includes, reject, flatMap } from 'lodash';
import './CommercialsModal.scss';
import ModalWithConfirmationButtons from '../../../../../common/Modal/ModalWithConfirmationButtons/ModalWithConfirmationButtons';
import Dropdown from '../../../../../common/Dropdown/Dropdown/Dropdown';
import WindowEventListener from '../../../../../common/WindowEventListener/WindowEventListener';
import TvCustomTimeframe from '../../TvCustomTimeframe/TvCustomTimeframe';
import SelectToggle from '../../../../../common/SelectToggle/SelectToggle';
import ClickableIcon from '../../../../../common/ClickableIcon/ClickableIcon';
import {
  dateToString,
  isSmartTvChannel,
  LEVEL_OF_EXPOSURE_COMMERCIALS_TOOLTIP,
  LEVELS_OF_EXPOSURE,
  VIEWERSHIP_MODES,
  VIEWING_TYPES,
  CHANNELS,
  COUNTRIES,
} from '../../../../../../../data/audience-segment-builder-helper';
import SingleSelectToggle from '../../../../../common/SingleSelectToggle/SingleSelectToggle';
import { getKeywords } from '../../../../../../services/TvService';
import ResultsTable from '../../../../../common/ResultsTable/ResultsTable';
import { getTvCommercialsMetaDataV2 } from '../../../../../../services/AudienceInsightsService';
import DropdownWithHeader from '../../../../../common/Dropdown/DropdownWithHeader/DropdownWithHeader';

const creativeVideoLinkBuilder = (creative) =>
  `http://customportal.kantarmediana.com/Creatives/Gateway.aspx?cid=${creative.value}&mtid=1&uid=20180529`;

const ResponseAttributesEnum = {
  BRAND_PARENT: 'brand_parents',
  BRAND: 'brands',
  PRODUCT: 'products',
  CREATIVE: 'creatives',
};

const ExpectedResponseAttributes = {
  [CHANNELS.smart_tv_inscape?.name]: {
    initNew: [ResponseAttributesEnum.BRAND],
    fetchByBrand: [ResponseAttributesEnum.CREATIVE],
  },
  [CHANNELS.hisense?.name]: {
    initNew: [ResponseAttributesEnum.BRAND, ResponseAttributesEnum.PRODUCT],
    fetchByBrand: [ResponseAttributesEnum.PRODUCT, ResponseAttributesEnum.CREATIVE],
    fetchByProduct: [ResponseAttributesEnum.CREATIVE],
  },
  [CHANNELS.tivo?.name]: {
    initNew: [ResponseAttributesEnum.BRAND_PARENT, ResponseAttributesEnum.BRAND, ResponseAttributesEnum.PRODUCT],
    fetchByParent: [ResponseAttributesEnum.BRAND, ResponseAttributesEnum.PRODUCT, ResponseAttributesEnum.CREATIVE],
    fetchByBrand: [ResponseAttributesEnum.PRODUCT, ResponseAttributesEnum.CREATIVE],
    fetchByProduct: [ResponseAttributesEnum.CREATIVE],
  },
};

const CommercialsModal = ({
  isOpen,
  modalTitle,
  onSubmit,
  onCancel,
  commercialsInput,
  commercialsMetadataPromise,
  channel,
  isTimeframeVisible,
  geo,
}) => {
  /* eslint-enable camelcase */
  const SEGMENT_TYPE = 'smartTvCommercials';
  const CHANNELS_WITHOUT_BRAND_PARENT = [CHANNELS.smart_tv_inscape?.name, CHANNELS.hisense?.name];
  const WITH_BRAND_PARENT = !includes(CHANNELS_WITHOUT_BRAND_PARENT, channel);
  const IS_ADVANCED_TV_CHANNEL = includes([CHANNELS.tivo?.name, CHANNELS.hisense?.name], channel);
  const FULL_SCREEN_MAX_HEIGHT = '600px';
  const SMALL_SCREEN_MAX_HEIGHT = '515px';
  const TOOLTIP_MSG = 'Currently streaming data does not have commercials';

  // values currently in dropdown
  const [brandParentValues, setBrandParentValues] = useState([]);
  const [brandValues, setBrandValues] = useState([]);
  const [creativeValues, setCreativeValues] = useState([]);
  const [productsValues, setProductsValues] = useState([]);

  // values selected in dropdown
  const [selectedBrandParents, setSelectedBrandParents] = useState(commercialsInput.brandParents || []);
  const [selectedBrands, setSelectedBrands] = useState(commercialsInput.brands || []);
  const [selectedCreatives, setSelectedCreatives] = useState(commercialsInput.creatives || []);
  const [selectedProducts, setSelectedProducts] = useState(commercialsInput.products || []);

  const geoParam = CHANNELS[channel]?.isGeoMandatory ? geo : '';
  const [showSpinner, setShowSpinner] = useState(true);
  const [modalMaxHeight, setModalMaxHeight] = useState(FULL_SCREEN_MAX_HEIGHT);
  const [selectedStartDate, setSelectedStartDate] = useState(commercialsInput.startDate);
  const [selectedEndDate, setSelectedEndDate] = useState(commercialsInput.endDate);
  const [selectedTimeframe, setSelectedTimeframe] = useState(commercialsInput.timeframe);
  const [levelOfExposure, setLevelOfExposure] = useState(commercialsInput.exposure);

  const isHisenseUS = channel === CHANNELS.hisense?.name && geoParam === COUNTRIES.US?.cc.toLowerCase();

  const [viewingType, setViewingType] = useState(!isHisenseUS ? 'linear' : commercialsInput.viewingType || 'both');
  const [viewershipModes, setViewershipModes] = useState(commercialsInput.viewModes || []);
  const LIMITED_VIEWING_TYPES = [
    { label: 'Both', value: 'both', disabled: true, tooltip: TOOLTIP_MSG },
    { label: 'Streaming', value: 'streaming', disabled: true, tooltip: TOOLTIP_MSG },
    { label: 'Linear', value: 'linear' },
  ];
  const [isEmptyCommercialInput] = useState(Object.keys(commercialsInput).length === 0);
  const [isEditByKeywords] = useState(
    !isEmptyCommercialInput
      ? commercialsInput.brandParents?.length === 0 &&
          commercialsInput.brands?.length === 0 &&
          commercialsInput.products?.length === 0 &&
          commercialsInput.creatives?.length !== 0
      : false
  );

  const setModalMaxHeights = () => {
    setModalMaxHeight(window.innerHeight > 900 || WITH_BRAND_PARENT ? FULL_SCREEN_MAX_HEIGHT : SMALL_SCREEN_MAX_HEIGHT);
  };

  useEffect(() => {
    setModalMaxHeights();
  });

  const fetchPropsValidation = (res, expectedResponseAttributes) => {
    const missingProperties = difference(expectedResponseAttributes, keys(res));
    if (missingProperties.length)
      throw new Error(`The following properties are missing: ${join(missingProperties, ', ')}`);
  };

  const fetchCommercialsMetadata = async () => {
    setShowSpinner(true);
    const res = await commercialsMetadataPromise;
    fetchPropsValidation(res, ExpectedResponseAttributes[CHANNELS[channel]?.name].initNew);

    if (res[ResponseAttributesEnum.BRAND_PARENT]) setBrandParentValues(res[ResponseAttributesEnum.BRAND_PARENT]);
    if (res[ResponseAttributesEnum.BRAND]) setBrandValues(res[ResponseAttributesEnum.BRAND]);
    if (res[ResponseAttributesEnum.PRODUCT]) setProductsValues(res[ResponseAttributesEnum.PRODUCT]);
    setShowSpinner(false);
  };

  const fetchCommercialsMetadataByParams = (parents, brands, products) => {
    setShowSpinner(true);
    return getTvCommercialsMetaDataV2(
      channel,
      flatMap(parents, 'value'),
      flatMap(brands, 'value'),
      flatMap(products, 'value')
    ).finally(() => {
      setShowSpinner(false);
    });
  };

  useEffect(() => {
    setBrandParentValues([]);
    setBrandValues([]);
    setProductsValues([]);
    setCreativeValues([]);
    const fetchInitialMetadata = async () => await fetchCommercialsMetadata();
    fetchInitialMetadata();
    if (!isEmptyCommercialInput && !isEditByKeywords)
      fetchCommercialsMetadataByParams(selectedBrandParents, selectedBrands, selectedProducts).then((res) => {
        let expectedResponseAttributes = [];
        if (selectedBrandParents.length)
          expectedResponseAttributes = ExpectedResponseAttributes[CHANNELS[channel]?.name].fetchByParent;
        else if (selectedBrands.length)
          expectedResponseAttributes = ExpectedResponseAttributes[CHANNELS[channel]?.name].fetchByBrand;
        else if (selectedProducts.length)
          expectedResponseAttributes = ExpectedResponseAttributes[CHANNELS[channel]?.name].fetchByProduct;

        fetchPropsValidation(res, expectedResponseAttributes);
        if (res[ResponseAttributesEnum.BRAND]) setBrandValues(res[ResponseAttributesEnum.BRAND]);
        if (res[ResponseAttributesEnum.PRODUCT]) setProductsValues(res[ResponseAttributesEnum.PRODUCT]);
        if (res[ResponseAttributesEnum.CREATIVE]) setCreativeValues(res[ResponseAttributesEnum.CREATIVE]);
      });
  }, [commercialsMetadataPromise]);

  const setStatesFromFetch = (res, isSelectFetch) => {
    if (res[ResponseAttributesEnum.BRAND]) {
      setBrandValues(res[ResponseAttributesEnum.BRAND]);
      setSelectedBrands(isSelectFetch ? res[ResponseAttributesEnum.BRAND] : []);
    }
    if (res[ResponseAttributesEnum.PRODUCT]) {
      setProductsValues(res[ResponseAttributesEnum.PRODUCT]);
      setSelectedProducts(isSelectFetch ? res[ResponseAttributesEnum.PRODUCT] : []);
    }
    if (res[ResponseAttributesEnum.CREATIVE]) {
      setCreativeValues(res[ResponseAttributesEnum.CREATIVE]);
      setSelectedCreatives(isSelectFetch ? res[ResponseAttributesEnum.CREATIVE] : []);
    }
  };
  const fetchByParent = (isSelectFetch = true) => {
    if (isEmpty(selectedBrandParents)) {
      resetSelection();
      return;
    }
    fetchCommercialsMetadataByParams(selectedBrandParents, undefined, undefined).then((res) => {
      fetchPropsValidation(res, ExpectedResponseAttributes[CHANNELS[channel]?.name].fetchByParent);
      setStatesFromFetch(res, isSelectFetch);
    });
  };

  const fetchByBrand = (isSelectFetch = true) => {
    if (isEmpty(selectedBrands)) {
      onResetBrands();
      return;
    }
    fetchCommercialsMetadataByParams(undefined, selectedBrands, undefined).then((res) => {
      fetchPropsValidation(res, ExpectedResponseAttributes[CHANNELS[channel]?.name].fetchByBrand);
      setStatesFromFetch(res, isSelectFetch);
    });
  };

  const fetchByProduct = (isSelectFetch = true) => {
    if (isEmpty(selectedProducts)) {
      onResetProducts();
      return;
    }
    fetchCommercialsMetadataByParams(undefined, undefined, selectedProducts).then((res) => {
      fetchPropsValidation(res, ExpectedResponseAttributes[CHANNELS[channel]?.name].fetchByProduct);
      setStatesFromFetch(res, isSelectFetch);
    });
  };

  const getDropdownSummaryTextBuilder = (allSelectedText, noneSelectedText, emptySelectedText = 'Select') => (
    selectedValues,
    values
  ) => {
    if (!selectedValues.length) return noneSelectedText;
    if (selectedValues.length === 1 && isEmpty(selectedValues[0])) return emptySelectedText;
    if (selectedValues.length === values.length) return allSelectedText;
    return map(selectedValues, 'label').join(', ');
  };

  const parentsSummaryTextBuilder = getDropdownSummaryTextBuilder('Select parent brands', 'Select parent brands');
  const brandsSummaryTextBuilder = getDropdownSummaryTextBuilder('All brands', 'Select brands', 'Select brands');
  const creativesSummaryTextBuilder = getDropdownSummaryTextBuilder('All creatives', 'Select creatives');
  const productsSummaryTextBuilder = getDropdownSummaryTextBuilder('All products', 'Select products');

  const keywordsSearchDebounce = debounce((searchString, geo, resolve) => {
    const reduceFunction = (param, item) => `${param ? param + ',' : ''}${item.value}`;
    const brand = selectedBrands.reduce(reduceFunction, '');
    const parentBrand = selectedBrandParents.reduce(reduceFunction, '');
    const paramsObj = { text: searchString };
    if (parentBrand) {
      paramsObj['parent_brand'] = parentBrand;
    }
    if (brand) {
      paramsObj.brand = brand;
    }
    let queryParams = new URLSearchParams(paramsObj).toString();
    queryParams = decodeURIComponent(queryParams);
    getKeywords(channel, queryParams, geoParam).then((res) =>
      resolve(
        res.map((item) => ({
          label: `${item.creative} | ${item.product_name}, ${item.brand_parent}, ${item.brand}`,
          value: item.ad_id,
          product_label: item.product_name,
          brand_label: item.brand,
          brand_parent_label: item.brand_parent,
          secondaryText: `${item.brand_parent} | ${item.brand}`,
        }))
      )
    );
  }, 300);

  const keywordsSearch = (searchString) =>
    new Promise((resolve) => keywordsSearchDebounce(searchString, geoParam, resolve));

  const resetSelection = () => {
    setSelectedBrandParents([]);
    setSelectedBrands([]);
    setSelectedProducts([]);
    setSelectedCreatives([]);
    fetchCommercialsMetadata();
  };

  const onResetBrands = () => {
    setSelectedBrands([]);
    if (WITH_BRAND_PARENT) {
      fetchByParent(false);
    } else {
      resetSelection();
    }
  };

  const onResetProducts = () => {
    setSelectedProducts([]);
    setSelectedCreatives([]);
    fetchByBrand(false);
  };

  const onRemove = (item, type) => {
    const resultsWrapper = find(resultsTable, { type });
    const selectedResults = reject(resultsWrapper.results, { value: item.value });
    switch (type) {
      case 'parent_brand':
        setSelectedBrandParents(selectedResults);
        fetchByParent(false);
        break;
      case 'brand':
        setSelectedBrands(selectedResults);
        fetchByBrand(false);
        break;
      case 'product':
        setSelectedProducts(selectedResults);
        fetchByProduct(false);
        break;
      case 'creative':
        setSelectedCreatives(selectedResults);
        break;
    }
  };

  const selectQueryKeys = useCallback(() => {
    if (!isAllSelected(selectedCreatives, creativeValues)) return ['brands', 'creatives'];
    if (!isAllSelected(selectedBrands, brandValues)) return ['brands'];
    return ['brandParents'];
  }, [selectedCreatives, selectedBrands, brandValues, creativeValues]);

  const isAllSelected = (selectedValues, values) => !selectedValues.length || selectedValues.length === values.length;

  const maxNumberOfSelectedBrands = WITH_BRAND_PARENT ? 500 : 7;
  const reachMaxSelectedBrandsMsg = `Sorry, this field is limited to ${maxNumberOfSelectedBrands} brands`;

  const resultsTable = useMemo(
    () =>
      compact([
        !isEmpty(selectedBrandParents) && {
          type: 'parent_brand',
          title: 'Parent brand',
          results: selectedBrandParents,
        },
        !isEmpty(selectedBrands) && {
          type: 'brand',
          title: 'Brand',
          results: selectedBrands,
        },
        !isEmpty(selectedProducts) && {
          type: 'product',
          title: 'Product',
          results: selectedProducts,
        },
        !isEmpty(selectedCreatives) && {
          type: 'creative',
          title: 'Creative',
          results: selectedCreatives,
        },
      ]),
    [selectedCreatives, selectedProducts, selectedBrandParents, selectedBrands]
  );

  const isHyperLink = (type) => channel === CHANNELS.tivo?.name && type === 'creative';

  const handleSubmit = useCallback(() => {
    const segment = {
      ...commercialsInput,
      type: SEGMENT_TYPE,
      channel,
      queryKeys: selectQueryKeys(),
      ...(WITH_BRAND_PARENT && { brandParents: selectedBrandParents }),
      brands: selectedBrands,
      ...(IS_ADVANCED_TV_CHANNEL && { products: selectedProducts }),
      creatives: selectedCreatives,
      ...(selectedStartDate && { startDate: dateToString(selectedStartDate) }),
      ...(selectedEndDate && { endDate: dateToString(selectedEndDate) }),
      ...(selectedTimeframe && { timeframe: selectedTimeframe }),
      ...(isSmartTvChannel(channel) && { exposure: levelOfExposure }),
    };
    if (!selectedTimeframe) {
      delete segment.timeframe;
    }

    if (IS_ADVANCED_TV_CHANNEL) {
      segment.viewingType = viewingType;
      segment.viewershipModes = viewershipModes;
    }
    onSubmit(segment);
  }, [
    selectedCreatives,
    selectedBrands,
    selectedBrandParents,
    selectedProducts,
    brandParentValues,
    brandValues,
    creativeValues,
    selectedStartDate,
    selectedEndDate,
    selectedTimeframe,
    levelOfExposure,
    viewingType,
    viewershipModes,
  ]);

  const handleCancel = () => {
    onCancel(SEGMENT_TYPE);
  };

  return (
    <div className="commercials-modal-component">
      <WindowEventListener events="resize" eventHandlerFunction={setModalMaxHeights} />
      <ModalWithConfirmationButtons
        width="800px"
        maxHeightBeforeScroll={modalMaxHeight}
        modalTitle={modalTitle}
        isOpen={isOpen}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        onXClick={handleCancel}
        showSpinner={showSpinner}
        isSubmitDisabled={isEmpty(selectedCreatives)}
        isDisabledEnterKeyPress={isEmpty(selectedCreatives)}
        isLightTheme
        isYAutoScrollEnabled={false}
      >
        <div className="commercials-modal-content">
          {IS_ADVANCED_TV_CHANNEL && (
            <div className="modal-row viewer-type-wrapper">
              <div className="viewing-type">
                <div className="row-label">Viewing Type:</div>
                <SingleSelectToggle
                  values={isHisenseUS ? VIEWING_TYPES : LIMITED_VIEWING_TYPES}
                  selectedValue={viewingType}
                  onToggle={setViewingType}
                  isModalTheme
                />
              </div>
              {viewingType != 'streaming' && (
                <div className="viewership-mode">
                  <div className="row-label">Viewership Mode:</div>
                  <Dropdown
                    values={VIEWERSHIP_MODES}
                    selectedValues={viewershipModes}
                    isMulti
                    summaryTextBuilder={getDropdownSummaryTextBuilder('All viewership modes')}
                    onSelect={setViewershipModes}
                  />
                </div>
              )}
            </div>
          )}

          {WITH_BRAND_PARENT && (
            <div className="modal-row parent-brands">
              <DropdownWithHeader
                selectedValues={selectedBrandParents}
                isMulti
                isSearchable
                summaryTextBuilder={parentsSummaryTextBuilder}
                onSelect={setSelectedBrandParents}
                values={brandParentValues}
                maxNumberOfSelectedOptions={3}
                reachMaxSelectedOptionsMsg="Sorry, this field is limited to 3 parent brands"
                onClose={fetchByParent}
                headerTitle="Parent brand:"
                headerResetText="Reset"
                onReset={resetSelection}
              />
            </div>
          )}
          <div className="modal-row brands">
            <DropdownWithHeader
              selectedValues={selectedBrands}
              isMulti
              isSearchable
              summaryTextBuilder={brandsSummaryTextBuilder}
              onSelect={setSelectedBrands}
              showSelectAllOptions={!isEmpty(selectedBrandParents)}
              values={brandValues}
              isOpen={isEmpty(selectedBrandParents) ? false : undefined}
              maxNumberOfSelectedOptions={maxNumberOfSelectedBrands}
              reachMaxSelectedOptionsMsg={reachMaxSelectedBrandsMsg}
              onClose={fetchByBrand}
              headerTitle="Brand:"
              headerResetText="Reset"
              onReset={onResetBrands}
            />
          </div>

          {IS_ADVANCED_TV_CHANNEL && (
            <div className="modal-row products">
              <DropdownWithHeader
                selectedValues={selectedProducts}
                isMulti
                isSearchable
                summaryTextBuilder={productsSummaryTextBuilder}
                onSelect={setSelectedProducts}
                showSelectAllOptions
                values={productsValues}
                isOpen={
                  isEmpty(selectedBrands) || (WITH_BRAND_PARENT && isEmpty(selectedBrandParents)) ? false : undefined
                }
                headerTitle="Product:"
                headerResetText="Reset"
                onReset={onResetProducts}
                onClose={fetchByProduct}
              />
            </div>
          )}

          <div className="modal-row creatives">
            <DropdownWithHeader
              selectedValues={selectedCreatives}
              isMulti
              isSearchable
              isDisabled={isEmpty(selectedBrands) && isEmpty(selectedProducts)}
              summaryTextBuilder={creativesSummaryTextBuilder}
              onSelect={setSelectedCreatives}
              showSelectAllOptions
              values={creativeValues}
              isOpen={
                isEmpty(selectedBrands) || (WITH_BRAND_PARENT && isEmpty(selectedBrandParents)) ? false : undefined
              }
              headerTitle="Creative:"
              headerResetText="Reset"
            />
          </div>

          {IS_ADVANCED_TV_CHANNEL && (
            <div className="modal-row keywords">
              <div className="row-label">Keyword Search:</div>
              <Dropdown
                selectedValues={selectedCreatives}
                customSearch={keywordsSearch}
                isMulti
                isSearchable
                summaryTextBuilder={() => 'Search for Comma-separated Keywords / Phrases'}
                onSelect={setSelectedCreatives}
                reachMaxSelectedOptionsMsg="Sorry, this field is limited to 7 keywords"
              />
            </div>
          )}
          {!isEmpty(resultsTable) && (
            <div className="modal-row selected-items">
              <ResultsTable
                resultsTable={resultsTable}
                onReset={resetSelection}
                onRemove={onRemove}
                splitMainTextByDelimeter={' |'}
                isHyperLink={isHyperLink}
                linkBuilder={creativeVideoLinkBuilder}
              />
            </div>
          )}

          {isTimeframeVisible && isSmartTvChannel(channel) && (
            <div className="modal-row">
              <div className="row-label">Timeframe:</div>
              <TvCustomTimeframe
                startDateString={selectedStartDate}
                endDateString={selectedEndDate}
                timeframe={selectedTimeframe}
                selectedStartDateCB={(selectedDate) => setSelectedStartDate(dateToString(selectedDate))}
                selectedEndDateCB={(selectedDate) => setSelectedEndDate(dateToString(selectedDate))}
                selectedTimeframeCB={setSelectedTimeframe}
              />
            </div>
          )}
          {isSmartTvChannel(channel) && (
            <div className="modal-row level-of-exposure">
              <div className="row-label">
                <span className="label">Level of Exposure:</span>
                <ClickableIcon iconId="info-italic" tooltip={LEVEL_OF_EXPOSURE_COMMERCIALS_TOOLTIP} />
              </div>
              <SelectToggle
                values={LEVELS_OF_EXPOSURE}
                selectedValues={levelOfExposure}
                onChange={setLevelOfExposure}
              />
            </div>
          )}
        </div>
      </ModalWithConfirmationButtons>
    </div>
  );
};

CommercialsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  modalTitle: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  commercialsMetadataPromise: PropTypes.object,
  commercialsInput: PropTypes.object,
  channel: PropTypes.string,
  isTimeframeVisible: PropTypes.bool,
  geo: PropTypes.string,
};

CommercialsModal.defaultProps = {
  commercialsInput: {},
  channel: '',
  isTimeframeVisible: true,
  geo: '',
};

export default CommercialsModal;
