import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

const BaseSideMenu = ({ isOpen, children, renderWhenClosed }) => (
  <div className="base-side-menu-component">{isOpen ? children : renderWhenClosed()}</div>
);

BaseSideMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
  renderWhenClosed: PropTypes.func,
};

BaseSideMenu.defaultProps = {
  isOpen: false,
  renderWhenClosed: noop,
};

export default BaseSideMenu;
