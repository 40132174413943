import React, { useCallback, useEffect, useState } from 'react';
import { bool, func, string, object } from 'prop-types';
import { clone, filter, isEmpty } from 'lodash';
import { Typeahead } from '@amobee/component-library';
import Icon from '../../../../../common/Icon/Icon';
import ModalWithConfirmationButtons from '../../../../../common/Modal/ModalWithConfirmationButtons/ModalWithConfirmationButtons';
import './LinkedinIndustriesModal.scss';

const LinkedinIndustriesModal = ({
  isOpen,
  modalTitle,
  onSubmit,
  onCancel,
  industriesInput,
  linkedinMetaDataPromise,
}) => {
  const [industryData, setIndustryData] = useState(industriesInput || {});
  const [allIndustries, setAllIndustries] = useState([]);
  const [shownIndustries, setShownIndustries] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState(industriesInput.industries?.[0] || {});
  const [isInputEmpty, setInputEmpty] = useState(!isEmpty(selectedIndustry));

  const fetchLinkedinMetaData = async () => {
    const res = await linkedinMetaDataPromise;
    setAllIndustries(res.industries);
  };

  useEffect(() => {
    fetchLinkedinMetaData();
  }, [linkedinMetaDataPromise]);

  const onChange = useCallback(
    (selected) => {
      const criteriaData = clone(industryData);
      if (!selected) {
        delete criteriaData.industries;
        setInputEmpty(true);
        setSelectedIndustry({});
      } else {
        criteriaData.industries = [{}];
        criteriaData.industries[0].value = selected.value;
        criteriaData.industries[0].label = selected.label;
        setInputEmpty(false);
        setSelectedIndustry(selected);
      }
      setIndustryData(criteriaData);
    },
    [setSelectedIndustry]
  );

  const onQueryChange = useCallback(
    (query) => {
      setInputEmpty(!query);
      const queryString = query.toLowerCase();
      const shownItems = filter(allIndustries, (industry) => industry.label.toLowerCase().includes(queryString));
      setShownIndustries(shownItems);
    },
    [setShownIndustries, allIndustries]
  );

  const handleSubmit = useCallback(() => {
    const segment = {
      ...industryData,
      type: 'linkedinIndustries',
    };
    onSubmit(segment);
  }, [industryData, setIndustryData]);

  return (
    <div className="linkedin-industries-modal-component">
      <ModalWithConfirmationButtons
        width="800px"
        modalTitle={modalTitle}
        isOpen={isOpen}
        isSubmitDisabled={isEmpty(selectedIndustry)}
        isDisabledEnterKeyPress={true}
        onSubmit={handleSubmit}
        onCancel={onCancel}
        onXClick={onCancel}
        isLightTheme={true}
        hasScrollContainer={false}
        containerPadding="24px 19px 17px"
      >
        <div className="linkedin-industries-modal-content">
          <div className="row">
            <div className="row-title">Members of:</div>
            {isInputEmpty && <Icon iconId="search" className="search-icon" />}
            {allIndustries.length && (
              <Typeahead
                results={shownIndustries}
                selected={selectedIndustry}
                rightIconAccessor="Remove"
                onQueryChange={onQueryChange}
                onSelectionChange={onChange}
                placeholder="Add industry"
                noResultsText="Industry not found"
                className={isInputEmpty && 'typeahead-hide'}
              />
            )}
          </div>
        </div>
      </ModalWithConfirmationButtons>
    </div>
  );
};

LinkedinIndustriesModal.propTypes = {
  isOpen: bool.isRequired,
  modalTitle: string,
  onSubmit: func.isRequired,
  onCancel: func.isRequired,
  industriesInput: object,
  linkedinMetaDataPromise: object,
};

LinkedinIndustriesModal.defaultProps = {
  industriesInput: {},
};

export default LinkedinIndustriesModal;
