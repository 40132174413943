import { clone, extend, map, isEqual } from 'lodash';
import { getContext, getStateCurrent } from '../../middleware/AngularExportedMiddleware';
import * as MixpanelCommon from './MixpanelCommon';

const appName = 'Interests';

let context = getContext();
let snapshot = context ? context.current : {};
let widgetName = MixpanelCommon.extractWidget(getStateCurrent());

export const extractTimeFrame = () => MixpanelCommon.buildTimeframe(snapshot.interests_timeframe || []);

export const interests = () => {
  const allTerms = MixpanelCommon.buildTerms(snapshot.terms);
  context = getContext();
  snapshot = context ? context.current : {};
  const result = {
    appName,
    widget: MixpanelCommon.extractWidget(getStateCurrent()),
    terms: allTerms.terms.concat(allTerms.bl_terms),
    refinedTerms: allTerms.bl_terms.length > 0,
    timeframe: extractTimeFrame(),
    topics: map(snapshot.topics, 'name'),
    geo: map(snapshot.geo, 'label'),
    insightsChannels: snapshot.insightsChannels ? snapshot.insightsChannels.label : '',
    audience: map(snapshot.audience, 'summary'),
    language: snapshot.language ? snapshot.language.value : 'en',
  };
  extend(result, MixpanelCommon.buildProgram(context));
  switch (widgetName) {
    case 'streams':
      result.articleType = map(snapshot.articleType, 'label');
      result.streamsChannels = map(snapshot.channels, 'label');
      result.channel = result.streamsChannels;
      break;
    case 'bubbles':
      result.bubblesChannels = snapshot.bubblesChannels ? snapshot.bubblesChannels.label : '';
      result.keyTrends = clone(snapshot.keyTrends);
      result.channel = result.bubblesChannels;
      break;
    default:
      result.gridChannels = snapshot.bubblesChannels ? snapshot.bubblesChannels.label : '';
      result.channel = result.gridChannels;
  }
  return result;
};

let lastSearch = clone(interests());
let lastTerms = lastSearch.terms;

export const trackPageView = (widget) => {
  if (widget === 'grid') {
    widget = MixpanelCommon.extractWidget(getStateCurrent());
  }
  const props = clone(interests());
  MixpanelCommon.trackPageView(appName, widget, props.channel);
  widgetName = widget;
};

export const trackExport = () => {
  const props = clone(interests());
  MixpanelCommon.trackExport(appName, widgetName, props.channel);
};

export const trackSort = (column) => {
  const props = {
    widget: widgetName,
    appName: appName,
    'column title': column.title,
  };
  MixpanelCommon.trackSort(props);
};

export const trackInterestsSearch = () => {
  const props = clone(interests());

  if (isEqual(props, lastSearch)) return;

  lastSearch = clone(props);
  props.fullSearch = clone(interests());
  MixpanelCommon.sendToMixpanel('Overview - Insights Generation', props);
  MixpanelCommon.sendToMixpanel('Interests - Search', props);

  if (isEqual(props.terms, lastTerms)) return;

  MixpanelCommon.trackSearch(appName, widgetName, props.terms, props.refinedTerms, props.channel);
  lastTerms = props.terms;
};

export const trackInterestsSearchChange = () => {
  const props = clone(interests());
  props.fullSearch = clone(interests());
  MixpanelCommon.sendToMixpanel('Overview - Insights Generation', props);
  MixpanelCommon.sendToMixpanel('Interests - Search', props);
  lastTerms = props.terms;
};

export const trackKeyTrendsToggle = () => {
  const props = clone(interests());
  props.fullSearch = clone(interests());
  MixpanelCommon.sendToMixpanel('Overview - Insights Generation', props);
};

export const trackBubbleClicked = (bubble) => {
  const props = {
    appName,
    widget: widgetName,
    selectedBubble: bubble,
    search: interests(),
  };
  MixpanelCommon.sendToMixpanel('Interests - Bubbles - Bubble Clicked', props);
};

export const trackStream = (streamUrl) => {
  const props = {
    appName,
    widget: 'content board',
    selectedStream: streamUrl,
    search: interests(),
  };
  MixpanelCommon.sendToMixpanel('Interests - Streams - Stream Clicked', props);
};

export const trackContentDriveClick = (row) => {
  const props = {
    widget: widgetName,
    appName: appName,
    Phrase: row.phrase,
  };
  MixpanelCommon.sendToMixpanel('Interests - Phrase table - Content Driver button', props);
};

export const trackAudience = (val) => {
  if (!val) return;
  const segment = Array.isArray(val) ? val[0] : val;
  if (!segment) return;

  const props = {
    widget: widgetName,
    appName: appName,
    'Audience Name': segment.label,
  };
  MixpanelCommon.sendToMixpanel('Interests - Channel Insights - Audience Skew', props);
};

export const trackAudienceBubblesSkew = (val) => {
  if (!val) return;
  const segment = Array.isArray(val) ? val[0] : val;
  if (!segment) return;

  const props = {
    widget: widgetName,
    appName: appName,
    'Audience Name': segment.label,
  };
  MixpanelCommon.sendToMixpanel('Interests - Bubbles - Audience Skew', props);
};
