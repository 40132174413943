"use strict";
import config from 'infra/config';

module.exports = angular.module(__filename, [])
    .service('keywords', ["$http", "$q", function ($http, $q) {

        var SERVER_URL = config.USER_MGMT_API + '/phrases_id';
        var SERVER_URL_SEARCH = config.USER_MGMT_API + '/phrase/search';
        var SERVER_URL_SAVE = config.USER_MGMT_API + '/phrase_edit';
        var GET_PHRASES_BY_IDS_URL = config.USER_MGMT_API + '/phrases';

        return {
            validation: validation,
            noValidation: noValidation,
            search: search,
            save: save,
            get_kwds_by_ids: get_kwds_by_ids
        };

        function get_kwds_by_ids(ids, min_kl) {
            return $http.post(GET_PHRASES_BY_IDS_URL, {'ids': ids, 'min_kl': min_kl}).then(function (res) {
                return res.data;
            });
        }

        function validation(keywords, language) {
            return $http.post(SERVER_URL, {
                'phrases': keywords,
                'language': language
            }).then(function (res) {
                return res.data
            });
        }

        function noValidation(keywords) {  
            return $q.when({}).then(() =>{
                let obj = {};
                keywords.forEach((word)=>{
                    obj[word] = {disp: word, id: -1};
                });
                return obj;
            });
        }

        function search(keywords) {
            if (keywords == "") {
                return $q.when([]);
            }
            return $http.get(SERVER_URL_SEARCH, {
                params: {
                    keywords: keywords
                }
            }).then(function (res) {
                return res.data;
            });
        }

        function save(phraseObject) {
            return $http.put(SERVER_URL_SAVE, phraseObject).then(function (res) {
                return angular.copy(res.data, phraseObject);
            });
        }
    }]
);
