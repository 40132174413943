module.exports = angular.module(__filename, []).directive('removedColumn', function () {
    return {
        restrict: 'A',
        require: '^amTable',
        template: require('./removed-column.html'),
        scope: {
            rowObj: '=removedColumn'
        },
        link: function (scope, element, attr, amTableCtrl) {
            scope.amTableCtrl = amTableCtrl;
        }
    }
});