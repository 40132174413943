import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { compact, each, isEmpty, join, map } from 'lodash';
import { Icon } from '@amobee/component-library';
import { getUpdatedTree } from '../../../utils/MultiLevelTableUtils';
import './MultiLevelResultsTable.scss';

const MultiLevelResultsTable = ({ levelNames, tableData, onClear, disabled, withoutHeader }) => {
  const [tableDataValue, setTableDataValue] = useState(tableData);
  const [selectDataValue, setSelectDataValue] = useState({});
  const shouldDisplayLevelName = levelNames.length > 1;

  function getDescendantsDescription(node, level) {
    function getNumDescendantsByLevelNameRecursive(node, currentLevel) {
      if (isEmpty(node.children)) {
        return {};
      }
      const childLevelName = levelNames[currentLevel + 1];
      let res = { [childLevelName]: node.children.length };
      const childLevelResults = node.children.map((child) =>
        getNumDescendantsByLevelNameRecursive(child, currentLevel + 1)
      );
      // merge results of the descendants of the child level
      childLevelResults.forEach((childLevelResult) => {
        each(
          childLevelResult,
          (numDescendants, levelName) => (res[levelName] = (res[levelName] || 0) + numDescendants)
        );
      });
      return res;
    }

    const numDescendantsByLevelName = getNumDescendantsByLevelNameRecursive(node, level);
    return map(numDescendantsByLevelName, (num, levelName) => `${num} ${levelName}`).join(' | ');
  }

  const populateTableElements = (items) => {
    const elements = levelNames.reduce((elementsObj, name) => {
      elementsObj[name] = [];
      return elementsObj;
    }, {});
    function populateRecursionTableElements(dataArray, level = 0, parentLabel = null) {
      dataArray.map((data) => {
        if (data.selected) {
          const descendantsDescription = getDescendantsDescription(data, level);
          const secondaryText = join(compact([parentLabel, descendantsDescription]), ' | ');
          elements[levelNames[level]] = [...elements[levelNames[level]], { ...data, secondaryText }];
        } else if (!isEmpty(data.children)) {
          const nextLevelParentLabel = (parentLabel ? `${parentLabel} > ` : '') + data.label;
          populateRecursionTableElements(data.children, level + 1, nextLevelParentLabel);
        }
      });
    }

    populateRecursionTableElements(items);
    return elements;
  };

  const [tableElements, setTableElements] = useState({});

  const onRemove = useCallback((item) => setSelectDataValue({ ...item, selected: false }), []);

  const onRemoveAll = (items) => {
    const updatedTree = getUpdatedTree(items, false);
    setTableDataValue(updatedTree);
    onClear(updatedTree);
  };

  useEffect(() => {
    if (!isEmpty(selectDataValue)) {
      const updatedData = getUpdatedTree(tableDataValue, false, selectDataValue);
      setTableDataValue(updatedData);
      onClear(updatedData, selectDataValue);
    }
  }, [selectDataValue]);

  useEffect(() => {
    setTableDataValue(tableData);
  }, [tableData]);

  useEffect(() => {
    setTableElements(populateTableElements(tableData));
  }, [tableDataValue]);

  return (
    <div className={classNames('multi-level-results-component', { disabled })}>
      {!withoutHeader && (
        <div className="multi-level-results-header">
          <span className="multi-level-results-title">Selected</span>
          <span
            className="multi-level-results-action"
            aria-disabled={!tableDataValue.length}
            onClick={() => onRemoveAll(tableDataValue)}
          >
            Reset
          </span>
        </div>
      )}
      <div className="multi-level-results">
        {levelNames.map(
          (levelName, index) =>
            !!tableElements[levelName]?.length && (
              <div key={levelName} className="mlr-result">
                {shouldDisplayLevelName && <div className="mlr-result-header">{levelName}</div>}
                {tableElements[levelName].map((item) => (
                  <div
                    key={item.value + item.label}
                    className={classNames('mlr-result-child', { 'mlr-small-result-child': !shouldDisplayLevelName })}
                  >
                    <div className="mlr-result-child-title">{item.label}</div>
                    {shouldDisplayLevelName && <div className="mlr-result-child-body">{item.secondaryText}</div>}
                    <div className="mlr-result-child-action" onClick={() => onRemove(item)}>
                      <Icon name="Remove" />
                    </div>
                  </div>
                ))}
              </div>
            )
        )}
      </div>
    </div>
  );
};

MultiLevelResultsTable.propTypes = {
  levelNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  tableData: PropTypes.array.isRequired,
  onClear: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  withoutHeader: PropTypes.bool,
};

MultiLevelResultsTable.defaultProps = {
  levelNames: [],
  tableData: [],
  disabled: false,
  withoutHeader: false,
};

export default MultiLevelResultsTable;
