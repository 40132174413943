import * as MixpanelInsights from '../../react/infra/mixpanel/MixpanelInsights';

const BaseWidget = require("../base_widget"),
      c = require("infra/utils/common"),
      ex = require("infra/utils/export");

const DEFAULT_TICK_RANGE = [0, 100];
const DEFAULT_VALUE_MEASURE = 'relative';
const DEFAULT_VIEW_TYPE = 'normal';
const CHANNEL_SOCIAL = {'label': 'Social', 'value': 'facebook', permission: ['facebook channel']};
const CHANNEL_ALL = {'label': 'All', 'value': 'articles', permission: ['web channel']};
const CHANNEL_SG_TELCO = {'label': 'Web - SG Telco', 'value': 'sg_telco', permission: ['sg telco channel']};
const CUSTOM_DROPDOWN_OPTIONS = [
    {label: "Consumption", id: "consumption", metric: "consumption", channels: [CHANNEL_ALL, CHANNEL_SG_TELCO]},
    {label: "Net Sentiment", id: "net_sentiment", metric: "net_sentiment", channels: [CHANNEL_SOCIAL]},
    {label: "Positive Sentiment", id: "positive_sentiment", metric: "positive_sentiment", channels: [CHANNEL_SOCIAL]},
    {label: "Negative Sentiment", id: "negative_sentiment", metric: "negative_sentiment", channels: [CHANNEL_SOCIAL]}
];
const MAIN_DROPDOWN_OPTIONS = [
    {label: "Social Engagements", id: "social_engagements", metric: "consumption", channels: [CHANNEL_SOCIAL]},
    {label: "Association Index", id: "association_index", metric: "consumption", measure: "index", channels: [CHANNEL_ALL, CHANNEL_SG_TELCO]},
    {label: "Association Skew", id: "association_skew", metric: "consumption", measure: "skew", channels: [CHANNEL_ALL, CHANNEL_SG_TELCO]}
];
const DEFAULT_DROPDOWN_OPTIONS = _.sortBy(_.concat(MAIN_DROPDOWN_OPTIONS, CUSTOM_DROPDOWN_OPTIONS), 'label');
const SG_WEB_SOURCES_PERMISSIONS = ['web channel', 'sg telco channel']; //TODO - is this needed?

function LandscapeWidgetController($q, $scope, $rootScope, $timeout, $element, util, consumptionTrendService,
                                   associationTrendService, sentimentTrendService, examplesDataModel,
                                   insightsExportService, notificator, modalService, topicsTree,
                                   geoService, TIMES, $location, abiPermissions, $state) {
    let self = this;
    this.util = util;
    this.TIMES = TIMES;
    this.$location = $location;
    this.notificator = notificator;
    this.consumptionService = consumptionTrendService;
    this.associationService = associationTrendService;
    this.sentimentService = sentimentTrendService;
    this.examplesDataModel = examplesDataModel;
    this.insightsExportService = insightsExportService;
    this.originalChartTicksRange = {x: DEFAULT_TICK_RANGE, y: DEFAULT_TICK_RANGE};
    this.unnormalizedMaxes = {x: 0, y: 0};
    $scope.chartTicksRange = {x: DEFAULT_TICK_RANGE, y: DEFAULT_TICK_RANGE};
    $scope.context = $rootScope.context;
    $scope.showChart = false;
    $scope.axisData = {x: [], y: []};
    $scope.tickRangeLabels = {x: [], y: []};
    $scope.chartData = [];
    $scope.custom_dropdown_options = [];
    $scope.sgWebSource = $scope.sgWebSource || $rootScope.sgWebSourceFilter[0];
    MixpanelInsights.trackPageView('landscape');

    function getSentimentSummaryFunction(field) {
      return (rec) => rec.value === 0 ? 0 : rec[field] / rec.value;
    }

    const SUMMARY_FUNCTIONS_BY_OPTION_ID = {
        "positive_sentiment" : getSentimentSummaryFunction('positive'),
        "negative_sentiment" : getSentimentSummaryFunction('negative')
    };

    this.initSelectedAxes = function() {
        if ($scope.context.current.landscape === undefined) {
            $scope.selectedAxes = {x: undefined, y: undefined};
        } else {
            $scope.selectedAxes = {
                x: $scope.context.current.landscape.x_axis,
                y: $scope.context.current.landscape.y_axis
            };
        }
    };

    this.initLandscapeContext = function() {
        $scope.context.current.landscape = $scope.context.current.landscape || {};
        $scope.context.current.landscape.dropdown_extra = $scope.context.current.landscape.dropdown_extra ||
                                                            {x: {type: null, value: []}, y: {type: null, value: []}};
        $scope.context.current.landscape.previous_dropdown_extra =
          $scope.context.current.landscape.previous_dropdown_extra ||
            angular.copy($scope.context.current.landscape.dropdown_extra);
        this.default_dropdown_options = _.map(DEFAULT_DROPDOWN_OPTIONS, (opt) => {
            let new_opt = _.clone(opt);
            _.remove(new_opt.channels, (chan) => !abiPermissions.hasPermission(chan.permission) ||
                                                 (chan.label === 'Social' && !abiPermissions.hasPermission('facebook channel')));
            return new_opt;
        });
        _.remove(this.default_dropdown_options, (opt) => _.isEmpty(opt.channels));
        $scope.dropdown_options = _.concat(this.default_dropdown_options, $scope.custom_dropdown_options);
        $scope.dropdown_extra = $scope.context.current.landscape.dropdown_extra;
        _.each(['x', 'y'], (axis) => {
            if (_.isEmpty(self.getSelectedAxisObject(axis).channels)) {
              $scope.selectedAxes[axis] = undefined;
              $scope.dropdown_extra[axis] = {type: null, value: []};
            }
        });
    };

    this.setGlobalParams = function() {
        const xChannel = ((self.getSelectedAxisObject('x').channels || [])[0] || {}).value;
        const yChannel = ((self.getSelectedAxisObject('y').channels || [])[0] || {}).value;
        $scope.insightsChannel = ((xChannel && xChannel === 'articles') || (yChannel && yChannel !== 'articles')) ? CHANNEL_ALL : CHANNEL_SG_TELCO;
        $scope.$emit('landscapeChannelChanged', $scope.insightsChannel);
        self.parameters = self.util.buildInsightsParameters($scope, false);
        self.parameters.no_examples = true;

        self.export_parameters = _.clone(self.parameters);

        c.logUpdate('Landscape', self.parameters);
        const nonPhrases = c.validateNonPhrases($scope.terms, $scope.insightsChannel.value, self.notificator);
        $scope.chartTerms = nonPhrases ? _.filter($scope.terms, (t) => ['mention', 'hashTag', 'post'].indexOf(t.type) === -1) : $scope.terms;
    };

    this.updateChartData = function () {
        self.setGlobalParams();
        const xPromise = self.fetchAxisData('x');
        const yPromise = self.fetchAxisData('y');
        if ($scope.selectionPromise) {
          $scope.selectionPromise.cancel();
        }
        $scope.selectionPromise = $q.all([xPromise, yPromise]);
        $scope.selectionPromise.cancel = function() {
          if (xPromise && xPromise.cancel) xPromise.cancel();
          if (yPromise && yPromise.cancel) yPromise.cancel();
        };
        $scope.selectionPromise.then(function(values) {
            $scope.xAxisEdit = self.getSelectedAxisObject('x').custom;
            $scope.yAxisEdit = self.getSelectedAxisObject('y').custom;
            $scope.xAxisLabel = self.getLabelForAxis('x');
            $scope.yAxisLabel = self.getLabelForAxis('y');
            self.setChartTicksRange();
            self.setChartData();
            self.insightsExportService.clear();
            self.insightsExportService.setParams(self.export_parameters);
            self.insightsExportService.setReportFunctions([]);
            self.insightsExportService.addReportFunction(self.getLandscapeExportReport);
        });
    };

    this.getOptionBySelectedAxis = function(axis) {
        return _.find($scope.dropdown_options, ['id', $scope.selectedAxes[axis]]);
    };

    this.getSelectedAxisObject = function(axis) {
        if ($scope.selectedAxes[axis] && !_.isEmpty($scope.dropdown_options)) {
            return (self.getOptionBySelectedAxis(axis) || {});
        }
        return {};
    };

    this.fetchDataByMetric = function(selectedAxis, axis, associationInterest, measure, params) {
        let promise;

        if (selectedAxis.metric === 'consumption' && !_.isEmpty(measure) && !_.isEmpty(associationInterest)) {
            $scope.showChart = true;
            promise = self.loadAssociationData(axis, associationInterest, measure, params);
        } else if (selectedAxis.metric === 'consumption') {
            $scope.showChart = true;
            if (!self.isAssociationOption(selectedAxis.id)) {
                promise = self.loadConsumptionData(axis, params);
            } else {
                self.clearAxisData(axis);
            }
        } else if ((selectedAxis.metric || '').endsWith('_sentiment')) {
            $scope.showChart = true;
            promise = self.loadSentimentData(axis, params, SUMMARY_FUNCTIONS_BY_OPTION_ID[selectedAxis.metric] || selectedAxis.metric);
        } else {
            self.clearAxisData(axis);
        }

        return promise;
    };

    this.customGlobalFilterIntersection = function(globalFilters, customFilters) {
        const combinedFilters = _.reject([globalFilters, customFilters], _.isEmpty);
        const intersection = _.intersectionBy(globalFilters, customFilters, 'id');

        if (combinedFilters.length > 1 && !_.isEmpty(intersection)) {
            return intersection;
        }
        else if (combinedFilters.length <= 1) {
            return _.flatten(combinedFilters);
        }

        return null;
    };

    this.mapArrayToText = function(array, kwd_ids) {
        return _.map(array, function(trend) {
            if (trend.type === 'term') kwd_ids[trend.text.toLowerCase()] = trend.id;
            return trend.text.toLowerCase();
        });
    };

    this.intersectAssociationWithTrendsParams = function(params, association) {
        let assocRequired = [], assocIncluded =[], assocExcluded = [];

        if (association.type === 'booleanLogic' || association.type == 'programBL') {
            assocRequired = self.mapArrayToText(association.required, params.kwd_ids);
            assocIncluded = self.mapArrayToText(association.included, params.kwd_ids);
            assocExcluded = self.mapArrayToText(association.excluded, params.kwd_ids);
        } else {
            assocRequired = self.mapArrayToText([association], params.kwd_ids);
        }

        //add assoc to bls
        _.each(params.boolean_logics, function (bl) {
            bl.required.push(...assocRequired);
            bl.included.push(...assocIncluded);
            bl.excluded.push(...assocExcluded);
        });

        //add assoc to terms and make them bl
        _.each(params.terms, function(term) {
            term.id = util.common.generateUUID();
            term.type = "booleanLogic";
            term.display = util.getTermDisplay(term);
            term.required = [term.text.toLowerCase()].concat(assocRequired);
            term.included = assocIncluded;
            term.excluded = assocExcluded;
            params.boolean_logics.push(term);
        });

        params.terms = [];
        params.trends = params.boolean_logics;
    };

    this.shouldSendSgWebSource = function() {
        return abiPermissions.hasPermission(SG_WEB_SOURCES_PERMISSIONS) &&
               (_.some($scope.geo, {cc: "SG"}) ||
                 (_.isEmpty($scope.geo) &&
                   _.some(geoService.geosForChannel(geoService.geos, $state, $scope.context), {cc: "SG"})));
    };

    this.fetchAxisData = function(axis) {
        const selectedAxis = self.getSelectedAxisObject(axis);
        let associationInterest;
        const measure = selectedAxis.measure;
        self.parameters.channel = ((selectedAxis.channels || [])[0] || {}).value;
        self.parameters.to_normalize = self.parameters.channel !== 'facebook';
        if (this.shouldSendSgWebSource()) {
            self.parameters.sg_web_source = $scope.sgWebSource.value;
        }
        delete self.parameters.audience;
        let params = self.parameters;
        $scope.tickRangeLabels[axis] = (selectedAxis.metric === 'net_sentiment') ? ['Negative', 'Positive'] : [];

        if (selectedAxis.custom) {
            params = angular.copy(self.parameters);
            associationInterest = selectedAxis.associationInterest;
            if (!_.isEmpty(selectedAxis.metric) && selectedAxis.metric !== 'consumption' && !_.isEmpty(associationInterest)) {
                self.intersectAssociationWithTrendsParams(params, associationInterest[0]);
            }

            const geoFilters = self.customGlobalFilterIntersection($scope.geo, selectedAxis.geo);
            const topicFilters = self.customGlobalFilterIntersection($scope.topics, selectedAxis.topics);

            if (!geoFilters || !topicFilters) { //if no intersection data needs to be 0
                self.notificator.notify({body: "Warning: data from '" + selectedAxis.label + "' will be narrowed by the filter change"});
                $scope.showChart = true;

                if (_.isEmpty($scope.chartTerms)) {
                    self.clearAxisData(axis);
                    return $q.defer().reject("No Terms");
                } else {
                    const data = _.map($scope.chartTerms, (term) => ({id: term.id, class: term.class, label: term.text, value: 0}));
                    return $q.defer().resolve(self.setDataForAxis(axis, data));
                }
            } else {
                params.geo = geoFilters;
                params.topics = topicFilters;
                if (_.isArray(selectedAxis.sub_geos)) {
                    params.sub_geos = geoService.createRequestSubGeoParam(selectedAxis.sub_geos);
                }
            }
        } else {
            associationInterest = $scope.dropdown_extra[axis].value;
        }

        return self.fetchDataByMetric(selectedAxis, axis, associationInterest, measure, params);
    };

    this.loadConsumptionData = function(axis, params) {
        let consumptionPromise = null;
        if (!_.isEmpty(params)) {
            consumptionPromise = self.consumptionService.get(params, null, true);
            const property = params.channel === 'facebook' ? 'sums' : 'averages';
            let p = consumptionPromise.then(function (data) {
                self.unnormalizedMaxes[axis] = data.maxUnnormalized;
                self.setDataForAxis(axis, data[property]);
            }, function (error) {
                self.clearAxisData(axis);
            });
            p.cancel = consumptionPromise.cancel;
            return p;
        }

        return consumptionPromise;
    };

    this.loadAssociationData = function(axis, associationInterest, measure, params) {
        let associationPromise = null;
        if (!_.isEmpty(params)) {
            associationPromise = self.associationService.getUserAssociationsByReference(DEFAULT_VIEW_TYPE, DEFAULT_VALUE_MEASURE,
                associationInterest, params, 1);
            let promise = associationPromise.then(function (response) {
                if (!_.isEmpty(response.userData)) {
                    self.unnormalizedMaxes[axis] = response.max;
                    const associationData = _.map(response.userData[0].values, function (entry) {
                        if (measure === 'index' && _.has(entry, 'abs_value')) {
                            entry['value'] = entry['abs_value'];
                        } else if (measure === 'skew' && _.has(entry, 'skew')) {
                            entry['value'] = entry['skew'];
                        }
                        return _.omit(entry, ['abs_value', 'abs_value_original', 'value_original', 'skew', 'share']);
                    });
                    self.setDataForAxis(axis, associationData);
                } else {
                    c.validateNonPhrases(associationInterest, params.channel, self.notificator);
                    self.clearDropdownExtra(axis);
                }
            }, function (error) {
                self.clearAxisData(axis);
            });
            promise.cancel = associationPromise.cancel;
            return promise;
        }

        return associationPromise;
    };

    this.loadSentimentData = function(axis, params, summary_value) {
        let sentimentPromise = null;
        if (!_.isEmpty(params)) {
            sentimentPromise = self.sentimentService.getSentiments(params, true);
            let p = sentimentPromise.then(function (data) {
                self.setDataForAxis(axis, data.averages, summary_value);
            }, function (error) {
                self.clearAxisData(axis);
            });
            p.cancel = sentimentPromise.cancel;
            return p;
        }

        return sentimentPromise;
    };

    this.getTicksRangeByMinMax = function(values, summary_function, isAbs) {
        const maxTick = summary_function(_.maxBy(values, summary_function));
        const minTick = summary_function(_.minBy(values, summary_function));
        if (minTick === 0 && maxTick === 0) {
            return DEFAULT_TICK_RANGE;
        }
        if (isAbs) {
            const maxAbsValue = Math.max(Math.abs(maxTick),Math.abs(minTick));
            return [-maxAbsValue, maxAbsValue];
        }
        return [minTick, maxTick];
    };

    this.setDataForAxis = function(axis, data, summaryValue) {
        const axisProperty = axis + '_value';
        let newTicksRange = this.originalChartTicksRange;

        summaryValue = summaryValue || 'value';
        const summaryFunction = typeof summaryValue == 'function' ? summaryValue : function(entry){
            return _.isObject(entry) ? entry[summaryValue] : undefined;
        };

        newTicksRange[axis] = self.getTicksRangeByMinMax(data, summaryFunction, summaryValue === 'net_sentiment');
        _.each(data, (entry) => entry[axisProperty] = summaryFunction(entry));

        this.originalChartTicksRange = angular.copy(newTicksRange);
        $scope.axisData[axis] = angular.copy(data);
    };

    this.isDefaultRange = (range) => range[0] === 0 && range[1] === 100;

    this.setChartTicksRange = function() {
        const xObject = this.getSelectedAxisObject('x');
        const yObject = this.getSelectedAxisObject('y');
        const SENTIMENT_CONSUMPTION_METRICS = ['positive_sentiment', 'negative_sentiment'];
        if (((xObject.channels || [])[0] || {}).value === ((yObject.channels || [])[0] || {}).value &&
            xObject.measure === yObject.measure &&
            (xObject.metric === yObject.metric ||
             (SENTIMENT_CONSUMPTION_METRICS.indexOf(xObject.metric) !== -1) &&
              SENTIMENT_CONSUMPTION_METRICS.indexOf(yObject.metric) !== -1) &&
             !this.isDefaultRange(self.originalChartTicksRange['x']) &&
             !this.isDefaultRange(self.originalChartTicksRange['y'])) {
            if (((xObject.channels || [])[0] || {}).value !== 'facebook' && (xObject.measure === 'index' || xObject.measure === undefined)){
                const unifiedMaxUnnormalized = Math.max(self.unnormalizedMaxes['x'], self.unnormalizedMaxes['y']);
                _.each(['x','y'], function(axis) {
                   if (self.unnormalizedMaxes[axis] !== unifiedMaxUnnormalized) {
                       self.setDataForAxis(axis, $scope.axisData[axis], function(entry){
                           return self.unnormalizedMaxes === 0 ?
                               entry[axis + '_value'] :
                               entry[axis + '_value'] * self.unnormalizedMaxes[axis] / unifiedMaxUnnormalized;
                       });
                   }
                });
            }
            const unifiedRange = [Math.min(self.originalChartTicksRange['x'][0], self.originalChartTicksRange['y'][0]),
                                  Math.max(self.originalChartTicksRange['x'][1], self.originalChartTicksRange['y'][1])];
            $scope.chartTicksRange = {'x': unifiedRange, 'y': unifiedRange};
        } else {
            $scope.chartTicksRange = angular.copy(self.originalChartTicksRange);
        }
    };

    this.clearAxisData = function(axis) {
        $scope.chartData = [];
        $scope.chartTicksRange[axis] = DEFAULT_TICK_RANGE;
        this.originalChartTicksRange[axis] = DEFAULT_TICK_RANGE;
        this.unnormalizedMaxes[axis] = 0;
        $scope.axisData[axis] = [];
        if (!$scope.selectedAxes.x && !$scope.selectedAxes.y) {
            $scope.showChart = false;
        }
    };

    this.setChartData = function() {
        $scope.axisData.x = _.sortBy($scope.axisData.x, 'class');
        $scope.axisData.y = _.sortBy($scope.axisData.y, 'class');
        const mergedAxesData = _.zip($scope.axisData.x, $scope.axisData.y);
        $scope.chartData = _.map(mergedAxesData, function (termData) {
            let extendedTermData = _.extend(termData[0], termData[1]);
            extendedTermData.label = self.util.getTermDisplay({text: extendedTermData.label});
            return _.pick(extendedTermData, ['id', 'label', 'class', 'x_value', 'y_value']);
        });
        self.setMissingChartDataValues();
    };

    this.setMissingChartDataValues = function() {
        _.each($scope.chartData, function (entry) {
            //add default x y values if they don't exist
            if (!entry.hasOwnProperty('y_value')) {
                entry.y_value = $scope.chartTicksRange.y[1] / 2;
            }
            if (!entry.hasOwnProperty('x_value')) {
                entry.x_value = $scope.chartTicksRange.x[1] / 2;
            }
        });
    };

    this.getLabelForAxis = function(axis, formatForExport) {
        formatForExport = formatForExport || false;
        let label = [];
        const selected = self.getSelectedAxisObject(axis);
        const associationInterest = selected.custom ? selected.associationInterest : $scope.dropdown_extra[axis].value;

        if (!_.isEmpty(selected.label)) {
            if (selected.label.startsWith('Association') && !_.isEmpty(associationInterest)) {
                const assocInputText = associationInterest[0].text;
                if (formatForExport) {
                    label.push(_.join([selected.label, assocInputText], ' with '));
                }
                else {
                    label.push('Association with - ');
                    label.push(assocInputText);
                }
            } else if (!selected.label.startsWith('Association')) {
                label.push(selected.label);
            }
        }

        return label;
    };

    this.capitalizeText = function(label) {
        if (!_.isEmpty(label)) {
            return label.replace(/_/g, ' ').replace(/(^|\s)[a-z]/g, toUpperCase);
        }
    };

    this.clearDropdownExtra = function(axis) {
        $scope.dropdown_extra[axis]["value"] = [];
    };

    this.selectDropdownExtra = function(axis) {
        self.initLandscapeContext();
        $scope.dropdown_extra[axis]["type"] = $scope.selectedAxes[axis];
    };

    this.isAssociationOption = (option) => {
        return _.includes(['association_index', 'association_skew'], option);
    };

    this.focusOnInputBar = function(axis) {
      if (self.isAssociationOption($scope.selectedAxes[axis])) {
        const inputBar = $element.find(`input-bar[name=${axis}Association]`);
        angular.element(inputBar).controller('inputBar').focus();
      }
    };

    this.getSummaryReport = function (format, parameters, date) {
        const fields = _.reject(ex.DEFAULT_SUMMARY_PARAMETERS, ['key', 'channel']);
        _.find(fields, ['key', 'terms']).pkey = 'display';
        let summary =  self.insightsExportService.getSummaryDefault(format, _.omit(parameters,['sub_geos']), date, fields);
        const customAxes = _.filter([self.getSelectedAxisObject('x'), self.getSelectedAxisObject('y')], ['custom', true]);
        _.each(customAxes, function(axisData, i) {
            summary.table.push([]);
            summary.table.push([format('Custom axis ' + (i+1) + '', 'bold')]);
            summary.table.push([format('Name:', 'bold'), axisData.label]);
            summary.table.push([format('Metric:', 'bold'), self.capitalizeText(axisData.metric)]);
            const axisFields = _.filter(ex.DEFAULT_SUMMARY_PARAMETERS, (field) =>_.includes(['channel','topics','geo'], field.key));
            const axisFieldsData = _.pick(axisData, ['topics', 'geo', 'sub_geos']);
            axisFieldsData.channel = ((axisData.channels || [])[0] || {}).value;
            if (topicsTree.isAllTopics(axisFieldsData.topics)) axisFieldsData.topics = [];
            let axisSummary =  self.insightsExportService.getSummaryDefault(format, axisFieldsData, date, axisFields);
            axisSummary.table.shift();
            summary.table = summary.table.concat(axisSummary.table);
        });
        return summary;
    };

    this.getLandscapeExportReport = function (format) {
        const xTitle = _.first(self.getLabelForAxis('x', true));
        const yTitle = _.first(self.getLabelForAxis('y', true));
        const titles = _.map(_.reject(['Seed', xTitle, yTitle], _.isEmpty), _.partial(format, _, 'bold'));
        const columns = [{width: 20}].concat(Array(titles.length - 1).fill({width: 40}));
        let table = [titles];
        _.each($scope.chartData, function(seedData) {
            let seedRecord = [seedData.label];
            if (!_.isEmpty(xTitle)) {
                seedRecord.push(format(seedData.x_value, self.getExportFormatForAxis('x')));
            }
            if (!_.isEmpty(yTitle)) {
                seedRecord.push(format(seedData.y_value, self.getExportFormatForAxis('y')));
            }
            table.push(seedRecord);
        });
        return {name: 'Landscape', columns: columns, table: table};
    };

    this.getExportFormatForAxis = function(axis) {
        const selectedAxis = self.getSelectedAxisObject(axis);
        let exportFormat = 'numeric';

        if (selectedAxis.metric === 'consumption' && ((selectedAxis.channels || [])[0] || {}).value === 'facebook') {
            exportFormat = 'integer';
        } else if (selectedAxis.metric === 'positive_sentiment' || selectedAxis.metric === 'negative_sentiment') {
            exportFormat = 'percent';
        }

        return exportFormat;
    };

    this.addCustomAxisToDropdown = function(id, axis) {
        $scope.dropdown_options = _.concat(DEFAULT_DROPDOWN_OPTIONS, $scope.custom_dropdown_options);
        $scope.selectedAxes[axis] = id;
        $scope.selectAxis(axis);
    };

    this.openCustomAxisDialog = function (customAxis, axis) {
        modalService.showModal({
            template: require('./custom-axis-dialog/custom-axis-dialog.html'),
            inputs: {
                customAxis: customAxis,
                currentAxesNames: new Set(_.pull(_.map($scope.dropdown_options, function (option) {
                    return option.label.toLowerCase() }), (customAxis.label || '').toLowerCase())),
                metricOptions: CUSTOM_DROPDOWN_OPTIONS
            },
            controller: 'customAxisDialogController',
            scope: $scope
        }).then(function (modal) {
            modal.close.then(function (result) {
                if (result) {
                    const index = _.findIndex($scope.custom_dropdown_options, {id: result.id});
                    if (index !== -1) {
                        if (result.delete) {
                            $scope.custom_dropdown_options.splice(index, 1);
                            result.id = undefined;
                        } else {
                            $scope.custom_dropdown_options.splice(index, 1, result);
                        }
                    } else {
                        if (result.delete) return;
                        $scope.custom_dropdown_options.push(result);
                    }

                    self.addCustomAxisToDropdown(result.id, axis);
                }
            });
        });
    };

    $scope.createCustomAxis = function(axis) {
        self.openCustomAxisDialog({}, axis);
    };

    $scope.$on('editCustomAxis', function (event, axis) {
        const editCustomAxis = self.getOptionBySelectedAxis(axis);
        self.openCustomAxisDialog(editCustomAxis, axis);
    });

    $scope.selectAxis = function(axis) {
        self.selectDropdownExtra(axis);
        self.updateChartData();
        $scope.context.current.landscape.x_axis = $scope.selectedAxes.x;
        $scope.context.current.landscape.y_axis = $scope.selectedAxes.y;
        $scope.context.current.landscape.previous_dropdown_extra = angular.copy($scope.dropdown_extra);
    };

    $scope.focusOnAxis = (axis) => $timeout(() => self.focusOnInputBar(axis));

    $scope.showInputBarForAxis = (axis) => self.isAssociationOption((($scope.dropdown_extra || {})[axis] || {}).type);

    $scope.dataTrees = {
        topicDataTree: {
            checkedArray: [],
            children: topicsTree.topics,
            allSelectedLabel: 'All Selected',
            maxSummaryItems: 2
        }
    };

    //inits dataTrees.subGeos
    $rootScope.Geos.setSubGeos($scope, () => null, []);
    this.insightsExportService.setTitle('Landscape');
    this.insightsExportService.setSummaryFunction(this.getSummaryReport);
}

LandscapeWidgetController.prototype._doUpdate = function(values, changedVals) {
    this.initSelectedAxes();
    this.initLandscapeContext();
    this.examplesDataModel.visible = false;
    if (this.$location.path() === "/insights/landscape") { //Don't set timeframe vars if we're stepping out of widget
        this.$scope.$root.$broadcast('timeframe-vars-update', ["1M", "3M", "6M", "1Y"], this.TIMES.INSIGHT_OFFSET, false);
    }
    this.updateChartData();
};

LandscapeWidgetController.$inject = ["$q", "$scope", "$rootScope", "$timeout", "$element", "util",
                                     "consumptionTrendService", "associationTrendService", "sentimentTrendService",
                                     "examplesDataModel", "insightsExportService", "notificator",
                                     "ModalService", "topicsTree", "geoService", "TIMES", "$location",
                                     "abiPermissions", "$state"];

module.exports = require("angular").module(__filename, [
    require('../../common/charts/scatter-chart').name,
    require('./custom-axis-dialog/custom-axis-dialog').name
])
    .directive("landscapeWidget", [function() {
        return BaseWidget({
            restrict: "E",
            template: require('./landscape-widget.html'),
            scope: {
                timeframe: "=",
                active: "=",
                terms: "=",
                geo: "=",
                sub_geos: "=subGeos",
                topics: "=",
                sgWebSource: "=",
                cacheBaster: '='
            },
            controller: LandscapeWidgetController
        });
    }]);
