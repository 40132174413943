"use strict";
import ComplianceService from 'react/services/ComplianceService';
import * as TargetComplianceService from 'react/services/TargetComplianceService';
createTargetCtrl.$inject = ['$scope', '$timeout', '$state', '$http', '$q', 'TARGET_CONSTS', 'context', 'notificator', 'TargetsCommon', 'ssoService', 'params', 'close'];
function createTargetCtrl($scope,  $timeout, $state, $http, $q, TARGET_CONSTS, context, notificator, TargetsCommon, ssoService, params, close) {

    $scope.createTargetCtrl = this;
    $scope.name = '';
    $scope.nameError = '';
    
    $scope.init = function () {
        setTimeout(function(){$("#target-name").focus();},300);
    };

    $scope.showTargetLoading = ()=>{
        return $scope.targetLoading && 
            params.target_type==
            TARGET_CONSTS.INVENTORY_LISTS_TARGET_TYPE;
    }

    this.close = function () {
        $scope.targetLoading = false;
        close({});
    };

    this.cancel = function () {
        if(params.hasPhrases){
            TargetComplianceService.removeAllPhrases();
        }
        this.close();
    };

    this.checkEnter = function($event){
        $scope.nameError = '';
        if($event.keyCode==13){
            this.createTarget();    
        }
    }

    this.validateTarget = function(){
        return new Promise(resolve => {

            if("" == $("#target-name").val()){
                resolve(false);
                return;
            }

            let nameLength = 50, nameMsg = 'target name exceeded the max length (1-'+nameLength+')';
            if (params.name.length > nameLength) {
                notificator.error({body: nameMsg});
                resolve(false);
                return;
            }
        
            $scope.validatingName = true;

            try{
               ComplianceService.checkPhrases({
                    phrases: $("#target-name").val().split(" "), 
                    feature: 'target',
                    isTitle: true,
                    checkGDPR: true,
                    checkNAI: true,
                }).then((res)=>{
                    $scope.validatingName = false;

                    if(res.passed){
                        $scope.nameError = '';
                        resolve(true);
                    }else{
                        $scope.nameError = res.text;
                        resolve(false);
                    }
                    $timeout(()=>$scope.$apply());  
                });
            }catch(er){
                $scope.validatingName = false;
                resolve(false);
            }
        });
    }


    this.createTarget = async function(){
        const isValidated = await this.validateTarget(); 
        if(!isValidated){
            return;
        }
        $scope.nameError = '';

        params.name = $("#target-name").val().trim();
        params.program_id = context.current.p_id+"";
        params.action = 'createNewTarget';
        delete params.hasPhrases;
        TargetsCommon.TargetData.targetToServer({target: params});

        let r = $scope.$root,
            url = TargetsCommon.targetURL(params);

        $scope.targetLoading = true;
        ssoService.getAccessToken().then(accessToken => {
          let headers = { 'headers' : { 'SSO-Access-Token' : accessToken.accessToken } };
          $http.post(url, params, headers).then((res)=>{
              notificator.success({body: 'Target "' + params.name + '" created'});
              r.$broadcast('updateTargets', {target: res.data, action: 'create-target'});
              TargetsCommon.mixpanelTargets.trackNewTarget(res.data);
              this.close();
          }, (res)=>{
              let error = res.data.errors ? _.castArray(res.data.errors).join(" ") : 'Error creating target.';
              console.log(error);
              notificator.error({body: error});
              this.close();
          });
        });
    }

    $scope.init();
}

module.exports = createTargetCtrl;
 
