import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon/Icon';
import './LabelWithIcon.scss';

const LabelWithIcon = ({ iconId, label }) => (
  <div className="label-with-icon-component">
    <Icon iconId={iconId} className="label-icon" width="16px" height="16px"></Icon>
    <span className="label">{label}</span>
  </div>
);

LabelWithIcon.propTypes = {
  iconId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

LabelWithIcon.defaultProps = {};

export default LabelWithIcon;
