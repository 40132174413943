import config from 'infra/config';

const SEARCH_API = config.SEARCH_API + '/new_related_articles?disable_notification=1';

module.exports = angular.module(__filename, [])
    .service('newsService', ['$http', 'geoService', 'errorMgmt', 'topicsTree',
        function ($http, geoService, errorMgmt, topicsTree) {

            this.get = getRelatedArticles;

            function getRelatedArticles({ terms, results_per_term, geos, kwd_ids, timeframe, brand_name }) {
                return $http({
                    url: SEARCH_API,
                    method: 'GET',
                    cache: true,
                    params: Object.assign({
                        "phrases[]": terms, // array of phrases
                        "cc_filter[]": _.map(geoService.serverValue(geos), 'id'),
                        sensitive_content: topicsTree.isSensitive,
                        results_per_term,
                        kwd_ids,
                        brand_name
                    }, {timeframe_start: timeframe.start, timeframe_end: timeframe.end})
                }).then(responseSucess, articlesResponseFailed);
            }

            function responseSucess(res) {
                return res.data;
            }

            function articlesResponseFailed(err) {
                errorMgmt.widgetServiceError('Recent Articles', err);
                return [];
            }
        }
    ]
);