import CacheWithTTL from './CacheWithTTL';
import md5 from 'js-md5';

const DEFAULT_OPTIONS = { secondsUntilTTL: 60 * 60 * 6, cacheSingleEntryForMethod: true };

export const cacheAsyncMethod = (methodCacheKey, asyncMethod, options) => {
  options = { ...DEFAULT_OPTIONS, ...options };

  function executeAsyncMethodAndCachePromise(args, cacheKey, argsMd5) {
    const promise = asyncMethod(...args);
    setValueWithTTL(
      cacheKey,
      argsMd5,
      false,
      promise,
      Date.now() + options.secondsUntilTTL * 1000,
      options.cacheSingleEntryForMethod
    );
    return promise;
  }

  function setValueWithTTL(cacheKey, argsMd5, isResolved, promise, ttl, isCacheSingleEntryForMethod) {
    const cachedValue = isCacheSingleEntryForMethod ? [isResolved, argsMd5, promise] : [isResolved, promise];
    CacheWithTTL.set(cacheKey, cachedValue, ttl);
  }

  function getValueWithTTL(cacheKey, argsMd5) {
    let valueWithTTL = CacheWithTTL.getWithTTL(cacheKey);
    if (!valueWithTTL) return null;

    const [value, ttl] = valueWithTTL;
    if (!options.cacheSingleEntryForMethod) {
      const [isResolved, promise] = value;
      return [isResolved, promise, ttl];
    }
    const [isResolved, cachedArgsMd5, promise] = value;
    if (cachedArgsMd5 != argsMd5) return null;
    return [isResolved, promise, ttl];
  }

  return async (...args) => {
    const cacheKey = md5(`${methodCacheKey}#${options.cacheSingleEntryForMethod ? '' : JSON.stringify(args)}`);
    const argsMd5 = md5(JSON.stringify(args));
    let valueWithTTL = getValueWithTTL(cacheKey, argsMd5);
    if (!valueWithTTL) return executeAsyncMethodAndCachePromise(args, cacheKey, argsMd5);

    const [isResolved, promise, ttl] = valueWithTTL;

    if (isResolved) return promise;

    try {
      await promise;
      setValueWithTTL(cacheKey, argsMd5, true, promise, ttl, options.cacheSingleEntryForMethod);
      return promise;
    } catch (e) {
      return executeAsyncMethodAndCachePromise(args, cacheKey, argsMd5);
    }
  };
};
