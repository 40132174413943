import { get } from 'lodash';
const DSP_PERMISSION_KEY_IN_OKTA = 'APP_TURN_PLATFORM';

function getOktaTokenFromLocalStorage() {
  const cookie = localStorage['okta-token-storage'];
  if (!cookie) return null;
  return JSON.parse(cookie);
}

function getUserPermissions() {
  const idToken = get(getOktaTokenFromLocalStorage(), 'idToken');
  return idToken?.claims?.authorities || [];
}

function isUserHasPermission(permission) {
  const userPermissions = getUserPermissions();
  return userPermissions.includes(permission);
}

function getUserEmail() {
  const idToken = get(getOktaTokenFromLocalStorage(), 'idToken');
  return idToken?.claims?.email;
}

export default {
  isUserHasDSPPermission: () => isUserHasPermission(DSP_PERMISSION_KEY_IN_OKTA),
  getUserEmailFromToken: getUserEmail,
  getOktaTokenFromLocalStorage,
};
