import { forEach, map, sum } from 'lodash';
import { getContext, getChannelName } from '../../middleware/AngularExportedMiddleware';
import * as MixpanelCommon from './MixpanelCommon';

const appName = 'Audience';

let context = getContext();
let snapshot = context ? context.current : {};
let widgetName;

export const audience = () => {
  context = getContext();
  snapshot = context ? context.current : {};

  const result = { appName };
  const demographics = [];
  const interests = [];
  const websites = [];
  const lifestyle = [];
  /* eslint-disable-next-line consistent-return */
  forEach(snapshot.audience_segment, (seg) => {
    switch (seg.type) {
      case 'demographics':
        demographics.push(map(seg.age, 'label'));
        demographics.push(map(seg.ethnicity, 'label'));
        demographics.push(map(seg.geo, 'label'));
        demographics.push(map(seg.income, 'label'));
        demographics.push(map(seg.gender, 'label'));
        demographics.push(map(seg.operand, 'label'));
        break;
      case 'interests':
        interests.push(map(seg.required, 'text'));
        interests.push(map(seg.excluded, 'text'));
        interests.push(map(seg.included, 'text'));
        interests.push(map(seg.levelOfIntent, 'text'));
        interests.push(map(seg.operand, 'label'));
        break;
      case 'websites':
        websites.push(map(seg.excluded, 'text'));
        websites.push(map(seg.included, 'text'));
        websites.push(map(seg.required, 'text'));
        websites.push(map(seg.operand, 'label'));
        break;
      case 'lifestyle':
        lifestyle.push(seg.label);
        lifestyle.push(map(seg.operand, 'label'));
        break;
      default:
        return result;
    }
    if (!result.segment) {
      result.segment = {};
    }
    result.segment.demographics = demographics;
    result.segment.interests = interests;
    result.segment.websites = websites;
    result.segment.lifestyle = lifestyle;
  });
  return result;
};

export const trackPageView = (widget, channel) => {
  widgetName = widget;
  MixpanelCommon.trackPageView(appName, widgetName, channel);
};

export const trackExport = (channel) => MixpanelCommon.trackExport(appName, widgetName, channel);

export const trackLoadAudience = (name, channel) => {
  const props = {
    ...audience(),
    'Audience Name': name,
    widget: widgetName,
    channel: getChannelName(channel),
  };
  MixpanelCommon.sendToMixpanel('Audience-Builder - Load Audience Clicked', props);
};

export const trackNewAudience = (data) => {
  const props = { ...audience(), ...data, widget: widgetName };
  MixpanelCommon.sendToMixpanel('Audience-Builder - New Audience Clicked', props);
};

export const trackSaveAudience = (data) => {
  const props = {
    ...audience(),
    ...data,
    segment: MixpanelCommon.extractSegmentDetails(data.segment),
    widget: widgetName,
  };
  MixpanelCommon.sendToMixpanel('Audience-Builder - Save Audience Clicked', props);
};

export const trackSort = (column, channel) => {
  const props = {
    widgetName: widgetName,
    appName: appName,
    channel: getChannelName(channel),
    'column title': column.title,
  };
  MixpanelCommon.trackSort(props);
};

//builder

export const trackSegment = (segment, channel) => {
  const props = { 'Segment Type': segment, channel: getChannelName(channel) };
  MixpanelCommon.sendToMixpanel('Audience-Builder - Segment Type Clicked', props);
};

export const trackTooNarrow = () => {
  const props = { ...audience(), widget: 'audience-builder' };
  MixpanelCommon.sendToMixpanel('Audience-Builder - Too Narrow Segment Parameters', props);
};

export const trackExploreAudience = (data) => {
  const props = {
    ...audience(),
    widget: 'audience-builder',
    channel: getChannelName(data.channel),
    segment: MixpanelCommon.extractSegmentDetails(data.segment),
  };
  MixpanelCommon.sendToMixpanel('Audience-Builder - Explore Audience Segment Parameters', props);
};

export const trackAdvancedSegmentation = (channel) => {
  const props = { ...audience(), widget: 'audience', channel: getChannelName(channel) };
  MixpanelCommon.sendToMixpanel('Audience-Builder - Advanced Segmentation Clicked', props);
};

//explorer

export const trackRefine = (widget, channel) => {
  const props = { ...audience(), widget, channel: getChannelName(channel) };
  MixpanelCommon.sendToMixpanel('Audience-Explorer - Refine Audience Clicked', props);
};

export const trackInterestTypeChanged = (interestType, channel) => {
  const props = {
    ...audience(),
    interestType: interestType,
    widget: 'interests',
    channel: getChannelName(channel),
  };
  MixpanelCommon.sendToMixpanel('Overview - Insights Generation', props);
};

export const trackViewChange = (value, widget, trackAppName, channel) => {
  const props = { ...audience(), 'view type': value, widget };
  if (trackAppName) {
    props.appName = trackAppName;
  }
  if (channel) {
    props.channel = getChannelName(channel);
  }
  MixpanelCommon.sendToMixpanel('Overview - Insights Generation', props);
};

export const trackInterestPhrasesSelectAll = (value, widget, channel) => {
  const props = { ...audience(), value, widget, channel: getChannelName(channel) };
  MixpanelCommon.sendToMixpanel('Audience-Explorer - Interests - Phrases - Select all', props);
};

export const trackAudienceExplorerTopicClick = (topicclicked, widget, channel) => {
  const props = { ...audience(), widget, topicclicked, channel: getChannelName(channel) };
  MixpanelCommon.sendToMixpanel('Audience-Explorer - Specific Topic Clicked', props);
};

export const trackDemographicsInterestsView = (value, widget, channel) => {
  const props = { ...audience(), widget, 'view type': value, channel: getChannelName(channel) };
  MixpanelCommon.sendToMixpanel('Audience-Explorer - Consumption/Skew View', props);
};

export const trackTVShowsViewershipType = (value, channel) => {
  const props = { ...audience(), widget: 'tv shows', 'view type': value, channel: getChannelName(channel) };
  MixpanelCommon.sendToMixpanel('Audience-Explorer - TV Show viewership Type', props);
};

export const trackTVShowsView = (value, channel) => {
  const props = { ...audience(), widget: 'tv shows', 'viewership type': value, channel: getChannelName(channel) };
  MixpanelCommon.sendToMixpanel('Audience-Explorer - List/Table/Streams view', props);
};

export const trackTimelineUserExample = (userExample, channel) => {
  const exampleChosen = { gender: userExample.gender, age: userExample.age };
  const props = { 'Example Chosen': exampleChosen, channel: getChannelName(channel) };
  MixpanelCommon.sendToMixpanel('Audience-Explorer - Timeline User Example', props);
};

export const trackMatchingMoments = () =>
  MixpanelCommon.sendToMixpanel('Audience-Explorer - Timeline Matching Moments Clicked');

export const trackClickEvent = (urlClicked) => {
  const props = { 'URL Clicked': urlClicked };
  MixpanelCommon.sendToMixpanel('Audience-Explorer - Timeline Click on Event (with URL)', props);
};

export const trackScrollDate = () => MixpanelCommon.sendToMixpanel('Audience-Explorer - Timeline Scroll Date');

export const trackTestingInterests = (type, interestsToTestSearchResults, isAdvanced, channel) => {
  const eventName =
    'Audience-Explorer - Interests - ' + (isAdvanced ? 'Testing Advanced Interests' : 'Testing Interests');
  const audienceData = audience();
  const props = {
    ...audienceData,
    channel: getChannelName(channel),
    widget: 'interests',
    segmentString: JSON.stringify(audienceData.segment),
    'interest type': type,
    'interests to test': interestsToTestSearchResults.map((r) => r.text),
    'first interest to test': interestsToTestSearchResults[0].text,
    'num of interests to test': interestsToTestSearchResults.length,
    ...(isAdvanced && { 'raw advanced interests to test': interestsToTestSearchResults }),
  };
  if (isAdvanced) {
    ['required', 'included', 'excluded'].forEach((o) => {
      props[`advanced interests to test: num of ${o}`] = sum(
        interestsToTestSearchResults.map((r) => (r[o] || []).length)
      );
    });
  }
  MixpanelCommon.sendToMixpanel(eventName, props);
};
