require("../app-common-elements/app-common-elements");

module.exports = angular.module(__filename, []).directive("appSecondaryBar", [function () {
    return {
        restrict: "E",
        scope: {
            onExport: '='
        },
        template: require("./app-secondary-bar.html"),  
        transclude: true
    }
}]);
