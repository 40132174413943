import 'angular-ui-router';
import moment from 'moment';
import { find } from 'lodash';
import { isTimeframeHidden, showDatePicker } from './timeframe/timeframe-utils';

const timeFrameFilter = require("./timeframe/timeframe"),
    contextModule = require("infra/context/context");

module.exports = angular.module(__filename, [
    timeFrameFilter.name,
    contextModule.name,
    'ui.router',
    require("data/interests-service").name
]).directive("appCommonElements", ["context", "$state", '$rootScope', 'abiPermissions', 'TIMES', 'notificator', 'INSIGHTS_TIMELINE', 'APP_NAME',
    function (context, $state, $rootScope, abiPermissions, TIMES, notificator, INSIGHTS_TIMELINE, APP_NAME) {

        return {
            restrict: "E",
            template: require("./app-common-elements.html"),
            scope: {
                onExport: '='
            },
            link: function (scope) {
                const DATE_FORMAT = 'DD_MM_YY_HH_mm';
                const INTERESTS_ALLOWED_PAST_MONTHS = 15;

                scope.context = context;
                scope.$state = $state;

                scope.times = [];
                scope.minDate = null;
                scope.subtractDay = false;

                scope.onToggleFilterMenu = $rootScope.toggleFilterBar;

                syncFiltersOnInit();

                scope.hasActiveFilter = function () {
                    const state = $state.current;
                    const display = state.display.toLowerCase();
                    return [APP_NAME.DASHBOARD, APP_NAME.INTERESTS, APP_NAME.INSIGHTS].includes(display) && $rootScope.hasActiveFilter(state.name);
                };

                scope.hasPermission = abiPermissions.hasPermission;

                scope.hideFiltersMenu = function () {
                    const name = $state.current.name;

                    if (!name.startsWith('audience-')) return false;
                    const hasAudienceChannelPermissions = abiPermissions.hasPermission('audience au telco channel') || abiPermissions.hasPermission('audience linkedin channel') || abiPermissions.hasPermission('audience sg telco channel');
                    return !hasAudienceChannelPermissions || name !== 'audience-builder';
                };


                scope.hideExport = function () {
                    const state = $state.current;
                    return state.display.toLowerCase() === APP_NAME.DASHBOARD
                           || (state.name.startsWith('settings.') && state.name !== 'settings.users' && state.name !== 'settings.targets')
                           || state.name === 'audience-builder'
                           || state.name === 'alerts-v2'
                           || !scope.hasPermission(['export']);
                };

                scope.hideTimeframe = function () {
                    return isTimeframeHidden($state.current.name, $state.current.display);
                };

                scope.showDatePicker = function () {
                    return showDatePicker($state.current.name, $state.current.display, scope.hasPermission, APP_NAME);
                };

                scope.onTimeFrameChanged = function (newValue) {
                    const value = newValue._value;
                    const state = $state.current;
                    if(!state.display.toLowerCase() === APP_NAME.INTERESTS && !state.display.toLowerCase() === APP_NAME.INSIGHTS) {
                        return;
                    }

                    context.current._insights_timeframe_mold._value = value;
                    context.current._interests_timeframe_mold._value = value;
                };

                scope.onTimelineIntervalChange = (newValue) => {
                    context.current.insights_timeline_interval = newValue;
                    if(newValue === 'Daily') {
                        context.current.insights_timeline_timezone = INSIGHTS_TIMELINE.DEFAULT_TIMEZONE;
                    }
                };

               scope.onTimelineTimezoneChange = (newValue) => context.current.insights_timeline_timezone = newValue;

                scope.getTimeframeMold = function () {
                    const state = $state.current;
                    if (state.display.toLowerCase() === APP_NAME.INTERESTS)
                        return context.current._interests_timeframe_mold;
                    if (state.display.toLowerCase() === APP_NAME.INSIGHTS)
                        return context.current._insights_timeframe_mold;
                    return null;
                };

                scope.showTimelineInterval = () => {
                    const isHourlyChannel = ['articles', 'sg_bidstream'].includes(context.current.insightsChannels.value);
                    const hasHourlyPermission = abiPermissions.hasPermission(['hourly consumption trends']);
                    if($state.current.display.toLowerCase() !== APP_NAME.INSIGHTS || !isHourlyChannel || !$state.includes('*.seasonality') || !hasHourlyPermission) {
                        resetTimelineIntervalAndTimezone();
                        return false;
                    }

                    const dateRange = context.current._insights_timeframe_mold._value;
                    if(_.isNumber(dateRange[0])) {
                        return dateRange[0] <= 6 && dateRange[1] === 'day';
                    }
                    const startDate = moment(dateRange[0], DATE_FORMAT);
                    const endDate = moment(dateRange[1], DATE_FORMAT);
                    return moment(endDate).diff(startDate, 'days') <= 6;
                }

                scope.$on('timeframe-vars-update', function(event, times, minDate, subtractDay, fullWeeksDetection, noMaxTimeframeNotification, maxSpan) {
                    if (times) {
                        times = TIMES.getTimes(times);
                        if (!_.isEqual(times, scope.times)) {
                            scope.times = times;
                        }
                    }

                    scope.minDate = minDate;
                    scope.subtractDay = !!subtractDay;
                    scope.fullWeeksDetection = !!fullWeeksDetection;
                    scope.noMaxTimeframeNotification = !!noMaxTimeframeNotification;
                    scope.maxSpan = maxSpan;
                });

              function isInterestsRangeInvalid(value) {
                  let startDate;
                  let subtractedToDate;

                  if(_.isNumber(value[0])) {
                      subtractedToDate = moment().subtract(getInterestsMaxMonthsRange(), 'month');
                      startDate = moment().subtract(value[1], value[0]);
                  } else {
                      subtractedToDate = moment(value[1], DATE_FORMAT).subtract(getInterestsMaxMonthsRange(), 'month');
                      startDate = moment(value[0], DATE_FORMAT);
                  }
                  return startDate.diff(subtractedToDate) < 0;
              }

              function getMaxInterestsRange(value) {
                  if(_.isNumber(value[0])) {
                      return [getInterestsMaxMonthsRange(), 'month'];
                  }

                  const from = moment(value[1], DATE_FORMAT).subtract(getInterestsMaxMonthsRange(), 'month').format(DATE_FORMAT);
                  const to =  moment(value[1], DATE_FORMAT).format(DATE_FORMAT);
                  return [from, to];
              }

              function getInterestsAllowedPastRange(value) {
                  const minDate = moment().subtract(INTERESTS_ALLOWED_PAST_MONTHS, 'month').startOf('day');
                  const startDate = moment(value[0], DATE_FORMAT);
                  const endDate = moment(value[1], DATE_FORMAT);
                  const minDateDiff = moment(minDate).diff(startDate);

                  if(minDateDiff > 0) {
                      notificator.notify({body: 'Maximum look-back is 15 months. Timeframe was adjusted accordingly'});
                      if(moment(minDate).diff(endDate) > 0) {
                          const startEndDateDiff = endDate.diff(startDate);
                          return [minDate.format(DATE_FORMAT), minDate.add(startEndDateDiff).format(DATE_FORMAT)]
                      }
                      return [minDate.format(DATE_FORMAT), value[1]]
                  }
                  return value;
              }

              function syncFiltersOnInit() {
                  if($state.current.display.toLowerCase() === APP_NAME.INTERESTS) {
                      const value = context.current._interests_timeframe_mold._value;
                      context.current._interests_timeframe_mold._value = value;
                      if(isInterestsRangeInvalid(value)) {
                          context.current._interests_timeframe_mold._value = getMaxInterestsRange(value);
                          const maxMonthRange = getInterestsMaxMonthsRange();
                          notificator.notify({body: `Maximum date span is ${maxMonthRange} month${maxMonthRange > 1 ? 's' : ''}. Timeframe was adjusted accordingly.`});
                        }
                        if(!_.isNumber(value[0])) {
                            context.current._interests_timeframe_mold._value = getInterestsAllowedPastRange(context.current._interests_timeframe_mold._value);
                        }
                      context.current._insights_timeframe_mold._value = context.current._interests_timeframe_mold._value
                    }
                }

                function getInterestsMaxMonthsRange() {
                  return context.current.gridChannels.value !== 'facebook' ? 3 : 1;
                }

                function resetTimelineIntervalAndTimezone() {
                    context.current.insights_timeline_interval = INSIGHTS_TIMELINE.DEFAULT_INTERVAL;
                    context.current.insights_timeline_timezone = INSIGHTS_TIMELINE.DEFAULT_TIMEZONE;
                }
            }
        }
    }
]);
