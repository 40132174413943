import * as MixpanelCommon from '../../../react/infra/mixpanel/MixpanelCommon';
import getLogoFallback from "../../../react/utils/LogoUtils";

module.exports = angular.module(__filename, []).directive('contentDriver', [function () {
    return {
        restrict: "E",
        require: '^contentDriversPane',
        controllerAs: 'contentCtrl',
        scope: {
            alphabetized: '=?',
            url: '=',
            imageUrl: '=',
            title: '=',
            tagline: '=',
            icon: '=?',
            rt: '=?',
            letter: '=?',
            highlight: '=?',
            hide: '=?',
            termClass: '=?',
            label: '=?',
            selected: '=?',
            sentiment: '=?',
            withTermContent: '=?',
            confidence: '=?',
            date: '=?',
            disableLikeIcons: '@',
            consumption: '=?'
        },
        template: require('./content-driver.drv.html'),
        link: function (scope, iElement) {
            scope.imageFallback = getLogoFallback(scope.url) || '/images/common/no-available-image-tweet.svg';
            iElement.removeAttr('title');
        },
        controller: ['$scope',
            function (scope) {
                scope.reportUrl = function(url){
                    MixpanelCommon.trackUrlClick(url);
                }
            }
        ]
    }
}]);
