module.exports = angular.module(__filename, [])
  .directive("amDropdownTree", [() => {
    return {
      restrict: 'E',
      template: require('./am-dropdown-tree.html'),
      scope: true,
      
      link: {
        pre: (scope, element, attrs) => {
          scope.dataTreeName = attrs.treeName;
          scope.disabled = scope.$eval(attrs.disabled);
          scope.open = false;
          scope.toggleOpen = () => {
            if (!scope.disabled) scope.open = !scope.open;
          }

          scope.close = () => {
            if (!scope.disabled) scope.open = false;
          }

          scope.$watch(attrs['disabled'], (newValue, oldValue) => {
            scope.disabled = newValue;
          });
        }
      }
    }
}]);