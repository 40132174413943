import React from 'react';
import './LifestyleModal.scss';
import ModalWithConfirmationButtons from '../../../../../common/Modal/ModalWithConfirmationButtons/ModalWithConfirmationButtons';
import RadioButton from '../../../../../common/RadioButton/RadioButton';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

class LifestyleModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLifestyle: props.lifestyleInput,
    };

    this.segmentType = 'lifestyle';
    this.handleLifestyleSelect = this.handleLifestyleSelect.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleLifestyleSelect(newValue) {
    this.setState({ selectedLifestyle: newValue });
  }

  handleSubmit() {
    this.props.onSubmit({ ...this.props.lifestyleInput, type: this.segmentType, ...this.state.selectedLifestyle });
  }

  handleCancel() {
    this.props.onCancel(this.segmentType);
  }

  render() {
    const { modalTitle, isOpen, lifestyles } = this.props;

    return (
      <div className="lifestyle-modal-component">
        <ModalWithConfirmationButtons
          width="810px"
          modalTitle={modalTitle}
          isOpen={isOpen}
          isSubmitDisabled={isEmpty(this.state.selectedLifestyle)}
          isDisabledEnterKeyPress={isEmpty(this.state.selectedLifestyle)}
          onSubmit={this.handleSubmit}
          onCancel={this.handleCancel}
          onXClick={this.handleCancel}
          isLightTheme={true}
        >
          <div className="lifestyle-modal-content">
            <RadioButton
              values={lifestyles}
              selectedValue={this.state.selectedLifestyle}
              onSelect={this.handleLifestyleSelect}
              numColumns={3}
              displayAsCheckbox={true}
            />
          </div>
        </ModalWithConfirmationButtons>
      </div>
    );
  }
}

LifestyleModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  modalTitle: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  lifestyleInput: PropTypes.object,
  lifestyles: PropTypes.array.isRequired,
};

LifestyleModal.defaultProps = {
  lifestyleInput: {},
};

export default LifestyleModal;
