/**
 * Created by pery on 12/11/2015.
 */
module.exports = amTableController;
amTableController.$inject = ['$scope', '$attrs'];
function amTableController(scope, attrs) {
    this.onSelected = scope.onSelected;
    this.sortedData = [];
    var me = this;
    /**************************
     * selected rows
     * **************************/

    scope.prevIndexSelected = 0;
    this.toggleSelected = function (obj, event) {
        var model = scope.selectedModel || [],
            data = me.sortedData,
            operation = obj.selected,  // this one selected if we 'add' or 'remove' all list
            datums = null;

        if (event.shiftKey) {
            //todo: maybe amInput[type=checkbox] should handle this part
            var prvI = scope.prevIndexSelected;
            var range = [prvI, obj.index];
            range.sort(function (a, b) {
                return a - b;
            });
            //var reverse = prvI > obj.index;
            datums = data.slice(range[0], range[1] + 1);

            if (operation) {
                datums = _.difference(datums, model);
            }
            datums.forEach(_.setter('selected', obj.selected));
        } else {
            obj.selected = operation;
            datums = [obj];
        }
        _.manipulateArray(model, operation, datums);
        scope.selectedModel = model.slice();
        scope.onSelected({$model: scope.selectedModel});
        scope.prevIndexSelected = obj.index;
    };

    this.clearSelected = function () {
        _.forEach(scope.selectedModel, _.setter('selected', false));
        scope.selectedModel = [];
    };

    scope.toggleColumnShow = function (col) {
        var hidden = {};
        for (var i = 0, c; c = me.columns[i]; i++) {
            hidden['hide-col-' + (i + 2)] = c.hidden;
        }
        scope.hiddenColumnsObject = hidden;
        var n = 0;
        me.columns.forEach(function (c) {
            n += c.hidden ? 0 : 1;
        });
        scope.numVisibleCol = n;
    };

    /****************************
     * Export Table desciption for Excel
     ****************************/
    me.generateTableSheetDesciption = function (workbook) {
        var exportColumn = {};
        me.columns.forEach(function (col) {
            exportColumn[col.title] = {
                key: col.dataKey,
                width: 20,
                type: col.dataType,
                def: ''
            }
        });
        return exportColumn;
    };

    me.generateTableDescription = function () {
        return me.columns;
    };

    /*************
     * remove row
     ************/
    me.remove = function (obj) {
        _.remove(me.sortedData, obj);

        scope.dataToView();
        attrs.onRemove && scope.onRemove({
            $value: obj
        });
    }
}
