import React from 'react';
import PropTypes from 'prop-types';
import './TopBar.scss';
import ClickableIcon from '../../ClickableIcon/ClickableIcon';
import Separator from '../../Separator/Separator';
import classNames from 'classnames';

// "Powered by ..." commented but we keep the logic due to Kaban board task "Change Hamburger Menu and Global Header"
const TopBar = ({ centerElement, rightElement, isHamburgerVisible, onHamburgerClick, hideCompanyLogo }) => (
  <div className={classNames('top-bar-component', { 'has-dsp-permissions': isHamburgerVisible })}>
    <div className="left-elements">
      {isHamburgerVisible && <ClickableIcon className="hamburger-icon" iconId="hamburger" onClick={onHamburgerClick} />}
      {hideCompanyLogo ? (
        <img src="/images/infra/style/discovery_header_without_nexxen.svg" className="nexxen-discovery-header"></img>
      ) : (
        <img src="/images/infra/style/nexxen_discovery_header.svg" className="nexxen-discovery-header"></img>
      )}
    </div>

    <div className="center-elements">{centerElement}</div>

    <div className="right-elements">
      <Separator />
      {rightElement}
    </div>
  </div>
);

TopBar.propTypes = {
  centerElement: PropTypes.element,
  rightElement: PropTypes.element,
  onHamburgerClick: PropTypes.func.isRequired,
  isHamburgerVisible: PropTypes.bool.isRequired,
  hideCompanyLogo: PropTypes.bool.isRequired,
};

export default TopBar;
