var c = require("infra/utils/common");

module.exports = angular.module(__filename, [])
    .service("baseInsightsService", ['$q', '$state', 'context', '$rootScope', 'topicsTree', 'geoService', 'errorMgmt', 'notificator', 'insightsExportService', 'abiPermissions', 'cancellableHttp', 'CHANNEL' ,
     function($q, $state, context, $rootScope, topicsTree, geoService, errorMgmt, notificator, insightsExportService, permissions, cancellableHttp, CHANNEL) {
        var INSUFFICIENT_DATA_MESSAGE = "Sorry, insufficient content about <seed>. Please try more general interests or filters.";

        return {
            handleError: handleError,
            buildParams: buildParams,
            postAsyncData: postAsyncData,
            getEmptyTrends: getEmptyTrends,
            notifyInsufficient: notifyInsufficient,
            shouldSendAudience: shouldSendAudience
        };

        function buildParams(trendsMap, params, subtractDay, noWeeklyAdjust) {
            var all_terms = params.boolean_logics ? params.boolean_logics.concat(params.terms) : params.terms;
            _.each(all_terms, function(entry) {
                trendsMap[entry.text] = entry
            });

            params.channel = params.channel || '';
            var t = c.getTimeframe(params.timeframe, false, subtractDay, noWeeklyAdjust);

            params.geo = geoService.geosForChannel(params.geo, $state, context);

            var returnValue =  {
                "phrases": _.map(params.terms, 'text'),
                "boolean_logics": params.boolean_logics,
                "kwd_ids": params.kwd_ids,
                "topics": topicsTree.getIds(params.topics),
                sensitive_content: topicsTree.isSensitive,
                "origins": geoService.objToIdArray(params.geo),
                timeframe: t.type,
                timeframe_start: t.start,
                timeframe_end: t.end
            };
            if (c.isArray(params.sub_geos)){
              returnValue["sub_geos"] = context.current.insightsAllSubGeosSelected ? [] : geoService.createRequestSubGeoParam(params.sub_geos, params.channel);
            }
            if (c.isArray(params.user_first_party_audience)){
              returnValue["user_first_party_audience"] = params.user_first_party_audience;
            }
            returnValue["channel"] = params.channel || 'articles';
            if(shouldSendAudience(returnValue["channel"], permissions)){
                returnValue["audience"] = params.audience;
            }

            return returnValue;
        }

        function postAsyncData(url, params) {
            var promise = cancellableHttp.$http({
                method: 'POST',
                cache: $rootScope.context.cacheBaster? (Date.now() > ($rootScope.context.cacheBaster+1000)) : true,
                url: url,
                data: params
            });
            insightsExportService.addRequest(promise);
            return promise;
        }

        function isRequestCancelled(error){
            return error.config.timeout && error.config.timeout.$$state.value == "AMOBEE_CANCEL_OK";
        }

        function handleError(error) {
            if (error.status && error.status != 401 && !isRequestCancelled(error)) {
                errorMgmt.widgetServiceError('Graph', error);
            }

            return Promise.reject(error)
        }

        function notifyInsufficient(seeds) {
          if (seeds && !_.isEmpty(seeds)) {
            notificator.notify({body: INSUFFICIENT_DATA_MESSAGE.replace("<seed>", seeds.join(", "))});
          }
        }

        function getEmptyTrends(graphData, terms) {
            var insufficient = terms;
            _.each(graphData, function(data) {
                _.each(data.values, function(value) {
                    if (value.value != 0) {
                        insufficient = _.reject(insufficient, {
                            class: value.class
                        });
                    }
                })
            });

            return _.map(insufficient, 'text');
        }

        function shouldSendAudience(channel, permissions) {
            return (channel == "articles" && permissions.hasPermission("web channel")) ||
                   (["sg_telco", "sg_bidstream"].includes(channel) &&
                    permissions.hasPermission(["sg telco audience filters", "sg telco channel"]));
        }
    }]);
