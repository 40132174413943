var c = require("infra/utils/common");
import * as TargetComplianceService from 'react/services/TargetComplianceService';
import * as MixpanelTargets from '../../react/infra/mixpanel/MixpanelTargets';

module.exports = angular.module(__filename, [
    require('./content-driver/content-driver.drv.js').name
]).directive('contentDriversPane', ['directiveBuildHelper', 'TargetsCommon', function (directiveBuildHelper, TargetsCommon) {
    var dataPattern = '(@model as)? (@url is url of)? (@) (@title for)? (_instance_ in @source@) (track by @index)?';
    var Parser = directiveBuildHelper.compile(dataPattern);
    return {
        restrict: "E",
        controllerAs: 'contentCtrl',
        transclude: true,
        replace: 'element',
        template: require('./content-drivers-pane.drv.html'),
        scope: {
            driversTitle: '=?',
            filters: '=?',
            filterType: '=?'
        },
        link: function (scope, iElement, iAttrs, controller) {
            iElement.removeAttr('title');
        },
        controller: ['$scope', '$element', '$attrs', '$transclude', '$rootScope', '$timeout', 'utils', 'TARGET_CONSTS', 'abiPermissions',
            function (scope, element, attr, $transclude, $rootScope, $timeout, utils, TARGET_CONSTS, abiPermissions) {

                scope.hasPermission = abiPermissions.hasPermission;
                scope.currentTarget = scope.$parent.currentTarget;
                scope.context = scope.$parent.context;
                scope.allGeos = scope.$parent.Geos.serverValue();

                if (scope.$parent.contentDriversTargets && abiPermissions.hasPermission('activate targets')) {
                    scope.contentDriversTab = "target_drawer";
                } else {
                    scope.contentDriversTab = "content_drivers";
                }

                if (scope.$root.contentDriversOpen) {
                    open();
                }

                scope.$on('openContentDrivers', function (event, tab) {
                    if (tab !== 'target_drawer' || abiPermissions.hasPermission('activate targets')) {
                        open(tab);
                    }
                });

                scope.$on('closeContentDrivers', () => close());

                scope.$on('current-target-changed', (event, target)=>scope.setTargetFolders(target));

                scope.filterNonDynamic = (cell) => !cell.dynamic;

                scope.showTargetLoading = () => (scope.$parent.targetLoading &&
                                                scope.$parent.currentTarget.target_type ==
                                                  TARGET_CONSTS.INVENTORY_LISTS_TARGET_TYPE) ||
                                                scope.$parent.targetValidating;

                scope.getTargetName = () => scope.$parent.currentTargetLoaded ?
                                              (scope.$parent.currentTarget.name ? c.cutText(scope.$parent.currentTarget.name, 38, '...') :
                                                                                  'No Target Selected') :
                                            '';

                scope.setTargetFolders =(target) => {
                    scope.currentTarget = scope.$parent.currentTarget;
                    scope.targetFolders = {};

                    for (var i in target.results) {
                        scope.targetFolders[i] = {open: true};
                    }

                    scope.targetFoldersLength = Object.keys(scope.targetFolders).length;
                    for (var i in scope.targetFolders) {
                        if ((scope.$parent.tabs||['']).indexOf(i) === -1) {
                            scope.targetFolders[i] = null;
                            scope.targetFoldersLength--;
                        }
                    }
                }

                _.extend(this, {
                    clickTab: clickTab,
                    close: close,
                    removeItem: removeItem,
                    openCloseFolder: openCloseFolder,
                    toggleByFilter: toggleByFilter,
                    getDisplay: getDisplay,
                    removeAllFromTarget: removeAllFromTarget,
                    removeAllFromTargetCB: removeAllFromTargetCB,
                    supportTargetForecast: supportTargetForecast
                });

                function open(tab) {
                    scope.currentTarget = scope.$parent.currentTarget;
                    utils.triggerResize(element);

                    if (tab) {
                        scope.contentDriversTab = tab;
                    }

                    if (!element[0].classList.contains('ng-hide')) {
                        $(".main-content-page, .widget-panel.channels-panel").addClass("content-drivers-open");
                    }

                    scope.$root.contentDriversOpen = true;
                }

                function clickTab(tab) {

                    scope.contentDriversTab = tab;

                    if (!scope.$root.contentDriversOpen) {
                        open();
                        scope.$emit('contentDriversPaneOpenByClick');
                        MixpanelTargets.trackContentDriversPaneOpen();
                    }
                }

                function close() {
                    scope.$root.contentDriversOpen = false;
                    utils.triggerResize();
                    $(".main-content-page, .widget-panel.channels-panel").removeClass("content-drivers-open");
                }

                function openCloseFolder(key) {
                    if (scope.targetFoldersLength > 1) {
                        scope.targetFolders[key].open = !scope.targetFolders[key].open;
                    }
                }

                function toggleByFilter(filter) {
                    scope.$emit("contentDriversPaneToggled", filter);
                }

                function getDisplay(cell, key, tooltip) {

                    let display = '';

                    if(cell.manual) {
                        display = cell.text; //icon handled in view
                    } else if(key=='phrases' || key=='websites') {
                        display = cell.phrase;
                    } else if(key=='hashtags') {
                        display = cell.hashtag;
                    } else if(key=='influencers' || key=='audience') {
                        display = cell.handle;
                    } else if(key=='pages') {
                        display = cell.name;
                    } else if(key=='interests') {
                        display = cell.id;
                    } else if(key=='emojis') {
                        display = '';//handled in view
                    }

                    let MAX_DISPLAY_LENGTH = 30;
                    if(display.length > MAX_DISPLAY_LENGTH && !tooltip) {
                        display = display.slice(0, MAX_DISPLAY_LENGTH) + "...";
                    }

                    return display;
                }

                function removeItem(phraseObj, tab) {
                    TargetComplianceService.removePhrase({phraseObj, tab, results: scope.$parent.currentTarget.results});
                    TargetsCommon.removeFromTarget(scope.$parent.currentTarget, tab, [phraseObj], scope);
                }

                function removeAllFromTarget() {
                    TargetsCommon.removeAllFromTarget(scope.$parent.currentTarget, scope.contentCtrl);
                }

                function removeAllFromTargetCB() {
                    TargetsCommon.removeAllFromTargetCB(scope.$parent.currentTarget, scope);
                    scope.removingAll = false;
                    TargetComplianceService.removeAllPhrases(scope.$parent.currentTarget);
                }

                function supportTargetForecast() {
                    return scope.currentTarget && scope.currentTarget.incOrExc !== 'exclude' && abiPermissions.hasPermission('target size estimator') && scope.currentTarget.channel === 'web';
                }

            }
        ]
    }
}]);
