const add_tooltip = require('./tooltip.js');
angular.extend(module.exports, angular.module(__filename, []))
    .directive('amTooltip', ['$parse', '$q', ($parse, $q) => ({
        restrict: 'A',
        priority: 0,
        scope: {
            amTooltip: '@',
            amTooltipAdjustX: '@',
            amTooltipAdjustY: '@',
            amTooltipMultiple: '=?',
            amTooltipDisabled: '=?',
            amTooltipClasses: '@?',
            amTooltipOptions: '=?',
            amTooltipType: '@',
            amTooltipApi: '=?'
        },
        link: function($scope, element, attr, ctrl) {
            const init = () => {
                if($scope.amTooltipApi !== undefined) $scope.apiPromise = $q.defer();

                $scope.getQtipId = () => element.data('hasqtip');

                $scope.getQtipElement = (id) => $("#qtip-" + (id || $scope.getQtipId()));

                $scope._before = (before, fn) => {
                    return function() {
                        before.apply(this, arguments);
                        if(fn) fn.apply(this, arguments);
                    }
                };

                function removeEmpties(obj, deep = true) {
                    for(let k in obj) {
                        let v = obj[k];
                        if((v != null) && typeof v === 'object' && deep)
                            removeEmpties(obj[k], deep);
                        else if(v == null)
                            delete obj[k];
                    }
                }

                const defaultOptions = {
                    style: {
                        classes: 'common-tooltip-info',
                        tip: {width: 10, height: 5}
                    }
                };
                const toolTipType = $scope.amTooltipType || 'info no solo';
                const [at, my] = ($scope.amTooltip || 'bottom center to top center').split(' to ');
                const x = $scope.amTooltipAdjustX && +$scope.amTooltipAdjustX, y = $scope.amTooltipAdjustY && +$scope.amTooltipAdjustY;
                const attrOptions = {
                    position: {
                        at, my, adjust: {x, y}
                    },
                    style: {
                        classes: $scope.amTooltipClasses
                    }
                };

                if($scope.amTooltipOptions == null) $scope.qtipOptions = {};

                if($scope.amTooltipApi !== undefined) {
                    $scope.api = (e, id) => $("#qtip-" + (id || $scope.getQtipId())).qtip("api");

                    $scope.isApiReady = () => !!$scope.getQtipElement().qtip().rendered;

                    $scope.amTooltipApi = {
                        isReady: $scope.isApiReady,
                        api: $scope.api,
                        apiPromise: () => $scope.apiPromise.promise
                    };

                    $scope.resolveApiPromise = (event, api) => $scope.apiPromise.resolve(api);
                }

                removeEmpties(defaultOptions);
                removeEmpties(attrOptions);
                removeEmpties($scope.amTooltipOptions);
                const options = _.merge({}, defaultOptions, attrOptions, $scope.amTooltipOptions);

                if($scope.amTooltipApi !== undefined) {
                    if((options.events && options.events.render) != null) {
                        options.events.render = $scope._before($scope.resolveApiPromise, options.events.render);
                    } else {
                        if(options.events == null) {
                            options.events = {};
                        }
                        options.events.render = $scope.resolveApiPromise;
                    }
                }

                setTimeout(() => {
                    add_tooltip(element, toolTipType, options, $scope.amTooltipMultiple);
                    $scope.$watch('amTooltipDisabled', () => {
                        if($scope.amTooltipDisabled == null) return;
                        element.qtip('disable', !!$scope.amTooltipDisabled);
                    });
                    
                }, 50);
            };

            // TODO: This allows to the tooltip location to be dynamic. but we still need to make his more efficient.
            // The init function is just a wrapper to all the link function in a previous verison
            // We probably dont need to rerun all the function each time.
            init();
            $scope.$watch('amTooltip', init);
            $scope.$watch('amTooltipAdjustX', init);
            $scope.$watch('amTooltipAdjustY', init);
        }
    })]);
