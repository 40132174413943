import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import BaseSideMenu from '../BaseSideMenu/BaseSideMenu';
import Accordion from '../../Accordion/Accordion';
import SideMenuDropdown from '../../Dropdown/SideMenuDropdown/SideMenuDropdown';
import './ChannelFilterSideMenu.scss';

const ChannelFilterSideMenu = ({
  isSideMenuOpen,
  isAccordionOpen,
  onAccordionClick,
  channels,
  selectedChannel,
  onChannelChange,
}) => (
  <div className={classNames('side-menu-channel-filter-component', { open: isSideMenuOpen })}>
    <BaseSideMenu isOpen={isSideMenuOpen}>
      <Accordion isOpen={isAccordionOpen} iconId="channel" label="Channel" onToggle={onAccordionClick}></Accordion>
      <SideMenuDropdown
        values={channels}
        selectedValue={selectedChannel}
        isOpen={isAccordionOpen}
        onSelect={onChannelChange}
      ></SideMenuDropdown>
    </BaseSideMenu>
  </div>
);

ChannelFilterSideMenu.propTypes = {
  isSideMenuOpen: PropTypes.bool.isRequired,
  isAccordionOpen: PropTypes.bool.isRequired,
  onAccordionClick: PropTypes.func.isRequired,
  channels: PropTypes.array.isRequired,
  selectedChannel: PropTypes.object.isRequired,
  onChannelChange: PropTypes.func.isRequired,
};

export default ChannelFilterSideMenu;
