import React from 'react';
import './Checkbox.scss';
import Tooltip from '../Tooltip/Tooltip';
import Icon from '../Icon/Icon';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Checkbox = ({ label, isDisabled, tooltip, tooltipOptions, onToggle, isSelected }) => (
  <Tooltip content={isDisabled ? null : tooltip} options={tooltipOptions}>
    <div
      className={classNames('checkbox-component', { disabled: isDisabled })}
      onClick={() => !isDisabled && onToggle(!isSelected)}
    >
      <Icon
        iconId={isSelected ? 'selected-checkbox' : 'unselected-checkbox'}
        className={classNames('checkbox-icon', { 'selected-checkbox-icon': isSelected })}
      ></Icon>
      {label}
    </div>
  </Tooltip>
);

Checkbox.propTypes = {
  label: PropTypes.string,
  onToggle: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  tooltip: PropTypes.string,
  tooltipOptions: PropTypes.object,
};

Checkbox.defaultProps = {
  isDisabled: false,
};

export default Checkbox;
