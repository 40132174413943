import { Provider } from 'react-redux';
import { getStore } from './rootStore';
import React from 'react';

export function wrapWithProvider(ComponentToRender) {
  // eslint-disable-next-line react/display-name
  return (props) => (
    <Provider store={getStore()}>
      <ComponentToRender {...props} />
    </Provider>
  );
}
