Heat = require("./heat")
Balls = require("./balls")
math = require("./math")

module.exports = class @Cluster

	LEGEND_HEIGHT = 60

	constructor:(@div_id, is_story_clustering_allowed, channel)->
		@heat                = new Heat()
		@allow_pruning       = true
		@variance_correction = 32
		@layout              = d3.layout.treemap().value((d)->d.size)
		@div                 = $(@div_id)
		@svg	             = d3.select("##{@div_id} #bubbles")
		@segments            = []
		@new_segments		 = []
		@is_story_clustering_allowed = is_story_clustering_allowed
		@channel = channel
		@bind()

		@div.on("orbis.pruned_node",@on_pruned_node)



	bind: () =>
		@svg.on("click", @on_click)

	load: (@seed_idx,@concept_idx,channel) =>
		@no_results 	= d3.keys(@seed_idx).length == 0
		@channel = channel
		@on_window_resize()


	on_click: () =>
		@div.trigger($.Event("orbis.background_clicked"))

	set_pruning: (@allow_pruning) => @redraw()
	set_variance_correction:(@variance_correction) => @redraw()

	get_max_score: () =>
		return 100
		without_pruned = d3.values(@concept_idx).filter((node)=>node.id not in @pruned_ids)
		without_pruned.map((x)->x.score).reduce(((a,b)->Math.max(a,b)),0)

	get_min_score: () =>
		return -100
		without_pruned = d3.values(@concept_idx).filter((node)=>node.id not in @pruned_ids)
		without_pruned.map((x)->x.score).reduce(((a,b)->Math.min(a,b)),0)

	redraw: () =>
		@calc_heatmap()

		max = d3.values(@concept_idx).map((x)->x.value).reduce(((a,b)->Math.max(a,b)),0) # get the max value from all the concepts
		radius_adjust = d3.scale.pow().exponent(0.3).domain([0,max]).range([0,250])  	# define an exponential (0.3) scaling from [0,max] -> [0,350]  (350px = max-radius)
		for key,concept of @concept_idx
			concept.r = radius_adjust( concept.value )

		@layout.size([@width, @height - LEGEND_HEIGHT])
		@segments = @compute_subsegments()
		@new_segments = []
		@populate_with_layout_info(@segments)
		if @segments instanceof Array
			@new_segments = @segments
		else
			if !($.isEmptyObject(@segments))
				@new_segments = (val for key, val of @segments).reduce (a, b) -> a.concat b

		boxes = @svg.selectAll(".box").data( @new_segments, (x) -> x.id)
		boxes.enter().insert("g", "#heatmap_legend").attr("class", "box")

		self = @
		boxes.attr("width", (d)-> d.dx)
		.attr("height", (d)-> d.dy)
		.attr("transform", (d)->"translate(#{d.x}, #{d.y})")
		.attr("data-seed", (d)->d.id)
		.each (segment) ->
			segment.balls = new Balls( d3.select(this), segment.nodes, segment.dx, segment.dy)
			segment.balls.set_pruning( segment.allow_pruning )
			segment.balls.ball_color(self.ball_color, self.border_color).text_color(self.text_color)


		boxes.exit().remove()

		@heat.relocate(@height)
		@div.trigger($.Event("orbis.redraw"))


	# 0 <= correction <= 100
	# bring the values closer to the mean, by a proportion of their distance to it
	correct_volumes:( original, correction = @variance_correction) =>
		mean = math.mean(original)
		original.map( (x) -> x + (mean - x) * correction/100 )

	new_correct_volumes: (original, correction = @variance_correction) =>
		volumes = (val for key, val of original)
		mean = math.mean(volumes)
		for key,val of original
			original[key] = val + (mean - val) * correction/100
		original

	compute_subsegments: () =>
		keys = d3.keys(@seed_idx).sort()

		# gather all sums
		volumes = []
		for key in keys
			ids 	= @seed_idx[key].ids
			nodes 	= ids.map( (x) => @concept_idx[ x ] )
			values 	= nodes.map( (x) -> x.value )
			volumes.push( math.sum(values) )

		corrected_volumes = @correct_volumes(volumes)
		idx = 0
		segments = []
		sup_segments = {}
		for key in keys
			ids 	= @seed_idx[key].ids
			nodes = ids.map( (x) => @concept_idx[ x ] )

			clusters = {}
			clusters_amount = 0
			if (@channel == "articles")
				for n,nd of nodes
					if clusters[nd['cluster_num'][0]] == undefined
						clusters[nd['cluster_num'][0]] = 0
					clusters[nd['cluster_num'][0]] += 1
				for c,cd of clusters
					if cd >= 2
						clusters_amount += 1
			# Check for articles channel only trending seed, user has permission for story clustering and there are more than 2 clusters.
			if (@channel == "articles" && key == "trending" && keys.length == 1 && @is_story_clustering_allowed && clusters_amount > 2)
				trending_nodes = {}
				ball_color = @seed_idx[key].color
				#loop for middle topics
				for node,node_data of nodes
					sub_cluster_num = node_data['cluster_num'][1]
					if trending_nodes[sub_cluster_num] == undefined
						trending_nodes[sub_cluster_num] =[]
					trending_nodes[sub_cluster_num].push(node_data)
				t_volumes = {}
				for tt,tt_data of trending_nodes
					if t_volumes[tt] == undefined
						t_volumes[tt] = math.sum(tt_data.map((x)->x.value))
				topics_corrected_volumes = @new_correct_volumes(t_volumes)
				for tt,tt_data of trending_nodes
					if tt_data.length > 1 or Object.keys(trending_nodes).length == 1
						#organize all mids under their super, each super has it's segments.
						cluster_num = tt_data[0]['cluster_num'][0]
						if sup_segments[cluster_num] == undefined
							sup_segments[cluster_num] = []
						# # if there are lots of bubbles and more than 1 topic
						segment =
							id: "#{key}_#{tt}"
							size: topics_corrected_volumes[tt]
							nodes: tt_data
							color: ball_color
							allow_pruning: @allow_pruning
						# segments.push(segment)
						sup_segments[cluster_num].push( segment )
				idx += 1
				return sup_segments
			else
				segment =
					id: key
					size: corrected_volumes[idx]
					nodes: nodes
					color: @seed_idx[key].color
					allow_pruning: @allow_pruning

				segments.push( segment )
				idx += 1
		return segments

	populate_with_layout_info: (segments) =>
		if segments instanceof Array
			segs = segments
		else
			segs = []
			for sup, sup_data of segments
				sup_vol = 0
				for d in sup_data
					sup_vol += d.size
				segs.push({label: sup, children: sup_data, size: sup_vol})
		data =
			label: "root"
			children: segs

		@layout.nodes(data).filter( (x) -> x.depth == 2)


	show_heat:(active, kpi) =>
		if kpi then @heat.reset_kpi(kpi)
		@heat.reset_active(active)
		if active
			$("#" + @div_id).removeClass()
			$("#" + @div_id).addClass(@heat.get_kpi_class())
		@calc_heatmap()

	set_heat:(active, kpi) =>
		@heat.reset_active(active)
		@heat.reset_kpi(kpi)

	on_window_resize: () =>
		@pruned_ids = []
		viewport_height 	= $(window).innerHeight()
		navbar_height 		= $(".navbar").outerHeight()

		# fixed value set due to miscalculation in safari browser
		selection_height = 92 #$("#selection").outerHeight()
		context_height = 90 #$("#context").outerHeight()

		$("#{@div_id}").css("height", viewport_height - selection_height )
		$("#{@div_id} .bubble").css("height", viewport_height -  context_height - navbar_height - selection_height)
		@width 	= @svg[0][0].parentNode.clientWidth
		@height	= viewport_height -  context_height - navbar_height - selection_height
		@redraw()

	on_pruned_node: (event) =>
		@pruned_ids.push(event.node.id)
		@calc_heat_called = @calc_heat_called || setTimeout(@calc_heatmap,1)



	get_heat_field: (node) =>
		if @heat.is_velocity_kpi() then	node.score else	node.audience

	calc_heatmap: () =>
		without_pruned = d3.values(@concept_idx).filter((node)=>node.id not in @pruned_ids)
		@heat.set_range(@get_min_score(),@get_max_score(), without_pruned.map((x)=>@get_heat_field(x)))
		@new_segments.forEach (segment)=>segment.balls.ball_color(@ball_color, @border_color).text_color(@text_color)
		@calc_heat_called = false

	ball_color:(d)  =>
		return "#{@heat.scale( +@get_heat_field(d) )} heat-map-bubble" if @heat.active
		"#{@seed_idx[d.seed] && @seed_idx[d.seed].color} bubble"

	border_color:(d)  =>
		return 'heat-map' if @heat.active
		@seed_idx[d.seed] && @seed_idx[d.seed].color


	text_color:(d) =>
		if @heat.active
			if d.seed.toLowerCase() == d.word.toLowerCase()
				return 'heat-map-text-seed'
			else
				return 'heat-map-text'
		color = @seed_idx[d.seed] && @seed_idx[d.seed].color
		return color + " bubble-text"
