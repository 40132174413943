import React, { useEffect, useRef, useState } from 'react';
import { number, string } from 'prop-types';
import classNames from 'classnames';
import './TextArea.scss';
import { clampLines } from 'react/utils/DomUtils';

const TextArea = ({ text, lineHeight, lines, className }) => {
  const el = useRef(null);
  const [expanded, setExpanded] = useState(false);
  const [clampedText, setClampedText] = useState(text);
  useEffect(() => {
    if (el.current) {
      const clampedText = clampLines(el.current, lines, text, buttonText);
      setClampedText(clampedText);
    }
  }, [el]);
  const buttonText = expanded ? ' Show Less' : ' Show More';
  const finalText = expanded ? text : clampedText;
  const showButton = expanded || finalText !== text;
  return (
    <div
      className={classNames('text-area-component', className, expanded ? 'expanded' : 'collapsed')}
      style={{ lineHeight }}
    >
      <div ref={el} className="text">
        {finalText}
      </div>
      {showButton ? (
        <div className="button" onClick={() => setExpanded(!expanded)}>
          {buttonText}
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

TextArea.propTypes = {
  className: string.isRequired,
  lineHeight: string.isRequired,
  lines: number.isRequired,
  text: string.isRequired,
};

TextArea.defaultProps = {
  className: '',
};

export default TextArea;
