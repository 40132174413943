import Orbis from 'common/bubbles/orbis';
import concept_manager from 'common/bubbles/concept-manager';
import * as MixpanelInterests from '../../react/infra/mixpanel/MixpanelInterests';
import getLogoFallback from '../../react/utils/LogoUtils';

const tooltip = require("common/tooltip/tooltip");
const viewSwitcher = require("common/view-switcher/view-switcher");
const exclusionService = require("./exclusions-dialog/exclusions-dialog");
const BaseWidget = require("../base_widget");
const c = require("infra/utils/common");

BubblesWidgetCtrl.$inject = ["$scope", "$state", "Interests", "examplesPopupService", "quickRefinePopupService",
                             "conceptsService", "exclusionService", "context", "$timeout", "$window",
                             "topicsTree", "util", "abiPermissions", "geoService",
                             "errorsLogger", "SIGNS", "TIMES", "$location",
                             "interestsExportService", "Excel", "$q", "$parse"];
function BubblesWidgetCtrl($scope, $state, interestsService, examplesPopupService, quickRefinePopupService,
                           conceptsService, exclusionService, context, $timeout, $window, topicsTree,
                           util, abiPermissions, geoService, errorsLogger, SIGNS, TIMES, $location,
                           interestsExportService, Excel, $q, $parse) {

    var self = this;

    this.conceptsService = conceptsService;
    this.Interests = interestsService;
    this.examplesPopupService = examplesPopupService;
    this.quickRefinePopupService = quickRefinePopupService;
    this.exclusionService = exclusionService;
    this.topicsTree = topicsTree;
    this.errorsLogger = errorsLogger;
    this.SIGNS = SIGNS;
    this.context = context;
    this.util = util;
    this.abiPermissions = abiPermissions;
    this.TIMES = TIMES;
    this.$location = $location;
    this.geoService = geoService;

    var orbis = new Orbis("balls", abiPermissions.hasPermission(['story clustering']), this.context.current.bubblesChannels.value);
    $scope.orbis = orbis;
    $scope.$state = $state;
    $scope.context = context; // passing context to bubbles filter bar
    $scope.concept_manager = new concept_manager();
    orbis.on("orbis.clicked_node", this.onClickNode.bind(this));
    this.isShowVelocity = false;
    this.displayed_notifications = {};
    this.$timeout = $timeout;
    this.$window = $window;
    this.$scope.hasEmptyTerms = false;
    this.$scope.cacheBaster = null;
    this.$scope.noData = false;
    this.$scope.lifestyle = null;

    this.$scope.$root.createExcelWorkbook = interestsExportService.getInterestsExcelWorkbook.bind(null, interestsService, Excel, $q, $parse);

    MixpanelInterests.trackPageView('bubbles');

    this.Interests.config = {
        isRisingOnly: $scope.rising_only
    };

    const unregisterRisingOnly = this.$scope.$root.$on('bubbles-rising-only-toggle', function(event, isRisingOnly) {
        self.Interests.config = {
            isRisingOnly: isRisingOnly
        };
        self.loadBubblesResults();
    });

    const unregisterShowVelocityToggle = this.$scope.$root.$on('bubbles-show-velocity-toggle', function(event, isShowVelocity) {
        self.isShowVelocity = isShowVelocity;
        toggleHeatMap(isShowVelocity);
    });

    const toggleHeatMap = (isShowVelocity) => {
        if (isShowVelocity && $scope.lifestyle) {
            $scope.lifestyle = null;
            self.$scope.orbis.show_velocity_overlay(isShowVelocity);
            self.drawBubbles();
        } else {
            let hasBubbles = !_.isEmpty($scope.concept_manager.seed_idx);
            self.$scope.orbis.show_velocity_overlay(isShowVelocity && hasBubbles);
        }
    };

    const openExclusionsModal = () => self.exclusionService.showModal($scope.program.id);


    const unregisterBubblesOpenExclusions = this.$scope.$root.$on('bubbles-open-exclusions', openExclusionsModal);

    const unregisterOnLifestyleChange = this.$scope.$root.$on('bubbles-on-lifestyle-change', function(event, lifestyle) {

        $scope.lifestyle = lifestyle;
        self.Interests.config = {
            lifestyle: lifestyle
        };
        if (!lifestyle && self.isShowVelocity) return;
        if (lifestyle && self.isShowVelocity) self.isShowVelocity = false;
        self.startLoader()
        self.loadBubblesResults().then(function(res){ self.stopLoader() }, function(res){ self.stopLoader() });
        MixpanelInterests.trackAudienceBubblesSkew(lifestyle);
    });

    angular.element($window).bind('resize', function () {
        self.$scope.$apply();
    });

    this.trackBubbleClicked = function (bubble) {
        MixpanelInterests.trackBubbleClicked(bubble);
    };

    var refreshViewListener = $scope.$root.$on('refresh-view', function (event) {
        self.updateMe();
    });


    this.$scope.$on('$destroy', () => {
        refreshViewListener();
        unregisterBubblesOpenExclusions();
        unregisterRisingOnly();
        unregisterOnLifestyleChange();
        unregisterShowVelocityToggle();
    });
}

BubblesWidgetCtrl.prototype.updateMe = function () {
    var self = this;
    if (self.$scope.$state.current.name.indexOf('bubbles') == -1) return;

    self.$scope.cacheBaster = Date.now();
    self.update(null, null);
};

BubblesWidgetCtrl.prototype._getOffset = function () {
    if (this.abiPermissions.hasPermission('discovery timeframe extension')) return this.TIMES.EXTENDED_INTERESTS_OFFSET;

    return this.context.current.bubblesShowCustomSources
      ? this.TIMES.INTERESTS_CUSTOM_OFFSET
      : this.TIMES.INTERESTS_OFFSET;
}

BubblesWidgetCtrl.prototype._getRange = function () {
    return this.context.current.bubblesShowCustomSources
      ? this.TIMES.INTERESTS_CUSTOM_MAX_SPAN
      : this.TIMES.INTERESTS_MAX_SPAN;
}

BubblesWidgetCtrl.prototype._isBubblesChannelsValid = function (bubblesChannels, bubblesChannelsFilter) {
    if (bubblesChannels == null) return false;
    const bubblesChannelsArr = _.castArray(bubblesChannels);
    if (!bubblesChannelsArr.length) return false;
    for (const channel of bubblesChannelsArr) {
        if (c.isNotBelongToPermissionGroup(bubblesChannelsFilter, channel.value)) return false;
        if (channel.permission && !this.abiPermissions.hasPermission(channel.permission)) return false;
    }
    return true;
}

BubblesWidgetCtrl.prototype._doUpdate = function (newVals, oldVals) {
    if (this.cancelPromise) this.cancelPromise();

    if (!this._isBubblesChannelsValid(this.$scope.bubblesChannels, this.$scope.$root.bubblesChannelsFilter)) {
        if (this.context.current.bubblesShowCustomSources){
            // Select all custom sources channels
            this.$scope.bubblesChannels = []
        } 
        else {
            this.$scope.bubblesChannels  = c.getAvailableChannelFromContext(this.$scope.$root.bubblesChannelsFilter, this.abiPermissions);
        }   
    }

    this.$scope.$root.topicsDistribution = null;
    const self = this;
    const currentCtx = this.context.current;

    if (this.$location.path() === "/interests/bubbles") { //Don't set timeframe vars if we're stepping out of widget
        let times = ["1D", "3D", "1W", "1M"];
        if(this.$scope.bubblesChannels.value !== "facebook" && !currentCtx.bubblesShowCustomSources){
            times.push("3M");
        } else if (_.isEqual(currentCtx.interests_timeframe, [3, "month"])) {
            currentCtx.interests_timeframe = [1, "month"];
        }

        const offset = this._getOffset();
        const maxSpan = this._getRange();
        this.$scope.$root.$broadcast('timeframe-vars-update', times, offset, false, false, false, maxSpan);
    }
    self.$scope.hasEmptyTerms = false;
    self.$scope.noData = false;

    let audience = c.getAudience(this.$scope, this.$scope.bubblesChannels.value);
    if (this.$scope.bubblesChannels.value === 'sg_telco' && !this.abiPermissions.hasPermission('sg telco audience filters')) {
      audience = [];
    }

    this.Interests.config = {
        topics: currentCtx.topics,
        sensitiveContent: this.topicsTree.isSensitive,
        timeframe: currentCtx.interests_timeframe,
        geos: this.geoService.geosForChannel(currentCtx.geo, self.$scope.$state, self.context),
        sub_geos: this.geoService.subGeosForChannel(currentCtx.interestsSubGeos, self.$scope.$state, self.context),
        audience: audience ? audience.filter(function (a) {
            return a && a.value
        }) : null,
        terms: currentCtx.terms,
        isKeyTrends: currentCtx.keyTrends,
        program: this.context.program,
        channels: currentCtx.channels,
        bubblesChannels: currentCtx.bubblesChannels,
        cacheBaster: self.$scope.cacheBaster,
        lifestyle: this.$scope.lifestyle,
        language: this.context.current._language_mold.getLanguage(self.$scope.$state, this.context).value,
        isCustom: currentCtx.bubblesShowCustomSources
    };

    if(this.abiPermissions.hasPermission('first party segments')){
        this.Interests.config.user_first_party_audience = self.$scope.userFirstPartyAudience;
    }

    return self.loadBubblesResults();
};

BubblesWidgetCtrl.prototype.onSelectNode = function onSelectNode(event) {
    var selected_word = JSON.parse(event.target.dataset.node_data).word;
    if (this.$scope.terms.length >= 7) {
        this.notificator.notify({body: "Sorry, the interest bar is limited to 7 seed interests"});
        this.$scope.$apply()
    } else {
        $(angular.element.find(".example-popup")).remove();
        this.$scope.addTerm(selected_word);
    }
};

BubblesWidgetCtrl.prototype.onDeleteNode = function onDeleteNode(event) {
    // todo: look on that for stale data on service
    console.log(event.target.dataset);
    var node_data = JSON.parse(event.target.dataset.node_data);
    var concept_manager = this.$scope.concept_manager;
    concept_manager.remove_concept(node_data.id);
    this.Interests.removeExcludedConceptFromBubblesCache(node_data.id);
    this.drawBubbles();
    this.conceptsService.delete(this.$scope.program.id, node_data.seed, node_data.word);
};

BubblesWidgetCtrl.prototype.onRefineSeed = function onRefineSeed(event) {
    var bindings = {
        required: this.handleRefineSeed.bind(this),
        included: this.handleRefineSeed.bind(this),
        excluded: this.handleRefineSeed.bind(this)
    };

    this.quickRefinePopupService.activate($(event.target), {data: JSON.parse(event.target.dataset.node_data)}, bindings);
};

BubblesWidgetCtrl.prototype.getTermByClass = function getTermByClass(clazz) {
    var currentTerm = this.Interests.config.terms.filter(function (term) {
        return term.class == clazz;
    });

    return currentTerm[0];
};

BubblesWidgetCtrl.prototype.getTermIndex = function (term) {
    return this.Interests.config.terms.indexOf(term);
};

BubblesWidgetCtrl.prototype.handleRefineSeed = function handleRefineSeed(event) {
    var inputBarController = angular.element("input-bar").controller('inputBar');
    var data = JSON.parse(event.target.dataset.node_data);
    var node_data = data.data;
    var booleanFields = {};
    var term = this.getTermByClass(node_data.class);
    booleanFields[data.field] = {class: term.class, id: parseInt(node_data.id), invalid: false, text: node_data.word, type: 'term'};

    inputBarController.editPhrase(this.getTermIndex(term), event, {}, booleanFields);
};

BubblesWidgetCtrl.prototype.markBubble = function(event){
    let bubble = $(event.target),
        cls = bubble.attr("class");
    if(cls.includes("marked")){ //jqeury cant addClass to svg
        bubble.attr("class", cls.replace("shift-marked", '').trim());
    }else{
        bubble.attr("class", cls+" shift-marked");
    }

    document.getSelection().removeAllRanges(); //unmark text after shift
};

BubblesWidgetCtrl.prototype.onClickNode = function onClickNode(event) {
    if(this.$scope.$parent.shiftDown){
        this.markBubble(event);
        $(angular.element.find(".example-popup")).remove();
        return;
    }

    const examples = (event.node.articles || []).map((example) => {
        return {
            image_url: example.image_url,
            image_fallback: getLogoFallback(example.url) || '/images/common/no-available-image-tweet.svg',
            desc: example.title,
            link_url: example.url,
            link_title: example.domain,
            user: example.user
        };
    });

    var segment = this.$scope.lifestyle;

    var data = {
        node_data: {
            word: event.node.word,
            seed: event.node.seed.replace(this.SIGNS.POST, ""),
            id: event.node.id,
            class: event.node.color
        },
        title: event.node.word,
        consumption: event.node.score,
        audience: this.$scope.lifestyle && this.$scope.lifestyle.value ? event.node.audience : null,
        segment: segment ? segment.label : null,
        examples: examples
    };

    var bindings;
    if (!event.node.isSeed) {
        bindings = {select: this.onSelectNode.bind(this), 'quick-refine': this.onRefineSeed.bind(this), trash: this.onDeleteNode.bind(this)};
    }

    this.examplesPopupService.activate($(event.target), data, bindings);
    this.trackBubbleClicked(data.title);
};

BubblesWidgetCtrl.prototype.loadBubblesResults = function loadBubblesResults() {
    var self = this, $scope = this.$scope;

    var promises = this.Interests.getBubbles();
    this.cancelPromise = promises.cancel;

    return promises.promise.then(function (result) {
        $scope.$root.topicsDistribution = result.topicsDistribution;
        const bubbles = result.bubbles;
        const conceptManager = $scope.concept_manager;
        conceptManager.reset();
        const terms = self.Interests.config.terms.concat({text: 'trending', class: 'term-trending'});
        const channel = self.context.current.bubblesChannels.value;

        if (channel !== "facebook") {
            const socialTerms = _(self.context.current.terms).filter(
              (term) => _.includes(['post', 'hashTag', 'mention'], term.type) || self.util.isSocialBooleanLogic(term)
            ).map('text').value();
            if (socialTerms.length) {
                self.notify('Sorry, @mentions, @posts and #hashtags are supported only for Facebook channel ('
                    + _(socialTerms).map(self.util.getTermDisplayShort).join() + ')');
            }
        }

        let noDataConcepts = [];
        let noDataSeeds = [];
        $.each(bubbles, function (seed, values) {
            let color;
            try {
                color = _.find(terms, {text: seed}).class;
            } catch (error) {
                self.errorsLogger.logError(error, {seed: seed, values: values, terms: terms, config: self.Interests.config, bubbles: bubbles});
                return;
            }

            const concepts = values.concepts;
            const seedObject = _.keys(concepts).length == 1 ? concepts[Object.keys(concepts)[0]] : {};

            if (_.isEmpty(concepts)) {
                const channel = self.context.current.bubblesChannels.value;

                if (channel == 'facebook' || (seed[0] != '#' && seed[0] != '@' && !seed.startsWith(self.SIGNS.POST))) {
                    self.notify("No concepts found for " + self.util.getTermDisplayShort(seed));
                } else {
                    noDataSeeds.push(self.util.getTermDisplayShort(seed));
                }
            } else if (!_.isEmpty(seedObject) && _.isEmpty(seedObject.articles)) {
                noDataConcepts.push(self.util.getTermDisplayShort(seed));
            }

            conceptManager.add_concepts(seed, concepts, color);
        });

        if (!_.isEmpty(noDataConcepts)) {
            let insufficientDataMsg = "Sorry, insufficient content about <seed>. Please try more general interests or filters.";
            self.notify(insufficientDataMsg.replace("<seed>", noDataConcepts.join(", ")));
        }

        self.drawBubbles();
        self.$scope.hasEmptyTerms = self.Interests.config.terms.length === 0 && !self.Interests.config.isKeyTrends;
        self.$timeout(function () {
            // prevent empty screen msg to show before bubbles animation ends
            self.$scope.noData = self.$scope.hasEmptyTerms ? false : noDataSeeds.length == self.Interests.config.terms.length && !self.Interests.config.isKeyTrends;
        }, 800);
    })
};

BubblesWidgetCtrl.prototype.notify = function (message) {
    if (!this.displayed_notifications[message]) {
        this.displayed_notifications[message] = true;
        this.notificator.notify({body: message});
        const self = this;
        this.$timeout(function () {
            delete self.displayed_notifications[message];
        }, 3000);
    }
};

BubblesWidgetCtrl.prototype.drawBubbles = function drawBubbles() {
    var $scope = this.$scope;
    var concept_manager = $scope.concept_manager;
    var concepts_res = concept_manager.concept_idx;
    var seeds_res = concept_manager.seed_idx;
    $scope.orbis.set_heat(!_.isEmpty(concepts_res) && ($scope.lifestyle || this.isShowVelocity), $scope.lifestyle ? "audience" : "score");
    if ($scope.lifestyle && !_.isEmpty(concepts_res)) $scope.orbis.show_audience_overlay(true);

    $scope.orbis.load(seeds_res, concepts_res, $scope.bubblesChannels.value);

    tooltip($("#balls .node"),
        'info no solo',
        {
            events: {
                show: function (event, api) {
                    if (!$(event.originalEvent.target).closest(".node").is(".pruned"))
                        event.preventDefault()
                }
            },
            style: {classes: 'common-tooltip-info', tip: {width: 10, height: 5}},
            position: {my: 'bottom center', at: 'center', adjust: {y: 5, method: 'shift'}}, hide: {delay: 100, event: 'mouseleave click', fixed: true}
        },
        true
    );
};

export default angular.module(__filename, [
    require("../../data/interests-service").name,
    require("../../common/examples-popup/examples-popup").name,
    require("../../common/examples-popup/sentiments-popup").name,
    require("../../common/examples-popup/quick-refine-seed").name,
    require("../../data/concepts-service").name,
    exclusionService.name,
    viewSwitcher.name,
    require("data/audience-mgmt").name
]).directive("bubblesWidget", [function () {
    return BaseWidget({
        restrict: "E",
        template: require("./bubbles-widget.html"),
        scope: {
            timeframe: "=",
            terms: "=",
            program: "=",
            keyTrends: "=",
            updateKeyTrends: "=",
            addTerm: "=",
            topics: "=",
            geo: "=",
            language: "=",
            sub_geos: "=subGeos",
            audience: "=",
            sgTelcoAudience: "=",
            userFirstPartyAudience: "=",
            allTopics: "=",
            showCustomSources: "=",
            bubblesChannels: "="
        },
        controller: BubblesWidgetCtrl,
        controllerAs: 'bubblesWidgetCtrl'
    });
}]);
