import 'angular-modal-service';

module.exports = angular.module(__filename, [
    'angularModalService',
    require('common/modals/confirmation/confirm-action.modal.service.js').name,
]).controller('organizationEditController', ['$scope', 'organization', 'permissions', 'countries', 'close', '$q', 'service', 'notificator', 'confirmAction','geoService',
    function ($scope, organization, permissions, countries, close, $q, service, notificator, confirmAction) {

        $scope.close = close;
        $scope.saving = false;

        $scope.init = function() {
          if (organization.id == undefined) {
            organization.max_users = 100;
            organization.max_admins = 5;
            $scope.action = "create";
          } else {
            $scope.action = "update";
          }
          $scope.organization = angular.copy(organization);
          $scope.permissions = permissions;
          $scope.sensitiveContentPermId = (_.find($scope.permissions, {"name" : "Include sensitive content by default"}) || {}).id;
          $scope.countries = countries;
          $scope.organization.permission_ids = _($scope.permissions).filter('on').map('id').value();
          $scope.organization.country_ids = _($scope.countries).filter('selected').map('id').value();
          $scope.initialOrg = angular.copy($scope.organization);
        };

        $scope.save = function(org) {
            if (org.id) {
                $scope.update(org);
            } else {
                $scope.create(org);
            }
        };

        $scope.create = function(org) {
            service.create(org).then(function (data) {
                notificator.success({body: data.name + ' was saved successfully'});
                close({reload: true});
            });
        };

        function executeUpdate(value) {
          var scope = value.scope, org = value.org;
          scope.saving = true;
          service.update(org.id, org).then(function (data) {
            if (data.reset_users.length > 0){
              notificator.success({body: data.name + ' was updated successfully. Geos were reset for the following users: ' + data.reset_users.toString()});
            } else {
              notificator.success({body: data.name + ' was updated successfully'});
            }
            close({reload: true});
          });
        }

        function checkRemovedPermissions(value) {
          let scope = value.scope, org = value.org;
          return $q(function(resolve, reject) {
            let removedPermissions = _.difference(scope.initialOrg.permission_ids, scope.organization.permission_ids);
            let hasRemovedPermissions = !_.isEmpty(removedPermissions);
            let isSensitiveContentPermissionRemoved = _.includes(removedPermissions, $scope.sensitiveContentPermId);
            let removedGeos = _.difference(scope.initialOrg.country_ids, scope.organization.country_ids);
            let hasRemovedGeos = !_.isEmpty(removedGeos);
            if (hasRemovedPermissions || hasRemovedGeos) {
              let confirmationMsg = "Removed permissions will no longer be available on users' settings for all users in organization '" +
                                    org.name + "'." +
                                    (isSensitiveContentPermissionRemoved ?
                                      "\n\nNote - sensitive content settings of users' existing programs will not be affected." : "");
              let txtConfirm      = "OK";
              let txtCancel       = "Cancel";
              let txtTitle        = "Apply Permissions";
              confirmAction.getConfirmation(confirmationMsg, txtConfirm, txtCancel, txtTitle).then(function (modal) {
                modal.close.then(function (confirmation) {
                  if (confirmation) {
                    resolve({scope: scope, org: org});
                  } else {
                    reject({scope: scope, org: org});
                  }
                });
              });
            } else {
              resolve({scope: scope, org: org});
            }
          });
        }

        function checkNewPermissions(value) {
          let scope = value.scope, org = value.org;
          return $q(function(resolve, reject) {
            let newPermissions = _.difference(scope.organization.permission_ids, scope.initialOrg.permission_ids);
            let s = (newPermissions.length > 1) ? 's' : '';
            let hasNewPermissions = !_.isEmpty(newPermissions);
            let isSensitiveContentPermissionAdded = _.includes(newPermissions, $scope.sensitiveContentPermId);
            let newGeos = _.difference(scope.organization.country_ids, scope.initialOrg.country_ids);
            let hasNewGeos = !_.isEmpty(newGeos);
            if (hasNewPermissions || hasNewGeos) {
              let confirmationMsg = "Apply new permission" + s + " to all '" + org.name + "' users?";
              let txtConfirm      = "Yes";
              let txtCancel       = "No";
              let txtTitle        = "Apply Permissions";
              confirmAction.getConfirmation(confirmationMsg, txtConfirm, txtCancel, txtTitle).then(function (modal) {
                modal.close.then(function (confirmation) {
                  org.apply_new_permissions = confirmation;
                  resolve({scope: scope, org: org});
                });
              });
            } else {
              resolve({scope: scope, org: org});
            }
          });
        }

        function checkSensitiveContentPermissionAdded(value) {
          let scope = value.scope, org = value.org;
          return $q(function(resolve, reject) {
            let newPermissions = _.difference(scope.organization.permission_ids, scope.initialOrg.permission_ids);
            let isSensitiveContentPermissionAdded = _.includes(newPermissions, $scope.sensitiveContentPermId);
            if (org.apply_new_permissions && isSensitiveContentPermissionAdded) {
              let confirmationMsg = "Would you like to update all users' existing programs to include sensitive content?";
              let txtConfirm      = "Yes";
              let txtCancel       = "No";
              let txtTitle        = "Sensitive Content";
              confirmAction.getConfirmation(confirmationMsg, txtConfirm, txtCancel, txtTitle).then(function (modal) {
                modal.close.then(function (confirmation) {
                  org.update_programs = confirmation;
                  resolve({scope: scope, org: org});
                });
              });
            } else {
              resolve({scope: scope, org: org});
            }
          });
        }
 
        $scope.update = function(org) {
          checkRemovedPermissions({scope: $scope, org: org}).then(checkNewPermissions).then(checkSensitiveContentPermissionAdded).then(executeUpdate);
        };

        $scope.init();
    }
]);
