import config from '../../infra/config';
const HOST = config.USER_MGMT_API;

export default {
  getEstimatedReach: (phrases, topics, geo) => ({
    url: `${HOST}/targets/reach`,
    payload: { kw: phrases, topics, geo },
    method: 'post',
  }),
};
