module.exports = angular.module(__filename, [])
  .service('audienceExplorerExportService', [ 'Excel', 'segmentInterestExportService', function (Excel, segmentInterestExportService) {

    function createTvShowsWorksheet(workbook, interests, formatter, title, filteredByNetworks = []) {
      const worksheet = workbook.createWorksheet({name: title});

      const titleLine = [
        formatter(title.slice(0, title.lastIndexOf('s')), 'bold'),
        formatter('Consumption In Audience', 'bold'),
        formatter('Audience Portion In Consumption', 'bold'),
        formatter('Audience Skew', 'bold'),
        formatter('Significance Score', 'bold')
      ];

      let data = interests.map(interest => {
        const arr = [
          formatter(interest.title, 'normal'),
          formatter(interest['interest-portion'] / 100, 'percent'),
          formatter(interest['segment-portion'] / 100, 'percent'),
          formatter(interest['uniqueness-index'], 'numeric'),
          formatter(interest['score'], 'numeric')
        ];
        return arr;
      });

      data.unshift(titleLine);

      let filteredByNetworksFormatted = [formatter('Filtered By Networks', 'bold')].concat(filteredByNetworks.map(network => formatter(network.label, 'normal')));
      worksheet.setData(_.isEmpty(filteredByNetworks) ? data : Excel.insertDataAtColumn(data, filteredByNetworksFormatted, titleLine.length + 2));
      const columnWidths = [20, 18, 18, 18, 18, 15, 20, 12, 20];
      worksheet.setColumns(_.map(columnWidths, (width) => ({ width })));
      workbook.addWorksheet(worksheet);
    }

    function momentBasedFileNamerGen(baseName, extension = 'xlsx') {
      return `${baseName} ${moment().toString()}.${extension}`;
    }

    function exportToExcel(tvShowsExportData, audienceInterestExportData, mutualExportData, showTvTab) {
      const {segment, demographicsData, channel} = mutualExportData;
      const {topics, websites, phrases, searches} = audienceInterestExportData;
      const workbook = segmentInterestExportService.exportToExcel(topics, websites, phrases, searches, segment, demographicsData, channel);
      const formatter = Excel.formater(workbook);

      if(showTvTab) {
        const {data, withNetworks, filteredByNetworks} = tvShowsExportData;
        createTvShowsWorksheet(workbook, data?.tv || [], formatter, 'TV Shows', filteredByNetworks);
        createTvShowsWorksheet(workbook, data?.genres || [], formatter, 'Genres', filteredByNetworks);
        if (withNetworks) createTvShowsWorksheet(workbook, data?.networks || [], formatter, 'Networks');
        workbook.fileName = momentBasedFileNamerGen('Audience Exploration');
      }

      return workbook;
    }

    return {
      exportToExcel
    };
    }]
  );
