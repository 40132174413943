import React from 'react';
import './HeaderBar.scss';
import PropTypes from 'prop-types';

const HeaderBar = ({ children }) => <div className="header-bar-component">{children}</div>;

HeaderBar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};

export default HeaderBar;
