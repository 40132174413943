import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, omitBy } from 'lodash';
import './TvShowsModal.scss';
import ModalWithConfirmationButtons from '../../../../../common/Modal/ModalWithConfirmationButtons/ModalWithConfirmationButtons';
import Dropdown from '../../../../../common/Dropdown/Dropdown/Dropdown';
import TvCustomTimeframe from '../../TvCustomTimeframe/TvCustomTimeframe';
import Separator from '../../../../../common/Separator/Separator';
import SelectToggle from '../../../../../common/SelectToggle/SelectToggle';
import ClickableIcon from '../../../../../common/ClickableIcon/ClickableIcon';
import {
  isSmartTvChannel,
  LEVELS_OF_EXPOSURE,
  LEVEL_OF_EXPOSURE_TV_SHOWS_TOOLTIP,
  dateToString,
  getSummaryTextBuilder,
} from '../../../../../../../data/audience-segment-builder-helper';

const TvShowsModal = ({
  isOpen,
  modalTitle,
  onSubmit,
  onCancel,
  tvShowsInput,
  tvNetworksMetadataPromise,
  tvGenresMetadataPromise,
  tvShowsMetadataPromise,
  channel,
  isTimeframeVisible,
}) => {
  const [selectedNetworks, setSelectedNetworks] = useState(tvShowsInput.networks || []);
  const [selectedGenres, setSelectedGenres] = useState(tvShowsInput.genres || []);
  const [selectedShows, setSelectedShows] = useState(tvShowsInput.tv || []);
  const [selectedStartDate, setSelectedStartDate] = useState(tvShowsInput.startDate);
  const [selectedEndDate, setSelectedEndDate] = useState(tvShowsInput.endDate);
  const [selectedTimeframe, setSelectedTimeframe] = useState(tvShowsInput.timeframe);
  const [isTimeframeDisabled, setIsTimeframeDisabled] = useState(
    isEmpty(tvShowsInput.networks) && isEmpty(tvShowsInput.tv)
  );
  const [levelOfExposure, setLevelOfExposure] = useState(tvShowsInput.exposure);

  const onNetworksSelect = (networks) => {
    setSelectedNetworks(networks);
    changeIsTimeframeDisabled({ networks });
  };

  const onGenresSelect = (genres) => {
    setSelectedGenres(genres);
  };

  const onShowsSelect = (shows) => {
    setSelectedShows(shows);
    changeIsTimeframeDisabled({ shows });
  };

  const changeIsTimeframeDisabled = ({ networks = selectedNetworks, shows = selectedShows }) => {
    const isTimeframeDisabledCurrent = isEmpty(networks) && isEmpty(shows);
    if (isTimeframeDisabledCurrent != isTimeframeDisabled) setIsTimeframeDisabled(isTimeframeDisabledCurrent);
  };

  const handleSubmit = useCallback(() => {
    const exposure = !isEmpty(selectedNetworks) || !isEmpty(selectedShows) ? levelOfExposure : [];
    const segment = {
      ...tvShowsInput,
      type: 'tvShows',
      networks: selectedNetworks,
      genres: selectedGenres,
      tv: selectedShows,
      ...(selectedStartDate && !isTimeframeDisabled && { startDate: dateToString(selectedStartDate) }),
      ...(selectedEndDate && !isTimeframeDisabled && { endDate: dateToString(selectedEndDate) }),
      ...(selectedTimeframe && { timeframe: selectedTimeframe }),
      ...(isSmartTvChannel(channel) && { exposure }),
    };
    if (!selectedTimeframe) {
      delete segment.timeframe;
    }
    const removeEmptyTvFilters = omitBy(
      segment,
      (val, key) => ['networks', 'genres', 'tv'].includes(key) && isEmpty(val)
    );
    onSubmit(removeEmptyTvFilters);
  }, [
    tvShowsInput,
    selectedNetworks,
    selectedGenres,
    selectedShows,
    selectedStartDate,
    selectedEndDate,
    selectedTimeframe,
    levelOfExposure,
  ]);

  const handleCancel = () => onCancel('tvShows');

  const isSubmitDisabled = () => isEmpty(selectedNetworks) && isEmpty(selectedGenres) && isEmpty(selectedShows);

  return (
    <div className="tv-shows-modal-component">
      <ModalWithConfirmationButtons
        width="592px"
        modalTitle={modalTitle}
        isOpen={isOpen}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        onXClick={handleCancel}
        isSubmitDisabled={isSubmitDisabled()}
        isDisabledEnterKeyPress={isSubmitDisabled()}
        isLightTheme={true}
        isYAutoScrollEnabled={false}
      >
        <div className="tv-shows-modal-content">
          <div className="modal-row">
            <div className="row-label">Networks:</div>
            <Dropdown
              promise={tvNetworksMetadataPromise}
              selectedValues={selectedNetworks}
              isMulti
              isSearchable
              summaryTextBuilder={getSummaryTextBuilder('All networks')}
              onSelect={onNetworksSelect}
              maxNumberOfSelectedOptions={7}
              reachMaxSelectedOptionsMsg="Sorry, this field is limited to 7 networks"
            />
          </div>
          <div className="modal-row">
            <div className="row-label">TV Shows:</div>
            <Dropdown
              promise={tvShowsMetadataPromise}
              selectedValues={selectedShows}
              isMulti
              isSearchable
              summaryTextBuilder={getSummaryTextBuilder('All shows')}
              onSelect={onShowsSelect}
              maxNumberOfSelectedOptions={20}
              reachMaxSelectedOptionsMsg="Sorry, this field is limited to 20 shows"
            />
          </div>
          {isTimeframeVisible && isSmartTvChannel(channel) && (
            <div className="modal-row">
              <div className="row-label">Timeframe:</div>
              <TvCustomTimeframe
                startDateString={selectedStartDate}
                endDateString={selectedEndDate}
                timeframe={selectedTimeframe}
                selectedStartDateCB={(selectedDate) => setSelectedStartDate(dateToString(selectedDate))}
                selectedEndDateCB={(selectedDate) => setSelectedEndDate(dateToString(selectedDate))}
                selectedTimeframeCB={setSelectedTimeframe}
                isDisabled={isTimeframeDisabled}
              ></TvCustomTimeframe>
              <Separator className="modal-bottom-separator" />
            </div>
          )}
          {isSmartTvChannel(channel) && (
            <div className="modal-row level-of-exposure">
              <div className="row-label">
                <span className="label">Level of Exposure:</span>
                <ClickableIcon iconId="info-italic" tooltip={LEVEL_OF_EXPOSURE_TV_SHOWS_TOOLTIP} />
              </div>
              <SelectToggle
                values={LEVELS_OF_EXPOSURE}
                selectedValues={levelOfExposure}
                onChange={setLevelOfExposure}
              />
              <Separator className="modal-bottom-separator exposure-separator" />
            </div>
          )}
          <div className="modal-row">
            <div className="row-label">{"User's favorite genres:"}</div>
            <Dropdown
              promise={tvGenresMetadataPromise}
              selectedValues={selectedGenres}
              isMulti
              isSearchable
              summaryTextBuilder={getSummaryTextBuilder('All genres')}
              onSelect={onGenresSelect}
            />
          </div>
        </div>
      </ModalWithConfirmationButtons>
    </div>
  );
};

TvShowsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  modalTitle: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  tvShowsInput: PropTypes.object,
  tvNetworksMetadataPromise: PropTypes.object,
  tvGenresMetadataPromise: PropTypes.object,
  tvShowsMetadataPromise: PropTypes.object,
  channel: PropTypes.string,
  isTimeframeVisible: PropTypes.bool,
};

TvShowsModal.defaultProps = {
  tvShowsInput: {},
  channel: '',
  isTimeframeVisible: true,
};

export default TvShowsModal;
