'use strict';
require('sly');

function whichTransitionEvent() {
    var t;
    var el = document.createElement('fakeelement');
    var transitions = {
        'transition': 'transitionend',
        'OTransition': 'oTransitionEnd',
        'MozTransition': 'transitionend',
        'WebkitTransition': 'webkitTransitionEnd'
    };

    for (t in transitions) {
        if (el.style[t] !== undefined) {
            return transitions[t];
        }
    }
}

//DEFAULTS
var defaultOptions = {
    itemNav: 'basic',
    smart: 1,
    activateOn: 'click',
    mouseDragging: 1,
    touchDragging: 1,
    releaseSwing: 1,
    startAt: 0,
    scrollBy: 2,
    activatePageOn: 'click',
    speed: 300,
    elasticBounds: 1,
    //easing: 'easeOutExpo',
    easing: 'linear',
    dragHandle: 1,
    dynamicHandle: 1,
    clickBar: 1,
    scrollHijack: 100
};

//DIRECTIVE PROPER
/**
 * for general lists
 */

var angularSly = module.exports = angular.module('__filename', []);
angularSly.directive('slyVertical', ['$timeout', function ($timeout) {
    return {
        restrict: 'A',
        link: function (scope, el, attrs) {
            slyDirectiveLink(scope, el, attrs, $timeout)
        }
    }
}]);

angularSly.directive('slyHorizontal', ['$timeout', function ($timeout) {
    return {
        restrict: 'A',
        $scope: {
            sly: "=?"
        },
        link: function (scope, el, attrs) {
            slyDirectiveLink(scope, el, attrs, $timeout)
        }
    }
}]);

function slyDirectiveLink(scope, el, attrs, $timeout) {
    var $frame = $(el);
    var wrap = $(el[0]).parent();
    var list = $($frame.children()[0]);
    if (!list[0]) {
        throw new Error('must be child Element to Element of sly directive');
    }
    if (attrs.slyHorizontal != void 0) {
        defaultOptions.horizontal = true;
    }
    if (attrs.slyVertical != void 0) {
        defaultOptions.horizontal = false;
    }

    var defaultControls = {
        scrollBar: wrap.find('.scrollbar') || null,
        pagesBar: wrap.find('.pages') || null,
        forward: wrap.find('.forward') || null,
        backward: wrap.find('.backward') || null,
        prev: wrap.find('.prev') || null,
        next: wrap.find('.next') || null,
        prevPage: wrap.find('.prevPage') || null,
        nextPage: wrap.find('.nextPage') || null
    };

    // Merge parts into options object for sly argument
    var options = $.extend({}, defaultOptions, defaultControls, scope.$eval(attrs.slyOptions));
    // Call Sly on frame
    var sly = new Sly($frame, options, scope.$eval(attrs.slyCallbacks))
    sly.init();
    scope.sly = sly;
    var resetSly = function() {
        $timeout(function(){
            var prevfirstItem = sly.rel.firstItem;
            sly.destroy();
            sly = new Sly($frame, _.merge({}, options, {startAt: prevfirstItem}, scope.slyOptions), scope.$eval(attrs.slyCallbacks))
            sly.init();
            scope.sly = sly;
        },0)
    };

    var savedValue = null;

    var disableWatchDog = scope.$watch(watchDog);

    /** listen to resize window and recalculate the scroll**/
    window.addEventListener('resize', onResize);
    function onResize() {
        window.requestAnimationFrame(resetSly);
    }

    function watchDog() {
        disableWatchDog();

        $timeout(function () {
            disableWatchDog = scope.$watch(watchDog);
            var curValue = list.outerHeight();
            if (savedValue != curValue) {
                savedValue = curValue;
                resetSly();

            }
        }, 0, false);
    }

    scope.$on('$destroy', function () {
        window.removeEventListener('resize', onResize);
        sly.destroy();
    });
}

angularSly.directive('slyVerticalRepeat', function () {
    return {
        restrict: 'A',
        link: function (scope, el, attrs) {
            if (scope.$last === true) {
                $timeout(function () {
                    var frame = $(el);
                    var wrap = $(el[0]).parent();
                    defaultOptions.horizontal = 0;

                    var defaultControls = {
                        scrollBar: wrap.find('.scrollbar') || null,
                        pagesBar: wrap.find('.pages') || null,
                        forward: wrap.find('.forward') || null,
                        backward: wrap.find('.backward') || null,
                        prev: wrap.find('.prev') || null,
                        next: wrap.find('.next') || null,
                        prevPage: wrap.find('.prevPage') || null,
                        nextPage: wrap.find('.nextPage') || null
                    };

                    // Merge parts into options object for sly argument
                    var options = $.extend({}, defaultOptions, defaultControls, scope.$eval(attrs.slyOptions));
                    var callback = scope.$eval(attrs.slyCallback) || function (cb) {};
                    // Call Sly on frame
                    frame.sly(options, callback());
                });
            }
        }
    }
});

/** directives for ng-repeat
 * set to the ng-repeat row
 * checks if the last item is rendered before calling sly
 */
angularSly.directive('slyHorizontalRepeat', function ($timeout) {
    return {
        restrict: 'A',
        link: function (scope, el, attrs) {
            if (scope.$last === true) {
                $timeout(function () {
                    var frame = $(el[0]).parent().parent();
                    var wrap = $(el[0]).parent().parent().parent();

                    defaultOptions.horizontal = 1;

                    var defaultControls = {
                        scrollBar: wrap.find('.scrollbar') || null,
                        pagesBar: wrap.find('.pages') || null,
                        forward: wrap.find('.forward') || null,
                        backward: wrap.find('.backward') || null,
                        prev: wrap.find('.prev') || null,
                        next: wrap.find('.next') || null,
                        prevPage: wrap.find('.prevPage') || null,
                        nextPage: wrap.find('.nextPage') || null
                    };
                    // Merge parts into options object for sly argument
                    var options = $.extend({}, defaultOptions, defaultControls, scope.$eval(attrs.slyOptions));
                    var callback = scope.$eval(attrs.slyCallback) || function (cb) {};
                    // Call Sly on frame
                    frame.sly(options, callback());
                    // scope.$emit('ngRepeatFinished');
                });
            }
        }
    }
});

//METHODS

//Positioning
angularSly.directive('slyToBegin', function () { //slyToStart doesn't seem to work :(
    return {
        restrict: 'A',
        link: function (scope, el, attrs) {
            el.on('click', function () {
                // Need to pass the sly frame element Id
                var frame = $('#' + attrs.slyFrame);
                var item = attrs.slyDataItem || undefined;

                // Animate a particular item to the start of the frame.
                // If no item is provided, the whole content will be animated.
                frame.sly('toStart', item);
            });
        }
    }
});

angularSly.directive('slyToCenter', function () {
    return {
        restrict: 'A',
        link: function (scope, el, attrs) {
            el.on('click', function () {
                // Need to pass the sly frame element Id
                var frame = $('#' + attrs.slyFrame);
                var item = attrs.slyDataItem || undefined;
                // Animate a particular item to the center of the frame.
                // If no item is provided, the whole content will be animated.
                frame.sly('toCenter', item);
            });
        }
    }
});

angularSly.directive('slyToEnd', function () {
    return {
        restrict: 'A',
        link: function (scope, el, attrs) {
            el.on('click', function () {
                // Need to pass the sly frame element Id
                var frame = $('#' + attrs.slyFrame);
                var item = attrs.slyDataItem || undefined;

                // Animate a particular item to the center of the frame.
                // If no item is provided, the whole content will be animated.
                frame.sly('toEnd', item);
            });
        }
    }
});
