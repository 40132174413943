import $ from 'jquery';
import './TvShowInfoBrick.scss';
import classNames from 'classnames';
import BaseBrick from '../BaseBrick';
import { any, string } from 'prop-types';
import Tooltip from '../../Tooltip/Tooltip';
import React, { useEffect, useRef, useState } from 'react';
import { formatNumberToUnits } from '../../../../utils/NumberUtils';
import SimpleProgressBar from '../../SimpleProgressBar/SimpleProgressBar';

function audienceConsumptionComparisonTooltip(tvShow) {
  const percentValue = tvShow['interest-portion'].toFixed(1);
  const value = tvShow['uniqueness-index'].toFixed(1);
  let uniquenessValueQuantifier;
  if (value === 1) uniquenessValueQuantifier = 'as';
  if (value > 1) uniquenessValueQuantifier = 'x' + value + ' times more';
  if (value < 1) uniquenessValueQuantifier = 'x' + (1 / value).toFixed(1) + ' times less';

  return (
    `${percentValue}% of the target audience are interested in ${tvShow.phrase}. ` +
    `Consumers of ${tvShow.phrase} are ${uniquenessValueQuantifier} likely to be in the target audience than the average consumer.`
  );
}

const TvShowInfoBrick = ({ tvShow, scoreView }) => {
  const interestPortion = formatNumberToUnits(tvShow['interest-portion'], 1, 1);
  const uniquenessIndex = formatNumberToUnits(tvShow['uniqueness-index'], 1, 1);
  const progressBarLabel = `${interestPortion}% | ${uniquenessIndex}`;
  let progressValue = scoreView === 'skew-score' ? tvShow['skew-score'] : tvShow['interest-portion-normalized'];
  progressValue = progressValue.toFixed();
  const progressBarTooltip = audienceConsumptionComparisonTooltip(tvShow);
  const [isTooltipEnabled, setIsTooltipEnabled] = useState(false);

  const titleRef = useRef();
  useEffect(() => {
    setTimeout(() => $(titleRef.current).dotdotdot({ callback: (isTruncated) => setIsTooltipEnabled(isTruncated) }));
  }, []);

  return (
    <div className="tv-show-info-brick-component">
      <Tooltip options={{ isEnabled: isTooltipEnabled }} content={tvShow['title']}>
        <div className="on-image-info" ref={titleRef}>
          {tvShow['title']}
        </div>
      </Tooltip>
      <div className={classNames('base-brick-wrapper', { 'smaller-font': tvShow['uniqueness-index'] >= 100 })}>
        <BaseBrick
          imgUrl={tvShow.image?.url}
          imgWidth={'215px'}
          imgHeight={'295px'}
          rolloverContent={<TvShowDescription tvShow={tvShow} />}
          bottomContent={
            <Tooltip content={progressBarTooltip}>
              <SimpleProgressBar progressValue={progressValue} label={progressBarLabel} />
            </Tooltip>
          }
        />
      </div>
    </div>
  );
};

TvShowInfoBrick.propTypes = {
  tvShow: any,
  scoreView: string,
};

function TvShowDescription({ tvShow }) {
  const summaryRef = useRef();
  const hasTerms = tvShow['network'] || tvShow['genre'] || tvShow['genres'];
  const shownGenres = tvShow['genres'] || tvShow['genre'];

  useEffect(() => {
    setTimeout(() => $(summaryRef.current).dotdotdot());
  }, []);

  return (
    <div className="desc-and-terms">
      <div
        ref={summaryRef}
        className={classNames('desc-container', { 'no-terms': !hasTerms }, { 'no-desc': !tvShow['summary'] })}
      >
        <div className="desc">{tvShow['summary'] && <span>{tvShow['summary'] + ' '}</span>}</div>
      </div>
      {hasTerms && (
        <div className="meta-data-container">
          {tvShow['network'] && (
            <React.Fragment>
              <div className="tv-show-meta-type">Origin Network:</div>
              <div className="tv-show-meta network">{tvShow['network']}</div>
            </React.Fragment>
          )}

          {shownGenres && (
            <React.Fragment>
              <div className="tv-show-meta-type">{`Genre${tvShow['genres'] ? 's' : ''}:`}</div>
              <div className="tv-show-meta genre">{shownGenres}</div>
            </React.Fragment>
          )}
        </div>
      )}
    </div>
  );
}

TvShowDescription.propTypes = {
  tvShow: any,
};

export default TvShowInfoBrick;
