module.exports = angular.module(__filename, [
  require("./am-sub-nav-button").name,
]).component('amSubNav', {
  template: require('./am-sub-nav.html'),
  transclude: true,
  require: {
    button: '^amNavButton'
  },
  controller: ['$scope', '$state', '$element', '$timeout', function($scope, $state, $element, $timeout) {
    var self = this;

    self.$onInit = function() {
      self.SHOW_TIMEOUT = 100;
      self.HIDE_TIMEOUT = 300;

      self.items = {};
      self.selected = null;
      self.show = false;
      self.timeout = null;

      self.unregisterMouseEnter = $scope.$on('am-nav-button:mouseenter', function(event, value) {
        self.showSubMenu(true);
      });
      self.unregisterMouseLeave = $scope.$on('am-nav-button:mouseleave', function(event, value) {
        self.showSubMenu(false);
      });
    };

    self.showSubMenu = function(show) {
      $timeout.cancel(self.timeout);
      self.timeout = $timeout(function() {
        self.show = show;
      }, show ? self.SHOW_TIMEOUT : self.HIDE_TIMEOUT);
    };

    self.register = function(button) {
      button.selected = $state.current.name == button.link;
      self.items[button.$id] = button;
      $element.removeClass("buttons-"+self.itemsCount);
      self.itemsCount = _.size(self.items);
      $element.addClass("buttons-"+self.itemsCount);
      $scope.$emit("am-sub-nav:link:amount", self.itemsCount);
      if (self.defaultLink === undefined && !button.disabled) {
        self.defaultLink = button.getLink();
        $scope.$emit("am-sub-nav:link:set", self.defaultLink);
      }
    };

    self.setSelectedButtonById = function(id) {
      self.selected = id;
      _.each(self.items, function(item) {
        item.selected = (id === item.$id);
      });
    };

    self.$onDestroy = function() {
      self.unregisterMouseEnter();
      self.unregisterMouseLeave();
    };
  }]
});
