import 'angular-modal-service';
import { setShowContactUsService } from '../../../react/middleware/AngularExportedMiddleware';

module.exports = angular.module(__filename, [
    'angularModalService',
    require('data/email-service').name,
    require('common/am-placeholder/am-placeholder').name
]).service('contactUs', ['$window', 'ModalService', 'emailService', function ($window, ModalService, emailService) {
    this.show = openContactUsModal;
    setShowContactUsService(openContactUsModal);

    function openContactUsModal(options) {
      var defaults = { showTo: true,
                       subject: null,
                       msgPlaceholder: "Questions, suggestions, anything you want to tell us...",
                       showAttachments: true };
      options = angular.merge({}, defaults, options || {});

      return ModalService.showModal({
        backdrop: false,
        template: require('./contact_us.html'),
        controller: ['$scope', '$http', 'emailService', 'close', 'showTo', 'subject', 'msgPlaceholder', 'showAttachments', ContactUsController],
        inputs: options
      });
    }

    function ContactUsController($scope, $http, emailService, close, showTo, subject, msgPlaceholder, showAttachments) {
        $scope.send = send;
        $scope.addAttachments = addAttachments;
        $scope.removeAttachment = removeAttachment;
        $scope.showTo = showTo;
        $scope.msgPlaceholder = msgPlaceholder;
        $scope.placeholder = msgPlaceholder;
        $scope.showAttachments = showAttachments;
        $scope.fields = {
            to: 'ABI_Support@amobee.com',
            from: $scope.$root.user,
            subject: subject,
            message: null,
            attachments: []
        };

        // precache x.svg for instant load on attachments.
        $http.get("/images/common/modals/contact_us/x.svg", { cache: true });

        // close modal on navigate back
        var closeListener = $scope.$root.$on("$stateChangeStart", function () {
            $scope.close({reload: false})
        });
        $scope.$on('$destroy', closeListener);

        $scope.closeOnClickOutside = function (event) {
            if (event.target.id == "contact-us-overlay") {
                $scope.close();
            }
        };

        $scope.close = close;

        function send() {
            var fields = $scope.fields;
            var promise = emailService.contactUs(fields.from.id, fields.subject, fields.message, fields.attachments);
            promise.then(function (response) {
                $scope.close();
            });
        }

        function addAttachments(element) {
            var attachments = $scope.fields.attachments;
            for (var i = 0; i < element.files.length; i++) {
                attachments.push(element.files[i]);
            }

            $scope.$apply();
        }

        function removeAttachment(index) {
            $scope.fields.attachments.splice(index, 1);
        }

        $window.addAttachments = addAttachments;
        $scope.$on('$destroy', function() {
          delete $window.addAttachments;
        })
    }
}]);
