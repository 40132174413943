import config from '../../infra/config';
import {
  getFirstPartyService,
  getContext,
  getGeoService,
  getAudienceSkewService,
  getSsoAccessToken,
  getIsMySegmentsProgram,
  getUploadService,
} from '../middleware/AngularExportedMiddleware';
import { executeHttpRequest, executeWithProxyTower } from '../infra/HttpClient';
// Avoid mixing methods naming by importing 'as'
import {
  getLinkedinSegmentDemographicsDistribution,
  getSegmentIds as getAudienceServiceSegmentIds,
  createAudienceTargetTaxonomy as createAudienceServiceTargetTaxonomy,
  createAudienceTargetUserList as createAudienceServiceTargetUserList,
  createAmplifiedAudience as createAudienceServiceAmplifiedAudience,
  createDeterministicAudience as createAudienceServiceDeterministicAudience,
  createAlwaysOnAudience as createAudienceServiceAlwaysOnAudience,
  createUserListForDeterministicAudience as createAudienceServiceUserListForDeterministicAudience,
  getAudienceTaxonomyCategory as getAudienceServiceTaxonomyCategory,
} from './AudienceService';
import * as AudienceServiceHelper from './AudienceServiceHelper';
import AbiManagementApi from '../../react/api/AbiManagementApi';
import AudienceProfilerApi from '../../react/api/AudienceProfilerApi';
import {
  convertAudienceSegmentToLogicStatement,
  convertAudienceSegmentToFilterMapping,
  geoByChannel,
  getGeoFromAudienceSegment,
  AND_LOGICAL_OPERAND,
} from '../../data/audience-segment-builder-helper';
import {
  getCachedShowsMetadataByids,
  getNielsenNetworksMap,
  getCachedCommercials,
  getCachedGenres,
  getCachedNetworks,
  getCachedShows,
  getCachedHierarchy,
  getNetworksShows,
  getCachedAdvancedGenres,
  getCachedAdvancedNetworks,
  getCachedAdvancedShows,
  getCachedCommercialsV2,
} from './TvService';
import {
  clone,
  compact,
  extend,
  find,
  fromPairs,
  includes,
  isEmpty,
  isUndefined,
  has,
  map,
  mapValues,
  noop,
  omitBy,
  pick,
  random,
  some,
  sortBy,
  uniq,
  zip,
} from 'lodash';
import ActivationApi from '../api/ActivationApi';

const TV_SEARCH_TYPES = ['tv', 'genres', 'networks'];
const TYPES_TO_SORT = ['countries', 'industries', 'regions', 'us_states'];
const FIRST_PARTY_UPLOADER = config.FIRST_PARTY_UPLOADER + '/first-party-upload';

export const createFirstPartySegment = getFirstPartyService() && getFirstPartyService().create;

export function checkLinkedinUserAccessTokenStatus(userId) {
  const key = userId;
  const linkedinAccessTokenPromiseCache = AudienceServiceHelper.getLinkedinAccessTokenPromiseCache();
  if (linkedinAccessTokenPromiseCache[key]) return linkedinAccessTokenPromiseCache[key];

  /* eslint-disable-next-line camelcase */
  const promise = executeHttpRequest(AbiManagementApi.checkLinkedinUserAccessTokenStatus({ user_id: userId }), {
    returnOnlyData: true,
  });
  AudienceServiceHelper.setLinkedinAccessTokenPromiseCache(key, promise);
  return promise;
}

export function getLinkedinMetaData(userId) {
  const key = userId;
  const linkedinMetaDataPromiseCache = AudienceServiceHelper.getLinkedinMetaDataPromiseCache();
  if (linkedinMetaDataPromiseCache[key]) return linkedinMetaDataPromiseCache[key];

  function mapMetaDataObjectToValueLabelArray(metaDataObject, type) {
    metaDataObject = map(metaDataObject, (val, key) => ({ label: val, value: key }));
    return TYPES_TO_SORT.includes(type) ? sortBy(metaDataObject, (object) => object.label) : metaDataObject;
  }
  /* eslint-disable-next-line camelcase */
  const promise = executeHttpRequest(AbiManagementApi.getLinkedinMetaData({ user_id: userId })).then((data) => {
    if (data.error && data.error.reason === 'access_token_expired') return AudienceServiceHelper.linkedinTokenExpired();
    return mapValues(data, (value, key) =>
      key === 'regions_by_state' ? value : mapMetaDataObjectToValueLabelArray(value, key)
    );
  });
  AudienceServiceHelper.setLinkedinMetaDataPromiseCache(key, promise);
  return promise;
}

export async function getTvShowsMetaData(channel) {
  const { shows } = await getCachedShows(channel);
  return shows.map((show) => ({ label: show.title, value: show.id, type: show.type }));
}

export async function getTvNetworksMetaData(channel) {
  const { networks } = await getCachedNetworks(channel);
  return networks.map((network) => ({ label: network.title, value: network.id }));
}

export async function getNetworksShowsHierarchy(channel) {
  return await getCachedHierarchy(channel);
}

export async function getTvGenresMetaData() {
  const { genres } = await getCachedGenres();
  return genres.map((genre) => ({ label: genre, value: genre }));
}

export async function getTvCommercialsMetaData(channel) {
  const commercials = await getCachedCommercials(channel);
  return commercials;
}

export async function getTvCommercialsMetaDataV2(channel, parent_brands, brands, products, geo) {
  const commercials = await getCachedCommercialsV2(channel, parent_brands, brands, products, geo);
  return commercials;
}

export async function getAdvancedTvNetworksMetaData(channel, geo) {
  const { networks } = await getCachedAdvancedNetworks(channel, geo);
  return networks.map((network) => ({ label: network.title, value: network.id }));
}

export async function getAdvancedTvGenresMetaData() {
  const { genres } = await getCachedAdvancedGenres();
  return genres.map(({ title, id, genres }) => ({
    label: title,
    value: id,
    genres,
  }));
}

export async function getAdvancedTvShowsMetaData(channel, networks, genres, geo) {
  const shows = await getCachedAdvancedShows(channel, networks, genres, geo);
  return shows;
}

export function getFirstPartyDataByProgram(program, callback = noop) {
  function getNormalizedFirstPartyRecord(data) {
    callback(data);
    return data.map(AudienceServiceHelper.normalizeFirstPartyRecord);
  }
  if (getIsMySegmentsProgram(program)) {
    return getSsoAccessToken().then((accessToken) => {
      const headers = { 'SSO-Access-Token': accessToken.accessToken };
      return executeHttpRequest(AbiManagementApi.getFirstPartyDataBySSOAccessToken(headers)).then((data) =>
        getNormalizedFirstPartyRecord(data)
      );
    });
  } else {
    return executeHttpRequest(AbiManagementApi.getFirstPartyDataByProgram(program.id)).then((data) =>
      getNormalizedFirstPartyRecord(data)
    );
  }
}

export function getFirstPartyDataByUser(userId) {
  return getSsoAccessToken().then((accessToken) => {
    const headers = { 'SSO-Access-Token': accessToken.accessToken };
    return executeHttpRequest(AbiManagementApi.getFirstPartyDataByUser(userId, headers)).then((data) =>
      data.map(AudienceServiceHelper.normalizeFirstPartyRecord)
    );
  });
}

function executeUpload(url, data, file, method, callbacks) {
  const Upload = getUploadService();
  callbacks = callbacks || {};
  data = data || {};
  data.file = file;
  const upload = Upload.upload({ url, data, method });
  return upload.then(callbacks.success || noop, callbacks.error || noop, callbacks.progress || noop);
}

export function createFirstPartySegmentWithFile(data, file, callbacks) {
  return executeUpload(FIRST_PARTY_UPLOADER, data, file, 'POST', callbacks);
}

export function updateFirstPartySegment(segmentId, data, callbacks = {}) {
  const context = getContext();
  const firstPartyService = getFirstPartyService();
  const successCallback = callbacks.success || noop;
  callbacks.success = function (res) {
    successCallback(res);
    if (!includes(data.programs, context.program.id)) {
      AudienceServiceHelper.deleteSegmentFromContext(data.segment_value);
    } else {
      AudienceServiceHelper.updateSegmentNameInContext(segmentId, data.name);
    }
  };
  return firstPartyService.update(segmentId, data).then(callbacks.success, callbacks.error);
}

export function updateFirstPartySegmentWithFile(segmentId, data, file, callbacks) {
  const successCallback = callbacks.success || noop;
  callbacks.success = function (res) {
    successCallback(res);
    AudienceServiceHelper.deleteSegmentFromContext(data.segment_value);
    AudienceServiceHelper.updateSegmentNameInContext(segmentId, data.name);
  };
  return executeUpload(FIRST_PARTY_UPLOADER + '/' + segmentId, data, file, 'PUT', callbacks);
}

export function deleteFirstPartySegment(segmentId, segmentValue) {
  const firstPartyService = getFirstPartyService();
  AudienceServiceHelper.deleteSegmentFromContext(segmentValue);
  return firstPartyService.delete(segmentId);
}

export function getLinkedinTopContentForAudience(audience, userId) {
  audience = AudienceServiceHelper.convertSegmentToLinkedinAudience(audience);
  return executeHttpRequest(AbiManagementApi.getLinkedinTopContentForAudience(audience, userId)).then((data) => {
    if (data.error && data.error.reason === 'access_token_expired') return AudienceServiceHelper.linkedinTokenExpired();
    return data;
  });
}

export function getLinkedinAudienceDistribution(audience, { disableNotification, userId } = {}) {
  audience = AudienceServiceHelper.convertSegmentToLinkedinAudience(audience, true);
  // const url = disableNotification ? `${LINKEDIN_GET_AUDIENCE_DIST_API}?disable_notification=1` : LINKEDIN_GET_AUDIENCE_DIST_API;
  return getLinkedinSegmentDemographicsDistribution(audience, userId).then((res) => {
    if (res.error && res.error.reason === 'access_token_expired') return AudienceServiceHelper.linkedinTokenExpired();
    return res.status === 'error' ? res : { data: res, status: 'ok' };
  });
}

export function getLinkedinCompaniesInfoByNames(companiesNames, userId) {
  if (isEmpty(companiesNames)) return Promise.resolve([]);
  return executeHttpRequest(AbiManagementApi.getLinkedinCompaniesInfoByNames(companiesNames, userId)).then((data) => {
    if (data.error && data.error.reason === 'access_token_expired') return AudienceServiceHelper.linkedinTokenExpired();
    return data;
  });
}

export function getFullDemographicsDataWithGenderAgeByLogicalStatement(query, filterMapping, options = {}) {
  const rawDataPromise = AudienceServiceHelper.getRawDemographicsDataByLogicalStatement(query, filterMapping, options);
  const transformedDataPromise = rawDataPromise.then((data) => {
    if (!(data && data.data) || data.status !== 'ok') return data;

    const dataParts = {
      audienceSize: AudienceServiceHelper.transformAudienceSize(data.data),
      gender: AudienceServiceHelper.transformGender(data.data),
      genderAge: AudienceServiceHelper.transformGenderAge(data.data, query, filterMapping, options),
      income: AudienceServiceHelper.transformIncome(data.data),
      ethnicity: AudienceServiceHelper.transformEthnicity(data.data),
    };
    return Promise.all(Object.values(dataParts))
      .then((resultsArr) => fromPairs(zip(Object.keys(dataParts), resultsArr)))
      .then(AudienceServiceHelper.transformDataByTypeToDataByDistSkew)
      .then((transformedData) => Object.assign(transformedData, { status: data.status }));
  });

  transformedDataPromise.cancel = rawDataPromise.cancel;
  return transformedDataPromise;
}

export function getFullDemographicsDataWithGenderAgeBySegment(segment, options = {}) {
  const geoService = getGeoService();
  const query = convertAudienceSegmentToLogicStatement(segment, options.channel, geoService.geos);
  const filterMapping = convertAudienceSegmentToFilterMapping(segment, options.channel);
  return getFullDemographicsDataWithGenderAgeByLogicalStatement(query, filterMapping, options);
}

export function getDemographicsDataForPreviewByLogicalStatement(query, filterMapping, options = {}) {
  let rawDataPromise;
  switch (options.channel) {
    case 'linkedin':
      rawDataPromise = getLinkedinAudienceDistribution(query, options);
      break;
    default:
      rawDataPromise = AudienceServiceHelper.getRawDemographicsDataByLogicalStatement(query, filterMapping, options);
      break;
  }
  const transformedDataPromise = rawDataPromise.then((res) => {
    if (!(res && res.data) || res.status !== 'ok') return res;

    let dataParts;
    switch (options.channel) {
      case 'linkedin':
        dataParts = {
          audienceSize: { population: res.data.total },
          gender: {
            distribution: fromPairs(
              AudienceServiceHelper.formatDataToPercent(res.data.gender, false).map((o) => Object.entries(o)[0])
            ),
          },
          age: {
            distribution: AudienceServiceHelper.sortByLabelsNum(
              AudienceServiceHelper.formatDataToPercent(res.data.age)
            ),
          },
        };
        break;
      default:
        dataParts = {
          audienceSize: AudienceServiceHelper.transformAudienceSize(res.data),
          gender: AudienceServiceHelper.transformGender(res.data),
          age: AudienceServiceHelper.transformAge(res.data),
        };
        break;
    }

    return Promise.all(Object.values(dataParts))
      .then((resultsArr) => fromPairs(zip(Object.keys(dataParts), resultsArr)))
      .then(AudienceServiceHelper.transformDataByTypeToDataByDistSkew)
      .then((transformedData) => Object.assign(transformedData, { status: res.status }));
  });

  transformedDataPromise.cancel = rawDataPromise.cancel;
  return transformedDataPromise;
}

export async function getDemographicsDataForPreviewBySegment(segment, options = {}) {
  let query, filterMapping;
  const geoService = getGeoService();
  switch (options.channel) {
    case 'linkedin':
      query = segment;
      break;
    default:
      query = convertAudienceSegmentToLogicStatement(
        segment,
        options.channel,
        geoService.geos,
        options.isTv,
        options.isBidstream
      );
      filterMapping = convertAudienceSegmentToFilterMapping(segment, options.channel);
      break;
  }

  return await getDemographicsDataForPreviewByLogicalStatement(query, filterMapping, options);
}

export function getDemographicsDataByKeywordsAndCountries(keywords, countries, options = {}) {
  const geoService = getGeoService();
  if (isEmpty(countries)) countries = geoByChannel(null, options.channel, geoService.geos);
  const query = ['and', { aw: keywords }];
  if (countries.length) query.push(['or', ...countries.map((country) => ({ countries: country.toLowerCase() }))]);
  return getDemographicsDataForPreviewByLogicalStatement(query, null, options);
}

export function getRawInterestsDataV1(reqData) {
  const reqDefaultsBySearchType = {
    wiki: { 'wiki-data-entities': 10 },
    td: { 'added-topics-number': 1400, 'ensure-topics': 40 },
    'frequent-keywords': { 'ensure-topics': 40, 'ensure-topics2': 1, 'added-topics-number': 1000 },
    xw: { 'ensure-topics': 40, 'ensure-topics2': 1, 'added-topics-number': 1400, 'call-elastic': true },
    gsw: { 'ensure-topics': 40, 'ensure-topics2': 1, 'added-topics-number': 1400 },
    tv: { 'added-topics-number': 1400 },
    genres: { 'added-topics-number': 1400 },
    networks: { 'added-topics-number': 1400 },
  };
  reqData = Object.assign({}, reqDefaultsBySearchType[reqData['search-type']], reqData);

  // AudienceProfilerApi
  return executeWithProxyTower(AudienceProfilerApi.getRawInterestsDataV1(reqData), {
    returnOnlyData: false,
  }).then((res) => {
    if (!res) return false;
    if (res.status !== 200) return;

    let data = clone(res.data);

    // fix age legal
    if (res.data['users-sample']) {
      res.data['users-sample'].forEach((user) => {
        if (user.age === '12-17') user.age = '13-17';
      });
    }
    for (const dataType of ['distribution', 'skew']) {
      data = AudienceServiceHelper.fixAgeLegal(data, dataType);
    }

    // data size errors
    if (data.status === 'insufficient data' || data.status === 'segment too wide') return data;

    // make 'words'/'brands' uniform as 'words'
    if (reqData.isSports) data.words = data.brands;

    // make 'segment-proportion-heuristic'/'intenders-ratio-in-geo' uniform as 'intenders-ratio-in-geo' and normalize its value
    data['intenders-ratio-in-geo'] = AudienceServiceHelper.roundSegmentRatio(
      data['segment-proportion-heuristic'] || data['intenders-ratio-in-geo']
    );

    return data;
  });
}

export function getSegmentParams(segment, searchType, channel, extraParams, filterByNetworks) {
  const filterByNetworksSegment = filterByNetworks
    ? { type: 'tvShows', networks: filterByNetworks, operand: AND_LOGICAL_OPERAND }
    : undefined;
  if (filterByNetworks) segment = [...segment, filterByNetworksSegment];
  const geoService = getGeoService();
  const audienceSkewService = getAudienceSkewService();
  const isTv = TV_SEARCH_TYPES.includes(searchType) || some(segment, { type: 'tvShows' });
  const DEFAULT_PARAMS = {
    'users-sample-size': audienceSkewService.DEFAULTS['users-sample-size'],
    'words-sample-size': 1000,
  };
  const logicalStatement = convertAudienceSegmentToLogicStatement(segment, channel, geoService.geos, isTv);
  const filterMapping = convertAudienceSegmentToFilterMapping(segment, channel);
  const referenceGroup = AudienceServiceHelper.getReferenceGroup(segment, channel, { isTv, filterByNetworks });
  const referenceGroupInObj = referenceGroup && { filter2: referenceGroup };
  return Object.assign(
    DEFAULT_PARAMS,
    { filter1: logicalStatement, 'search-type': searchType, ...(filterMapping && { 'filter-mapping': filterMapping }) },
    referenceGroupInObj,
    extraParams
  );
}

export function getSegmentParamsV2(segment, searchType, channel, extraParams) {
  const geoService = getGeoService();
  const audienceSkewService = getAudienceSkewService();
  const isTv = TV_SEARCH_TYPES.includes(searchType) || some(segment, { type: 'tvShows' });
  const DEFAULT_PARAMS = { 'users-sample-size': audienceSkewService.DEFAULTS['users-sample-size'] };
  const logicalStatement = convertAudienceSegmentToLogicStatement(segment, channel, geoService.geos, isTv);
  const filterMapping = convertAudienceSegmentToFilterMapping(segment, channel);
  const referenceGroup = AudienceServiceHelper.getReferenceGroup(segment, channel, { isTv });
  const referenceGroupInObj = referenceGroup && { 'reference-segment-filter': referenceGroup };
  return Object.assign(
    DEFAULT_PARAMS,
    {
      'segment-filter': logicalStatement,
      ...(filterMapping && { 'filter-mapping': filterMapping }),
    },
    referenceGroupInObj,
    extraParams
  );
}

export function getSegmentInterestsData(segment, searchType, channel, extraParams, opts, filterByNetworks) {
  return AudienceServiceHelper.getInterestsDataV1(
    getSegmentParams(segment, searchType, channel, extraParams, filterByNetworks),
    opts
  );
}

export function getSegmentInterestsDataByIds(segment, searchType, channel, interestIds, extraParams, extraOpts) {
  const params = Object.assign(getSegmentParamsV2(segment, searchType, channel, extraParams), { ids: interestIds });
  const opts = Object.assign({ minKl: 0, fetchTextPhrase: false }, extraOpts);
  return AudienceServiceHelper.getInterestsDataV2(searchType, params, opts);
}

export function getSegmentAdvancedInterestsDataByQueries(
  segment,
  searchType,
  channel,
  advanceInterest,
  extraParams,
  extraOpts
) {
  const params = Object.assign(getSegmentParamsV2(segment, searchType, channel, extraParams), {
    queries: advanceInterest,
  });
  const opts = Object.assign({ minKl: 0, fetchTextPhrase: false }, extraOpts);
  return AudienceServiceHelper.getInterestsDataV2(searchType, params, opts, '/advanced');
}

export async function getNielsenNetworksInfo(networks) {
  const networksIdToNameMap = await getNielsenNetworksMap();
  return AudienceServiceHelper.getNetworkTitle(networks, networksIdToNameMap);
}

export async function getGenresInfo(segment, channel, filterByNetworks) {
  const res = await getSegmentInterestsData(segment, 'genres', channel, {}, {}, filterByNetworks);
  const genres = res.words;
  if (isEmpty(genres))
    return extend(res, { status: res.status === 'ok' || !has(res, 'status') ? 'noData' : res.status });
  return map(genres, (genre) => extend(genre, { title: genre.phrase }));
}

export async function getNetworksInfo(segment, channel) {
  const res = await getSegmentInterestsData(segment, channel === 'articles' ? 'networks' : 'pnws', channel);
  const networks = res.words;
  if (isEmpty(networks))
    /* eslint-disable-next-line */
    return extend(res, { status: ((res.status === 'ok' || !has(res, 'status')) ? 'noData' : res.status) });
  return networks.map((network) => extend(network, { title: network.phrase }));
}

export async function getShowInfo(segment, channel, filterByNetworks) {
  const res = await getSegmentInterestsData(segment, 'tv', channel, {}, {}, filterByNetworks);
  let shows = res.words;
  if (!isEmpty(filterByNetworks)) {
    const networksShows = await getNetworksShows(map(filterByNetworks, 'value'), channel);
    shows = (shows || []).filter((show) => networksShows.has(show['phrase-id']));
  }

  if (isEmpty(shows))
    return extend(res, { status: res.status === 'ok' || !has(res, 'status') ? 'noData' : res.status });

  const showsMetadata = await getCachedShowsMetadataByids(
    shows.map((show) => show['phrase-id']),
    channel
  );
  shows.forEach((word) => {
    const show = showsMetadata[word['phrase-id']];
    if (show) Object.assign(word, pick(show, ['title', 'image', 'genre', 'genres', 'summary', 'network']));
  });
  return shows.filter((word) => word.title);
}

export async function getTvInfo(segment, channel, withNetworks, filterByNetworks = null) {
  const res = await Promise.all([
    getGenresInfo(segment, channel, filterByNetworks),
    getShowInfo(segment, channel, filterByNetworks),
    withNetworks ? getNetworksInfo(segment, channel) : null,
  ]);
  let [genres] = res;
  const [, shows, networks] = res;
  const resulWithStatus = find(compact([shows, genres, networks]), (type) => has(type, 'status'));
  if (!isEmpty(resulWithStatus)) return resulWithStatus;
  if (filterByNetworks) {
    const showsGenres = uniq(compact(map(shows, 'genre')));
    genres = genres.filter((genre) => includes(showsGenres, genre['phrase-id']));
  }
  return omitBy({ shows, genres, networks }, isUndefined);
}

export function getPhrasesIndexInLifestyle(segment, phrases, channel) {
  if (segment && phrases && phrases.length) {
    const audienceSkewService = getAudienceSkewService();
    return getSegmentInterestsData(segment, 'xw', channel, {
      'words-sample-size': audienceSkewService.DEFAULTS['words-sample-size'],
      'added-topics-number': 0,
      'only-phrase-id': true,
      'pre-chosen-words': phrases,
    }).then((audienceData) =>
      audienceData.words.reduce((phraseToCompIndex, phrase) => {
        phraseToCompIndex[phrase['phrase-id'] + ''] = phrase['uniqueness-index'];
        return phraseToCompIndex;
      }, {})
    );
  } else {
    return Promise.resolve();
  }
}

export async function getSegmentIds(segment, channel, options = {}) {
  const sampleSize = options.sampleSize || null; //To keep the same value for sample size in the cache key
  const defaultOptions = { onlySize: true, isDeterministic: true, isShowHouseholds: true };
  const queryOptions = { ...defaultOptions, ...options };
  const audienceField = queryOptions.isDeterministic ? 'ip' : 'id';
  const key = `${JSON.stringify(segment)}_${channel}_${sampleSize}_${audienceField}`;
  const segmentsIdsPromiseCache = AudienceServiceHelper.getSegmentsIdsPromiseCache(key);
  if (segmentsIdsPromiseCache) return segmentsIdsPromiseCache;

  const userListQuery = AudienceServiceHelper.getSegmentIdsQuery(segment, channel, queryOptions);
  const res = await getAudienceServiceSegmentIds(userListQuery);

  if (!res) return false;
  if (res.status && res.status !== 200) return false;
  AudienceServiceHelper.setSegmentsIdsPromiseCache(key, res.size);
  return res.size;
}

export function createAudienceTargetTaxonomy(segmentId, channel, marketId, advertiserId) {
  return createAudienceServiceTargetTaxonomy(segmentId, channel, marketId, advertiserId).then((res) =>
    res ? res.status : false
  );
}

export function createAudienceTargetUserList(segmentId, channel, ids) {
  return createAudienceServiceTargetUserList(segmentId, channel, ids).then((res) => (res ? res.status : false));
}

export function createAmplifiedAudience(
  segment,
  segmentId,
  segmentName,
  channel,
  market,
  advertiser,
  amplifyThreshold,
  ssoAccessToken,
  tremorSyndicateAmplified,
  isCustomPriceAmplified,
  optimizationType
) {
  const isFirstParty = channel == 'articles' && some(segment, { type: '1st party' });
  const filterMapping = convertAudienceSegmentToFilterMapping(segment, channel);
  const target = getTargetForAmplification(segment, channel, filterMapping);
  const userListQuery = AudienceServiceHelper.getSegmentIdsQuery(segment, channel, {
    shouldSave: true,
    isAmplification: true,
  });
  const geo = getGeoFromAudienceSegment(segment);
  return createAudienceServiceAmplifiedAudience(
    segmentId,
    segmentName,
    channel,
    market,
    advertiser,
    amplifyThreshold,
    target,
    ssoAccessToken,
    userListQuery,
    geo,
    isFirstParty,
    tremorSyndicateAmplified,
    isCustomPriceAmplified,
    optimizationType
  ).then((res) => (res ? res.amplified_segment_id : false));
}

export function createAlwaysOnAudience(
  segment,
  segmentId,
  segmentName,
  channel,
  dataContractId,
  dataContractText,
  categoryId,
  amplifyThreshold,
  ssoAccessToken
) {
  const filterMapping = convertAudienceSegmentToFilterMapping(segment, channel);
  const target = getTargetForAmplification(segment, channel, filterMapping);
  const userListQuery = AudienceServiceHelper.getSegmentIdsQuery(segment, channel, {
    shouldSave: true,
    isAmplification: true,
  });
  const geo = getGeoFromAudienceSegment(segment);

  return createAudienceServiceAlwaysOnAudience(
    segmentId,
    segmentName,
    dataContractId,
    dataContractText,
    categoryId,
    amplifyThreshold,
    target,
    ssoAccessToken,
    userListQuery,
    geo
  ).then((res) => (res ? res.amplified_segment_id : false));
}

function getTargetForAmplification(segment, channel, filterMapping) {
  const geoService = getGeoService();
  const isBidstream = true;
  const query = convertAudienceSegmentToLogicStatement(segment, channel, geoService.geos, null, isBidstream);
  const reference = AudienceServiceHelper.getReferenceGroup(segment, channel, { isBidstream });
  return { query, reference, ...(filterMapping && { 'filter-mapping': filterMapping }) };
}

export function createDeterministicAudience(
  segmentId,
  segmentName,
  channel,
  market,
  advertiser,
  ssoAccessToken,
  userListQuery,
  tremorSyndicateDeterministic,
  isCustomPriceDeterministic,
  expirationDate
) {
  return createAudienceServiceDeterministicAudience(
    segmentId,
    segmentName,
    channel,
    market,
    advertiser,
    ssoAccessToken,
    userListQuery,
    tremorSyndicateDeterministic,
    isCustomPriceDeterministic,
    expirationDate
  ).then((res) => {
    if (!res) return false;
    if (res.status && res.status !== 200) return false;
    if (res.category_id && res.data_contract_id && res.data_provider_id && res.dsp_segment_id) {
      return {
        categoryId: res.category_id,
        dataContractId: res.data_contract_id,
        dataProviderId: res.data_provider_id,
        dspSegmentId: res.dsp_segment_id,
      };
    }
    return false;
  });
}

export async function createUserListForDeterministicAudience(
  channel,
  segment,
  segmentId,
  market,
  advertiser,
  categoryId,
  dataContractId,
  dataProviderId,
  dspSegmentId,
  isDynamic,
  ssoAccessToken,
  audienceName,
  tremorSyndicateDeterministic,
  isCustomPriceDeterministic
) {
  const userListQuery = AudienceServiceHelper.getSegmentIdsQuery(segment, channel, {
    shouldSave: true,
    isDeterministic: true,
  });
  const res = await createAudienceServiceUserListForDeterministicAudience(
    channel,
    segmentId,
    market,
    advertiser,
    categoryId,
    dataContractId,
    dataProviderId,
    dspSegmentId,
    userListQuery,
    isDynamic,
    ssoAccessToken,
    audienceName,
    tremorSyndicateDeterministic,
    isCustomPriceDeterministic
  );

  return res ? res.status : false;
}

export function getAudienceTaxonomyCategory(segmentId) {
  return getAudienceServiceTaxonomyCategory(segmentId).then((res) => (res ? res.taxonomy_category_id : false));
}

export function getDataContract(dataContractId) {
  return getSsoAccessToken().then((accessToken) => {
    const headers = { 'SSO-Access-Token': accessToken.accessToken };
    return executeHttpRequest(ActivationApi.getDataContract(dataContractId, headers));
  });
}

export function getTaxonomyCategory(dataContractId, categoryId) {
  return getSsoAccessToken().then((accessToken) => {
    const headers = { 'SSO-Access-Token': accessToken.accessToken };
    return executeHttpRequest(ActivationApi.getTaxonomyCategory(dataContractId, categoryId, headers));
  });
}

export function getMarketDetails(marketId, ssoToken = null) {
  if (ssoToken) {
    return executeHttpRequest(ActivationApi.getMarketDetails(marketId, { 'SSO-Access-Token': ssoToken }));
  }
  return getSsoAccessToken().then((accessToken) => {
    const headers = { 'SSO-Access-Token': accessToken.accessToken };
    return executeHttpRequest(ActivationApi.getMarketDetails(marketId, headers));
  });
}

export function getAudienceSegmentByDspSegmentId(dsp_segment_id, ssoToken) {
  const headers = { 'SSO-Access-Token': ssoToken };
  return executeHttpRequest(ActivationApi.getAudienceByDspSegmentId(dsp_segment_id, headers));
}

export function getAmplifiedEstimatedReachGoal(bidstreamUsers, amplifiedReachGoalValue) {
  return new Promise((resolve) =>
    setTimeout(
      () =>
        resolve(
          bidstreamUsers * (amplifiedReachGoalValue / 100) + random(0, 99) * (bidstreamUsers >= 10000000 ? 1000 : 100)
        ),
      2000
    )
  );
}

export async function createAudienceInlineSegment(name, segmentCriterias, channel, ssoAccessToken) {
  return executeHttpRequest(
    AbiManagementApi.createAudienceInlineSegment(name, segmentCriterias, channel, ssoAccessToken)
  );
}

export const NUM_USERS_BY_GEO = {
  US: 183340788,
  SG: 3654756,
  AU: 13397359,
  GB: 29663901,
};

const MIN_AUDIENCE_SIZE = 500;

export async function getAudienceSize(segment, options) {
  options.isAmplification = options.isBidstream;
  options.isDeterministic = false;
  const res = await getSegmentIds(segment, options.channel, options);
  return res;
}

export function isAudienceSizeTooSmall(size) {
  return size < MIN_AUDIENCE_SIZE;
}
