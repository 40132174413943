import * as MixpanelCommon from './MixpanelCommon';
import { getStateCurrent } from '../../middleware/AngularExportedMiddleware';

let widgetName = MixpanelCommon.extractWidget(getStateCurrent());
const appName = 'Settings';

export const trackPageView = (widget) => {
  widgetName = widget || widgetName;
  MixpanelCommon.trackPageView(appName, widgetName);
};
