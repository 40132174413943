var c = require("infra/utils/common");

module.exports = angular.module(__filename, [])
    /*base directive for interface for all am-container family*/
    .directive('amContainer', ["$parse", "$compile", "utils", "$q", "directiveBuildHelper",
        function ($parse, $compile, utils, $q, directiveBuildHelper) {

            var dataPattern = '(@label for)? (_instance_ in @source@)';
            //var dataPattern = '(@model as)? (@label for)? (_instance_ in @source@) (index by @index)?';
            var parser = directiveBuildHelper.compile(dataPattern);

            var template = {
                'accordion': require('./type-accordion/accordion.html'),
                'drop': require('./type-drop/drop.html')
            };

            var postLink = {
                switch: require('./type-accordion/accordion'),
                drop: require('./type-drop/drop')
            };
            var postLinkPerType = {
                'accordion': postLink.switch,
                'drop': postLink.drop
            };

            var MAX_CHARS_IN_SUMMARY = 25;

            return {
                restrict: "E",
                transclude: true,
                require: '?^sideFiltersMenu',
                controllerAs: 'amContainerCtrl',
                controller: ['$scope', function ($scope) {
                    var components = [];
                    this.add = function (scope) {
                        components.push(scope)
                    };

                    $scope.resetFilters = function () {
                        angular.forEach(components, function (inputCtrl) {
                            inputCtrl.editor.reset();
                        });

                        // So label will reset as well
                        $scope.selected = [];
                        $scope.size = 0;
                    };
                }],
                scope: {
                    caption: '@',
                    icon: '@',
                    filterName: '@',
                    summaryPlaceholder: '@',
                    summaryCustomFilter:'@',
                    startOpened: '@',
                    info: '@',
                    refreshSummary: '='
                },
                template: function (element, attr) {
                    return template[attr.type];
                },
                compile: function (element, attr, ctrl, transcludeFn) {
                    return {
                        pre: function (scope, element, attr, ctrl, transcludeFn) {
                            ctrl && ctrl.add(scope);

                            scope.$watch('summaryCustomFilter', function(newValue, oldValue) {
                              scope.summaryCustomFilterObj = scope.$eval(newValue);
                            });

                            scope.summaryOrder = eval(attr.summaryOrder);

                            function getNumOfWordsFitInLength(words, length, wordsSeparatorLength, maxWords) {
                                if (!words || !words.length || words[0].length > length) return 0;
                                var numOfChars = words[0].length;
                                var numOfWordsToCheck = Math.min(maxWords, words.length);
                                for (var i = 1; i < numOfWordsToCheck; i++) {
                                    if (numOfChars + wordsSeparatorLength + words[i].length > length) return i;
                                    numOfChars += wordsSeparatorLength + words[i].length
                                }
                                return numOfWordsToCheck;
                            }

                            var refreshSummary = function (nv) {
                                if (nv === void 0) return;
                                if (element.hasClass('open') === false) {
                                    scope.selected = _.compact(_.map(selected(), (rec) => c.cutText(rec.label, MAX_CHARS_IN_SUMMARY - 5)));
                                    if (scope.summaryOrder) {
                                        scope.selected = _.sortBy(scope.selected, function (s) {return scope.summaryOrder.indexOf(s);});
                                    }
                                    scope.size = scope.selected.length;
                                    scope.curSummaryLimit = getNumOfWordsFitInLength(scope.selected, MAX_CHARS_IN_SUMMARY, 2, scope.summaryLimit);
                                }
                            };

                            if (attr.summary) {
                                var parseResult = parser(attr.summary, scope.$parent);
                                var selected = parseResult.source;
                                scope.summaryLimit = attr.summaryLimit * 1;

                                scope.$watch(function () { return element.hasClass('open') }, refreshSummary);
                                scope.$watch('refreshSummary', refreshSummary);
                            }

                            scope.toggleMenu = function () {
                                element.toggleClass('open');
                            };

                            if (scope.startOpened) {
                                scope.toggleMenu();
                            }
                        },
                        post: postLinkPerType[attr.type]
                    }
                }
            }
        }
    ]
);
