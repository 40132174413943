import React from 'react';
import PropTypes from 'prop-types';
import { noop, isEqual } from 'lodash';
import ResetableErrorBoundary from '../ErrorBoundary/ResetableErrorBoundary/ResetableErrorBoundary';
import WidgetOutlineView from './WidgetOutlineView';
import WidgetErrorView from './WidgetErrorView';
import { logWidgetError } from '../../../infra/Logger';

class Widget extends React.Component {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(prevProps, prevState) {
    return (
      !isEqual(this.props.dataServiceParams, prevProps.dataServiceParams) ||
      !isEqual(this.props.renderOnValueChange, prevProps.renderOnValueChange)
    );
  }

  errorMarkup = () => <WidgetErrorView emptyStateRender={this.props.emptyStateRender}></WidgetErrorView>;

  onComponentJsError = (error, componentStack) => {
    logWidgetError(error, { isComponentError: true, componentStack, widgetProps: this.props });
  };

  onServiceError = (error) => logWidgetError(error, { isServiceError: true, widgetProps: this.props });

  render() {
    return (
      <ResetableErrorBoundary
        shouldResetError={true}
        onError={this.onComponentJsError}
        fallbackComponent={this.errorMarkup}
      >
        <WidgetOutlineView
          {...this.props}
          errorRender={this.errorMarkup}
          onServiceError={this.onServiceError}
        ></WidgetOutlineView>
      </ResetableErrorBoundary>
    );
  }
}

Widget.propTypes = {
  dataService: PropTypes.func.isRequired,
  dataServiceParams: PropTypes.array.isRequired,
  onRender: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  emptyStateRender: PropTypes.func,
  onDataLoaded: PropTypes.func,
  onDataUnloaded: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
  isSmallSpinner: PropTypes.bool,
  isAclSpinner: PropTypes.bool,
  renderOnValueChange: PropTypes.array,
};

Widget.defaultProps = {
  emptyStateRender: () => null,
  onDataLoaded: noop,
  onDataUnloaded: noop,
  isSmallSpinner: false,
  isAclSpinner: false,
};

export default Widget;
