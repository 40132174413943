var c = require('./utils/common');

module.exports = angular.module(__filename, [])
    .service('util', ['SIGNS', 'filtersPartition', '$timeout', 'abiPermissions', '$rootScope',
      function (SIGNS, filtersPartition, $timeout, abiPermissions, $rootScope) {
        const canvasEl = document.createElement('canvas');
        const canvasCo = canvasEl.getContext("2d");

        this.hasValues = function (hash, ignoreKeys) {
            var keys = Object.keys(hash);
            for (var i = 0; i < keys.length; i++) {
                if (ignoreKeys && ignoreKeys.indexOf(keys[i]) > -1) continue;
                if (!_.isEmpty(hash[keys[i]])) return true;
            }
            return false;
        };

        this.all_ages = _.map(filtersPartition.age, 'value');
        this.all_incomes = _.flatMap(filtersPartition.newIncome, 'value');
        this.all_races = _.map(filtersPartition.races, 'value');
        this.all_sgEthnicities = _.map(filtersPartition.sgEthnicity, 'value');
        this.filtersPartition = filtersPartition;

        this.common = c;

        this.prepareAgeDistDataToAgeChart = function (selected_ages, all_ages_size, age_distribution, remove_unselected_ages, BAR_SPACE_BY_BAR_NUM) {
            var age_values, bar_spacing;
            var age_labels = Object.keys(age_distribution);
            if (selected_ages) {
                if (remove_unselected_ages) age_labels = selected_ages;
                age_values = age_labels.map(function (age) {
                    return selected_ages.indexOf(age) > -1 ? (age_distribution[age] * 100) : 0;
                });
                var total_ages = age_values.reduce(function (a, b) {
                    return a + b;
                });
                age_values = [c.roundPercents(age_values.map(function (age) {
                    return (age / total_ages * 100);
                }))];
                if (BAR_SPACE_BY_BAR_NUM) bar_spacing = BAR_SPACE_BY_BAR_NUM[(remove_unselected_ages ? selected_ages.length : all_ages_size) - 1];
            } else {
                age_values = [c.roundPercents(age_labels.map(function (age) {
                    return (age_distribution[age] * 100);
                }))];
                if (BAR_SPACE_BY_BAR_NUM) bar_spacing = BAR_SPACE_BY_BAR_NUM[all_ages_size - 1];
            }

            return [age_labels, age_values, bar_spacing];
        };

        this.removeFullySelectedAudienceSegments = function (audience, isFullObject) {
            if (isFullObject) {
                if (_.isEmpty(_.differenceBy(this.filtersPartition.age, audience, 'value'))) audience = _.differenceBy(audience, this.filtersPartition.age, 'value');
                if (_.isEmpty(_.differenceBy(this.filtersPartition.newIncome, audience, 'value'))) audience = _.differenceBy(audience, this.filtersPartition.newIncome, 'value');
                if (_.isEmpty(_.differenceBy(this.filtersPartition.races, audience, 'value'))) audience = _.differenceBy(audience, this.filtersPartition.races, 'value');
                if (_.isEmpty(_.differenceBy(this.filtersPartition.sgEthnicity, audience, 'value'))) audience = _.differenceBy(audience, this.filtersPartition.sgEthnicity, 'value');
                audience = _.reject(audience, function (a) {
                    return a.value == "";
                });
            } else {
                if (_.isEmpty(_.difference(this.all_ages, audience))) audience = _.difference(audience, this.all_ages);
                if (_.isEmpty(_.difference(this.all_incomes, _.flatten(audience)))) audience = _.difference(_.flatten(audience), this.all_incomes);
                if (_.isEmpty(_.difference(this.all_races, audience))) audience = _.difference(audience, this.all_races);
                if (_.isEmpty(_.difference(this.all_sgEthnicities, audience))) audience = _.difference(audience, this.all_sgEthnicities);
                audience = _.reject(audience, function (a) {
                    return a == "";
                });
            }

            return _.flatten(audience);
        };

        this.getTopicsDistribution = function (topics) {
            if (!topics) return {};
            var topTopics = Object.keys(topics).sort(function (a, b) {
                return topics[b] - topics[a];
            }).slice(0, 3).map(function (t) {
                return parseInt(t);
            });
            var formattedTopics = [];
            Object.keys(topics).forEach(function (key) {
                formattedTopics[key] = topics[key] > 0 ? topics[key] + '%' : null
            });
            return {distribution: formattedTopics, top_topics: topTopics};
        };

        this.normalize = function (entities, sourceProperty, destProperty, referenceEntities) {
            if (!_.isEmpty(entities)){
                let max = _.maxBy(referenceEntities || entities, sourceProperty)[sourceProperty];
                let factor = 100.0 / max;
                entities.forEach((entity) => { entity[destProperty] = entity[sourceProperty] * factor });
            }
            return entities;
        };

        this.capitalFirst = function (str) {
            return str.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
        };

        this.ignoreSerialize = function (obj, key) {
            var value = obj[key];
            delete obj[key];
            return Object.defineProperty(obj, key, {
                configurable: true,
                writable: true,
                value: value
            });
        };

        this.getTermsText = function (terms) {
            return terms ? _.map(terms, 'text') : [];
        };

        this.getTermDisplay = function (term) {
            var editedSign = term.text.endsWith(SIGNS.EDITED);
            var display = term.text.replace(SIGNS.EDITED, "") || "";

            if (display.startsWith(SIGNS.POST)) {
                display = display.replace(SIGNS.POST, "") + ' posts';
            } else if (display.startsWith("@")) {
                display = display + ' mentions';
            }

            if (editedSign) {
                display += SIGNS.EDITED;
            }

            return display;
        };

        this.getTermDisplayShort = function (text) {
            return text.replace(SIGNS.POST, "");
        };

        this.getTermTextByDisplay = function (display) {
            //@handle mentions  => @handle
            //@handle posts     => u:@handle

            var text = display.trim();

            if (text.startsWith(SIGNS.MENTION)) {
                if (text.indexOf(' mentions') != -1) {
                    text = text.replace(" mentions", "");
                } else if (text.indexOf(' posts') != -1) {
                    text = SIGNS.POST + text.replace(" posts", "");
                }
            }

            return text;
        };

        this.upgradeTerms = function (terms) {
            //when adding new props to term...
            var that = this;
            if (!terms) {
                return;
            }

            _.each(terms, function (term) {
                if (!term.display) {
                    term.display = that.getTermDisplay(term);
                }
            });
        };

        this.getTerms = function (terms, isBooleanLogic, withoutSocialBl) {
            this.upgradeTerms(terms);

            if (isBooleanLogic) {
                var self = this;
                return c.selectBooleanLogics(terms).filter(function (term) {
                    return !withoutSocialBl || !c.isSocialBooleanLogic(term);
                }).map(function (term) {
                    return {
                        id: term.id,
                        text: term.text,
                        type: term.type,
                        required: self.getTermsText(term.required),
                        included: self.getTermsText(term.included),
                        excluded: self.getTermsText(term.excluded),
                        class: term.class
                    };
                });
            } else {
                return this.getTermsText(c.rejectBooleanLogics(terms));
            }
        };


        this.getButtonCss = function (option, currentVal, scope) {
            let res = "";

            if (option.first) {
                res += "first ";
            }
            if (scope.$eval(option.last)) {
                res += "last ";
            }
            if (option.value === currentVal) {
                res += "chosenTime ";
                if (option.first) {
                    res += "chosenFirst ";
                }
            }
            return res;
        };

        this.removeDuplicates = function(json_all) {
            var arr = [],
                collection = [];

            $.each(json_all, function (index, value) {
                if ($.inArray(value.id, arr) == -1) {
                    arr.push(value.id);
                    collection.push(value);
                }
            });
            return collection;
        };

        this.getPhrasesToIdMap = function (terms) {
            var phrases = _.compact(_.flattenDeep((terms || []).map(function (p) {
                return (p.type === 'booleanLogic' || p.type === 'programBL') ? [p.required, p.included, p.excluded] : p;
            })));
            return phrases.filter(function (t) {
                return t.id != -1;
            }).reduce(function (map, t) {
                map[t.text.toLowerCase()] = t.id;
                return map;
            }, {});
        };

        this.getValidTerms = function (scope, parameters) {
            var oid = function (o) {
                return o.id + "|" + o.text;
            };
            var terms = parameters.terms.map(oid);
            var bls = parameters.boolean_logics.map(oid);
            return _.filter(scope.terms, function (o) {
                return _.includes(terms.concat(bls), oid(o));
            });
        };

        function allowSubGeos(channel) {
            return ["sg_telco", "sg_bidstream", "bidstream", "au_telco", "articles"].includes(channel);
        }

        this.buildInsightsParameters = function (values, disablePosts, sendAudience) {
            let self = this;
            let channel = (values.insightsChannel || {}).value;
            const allowHashtagsMentions = channel === 'facebook';
            let parameters = c.getValidContextParameters(values, {
                term: true,
                mention: (allowHashtagsMentions || false),
                hashTag: (allowHashtagsMentions || false),
                post: ((allowHashtagsMentions && !disablePosts) || false)
            });
            _.each(parameters.terms, function (term) {
                term.display = self.getTermDisplay(term);
            });
            if (!allowSubGeos(channel)) {
                delete parameters.sub_geos;
            }

            parameters.channel = channel;
            parameters.sensitive_content = values.sensitive_content;

            // toNormalize can be undefined, in that case the channel the to_normalize will be depend on the channel
            parameters.to_normalize = (typeof values.toNormalize === "undefined") ?
                                        parameters.channel !== 'facebook' :
                                        values.toNormalize;

            parameters.boolean_logics = this.getTerms(values.terms, true, !allowHashtagsMentions);
            parameters.kwd_ids = this.getPhrasesToIdMap(values.terms);
            parameters.trends = values.terms;
            if (_.includes(['articles', 'sg_telco', 'sg_bidstream', 'bidstream'], channel) || sendAudience) {
                parameters.audience = _.flatMap(this.removeFullySelectedAudienceSegments(c.getAudience(values, channel), true), 'value');
            }
            if (channel == 'articles' && values.userFirstPartyAudience) {
                parameters.user_first_party_audience = _.map(values.userFirstPartyAudience || [], "value");
            }
            return parameters;
        };

        this.isSocialBooleanLogic = this.common.isSocialBooleanLogic;

        this.extractDomainFromURL = function (input) {
            var match = input.match(/((.*:\/\/)?(www[0-9]?\.)?)([^/:\?]+)/i);
            if (match != null && match.length > 2 && typeof match[4] === 'string' && match[4].length > 0) {
                return match[4];
            } else {
                return null;
            }
        };

        this.animateElementBloat = function (e) {
            $(e.toElement).addClass("animate-bloat");
            $timeout(function () {
                $(e.toElement).removeClass("animate-bloat")
            }, 500);
        };

        this.isMyInterestsProgram = function (program) {
            return program && /interests/.test(program.name) && program.name != 'guest interests';
        };

        this.isMySegmentsProgram = function (program) {
            return program && /segments$/.test(program.name);
        };

        const CUTOFF_USER_MAIL = "consumption.tooltip.internal@gmail.com";

        this.shouldApplySmoothing = function () {
            return _.lowerCase($rootScope.user.email) !== _.lowerCase(CUTOFF_USER_MAIL);
        }

        this.isTextOverflow = function (str, width,textStyle = {}){
          canvasCo.font = textStyle.font || "400 14px Roboto";
          canvasCo.letterSpacing = textStyle.letterSpacing || "0.3px";
          return canvasCo.measureText(str).width < width;
        }
    }]
);
