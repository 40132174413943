import * as MixpanelAlerts from '../../../react/infra/mixpanel/MixpanelAlerts';

module.exports = angular.module(__filename, [
    require('data/alerts-v2-service').name
]).controller('alertsV2SettingsController', ['$scope', 'close', 'alertsV2Service', 'notificator',
              'settings', 'firstTime', 'util',
    function ($scope, close, alertsInterface, notificator, settings, firstTime, util) {
    console.log(settings);
        $scope.settings = settings || {email_recipients: [$scope.$root.user.email], recurrence_type: 'as_it_happens'};
        $scope.firstTime = firstTime;
        $scope.email_recipients = $scope.settings.email_recipients.join().concat(',');
        $scope.close = close;
        $scope.save = save;

        $scope.day_of_week = (angular.isNumber($scope.settings.day_of_week) ? $scope.settings.day_of_week : 1).toString();
        $scope.hour_of_day = (($scope.settings.hour_of_day || 12) - 3).toString();
        $scope.includeWeekends = $scope.settings.include_weekends == null ? true : $scope.settings.include_weekends;

        $scope.weekdays = moment.weekdays();
        $scope.hours = _.range(0, 24).map((value) => ({value, label: (value.toString().padStart(2, '0') + ':00')}));

        $scope.recurrenceTypes = [
            {text: 'Daily', value: 'daily'},
            {text: 'Weekly', value: 'weekly'},
            {text: 'As it happens', value: 'as_it_happens'}
        ];

        $scope.getButtonCss = (settings) => util.getButtonCss(settings, $scope.settings.recurrence_type, $scope);

        function save() {
            if (!validateEmailRecipients()) return;

            buildInterval();
            alertsInterface.alertsNotificationSettings.update($scope.settings.id, $scope.settings).then(function (data) {
                if (data.failed) {
                    notificator.error({body: 'Save canceled! ' + data.error});
                } else {
                    MixpanelAlerts.trackAlertsSettings(data);
                    notificator.success({body: 'Email settings saved successfully'});
                    close({reload: true});
                }
            });
        }

        function buildInterval() {
            switch ($scope.settings.recurrence_type) {
                case 'daily':
                    $scope.settings.hour_of_day = getHourOfDay();
                    $scope.settings.include_weekends = $scope.includeWeekends;
                    break;
                case 'weekly':
                    $scope.settings.hour_of_day = getHourOfDay();
                    $scope.settings.day_of_week = parseInt($scope.day_of_week);
                    break;
            }
        }

        function getHourOfDay() {
            return parseInt($scope.hour_of_day) + 3;
        }

        var EMAIL_REGEX = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

        function validateEmailRecipients() {
            $scope.settings.email_recipients = [];
            var invalidEmailFound = false;
            $scope.email_recipients.split(",").forEach(function (email_recipient) {
                var e = email_recipient.trim();
                if (e.length === 0) return;

                if (EMAIL_REGEX.test(e)) {
                    $scope.settings.email_recipients.push(e);
                } else {
                    invalidEmailFound = true;
                }
            });

            $scope.invalidEmailsError = invalidEmailFound;
            return !$scope.invalidEmailsError;
        }
    }
]);
