###
  to use orbis, construct a new orbis with the jquery element you want the svg to be added to.
  Then whenever you want to change the data orbis shows just call orbis.load (see the load function for information)

  The following attributes can be enabled/disabled
  	show_heat - show the heatmap
    allow_pruning - prune nodes whose text don't fit inside the bubble
    set_variance_correction - set the variance correction used to determine bubble size

  You can listen on the following events:
  	orbis.on("orbis.clicked_node") - when a certain node is clicked
  	orbis.on("orbis.background_clicked") - when the background is clicked
  	orbis.on("orbis.redraw") - when a redraw is called (for instance when the window resizes)
###

Cluster = require("./cluster")
tooltip = require("common/tooltip/tooltip")

module.exports = class Orbis

	constructor: (@div_id,is_story_clustering_allowed,channel) ->
		@svg_id = "bubbles"
		@scaffold_elements()

		@cluster = new Cluster(@div_id,is_story_clustering_allowed,channel)
		$(window).resize =>
			@redraw()


	scaffold_elements: () ->
		$("<svg id='#{@svg_id}' width='100%' height='100%' xmlns = 'http://www.w3.org/2000/svg'>").appendTo("##{@div_id}")

		@svg = d3.select("##{@svg_id}")

		@svg.append("g").attr("id", "heatmap_legend")
		defs = @svg.append("defs")

	show_heat:(show, field) => @cluster.show_heat(show, field)

	show_audience_overlay:(show) => @show_heat(show, "audience")

	show_velocity_overlay:(show) => @show_heat(show, "score")

	set_heat:(show, field) => @cluster.set_heat(show, field)

	get_abs_max_score:() => Math.max(@cluster.get_max_score(),Math.abs(@cluster.get_min_score()))

	load: (seed_idx,concept_idx,channel) =>
		@cluster.load(seed_idx,concept_idx,channel)

	on_error: ( message ) =>
		console.warn("there was an error", message )


	allow_pruning:(allow) => @cluster.set_pruning(allow)

	set_variance_correction:(correction) => @cluster.set_variance_correction(correction)

	redraw: () =>
		@cluster.on_window_resize()


	on: ()->
		$("##{@div_id}").on.apply($("##{@div_id}"),arguments)
