// copied example from https://webpack.js.org/guides/dependency-management/#context-module-api
function importAll(request) {
  return request.keys().map((item) => {
    const { domain } = item.match(/\.\/(?<domain>.*)\.(?:png|jpe?g|svg)$/).groups;
    return {
      domain,
      image: request(item).default,
    };
  });
}

const images = importAll(require.context('./assets', false, /\.(png|jpe?g|svg)$/));

export default function getLogoFallback(url) {
  const { hostname } = new URL(url);
  const { image } = images.find(({ domain }) => hostname.includes(domain)) || {};
  return image;
}
