import * as MixpanelAudience from '../../react/infra/mixpanel/MixpanelAudience';
import * as MixpanelInsights from '../../react/infra/mixpanel/MixpanelInsights';

const Template = require('./topic-trends-widget.html');
const qtip2 = require('qtip2');
const $ = require('jquery');
const c = require("infra/utils/common");
const ex = require("infra/utils/export");
const BaseWidget = require('../base_widget');
const TopChart = require('../../common/charts/top-chart');
const TopBar = require("common/charts/top-bar");
const VerticalBarChart = require("../../common/charts/vertical-bar-chart");
const TrendChart = require('../../common/charts/trend-chart');
const examplesDataModel = require("data/insights/examples-data-model");

const isDefined = angular.isObject;
const isArray = angular.isArray;
const isString = angular.isString;
const forEach = angular.forEach;
const isFunction = angular.isFunction;

const topicsExportName = 'Topic Breakdown';
const subTopicsExportName = 'Sub-Topic Breakdown';

const consumptionExportTabName = 'Topic Trend';
const consumptionChangeFromAvgExportTabName = '% Change From Average';
const consumptionTopicsAverageConfiguration = {
    title: 'Average',
    element: 'consumption-topics-average-chart',
    parent: 'topic-trends-widget',
    margin: 160,
    pad: 0,
    grouped: true
};
const TREND_CHART_ENTRIES = [{
    key: 'value',
    source: 'value',
    order: 1,
    class: '',
    units: ''
}];

const topicsTopChartConfiguration = {
    topBarGroup: {
        graph: {},
        examples: {}
    },
    barGroupXOffset: 20
};

const isNumber = function(n) {
    return angular.isNumber(n) && !isNaN(n);
};

const TREND_LINE_ERROR_MESSAGE = "The Graph is currently unavailable";

var deepEach = function (array, key, apply) {
    if (isFunction(apply) && isArray(array) && array.length > 0) {
        try {
            forEach(array, function (entry, entryIndex) {
                if (isString(key) && key.length) {
                    if (isArray(entry[key]) && entry[key].length > 0) {
                        forEach(entry[key], function (item, itemIndex) {
                            apply(entry, entryIndex, item, itemIndex);
                        });
                    } else {
                        apply(entry, entryIndex, {}, 0);
                    }
                } else {
                    apply(entry, entryIndex, null, null);
                }
            });
        } catch (e) {
            console.log('Failed to run on array', e);
        }
    } else {
        return [];
    }

    return array;
};

function TopicTrendsController($scope, $timeout, $state, topicsTrendService, consumptionTrendService, util,
                               examplesDataModel, insightsExportService, permissions,
                               baseInsightsService, TIMES, filtersPartition, notificator, $location, context) {

    $scope.trends = null;
    $scope.noData = true;
    $scope.noTrendsData = true;
    $scope.expand = false;
    $scope.topicData = [];
    $scope.averageTopicsFactor = 1;
    $scope.examplesData = examplesDataModel;
    $scope.legendTrends = [];
    $scope.legendTrendsRow2 = [];
    $scope.legendTrendsRow3 = [];
    const self = this;
    this.$timeout = $timeout;
    this.TIMES = TIMES;
    this.filtersPartition = filtersPartition;
    this.notificator = notificator;
    this.$location = $location;

    this.consumptionService = consumptionTrendService;
    this.topicsTrendService = topicsTrendService;
    this.insightsExportService = insightsExportService;
    this.baseInsightsService = baseInsightsService;
    this.$timeout = $timeout;
    this.permissions = permissions;
    this.parameters = {};
    this.trendChart = new TrendChart(this, "topic-consumption-chart", {
        type: 'daily',
        force_max: true,
        agg_examples_mode: true,
        templates: {
            non_normalized: _.template("<%= parseInt(consumption).toLocaleString('en') %> interactions, <%= consumptionAvg > 0 ? '+' : ''%><%= consumptionAvg %>% from Avg"),
            normalized: _.template("Consumption <%= parseFloat(consumption.toFixed(1)) %>, <%= (consumptionAvg > 0) ? '+' : '' %><%= consumptionAvg %>% from Avg")
        },
        marginTop: 80
    });
    this.barChart = new VerticalBarChart(this, TREND_CHART_ENTRIES, consumptionTopicsAverageConfiguration);

    this.selectedTopic = {id: null};
    this.util = util;

    this.chartInit = () =>  { return {chart: [], range: [], max: 100 } };

    this.chartData = this.chartInit();

    this.groupedTopicData = [];
    $scope.valueMeasures = [
      {
        label: 'Consumption',
        value: 'consumption',
        tooltip: 'Topic consumption score represents how often a seed interest consumed in relation to a certain topic, compared to the other displayed topics. The displayed topic with the highest consumption is given a score of 100.'
      },
      {
        label: 'Topic distribution',
        value: 'distribution',
        tooltip: 'Topic distribution score measures the percentage of consumption that a seed interest is consumed within the topic, out of the total seed consumption.'
      },
      {
        label: 'Skew',
        value: 'skew',
        tooltip: 'Topic skew score measures the extent of which the seed interest is over-indexed within the topic consumption compared to all topics combined.'
      }
    ];
    $scope.valueMeasure = $scope.valueMeasures[0];

    MixpanelInsights.trackPageView('topics');

    this.showGraph = function(selectedSeed) {
        if(selectedSeed.id != self.selectedTopic.id) {
            self.resetTopicObjects();
        }
        $scope.showGraph = true;
        $scope.examplesData.alphabetized = true;
        self.selectTopic(selectedSeed, 'graph');

        self.insightsExportService.addReportFunction(self.getTopTopicExportReport, true);
        self.insightsExportService.addReportFunction(self.getSubTopicExportReport, false);
        _.each([false, true], (avgMode) => {
            self.insightsExportService.addReportFunction((format) => self.getConsumptionExportReport(format, avgMode), false);
        });
        const language = context.current._language_mold.getLanguage($state, context).value;
        self.trendChart.setWithSimWebDate(self.parameters.channel === 'articles' && language === 'en' && !self.util.shouldApplySmoothing());
    };

    this.showExamples = function(selectedSeed) {
        if(selectedSeed.id != self.selectedTopic.id) {
            self.resetTopicObjects();
        }
        $scope.examplesData.open = true;
        $scope.$root.$broadcast('openContentDrivers', "content_drivers");
        self.selectTopic(selectedSeed, 'contentDrivers');
    };

    this.emitEventForChartCircle = function(eventType, letter) {
        $scope.$emit(eventType, letter);
    };

    this.getTopicChartWidth = function() {
        let topic_chart_node = d3.select(".charts-frame", self.$element).node();
        if (!topic_chart_node) return 0;
        return topic_chart_node.getBoundingClientRect().width;
    };

    this.getTrendChartWidth = function() {
        const SPACE_FOR_TOPIC_CHART = 360;
        return d3.select(self.$element).node()[0].getBoundingClientRect().width - SPACE_FOR_TOPIC_CHART;
    };

    this.getAvailableSpaceForLegend = function() {
        const TOPIC_HEADER_MARGIN = 160;
        let chartWidth = self.getTopicChartWidth();
        if(chartWidth <= 0) {
            chartWidth = self.getTrendChartWidth();
        }
        return chartWidth - TOPIC_HEADER_MARGIN;
    };

    this.determineLegendTrends = function() {
        const TOPIC_HEADER_MARGIN = 20, LEGEND_MARGIN = 25, MAX_LEGEND_LENGTH = 15,
            TOPIC_CHAR_PIXELS = 8, LEGEND_CHAR_PIXELS = 6;
        $scope.legendTrends = _.concat($scope.legendTrends, $scope.legendTrendsRow2 || [], $scope.legendTrendsRow3 || []);
        $scope.legendTrendsRow2 = [];
        $scope.legendTrendsRow3 = [];
        $scope.numTrendRows = 1;
        let spaceForLegend = self.getAvailableSpaceForLegend();
        if(self.selectedParentTopicLabel && self.selectedParentTopicLabel.length > 0) {
            spaceForLegend -= TOPIC_HEADER_MARGIN + self.selectedParentTopicLabel.length * TOPIC_CHAR_PIXELS;
        }
        if(self.selectedSubTopicLabel && self.selectedSubTopicLabel.length > 0) {
            spaceForLegend -= TOPIC_HEADER_MARGIN + self.selectedSubTopicLabel.length * TOPIC_CHAR_PIXELS;
        }

        function getBreakingIndex(legendRowArray) {
            let currentSpaceForLegend = spaceForLegend;
            let index = 0;
            while(currentSpaceForLegend > 0 && legendRowArray.hasOwnProperty(index)) {
                const legend = legendRowArray[index].display || legendRowArray[index].text;
                currentSpaceForLegend -= LEGEND_MARGIN + Math.min(legend.length, MAX_LEGEND_LENGTH) * LEGEND_CHAR_PIXELS;
                index++;
            }
            if(currentSpaceForLegend <= 0) {
                return index;
            }
            return -1;
        }

        let breakingIndex = getBreakingIndex($scope.legendTrends);
        if(breakingIndex > -1) {
            $scope.legendTrendsRow2 = $scope.legendTrends.slice(breakingIndex - 1);
            $scope.legendTrends = $scope.legendTrends.slice(0, breakingIndex - 1);
            $scope.numTrendRows = 2;
        }
        breakingIndex = getBreakingIndex($scope.legendTrendsRow2);
        if(breakingIndex > -1) {
            $scope.legendTrendsRow3 = $scope.legendTrendsRow2.slice(breakingIndex - 1);
            $scope.legendTrendsRow2 = $scope.legendTrendsRow2.slice(0, breakingIndex - 1);
            $scope.numTrendRows = 3;
        }
    };

    this.onResize = function() {
        self.determineLegendTrends();
        if($scope.expand && isDefined(this.trendChart)) {
            const m = isDefined(self.chartData) ? self.chartData.max : 100;
            const data = self.parameters.to_normalize ? self.chartData.averages : self.chartData.sums;
            const dataToSet = [];
            angular.copy(data, dataToSet);
            _.each(dataToSet, function(record) {
                record.value = record.original;
                delete record.original;
            });
            $timeout(function() {
                self.barChart.setData(dataToSet, self.chartData.maxChart);
                self.trendChart.draw(self.chartData, m, self.parameters.to_normalize ? 'normalized' : 'non_normalized');
                self.trendChart.addCircles($scope.examplesData.examples.topics);
            }, 0);
        } else if(isDefined(this.topChart)) {
            $timeout(function() {
                self.topChart.draw($scope.topicData, 'normal', $scope.valueMeasure.value);
            });
        }
    };

    this.setTopicDataFromGroupedData = function() {
        _.each(self.groupedTopicData, function(topics) {
            if(!_.isEmpty(topics.children)) {
                topics.children.sort(function(a, b) {
                    return _.sumBy(b.values, $scope.valueMeasure.value) - _.sumBy(a.values, $scope.valueMeasure.value);
                });
            }
        });
        self.groupedTopicData.sort(function(a, b) {
            return _.sumBy(b.values, $scope.valueMeasure.value) - _.sumBy(a.values, $scope.valueMeasure.value);
        });
        $scope.topicData = [];
        _.each(self.groupedTopicData, function(entry) {
            const topic_entry = angular.copy(entry);
            topic_entry.is_parent = !_.isEmpty(entry.children);
            setTooltipsInTopicEntry(topic_entry);
            delete topic_entry.children;
            $scope.topicData.push(topic_entry);
            if(!_.isEmpty(entry.children)) {
                _.each(entry.children, function(child) {
                    child.is_parent = false;
                    child.parent_id = entry.id;
                    setTooltipsInTopicEntry(child);
                    $scope.topicData.push(child);
                });
            }
        });
    };

    function setTooltipsInTopicEntry(entry) {
        const topic_text = entry.label;
        _.each(entry.values, function(value) {
            if($scope.valueMeasure.value != 'skew') {
                delete value.title;
                return;
            }
            const skew = TopBar.prototype.formatLabel(value.skew);
            const seed_text = value.label;
            if(skew == 0) {
                value.title = seed_text + " is unlikely to be consumed within " + topic_text;
                return;
            }
            if(skew < 1) {
                const reciprocal_skew = TopBar.prototype.formatLabel(1 / value.skew);
                value.title = seed_text + " is x" + reciprocal_skew + " times less likely to be consumed within " + topic_text + " than in all topics combined";
                return;
            }
            if(skew == 1) {
                value.title = seed_text + " is as likely to be consumed within " + topic_text + " as in all topics combined";
                return;
            }
            value.title = seed_text + " is x" + skew + " times more likely to be consumed within " + topic_text + " than in all topics combined";
        });
    }

    $scope.setTopicData = function(data) {
        self.groupedTopicData = data;
        self.setTopicDataFromGroupedData();
        $timeout(function() {
            topicsTopChartConfiguration.topBarGroup.graph.showGraph = self.showGraph;
            topicsTopChartConfiguration.topBarGroup.examples.showExamples = self.showExamples;
            self.topChart = new TopChart(self.type, null, '#split-bar-chart', topicsTopChartConfiguration);
            if(c.isArray($scope.topicData) && $scope.topicData.length > 0) {
                self.topChart.draw($scope.topicData, 'normal', $scope.valueMeasure.value);
            }
            self.onResize();
        }, 0);
    };

    this.getExportExamples = function(format) {
        const report = {name: 'Content Drivers', columns: [], table: []};
        const selectedTopic = self.selectedTopic.text || 'None';
        const info = [format('Selected Topic:', 'bold'), selectedTopic];
        const blank = [];
        const titles = [format('Date', 'bold'), format('Seed', 'bold'), format('Content Drivers', 'bold')];
        report.table = [info, blank, titles];
        report.columns = [{width: 18}, {width: 25}, {width: 100}];
        const result = self.consumptionService.getExamples($scope.trends, format);
        report.table = report.table.concat(result);
        return report;
    };

    self.cleanUp = (showGraph) => {
        self.trendChart.clean();
        self.chartData = self.chartInit();
        self.barChart.clean();
        $scope.examplesData.examples.topics  = [];
        $scope.noGraph = showGraph;
    };

    self.selectTopic = function(topic, type) {
        insightsExportService.addReportFunction(self.getTopTopicExportReport, true);
        insightsExportService.addReportFunction(self.getSubTopicExportReport, false);
        insightsExportService.addReportFunction(self.getConsumptionExportReport, false);
        _.each($scope.topicData, (t, i) => t.selected = t.id == topic.id);
        $scope.chartHeader = topic.label;
        self.selectedParentTopicLabel = topic.label;
        self.selectedSubTopicLabel = '';
        if(topic.parent_id) {
            const parent_topic = _.find($scope.topicData, {'id': topic.parent_id});
            if(typeof parent_topic == 'object') {
                $scope.chartHeader = parent_topic.label +
                                     '  <span class="sub-topic-triangle">&#9658;</span>  <span class="sub-topic-text">' +
                                     $scope.chartHeader + '</span>';
                self.selectedSubTopicLabel = parent_topic.label;
            }
        }
        if(topic.id != self.selectedTopic.id && isDefined(self.timeframe) && isArray($scope.trends)) {
            self.resetTopicObjects();
            $scope.examplesData.visible_examples = [];

            if($scope.trends.length < 1) {
                self.cleanUp(true);
            } else {
                self.cleanUp(false);
                self.parameters.channel = $scope.insightsChannel.value;
                self.selectedTopic = topic;
                self.trendChart.resetHighlightedCircle();
                self.openObject(type);
                $scope.examplesData.promise = self.consumptionService.get(self.parameters, self.selectedTopic);
                return $scope.examplesData.promise.then(function(data) {
                    self.$scope.averageTopicsFactor = 1;
                    if(isDefined(data) && isArray(data.averages) && isNumber(data.max)) {
                        $scope.noTrendsData = false;
                        $scope.noGraph = false;
                        $scope.examplesData.examples.topics = data.examples;
                        _.each($scope.examplesData.examples.topics, (ex, i) => ex.class = $scope.termClasses[ex.keyword]);
                        $scope.examplesData.examples.topics = _.filter($scope.examplesData.examples.topics,
                            example => $scope.termClasses[example.keyword]);
                        $scope.examplesData.visible_examples = $scope.examplesData.examples.topics;
                        const max = self.parameters.to_normalize ? data.max : data.maxChart;
                        const dataToIterate = self.parameters.to_normalize ? data.averages : data.sums;
                        self.$scope.averageTopicsFactor = 100 / (max > 0 ? max : 100);
                        forEach(dataToIterate, function(entry) {
                            entry.original = entry.value;
                            if(isNumber(entry.value) && !isNaN(entry.value)) {
                                entry.value = entry.value * self.$scope.averageTopicsFactor;
                            } else {
                                entry.value = 0;
                            }
                        });
                        self.openObject(type);
                    }
                    $timeout(function() {
                        self.chartData = data;
                        self.onResize();
                    }, 0);
                }, function(err) {
                    $scope.noTrendsData = true;
                    $scope.noGraph = true;
                });
            }
        } else {
            self.openObject(type);
            $timeout(() => {self.onResize()}, 0);
        }
    };

    this.openObject = function(type) {
        if(type == 'graph') {
            $scope.expand = true;
            $scope.noTrendsData =  false;
            $scope.examplesData.alphabetized = true;
        } else if(type == 'contentDrivers') {
            $scope.examplesData.visible_examples = $scope.examplesData.examples.topics;
            $scope.examplesData.open = true;

            $scope.$root.$broadcast('openContentDrivers', "content_drivers");
        }
    };

    this.resetTopicObjects = function() {
        self.resetContentDriversPaneFilters();
        self.resetHighlightedLetter();
        self.resetVisibleContentDrivers();
        $scope.expand = false;
        $scope.noTrendsData = true;
        $scope.examplesData.alphabetized = false;
    };

    this.unselectTopic = function() {
        $scope.chartHeader = '';
        self.selectedParentTopicLabel = '';
        self.selectedSubTopicLabel = '';
        self.determineLegendTrends();
        document.getElementsByClassName("selected-row")[0] && document.getElementsByClassName("selected-row")[0].classList.remove("selected-row");
        _.each($scope.topicData, function(topic, i) {
            topic.selected = false;
        });
        const examplesResetNeeded = !!self.selectedTopic.id;
        self.selectedTopic = {id: null};
        self.insightsExportService.addReportFunction(self.getTopTopicExportReport, true);
        self.insightsExportService.addReportFunction(self.getSubTopicExportReport, false);
        self.resetTopicObjects();
        if(examplesResetNeeded) {
            self.setConsumptionContentExamples();
        }
    };

    this.getTopTopicExportReport = function(format) {
        const table = [
            [format('Topic', 'bold'), format('Seed', 'bold'), format('Consumption', 'bold'),
             format('Topic Distribution', 'bold'), format('Skew', 'bold')]
        ];
        const data = self.topicsTrendService.getLatestData();
        if(data) {
            _.each(data, function(topic) {
                _.each(topic.values, function(term) {
                    table.push([topic.label, term.label,
                        format(term.consumption, 'numeric'),
                        format(term.distribution / 100, 'percent'),
                        format(term.skew, 'numeric')]);
                });
            });
        }
        const columns = [{width: 45}, {width: 35}, {width: 18}, {width: 18}, {width: 18}];
        return {name: topicsExportName, columns, table};
    };

    this.getSubTopicExportReport = function(format) {
        const table = [
            [format('Topic', 'bold'), format('Sub-Topic', 'bold'), format('Seed', 'bold'),
             format('Consumption', 'bold'), format('Topic Distribution', 'bold'), format('Skew', 'bold')]
        ];
        const data = self.topicsTrendService.getLatestData();
        if(data) {
            _.each(data, function(topic) {
                if(!_.isEmpty(topic.children)) {
                    _.each(topic.children, function(child) {
                        _.each(child.values, function(child_term) {
                            table.push([topic.label, child.label, child_term.label,
                                format(child_term.consumption, 'numeric'),
                                format(child_term.distribution / 100, 'percent'),
                                format(child_term.skew, 'numeric')]);
                        });
                    });
                }
            });
        }
        const columns = [{width: 45}, {width: 45}, {width: 35}, {width: 18}, {width: 18}, {width: 18}];
        return {name: subTopicsExportName, columns, table};
    };

    this.getConsumptionExportReport = function(format, avgMode) {
        const report = {
            name: avgMode ? consumptionChangeFromAvgExportTabName : consumptionExportTabName,
            columns: [],
            table: []
        };
        const terms = self.util.getValidTerms($scope, self.parameters);
        if(c.isArray(terms)) {
            report.columns = [{width: 18}];
            const selectedTopic = self.selectedTopic.label || 'All';
            const info = [format('Selected Topic:', 'bold'), selectedTopic];
            const blank = [];
            const titles = [format('Date', 'bold')];
            _.each(terms, function(term) {
                titles.push(format(term.text, 'bold'));
                report.columns.push({width: 25});
            });
            report.table = [info, blank, titles];
            const cellType = self.parameters.to_normalize ? 'numeric' : 'integer';
            const result = self.consumptionService.getValuesByDateTable(terms, format, cellType, avgMode);
            report.table = report.table.concat(result);
        }
        return report;
    };

    this.setConsumptionContentExamples = function() {
        let params = _.clone(self.parameters);
        params.examples_only = true;
        $scope.examplesData.promise = self.consumptionService.get(params, {});
        return $scope.examplesData.promise.then(self.handleConsumptionExamplesResult).catch(function(err) {});
    };

    this.handleConsumptionExamplesResult = function(data) {
        if(isDefined(data)) {
            $scope.examplesData.icon = data.icon;
            $scope.examplesData.examples.consumption = data.examples;
            _.each($scope.examplesData.examples.consumption, function(ex, i) {
                ex.class = $scope.termClasses[ex.keyword];
            });
            $scope.examplesData.examples.consumption = _.filter($scope.examplesData.examples.consumption,
                example => $scope.termClasses[example.keyword]);
            if($state.includes('*.topic')) {
                angular.copy($scope.examplesData.examples.consumption, $scope.examplesData.visible_examples);
            }
        }
    };

    this.resetContentDriversPaneFilters = function() {
        _.each($scope.examplesData.filters, function(trend) {
            trend.show = true;
        });
    };

    this.resetVisibleContentDrivers = function() {
        _.each($scope.examplesData.visible_examples, function(example) {
            example.hide = false;
            example.highlight = false;
            example.selected = false;
        });
    };

    this.resetHighlightedLetter = function() {
        self.trendChart.resetHighlightedCircle();
        $scope.$emit('resetLetterClicked');
    };

    $scope.closeGraph = function() {
        self.resetTopicObjects();
        $timeout(function() {
            self.topChart.draw($scope.topicData, 'normal', $scope.valueMeasure.value);
        });
    };

    $scope.resetTopics = function() {
        self.resetTopicObjects();
        self.unselectTopic();
    };

    $scope.setValueMeasure = function(measure) {
        MixpanelAudience.trackViewChange(measure, 'Topic Trends widget', 'Insights');
        $scope.valueMeasure = _.find($scope.valueMeasures, {value: measure}) || $scope.valueMeasures[0];
        self.setTopicDataFromGroupedData();
        $timeout(() => {
            self.unselectTopic();
            self.onResize();
        });
    };

    $scope.$on('barChartContentDriversClicked', function(event, topic) {
        self.selectTopic(topic, 'contentDrivers');
    });

    $scope.$on('barChartGraphClicked', function(event, topic) {
        self.selectTopic(topic, 'graph');
    });

    $scope.$on('contentDriverMouseover', function(event, letter) {
        self.trendChart.highlightCircleFromLetter(letter, true);
    });

    $scope.$on('contentDriverMouseout', function(event, letter) {
        self.trendChart.highlightCircleFromLetter(letter, false);
    });

    $scope.$on('contentDriverClick', function(event, letter) {
        self.trendChart.resetHighlightedCircle();
        self.trendChart.letterClicked = letter;
        self.trendChart.highlightCircleFromLetter(letter, true);
    });

    $scope.$on('contentDriversPaneOpen', function(event) {
        self.openObject('contentDrivers');
        if(!self.selectedTopic.id) {
            self.setConsumptionContentExamples();
        }
    });

    $scope.$on('widgetPanelClick', function() {
        if($scope.expand) {
            self.trendChart.resetHighlightedCircle();
        } else if(!$scope.expand && self.selectedTopic.id) {
            $scope.resetTopics();
        }
    });

    $scope.noGraph = false;

    $scope.hasNoGraph = function () {
        $scope.noGraphMsg = TREND_LINE_ERROR_MESSAGE;
        return $scope.noGraph;
    };

    insightsExportService.setTitle('Topics');
}

TopicTrendsController.prototype._doUpdate = function(values, changedVals) {
    this.insightsExportService.clear();
    this.insightsExportService.addReportFunction(this.getTopTopicExportReport);
    this.insightsExportService.addReportFunction(this.getSubTopicExportReport);
    this.insightsExportService.setExampleFunction(this.getExportExamples);
    const $scope = this.$scope;
    const $location = this.$location;
    const self = this;
    values = _.pick($scope, ['topics', 'terms', 'active', 'insightsChannel', 'geo', 'timeframe', 'audience', 'sgTelcoAudience', 'sub_geos','userFirstPartyAudience']);
    this.selectedTopic = {id: null};
    $scope.noData = true;
    $scope.examplesData.visible_examples = [];
    $scope.examplesData.examples.topics = [];
    $scope.examplesData.visible = true;
    $scope.examplesData.filter_type = 'trend';
    $scope.examplesData.icon = c.getChannelIcon(values.insightsChannel.value);
    $scope.insightsChannel = c.getCurrentChannel($scope.insightsChannel, $scope.$root.insightsTopicsChannelsFilter, this.permissions);
    if($scope.channel == 'videos') {
        $scope.valueMeasure = _.find($scope.valueMeasures, {value: 'consumption'});
    }
    c.logUpdate('Topics', values);
    if ($location.path() == "/insights/topic") { //Don't set timeframe vars if we're stepping out of widget
        let times = ["1M", "3M", "6M", "1Y"];
        $scope.$root.$broadcast('timeframe-vars-update', times, this.TIMES.INSIGHTS_OFFSET, false, true)
    }

    const nonPhrases = c.validateNonPhrases($scope.terms, $scope.insightsChannel.value, this.notificator);
    this.parameters = this.util.buildInsightsParameters(values);
    $scope.trends = this.parameters.trends;
    $scope.examplesData.terms = $scope.trends;
    $scope.termClasses = {};
    _.each($scope.trends, trend => $scope.termClasses[trend.text] = $scope.termClasses[trend.id] = trend.class);
    const filteringFn = t => ['mention', 'hashTag', 'post'].indexOf(t.type) === -1 && !self.util.isSocialBooleanLogic(t);
    $scope.legendTrends = nonPhrases ? $scope.trends.filter(filteringFn) : angular.copy($scope.trends);
    $scope.legendTrendsRow2 = [];
    $scope.legendTrendsRow3 = [];
    $scope.numTrendRows = 1;
    _.each($scope.legendTrends, function(trend) {
        trend.display = trend.display || trend.text;
        trend.display = c.cutText(trend.display, 15, '...');
    });
    angular.copy($scope.legendTrends, $scope.examplesData.filters);
    this.resetTopicObjects();

    this.timeframe = values.timeframe;
    this.topics = values.topics;
    this.geo = values.geo;
    consumptionTopicsAverageConfiguration.title = this.parameters.to_normalize ? 'Average' : 'Total';
    consumptionTopicsAverageConfiguration.precision = this.parameters.to_normalize ? 1 : 0;
    this.barChart = new VerticalBarChart(this, TREND_CHART_ENTRIES, consumptionTopicsAverageConfiguration);
    this.insightsExportService.initSummaryFields();
    this.insightsExportService.addSummaryFields({label: 'Selected Topic:',
                                                 value_f: (entry, field) => (self.selectedTopic.label || "None") });
    let export_parameters = [];
    if(this.baseInsightsService.shouldSendAudience(this.parameters.channel, this.permissions)) {
        this.insightsExportService.addSummaryFields(ex.AUDIENCE_SUMMARY_FIELD);
        export_parameters = _.extend({}, this.parameters, {audience: _.map(c.getAudience($scope, this.parameters.channel), 'summary')});
    } else {
        export_parameters = _.clone(this.parameters);
    }
    if(this.parameters.channel == 'articles' && this.permissions.hasPermission('first party segments') && !_.isEmpty($scope.userFirstPartyAudience)) {
        this.insightsExportService.addSummaryFields(ex.FIRST_PARTY_SEGMENTS_SUMMARY_FIELD);
        export_parameters.first_party_audience = _.map($scope.userFirstPartyAudience, 'summary');
    } else {
        delete this.parameters.user_first_party_audience;
    }
    this.insightsExportService.setParams(export_parameters);
    this.setConsumptionContentExamples();

    $scope.examplesData.promise = this.topicsTrendService.getTrendsByTopics(self.parameters);
    return $scope.examplesData.promise.then(function(response) {
        $scope.noData = false;
        self.$timeout(function() {
            $scope.setTopicData(response);
        }, 0);
    }, function(error) {
        if (error != "No Terms") {
          $scope.$emit('insightsError', error);
        }
        return error;
    });
};


TopicTrendsController.$inject = ["$scope", "$timeout", "$state", "topicsTrendService", "consumptionTrendService",
                                 "util", "examplesDataModel", "insightsExportService",
                                 "abiPermissions", "baseInsightsService", "TIMES", "filtersPartition", "notificator",
                                 "$location", "context"];


module.exports = angular.module(__filename, [
    require('data/insights/topics-trend-service').name
]).directive("topicTrendsWidget", [() => BaseWidget({
    restrict: "E",
    template: Template,
    scope: {
        topics: "=",
        terms: "=",
        active: "=",
        insightsChannel: '=insightsChannel',
        audience: '=',
        sgTelcoAudience: '=',
        userFirstPartyAudience: '=',
        geo: "=",
        sub_geos: "=subGeos",
        timeframe: "=",
        cacheBaster: "="
    },
    controller: TopicTrendsController
})]).filter("trusted_html", ['$sce', $sce => htmlCode => $sce.trustAsHtml(htmlCode)]);
