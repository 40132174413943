var common = require("infra/utils/common");

module.exports = require("angular").module(__filename, []).directive('amTabs', [function () {
    return {
        restrict: 'E',
        template: require('./am-tabs.drv.html'),
        scope: {
            "tabs": "=",
            "selected": "=",
            "onSelect": "&"
        },
        link: function ($scope, $element) {
            var onSelect = $scope.onSelect() ? $scope.onSelect() : angular.noop;
            var font = $element.css('font').replace("Helvetica", "Helvetica Bold");
            var tabWidthMap = {};
            var TABS_HORIZ_PAD = 30;

            $scope.select = function (tab) {
                if (!$scope.isSelected(tab)) {
                    $scope.selected = tab.value;
                    onSelect(tab.value);
                }
            };

            $scope.isSelected = function (tab) {
                return $scope.selected == tab.value;
            };

            $scope.getTabWidth = function (tab) {
                return tabWidthMap[tab.value]
            };

            $scope.$watch('tabs', function () {
                if (!$scope.tabs) return;

                tabWidthMap = $scope.tabs.reduce(function (tmpTabWidthMap, tab) {
                    tmpTabWidthMap[tab.value] = common.getTextWidth(tab.label, font) + TABS_HORIZ_PAD;
                    return tmpTabWidthMap;
                }, {});

                if (!$scope.selected) $scope.select($scope.tabs[0]);
            })
        }
    }
}]);
