function RegionsController($rootScope) {
}

RegionsController.$inject = ["$rootScope"];

module.exports.stateConfig = {
    name: "regions",
    url: "/regions",
    template: require("./regions.insights.html"),
    display: "Insights",
    data: {
    	permissions: ['regions']
    },
    controller: RegionsController,
    context: {}
};
