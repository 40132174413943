"use strict";
import ComplianceModal from 'react/components/apps/channelInsights/ComplianceModal/ComplianceModal';
import TargetForecastWidget from '../../widgets/target-forecast-widget/target-forecast-widget';
import { react2angular } from 'react2angular';
import { getFirstPartyDataByProgram } from '../../react/services/AudienceInsightsService';
import ToggleSwitch from 'react/components/common/ToggleSwitch/ToggleSwitch';
import * as MixpanelInterests from '../../react/infra/mixpanel/MixpanelInterests';

const customFilter = require("infra/context/filters/custom-filter");
const c = require("infra/utils/common");
const interestsModule = angular.module(__filename, [
    require("widgets/bubbles-widget/bubbles-widget").name,
    require("widgets/streams-widget/streams-widget").name,
    require("data/interests-export-service").name,
    require("data/stream-export-service").name,
    require("infra/context/filters/channels-mold").name,
    require("infra/context/filters/bubblesChannels-mold").name,
    require("infra/context/filters/articleType-mold").name,
    require("infra/context/filters/language-mold").name,
    require('./grid.interests.prediction').name,
    require('pages/interests/grid.queries').name,
    require("pages/programs/program_settings/program-settings").name,
    require("infra/user_access/abi-permissions").name,
    require("common/bubbles/bubbles-filter-bar/bubbles-filter-bar").name,
    require("common/bubbles/bubbles-actions-bar/bubbles-actions-bar").name
]);

interestsModule.component('toggleSwitch', react2angular(ToggleSwitch));
interestsModule.component('complianceModal', react2angular(ComplianceModal, ['phrasesByTab', 'topics', 'text', 'isOpen' ,'saveWithNonCompliant', 'saveWithoutNonCompliant', 'onCloseCompliance']));
interestsModule.component('targetForecastWidget', react2angular(TargetForecastWidget, ['phrases', 'topics', 'geo', 'isDynamic', 'incOrExc', 'allGeos']));

InterestsController.$inject = ["$scope", "$rootScope", "$http", "$state", "$window", "$timeout", "programService",
                               "ModalService", "context", "filtersPartition", "streamsService", "abiPermissions",
                               "audienceSkewService", "audienceMgmt", "notificator"];
function InterestsController(scope, $rootScope, $http, $state, $window, $timeout, programService, ModalService, context,
                             filtersPartition, streamsService, permissions, audienceSkewService, audienceMgmt,
                             notificator) {

    let syncFiltersTimer;

    scope.$state = $state;
    scope.context = context;
    scope.refreshAudienceSummary = 0;
    scope.tFires = false;

    syncFiltersOnInit();

    $rootScope.Geos.setSubGeos(scope, () => context.current._interestsSubGeos_mold, c.getChannels($state, context));
    const listener = $rootScope.$on('$stateChangeSuccess', () => {
        syncFiltersOnInit();
        $rootScope.Geos.setSubGeos(scope, () => context.current._interestsSubGeos_mold, c.getChannels($state, context));
    });

    //events
    scope.keyDown = ($event) => scope.checkKey($event, true);
    scope.keyUp = ($event) => scope.checkKey($event, false);
    scope.checkKey = ($event, down) => {
        //shift = if combined with click, un/mark bubbles
        let shiftPressed = $event.keyCode === 16;
        if (shiftPressed) {
            scope.shiftDown = down;
        }
        //esc = unmark all bubbles
        let escPressed = $event.keyCode === 27;
        if (escPressed && down) {
            $.each($("#balls g, .brick"), (ind, item) =>{
                item = $(item);
                let cls = item.attr("class");
                if (cls) { //no $ as svg doesnt support $.removeClass
                    item.attr("class", cls.replace("shift-marked", '').trim());
                }
            });
        }
    };

    $window.addEventListener('keydown', scope.keyDown);
    $window.addEventListener('keyup', scope.keyUp);
    scope.$on('$destroy', () => {
        $window.removeEventListener("keydown", scope.keyDown);
        $window.removeEventListener("keyup", scope.keyUp);
        listener();
    });

    scope.isSupportLanguage = function () {
        context.current._language_mold.languageSelectRefreshHandler($state, context);
        return context.current._language_mold.isSupportLanguage($state, context);
    };

    scope.shouldShowTopics = () => context.current._language_mold.shouldShowTopics($state, context);
    scope.supportedLanguages = () => context.current._language_mold.supportedLanguages($state, context);

    scope.editSources = function () {
        programService.program(context.program.id).then(function (program) {
            ModalService.showModal({
                template: require('pages/programs/program_settings/program-settings.html'),
                inputs: {program: program, initialView: 'connect sources'},
                controller: 'programSettingsController'
            });
        }, function () {
            console.log("failed Edit");
            notificator.error({body: "Edit canceled! Could not load program, please check internet connection"});
        });
    };

    function onDestroy() {
        closeListener();
        $timeout.cancel(syncFiltersTimer);
    }
    let closeListener = context.onChange(MixpanelInterests.trackInterestsSearch);
    scope.$on('$destroy', onDestroy);
    scope.audienceSummaryOrder = _.compact(_.map(filtersPartition.genders.concat(filtersPartition.children).concat(filtersPartition.age)
        .concat(filtersPartition.income).concat(filtersPartition.racesWithAll), "summary"));

    scope.trackFilterChange = MixpanelInterests.trackInterestsSearchChange;
    scope.gridAdvancedSummary = () => customFilter.summary('gridAdvanced', context.current.adv);
    scope.gridAdvancedTitles = (text) => customFilter.gridAdvanced.titles[text];

    scope.gridAdvancedShow = () => $state.includes('interests.grid') &&
                                   context.current.adv && Object.keys(context.current.adv).length;
    scope.interestsGeos = () => $rootScope.Geos.geosForChannel($rootScope.Geos.serverValue(), $state, context);

    scope.showAudienceFilter = function () {
        if (false === context.current._language_mold.isShowAudience($state, context)){
            return false;
        }

        const channels = c.getChannels($state, context);
        if ($state.is('interests.board') && (context.current.showCustomSources || (channels.includes('sg_telco') && (channels.length > 1)))) return false;

        const channelSources = _.groupBy(channels, c.getSource);
        let shouldDisplayAudience = _.size(channelSources) === 1 && _(['articles', 'sg_telco']).includes(_(channelSources).keys().first());
        const hasSgTelcoAudiencePermission = permissions.hasPermission('sg telco audience filters');
        if (channelSources['sg_telco']) shouldDisplayAudience = shouldDisplayAudience && hasSgTelcoAudiencePermission;
        return shouldDisplayAudience;
    };

    scope.showGeoFilter = () => {
        if (context.current._gridChannels_mold._value.value === 'sg_bidstream' ||
            ($state.is('interests.board') && context.current.showCustomSources) ||
            ($state.is('interests.bubbles') && context.current.bubblesShowCustomSources)) return false;

        return scope.showSubGeos();
    };

    scope.showSubGeos = () => {
        const channels = c.getChannels($state, context);
        return $rootScope.Geos.showSubGeos(channels) &&
          !(channels.includes('sg_telco') && !permissions.hasPermission('SG Telco Electoral Filter'));
    };

    scope.showMediaTypeFilter = () => $state.is('interests.board') &&
                                      (!context.current.showCustomSources && !scope.hideMediaFilter);

    scope.getCustomAudienceSegments = async function(program, lifestyles, channel) {
        if (!program || !program.id || !permissions.hasPermission('audience') ) return;

        const audienceChannel = channel === 'sg_telco' ? 'data_spark' : channel;
        const audienceByChannel = await audienceMgmt.list(audienceChannel);
        const lifestylesSegments = channel === 'articles' ? lifestyles : [];
        const audienceCustomSegments = audienceSkewService.getAudienceCustomSegments(audienceByChannel);
        let firstPartyAudienceSegments = [];

        if (permissions.hasPermission('first party segments') && channel === 'articles') {
            const firstPartyDataByProgram = await getFirstPartyDataByProgram(program);
            firstPartyAudienceSegments = firstPartyDataByProgram.map(({label, value}) => ({
                label,
                value,
                segment: [{type: '1st party', value, operand: {value: 'and'}}]
            }));
        }

        return _.sortBy(audienceCustomSegments.concat(firstPartyAudienceSegments).concat(lifestylesSegments), s => s.label.toLowerCase());
    };

    scope.$watch('context.current._gridChannels_mold._value', (newChannel, oldChannel) => channelChanged(newChannel, oldChannel, 'gridChannel'));
    scope.$watch('context.current._channels_mold._value', (newChannel, oldChannel,) => channelChanged(newChannel, oldChannel,'channels'));
    scope.$watch('context.current._bubblesChannels_mold._value', (newChannel, oldChannel) => channelChanged(newChannel, oldChannel, 'bubbles'));
    scope.$watch("context.current._interestsSubGeos_mold._value", (newValue, oldValue) => {
        if(scope.dataTrees?.subGeos?.isAllChecked) {
            context.current.interestsAllSubGeosSelected = scope.dataTrees.subGeos.isAllChecked();
        }
        if(!_.isEqual(newValue, oldValue)) {
            scope.trackFilterChange();
        }
    });

    scope.$watch("firstLevelGeos", (newValue, _oldValue) => {
        if(!newValue) return;
        scope.context.current.geo = newValue;
    })

    function channelChanged(newChannel, oldChannel, currentContextName) {
       //media types
       const streamsChannels = streamsService.getChannels(context.current.channels);
       const mediaTypes = _.flatten(Object.values(_.pick(filtersPartition.mediaTypes, streamsChannels)));
       const intersectionFilters = _.uniqBy(mediaTypes, 'value');

       context.current._articleType_mold.replace(_.intersectionBy(context.current.articleType, intersectionFilters, 'value'));

       // Show the filters only when there is more then 1 type for each channel
       scope.hideMediaFilter = streamsChannels.length >= mediaTypes.length;
       scope.mediaTypes = intersectionFilters;
       scope.channels = c.getChannels($state, scope.context);

       $timeout(() => syncChannelsFilter(currentContextName));

       $rootScope.Geos.setSubGeos(scope, () => context.current._interestsSubGeos_mold, c.getChannels($state, context));
    }

    function syncChannelsFilter(currentContextName) {
        const newChannel = getCurrentChannelData(currentContextName)?.currentChannel;

        if (!newChannel) {
            return;
        }

        if ($state.includes('interests.grid')) {
            context.current.bubblesShowCustomSources = false;
            context.current.showCustomSources = false;
        }

        if (currentContextName !== 'bubbles' && !$state.includes('interests.bubbles')) {
            context.current.bubblesShowCustomSources = context.current.showCustomSources;
            context.current.lastSelected = _.findIndex($rootScope.bubblesChannelsFilter, {value: newChannel.value});
            context.current._bubblesChannels_mold._value = newChannel;
        }
        if (currentContextName !== 'gridChannel' && !$state.includes('interests.grid')) {
            context.current.gridChannels = context.current.bubblesShowCustomSources && _.isEmpty(newChannel) ? {value: null } : newChannel;
        }
        if (currentContextName !== 'channels' && !$state.includes('interests.board')) {
            context.current.channels =  _.isArray(newChannel) ? newChannel : [newChannel];
            context.current.showCustomSources = context.current.bubblesShowCustomSources;
        }
        context.current._insightsChannels_mold._value = newChannel;
    }


    function syncFiltersOnInit() {
        let currentContextName;
        if ($state.includes('interests.board')) {
            currentContextName = 'channels';
        }
        if ($state.includes('interests.bubbles')) {
            currentContextName = 'bubbles';
        }
        if ($state.includes('interests.grid')) {
            currentContextName = 'gridChannel';
        }
        if (currentContextName) {
            handleCurrentChannel(currentContextName);
            syncFiltersTimer = $timeout(() => syncChannelsFilter(currentContextName));
        }
    }

    function handleCurrentChannel(currentContextName) {
        const { currentChannel, channelsList } = getCurrentChannelData(currentContextName);
        let newChannel;

        const {showCustomSources , bubblesShowCustomSources} = context.current;
        if (($state.includes('interests.board') && showCustomSources) ||
            ($state.includes('interests.bubbles') && bubblesShowCustomSources) ||
            (currentContextName === 'channels' && currentChannel.value === 'unified')) {
            return;
        }

        if (_.findIndex(channelsList, {value: currentChannel.value}) < 0) {
            if (currentContextName === 'channels') {
                context.current.channels = [$rootScope.streamsChannelsFilter[0]];
                newChannel = $rootScope.streamsChannelsFilter[0];
            }
            if (currentContextName === 'bubbles') {
                context.current.lastSelected = 0;
                newChannel = $rootScope.bubblesChannelsFilter[0];
            }
            if (currentContextName === 'gridChannel') {
                context.current.gridChannels = $rootScope.gridChannelsFilter[0];
                newChannel = $rootScope.gridChannelsFilter[0];
            }

            const oldChannelName = showCustomSources || bubblesShowCustomSources ? 'custom sources' : currentChannel.label;
            notificator.notify({body: `Channel has switched to ${newChannel.label} as ${oldChannelName} is not supported for this insight`});
        }
    }

    function getCurrentChannelData(currentContextName) {
        if (currentContextName === 'channels') {
            let currentChannel;
            if (context.current.showCustomSources) {
                currentChannel = context.current.channels;
            } else {
                currentChannel = context.current.channels.length === 1 ? context.current.channels[0] : {label: "Unified", value: "unified"};
            }
            return { currentChannel, channelsList: $rootScope.streamsChannelsFilter };
        }
        if( currentContextName === 'bubbles') {
            return { currentChannel: context.current._bubblesChannels_mold._value, channelsList: $rootScope.bubblesChannelsFilter };
        }
        if(currentContextName === 'gridChannel') {
            return { currentChannel: context.current.gridChannels, channelsList: $rootScope.gridChannelsFilter };
        }
        return {};
    }
}

const bubbles = require('./bubbles.interests.js').stateConfig;
const streams = require('./board.interests.js').stateConfig;
const grid = require('./grid.interests.js').stateConfig;

interestsModule.stateConfig = {
    name: "interests",
    url: "/interests",
    template: require("./interests.html"),
    display: "Interests",
    abstract: true,
    reload: false,
    context: {},
    data: {
        permissions: ["interests"]
    },
    children: [bubbles, streams, grid],
    controller: InterestsController,
    controllerAs: "interestsCtrl"
};

export default interestsModule;
