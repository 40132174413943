import { executeWithProxyTower } from '../infra/HttpClient';
import {
  displayCustomError,
  getContext,
  getGeoService,
  getAbiPermissionsService,
  getKeywordsService,
} from '../middleware/AngularExportedMiddleware';
import {
  clone,
  forEach,
  fromPairs,
  get,
  isEmpty,
  keyBy,
  map,
  mapKeys,
  mapValues,
  omit,
  orderBy,
  pick,
  remove,
  some,
  sortBy,
  sum,
  hasIn,
  compact,
  concat,
  uniq,
  zip,
} from 'lodash';
import AudienceProfilerApi from '../api/AudienceProfilerApi';
import {
  convertAudienceSegmentToLogicStatement,
  convertAudienceSegmentToFilterMapping,
  geoByChannel,
  convertTvShowsToNewLogicStatement,
} from '../../data/audience-segment-builder-helper';
import { getRawInterestsDataV1, getSegmentInterestsData } from './AudienceInsightsService';
import { roundPercents } from '../../react/utils/NumberUtils';

const REQUEST_STATUS_CANCEL = -1;
const TV_CHANNELS = ['smart_tv_inscape', 'tivo', 'hisense'];
export const QUERY_NAMES = {
  phrases: 'xw',
  websites: 'td',
  topics: 'topics-a',
  searches: 'gsw',
  searchesBidStream: 'iw',
  apps: 'app',
};
export const QUERY_NAMES_V2 = {
  phrases: 'frequent-keywords',
  websites: 'domains',
  topics: 'topics',
  searches: 'searches',
};

let lastDemographicsCacheKey;
let lastReqCacheKey;
let lastDemographicsPromise;
let lastReqPromise;
const segmentPropertiesReqs = {};
const segmentsIdsPromiseCache = {};
const linkedinMetaDataPromiseCache = {};
const linkedinAccessTokenPromiseCache = {};
let tvGenresMetaDataPromiseCache;
let firstPartyMetaDataPromiseCache;

export function linkedinTokenExpired() {
  displayCustomError('Your LinkedIn access token has expired, please refresh the page to continue.');
}

export function responseFailed() {
  return function (err) {
    return get(err, 'err.config.timeout.$$state.value') === 'AMOBEE_CANCEL_OK' ? REQUEST_STATUS_CANCEL : false;
  };
}

export function normalizeFirstPartyRecord(record) {
  const segment = clone(record.segment);
  segment.programs = record.programs.map(function (program) {
    program.name =
      program && /interests/.test(program.name) && program.name !== 'guest interests' ? 'My Interests' : program.name;
    return program;
  });
  segment.creator = record.creator;
  segment.label = segment.name;
  segment.value = segment.segment_value;
  return segment;
}

export function deleteSegmentFromContext(segmentValue) {
  const context = getContext();
  if (hasIn(context, 'current.firstPartyAudience')) {
    /* eslint-disable-next-line camelcase */
    remove(context.current.firstPartyAudience, { segment_value: segmentValue });
  }
}

export function updateSegmentNameInContext(segmentId, name) {
  const context = getContext();
  if (hasIn(context, 'current.firstPartyAudience')) {
    forEach(context.current.firstPartyAudience, function (rec) {
      if (rec.id === segmentId) {
        rec = { ...rec, name: name, label: name, summary: name };
      }
    });
  }
}

export function convertSegmentToLinkedinAudience(audience, isCountApi = false) {
  const propertyToApi = {
    age: 'ages',
    country: 'countries',
    functions: 'functions',
    gender: 'genders',
    industries: 'industries',
    jobTitles: 'titles',
    names: 'companies',
    regions: 'regions',
    seniorities: 'seniorities',
    sizes: 'company_sizes',
    state: 'states',
  };
  let audienceAsKeyValMap = audience.reduce(
    (acc, curVal) =>
      Object.assign(
        {},
        acc,
        mapKeys(curVal, (value, key) => propertyToApi[key])
      ),
    {}
  ); // {ages: [{label: '1-10', value: 'urn:xxx'}, {...}], genders: [{...},{...}]}
  audienceAsKeyValMap = pick(audienceAsKeyValMap, Object.values(propertyToApi)); // select only needed attributes
  if (isCountApi) {
    // In count api, 'locations' contains the values for 'countries', 'states' and 'regions' but needs only the last defined audience in the hierarchy
    let locations = [audienceAsKeyValMap['regions'], audienceAsKeyValMap['states'], audienceAsKeyValMap['countries']];
    locations = locations.find((location) => location !== undefined) || [];
    const { countries, states, regions, ...audienceWithLocation } = {
      ...audienceAsKeyValMap,
      locations,
    };
    audienceAsKeyValMap = audienceWithLocation;
  }
  return mapValues(audienceAsKeyValMap, (val) => val.map((val) => val.value || val.id)); // {age: ['urn:xxx', 'urn:yyy'], gender: [...]}
}

export function deleteContainedPhrases(phrases) {
  function markIfContain(a1, a2) {
    let i = 0;
    let j = 0;
    while (i < a1['words_arr'].length && j < a2['words_arr'].length) {
      const compare = a1['words_arr'][i].localeCompare(a2['words_arr'][j]);
      if (compare === 1) return;
      if (compare === 0) j++;
      i++;
    }
    if (j < a2['words_arr'].length) return;
    a2['contained'] = true;
  }

  const MIN_DEST_BETWEEN_SIMILAR_TERMS = 20;
  if (phrases.length <= MIN_DEST_BETWEEN_SIMILAR_TERMS) return phrases;
  for (let i = 0; i < phrases.length; i++) {
    const lastIndex = Math.min(i + MIN_DEST_BETWEEN_SIMILAR_TERMS, phrases.length);
    for (let j = i + 1; j < lastIndex; j++) {
      const phrase1 = phrases[i]['longest-term-check'];
      const phrase2 = phrases[j]['longest-term-check'];
      if (phrase1['num_of_words'] !== phrase2['num_of_words']) continue;
      phrase1['num_of_words'] > phrase2['num_of_words']
        ? markIfContain(phrase1, phrase2)
        : markIfContain(phrase2, phrase1);
    }
  }
  return phrases.filter((phrase) => !phrase['longest-term-check']['contained']);
}

export function fixAgeLegal(data, dataType = 'distribution') {
  const key = `age-${dataType}`;
  if (data && data[key]) {
    data[key] = Object.assign({ '13-17': data[key]['12-17'] }, omit(data[key], ['2-11', '12-17']));
  }
  return data;
}

export function roundSegmentRatio(ratio) {
  ratio = +(ratio * 100).toFixed(2);
  return ratio < 0.1 ? Math.max(ratio, 0.01) : parseFloat(ratio.toFixed(1));
}

const formatDist = (value) => `${Math.round(value)}%`;
const formatSkew = (value, decimalPoint) => value.toFixed(decimalPoint);

export function sortByLabelsNum(ageObject) {
  return sortBy(ageObject, (o) => +o.label.split('-')[0]);
}

const skewValueAndDisplayObj = (skew) => ({ value: +formatSkew(skew, 2), displayValue: formatSkew(skew, 1) });
export function transformAudienceSize(data) {
  const segmentSize = roundSegmentRatio(data['intenders-ratio-in-geo']);
  const population = parseInt(data['segment-size-in-geo'] * 1000000, 10);
  const NUM_OF_TICKS = 15;
  const PERCENTS_ACTUALLY_PER_TICK = 100 / NUM_OF_TICKS;
  const PERCENTS_WANTED_PER_TICK = 1;
  const MIN_TICKS = 4;
  const MIN_TICKS_PERCENTS = 0.5;
  const MIN_TICKS_PERCENTS_ADDITION = MIN_TICKS * PERCENTS_ACTUALLY_PER_TICK;
  const segmentGaugeBarPercents =
    ((segmentSize - Math.min(segmentSize, MIN_TICKS_PERCENTS)) * PERCENTS_ACTUALLY_PER_TICK) /
      PERCENTS_WANTED_PER_TICK +
    MIN_TICKS_PERCENTS_ADDITION;
  return { segmentSize, population, segmentGaugeBarPercents };
}

export function transformGender(data) {
  const { male: maleDist, female: femaleDist } = data['gender-distribution'];
  const { male: maleSkew, female: femaleSkew } = data['gender-skew'];
  return {
    distribution: {
      male: { value: maleDist, displayValue: formatDist(maleDist * 100) },
      female: { value: femaleDist, displayValue: formatDist(femaleDist * 100) },
    },
    skew: {
      male: skewValueAndDisplayObj(maleSkew),
      female: skewValueAndDisplayObj(femaleSkew),
    },
  };
}

function getGenderSpecificRawDemographicsDataByQuery(query, filterMapping, gender, options = {}) {
  const queryForTheSpecificGender = ['and', query, { genders: gender }];
  const cachePromise = false;
  return getRawDemographicsDataByLogicalStatement(
    queryForTheSpecificGender,
    filterMapping,
    Object.assign({ cachePromise }, options)
  );
}

export function transformGenderAge(data, query, filterMapping, options) {
  const { male: hasMaleDist, female: hasFemaleDist } = data['gender-distribution'];

  let maleData, femaleData;
  if (hasMaleDist && hasFemaleDist) {
    [maleData, femaleData] = ['male', 'female'].map((gender) =>
      getGenderSpecificRawDemographicsDataByQuery(query, filterMapping, gender, options).then((r) =>
        r.status === 'ok' ? r.data : data
      )
    );
  } else {
    // if only one gender has data (and not both) then this gender is 100% and no need to make another query to server
    const emptyAgeObj =
      data && !isEmpty(data['age-distribution']) ? fromPairs(map(data['age-distribution'], (v, k) => [k, 0])) : {};
    const emptyAgeData = Object.assign({}, data, { 'age-distribution': emptyAgeObj, 'age-skew': emptyAgeObj });
    [maleData, femaleData] = hasMaleDist ? [data, emptyAgeData] : [emptyAgeData, data];
  }

  return Promise.all([maleData, femaleData]).then(([maleData, femaleData]) => {
    const maleDistributionValues = Object.values(maleData['age-distribution']).map(
      (p) => p * 100 * data['gender-distribution']['male']
    );
    const femaleDistributionValues = Object.values(femaleData['age-distribution']).map(
      (p) => p * 100 * data['gender-distribution']['female']
    );
    const bothNormalizedDist = roundPercents(maleDistributionValues.concat(femaleDistributionValues));

    const ages = Object.keys(data['age-distribution']);
    const dist = ages.map((age, i) => {
      const male = bothNormalizedDist[i];
      const female = bothNormalizedDist[i + ages.length];
      return {
        label: age,
        male: { value: male, displayValue: formatDist(male) },
        female: { value: female, displayValue: formatDist(female) },
      };
    });
    const skew = ages.map((age) => {
      const male = maleData['age-skew'][age] * data['gender-skew'].male;
      const female = femaleData['age-skew'][age] * data['gender-skew'].female;
      return {
        label: age,
        male: skewValueAndDisplayObj(male),
        female: skewValueAndDisplayObj(female),
      };
    });

    return { distribution: sortByLabelsNum(dist), skew: sortByLabelsNum(skew) };
  });
}

export function transformAge(data) {
  const distValues = roundPercents(Object.values(data['age-distribution']).map((v) => v * 100));
  const dist = Object.keys(data['age-distribution']).map((age, i) => ({
    label: age,
    value: distValues[i],
    displayValue: formatDist(distValues[i]),
  }));
  const skew =
    data && data['age-skew'] && data['age-skew'].length
      ? data['age-skew'].map((value, label) => ({
          label,
          ...skewValueAndDisplayObj(value),
        }))
      : [];
  return { distribution: sortByLabelsNum(dist), skew: sortByLabelsNum(skew) };
}
export function transformIncome(data) {
  const allowedIncomes = ['0-25k', '25-50k', '50-75k', '75-100k', '100-150k', '150-200k', '200k+'];
  return {
    distribution: sortByLabelsNum(
      map(pick(data['income-distribution'], allowedIncomes), (value, label) => ({
        label: label.toUpperCase(),
        value: value * 100,
        displayValue: formatDist(value * 100),
      }))
    ),
    skew: sortByLabelsNum(
      map(pick(data['income-skew'], allowedIncomes), (value, label) => ({
        label: label.toUpperCase(),
        ...skewValueAndDisplayObj(value),
      }))
    ),
  };
}

export function transformEthnicity(data) {
  let { 'race-distribution': ethnicityDist, 'race-skew': ethnicitySkew } = data;

  const raceMapping = {
    white: 'Caucasian',
    asian: 'Asian American',
    hispanic: 'Hispanic',
    black: 'African American',
    chinese: 'Chinese',
    indian: 'Indian',
    malay: 'Malay',
    other: 'Others',
  };
  const ethnicities = Object.keys(ethnicityDist).map((key) => raceMapping[key]);
  const distValues = roundPercents(Object.values(ethnicityDist).map((v) => v * 100));
  const skewValues = Object.values(ethnicitySkew);

  ethnicityDist = zip(ethnicities, distValues).map(([label, value]) => ({
    label,
    value: +value,
    displayValue: formatDist(value),
  }));
  ethnicitySkew = zip(ethnicities, skewValues).map(([label, value]) => ({
    label,
    ...skewValueAndDisplayObj(value),
  }));

  ethnicityDist = orderBy(ethnicityDist, 'value', 'desc');
  const ethnicitySkewByLabel = keyBy(ethnicitySkew, (o) => o.label);
  ethnicitySkew = compact(Object.values(raceMapping).map((l) => ethnicitySkewByLabel[l])); // order like in +raceMapping+. alphabetically in the future.
  return { distribution: ethnicityDist, skew: ethnicitySkew };
}

export function transformDataByTypeToDataByDistSkew(data) {
  const accObj = { distribution: {}, skew: {} };
  for (const prop in data) {
    const { distribution, skew } = data[prop];
    if (distribution) accObj.distribution[prop] = distribution;
    if (skew) accObj.skew[prop] = skew;
    if (!distribution && !skew) accObj[prop] = data[prop];
  }
  return accObj;
}

export function formatDataToPercent(data, labelIsKey = true) {
  const valSum = sum(Object.values(data));
  const percentValues = roundPercents(Object.values(data).map((val) => (val === 0 ? val : (val * 100) / valSum)));
  return Object.keys(data).map((label, i) => {
    const valsObject = { value: percentValues[i], displayValue: formatDist(percentValues[i]) };
    return labelIsKey ? Object.assign({ label: label }, valsObject) : { [label]: valsObject };
  });
}

export function formatAndFilterAudienceDataWords(words, convertKIdToPhrase = false) {
  words.forEach((word) => {
    if (convertKIdToPhrase) {
      const wordsArr = (word['phrase'] || '').toLowerCase().split(' ').sort();
      /* eslint-disable-next-line camelcase */
      word['longest-term-check'] = { words_arr: wordsArr, num_of_words: wordsArr.length };
    } else {
      word['phrase'] = word['id'] || word['phrase-id'];
    }
    // transform to percents
    word['interest-portion'] *= 100;
    word['segment-portion'] *= 100;
  });

  words = words.filter((word) => word['phrase'] && word['topic'] !== 'Sensitive Content');
  if (convertKIdToPhrase) words = deleteContainedPhrases(words);

  return words;
}

export function getPhrasesByPhrasesIds(phrases, brands, minKl = 1.3) {
  if (isEmpty(phrases) && isEmpty(brands)) return Promise.resolve();
  const keywordsService = getKeywordsService();
  return keywordsService
    .get_kwds_by_ids(
      phrases.concat(brands || []).map((p) => p['id'] || p['phrase-id']),
      minKl
    )
    .then((res) => {
      phrases.forEach((p) => {
        p['phrase'] = res[p['id'] || p['phrase-id']];
      });
    });
}

export function getRawDemographicsDataByLogicalStatement(
  query,
  filterMapping,
  {
    channel,
    cachePromise = true,
    sampleSize = 5000,
    disableNotification = false,
    minimalSize = 150,
    filterBidstreamDomains = false,
  } = {}
) {
  const filtersCacheKey = `${JSON.stringify(query)}_${sampleSize}_${minimalSize}`;
  if (filtersCacheKey === lastDemographicsCacheKey) return lastDemographicsPromise;

  const abiPermissionsService = getAbiPermissionsService();
  const SG_CHANNELS = ['snbb', 'data_spark', 'sg_bidstream'];
  const data = {
    query,
    'sample-size': sampleSize,
    'minimal-size': minimalSize,
    'filter-bs': filterBidstreamDomains,
    ...(filterMapping && { 'filter-mapping': filterMapping }),
    ...(SG_CHANNELS.includes(channel) && {
      'use-sg-races': abiPermissionsService.hasPermission('sg telco ethnicity'),
    }),
  };
  const params = {};
  if (disableNotification) {
    /* eslint-disable-next-line camelcase */
    params.disable_notification = 1;
  }
  const promise = executeWithProxyTower(AudienceProfilerApi.getRawDemographicsDataByLogicalStatement(data, params), {
    returnOnlyData: false,
  })
    .then((res) => {
      if (!res) return false;
      if (res.status === 200 && res.data.status === 'ok')
        for (const dataType of ['distribution', 'skew']) {
          res.data.data = fixAgeLegal(res.data.data, dataType);
        }
      return res.data;
    })
    .catch(responseFailed());

  if (cachePromise) {
    lastDemographicsCacheKey = filtersCacheKey;
    lastDemographicsPromise = promise;
  }

  return promise;
}

export function getRawInterestsDataV2(searchType, reqData, uriSuffix = '') {
  return executeWithProxyTower(AudienceProfilerApi.getRawInterestsDataV2(reqData, searchType, uriSuffix), {
    returnOnlyData: false,
  }).then((res) => {
    if (!res) return false;
    if (res.status !== 200) return;

    const body = clone(res.data);
    if (body.status === 'ok') {
      // fix age legal
      if (body.data['users-sample']) {
        body.data['users-sample'].forEach((user) => {
          if (user.age === '12-17') user.age = '13-17';
        });
      }
      for (const dataType of ['distribution', 'skew']) {
        body.data = fixAgeLegal(body.data, dataType);
      }

      // make 'words'/'brands' uniform as 'words'
      if (reqData.isSports) body.data.words = body.data.brands;

      // make 'segment-proportion-heuristic'/'intenders-ratio-in-geo' uniform as 'intenders-ratio-in-geo' and normalize its value
      if (body.data['segment-proportion-heuristic'] || body.data['intenders-ratio-in-geo'])
        body.data['intenders-ratio-in-geo'] = roundSegmentRatio(
          body.data['segment-proportion-heuristic'] || body.data['intenders-ratio-in-geo']
        );
    }
    return body.data;
  });
}

export function getInterestsDataV1(reqData, { preventCaching = false, minKl = 1.3, fetchTextPhrase = true } = {}) {
  const reqCacheKey = JSON.stringify(reqData);
  if (reqCacheKey === lastReqCacheKey) return lastReqPromise;

  const segmentPropertiesCacheKey = JSON.stringify(omit(reqData, 'search-type'));
  if (!segmentPropertiesReqs[segmentPropertiesCacheKey]) segmentPropertiesReqs[segmentPropertiesCacheKey] = {};
  if (segmentPropertiesReqs[segmentPropertiesCacheKey][reqData['search-type']])
    return segmentPropertiesReqs[segmentPropertiesCacheKey][reqData['search-type']];

  const promise = getRawInterestsDataV1(reqData).then((data) => {
    if (!data) return data; // false if no res, undefined if data size error, Object if OK
    const convertKIdToPhrase =
      fetchTextPhrase &&
      !['topics-a', 'td', 'tv', 'genres', 'networks', 'pnws'].includes(reqData['search-type']) &&
      !reqData['only-phrase-id'];
    return (convertKIdToPhrase
      ? getPhrasesByPhrasesIds((data.words || data.phrases || []).concat(data.brands || []), null, minKl)
      : Promise.resolve()
    ).then(() =>
      Object.assign(data, {
        words: formatAndFilterAudienceDataWords(data.words || data.phrases || [], convertKIdToPhrase),
      })
    );
  }, responseFailed(true));

  if (!preventCaching) {
    lastReqCacheKey = reqCacheKey;
    lastReqPromise = promise;
    if (['xw', 'td', 'frequent-keywords', 'tv', 'genres', 'pnws'].includes(reqData['search-type']))
      segmentPropertiesReqs[segmentPropertiesCacheKey][reqData['search-type']] = promise;
  }
  return promise;
}

export function getInterestsDataV2(
  searchType,
  reqData,
  { preventCaching = false, minKl = 1.3, fetchTextPhrase = true } = {},
  uriSuffix = ''
) {
  const reqCacheKey = JSON.stringify(reqData);
  if (reqCacheKey === lastReqCacheKey) return lastReqPromise;

  const segmentPropertiesCacheKey = JSON.stringify(reqData);
  if (!segmentPropertiesReqs[segmentPropertiesCacheKey]) segmentPropertiesReqs[segmentPropertiesCacheKey] = {};
  if (segmentPropertiesReqs[segmentPropertiesCacheKey][searchType])
    return segmentPropertiesReqs[segmentPropertiesCacheKey][searchType];

  const promise = getRawInterestsDataV2(searchType, reqData, uriSuffix).then((data) => {
    if (!data) return data; // false if no res, undefined if data size error, Object if OK
    const convertKIdToPhrase = fetchTextPhrase && !['topics', 'domains', 'tv'].includes(searchType);
    return (convertKIdToPhrase
      ? getPhrasesByPhrasesIds((data.words || data.phrases || []).concat(data.brands || []), null, minKl)
      : Promise.resolve()
    ).then(() =>
      Object.assign(data, {
        words: formatAndFilterAudienceDataWords(data.words || data.interests || [], convertKIdToPhrase),
      })
    );
  }, responseFailed(true));

  if (!preventCaching) {
    lastReqCacheKey = reqCacheKey;
    lastReqPromise = promise;
    if (['keywords', 'td', 'frequent-keywords'].includes(searchType))
      segmentPropertiesReqs[segmentPropertiesCacheKey][searchType] = promise;
  }

  return promise;
}

export function getReferenceGroup(
  segment,
  channel,
  { isTv = false, isBidstream = false, filterByNetworksSegment = null }
) {
  isBidstream = isBidstream || some(segment, { type: '1st party' });
  const geoService = getGeoService();
  const demographics = segment.filter((segment) => segment.type === 'demographics' || segment.type === 'country')[0];
  const geos = geoByChannel(demographics && demographics.geo, channel, geoService.geos, isBidstream);
  const geoRefGroup = geos.length > 1 ? ['or', ...geos.map((geo) => ({ countries: geo }))] : { countries: geos[0] };
  const tvRefGroup = isTv && !TV_CHANNELS.includes(channel) ? { 'any-tv': 'yes' } : undefined;
  const networkRefGroup = filterByNetworksSegment
    ? convertTvShowsToNewLogicStatement(filterByNetworksSegment, channel)
    : undefined;
  return compact(['and', geoRefGroup, tvRefGroup, networkRefGroup]);
}

export function getNetworkTitle(networks, networksIdToNameMap) {
  networks.forEach((network) => {
    const networkLabel = networksIdToNameMap[network['phrase-id']];
    if (networkLabel) Object.assign(network, { title: networkLabel });
  });
  return networks.filter((network) => network.title);
}

export function getSegmentsIdsPromiseCache(key) {
  return segmentsIdsPromiseCache[key];
}

export function setSegmentsIdsPromiseCache(key, resSize) {
  return (segmentsIdsPromiseCache[key] = resSize);
}

export function getLinkedinMetaDataPromiseCache() {
  return linkedinMetaDataPromiseCache;
}

export function setLinkedinMetaDataPromiseCache(key, res) {
  return (linkedinMetaDataPromiseCache[key] = res);
}

export function getLinkedinAccessTokenPromiseCache() {
  return linkedinAccessTokenPromiseCache;
}

export function setLinkedinAccessTokenPromiseCache(key, res) {
  return (linkedinAccessTokenPromiseCache[key] = res);
}

export function getTvGenresMetaDataPromiseCache() {
  return tvGenresMetaDataPromiseCache;
}

export function setTvGenresMetaDataPromiseCache(res) {
  return (tvGenresMetaDataPromiseCache = res);
}

export function getFirstPartyMetaDataPromiseCache() {
  return firstPartyMetaDataPromiseCache;
}

export function setFirstPartyMetaDataPromiseCache(res) {
  return (firstPartyMetaDataPromiseCache = res);
}

export function getSegmentIdsQuery(
  segment,
  channel,
  { sampleSize = null, shouldSave = null, onlySize = null, isDeterministic = null, isAmplification = null }
) {
  const geoService = getGeoService();
  const segmentGeos =
    segment.find((section) => section.type === 'demographics' || section.type === 'country')?.geo || [];
  const isBidStream =
    isAmplification && !(channel === 'articles' && segmentGeos.length === 1 && segmentGeos[0].id === '840');
  const isIP = isDeterministic;
  const logicalStatement = convertAudienceSegmentToLogicStatement(
    segment,
    channel,
    geoService.geos,
    null,
    isBidStream,
    isDeterministic
  );
  const filterMapping = convertAudienceSegmentToFilterMapping(segment, channel);

  return {
    filter1: logicalStatement,
    field: isIP ? 'ip' : '_id',
    'should-save': shouldSave,
    'only-size': onlySize,
    'filter-bs': isBidStream,
    ...(filterMapping && { 'filter-mapping': filterMapping }),
    ...(sampleSize && { 'sample-size': sampleSize }),
  };
}

export function getSegmentMostCommonKeywords(data) {
  if (!data || !data.words) return [];
  const topByCompositionIndex = sortBy(data.words, 'uniqueness-index').reverse().slice(0, 30);
  return uniq(topByCompositionIndex.concat(topByCompositionIndex).map((word) => word['id'] || word['phrase-id']));
}

export function getSegmentProperties(segment, channel) {
  const searchesSearchType = TV_CHANNELS.includes(channel)
    ? 'tv'
    : some(segment, { type: '1st party' })
    ? QUERY_NAMES.searchesBidStream
    : QUERY_NAMES.searches;
  const searchesPromise = getSegmentInterestsData(segment, searchesSearchType, channel);
  const domainsPromise = getSegmentInterestsData(
    segment,
    TV_CHANNELS.includes(channel) ? 'tv' : QUERY_NAMES.websites,
    channel
  );
  return Promise.all([searchesPromise, domainsPromise]).then(([searches, domains]) => ({
    searches: getSegmentMostCommonKeywords(searches),
    domains: getSegmentMostCommonKeywords(domains),
  }));
}

export function isRequestCancelled(data) {
  return data === REQUEST_STATUS_CANCEL;
}
