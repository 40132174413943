module.exports = angular.module(__filename, [
    require('./format-number.js').name
]).filter('slice', [function () {
    return function (array, from, to) {
        if (angular.isArray(array)) {
            return array.slice(from, to);
        } else {
            return array;
        }
    };
}]).filter('pluck', [function () {
    return function (collection, path) {
        return _.map(collection, path)
    }
}]).filter('reject', [function () {
    return function (collection, value) {
        if (!collection) return [];
        return collection.filter(function(e) { return e['index'] != value });
    }
}]).filter('list', [function () {
    return function (array, dlimiter) {
        if (angular.isArray(array)) {
            return array.join(dlimiter || ',');
        } else {
            return array;
        }
    }
}]);
