import React from 'react';
import PropTypes from 'prop-types';
import Range from 'rc-slider/lib/Range';
import 'rc-slider/assets/index.css';
import './RangeSlider.scss';

const RangeSlider = ({ values, minValue, maxValue, onChange }) => {
  const EXTRA_PADDING_FOR_INCREASE_TOUCH_AREA = 7;
  const WHITE_THEME_TEXT_COLOR = 'rgba(0,0,0,0.7)';
  const AMOBEE_BLUE = '#009EBD';
  const handleStyle = {
    borderColor: AMOBEE_BLUE,
    backgroundColor: AMOBEE_BLUE,
    height: 16,
    width: 16,
    boxShadow: 'none',
    cursor: 'pointer',
    bottom: 0,
  };
  const trackStyle = { backgroundColor: AMOBEE_BLUE, height: 3 };
  const labelsStyle = {
    color: WHITE_THEME_TEXT_COLOR,
    marginTop: '4px',
    whiteSpace: 'nowrap',
    padding: EXTRA_PADDING_FOR_INCREASE_TOUCH_AREA + 'px',
    top: -EXTRA_PADDING_FOR_INCREASE_TOUCH_AREA + 'px',
  };
  const labels = {};
  values.forEach((value, i) => (labels[i] = { label: value['label'], style: labelsStyle }));
  const selectedMinValueIndex = values.map((val) => val['value']).indexOf(minValue['value']);
  const selectedMaxValueIndex = values.map((val) => val['value']).indexOf(maxValue['value']);

  return (
    <div className="range-slider-component">
      <Range
        onChange={(value) =>
          !(value[0] == selectedMinValueIndex && value[1] == selectedMaxValueIndex) &&
          onChange(values[value[0]], values[value[1]])
        }
        value={[selectedMinValueIndex, selectedMaxValueIndex]}
        railStyle={{ backgroundColor: 'rgba(0,0,0,0.05)', height: 3 }}
        trackStyle={[trackStyle, trackStyle]}
        handleStyle={[handleStyle, handleStyle]}
        marks={labels}
        min={0}
        max={values.length - 1}
        dotStyle={{ backgroundColor: 'transparent', borderColor: 'transparent', height: 0, width: 0 }}
        allowCross={false}
      />
    </div>
  );
};

RangeSlider.propTypes = {
  values: PropTypes.array.isRequired,
  minValue: PropTypes.object.isRequired,
  maxValue: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RangeSlider;
