import 'angular-modal-service';

module.exports = angular.module(__filename, [
    'angularModalService'
]).controller('domainEditController', ['$scope', '$rootScope', 'close', 'service', 'notificator', 'domain', 'domains',
    function ($scope, $root, close, service, notificator, domain, currentDomains) {

        $scope.domain = domain;
        $scope.close = close;
        $scope.save = save;
        $scope.domainsList = [];
        $scope.reason = undefined;
        $scope.urlExist = false;
        var domainsNames = _.map(currentDomains, 'name');

        function save() {
            var domains = _.map($scope.domainsList, 'text');
            $scope.urlExist = _.intersection(domainsNames, domains).length > 0;
            if (isValid(domains)) {
                create(domains);
            }
        }

        function isValid(domains) {
            var blacklistedUrls = _.intersection(domainsNames, domains);
            $scope.blacklistedUrl = _.head(blacklistedUrls);
            $scope.editDomainForm.domains.$setValidity("exists", _.isEmpty(blacklistedUrls));
            return $scope.editDomainForm.$valid;
        }

        function create(urls) {
            service.create({
                urls: urls,
                reason: $scope.reason,
                added_by: $root.user.name,
                bl_type: 2
            }).then(function (data) {
                notificator.success({body: 'Domains were saved successfully'});
                close({reload: true});
            });
        }

        function update(domain) {
            service.update(domain.id, domain).then(function (data) {
                notificator.success({body: data.name + ' was updated successfully'});
                close({reload: true});
            });
        }
    }
]);
