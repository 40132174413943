import React from 'react';
import PropTypes from 'prop-types';
import { includes, intersection, isEmpty } from 'lodash';
import './SettingsSecondaryBar.scss';
import classNames from 'classnames';
import AppSecondaryBar from '../../../common/AppNavigation/AppSecondaryBar/AppSecondaryBar';

const TABS = [
  'organizations',
  'users',
  'programs',
  'phrases',
  'domains',
  'targets',
  'first_party',
  'activated_audience_segments',
];

const SettingsSecondaryBar = ({ clickTab, visibleTabs, selectedTab, permissions, onExportClick }) => (
  <div className="settings-secondary-bar-component">
    <AppSecondaryBar
      isExportVisible={selectedTab === 'targets' || selectedTab === 'users'}
      onExportClick={onExportClick}
    >
      <div className="navigation">
        {visibleTabs.indexOf('organizations') !== -1 && (
          <div
            onClick={() => clickTab('organizations')}
            className={classNames('nav-button', { selected: selectedTab === 'organizations' })}
          >
            <span className="text insights-tab-control">Organizations</span>
          </div>
        )}
        {visibleTabs.indexOf('users') !== -1 && (
          <div
            onClick={() => clickTab('users')}
            className={classNames('nav-button', { selected: selectedTab === 'users' })}
          >
            <span className="text insights-tab-control">Users</span>
          </div>
        )}
        {visibleTabs.indexOf('programs') !== -1 && (
          <div
            onClick={() => clickTab('programs')}
            className={classNames('nav-button', { selected: selectedTab === 'programs' })}
          >
            <span className="text insights-tab-control">Programs</span>
          </div>
        )}
        {visibleTabs.indexOf('phrases') !== -1 && (
          <div
            onClick={() => clickTab('phrases')}
            className={classNames('nav-button', { selected: selectedTab === 'phrases' })}
          >
            <span className="text insights-tab-control">Phrases</span>
          </div>
        )}
        {visibleTabs.indexOf('domains') !== -1 && (
          <div
            onClick={() => clickTab('domains')}
            className={classNames('nav-button', { selected: selectedTab === 'domains' })}
          >
            <span className="text insights-tab-control">Exclusion List</span>
          </div>
        )}
        {visibleTabs.indexOf('targets') !== -1 && includes(permissions, 'targets view') && (
          <div
            onClick={() => clickTab('targets')}
            className={classNames('nav-button', { selected: selectedTab === 'targets' })}
          >
            <span className="text insights-tab-control">Targets</span>
          </div>
        )}
        {visibleTabs.indexOf('first_party') !== -1 && includes(permissions, 'first party segments') && (
          <div
            onClick={() => clickTab('first_party')}
            className={classNames('nav-button', { selected: selectedTab === 'first_party' })}
          >
            <span className="text insights-tab-control">
              1<span className="ordinal">st</span> Party Segments
            </span>
          </div>
        )}
        {visibleTabs.indexOf('activated_audience_segments') !== -1 &&
          !isEmpty(intersection(permissions, ['web', 'tv activation'])) && (
            <div
              onClick={() => clickTab('activated_audience_segments')}
              className={classNames('nav-button', { selected: selectedTab === 'activated_audience_segments' })}
            >
              <span className="text insights-tab-control">Activated Audience Segments</span>
            </div>
          )}
      </div>
    </AppSecondaryBar>
  </div>
);

SettingsSecondaryBar.propTypes = {
  clickTab: PropTypes.func.isRequired,
  visibleTabs: PropTypes.array.isRequired,
  permissions: PropTypes.array.isRequired,
  selectedTab: PropTypes.oneOf(TABS),
  onExportClick: PropTypes.any,
};

export default SettingsSecondaryBar;
