import React from 'react';
import AppTopBar from '../../../common/AppNavigation/AppTopBar/AppTopBar';
import './SettingsTopBar.scss';

const SettingsTopBar = () => (
  <div className="settings-top-bar-component">
    <AppTopBar></AppTopBar>
  </div>
);

export default SettingsTopBar;
