module.exports = {
  geographyConfig: {
    highlightOnHover: false,
    borderColor: '#202427',
    borderWidth: 1,
  },
  fills: {
    defaultFill: '#81878c'
  }
}
