import React from 'react';
import PropTypes from 'prop-types';
import TopBar from '../TopBar/TopBar';
import BITopBarCommonElements from '../BITopBarCommonElements/BITopBarCommonElements';
import PlatformMenu from '../PlatformMenu/PlatformMenu';
import { logout } from '../../../../middleware/AngularExportedMiddleware';
import OutsideClickHandler from 'react-outside-click-handler'; //'react-onclickoutside' has issues with multiple hooks components
import classNames from 'classnames';

const BITopBar = ({ userEmail, isHamburgerVisible, onHamburgerClick, isPlatformMenuOpen, hideCompanyLogo }) => {
  const commonActionsElement = (
    <BITopBarCommonElements userEmail={userEmail} onSignOutClick={() => logout(true)}></BITopBarCommonElements>
  );
  return (
    <div className={classNames('bi-top-bar-component', { 'platform-menu-open': isPlatformMenuOpen })}>
      <TopBar
        rightElement={commonActionsElement}
        onHamburgerClick={onHamburgerClick}
        isHamburgerVisible={isHamburgerVisible}
        hideCompanyLogo={hideCompanyLogo}
      ></TopBar>
      <OutsideClickHandler onOutsideClick={() => isPlatformMenuOpen && onHamburgerClick()}>
        <PlatformMenu></PlatformMenu>
      </OutsideClickHandler>
      <div className="platform-menu-overlay"></div>
    </div>
  );
};

BITopBar.propTypes = {
  userEmail: PropTypes.string.isRequired,
  isHamburgerVisible: PropTypes.bool.isRequired,
  onHamburgerClick: PropTypes.func.isRequired,
  isPlatformMenuOpen: PropTypes.bool.isRequired,
  hideCompanyLogo: PropTypes.bool.isRequired,
};

BITopBar.defaultProps = {};

export default BITopBar;
