var tmpl = require("./sentiments-popup.html");

var popupSettings = {
    position: {
        adjust: {}
    },
    hide: {
        target: false,
        event: 'mouseleave scroll',
        fixed: true
    },
    show: {
        ready: true,
        solo: true,
        event: 'mouseenter'
    },
    style: {
        width: '170px',
        classes: 'sentiments-popup popup-window'
    }
};

module.exports = angular.module(__filename, [
    require('../popup/popup-service').name
]).service("sentimentsPopupService", ['popupService', function (popupService) {
    return {
        activate: function (elem, data, bindings) {
            popupService.pop(elem, data, popupSettings, tmpl, bindings);
        },

        reset: function(obj){
            //fix for perry's tooltip that doesnt disappear
            //normal hide methods fail because tooltip is wrapped & warped in many ways
            
            var events = $._data(obj[0], "events");
            if(events && events.mouseout){
                return;
            }

            obj.mouseleave(function(){
                setTimeout(function(){$("*").qtip("hide");},500);
            });
        }
    }
}]);
