import { any, string } from 'prop-types';
import classNames from 'classnames';
import React from 'react';
import './BaseBrick.scss';

const BaseBrick = ({ rolloverContent, bottomContent, imgUrl, imgWidth, imgHeight }) => (
  <div className="base-brick-component">
    <div className="rollover-container">
      <div className="brick-image-wrapper">
        <img
          style={{ width: imgWidth, height: imgHeight }}
          src={imgUrl || '/images/common/no-available-image.svg'}
          className={classNames({ 'no-avail-image': !imgUrl })}
        />
      </div>

      <div className="rollover">{rolloverContent}</div>
    </div>
    <div className={'bottom-content-wrapper'}>{bottomContent}</div>
  </div>
);

BaseBrick.propTypes = {
  rolloverContent: any,
  bottomContent: any,
  imgHeight: string,
  imgWidth: string,
  imgUrl: string,
};

export default BaseBrick;
