import { createSlice } from '@reduxjs/toolkit';

const contextSlice = createSlice({
  name: 'context',
  initialState: {},
  reducers: {
    updateContext: (state, action) => action.payload,
    syncContext: (state, action) => action.payload,
  },
});

export const { updateContext, syncContext } = contextSlice.actions;

export default contextSlice;
