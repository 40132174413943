import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../../../Tooltip/Tooltip';
import Icon from '../../../Icon/Icon';
import { sortBy } from 'lodash';
import classNames from 'classnames';
import './BaseTag.scss';

const Button = ({
  iconId,
  className,
  onClick,
  isMostLeftButton,
  isMostRightButton,
  tooltip,
  value,
  tooltipOptions,
}) => (
  <Tooltip content={tooltip} options={tooltipOptions}>
    <span
      onClick={(e) => onClick(value, e)}
      className={classNames('button-component', className, { leftest: isMostLeftButton, rightest: isMostRightButton })}
    >
      <Icon iconId={iconId} className="button-icon"></Icon>
    </span>
  </Tooltip>
);

Button.propTypes = {
  iconId: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  isMostLeftButton: PropTypes.bool,
  isMostRightButton: PropTypes.bool,
  tooltip: PropTypes.string,
  value: PropTypes.any,
  tooltipOptions: PropTypes.object,
};

const BaseTag = ({ value, label, labelMarkup, className, buttons, onClick, tooltipOptions, showLoader }) => {
  const leftestButtonPosition = Math.min(...buttons.map((b) => b.position));
  const rightestButtonPosition = Math.max(...buttons.map((b) => b.position));

  return (
    <div className={classNames('base-tag-component', { 'show-loader': showLoader }, className)}>
      {sortBy(
        buttons.filter((b) => b.position < 0),
        'position'
      ).map((b) => (
        <Button
          {...b}
          key={b.position}
          isMostLeftButton={b.position == leftestButtonPosition}
          value={value}
          tooltipOptions={tooltipOptions}
        />
      ))}
      <span onClick={() => onClick && onClick(value)} className={classNames('label', { clickable: onClick })}>
        {label || labelMarkup}
      </span>

      {showLoader && (
        <svg className="base-tag-loader" width="16" height="16">
          <image
            xlinkHref="/images/infra/loader/loader.svg"
            src="/images/infra/loader/loader.svg"
            width="16"
            height="16"
          />
        </svg>
      )}

      {sortBy(
        buttons.filter((b) => b.position > 0),
        'position'
      ).map((b) => (
        <Button
          {...b}
          key={b.position}
          isMostRightButton={b.position == rightestButtonPosition}
          value={value}
          tooltipOptions={tooltipOptions}
        ></Button>
      ))}
    </div>
  );
};

BaseTag.propTypes = {
  label: PropTypes.any,
  labelMarkup: PropTypes.any,
  className: PropTypes.string,
  buttons: PropTypes.array,
  onClick: PropTypes.func,
  value: PropTypes.any,
  tooltipOptions: PropTypes.object,
  showLoader: PropTypes.bool,
};

BaseTag.defaultProps = {
  color: 'black',
  buttons: [],
  skin: 'default',
};

export default BaseTag;
