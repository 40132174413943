import React, { useEffect, useState, useMemo } from 'react';
import './GenderBarChart.scss';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { BarChart } from '../BarChart/BarChart';
import { isEmpty, map, noop, some, gt } from 'lodash';
import Icon from '../Icon/Icon';
import { getMaxValue } from '../../../utils/ArrayUtils';

const GenderBarChart = ({ data, isSkew, tooltipFunc }) => {
  const [maleData, femaleData] = map(['male', 'female'], (gender) =>
    map(data, (rec) => {
      const { value, displayValue } = rec[gender];
      return { label: rec.label, value, displayValue };
    })
  );
  const [maleMaxValue, femaleMaxValue] = map([maleData, femaleData], (genderData) => getMaxValue(genderData, 'value'));
  const maxValueForChart = Math.max(maleMaxValue, femaleMaxValue);
  const WINDOW_HEIGHT_SMALL_CHART_LIMIT = 820;
  const [isSmallChart, setIsSmallChart] = useState(window.innerHeight < WINDOW_HEIGHT_SMALL_CHART_LIMIT);
  const iconSize = useMemo(() => (isSmallChart ? '25px' : '30px'));
  const tooltipFuncByGender = (gender) => (ageLabel, value) => tooltipFunc(gender, ageLabel, value);

  useEffect(() => {
    function handleResize() {
      setIsSmallChart(window.innerHeight < WINDOW_HEIGHT_SMALL_CHART_LIMIT);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return (
    <div className={classNames('gender-bar-chart-component', { 'small-chart': isSmallChart })}>
      {!isEmpty(maleData) && (
        <div className="chart male">
          <div className="chart-icon">
            <Icon iconId="male-detailed" height={iconSize} width={iconSize} />
          </div>
          <BarChart
            data={maleData}
            tickOffset={isSmallChart ? 15 : 30}
            hasTooltip={isSkew}
            tooltipFunc={tooltipFuncByGender('Males')}
            shouldHighlightMax={some(maleData, (value) => gt(value.value, 0))}
            maxValueForChart={maxValueForChart}
            {...(isSkew && { referenceLine: 1 })}
          />
        </div>
      )}
      {!isEmpty(femaleData) && (
        <div className="chart female">
          <div className="chart-icon">
            <Icon iconId="female-detailed" height={iconSize} width={iconSize} />
          </div>
          <BarChart
            data={femaleData}
            hideXAxisLabels
            isUpsideDown
            hasTooltip={isSkew}
            shouldHighlightMax={some(femaleData, (val) => gt(val.value, 0))}
            tooltipFunc={tooltipFuncByGender('Females')}
            maxValueForChart={maxValueForChart}
            {...(isSkew && { referenceLine: -1 })}
          />
        </div>
      )}
    </div>
  );
};

GenderBarChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      male: PropTypes.shape({
        value: PropTypes.number,
        displayValue: PropTypes.string,
      }),
      female: PropTypes.shape({
        value: PropTypes.number,
        displayValue: PropTypes.string,
      }),
    })
  ),
  isSkew: PropTypes.bool,
  tooltipFunc: PropTypes.func,
};

GenderBarChart.defaultProps = {
  data: [],
  isSkew: false,
  tooltipFunc: noop,
};

export default GenderBarChart;
