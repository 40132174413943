"use strict";

module.exports = angular.module(__filename, []).service("StringMold", function () {
    function StringMold() {
        var me = this;
        me._value = [];
        me.default = [];

        me.replace = function (val) {
            return me._value = val || '';
        }.bind(this);
    }

    return StringMold;
});
