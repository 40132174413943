import * as MixpanelInsights from '../../react/infra/mixpanel/MixpanelInsights';

var ex = require("infra/utils/export");

module.exports = angular.module(__filename, [])
    .service("insightsExportService", ['$rootScope','Excel', 'geoService', 'topicsTree', '$state',
    'context', function($rootScope, Excel, geoService, topicsTree, $state, context) {
      var title;
      var reportsFunctions = [];
      var examplesFunction;
      var summaryFunction = getSummaryDefault;
      var params = {};
      var waitingRequests = [];
      var summaryFields = [];
      clear();

      return {
        createWorkBook: createWorkBook,
        setTitle: setTitle,
        addReportFunction: addReportFunction,
        setSummaryFunction: setSummaryFunction,
        addSummaryFields: addSummaryFields,
        initSummaryFields: initSummaryFields,
        setReportFunctions: setReportFunctions,
        setExampleFunction: setExampleFunction,
        setParams: setParams,
        addRequest: addRequest,
        clear: clear,
        getSummaryDefault: getSummaryDefault
      };

      function createWorkBook() {
        if (_.isUndefined(title)) return false;
        return Promise.all(waitingRequests).then(function() {
          try {
            var date = moment();
            var workbook = Excel.builder.createWorkbook();
            workbook.fileName = ex.getExportFileName(title, date) + ".xlsx";
            var t = Excel.formater(workbook);
            var sheets = new Set();
            var summary = createPage(workbook, summaryFunction(t, params, date));
            var reports = _.map(reportsFunctions, function(report){
              return createPage(workbook, report(t));
            });

            var examples = createPage(workbook, examplesFunction(t));

            addWorkSheet(sheets, workbook, summary);
            _.each(reports, function(report) {
              addWorkSheet(sheets, workbook, report)
            } );

            if (examples && examples.data.length > 1) {
              addWorkSheet(sheets, workbook, examples);
            }
            MixpanelInsights.trackExport();
            return workbook;
          } catch (e) {
            console.log("Fail to export report! : ", e);
            return false;
          }
        });
      }

      function createPage(workbook, data) {
        var page;
        if (data) {
          page = workbook.createWorksheet({name: data.name});
          page.setColumns(data.columns);
          page.setData(data.table);
        } else {
          page = undefined;
        }

        return page;
      }

      function addWorkSheet(sheets, workbook, data) {
        var name = data.name.toLowerCase();
        if(data) {
          if(!sheets.has(name))
            workbook.addWorksheet(data);
            sheets.add(name);
        }
      }

      function getSummaryDefault(format, params, date, fields) {
        fields = fields || summaryFields;
        CheckGeoSummary(params); //Done at export time, after (sub)geos are loaded from server
        return ex.getQuerySummary(format, params, date, fields);
      }

      function setTitle(value) {
        title = value;
      }

      function setSummaryFunction(func) {
        summaryFunction = func;
      }

      function setExampleFunction(func) {
        examplesFunction = func;
      }

      function addReportFunction(func, clear) {
        if (clear) {
          reportsFunctions = [];
        }

        reportsFunctions.push(func);
      }

      function setReportFunctions(functions) {
        reportsFunctions = functions;
      }

      function setParams(newParams) {
        Object.assign(params, newParams, {
          // This is to make sure that when all topics are selected in the summary page we will get 'All', and not a list of topics
          topics: topicsTree.isAllTopics(newParams.topics) ? [] : newParams.topics
        }, {
          channel: newParams.channel && newParams.channel == 'articles' ? 'All' : newParams.channel
        });
      }

      function CheckGeoSummary(params) {
        // This is to make sure that when all (sub)geos are selected in the summary page we will get 'All', and not a list of (sub)geos
        //Object.assign(params, {geo: geoService.getGeoSummary() });
        Object.assign(params,{geos: geoService.isAllGeos(params.geo) ? [] : params.geo});

        if (!params.hasOwnProperty('sub_geos')) return;
        const subGeos = geoService.indexedSubGeosTreeHelper($rootScope.subGeosTree)
            .contextSubGeosHelper(params.sub_geos)
            .getOnlyTopTypedCheckedSubGeos()
            .map(sg => ({label: sg.name}));
        if (context.current.insightsAllSubGeosSelected || subGeos.length === 0) return Object.assign(params,{sub_geos: []});

        Object.assign(params,{sub_geos: subGeos});
      }

      function addRequest(req) {
        waitingRequests.push(req);
        // Removing the request when its resolved.
        req.finally(function(){
          waitingRequests = _.reject(waitingRequests, req);
        })
      }

      function addSummaryFields(field, index) {
        _.isNumber(index) ? summaryFields.splice(index, 0, field) : summaryFields.push(field);
      }

      function initSummaryFields() {
        summaryFields = [];
        angular.copy(ex.DEFAULT_SUMMARY_PARAMETERS, summaryFields);
      }

      function clear() {
        $rootScope.createExcelWorkbook = createWorkBook;
        summaryFunction = getSummaryDefault;
        reportsFunctions = [];
        waitingRequests = [];
        params = {};
        examplesFunction = angular.noop;

        initSummaryFields();
        _.find(summaryFields, {key: 'terms'}).pkey = 'display';
      }
    }]
);
