module.exports = angular.module(__filename, []).service('audienceMgmt', ['mgmtServiceFactory', function(mgmtServiceFactory) {
  const service = mgmtServiceFactory.createService('audience');

  return {
    list: audienceList,
    create: service.create,
    update: service.update,
    delete: deleteAudience
  };

  // Filter audience by channel
  async function audienceList(channel) {
    const data = await service.list();
    return channel
      ? data.filter(audience => (audience.channel === channel) || (channel === 'articles' && !audience.channel))
      : data;
  }

  function deleteAudience(audienceId, isAudienceActivated = false) {
    return service.customRequest('DELETE', `/${audienceId}/${isAudienceActivated}`);
  }
}]);