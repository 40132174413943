import React from 'react';
import './Accordion.scss';
import Icon from '../Icon/Icon';
import Tooltip from '../Tooltip/Tooltip';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Accordion = ({ isOpen, iconId, label, onToggle, tooltip }) => (
  <div className={classNames('accordion-component', { open: isOpen })} onClick={onToggle}>
    <Icon iconId={iconId} className="main-icon"></Icon>
    <div className="label">
      <span className="label-text">{label}</span>
      {tooltip && (
        <Tooltip content={tooltip}>
          <Icon iconId="info" className="info-icon"></Icon>
        </Tooltip>
      )}
    </div>
    <Icon iconId="triangle-up" className={classNames('triangle', isOpen ? 'up' : 'down')}></Icon>
  </div>
);

Accordion.propTypes = {
  isOpen: PropTypes.bool,
  iconId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
  tooltip: PropTypes.string,
};

export default Accordion;
