import { compact, difference, groupBy, isEmpty, map } from 'lodash';

export function compareArraysIgnoreOrder(arr1, arr2) {
  if (!arr1 || !arr2) throw Error('array is not defined');
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) throw Error('not an array');
  if (arr1.length !== arr2.length) return false;
  if (arr1.length == 0) return true;
  return difference(arr1, arr2).length === 0;
}

export function compareArraysByObjectFieldIgnoreOrder(arr1, arr2, field) {
  if (!arr1 || !arr2) throw Error('array is not defined');
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) throw Error('not an array');
  if (!field) throw Error('field is not defined');
  if (arr1.length !== arr2.length) return false;
  if (arr1.length == 0) return true;
  return compareArraysIgnoreOrder(
    arr1.map((obj) => obj[field]),
    arr2.map((obj) => obj[field])
  );
}

export function convertToArray(element) {
  if (element === null || element === undefined) return [];
  if (Array.isArray(element)) return element;
  return [element];
}

export function getAllObjectsByMaxValue(arr, key, withIndex) {
  if (!Array.isArray(arr)) throw Error('not an array');
  if (isEmpty(arr)) return [];
  const finalArray = withIndex ? map(arr, (rec, index) => ({ ...rec, index })) : arr;
  const groups = groupBy(finalArray, key);
  const keys = Object.keys(groups);
  if (keys.includes('undefined')) throw Error("key doesn't exist");
  const max = Math.max(...keys);
  return groups[max];
}

export function getMaxValue(arr, key) {
  if (!Array.isArray(arr)) throw Error('not an array');
  const values = compact(map(arr, key));
  if (isEmpty(values)) return null;
  return Math.max(...values);
}
