import AbiManagementApi from '../api/AbiManagementApi';
import ActivationApi from '../api/ActivationApi';
import AudienceProfilerApi from '../api/AudienceProfilerApi';
import { executeHttpRequest, executeWithProxyTower } from '../infra/HttpClient';

export function getLinkedinSegmentDemographicsDistribution(audience, userId) {
  return executeHttpRequest(AbiManagementApi.getLinkedinSegmentDemographicsDistribution(audience, userId), {
    displayRedBannerOnError: false,
  });
}

export function getSegmentIds(audience) {
  return executeWithProxyTower(AudienceProfilerApi.getSegmentIds(audience));
}

export function createAudienceTargetTaxonomy(segmentId, channel, marketId, advertiserId) {
  return executeHttpRequest(AbiManagementApi.createAudienceTargetTaxonomy(segmentId, channel, marketId, advertiserId));
}

export function createAudienceTargetUserList(segmentId, channel, ids) {
  return executeHttpRequest(AbiManagementApi.createAudienceTargetUserList(segmentId, channel, ids));
}

export function getAudienceTaxonomyCategory(segmentId) {
  return executeHttpRequest(AbiManagementApi.getAudienceTaxonomyCategory(segmentId));
}

export function createAmplifiedAudience(
  segmentId,
  segmentName,
  channel,
  market,
  advertiser,
  amplifyThreshold,
  target,
  ssoAccessToken,
  userListQuery,
  geo,
  isFirstParty,
  tremorSyndicateAmplified,
  isCustomPriceAmplified,
  optimizationType
) {
  return executeHttpRequest(
    ActivationApi.createAmplifiedAudience(
      segmentId,
      segmentName,
      channel,
      market,
      advertiser,
      amplifyThreshold,
      target,
      ssoAccessToken,
      userListQuery,
      geo,
      isFirstParty,
      tremorSyndicateAmplified,
      isCustomPriceAmplified,
      optimizationType
    )
  );
}

export function createDeterministicAudience(
  segmentId,
  segmentName,
  channel,
  market,
  advertiser,
  ssoAccessToken,
  userListQuery,
  tremorSyndicateDeterministic,
  isCustomPriceDeterministic,
  expirationDate
) {
  return executeHttpRequest(
    ActivationApi.createDeterministicAudience(
      segmentId,
      segmentName,
      channel,
      market,
      advertiser,
      ssoAccessToken,
      userListQuery,
      tremorSyndicateDeterministic,
      isCustomPriceDeterministic,
      expirationDate
    )
  );
}

export function createAlwaysOnAudience(
  segmentId,
  segmentName,
  dataContractId,
  dataContractText,
  categoryId,
  amplifyThreshold,
  target,
  ssoAccessToken,
  userListQuery,
  geo
) {
  return executeHttpRequest(
    ActivationApi.createAlwaysOnAudience(
      segmentId,
      segmentName,
      dataContractId,
      dataContractText,
      categoryId,
      amplifyThreshold,
      target,
      ssoAccessToken,
      userListQuery,
      geo
    )
  );
}

export function createUserListForDeterministicAudience(
  channel,
  segmentId,
  market,
  advertiser,
  categoryId,
  dataContractId,
  dataProviderId,
  dspSegmentId,
  userListQuery,
  isDynamic,
  ssoAccessToken,
  audienceName,
  tremorSyndicateDeterministic,
  isCustomPriceDeterministic
) {
  return executeHttpRequest(
    ActivationApi.createUserListForDeterministicAudience(
      channel,
      segmentId,
      market,
      advertiser,
      categoryId,
      dataContractId,
      dataProviderId,
      dspSegmentId,
      userListQuery,
      isDynamic,
      ssoAccessToken,
      audienceName,
      tremorSyndicateDeterministic,
      isCustomPriceDeterministic
    )
  );
}

export function getUserAllActivatedSegments(userId) {
  return executeHttpRequest(ActivationApi.getUserAllActivatedSegments(userId));
}
