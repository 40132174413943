import 'angular-ui-router';
import { react2angular } from 'react2angular';
import BITopBar from 'react/components/common/AppNavigation/BITopBar/BITopBar';
import { openHelpPage } from 'react/middleware/AngularExportedMiddleware';
import * as MixpanelCommon from '../../react/infra/mixpanel/MixpanelCommon';

const HIDE_COMPANY_LOGO = "hide company logo";

const topbarTmpl = require("./topbar.html"),
    contextModule = require("infra/context/context"),
    PlatformAuthUtils = require("react/infra/PlatformAuthUtils.js");

const topBarModule = angular.module(__filename, [
    contextModule.name,
    'ui.router',
    require("data/interests-service").name
]);

topBarModule.component('biTopBar', react2angular(BITopBar, ['userEmail', 'isHamburgerVisible', 'onHamburgerClick', 'isPlatformMenuOpen', 'hideCompanyLogo']));

topBarModule.directive("topbar", ["context", "$state", '$rootScope', 'abiPermissions', 'TIMES', 'datadogRumService',
    function (context, $state, $rootScope, abiPermissions, TIMES, datadogRumService) {
        return {
            restrict: "E",
            template: topbarTmpl,
            compile: function () {
                return {
                    pre: function (scope) {
                        scope.context = context;
                        scope.$state = $state;

                        scope.times = [];
                        scope.minDate = null;
                        scope.subtractDay = false;

                        scope.isHamburgerVisible = PlatformAuthUtils.isUserHasDSPPermission();
                        scope.hideCompanyLogo = abiPermissions.hasPermission(HIDE_COMPANY_LOGO);
                        scope.onHamburgerClick = () => {
                            scope.isPlatformMenuOpen = !scope.isPlatformMenuOpen;
                            scope.$apply();
                        }
                        scope.isPlatformMenuOpen = false;
                        scope.hasPermission = abiPermissions.hasPermission;
                    }
                }}
        }
    }
]);

module.exports = angular.module(__filename);
