import dynamicMiddlewares from 'redux-dynamic-middlewares';
import { configureStore } from '@reduxjs/toolkit';
import { getSlices } from './slices';

const globalStore = {};

export const getStore = () => globalStore.store;

// redux devtools extension - https://github.com/reduxjs/redux-devtools
export const createStore = () => {
  const store = configureStore({
    reducer: getSlices(),
    middleware: [dynamicMiddlewares],
    // eslint-disable-next-line no-undef
    devTools: !IS_PRODUCTION,
  });
  globalStore.store = store;
  return store;
};
