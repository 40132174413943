root = exports ? this

module.exports = root.pack_rectangle= ( data, width, height, padding = 10 ) ->
	epsilon = 1e-2

	placed = []

	# do circles a and b overlap?
	circles_overlap= ( a, b ) ->
		delta_x = b.x - a.x
		delta_y = b.y - a.y
		total_r = a.r + b.r
		min_squared = Math.pow( total_r, 2)
		distance_squared = Math.pow( delta_x, 2) + Math.pow(delta_y, 2)
		min_squared - distance_squared > epsilon

	circles_are_tangent= ( a, b ) ->
		delta_x = b.x - a.x 
		delta_y = b.y - a.y
		total_r = a.r + b.r
		total_r_sq = Math.pow( total_r, 2)
		distance_squared = Math.pow( delta_x, 2) + Math.pow(delta_y, 2)
		Math.abs(total_r_sq - distance_squared) <= epsilon



	# given two tangent circles a & b, find a third circle c of radius r that is tangential to a and b
	# ( this may even work with non-tangential a & b )
	tangent_circle= ( A, B, r ) ->
		a = B.r + r 
		b = A.r + B.r
		c = A.r + r  
		alpha = Math.acos( ( b*b + c*c - a*a) / ( 2*b*c) )
		delta_x = B.x - A.x
		delta_y = B.y - A.y
		theta = Math.atan( delta_y / delta_x ) 

		phi = theta + alpha
		phi += Math.PI if delta_x < 0

		x = A.x + c * Math.cos( phi )
		y = A.y + c * Math.sin( phi )
		{ x:x, y:y }


	add_boxes=( a, b ) -> 
		box = 
			left:	Math.min( a.left, 	b.left 		)
			right: 	Math.max( a.right, 	b.right 	)
			top:	Math.max( a.top, 	b.top 		)
			bottom:	Math.min( a.bottom, b.bottom 	)

	bbox= ( balls ) -> balls.reduce( (a,b) -> add_boxes(a, b))

	compute_box=( ball ) ->
		ball.left 	= ball.x - ball.r
		ball.right	= ball.x + ball.r
		ball.top	= ball.y + ball.r 
		ball.bottom = ball.y - ball.r
		ball

	box_derivatives=( box ) ->
		w =	box.right - box.left
		h = box.top   - box.bottom
		ratio = h / w
		{ w:w, h:h, ratio:ratio, cx: box.left + w/2, cy: box.bottom + h/2}

	compute_boxes=(balls) ->
		$.each( balls, (i,x)-> compute_box(x) )

	place_ball=(ball, coords) ->
		##console.log( "placing ball #{ball.id} on #{coords.x},#{coords.y}")
		$.extend( ball, coords )
		compute_box(ball)
		placed.push( ball )

	regret_placement=() -> placed.pop()


	steps_on_toes=(candidate_ball) ->
		for idx in [0..placed.length-1]
			return true if circles_overlap( candidate_ball, placed[idx])
		return false

	can_use_pair=(i,j) ->
		return i != j

	best_candidate=(ball) ->
		#console.log("looking for candidate with #{placed.length} already in place")
		target_ratio = height / width
		candidate = null
		candidate_ratio = null
		candidate_pair = null
		placed_bbox = bbox(placed)
		for i in [0..placed.length-1]
			for j in [0..placed.length-1]
				if can_use_pair( i,j )
					coords 		= tangent_circle( placed[i], placed[j], ball.r + 5 )
					dummy_ball 	= $.extend( {r: ball.r}, coords )
					if steps_on_toes(dummy_ball) 
						# spent_pairs.push( [i,j] )  # since there's no backtracking, <i,j> will always overlap earlier balls
					else
						compute_box( dummy_ball )
						total_box 	= add_boxes( placed_bbox, dummy_ball )
						ratio 		= box_derivatives(total_box).ratio
						if candidate == null
							candidate 		= coords
							candidate_ratio = ratio
							candidate_pair 	= [i,j]
						else
							if Math.abs(candidate_ratio - target_ratio) > Math.abs(ratio - target_ratio)
								candidate 		= coords
								candidate_ratio = ratio
								candidate_pair 	= [i,j]

						# is this ratio good enough?
						if Math.abs( candidate_ratio - target_ratio ) < epsilon
							return candidate
			
		#spent_pairs.push( candidate_pair )
		return candidate		
		

	can_layout= () ->
		(width > 2 * padding) and (height > 2 * padding)


	layout=( balls ) ->
		return unless can_layout()
		return if balls.length == 0
		place_ball( balls[0], {x:0, y:0} )
			
		compute_boxes(balls)

		if balls.length >= 2
			place_ball( balls[1], {x: balls[0].r + balls[1].r, y:0})
		
		if balls.length >= 3
			for idx in [2..balls.length-1]
				coords = best_candidate( balls[idx] )
				# adjust		
				place_ball( balls[idx], coords )

		computed_box 	= bbox(placed)
		target_box 		= {left: padding, top: height - padding, right: width - padding, bottom: padding }
		computed_derivatives 	= box_derivatives(computed_box)
		target_derivatives 		= box_derivatives(target_box)
		# console.debug("computed vs target box", computed_box, target_box)
		# console.debug("computed vs target derivatives", computed_derivatives, target_derivatives)

		if computed_derivatives.ratio > target_derivatives.ratio
			scale = computed_derivatives.h / target_derivatives.h
		else 
			scale = computed_derivatives.w / target_derivatives.w
		
		dx = target_derivatives.cx - computed_derivatives.cx 
		dy = target_derivatives.cy - computed_derivatives.cy

		# align the centers of the boxes
		for ball in placed 
			ball.x += dx 
			ball.y += dy 

		# project according to scale
		for ball in placed 
			ball_dx = ball.x - target_derivatives.cx  	# coords from the box center
			ball_dy = ball.y - target_derivatives.cy 
			ball_dx /= scale 							# rescale
			ball_dy /= scale 
			ball.x = target_derivatives.cx + ball_dx	# adjust
			ball.y = target_derivatives.cy + ball_dy
			ball.r /= scale								# new radius

		return

	
	layout( data )
	return placed

	

root.locate_box= (d)->"translate(#{d.x},#{d.y})"
###

root.test_pack= (amount, width = 800, height = 600) ->
	
	color = d3.scale.category20c()
	balls = d3.range(amount).map( (x) -> { id:x, r:100 + (Math.random() * 100 - 50)   } )

	start = new Date().getTime();
	layout = pack_rectangle( balls, width, height )
	elapsed = new Date().getTime() - start
	$("#measure #value").html(elapsed)


	svg = d3.select("#amazeballs").select("#frame")

	svg.select("rect").attr("width", width).attr("height", height);

	

	stronzo = svg.selectAll(".ball").data( balls, (x)->x.id )
	
	stronzo.transition().duration(500).attr("transform", locate_box )
	stronzo.select("circle").transition().duration(500).attr("r", (d)->d.r)

	stronzo.exit().remove()


	little_boxes = stronzo.enter()
					.append("g")
					.attr("class", "ball")
					.attr("id", (d)->d.id )
					.attr("transform", locate_box )

	little_boxes
		.append("circle")
		.style("fill", (d,i) -> color(i) )
		.style("stroke-width", 1)
		.style("stroke", "black")
		.attr("r", 0)
		.transition().duration(500)
		.attr("r", (d)->d.r)
	little_boxes
		.append("text")
		.attr("text-anchor", "middle")
		.text( (d) -> d.id )
###

###
root.test_tangents=() ->

	balls = [
		{x:10, y:  0, r:50}
		{x:70, y: 80, r:50}
		{x:70, y:500, r:50}
		{x:10, y:580, r:50}
	]

	r = 20

	balls.push( $.extend( tangent_circle(balls[0], balls[1], r ), {r:r} ) )
	balls.push( $.extend( tangent_circle(balls[1], balls[0], r ), {r:r} ) )

	balls.push( $.extend( tangent_circle(balls[2], balls[3], r ), {r:r} ) )
	balls.push( $.extend( tangent_circle(balls[3], balls[2], r ), {r:r} ) )

	svg = d3.select("#amazeballs").select("#frame")
	stronzo = svg.selectAll(".ball").data( balls )

	little_boxes = stronzo.enter()
					.append("g")
					.attr("transform", locate_box )

	little_boxes
		.append("circle")
		.attr("class", "ball")
		.attr("r", (d)->d.r)
		.style("fill", "none")
		.style("stroke-width", 1)
		.style("stroke", "black")
	little_boxes
		.append("text")
		.attr("text-anchor", "middle")
		.text( (d,i) -> i )	
	

###


