function bindMethodExecutionToKeyPress($document, $scope, method, keyCode, condition) {
    var executeMethodOnKeyPress = function ($event) {
        if ($event.keyCode === keyCode) {

            if((condition != undefined && $scope.$eval(condition) == true) || condition == undefined){
                $scope.$apply(function () {
                    $scope.$eval(method);
                });
                $event.stopPropagation()
            }
        }
    };

    $document.on('keydown', executeMethodOnKeyPress);
    $scope.$on('$destroy', function () {
        $document.off('keydown', executeMethodOnKeyPress)
    });
}

//USAGE:
//am-on-press-esc="editTargetCtrl.close();" 
//OPTIONAL: condition: when the escape press will be active
//am-on-press-esc-condition="!editTargetCtrl.dynamicTrendsOpened"
module.exports = angular.module(__filename, []).directive('amOnPressEsc', ['$document', function ($document) {
    return {
        restrict: 'A',
        link: function ($scope, $element, $attrs) {
            bindMethodExecutionToKeyPress($document, $scope, $attrs.amOnPressEsc, 27 , $attrs.amOnPressEscCondition)
        }
    }
}]).directive('amOnPressEnter', ['$document', function ($document) {
    return {
        restrict: 'A',
        link: function ($scope, $element, $attrs) {
            bindMethodExecutionToKeyPress($document, $scope, $attrs.amOnPressEnter, 13)
        }
    }
}]);
