import React from 'react';
import './CloneAudience.scss';
import PropTypes from 'prop-types';
import ConfirmationModal from '../../../../common/Modal/ConfirmationModal/ConfirmationModal';

const CloneAudience = ({
  createClonedAudience,
  updateIsCloneAudienceOpen,
  isOpen,
  audienceId,
  audienceName,
  audienceSegment,
}) => (
  <div className="clone-audience-component">
    <ConfirmationModal
      isOpen={isOpen}
      marginTop="300px"
      onCancel={() => updateIsCloneAudienceOpen(false)}
      onSubmit={createClonedAudience}
      modalTitle="Audience must be cloned before editing"
      message="The audience you have selected has been activated or amplified and cannot be edited. Please clone and save as new audience."
      submitLabel="Clone"
      isLightTheme={true}
      width={'424px'}
    ></ConfirmationModal>
  </div>
);

CloneAudience.propTypes = {
  createClonedAudience: PropTypes.func.isRequired,
  updateIsCloneAudienceOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  audienceId: PropTypes.string,
  audienceName: PropTypes.string,
  audienceSegment: PropTypes.array,
};

CloneAudience.defaultProps = {};

export default CloneAudience;
