import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './EmptyScreenMessage.scss';

const EmptyScreenMessage = ({ imgSrc, msgTitle, msgText, imgStyle, elementStyle, textStyle, noTermsClass }) => (
  <div className={classNames('empty-screen-message-component', { 'no-terms': noTermsClass })} style={elementStyle}>
    {imgSrc && <img src={imgSrc} className="empty-screen-img" style={imgStyle} />}
    <div className="empty-screen-msg">
      {msgTitle && <h2 className="empty-screen-msg-title">{msgTitle}</h2>}
      {msgText && (
        <h3 className="empty-screen-msg-text" style={textStyle}>
          {msgText}
        </h3>
      )}
    </div>
  </div>
);

EmptyScreenMessage.propTypes = {
  imgSrc: PropTypes.string,
  msgTitle: PropTypes.string,
  msgText: PropTypes.string,
  imgStyle: PropTypes.object,
  elementStyle: PropTypes.object,
  textStyle: PropTypes.object,
  noTermsClass: PropTypes.bool,
};

EmptyScreenMessage.defaultProps = {};

export default EmptyScreenMessage;
