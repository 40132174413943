import React, { useCallback, useState, useEffect } from 'react';
import { bool, func, string } from 'prop-types';
import './AudienceNameModal.scss';
import TextBox from '../../../../../common/TextBox/TextBox';
import ModalWithConfirmationButtons from '../../../../../common/Modal/ModalWithConfirmationButtons/ModalWithConfirmationButtons';

const AudienceNameModal = ({ isOpen, origName, origErrorMessage, checkName, onCancel, onSubmit }) => {
  const [name, setName] = useState(origName);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(origErrorMessage);
  const onSubmitCb = useCallback(async () => {
    setIsLoading(true);
    const { error, isValid } = await checkName(name);
    setIsLoading(false);
    if (isValid) {
      onSubmit(name);
    } else {
      setErrorMessage(error);
    }
  }, [name]);
  const onChangeCb = useCallback(({ target: { value } }) => {
    setErrorMessage('');
    setName(value);
  }, []);

  useEffect(() => {}, []);

  return (
    <div className="audience-name-modal-component" role="dialog" aria-label="Set Audience Name Dialog">
      <ModalWithConfirmationButtons
        hasScrollContainer={false}
        isLightTheme={true}
        isOpen={isOpen}
        isCancelTransparent={true}
        isSubmitDisabled={isLoading || !name || !!errorMessage}
        isCancelDisabled={isLoading}
        modalTitle="Audience must be saved before activating"
        showSeparator={false}
        submitLabel="Save and Activate"
        width={500}
        onCancel={onCancel}
        onSubmit={onSubmitCb}
        confirmationButtonsWidth="auto"
      >
        <div className="label">Audience name:</div>
        <TextBox
          value={name}
          placeholder="Untitled Audience"
          isDarkTheme={false}
          isDisabled={isLoading}
          shouldDisplayErrorMessages={true}
          errorMessage={errorMessage}
          onChange={onChangeCb}
          onEnter={onChangeCb}
          isFocused={true}
        />
      </ModalWithConfirmationButtons>
    </div>
  );
};

AudienceNameModal.propTypes = {
  isOpen: bool.isRequired,
  origName: string,
  origErrorMessage: string,
  checkName: func.isRequired,
  onCancel: func.isRequired,
  onSubmit: func.isRequired,
};

AudienceNameModal.defaultProps = {
  origName: '',
  origErrorMessage: '',
};

export default AudienceNameModal;
