var TrendChart = require("common/charts/trend-chart");

var consumptionConfiguration = {
    element: 'alert-consumption-graph',
    type: 'daily',
    force_max: true,
    agg_examples_mode: true,
    maxValue: 100,
    templates: {
      normalized: _.template("Consumption <%= parseFloat(consumption.toFixed(1)) %>, <%= (consumptionAvg > 0) ? '+' : '' %><%= consumptionAvg %>% from Avg")
    }
};

module.exports = angular.module(__filename, [
    require('data/insights/alerts-insights-service').name
]).controller("alertsConsumptionGraph", ['$scope', '$timeout', 'close', 'interest', 'alert', 'alertsService', function ($scope, $timeout, close, interest, alert, alertsService) {
    var trigger = alert.triggers.compare_to_avg;
    $scope.alert = alert;
    $scope.close = close;
    init();
    $scope.legend = function () {
        var value = 1;
        var unit = 'STDev';
        if (trigger.selected) {
            if (trigger.stdev) {
                value = trigger.stdev;
            } else {
                value = trigger.percentage;
                unit = alert.metadata.sentiment == 'net' ? 'pts.' : '%';
            }
        }
        return `${Math.abs(value)}${unit} ${(value < 0 || $scope.decreaseAlert) ? 'Below' : 'Above'} Average`
    };

    function init() {
        $scope.graphOptions = Object.assign({}, consumptionConfiguration, calcGraphBounds(alert.metadata.sentiment == 'net'));
        switch (alert.metadata.type) {
            case 'sentiment':
                $scope.alertType = _.find(alertsService.sentimentDetection, {id: alert.metadata.sentiment}).label;
                $scope.decreaseAlert = alert.metadata.change == 'dec';
                $scope.avgLineTitle = 'Average';
                break;
            case 'consumption':
                $scope.alertType = _.capitalize(alert.metadata.type);
                $scope.avgLineTitle = 'Moving Average';
                break;
        }

        $scope.title = `${interest.text} Monthly ${$scope.alertType} Trend`;
    }

    function calcGraphBounds(withNegative) {
        var stdevData = _.find(interest.graphData.chart, ["term.text", "STDev"]);
        var maxValue = _.maxBy(stdevData.series, "value").value;
        var minValue = _.minBy(stdevData.series, "value").value;
        return {
            minValue: (withNegative ? Math.min(-90, minValue) : Math.min(10, minValue)) - 10,
            maxValue: Math.max(90, maxValue) + 10
        }
    }

    function draw() {
        $scope.trends = _.map(interest.graphData.chart, 'term');
        $timeout(function () {
            var chart = new TrendChart(this, $scope.graphOptions.element, $scope.graphOptions);
            chart.draw(interest.graphData, $scope.graphOptions.maxValue, "normalized", null, false, false);
            chart.addCircles(interest.examples, false);
        });
    }

    draw();
}]);
