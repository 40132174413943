module.exports = angular.module(__filename, []).directive('validatePwd', function () {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, element, attr, ctrl) {
            function validatePwd(ngModelValue) {
                var skip = false;
                // nothing to validate
                if (!ngModelValue || ngModelValue.length == 0) {
                    ctrl.$setValidity('hasUppercase', true);
                    ctrl.$setValidity('hasNumber', true);
                    ctrl.$setValidity('length', true);
                    return ngModelValue;
                }

                // check if contains uppercase
                if (/[A-Z]/.test(ngModelValue)) {
                    ctrl.$setValidity('hasUppercase', true);
                } else {
                    ctrl.$setValidity('hasUppercase', false);
                    skip = true;
                }

                // check if contains number
                if (/[0-9]/.test(ngModelValue) || skip) {
                    ctrl.$setValidity('hasNumber', true);
                } else {
                    ctrl.$setValidity('hasNumber', false);
                    skip = true;
                }

                // check if the length of our input is at least six characters long
                if (ngModelValue.length > 5 || skip) {
                    ctrl.$setValidity('length', true);
                } else {
                    ctrl.$setValidity('length', false);
                }

                return ngModelValue;
            }

            ctrl.$parsers.push(validatePwd);
        }
    };
});
