"use strict";
import { getLinkedinCompaniesInfoByNames } from '../../../react/services/AudienceInsightsService';

module.exports = angular.module(__filename, [
    require("data/keywords.srv").name
]).factory("TermsMold", ["$q", "$rootScope", "$state", "keywords", "util", "notificator", "SIGNS", "CHART",
function ($q, $rootScope, $state , keywords, util, notificator, SIGNS, CHART) {
    function TermsMold(context) {
        this._value = [];
        this.default = [];
        var DEFAULT_CLASS = 'term-default';

        this.generateClass = function (usedClasses, howMany) {
            usedClasses = usedClasses || _.map(this._value, 'class');
            howMany = howMany || 1;

            var retClass = [];
            for (var i = 0, c; c = CHART.TERM_CLASSES[i]; i++) {
                if (usedClasses.indexOf(c) == -1) {
                    retClass.push(c);
                    if (retClass.length >= howMany) {
                        break;
                    }
                }
            }

            while (retClass.length < howMany) {
                retClass.push(DEFAULT_CLASS);
            }

            return retClass;
        }.bind(this);

        function getTerms(args) {
            var terms = [];
            for (var i = 0; i < args.length; i++) {
                if (angular.isArray(args[i])) {
                    terms = terms.concat(getTerms(args[i]))
                } else if (angular.isString(args[i])) {
                    terms.push({text: args[i]});
                } else {
                    terms.push(args[i])
                }
            }

            return terms;
        }

        function notifyIfInputCombined(userInput, changedInput) {
            if (userInput.toLowerCase() != changedInput.toLowerCase()) {
                var message = "We combined <user_input> with <changed_input>. Please contact us if we did it wrong.";
                notificator.notify({body: message.replace("<user_input>", userInput).replace("<changed_input>", changedInput)});
            }
        }

        /* take array of terms, objects and term name and return promise that return just the new terms */
        /* input-bar using this */
        this.validate =  (term, usedClasses, replace, validate) => {
            if (validate === undefined) {
                validate = true;
            }

            var defer;
            if (term === undefined) {
                defer = $q.defer();
                defer.resolve([]);
                return defer.promise
            }

            var termsToValidate = _.map(getTerms([term]), 'text');
            var usedClasses = replace ? [] : (usedClasses || null);
            var classes = this.generateClass(usedClasses, termsToValidate.length);
            if (term.type === 'booleanLogic' || term.type === 'programBL') {
                term.class = classes.shift();
                defer = $q.defer();
                term.display = util.getTermDisplay(term);
                defer.resolve([term]);
                return defer.promise;
            }

            // posts start with "u:@" (SIGNS.POST)
            // mentions with "@" (SIGNS.MENTION)

            var posts       = _.remove(termsToValidate, _.partial(_.startsWith, _, SIGNS.POST)).map(createTermCallback('post', classes)),
                hashtags    = _.remove(termsToValidate, _.matchesProperty('[0]', '#')).map(createTermCallback('hashTag', classes)),
                mentions    = _.remove(termsToValidate, _.matchesProperty('[0]', SIGNS.MENTION)).map(createTermCallback('mention', classes)),
                program_bls = _.filter(_.remove(termsToValidate, _.partial(_.startsWith, _, SIGNS.PROGRAM_INTEREST)).map(createTermProgramBLCallback(classes)));

            var ctx = $rootScope.context;
            var language = ctx.current._language_mold.getLanguage($state, $rootScope.context).value;


            var isResolveKeywords = ctx.current._language_mold.isResolveKeywords($state, ctx);

            var validatedPromise = termsToValidate.length <= 0 ? $q.when({}) :

                isResolveKeywords ? keywords.validation(termsToValidate, language) :

                keywords.noValidation(termsToValidate);


            var validation = validatedPromise.then(function (ids) {

                var newTerms = _.map(ids, function (data, term) {
                    var text = term, termId = null;
                    if (data && validate) {
                        text = data.disp;
                        termId = data.id;
                        notifyIfInputCombined(term, text);
                    }

                    return validate ? createTerm(text, termId, termId ? classes.shift() : '', !termId, 'term', term) :
                        createTerm(text, ((data != undefined && data.id) ? data.id : -1), classes.shift(), false, 'term')
                });

                return _.compact(newTerms);
            });

            var promise = $q.all([$q.when(hashtags), $q.when(mentions), $q.when(posts), $q.when(program_bls), validation]).then(function (res) {
                return _.flatten(res);
            });

            promise.abort = validatedPromise.abort;
            return promise;

            function createTermCallback(type, classes) {
                return function (text) {
                    return createTerm(text, -1, classes.shift(), false, type);
                }
            }

            function createTermProgramBLCallback(classes) {
              return function (text) {
                var term = _.find($rootScope.context.program.boolean_logics, {text: text.trim().replace(SIGNS.PROGRAM_INTEREST, '')});
                if (term === undefined) return term;
                term.class = classes.shift();
                term.display = util.getTermDisplay(term);
                term.origin = text;
                return term;
              }
            }
        };

        const validDomainPattern = /^([a-z]|[a-z][a-z]|[a-z][0-9]|[0-9][a-z]|[a-z0-9][a-z0-9_-]{1,61}[a-z0-9])\.([a-z]{2,6}|[a-z0-9-]{2,30}\.[a-z]{2,3})$/i;
        this.validateDomain = function (term, replace) {
            const termsToValidate = getTerms([term]).map(({text:domain}) => ({origin: domain, text: util.extractDomainFromURL(domain).toLowerCase()}));
            const usedClasses = replace ? [] : null, classes = this.generateClass(usedClasses, termsToValidate.length);
            const domainObjs = termsToValidate.map((term) => {
                const valid = validDomainPattern.test(term.text), id = valid ? term.text : null;
                return createDomainTerm(term.text, id, classes.shift(), !valid, 'domain', term.origin);
            });
            return Promise.resolve(domainObjs);
        };

        this.validateCompanyName = function (terms, userId) {
            let companiesPromise = createCompanyTermsCallback(terms, userId);

            return $q.resolve(companiesPromise).then((res) => {
                return _.flatten(res);
            });

            function createCompanyTermsCallback(terms, userId) {
                return getLinkedinCompaniesInfoByNames(terms, userId).then(data => {
                    const newTerms = _.map(data, (termData, termOrigin) => {
                        const invalid = _.isEmpty(termData);
                        const id = invalid ? null : termData.id;
                        const termText = (termData || {}).name || termOrigin;
                        return createTerm(termText, id, DEFAULT_CLASS, invalid, 'company', termOrigin);
                    });

                    return newTerms;
                })
            }
        };

        this.validateApp = function (terms, replace) {
            const termsToValidate = getTerms([terms]).map(({text: domain}) => ({origin: domain, text: util.extractDomainFromURL(domain).toLowerCase()}));
            const appObjs = termsToValidate.map((term) => {
                return createTerm(term.text, term.text, DEFAULT_CLASS, false, 'app', term.origin);
            });
            return Promise.resolve(appObjs);
        };

        function createDomainTerm(text, id, className, invalid, type, origin) {
            return createTerm(text, id, className, invalid, type, origin)
        }

        /* input-bar using this */
        function createTerm(text, id, className, invalid, type, origin) {

            var term = {
                text: text ? text.trim() : null,
                id: id || null,
                class: className || null,
                invalid: (invalid != void 0) ? invalid : !id,
                type: type,
                origin: origin || text,
                display: text
            };

            term.display = util.getTermDisplay(term);
            util.ignoreSerialize(term, 'invalid');
            util.ignoreSerialize(term, 'display');

            return term;
        }

        this.replace = this.add = function (terms, replace, isDomain) {
            var self = this;
            return $q(function (resolve) {
                if ((terms instanceof Array) && terms.length == 0) {
                    resolve(self._value);
                    return;
                }

                if (typeof terms === 'string') terms = [terms];
                if (terms === void 0) terms = [];
                if (terms[0] && typeof terms[0] !== 'string') {
                    terms.forEach(function (term) {
                        term['type'] = term['type'] || 'term';
                    });

                    self._value = terms;
                    resolve(self._value);
                } else {
                    var currentTermsTexts = replace ? [] : _.map(self._value, 'text');
                    var newTermsText = _.map(getTerms(terms), 'text');
                    var requestedTermsTexts = _.difference(newTermsText, currentTermsTexts);
                    var promise = isDomain ? self.validateDomain(requestedTermsTexts, replace) : self.validate(requestedTermsTexts, null, replace)
                    promise.then(function (validatedTerms) {
                        var newValidatedTerms = validatedTerms.filter(function (t) {
                            return currentTermsTexts.indexOf(t.text) == -1;
                        });
                        self._value = replace ? newValidatedTerms : self._value.concat(newValidatedTerms);
                        resolve(self._value);
                    });
                }
            });
        }.bind(this);
    }

    return TermsMold;
}]);
