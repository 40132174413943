var ex = require("infra/utils/export");

function TimingController($scope, insightsExportService) {
  insightsExportService.setTitle('Timing');
}

TimingController.$inject = ['$scope', 'insightsExportService'];

module.exports.stateConfig = {
    name: "timing",
    url: "/timing",
    template: require("./timing.insights.html"),
    display: "Insights",
    data: {
        permissions: ['timing']
    },
    controller: TimingController,
    context: {}
};
