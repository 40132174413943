import config from 'infra/config';

module.exports = angular.module(__filename, []).
    factory('mgmtServiceFactory', ['$http', 'notificator', 'context', function ($http, notificator, context) {
        var routeMap = {
            domain: {
                url: '/domains'
            },
            organization: {
                url: '/organizations'
            },
            user: {
                url: '/users'
            },
            program: {
                url: '/programs'
            },
            alert: {
                url: '/programs/{PROGRAM_ID}/alerts',
                placeholder: '{PROGRAM_ID}',
                replaceMethod: currentProgram
            },
            alert_v2: {
                url: '/programs/{PROGRAM_ID}/alerts-v2',
                placeholder: '{PROGRAM_ID}',
                replaceMethod: currentProgram
            },
            alerts_notification_settings: {
                url: '/programs/{PROGRAM_ID}/alerts-v2/notification_settings',
                placeholder: '{PROGRAM_ID}',
                replaceMethod: currentProgram
            },
            'grid target': {
                url: '/programs/{PROGRAM_ID}/grid_target',
                dynamicUrl: '/programs/grid_target',
                placeholder: '{PROGRAM_ID}',
                replaceMethod: currentProgram
            },
            audience: {
                url: '/programs/{PROGRAM_ID}/audience_segment',
                placeholder: '{PROGRAM_ID}',
                replaceMethod: currentProgram
            },
            first_party: {
                url: '/first_party'
            }
        };
        var suffix = "?disable_notification=1";
        var dynamicUrl = "/update_dynamic";

        var mgmtServiceProvider = function (type) {
            var currentType = type;
            var baseUrl = routeMap[type];
            this.list = list;
            this.get = get;
            this.create = create;
            this.update = update;
            this.updateDynamic = updateDynamic;
            this.delete = deleteItem;
            this.customRequest = customRequest;
            this.getErrorMessage = getErrorMessage;
            var self = this;

            function get(id) {
                return $http.get(url() + "/" + id + suffix).then(responseSuccess, responseError);
            }

            function list() {
                return $http.get(url() + suffix).then(responseSuccess, responseError);
            }

            function create(item, config = {}) {
                return $http.post(url() + suffix, item, config).then(responseSuccess, responseError);
            }

            function update(id, params, config = {}) {
                return $http.put(url("dynamicUrl") + '/' + id + suffix, params, config).then(responseSuccess, responseError);
            }

            function updateDynamic(id, params) {
                return $http.post(url("dynamicUrl") + '/' + id + dynamicUrl + suffix, params).then(responseSuccess, responseError);
            }

            function deleteItem(id, config = {}) {
                return $http.delete(url() + '/' + id + suffix, config).then(responseSuccess, responseError);
            }

            function customRequest(method, path, params, headers) {
                return $http({
                    method: method,
                    headers: headers,
                    url: url() + path + suffix,
                    data: params
                }).then(responseSuccess, responseError);
            }

            function responseError(err) {
                var notification;
                switch (err.config.method) {
                    case 'PUT':
                        notification = "Failed to edit " + currentType + ". " + self.getErrorMessage(err.data);
                        break;
                    case 'DELETE':
                        notification = "Failed to delete " + currentType;
                        break;
                    case 'POST':
                        notification = "Failed to create " + currentType + ". " + self.getErrorMessage(err.data);
                        break;
                    default:
                        notification = "Something went wrong, our engineers are working on it.";
                        break;
                }

                notificator.error({body: notification});
                return Promise.reject(err);
            }

            function getErrorMessage(error) {
                var errorKeys = Object.keys(error.errors || {});
                return errorKeys.map(function (key) {
                    return key + ' ' + error.errors[key];
                }).join();
            }

            function responseSuccess(response) {
                return response.data;
            }

            function url(caller) {                
               var url = config.USER_MGMT_API + (baseUrl[caller] || baseUrl.url);
                if (baseUrl.placeholder) {
                    url = url.replace(baseUrl.placeholder, baseUrl.replaceMethod())
                }
                return url;
            }
        };

        return {
            createService: function (type) {
                return new mgmtServiceProvider(type);
            }
        };

        function currentProgram() {
            return context.program.id;
        }
    }]
);
