import config from 'infra/config';
import 'angular-cookies';

const HttpClient = require('../../react/infra/HttpClient');
import {setLogoutAuthenticationService} from '../../react/middleware/AngularExportedMiddleware';
import * as MixpanelCommon from '../../react/infra/mixpanel/MixpanelCommon';
import {
    setLocalStorageWithTTL,
} from '../../react/services/LocalStorageService.js';

export default angular.module(__filename, [
    require("common/sso/sso-service").name,
    'ngCookies'

]).service("authentication", ['$rootScope', 'abiPermissions', '$injector', '$http', '$q', '$auth', '$state', '$window', '$location', '$cookies', 'userMgmt', 'authenticationConfig', 'ssoService', 'TABS',
    function ($rootScope, abiPermissions, $injector, $http, $q, $auth, $state, $window, $location, $cookies, userMgmt, authenticationConfig, ssoService, TABS) {
        this.logout = logout;
        setLogoutAuthenticationService(logout);
        this.authenticate = autoAuthenticate;
        this.authenticatePage = authenticatePage;
        this.setRedirectUrl = setRedirectUrl;

        var APP_TO_PAGE_MAP = {
            "trending": "interests.bubbles",
            "content board": "interests.board",
            "channel insights": "interests.grid.phrases.web",
            "seasonality": TABS.INSIGHTS_SEASONALITY,
            topics: TABS.INSIGHTS_TOPIC,
            sentiment: TABS.INSIGHTS_SENTIMENT,
            associations: TABS.INSIGHTS_ASSOCIATION,
            channels: TABS.INSIGHTS_CHANNELS,
            timing: TABS.INSIGHTS_TIMING,
            landscape: TABS.INSIGHTS_LANDSCAPE,
            geos: TABS.INSIGHTS_REGIONS,
            audience: "audience-builder",
            alerts: "alerts"
        };

        var reAuthTs = 0;
        var MAX_MILLSEC_BETWEEN_AUTHS = 5000;
        var reAuthPromise = null;
        var reAuthURLs = {};
        var reauthListener = angular.noop;
        var PAGE_REDIRECTIONS = {"audience-explorer": "audience-builder"};
        var hash = $window.location.hash;
        var search = $location.search();

        function autoAuthenticate() {
            if ($window.location.pathname.indexOf('login') !== -1) return $q.reject('Already in login');
            if (!localStorage.getItem('abi-session')) {
                ssoService.redirectToLogin(hash);
            }

            if ($auth.isAuthenticated()) {
                return userMgmt.user($auth.getToken()).then(function (response) {
                    return onLoginSuccess({user: response, token: $auth.getToken()});
                });
            } else {
                ssoService.redirectToLogin(hash);
            }
        }

        function authenticatePage(state) {
            let perms = state.data.permissions;
            return 0 == perms.length || perms.every((perm) => {
                return abiPermissions.hasPermission(perm);
            });
        }

        function setRedirectUrl(redirectUrl) {
            let urlMinusDomain = redirectUrl.replace(/^.*\/\/[^\/]+/, '');
            setLocalStorageWithTTL('last_page', urlMinusDomain, 60 * 1000)
        }

        function isUsingSsoWrapper() {
            return $q(function (resolve, reject) {
                resolve(ssoService.isUsingSso());
            });
        }

        function authenticationLogout(req_config, isSso, isHardLogout) {
            return $http.post(config.AUTHENTICATION_API + "/logout", {}, req_config)
                .then(function (response) {
                })
                .catch(function (data) {
                    MixpanelCommon.sendToMixpanel("Logout", {
                        hard: isHardLogout,
                        sso: isSso,
                        status: data.status,
                        error: data.statusText
                    });
                });
        }

        function logoutSso(isHardLogout, isSso) {
            return ssoService.getAccessToken().then(function (accessToken) {
                let req_config = {};
                if (accessToken && isHardLogout) {
                    req_config = {'headers': {'SSO-Access-Token': accessToken.accessToken}};
                }
                return authenticationLogout(req_config, isSso, isHardLogout);
            });
        }

        //isHardLogout: true means Hard logout from all SSO apps
        function logout(isHardLogout) {
            isUsingSsoWrapper().then(function (isSso) {
                MixpanelCommon.sendToMixpanel("Logout", {hard: isHardLogout, sso: isSso});
                if (isSso) {
                    return logoutSso(isHardLogout, isSso);
                } else {
                    let req_config = {}
                    return authenticationLogout(req_config, isSso, isHardLogout); //logout Legacy
                }
            }).finally(function (response) {
                HttpClient.setCommonHeader('Authorization', null);
                $auth.logout();
            }).then(function () {
                // Waiting for the state transition to end,
                // and after that prevent state changes to other states in the application
                reauthListener = $rootScope.$on('$stateChangeStart', function (event, nextState) {
                    event.preventDefault();
                });
                ssoService.redirectToLogin(hash, isHardLogout);
            });
        }

        function onLoginSuccess(data) {
            HttpClient.setCommonHeader('Authorization', data.token);
            // Will destroy the reauth listener if exists.
            reauthListener();

            if ($window.location.pathname.indexOf('login') !== -1) return $window.location.replace('/');
            return postAuthentication(userMgmt.buildUser(data.user));
        }

        function postAuthentication(user) {
            return $q.all(startListeners(user)).then(function () {
                if ($state.params.help) {
                    return $window.location.href = 'http://help.intelligence.amobee.com';
                }
                const permissions = user.permissions.map(function (permission) {
                    return permission.name;
                });

                // If no dashboard for user navigate to the first application in map
                const allowedApps = _.intersection(Object.keys(APP_TO_PAGE_MAP), permissions);
                const appStates = allowedApps.map(function (app) {
                    return APP_TO_PAGE_MAP[app].split('.')[0];
                }).concat('settings');

                let nextState = permissions.indexOf('dashboard') == -1 ? APP_TO_PAGE_MAP[allowedApps[0]] : 'dashboard';
                let wantedState = hash.substring(2).replace(/\//g, '.').replace(/#.*/g, '');

                if (hash && hash != "#/" &&
                    false == $window.location.href.includes('login')) { //user entered a specific url
                    nextState = wantedState;
                } else { //user entered default url
                    if (PAGE_REDIRECTIONS[wantedState]) wantedState = PAGE_REDIRECTIONS[wantedState];
                    nextState = appStates.includes(wantedState.split('.')[0].split('?')[0]) ? wantedState : nextState;
                }
                nextState = nextState.split('?')[0];

                $state.go(nextState, {reload: true, ...search}).then(function () {
                }).catch(function (err) {
                    console.log(err)
                });

                //add the iframe other apps use to login
                if ($("#loginFrames").length) {
                    $("body").remove("#loginFrames");
                    setTimeout(() => loginIframe());
                } else {
                    loginIframe();
                }

                function loginIframe() {
                    if (ssoService.isProduction) {
                        ssoService.getAccessToken().then(function (data) {
                            let url = "https://ssomgr-api.amobee.com/web/login?accessToken=" + data.accessToken + "&app=APP_BRAND_INTELLIGENCE";
                            $("body").append('<iframe id="loginFrames" src="' + url + '" style="display: none;"></iframe>');
                        });
                    }
                }

                return user;
            });
        }

        function startListeners(authInfo) {
            return authenticationConfig.doAfterAuthentication.map(function (cb) {
                return $injector.invoke(cb, null, {authInfo: authInfo});
            });
        }
    }
]);
