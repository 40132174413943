var d3 = require("d3"),
	$ = require("jquery");

function TimelineChart(elem) {

	this.elem = elem;
	Object.defineProperty(this,"dateFormat",{
		set: function(value){
			this.parseDate = d3.time.format(value).parse;
			this.toStringDate = d3.time.format(value);
		}
	});
	this.dateFormat = "%Y-%m-%d";

	this.svg = d3.select(this.elem).append("svg").attr("width","100%")
		.attr("height","100%");
	var defs = this.svg.append("svg:defs")
		.append("svg:linearGradient")
		.attr("id","brandgradient")
		.attr("x1","100%")
		.attr("y1","0%")
		.attr("x2","100%")
		.attr("y2","100%")
		.attr("spreadMethod","pad");
	defs.append("stop")
		.attr("offset","0%")
		.attr("stop-color","#91e3f5 ")
		.attr("stop-opacity","0.2");
	defs.append("stop")
		.attr("offset","100%")
		.attr("stop-color","#91e3f5 ")
		.attr("stop-opacity","0");
	this.svg = this.svg.append("g");
	this.setup();
}
TimelineChart.prototype.setup = function(){


	this.svg.append("g")
		.attr("class", "x-axis axis");
	this.svg.append("g")
		.attr("class","y-axis axis");

};
TimelineChart.prototype.resize = function(width,height){
	this.svg.selectAll(".point").remove();
	var jElem = $(this.elem);
	var padding = {
		left:50,
		right: 20,
		top: 48,
		bottom: 40
	};
	this.width = width || jElem.width() - padding.left - padding.right;
	this.height = height || jElem.height() - padding.top - padding.bottom;

	d3.select(this.elem).select("svg")
		.attr("style","position:absolute");
	this.svg.attr("transform","translate(" + padding.left + "," + padding.top + ")");
	this.xScale = d3.time.scale()
		.range([0, this.width]);
	this.yScale = d3.scale.linear()
		.range([this.height, 0]);

	this.svg.select("g.x-axis")
		.attr("transform", "translate(0," + this.height + ")")

};
TimelineChart.prototype.draw = function(lines,classes){
	this.svg.selectAll(".point").remove();
    this.svg.selectAll("path.line").remove();
    this.svg.selectAll(".small-ticks").remove();
	var self = this;
	var maxValue = 0;
	var lineData = [];

	Object.keys(lines).forEach(function(key){
        var series = $.map(lines[key],function(v,k){
            if (v > maxValue) {
                maxValue = v;
            }
            return {date: new Date(k),value:v}
        });
        series = _.sortBy(series, 'date');
		lineData.push({
			series:series,
			class: classes[key]
		});
	});
	self.lines = lines;
	this._drawAxes(lineData[0].series.map(function(v){return v.date}),maxValue);
	this._drawLines(lineData);
};
TimelineChart.prototype._drawAxes = function(dateRange, maxValue) {
	var xAxis = d3.svg.axis()
		.scale(this.xScale)
		.orient("bottom")
        .tickFormat(d3.time.format("%m/%d"))
		.ticks(10);

	var yAxis = d3.svg.axis()
		.scale(this.yScale)
		.orient("left")
        .ticks(7)
		.tickSize(- this.width);

	this.xScale.domain(d3.extent(dateRange));
	this.yScale.domain([0,Math.max(maxValue, 100)]);

	this.svg.selectAll("g.x-axis").call(xAxis);
    this.svg.selectAll("g.x-axis text").attr("x", 25);
    this.svg.selectAll("g.x-axis .tick line").attr("y1", 9).attr("y2", 20).style("stroke", "#cacaca");
    // Hide last x tick if we have too many ticks, as the last tick go out of the graph
    var xTicks = this.svg.selectAll("g.x-axis .tick")[0];
    if (xTicks && xTicks.length > 14){
        d3.select(xTicks.reverse()[0]).attr("visibility","hidden");
    }

    this.svg.selectAll("g.y-axis").call(yAxis);
    this.svg.selectAll("g.y-axis text").attr("y", -10).attr("x", function(d) {
        var numOfDigits = d.toString().length;
        return -30 + (numOfDigits - 1) * 7;
    });
    this.svg.selectAll("g.y-axis .tick line").attr("x1", -10).attr("y1", 0);
    this.svg.selectAll("g.y-axis .tick").append("svg:line")
        .attr("x1", -10)
        .attr("y1", 0)
        .attr("x2", -40)
        .attr("y2", 0)
        .style("stroke", "#cacaca")
        .attr("class", "small-ticks");
};
TimelineChart.prototype._drawLines = function(lineData){
	var self = this;
	var line = d3.svg.line()
		.interpolate("monotone")
		.x(function(d) { return self.xScale(d.date); })
		.y(function(d) { return self.yScale(d.value); });

	var path = this.svg.selectAll("path.line").data(lineData);

	path.attr("d",function(d){return line(d.series)});
	path.enter().append("path")
		.attr("class", function(d){return "line " + d.class})
		.attr("d",function(d){return line(d.series)});

	//draw area below first line;
	var area = d3.svg.area()
		.interpolate("monotone")
		.x(function(d) { return self.xScale(d.date); })
		.y0(this.height)
		.y1(function(d) { return self.yScale(d.value);});

	var gard = this.svg.selectAll("path.area").data([lineData[0]]);
	gard.attr("d",function(d){return area(d.series)});
	gard.enter().append("path")
		.attr("class", function(d){return "area " + d.class})
		.attr("d",function(d){return area(d.series)});
};
TimelineChart.prototype.drawPoints = function(points){
	var self = this;

	var circles = this.svg.selectAll("circle.point")
		.data(points);
	circles.enter().append("svg:circle")
		.attr("class",function(d){return "point " + d.class})
		.attr("fill","#202427")
		.attr("cx", function(d, i) { return self.xScale(d.date)})
		.attr("cy", function(d, i) {
			return self.yScale(self.lines[d.term][self.toStringDate(d.date)])
		})
		.attr("r", function(d, i) { return 10 })
		.attr("class",function(d){return "point " + d.class});
	var text = this.svg.selectAll("text.point")
		.data(points)
		.enter()
		.append("text")
		.attr("class","point")
		.attr("style","font:bold 14px Arial;fill:white;")
		.text(function(d){return d.text})
		.attr("x", function(d, i) { return self.xScale(d.date) - 5 })
		.attr("y", function(d, i) {
			return self.yScale(self.lines[d.term][self.toStringDate(d.date)]) + 5
		})
};

module.exports = TimelineChart;
