function BubblesController() {}

BubblesController.$inject = [];

module.exports.stateConfig = {
    name: "bubbles",
    url: "/bubbles",
    template: require("./bubbles.interests.html"),
    display: "Interests",
    data: {
      permissions: ['trending']
    },
    controller: BubblesController,
    context: {}
};
