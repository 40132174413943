import { setDisplayCustomErrorService } from '../../react/middleware/AngularExportedMiddleware';

module.exports = angular.module(__filename, [])
    .service('errorMgmt', ['notificator', '$q', '$timeout', function (notificator, $q, $timeout) {
        var counter, cartridge;
        counter = 0;
        cartridge = [];

        // API ..
        this.error = handleGeneralError;
        this.httpError = handleGeneralError;
        this.widgetServiceError = handleWidgetError;
        this.customError = customError;
        this.customSuccessMessage = customSuccessMessage;
        /////////

        function onLine() {
            if (navigator.onLine) {
                return true
            } else {
                notificator.error({body: 'Internet connection lost. Please try again later.'});
                return false
            }
        }

        function updateCartridge(err) {
            var error = {id: counter, status: err.status || 0, time: new Date().getTime(), isSuccess: err.isSuccess};

            counter++;
            cartridge.push(error);
            return error;
        }

        function handleGeneralError(err) {
            if (!onLine()) return;

            // won't notify on authorization failure
            if (err.status == 401) return;

            updateCartridge(err);
            const notice = messageByStatusCode(err.status);
            if (notice !== '') notifyUser(notice);
        }

        function notifyUser(notice) {
            if (cartridge.length == 0) return;
            $timeout(function () {
                const successMessages = cartridge.filter((element) => element.isSuccess);
                if (successMessages.length > 0) {
                    notificator.success({body: notice})
                } else {
                    const not205errors = cartridge.filter((element) => element.status != 205);

                    if (not205errors.length == 1) {
                        notificator.error({body: notice});
                    } else {
                        if (not205errors.length == 0) {
                            return;
                        }
                        notificator.error({body: 'Sorry, something went wrong. Our engineers are working quickly to resolve the issue.'});
                    }
                }

                cartridge = [];
            }, 1000);
        }

        function messageByStatusCode(statusCode) {
            switch (statusCode) {
                case 400:
                case 500:
                    return 'Sorry, something went wrong. Our engineers are working quickly to resolve the issue.';
                case 502:
                case 503:
                    return 'Internet connection lost. Please try again later.';
                case -1:
                case 0:
                case 504:
                    return 'Oops, something went wrong, please try again.';
                default:
                    return 'Oops, something went wrong, please try again.';
            }
        }

        function handleWidgetError(wName, err) {
            if (!onLine()) return;
            updateCartridge(err);
            const notice = 'Sorry, ' + wName + ' is currently unavailable. Please try again later.';
            notifyUser(notice);
        }

        function customError(notice, statusCode = 500) {
            customNotice(notice, {status: statusCode});
        }

        function customSuccessMessage(notice, statusCode = 500) {
            customNotice(notice, {status: statusCode, isSuccess: true});
        }

        function customNotice(notice, cartridgeRecord) {
            if (!onLine()) return;
            updateCartridge(cartridgeRecord);
            notifyUser(notice);
        }

        setDisplayCustomErrorService(customError);
    }]
);
