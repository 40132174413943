import isEmail from 'validator/lib/isEmail';

module.exports = angular.module(__filename, []).directive('emailFormValidator', function () {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, element, attr, ctrl) {

            function emailValidator(ngModelValue) {
              ctrl.$setValidity('invalid', isEmail(ngModelValue));
              return ngModelValue;
            }

            ctrl.$parsers.push(emailValidator);
        }
    };
});
