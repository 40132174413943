import 'angular-modal-service';

module.exports = angular.module(__filename, [
    'angularModalService'
]).directive('programSelector', ['$rootScope', '$timeout', 'programService', 'notificator', 'abiPermissions', 'util',
  function ($rootScope, $timeout, programService, notificator, abiPermissions, util) {
    return {
        restrict: 'E',
        template: require('./program-selector.drv.html'),
        scope: {},
        link: function (scope) {
            scope.listOpen = false;
            scope.programs = getUserPrograms();
            scope.selected = scope.programs[0];
            let context = $rootScope.context;

            scope.clearPlaceholder = (event) => event.currentTarget.placeholder = '';
            scope.setPlaceholder = (event, placeholder) => event.currentTarget.placeholder = placeholder;

            scope.select = function (option) {
                if (option?.id !== context.program?.id) {
                    $rootScope.programChanged = true;
                }
                context.changeContext(option);
                selectProgram(option);
                scope.closeOptionsList();
            };

            scope.create = function () {
                if (scope.newName.length < 2) {
                    notificator.error({body: 'Program name is too short'});
                    return;
                }

                const program = {
                    name: scope.newName,
                    competitors: [],
                    creator_id: $rootScope.user.id,
                    account_id: $rootScope.user.account.id,
                    sensitive_content: abiPermissions.hasPermission("Include sensitive content by default")
                };
                programService.create(program).then(function (data) {
                    scope.programs.push(data);
                    $rootScope.user.programs.push(data);
                    scope.select(data);
                    notificator.success({body: data.name + ' was saved successfully'});
                    $rootScope.$broadcast('new-program');
                });

                scope.closeOptionsList();
            };

            scope.toggleOpen = function () {
                scope.listOpen = !scope.listOpen;
            };

            scope.closeOptionsList = function () {
                scope.listOpen = false;
                scope.search = '';
                scope.newName = '';
            };

            const removeListener = $rootScope.$watch('context.program', () => selectProgram(context.program), true);

            scope.$on('$destroy', removeListener);

            scope.$watch(() => $rootScope.user.programs, syncUserPrograms, true);

            scope.$on('program-removed', function (event, p_id) {
                const index = _.findIndex($rootScope.user.programs, {id: p_id});
                $rootScope.user.programs.splice(index, 1);
                if (p_id === scope.selected.id) scope.select(scope.programs[0]);
            });

            function getUserPrograms() {
                const hasFirstPartyPermission = abiPermissions.hasPermission(['my segments program', 'first party segments']);
                return _.filter($rootScope.user.programs, (prog) => hasFirstPartyPermission ||
                                                                    !util.isMySegmentsProgram(prog));
            }

            function syncUserPrograms() {
                scope.programs = getUserPrograms().map(function (program) {
                    program.selected = (_.find(scope.programs, {id: program.id}) || {}).selected;
                    return program;
                });
            }

            function selectProgram(program) {
                scope.programs.forEach((p) => p.selected = false);

                let selectedOption = _.find(scope.programs, {id: program.id});
                if (selectedOption) {
                    selectedOption.selected = true;
                    $timeout(() => scope.selected = selectedOption);
                } else {
                    scope.select(scope.programs[0]);
                }
            }
        }
    }
}]);
