import { isString, noop } from 'lodash';
import { sendErrorToServer } from '../services/LoggerService';
import { extractAppNameFromUrl } from './LoggerUtils';
import { getUserEmailFromToken } from './PlatformAuthUtils';
import throttle from 'lodash/throttle';

const unthrottledLogError = (errorInfo) => {
  sendErrorToServer(errorInfo);
};

// Don't send log to server in development mode, as usually we works in development mode with production abi-mgmt,
// which will cause indexing dev errors into the log server. In integration we work with integration abi-mgmt, which
// has flag not to index them
// eslint-disable-next-line no-undef
export const logError = IS_DEVELOPMENT ? noop : throttle(unthrottledLogError, 60000, { trailing: false });

export const logWidgetError = (error, errorInfo) => {
  const isHttpErr = isHttpError(error);
  const httpErrorDetails = isHttpErr ? extractHttpErrorDetails(error) : undefined;
  const errorMessage = isHttpErr ? httpErrorDetails.errorMessage : error.message;
  const stacktrace = isHttpErr ? httpErrorDetails.stacktrace : error.stack;
  // eslint-disable-next-line no-undef
  const biVersion = ABI_VERSION;
  const url = window.location.href;
  const app = extractAppNameFromUrl(url);
  const userEmail = getUserEmailFromToken();

  logError({
    errorMessage,
    stacktrace,
    componentStack: errorInfo.componentStack && errorInfo.componentStack.componentStack,
    widgetName: errorInfo.widgetProps.name,
    widgetDataServiceParams: errorInfo.widgetProps.dataServiceParams,
    widgetRenderOnValueChangeValues: errorInfo.widgetProps.renderOnValueChange,
    httpErrorDetails,
    biVersion,
    url,
    app,
    userEmail: userEmail,
  });
};

const isHttpError = (error) => error.response && error.response.status;

const extractHttpErrorDetails = (error) => {
  try {
    const service = error.config.url.split('/').slice(0, 3).join('/');
    const response = error.response.data;
    const errorMessage = isString(response) ? response : JSON.stringify(response);

    return {
      errorMessage,
      statusCode: error.response.status,
      statusText: error.response.statusText,
      service,
      endpoint: service + (error.request.path || ''),
      host: service.split('//')[1],
      payload: error.config.data,
      method: error.config.method,
      response,
      stacktrace: error.stack,
      requestHeaders: error.request.headers,
      responseHeaders: error.response.headers,
    };
  } catch (err) {
    return {
      errorMessage: `Could not extract http error details: ${err.message}`,
      error: err,
    };
  }
};
