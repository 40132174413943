import React from 'react';
import PropTypes from 'prop-types';
import './AppTopBar.scss';
import AppNameLabel from '../AppNameLabel/AppNameLabel';
import HeaderBar from '../HeaderBar/HeaderBar';

const AppTopBar = ({ children }) => (
  <div className="app-top-bar-component">
    <HeaderBar>
      <AppNameLabel />
      {children}
    </HeaderBar>
  </div>
);

AppTopBar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};

export default AppTopBar;
