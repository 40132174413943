import React from 'react';
import './PlatformMenu.scss';
import Icon from '../../Icon/Icon';
import classNames from 'classnames';

const BASE_URL = 'https://platform.amobee.com';

const MenuConfig = [
  { label: 'Dashboard', icon: 'platform-app-dashboard', url: BASE_URL + '/webapp/#/home-dashboard' },
  { label: 'Audiences', icon: 'platform-app-audiences', url: BASE_URL + '/audience/#/campaigns/last-visited' },
  { label: 'Campaigns', icon: 'platform-app-campaigns', url: BASE_URL + '/webapp/#/campaigns/last-visited' },
  { label: 'Reports', icon: 'platform-app-reports', url: BASE_URL + '/reportCenter/#/reports/last-visited' },
  { label: 'Data Queries', icon: 'platform-app-insights', url: BASE_URL + '/datamine/#/insights/last-visited' },
  { label: 'Discovery', icon: 'platform-app-brand-intelligence', url: 'https://intelligence.amobee.com' },
];

const selectedMenuItem = 'Discovery';

const PlatformMenu = () => (
  <div className={classNames('platform-menu-component')}>
    {MenuConfig.map(({ label, icon, url }) => (
      <div key={label} className={classNames('menu-item', { selected: label == selectedMenuItem })}>
        <a href={url} target="_self">
          <Icon iconId={icon} className="icon"></Icon>
          <span className="label">{label}</span>
        </a>
      </div>
    ))}
  </div>
);

export default PlatformMenu;
