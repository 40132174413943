import * as MixpanelAlerts from '../../../react/infra/mixpanel/MixpanelAlerts';

module.exports = angular.module(__filename, [
    require('data/settings/alerts-service').name
]).controller('alertsSettingsController', ['$scope', 'close', 'alertsService', 'notificator',
              'settings', 'firstTime', 'util',
    function ($scope, close, alertsInterface, notificator, settings, firstTime, util) {
        $scope.settings = settings || {emails: [$scope.$root.user.email], interval: {type: 'As it happens'}};
        $scope.firstTime = firstTime;
        $scope.emails = $scope.settings.emails.join().concat(',');
        $scope.close = close;
        $scope.save = save;

        $scope.day_of_week = (angular.isNumber($scope.settings.interval.day_of_week) ? $scope.settings.interval.day_of_week : 1).toString();
        $scope.hour_of_day = (($scope.settings.interval.hour_of_day || 12) - 3).toString();
        $scope.includeWeekends = $scope.settings.interval.include_weekends == undefined ? true : $scope.settings.interval.include_weekends;

        $scope.weekdays = moment.weekdays();
        $scope.hours = [];
        for (var i = 3; i < 24; i++) {
            var hh = (i < 10 ? '0' : '') + i;
            $scope.hours.push(hh + ':00');
        }

        $scope.intervals = [
            {text: 'Daily', first: true},
            {text: 'Weekly'},
            {text: 'As it happens', last: true}
        ];

        $scope.getButtonCss = (interval) => util.getButtonCss(interval, $scope.settings.interval.type, $scope);

        function save() {
            if (!validateEmails()) return;

            buildInterval();
            alertsInterface.editSettings($scope.settings).then(function (data) {
                if (data.failed) {
                    notificator.error({body: 'Save canceled! ' + data.error});
                } else {
                    MixpanelAlerts.trackAlertsSettings($scope.settings);
                    notificator.success({body: 'Email settings saved successfully'});
                    close({reload: true});
                }
            });
        }

        function buildInterval() {
            switch ($scope.settings.interval.type) {
                case 'Daily':
                    $scope.settings.interval.hour_of_day = getHourOfDay();
                    $scope.settings.interval.include_weekends = $scope.includeWeekends;
                    break;
                case 'Weekly':
                    $scope.settings.interval.hour_of_day = getHourOfDay();
                    $scope.settings.interval.day_of_week = parseInt($scope.day_of_week);
                    break;
                default:
                    console.log("'As it happens' option, doing nothing.")
            }
        }

        function getHourOfDay() {
            return parseInt($scope.hour_of_day) + 3;
        }

        var EMAIL_REGEX = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

        function validateEmails() {
            $scope.settings.emails = [];
            var invalidEmailFound = false;
            $scope.emails.split(",").forEach(function (email) {
                var e = email.trim();
                if (e.length == 0) return;

                if (EMAIL_REGEX.test(e)) {
                    $scope.settings.emails.push(e);
                } else {
                    invalidEmailFound = true;
                }
            });

            $scope.invalidEmailsError = invalidEmailFound;
            return !$scope.invalidEmailsError;
        }
    }
]);
