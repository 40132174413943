module.exports = angular.module(__filename, []).service('linkedinCompaniesModal', ['ModalService', function (ModalService) {
    this.showModal = showModal;

    function showModal(companies, isNewSegment, disableGeo, channel, params) {
        return ModalService.showModal({
            template: require('./linkedin-companies-modal.html'),
            inputs: { companies: companies, isNewSegment: isNewSegment, disableGeo: disableGeo, channel: channel, params: params },
            controller: ['$scope', 'context', 'close', 'companies', 'isNewSegment', 'disableGeo', 'channel', 'TermsMold', 'params', LinkedinCompaniesModalCtrl]
        });
    }

    function LinkedinCompaniesModalCtrl($scope, context, close, companies, isNewSegment, disableGeo, channel, TermsMold, params) {
        $scope.isNewSegment = companies == null;
        $scope.companies = _.extend({sizes: null, names: null}, companies);
        $scope.close = close;
        $scope.companiesData = {};
        $scope.linkedInPromise = params.linkedinMetaDataPromise.then(data => {
            $scope.companiesData.sizes = data.company_sizes;
        });
        $scope.termsMold = new TermsMold();
        $scope.userId = context.current.u_id;

        function removeEmptyFilters(companies) {
            let results = {};
            const allSizesSelected = _.isEqual(_.size(companies.sizes), _.size($scope.companiesData.sizes));

            if (!_.isEmpty(companies.sizes) && !allSizesSelected) {
                results.sizes = companies.sizes;
            }
            if (!_.isEmpty(companies.names)) {
                results.names = companies.names;
            }

            return results;
        }

        $scope.isValid = () => {
            return !_.isEmpty(removeEmptyFilters($scope.companies));
        };

        $scope.save = () => {
            close(_.extend(removeEmptyFilters($scope.companies), {type: 'linkedinCompanies', operand: $scope.companies.operand}));
        };
    }
}]);
