import React from 'react';
import BaseDropdown from '../BaseDropdown/BaseDropdown';
import './SideMenuDropdown.scss';
import PropTypes from 'prop-types';

const SideMenuDropdown = (props) => <BaseDropdown {...props} />;

SideMenuDropdown.propTypes = {
  values: PropTypes.array,
  selectedValue: PropTypes.object,
  selectedValues: PropTypes.array,
  promise: PropTypes.object,
  isMulti: PropTypes.bool,
  //isSearchable: PropTypes.bool, //disabled until tree is implemented
  isDisabled: PropTypes.bool,
  isOpen: PropTypes.bool,
  showSelectAllOptions: PropTypes.bool,
  selectAllOptionsText: PropTypes.string,
  showClearAllOptions: PropTypes.bool,
  clearAllOptionsText: PropTypes.string,
  summaryTextAllSelected: PropTypes.string,
  summaryTextBuilder: PropTypes.func,
  onSelect: PropTypes.func,
};

SideMenuDropdown.defaultProps = {
  className: 'side-menu-dropdown-component',
  shouldCloseOnClickOutside: false,
  isVirtualizeDisabled: true,
  shouldResizeMenu: false,
};

export default SideMenuDropdown;
