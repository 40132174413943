(function (__filename) {
    "use strict";
    module.exports = angular.module(__filename, [])
        .filter('formatNumber', ['$filter', function ($filter) {
            var abbr = ['', 'K', 'M', 'B'];
            var number = $filter('number');
            return function (input, dec, post) {
                if (isNaN(input) || input == null) { //isNaN(null) => false
                    return input;
                }

                var i = 0;
                while (input >= 1000 || -1000 >= input) {
                    i++;
                    input /= 1000;

                    // Will force 0 on dec if and only if dec = 0 as input, otherwise dec will be 1
                    dec = (dec == 0) ? 0 : 1
                }

                var num = number;
                if (dec == undefined) {
                    num = angular.identity;
                }
                try {
                    if(input && input.toFixed){
                        var post = post || '';
                        var dec = (input == input.toFixed(0)) ? 0 : dec;
                        //var sign = input < 0 ? '' : ' ';
                        return '' + num(input, dec) + abbr[i] + post;
                    }else{
                        return input;
                    }
                } catch (e) {
                    console.warn(e);
                    console.warn('error in formatNumber filter', input);
                }
            };
        }]
    );
}).call(this, "/src\\common\\filters\\format-number.js");
