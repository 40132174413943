import React from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { noop } from 'lodash';

class ResetableErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isChildrenHasJsError: false,
      errorBoundaryKey: 0,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.shouldResetError && prevState.isChildrenHasJsError) {
      this.setState((prevState) => ({ errorBoundaryKey: prevState.errorBoundaryKey + 1, isChildrenHasJsError: false }));
    }
  }

  onError = (errorMessage, stacktrace) => {
    this.props.onError(errorMessage, stacktrace);
    this.setState({ isChildrenHasJsError: true });
  };

  render() {
    return (
      <ErrorBoundary
        onError={this.onError}
        fallbackComponent={this.props.fallbackComponent}
        key={this.state.errorBoundaryKey}
      >
        {this.props.children}
      </ErrorBoundary>
    );
  }
}

ResetableErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
  onError: PropTypes.func,
  fallbackComponent: PropTypes.func,
  shouldResetError: PropTypes.bool,
};

ResetableErrorBoundary.defaultProps = {
  shouldResetError: false,
  onError: noop,
};

export default ResetableErrorBoundary;
