module.exports = angular.module(__filename, []).directive('loading', ['$q',
    function($q) {
        return {
            restrict: "A",
            link: function(scope, element, attr, ctrl) {
                /*init*/
                if ($(element).css('position') == 'static') {
                    element.css('position', 'relative');
                }
                var loaderDiv = angular.element("<div class='loader-overlay'></div><div class='loader'>&nbsp;</div>");
                var up = null;

                /*watch on promises*/
                scope.$watch(attr.loading, function(promise) {
                    if (promise == undefined || !promise.then || (promise.$$state || {}).status > 0) return;
                    element.append(loaderDiv);
                    promise.then(() => loaderDiv && loaderDiv.remove && loaderDiv.remove());
                });
            }
        }
    }
]);
