module.exports = angular.module(__filename, []).service('linkedinJobsModal', ['ModalService', function (ModalService) {
    this.showModal = showModal;

    function showModal(jobs, isNewSegment, disableGeo, channel, params) {
        return ModalService.showModal({
            template: require('./linkedin-jobs-modal.html'),
            inputs: { jobs: jobs, isNewSegment: isNewSegment, disableGeo: disableGeo, channel: channel, params: params },
            controller: ['$scope', 'close', 'jobs', 'isNewSegment', 'disableGeo', 'channel', 'params', LinkedinJobsModalCtrl]
        });
    }

    function LinkedinJobsModalCtrl($scope, close, jobs, isNewSegment, disableGeo, channel, params) {
        $scope.isNewSegment = jobs == null;
        $scope.jobs = _.extend({seniorities: null, functions: null, jobTitles: null}, jobs);
        $scope.close = close;
        $scope.jobsData = {};
        $scope.linkedInPromise = params.linkedinMetaDataPromise.then(data => {
            $scope.jobsData.seniorities = data.seniorities;
            $scope.jobsData.functions = data.functions;
            $scope.jobsData.jobTitles = data.titles;
        });

        const onChange = field => (input => jobFieldChange(field, input));
        const onSelect = field => (input => jobFieldSelect(field, input));

        function removeEmptyFilters(jobs) {
            let results = {};

            _.forEach(['seniorities', 'functions', 'jobTitles'], (field) => {
                if (!_.isEmpty(jobs[field])) {
                    results[field] = jobs[field];
                }
            });

            return results;
        }

        function jobFieldChange(field, input) {
            if (_.isEmpty(input)) {
                $scope.jobs[field] = [];
            }
        }

        function jobFieldSelect(field, input) {
            $scope.jobs[field] = [_.pick(input, ['label', 'value'])];
        }

        $scope.onSeniorityChange = onChange('seniorities');
        $scope.onFunctionChange = onChange('functions');
        $scope.onJobTitleChange = onChange('jobTitles');
        $scope.onSenioritySelect = onSelect('seniorities');
        $scope.onFunctionSelect = onSelect('functions');
        $scope.onJobTitleSelect = onSelect('jobTitles');

        $scope.onJobError = function(msg) {
            console.log(msg);
        };

        $scope.isValid = () => {
            return !_.isEmpty(removeEmptyFilters($scope.jobs));
        };

        $scope.save = () => {
            close(_.extend(removeEmptyFilters($scope.jobs), {type: 'linkedinJobs', operand: $scope.jobs.operand}));
        };

        $scope.autocompleteOpenDirTop = () => {
            const padding = 30;
            const modal = document.getElementsByClassName("modal")[0];
            const optionsDropdownElement = document.getElementsByClassName("autocomplete-options-dropdown")[0];
            const isOptionsOpenDirTop = (optionsDropdownElement && modal && 
                optionsDropdownElement.getBoundingClientRect().top < modal.getBoundingClientRect().top - padding);
            
            return {'autocomplete-open-top': isOptionsOpenDirTop};
        };
    }
}]);