import { compact, each, every, find, filter, isEmpty, isFunction, noop, uniqBy } from 'lodash';

export const linkParents = (item, parent) => {
  if (parent) {
    item.parent_ids = [...(parent.parent_ids || []), parent.value];
  }
  if (!isEmpty(item.children)) {
    item.children.forEach((child) => linkParents(child, item));
  }
};

// Set all child 'selected' values to match parent value
function updateAll(items, checked) {
  items.map((item) => {
    item.selected = checked;
    if (item.children && item.children.length) {
      updateAll(item.children, checked);
    }
  });
}

export function getUpdatedTree(tableData, newSelectedValue, selectedObject) {
  const updatedData = [...tableData];

  if (!selectedObject) {
    updateAll(tableData, newSelectedValue);
    return updatedData;
  }

  let currentObject = null;
  let currentItems = tableData;
  let parentObjects = [];
  //reach selected object in the tree
  const ids = [...(selectedObject.parent_ids || []), selectedObject.value];
  each(ids, (id) => {
    currentObject = find(currentItems, { value: id });
    parentObjects = compact([id !== selectedObject.value && currentObject, ...parentObjects]);
    currentItems = currentObject.children;
  });

  currentObject.selected = newSelectedValue;
  if (currentObject.children?.length) {
    updateAll(currentObject.children, newSelectedValue);
  }

  each(parentObjects, (obj) => {
    obj.selected = every(obj.children, 'selected');
  });
  return updatedData;
}

export function pickSelectedFromTree(items, dataLevels, prePopulateCB = noop, uniqByFunc = null) {
  const elements = dataLevels.reduce((elementsObj, name) => {
    elementsObj[name] = [];
    return elementsObj;
  }, {});
  function populateRecursionTableElements(dataArray, level = 0, parentName = null) {
    const parentKey = parentName ? dataLevels[level - 1].substring(0, dataLevels[level - 1].length - 1) + 'Name' : '';
    each(dataArray, (data) => {
      if (!data.selected && data?.children?.length) {
        populateRecursionTableElements(data.children, level + 1, data.label);
      }
      if (data.selected) {
        const element = {
          is_movie: data.is_movie,
          value: data.value,
          label: data.label,
          children: data.children || [],
        };
        if (parentName) {
          element[parentKey] = parentName;
        }
        const iteratee = isFunction(uniqByFunc) ? uniqByFunc : 'value';
        elements[dataLevels[level]] = uniqBy([...elements[dataLevels[level]], element], iteratee);
      }
    });
    return filter(elements, 'selected');
  }

  prePopulateCB(elements);
  populateRecursionTableElements(items);
  return elements;
}

export const valueAndNetworkNameUniqByFunc = ({ value, networkName }) => ({ value, networkName });
