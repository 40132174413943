/**
 * Created by pery on 06/07/2015.
 */
/**
 * that directive is for update ng-modle of some input by experion
 * */
module.exports = angular.module(__filename, [])
    .directive('amValue', function () {
        return {
            restrict: "A", require: 'ngModel',
            link: function (scope, element, attr, ctrl) {
                scope.$watch(attr.amValue, function (nv, ol) {
                    ctrl.$setViewValue(nv);
                    if (angular.equals(nv, ol)) {
                        ctrl.$setPristine();
                    }
                });
            }
        }
    }
);
