/**
 * Created by pery on 06/07/2015.
 * DropDown directive
 */
module.exports = function postLinkManyFromMany(scope, element, attr, ctrls, transcludeFn) {
    var ctrl = ctrls[0];
    var inputCtrl = scope.inputCtrl;

    /* option list define in am-input-collection file */
    scope.selectingValue = selectingValue;

    if (scope.returingArray == void 0)
        inputCtrl.returingArray = true;

    function selectingValue(option) {
        option.selected = !option.selected;
        var viewValue = _.filter(inputCtrl.options, 'selected', true);
        ctrl.$setViewValue(viewValue);
    }
};


