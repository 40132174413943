import mixpanel from 'mixpanel-browser';
import { getSsoLoginDataObj, setSsoLoginDataObj } from '../../middleware/AngularExportedMiddleware';

// eslint-disable-next-line no-undef
export const isDisabled = () => !IS_PRODUCTION;

export const initialize = (authInfo) => {
  if (isDisabled()) return;

  mixpanel.init('3b9c0e987bfe8dbfb18467ca8adad53b');
  const userEmail = authInfo.email;
  mixpanel.register({ user: userEmail });
  mixpanel.identify(userEmail);
  if (mixpanel.people) {
    mixpanel.people.set({
      $email: userEmail,
      Username: authInfo.name,
      Organization: authInfo.account.name,
    });
  }

  const loginData = getSsoLoginDataObj();
  const mode = loginData.mode || 'refresh';
  delete loginData.mode;
  /* eslint-disable camelcase*/
  track('Login', { mode, last_page: loginData.last_page });
  setSsoLoginDataObj(loginData);
};

export const track = (name, props) => {
  if (isDisabled()) return;
  mixpanel.track(name, props);
};
