import React, { useRef, useEffect } from 'react';
import { bool, func, string } from 'prop-types';
import { noop } from 'lodash';
import classNames from 'classnames';
import './TextBox.scss';

import Icon from '../Icon/Icon';

// Value in pixels for custom placement of asterisk depending on text.
// To avoid usage of 'canvas' measurement approach and redundant bindings we should provide custom method
const calcAsteriskLeft = (value) => {
  const avgCharWidthPx = 12;
  return value.length * avgCharWidthPx;
};

const TextBox = ({
  iconId,
  value,
  placeholder,
  isHoverEnabled,
  isDisabled,
  shouldDisplayErrorMessages,
  showAsterisk,
  errorMessage,
  isDarkTheme,
  onChange,
  isFocused,
  onFocus,
  onBlur,
  onEnter,
}) => {
  const textRef = useRef(null);

  useEffect(() => {
    if (isFocused) {
      textRef.current.focus();
    }
  }, [isFocused]);

  return (
    <div
      className={classNames(
        'text-box-component',
        {
          disabled: isDisabled,
          'hover-enabled': !isDisabled && isHoverEnabled,
          invalid: shouldDisplayErrorMessages && errorMessage,
        },
        isDarkTheme ? 'dark' : 'light'
      )}
    >
      <div className="input-container">
        <input
          type="text"
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={(event) => (event.key === 'Enter' || event.keyCode === 13) && onEnter(event)}
          disabled={isDisabled}
          readOnly={isDisabled}
          ref={textRef}
        />
        {iconId && <Icon className="input-icon" iconId={iconId}></Icon>}
        {showAsterisk && value && (
          <i className={classNames('icon-asterisk', 'fade-in')} style={{ left: calcAsteriskLeft(value) }}></i>
        )}
      </div>
      {shouldDisplayErrorMessages && errorMessage && (
        <div className="error-message" role="alert">
          {errorMessage}
        </div>
      )}
    </div>
  );
};

TextBox.propTypes = {
  iconId: string,
  value: string.isRequired,
  placeholder: string,
  isHoverEnabled: bool,
  isDisabled: bool,
  shouldDisplayErrorMessages: bool,
  errorMessage: string,
  isDarkTheme: bool.isRequired,
  showAsterisk: bool,
  onChange: func.isRequired,
  isFocused: bool,
  onFocus: func,
  onBlur: func,
  onEnter: func,
};

TextBox.defaultProps = {
  isHoverEnabled: true,
  isDisabled: false,
  shouldDisplayErrorMessages: false,
  errorMessage: null,
  isDarkTheme: true,
  showAsterisk: false,
  onEnter: noop,
};

export default TextBox;
