var d3 = require("d3"),
    c = require("infra/utils/common"),
    TopBar = require("./top-bar"),
    TopBarGroup = require("./top-bar-group");

var LEFT_HEADER_ICON = "/images/images/male.svg",
    RIGHT_HEADER_ICON = "/images/images/female.svg",
    ICON_SIZE = 25;

TopChart.BARS_DEFAULT_WIDTH = 650;
TopChart.BARS_LEFT_PAD = 105; // The scroll width is 16 px + value label + additional padding

function TopChart(type, remove, chart, configuration) {

  var self = this;
  this.configuration = configuration || {};
  c.cleanElement(chart, true);
  this.container = chart;
  this.calcWidth(type);
  this.svg = d3.select(chart);
  this.svg.classed("split-bar-chart", true);
  this.remove = remove;
  this.data = [];
  this.height = 0;
  this.type = type;
  this.sub_topic_backgrounds = [];

  this.appendGenderIcons = function(type) {
    c.cleanElement(chart + '-header', 'gender' !== type);
    this.header = d3.select(chart + '-header')
                    .attr("height", 0)
                    .style("visibility", false)
                    .style("display", "none");
    if ('gender' == type) {
      var lhp = TopBarGroup.BARS_LEFT_POS + self.barWidth - ICON_SIZE - TopBar.DIVIDER_OFFSET - TopBarGroup.SPLIT_BAR_DIVIDER_OFFSET;
      self.header.attr("height", ICON_SIZE + TopBar.DIVIDER);
      self.header.append("image").attr("xlink:href", LEFT_HEADER_ICON)
          .attr("class", "split-icon")
          .attr("x", lhp)
          .attr("y", 0)
          .attr("width", ICON_SIZE)
          .attr("height", ICON_SIZE);
      self.header.append("image").attr("xlink:href", RIGHT_HEADER_ICON)
          .attr("class", "split-icon")
          .attr("x", lhp + ICON_SIZE + TopBar.DIVIDER)
          .attr("y", 0)
          .attr("width", ICON_SIZE)
          .attr("height", ICON_SIZE);
    }
  };

  this.appendGenderIcons(type);
  this.split_bar_clusters = {};
  this.split_bar_groups = [];

  this.isTree = function() {
    return _.some(this.data, 'is_parent');
  };

  this.initVisibility = function() {
    if(!this.isTree()){
      return;
    }
    this.sub_topic_backgrounds = [];
    _.each(this.data, function(record){
      if(record.is_parent){
        record.open = false;
        self.sub_topic_backgrounds.push({id: record.id, visible: false, offset: 0, height: 0});
      } else {
        record.hidden = true;
      }
    });
  };

  this.updateOffsetHeights = function() {
    let current_offset_height = 0;
    let current_background = {};
    let current_parent_id;
    _.each(self.data, function(record) {
      record.offset_height = current_offset_height;
      if(!record.hidden){
        const group_size = self.measure === 'sov' ? 1 : (record.values || []).length;
        current_offset_height += TopBarGroup.height(group_size);
        if (record.is_parent) {
          current_background = _.find(self.sub_topic_backgrounds, ['id', record.id]);
          if (current_background) {
            current_background.visible = record.open;
            current_background.offset = current_offset_height;
          }
          current_parent_id = record.id;
        } else if (current_parent_id && current_background) {
          current_background.height = current_offset_height - current_background.offset;
        }
      }
    });
    self.height = current_offset_height + (self.configuration.groupYLocation || 0);
    self.svg.attr("height", this.height);
  };

  this.onBarGroupExpand = function(clickedId, isOpen){
    self.clickedId = clickedId;
    self.clickedToExpand = isOpen;
    var isProcessingId = false;
    _.each(self.data, function(record){
      if(!isProcessingId && record.id == clickedId){
        record.open = isOpen;
        isProcessingId = true;
        return;
      }
      if (isProcessingId){
        if(record.is_parent){
          isProcessingId = false;
          return;
        }
        record.hidden = !isOpen;
      }
    });

    self.redraw();
  };

  this.isSon = (d) => self.isTree() && !d.is_parent;

  this.getTransformValue = (d) => "translate(" + ((self.isSon(d) ? 25 : 0) + (self.configuration.barGroupXOffset || 0)) +
                                  ", " + (d.offset_height || 0) + ")";

  this.getMaxValue = function(data, measure, visibleOnly){
    if(visibleOnly){
      data = _.reject(data, 'hidden');
    }
    var maxRecord = _.maxBy(data,function(d){return (_.maxBy(d.values,measure) || {})[measure]});
    if(typeof maxRecord == 'object') {
      return _.max(_.map(maxRecord.values || [], measure));
    }
    return null;
  };

  this.getMaxVisibleValue = function(data, measure){
    return this.getMaxValue(data, measure, true);
  };
}

TopChart.prototype.clear = function() {
  _.each(this.split_bar_clusters, function(cluster) {
    if(cluster) cluster.destroy();
  });
  this.svg.selectAll("*").remove();
  this.split_bar_clusters = {};
  this.split_bar_groups = [];
};

TopChart.prototype.redraw = function() {
  var new_max_visible = this.getMaxVisibleValue(this.data, this.measure);
  var self = this;
  this.x.domain([0, c.getNumber(new_max_visible, 100)]);
  this.updateOffsetHeights();
  this.split_bar_groups.each(function(d) {
    var cluster = self.split_bar_clusters[d.id];
    if(d.hidden){
      if(cluster) cluster.destroy();
      self.split_bar_clusters[d.id] = null;
    } else if(!cluster) {
      cluster = new TopBarGroup(this, self);
      cluster.setData(d, self.type, self.measure);
      self.split_bar_clusters[d.id] = cluster;
    } else if(new_max_visible != self.maxVisible) {
      cluster.setDomain(self.x);
      cluster.setData(d, self.type, self.measure);
    }
    d3.select(this).moveToFront();
  }).transition()
    .delay((d) => d.parent_id == self.clickedId && self.clickedToExpand ? TopBar.TRANSITION_DURATION / 4 : 0)
    .attr("class", (d) => "split-bar-cluster" + (d.open ? " open" : "") + (self.isSon(d) ? " son-bar-cluster" : ""));

  this.split_bar_groups.transition()
      .duration((d) =>  d.parent_id == self.clickedId && self.clickedToExpand ? 0 : TopBar.TRANSITION_DURATION / 2)
      .attr("transform", self.getTransformValue);

  this.background_elements.transition()
      .duration(TopBar.TRANSITION_DURATION / 2)
      .attr("class", (d) => "sub-topic-background" + (d.visible ? " visible" : ""))
      .attr("y", (d) => d.offset)
      .attr("height", (d) =>  d.height);

  this.maxVisible = new_max_visible;
};

TopChart.prototype.setData = function(data, type, measure, max) {
  this.type = type;
  this.measure = measure;
  if (c.isArray(data)) {
    this.max = max || this.getMaxValue(data, measure);
    this.maxVisible = this.getMaxVisibleValue(data, measure);
    this.data = data;
    if (!_.has(this.data[0], 'open')) this.initVisibility();
    this.updateOffsetHeights();
    if (c.is(this.header)) {
      if ('gender' == type) {
        this.header.style("visibility", true);
        this.header.style("display", "block");
      } else {
        this.header.style("visibility", false);
        this.header.style("display", "none");
      }
    }
    var self = this;
    this.x.domain([0, c.getNumber(this.maxVisible, 100)]);

    if(!_.isEmpty(this.sub_topic_backgrounds)) {

      this.background_elements = this.svg.selectAll(".sub-topic-background")
        .data(_.values(this.sub_topic_backgrounds), (d) => d.id);

      this.background_elements.enter()
        .append("rect")
        .attr("class", (d) => "sub-topic-background" + (d.visible ? " visible" : ""))
        .attr("id", (d) =>  "sub-topic-background-" + d.id)
        .attr("x", 0)
        .attr("y", (d) => d.offset)
        .attr("height", (d) => d.height)
        .attr("width", "100%");

      this.background_elements.exit()
        .transition()
        .duration(TopBar.TRANSITION_DURATION / 2)
        .remove();
    }

    this.split_bar_groups = this.svg.selectAll(".split-bar-cluster").data(this.data, (d) => d.id);

    this.split_bar_groups.each(function(d) {
      var cluster = self.split_bar_clusters[d.id];
      if(d.hidden){
        if(cluster) cluster.destroy();
        self.split_bar_clusters[d.id] = null;
      } else if(!cluster) {
        cluster = new TopBarGroup(this, self);
        cluster.setData(d, self.type, self.measure);
        self.split_bar_clusters[d.id] = cluster;
      }
    }).transition()
      .duration(TopBar.TRANSITION_DURATION)
      .attr("transform", self.getTransformValue);

    this.split_bar_groups.enter()
      .append("g").attr("class", (d) => "split-bar-cluster" + (self.isSon(d) ? " son-bar-cluster" : ""))
        .attr("transform", self.getTransformValue)
        .each(function(d) {
          d3.select(this).moveToFront();
          var cluster = null;
          if(!d.hidden) {
            cluster = new TopBarGroup(this, self);
            cluster.setData(d, type, measure);
          }
          return self.split_bar_clusters[d.id] = cluster;
        });

    this.split_bar_groups.exit().each(function(d) {
      if (self.split_bar_clusters[d.id]) {
        self.split_bar_clusters[d.id].destroy();
        return delete self.split_bar_clusters[d.id];
      }
    }).transition()
      .duration(TopBar.TRANSITION_DURATION / 2)
      .attr("transform", function(d) {
        return "translate(0, 10000)";
      }).remove();
  } else {
    this.data = [];
    _.each(this.split_bar_clusters, function(cluster) {if(cluster) cluster.destroy()});
    this.svg.selectAll("*").remove();
    this.svg.classed("empty", this.data.length === 0);
  }
};

TopChart.prototype.draw = function(data, type, measure, max) {
  this.clear();
  this.calcWidth(type);
  this.appendGenderIcons(type);
  this.setData(data, type, measure, max);
};

TopChart.prototype.calcWidth = function(type) {
  var ew = c.getElementWidth(this.container);
  if (ew > 0) {
    this.barWidth = ew - TopBarGroup.BARS_LEFT_POS - TopBarGroup.LABEL_LEFT_MARGIN - TopChart.BARS_LEFT_PAD;
  } else {
    this.barWidth = TopChart.BARS_DEFAULT_WIDTH;
  }
  if ('gender' == type) this.barWidth = this.barWidth / 2;
  this.x = d3.scale.linear().range([0, this.barWidth]);
};

TopChart.prototype.destroy = function() {
  _.each(this.split_bar_clusters, function(cluster) {if(cluster) cluster.destroy()});
};

TopChart.prototype.addIndexLine = function(max, avg, text) {
  const width = Object.values(this.split_bar_clusters)[0].barWidth;
  const avgLocation = 295 + (max === 0 ? 0 : width * (avg / max));
  this.svg.insert("line", ":first-child")
          .attr("class", "indexline-line")
          .attr("y1", 20)
          .attr("x1", avgLocation)
          .attr("x2", avgLocation)
          .attr("y2", 20)
          .transition()
          .duration(TopBar.TRANSITION_DURATION)
          .attr("y2", this.height - 15)
          .style("stroke-width", 1)
          .style("stroke", "#4a4e51")
          .style("fill", "none");

  this.svg.insert("text")
          .attr("y", 10)
          .attr("x", avgLocation)
          .attr("class", "indexline-text")
          .attr("fill-opacity", 0 )
          .style("fill", "#4a4e51")
          .style("text-anchor", "middle")
          .style("font-size", "13px")
          .transition().delay( TopBar.TRANSITION_DURATION / 2)
          .attr( "fill-opacity", 1 )
          .text(text)
};

module.exports = TopChart;
