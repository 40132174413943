import React from 'react';
import PropTypes from 'prop-types';
import SingleSelectToggle from '../SingleSelectToggle/SingleSelectToggle';
import DateRangePicker from '../DateRangePicker/DateRangePicker';
import './DateRangePickerWithSingleSelectToggle.scss';

const DateRangePickerWithSingleSelectToggle = ({
  isLightTheme,
  startDate,
  endDate,
  minDate,
  maxDate,
  onStartDaySelected,
  onEndDaySelected,
  selectToggleValues,
  selectedToggleValue,
  onToggleSelect,
  isDisabled,
  isAudienceInline,
}) => (
  <div className="date-range-picker-with-single-select-toggle-component">
    <span className="timeframe-wrapper">
      <SingleSelectToggle
        values={selectToggleValues}
        selectedValue={selectedToggleValue}
        onToggle={onToggleSelect}
        isLightTheme={isLightTheme}
        isAudienceInline={isAudienceInline}
        disabled={isDisabled}
      ></SingleSelectToggle>
    </span>
    <DateRangePicker
      isHighlighted={!selectedToggleValue}
      isLightTheme={isLightTheme}
      startDate={startDate}
      endDate={endDate}
      minDate={minDate}
      maxDate={maxDate}
      onStartDaySelected={onStartDaySelected}
      onEndDaySelected={onEndDaySelected}
      isDisabled={isDisabled}
    ></DateRangePicker>
  </div>
);

DateRangePickerWithSingleSelectToggle.propTypes = {
  isLightTheme: PropTypes.bool,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  onStartDaySelected: PropTypes.func.isRequired,
  onEndDaySelected: PropTypes.func.isRequired,
  selectToggleValues: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedToggleValue: PropTypes.string,
  onToggleSelect: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  isAudienceInline: PropTypes.bool,
};

DateRangePickerWithSingleSelectToggle.defaultProps = {
  isLightTheme: false,
  isDisabled: false,
  isAudienceInline: false,
};

export default DateRangePickerWithSingleSelectToggle;
