class AlertDspComponent {
  constructor($element, $timeout, dspService) {
    this.$element = $element;
    this.$timeout = $timeout;
    this.dspService = dspService;
    this.onSelectionError = this.onSelectionError.bind(this);
    this.onMarketSelect = this.onMarketSelect.bind(this);

    this.onAdvertiserSelect = this.onAdvertiserSelect.bind(this);
    this.onInsertionSelect = this.onInsertionSelect.bind(this);
    this.onPackageSelect = this.onPackageSelect.bind(this);
  }

  $onInit() {
    const onChange = (field) => ((term) =>
      this.onDspfieldChange(term, field)
    );

    this.onMarketChange = onChange('market');
    this.onAdvertiserChange = onChange('advertiser');
    this.onInsertionChange = onChange('insertion');
    this.onPackageChange = onChange('package');
    this.onIineitemChange = onChange('lineitem');
    this.toClear = {};
  }

  $onChanges(change) {
    if (change.alert && change.alert.currentValue && !change.alert.previousValue) {
      this.markets = [];
      this.advertisers = [];
      this.insertionOrders = [];
      this.packages = [];
      this.alert.session_id = this.dspService.getSessionId();
      this._populateDspFeilds();
    }
  }

  onMarketSelect(market) {
    this.onDspfieldChange(null, 'advertiser');
    this.alert.dsp_market = market;
    this.advertisers = this._populateAdvertisers();
    this.formRef.advertiserInput.$setValidity('hasAdvertiser', false)
    this._setFocusOnNext('advertiser');
    this.$timeout(()=> {
      this.$element.find('.advertiser-input input')[0].focus();
    }, 100)
  }

  onAdvertiserSelect(advertiser) {
    this.onDspfieldChange(null, 'insertion');
    this.alert.dsp_advertiser = advertiser;
    this.insertionOrders = this._populateInsertions();
    this.formRef.advertiserInput.$setValidity('hasAdvertiser', true)
    this.formRef.insertionInput.$setValidity('hasInsertion', false)
    this._setFocusOnNext('insertion');
  }

  onInsertionSelect(insertion) {
    this.onDspfieldChange(null, 'package');
    this.formRef.insertionInput.$setValidity('hasInsertion', true)
    this.alert.dsp_insertion_order = insertion;
    this.packages = this._populatePackages();
    this.formRef.packageInput.$setValidity('hasPackage', false)
    this._setFocusOnNext('package');
  }

  onPackageSelect(pack) {
    this.onDspfieldChange(null, 'line-items');
    this.formRef.packageInput.$setValidity('hasPackage', true)
    this.alert.dsp_package = pack;
    this._setFocusOnNext('line-items');
  }

  onSelectionError() {
    console.log(arguments);
    console.log("input error");
  }

  onDspfieldChange(term, field) {

    if (_.isEmpty(term)) {
      this.toClear = {};
      switch (field) {
        // Intentional fallthrough!!!
        case 'market':
          this.alert.dsp_market = null;
          this.toClear.market = true;
        case 'advertiser':
          this.alert.dsp_advertiser = null;
          this.toClear.advertiser = true;
        case 'insertion':
          this.alert.dsp_insertion_order = null;
          this.toClear.insertion = true;
        case 'package':
          this.alert.dsp_package = null;
          this.toClear.package = true;
        case 'lineitem':
          this.alert.dsp_line_items = null;
          this.alert.lineItems = null
          this.toClear.lineItems = true;
      }
    }
  }

  _populateDspFeilds() {
    const promise = this._populateMarkets().then(markets => this.markets = markets)
    if (this.alert.dsp_market) {
      Object.assign(this.alert.dsp_market, {market: `${this.alert.dsp_market.id} - ${this.alert.dsp_market.name}`})
      Object.assign(this.alert.dsp_advertiser, {advertiser: `${this.alert.dsp_advertiser.id} - ${this.alert.dsp_advertiser.name}`})
      Object.assign(this.alert.dsp_insertion_order, {insertion: `${this.alert.dsp_insertion_order.id} - ${this.alert.dsp_insertion_order.name}`})
      Object.assign(this.alert.dsp_package, {package: `${this.alert.dsp_package.id} - ${this.alert.dsp_package.name}`})
      this.alert.lineItems = this.alert.dsp_line_items.join().concat(',')
        promise.then(() => {
          this._populateAdvertisers();
          this._populateInsertions();
          this._populatePackages();
        });
    }
  }

  _setFocusOnNext(next) {
    const selector = next == 'line-items' ? `.${next}-input` : `.${next}-input input`;
    this.$timeout(()=>
      this.$element.find(selector)[0].focus()
    , 100);
  }

  _populateMarkets() {
    return this.dspService.getMarkets().then(markets => this.markets = markets)
  }

  _populateAdvertisers() {
    return this.dspService.listAdvertisers(this.alert.dsp_market.id).then(advertisers =>
      this.advertisers = advertisers
    )
  }

  _populateInsertions() {
    return this.dspService.listInsertionOrders(this.alert.dsp_market.id, this.alert.dsp_advertiser.id).then(insertions =>
      this.insertionOrders = insertions
    )
  }

  _populatePackages() {
    return this.dspService.listPackages(this.alert.dsp_market.id, this.alert.dsp_insertion_order.id).then(packages =>
      this.packages = packages
    )
  }

}

module.exports = angular.module(__filename, [
]).component("alertDspComponent", {
    bindings: {
        alert: '<',
        formRef: '<',
        errors: '<'
    },
    template: require('./alert-dsp-component.html'),
    controller: ['$element', '$timeout', 'dspService', AlertDspComponent]
  });
