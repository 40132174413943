ViewSwitcherController.$inject = ['$scope', '$state', '$rootScope', 'abiPermissions', 'tabsService'];

function ViewSwitcherController($scope, $state, $rootScope, abiPermissions, tabsService) {
  $scope.$state = $state;
  this.$state = $state;

  const init = () => $scope.tabs = tabsService.getTabsByApp($scope.app);

  $scope.tabClicked = (tab) => $state.go($scope.app + '.' + tab.name);

  $scope.isSelected = (tab) => $state.current.name.includes("grid") && tab.name.includes("grid") ||
                               $state.includes($scope.app + '.' + tab.name);

  $scope.moreThanOneButton = function() {
    let activeButtons = _.filter($scope.tabs, (s) => abiPermissions.hasPermission(s.permission) && !s.hideTab);
    return activeButtons.length > 1;
  };

  $scope.$watch(() => tabsService.getWatchAttributesByApp($scope.app), init);
}

module.exports = angular.module(__filename, []).directive('amViewSwitcher', [function() {
  return {
    restrict:'E',
    template:require('./view-switcher.html'),
    scope: {
      app: '='
    },
    controller: ViewSwitcherController
  }
}]

).service('tabsService', ['context', 'abiPermissions', 'APP_NAME', function(context, abiPermissions, APP_NAME){

    return {getTabsByApp, getWatchAttributesByApp};

    function getTabsByApp(app) {
      switch(app){
        case APP_NAME.INTERESTS:
          return getInterestsTabs();
        case APP_NAME.INSIGHTS:
          return getInsightsTabs();
        default:
          return [];
      }
    }

    function getWatchAttributesByApp(app) {
      switch(app) {
        case APP_NAME.INTERESTS:
          return context.current.gridChannels?.value;
        case APP_NAME.INSIGHTS:
          return context.current.insightsChannels?.value;
        default:
          return app;
      }
    }

    function getInterestsTabs() {
      return [
        {
          name: 'bubbles',
          display: 'Trending',
          permission: 'trending'
        },
        {
          name: 'board',
          display: 'Content Board',
          permission: 'content board'
        },
        {
          name: 'grid.phrases.web',
          display: 'Channel Insights',
          permission: 'channel insights',
          hideTab: context.current.gridChannels.value === 'facebook'
        },
        {
          name: 'grid.interests.facebook',
          display: 'Channel Insights',
          permission: 'channel insights',
          hideTab: context.current.gridChannels.value !== 'facebook'
        }
      ];
    }

    function getInsightsTabs() {
      return [
        {
          name: 'seasonality',
          display: 'Seasonality',
          permission: 'seasonality',
          hideTab: !abiPermissions.hasSomePermissions(['web channel', 'video channel', 'facebook channel',
                                                       'sg telco channel', 'au telco channel'])
        },
        {
          name: 'association',
          display: 'Associations',
          permission: 'associations',
          hideTab: !abiPermissions.hasSomePermissions(['web channel', 'sg telco channel', 'au telco channel'])
        },
        {
          name: 'sentiment',
          display: 'Sentiment',
          permission: 'sentiment',
          hideTab: !abiPermissions.hasSomePermissions(['web channel', 'video channel', 'facebook channel',
                                                       'sg telco channel', 'au telco channel'])
        },
        {
          name: 'timing',
          display: 'Timing',
          permission: 'timing',
          hideTab: !abiPermissions.hasSomePermissions(['web channel', 'sg telco channel'])
        },
        {
          name: 'landscape',
          display: 'Landscape',
          permission: 'landscape',
          hideTab: !abiPermissions.hasSomePermissions(['web channel', 'facebook channel', 'sg telco channel'])
        },
        {
          name: 'topic',
          display: 'Topics',
          permission: 'topics',
          hideTab: !abiPermissions.hasSomePermissions(['web channel', 'video channel', 'sg telco channel',
                                                       'au telco channel'])
        },
        {
          name: 'channels',
          display: 'Channels',
          permission: 'channels',
          hideTab: !abiPermissions.hasSomePermissions(['web channel', 'sg telco channel', 'au telco channel'])
        },
        {
          name: 'regions',
          display: 'Regions',
          displayForChannels: ['articles', 'sg_telco', 'sg_bidstream', 'bidstream', 'au_telco'],
          permission: 'regions',
          rootScopeField: 'regionsInsightsChannelsFilter',
          hideTab: !abiPermissions.hasSomePermissions(['geos.US', 'sg telco channel', 'au telco channel']) }
      ];
    }
  }]);
