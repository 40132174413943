module.exports = angular.module(__filename, []).directive("amSwitchButton", ["$timeout", function ($timeout) {
    return {
        restrict: 'E',
        scope: {
            label: '@',
            iconClass: '@',
            value: '=',
            indeterminate: '=?',
            onClick: '&',
            disabled: '='
        },
        template: require('./am-switch-button.html'),
        link: function ($scope, $element, $attr) {
            var onClick = $scope.onClick() ? $scope.onClick() : angular.noop;
            if (!$attr.value) $scope.value = false;
            if ($scope.indeterminate === undefined) $scope.indeterminate = false;

            $scope.click = function () {
                $scope.value = !$scope.value;
                $scope.indeterminate = false;
                $timeout(function () {
                    onClick($scope.value);
                }, 0);
            }
        }
    }
}]);
