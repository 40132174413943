import config from 'infra/config';

const CHANNELS_API = config.SEARCH_API + '/consumption_channel';
const CHANNELS_NAME_MAPPING = {
  "articles/sim-web": "Web - Desktop",
  "articles/sim-web-mobile": "Web - Mobile",
  "videos": "Video"
};

module.exports = angular.module(__filename, [])
    .service("consumptionChannelService", ['baseInsightsService', function(baseInsightsService) {
        let latestData;

        return {
            get: getConsumptionChannel,
            getLatestData: getLatestData
        };

        function getConsumptionChannel(params) {
            let termMap = {};
            const requestParams = baseInsightsService.buildParams(termMap, params, false, true);
            if (_.isEmpty(termMap)) return Promise.reject("No Terms");

            return baseInsightsService.postAsyncData(CHANNELS_API, requestParams)
              .then(function(response) {
                return buildResults(response.data, termMap);
              }).then(function(data){
                const emptyTrends = baseInsightsService.getEmptyTrends(data, params.trends);
                baseInsightsService.notifyInsufficient(emptyTrends);
                return emptyTrends.length === params.trends.length ? Promise.reject(data) : data;
              }).catch(baseInsightsService.handleError);
        }

        function buildResults(results, termsMaps) {
          let finalResults = [];
          _.each(results['all/channels'],  function(channel) {
            let max = 0;
            let resultObject = {text: channel, values: []};

            _.each(termsMaps, function (termObject) {
              const termValue = results[termObject.text][channel] * 100;
              max = Math.max(max, termValue);
              resultObject.values.push(Object.assign({value: termValue}, termObject));
            });

            resultObject.max = max;
            finalResults.push(resultObject);
          });

          mapChannelNames(finalResults);
          latestData = finalResults;
          return finalResults;
        }

        function mapChannelNames(res) {
          _.mapValues(res, function(channel) {
            channel.text = CHANNELS_NAME_MAPPING[channel.text] || channel.text;
            return channel;
          });
        }

        function getLatestData() {
          return latestData;
        }
    }]);
