"use strict";

import { getPhrasesIndexInLifestyle } from '../react/services/AudienceInsightsService';
import config from 'infra/config';
import common from 'infra/utils/common';
var date = require("infra/utils/date");
var conceptsService = require("./concepts-service");

module.exports = angular.module(__filename, [
    conceptsService.name,
]).service("Interests", ["$http", "$rootScope", "cancellableHttp", "conceptsService", "$q", '$state', 'context', 'geoService', 'util', 'topicsTree', 'abiPermissions', 'CHANNEL',
    function ($http, $rootScope, cancellableHttp, conceptsService, $q, $state, context, geoService, util, topicsTree, permissions , CHANNEL) {
        let cache = {};
        let serviceConfig = {
            timeframe: null,
            terms: null,
            topics: null,
            isKeyTrends: null,
            program: null,
            bubblesChannels: {},
            sensitiveContent: false,
            geos: null,
            sub_geos: null,
            audience: null,
            isRisingOnly: null,
            cacheBaster: null
        };
        let excludeSeedPromise = null;
        /******* API ****************/
        return Object.defineProperties({
            getBubbles: getBubbles,
            getConceptsList: getConceptsList,
            removeExcludedConceptFromBubblesCache: removeExcludedConceptFromBubblesCache,
        }, {
            'config': {
                get: function () {
                    return serviceConfig;
                },
                set: function (newConfig) {
                    return _.extend(serviceConfig, newConfig);
                }
            },
            'lastUsedConfig': {
                get: function () {
                    return getBubbles.lastRequstPromise.config || {};
                }
            }
        });
        /******* END API ***********/

        /* ------------------------------------- */
        function getBubbles(instanceConfig) {
            const config = _.extend({}, serviceConfig, instanceConfig);
            let lastRequest = getBubbles.lastRequstPromise || null;
            let termsText = util.getTermsText(config.terms);
            const deferred = $q.defer();

            excludeSeedPromise = conceptsService.list_for_seeds(config.program.id, termsText, config.isKeyTrends);
            excludeSeedPromise.then(function (excludedSeeds) {
                const breakableList = ['timeframe', 'terms', 'topics', 'program', 'geos', 'sub_geos', 'audience',
                                       'bubblesChannels', 'isKeyTrends', 'cacheBaster', 'language',
                                       'user_first_party_audience'];
                config.excludedSeeds = excludedSeeds;
                config.send_first_party_audience = config.bubblesChannels.value == 'articles' && permissions.hasPermission('first party segments');
                config.user_first_party_audience = _.map(config.user_first_party_audience, "value");
                let unSupported = false, paramsDifferent = false, newTerms = false;

                if (lastRequest) {
                    const lastConfig = lastRequest.config;
                    paramsDifferent = !angular.equals(_.pick(lastConfig, breakableList), _.pick(config, breakableList));
                    newTerms = !!_.difference(config.terms, lastConfig.terms).length;
                    const socialRegEx = /^#|^@/;

                    const termDifference = _.difference(config.terms, lastConfig.terms);
                    const isChannelSupported = config.bubblesChannels.label === 'Facebook';
                    if (!isChannelSupported && newTerms) {
                        if (termDifference.filter((x) => socialRegEx.test(x.text)).length == termDifference.length) {
                            unSupported = true;
                        }
                    }
                }

                if ((!lastRequest || paramsDifferent || newTerms) && !unSupported) {
                    lastRequest = fireNewRequest(config);
                    lastRequest.config = config;
                    /** save last request **/
                    getBubbles.lastRequstPromise = lastRequest;
                }
                return lastRequest.then(function () {
                    if (config.lifestyle) {
                        let phrases = _.uniq(_.flatten(_.values(_.pick(cache.bubbles, termsText)).map((seed) => Object.keys(seed['concepts']))));
                        if (config.isKeyTrends) {
                            phrases = phrases.concat(_.map(_.flatten(_.values(cache.bubbles['trending'])), 'id'));
                        }
                        return getPhrasesIndexInLifestyle(config.lifestyle && config.lifestyle.segment, phrases, _.map(config.terms, 'id'))
                    } else {
                        return Promise.resolve(null)
                    }
                }).then(function (audience) {
                    /** return just the terms that requested in the arguments **/
                    if (config.isKeyTrends) {
                        termsText.push('trending');
                    }

                    let data = _.pick(cache.bubbles, termsText);
                    data = config.isRisingOnly ? risingFilter(data) : data;

                    if (config.velocity) {
                        data = config.velocity ? velocityFilter(data, config.velocity.min, config.velocity.max) : data;
                    }

                    if (audience) {
                        let concepts = _.flatten(_.values(data).map((seed) => _.values(seed['concepts'])));
                        for (let i = 0; i < concepts.length; i++) {
                            concepts[i]['audience'] = audience[concepts[i]['id']] ? audience[concepts[i]['id']] : 0;
                        }

                    }
                    deferred.resolve({bubbles: data, topicsDistribution: cache.topicsDistribution});
                });
            });
            return {
                promise: deferred.promise,
                cancel: function () {
                    deferred.reject('cancelled by user');
                }
            }
        }

        function risingFilter(data) {
            let res = {};
            for (var keyName in data) {
                res[keyName] = {
                    concepts: _.filter(data[keyName].concepts, (concept) => concept.score > 0 || concept.isSeed)
                };
            }
            return res;
        }

        function velocityFilter(data, min_value, max_value) {
            let res = {};
            for (var keyName in data) {
                res[keyName] = {
                    concepts: _.filter(data[keyName].concepts, (concept) => (isNotValidValue(min_value) || concept.score >= min_value) &&
                                                                            (isNotValidValue(max_value) || concept.score <= max_value))
                };
            }
            return res;
        }

        function isNotValidValue(val) {
            return (!val && val != 0) || isNaN(val) || val === '';
        }

        function tweakTelcoData(config, timeframe) {
            const channel = config.bubblesChannels.value || '';
            if (!channel.includes("telco")) return config, timeframe;

            timeframe = date.getTimeframe(config.timeframe, false);
            if (channel == "au_telco" || (["sg_telco", "sg_bidstream", "bidstream"].includes(channel) && !permissions.hasPermission('sg telco audience filters'))) {
                config.audience = [];
            }

            return config, timeframe;
        }

        function fireNewRequest(requestConfig) {
            let timeframe = date.getTimeframe(requestConfig.timeframe);
            requestConfig, timeframe = tweakTelcoData(requestConfig, timeframe);
            let sources = [];
            if (requestConfig.isCustom){
                const allFbSources = _.filter(requestConfig.program.program_sources, (source) => source.sources[0].type == 'facebook');
                const programSources =  requestConfig.bubblesChannels.length > 0 ? _.intersectionBy(allFbSources, requestConfig.bubblesChannels, 'id') : allFbSources;
                sources = _.flatMap(programSources, 'sources');
            }

            let params = {
                trending_topics: requestConfig.program.trending_topics,
                audience_mappings: requestConfig.program.program_audience_mappings,
                topics: topicsTree.getIds(requestConfig.topics),
                sensitive_content: requestConfig.sensitiveContent,
                clustering: permissions.hasPermission('story clustering'),
                geos: geoService.geosForChannel(requestConfig.geos, $state, context, 'id'),
                sub_geos: context.current.interestsAllSubGeosSelected ? [] : geoService.createRequestSubGeoParam(requestConfig.sub_geos),
                audience: util.removeFullySelectedAudienceSegments(_.map(requestConfig.audience, 'value')),
                timeframe_start: timeframe.start,
                timeframe_end: timeframe.end,
                language: context.current._language_mold.getLanguage($state, context).value,
                excluded: requestConfig.excludedSeeds,
                key_trends: (requestConfig.isKeyTrends) ? 1 : 0,
                phrases: util.getTerms(requestConfig.terms, false),
                phrases_bl: util.getTerms(requestConfig.terms, true),
                source: requestConfig.bubblesChannels.value,
                program_sources: _.mapValues(_.groupBy(sources, 'channel'), (val) => _.map(val, 'id'))
            };

            if(requestConfig.send_first_party_audience) {
                params.user_first_party_audience = requestConfig.user_first_party_audience;
            }

            const url = `${config.INTERESTS_API}/bubbles`;
            return cancellableHttp.$http({data: params, url, method: 'POST'}).cancellableThen(function (res) {
                addMetadata(res.data.bubbles, params, requestConfig);
                res.data.topicsDistribution = util.getTopicsDistribution(res.data.topics);
                _.extend(cache, res.data);
                return res.data;
            }, function (err) {
            });
        }

        function addMetadata(data, params, config) {
            for (const keyName in data) {
                const term = _.find(config.terms, {text: keyName});
                _.forEach(data[keyName].concepts, function (concept) {
                    concept['seed'] = keyName;
                    concept['isSeed'] = (keyName.toLowerCase() == concept.word.toLowerCase());
                    concept['term'] = term;
                });
            }
        }

        function getConceptsList(config) {
            return getBubbles(config).promise.then(function (data) {
                return _(data.bubbles)
                    .mapValues('concepts')
                    .values()
                    .map(_.values)
                    .flatten()
                    .value();
            });
        }

        function removeExcludedConceptFromBubblesCache(excludedConceptId) {
            const bubblesRes = {};
            for (const keyName in cache.bubbles) {
                bubblesRes[keyName] = { concepts: _.pickBy(cache.bubbles[keyName].concepts, (_, conceptId) => (conceptId !== excludedConceptId)) };
            }
            _.extend(cache.bubbles, bubblesRes);
        }
    }
]);
