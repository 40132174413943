import React from 'react';
import BaseDropdown from '../BaseDropdown/BaseDropdown';
import PropTypes from 'prop-types';
import './LinkedDropdown.scss';

const LinkedDropdown = (props) => <BaseDropdown {...props} />;

LinkedDropdown.propTypes = {
  values: PropTypes.array,
  selectedValue: PropTypes.object,
  isDisabled: PropTypes.bool,
  isOpen: PropTypes.bool,
  onSelect: PropTypes.func,
  onSummaryClick: PropTypes.func,
};

LinkedDropdown.defaultProps = {
  className: 'linked-dropdown-component',
  shouldCloseOnSelect: true,
  shouldCloseOnClickOutside: true,
  menuMaxHeight: 200,
  summaryIconName: 'chevron',
  isLightMode: false,
  isMulti: false,
};

export default LinkedDropdown;
