import React from 'react';
import PropTypes from 'prop-types';
import './AppCommonElements.scss';
import ClickableIcon from '../../ClickableIcon/ClickableIcon';
import Separator from '../../Separator/Separator';
import Dropdown from '../../Dropdown/Dropdown/Dropdown';
import { useSelector } from 'react-redux';
import { wrapWithProvider } from '../../../../redux/reduxUtils';

const AppCommonElementsComponent = ({
  onCalendarClick,
  onExportClick,
  onFilterClick,
  isCalendarVisible,
  isExportVisible,
  isFilterVisible,
  isDropdownVisible,
  dropdownValues,
  selectedDropdownValue,
  onDropdownChange,
}) => {
  const { showShortSpinner } = useSelector((state) => state.spinner);

  return (
    <div className="app-common-elements-component">
      {/* calendar */}
      {isExportVisible && (
        <React.Fragment>
          <Separator />
          {showShortSpinner && <div className={'clickable-placeholder'} />}
          {showShortSpinner && <div className={'short-loader'} />}
          {!showShortSpinner && (
            <ClickableIcon className="common-icon" iconId="download" onClick={onExportClick} tooltip="Export" />
          )}
        </React.Fragment>
      )}
      {isFilterVisible && (
        <React.Fragment>
          <Separator />
          <ClickableIcon className="common-icon" iconId="filter" onClick={onFilterClick} />
        </React.Fragment>
      )}
      {isDropdownVisible && (
        <Dropdown
          values={dropdownValues}
          selectedValue={selectedDropdownValue}
          onSelect={onDropdownChange}
          summaryWidth="200px"
          menuPosition="absolute"
          shouldCloseOnSelect={true}
          shouldCloseOnClickOutside={true}
        />
      )}
    </div>
  );
};

AppCommonElementsComponent.propTypes = {
  onCalendarClick: PropTypes.func,
  onExportClick: PropTypes.func,
  onFilterClick: PropTypes.func,
  isCalendarVisible: PropTypes.bool,
  isExportVisible: PropTypes.bool,
  isFilterVisible: PropTypes.bool,
  isDropdownVisible: PropTypes.bool,
  dropdownValues: PropTypes.array,
  selectedDropdownValue: PropTypes.object,
  onDropdownChange: PropTypes.func,
};

AppCommonElementsComponent.defaultProps = {
  isCalendarVisible: false,
  isExportVisible: false,
  isFilterVisible: false,
  isDropdownVisible: false,
};

const AppCommonElements = wrapWithProvider(AppCommonElementsComponent);

export default AppCommonElements;
