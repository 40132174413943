import config from 'infra/config';

module.exports = angular.module(__filename, []).service('programSourcesService', ['$http', 'notificator', 'streamsService', function ($http, notificator, streamsService) {
    var HOST = config.USER_MGMT_API;
    var suffix = '?disable_notification=1';

    function base_url(p_id) {
        return HOST + '/programs/' + p_id + '/custom_sources';
    }

    return {
        list: getProgramSources,
        save: saveProgramSource,
        delete: deleteProgramSource
    };

    function getProgramSources(p_id) {
        return $http.get(base_url(p_id), {cache: false}).then(getResponseSuccess, responseError);
    }

    function saveProgramSource(p_id, source) {
        return source.id ? update(p_id, source) : create(p_id, source);
    }

    function create(p_id, source) {
        var url = base_url(p_id) + suffix;
        return $http.post(url, source).then(responseSuccess, responseError);
    }

    function update(p_id, source) {
        var url = base_url(p_id) + '/' + source.id + suffix;
        return $http.put(url, source).then(responseSuccess, responseError);
    }

    function deleteProgramSource(p_id, source_id) {
        var url = base_url(p_id) + '/' + source_id;
        return $http.delete(url).then(responseSuccess, responseError);
    }

    function responseSuccess(response) {
        return response.data;
    }

    function getResponseSuccess(response) {
        return streamsService.streamsDocCount({programSources: response.data}).promise.then(function (docsCount) {
            _.each(response.data, function (ps) {
                _.each(ps.sources, source => source.docsCount = docsCount[source.id] || 0);
                ps.status = sourceStatus(ps.sources);
            });

            return response.data;
        });
    }

    function sourceStatus(sourceUrls) {
        var status;
        if (_.every(sourceUrls, source => source.docsCount > 0)) {
            status = 'all';
        } else if (_.some(sourceUrls, source => source.docsCount > 0)) {
            status = 'partial';
        } else {
            status = 'none';
        }

        return status;
    }

    function responseError(err) {
        var notification;
        switch (err.config.method) {
            case 'DELETE':
                notification = "Failed to delete program source";
                break;
            case 'POST':
                notification = "Failed to create program source. " + getErrorMessage(err.data);
                break;
            default:
                notification = "Something went wrong, our engineers are working on it.";
                break;
        }

        notificator.error({body: notification});
        return Promise.reject(err);
    }

    function getErrorMessage(error) {
        var errorKeys = Object.keys(error.errors || {});
        return errorKeys.map(function (key) {
            return key + ' ' + error.errors[key];
        }).join();
    }
}]);