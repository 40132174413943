'use strict';

module.exports = angular.module(__filename, []).directive("onPressEnter", [function () {
    return {
        restrict: "A",
        link: function (scope, elem, attr) {
            elem.bind('keydown', function (e) {
                scope.$apply(function () {
                    if (e.which === 13) {
                        scope.$eval(attr['onPressEnter'], {$event: e});
                        e.stopPropagation();
                    }
                });
            });
        }
    };
}]).directive("onPressTab", [function () {
    return {
        restrict: "A",
        link: function (scope, elem, attr) {
            elem.bind('keydown', function (e) {
                scope.$apply(function () {
                    if (e.which === 9) {
                        scope.$eval(attr['onPressTab'], {$event: e});
                        e.preventDefault();
                        e.stopPropagation();
                    }
                });
            });
        }
    };
}]).directive("onPressArrows", [function () {
    var hash = {40: 'DOWN', 38: 'UP'};
    return {
        restrict: "A",
        link: function (scope, elem, attr) {
            var key;
            elem.bind('keydown', function (e) {
                if (key = hash[e.which]) {
                    scope.$apply(function () {
                        scope.$eval(attr['onPressArrows'], {$event: e, $key: key});
                    });
                }
            });
        }
    };
}]).filter('highlight', ['$sce', function ($sce) {
    /**
     * Highlights text that matches $select.search.
     *
     * Taken from AngularUI Bootstrap Typeahead
     * See https://github.com/angular-ui/bootstrap/blob/0.10.0/src/typeahead/typeahead.js#L340
     */
    function escapeRegexp(queryToEscape) {
        var escapeCharactersRegex = /([.?*+^$[\]\\(){}|-])/g;
        return queryToEscape.replace(escapeCharactersRegex, '\\$1');
    }

    return function (matchItem, queries) {
        var escapedQueriesReText= _(_.castArray(queries)).map(function(query) { return escapeRegexp(query); }).join('|');
        var escapedQueriesRe = new RegExp(escapedQueriesReText, 'gi');
        var v = escapedQueriesReText && matchItem ? matchItem.replace(escapedQueriesRe, '<span class="ui-select-highlight">$&</span>') : matchItem;
        return $sce.trustAsHtml(v);
    };
}]).filter('truncateXss', function(){
    return function(text) {
        return text.replace(/<[a-z].*>|<script/ig, '*truncated*')
      };
});
