module.exports = angular.module(__filename, []).service('debouncer', function () {
    var callbacks = {};
    var debounces = {};

    this.debounce = function (caller_id, group, callback, duration) {
        if (!callbacks[group]) callbacks[group] = {};
        var extended_callbacks = _.clone(callbacks); // immutable - avoid race condition
        extended_callbacks[group][caller_id] = callback;
        callbacks = extended_callbacks;
        if (!debounces[group]) {
            debounces[group] = _.debounce(function () {
                _.values(callbacks[group]).forEach(function (f) {
                    f();
                });
                callbacks[group] = {};
            }, duration || 1500)
        }
        debounces[group]();
    };

    return {
        debounce: this.debounce
    }
});
