import React, { useMemo, useRef, useState } from 'react';
import './BarChart.scss';
import PropTypes from 'prop-types';
import { findIndex, isEmpty, map, noop, some, throttle } from 'lodash';
import { getAllObjectsByMaxValue, getMaxValue } from '../../../utils/ArrayUtils';
import {
  Bar,
  BarChart as RechartsBarChart,
  Cell,
  LabelList,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';

const COMMON_STYLE_OPTIONS = {
  barColor: '#cacaca',
  barLabelColor: '#cacaca',
  barHighlightedLabelColor: 'white',
  axisLineColor: '#3e4347',
  axisLabelColor: '#cacaca',
  axisHighlightedLabelColor: 'white',
  referenceLineColor: '#444',
};

const THEMES = {
  default: {
    ...COMMON_STYLE_OPTIONS,
    barWidth: 20,
    barLabelFont: { fontFamily: 'Helvetica', fontSize: 14 },
    barHighlightedColor: '#44b0c7',
    barHighlightedLabelFont: { fontFamily: 'Helvetica Bold', fontSize: 22 },
    axisLabelFont: { fontFamily: 'Helvetica', fontSize: 14 },
    axisHighlightedLabelFont: { fontFamily: 'Helvetica', fontSize: 14 },
    axisLineColor: 'rgba(255, 255, 255, 0.3)',
  },
  dashboard: {
    ...COMMON_STYLE_OPTIONS,
    barWidth: 20,
    barLabelFont: { fontFamily: 'Helvetica', fontSize: 14 },
    barHighlightedColor: 'white',
    barHighlightedLabelFont: { fontFamily: 'Helvetica Bold', fontSize: 16 },
    axisLabelFont: { fontFamily: 'Helvetica', fontSize: 14 },
    axisHighlightedLabelFont: { fontFamily: 'Helvetica', fontSize: 14 },
  },
  preview: {
    ...COMMON_STYLE_OPTIONS,
    barWidth: 13,
    barLabelFont: { fontFamily: 'Helvetica', fontSize: 12 },
    barHighlightedColor: 'white',
    barHighlightedLabelFont: { fontFamily: 'Helvetica', fontSize: 13 },
    axisLabelFont: { fontFamily: 'Helvetica', fontSize: 12 },
    axisHighlightedLabelFont: { fontFamily: 'Helvetica', fontSize: 12 },
  },
};

const BarChart = ({
  data,
  theme,
  styleOptions,
  shouldHighlightMax,
  referenceLine,
  width,
  height,
  hideXAxisLabels,
  tickOffset,
  isUpsideDown,
  hasTooltip,
  tooltipFunc,
  maxValueForChart,
}) => {
  let [barsPositions, setBarsPositions] = useState();
  const barChart = useRef();

  let highlightedObjects = (shouldHighlightMax && getAllObjectsByMaxValue(data, 'value', true)) || [];
  const maxValue = maxValueForChart || getMaxValue(data, 'value');
  const TOOLTIP_WIDTH = 185;
  const BAR_HEIGHT_OFFSET = 30;
  const TOOLTIP_TOP_OFFSET = 105;
  const TOOLTIP_EDGE_OFFSET = 10;
  const UPSIDE_DOWN_BAR_HEIGHT_OFFSET = 15;
  const UPSIDE_DOWN_TOOLTIP_TOP_POSITION = -35;
  const themeStyle = {
    ...THEMES[theme],
    ...styleOptions,
    ...{ highlightedObjects, shouldHighlightMax, tickOffset, isUpsideDown },
  };
  const customizedLabel = <CustomizedLabel {...themeStyle} />;
  const customizedTick = <CustomizedTick {...themeStyle} />;

  if (isUpsideDown) {
    data = map(data, (d) => ({ ...d, value: -d.value }));
    highlightedObjects = map(highlightedObjects, (d) => ({ ...d, value: -d.value }));
  }

  function mouseMoveHandler(mouseEvent) {
    if (isEmpty(barsPositions)) {
      barsPositions = [];
      const barsElements = barChart.current.querySelectorAll('.recharts-bar-rectangle');
      barsPositions = map(barsElements || [], (el) => el.getClientRects()[0]);
      setBarsPositions(barsPositions);
    }

    const { clientX, clientY } = mouseEvent;
    const barIndex = findIndex(barsPositions, (bar) => {
      const hasHorizontalOverlap = clientX > bar.x && clientX < bar.x + bar.width;
      const hasVerticalOverlap =
        (isUpsideDown && clientY > bar.y && clientY < bar.y + bar.height + BAR_HEIGHT_OFFSET) ||
        (!isUpsideDown && clientY > bar.y - UPSIDE_DOWN_BAR_HEIGHT_OFFSET && clientY < bar.y + bar.height);
      return hasHorizontalOverlap && hasVerticalOverlap;
    });

    closeTooltip();

    if (barIndex > -1) {
      showTooltip(barIndex, barsPositions[barIndex]);
    }
  }

  const onMouseMove = useMemo(() => {
    const throttled = throttle(mouseMoveHandler, 100);
    return (event) => {
      event.persist();
      return throttled(event);
    };
  }, []);

  function showTooltip(barIndex, bar) {
    const { label, value } = data[barIndex];
    const tooltipElement = document.createElement('div');
    tooltipElement.classList.add('bar-chart-static-tooltip');
    tooltipElement.innerHTML = tooltipFunc(label, Math.abs(parseFloat(value)));

    const { x, y } = getTooltipPosition(barIndex, bar);
    tooltipElement.style.left = x + 'px';
    tooltipElement.style.top = y + 'px';
    barChart.current.appendChild(tooltipElement);
  }

  function getTooltipPosition(barIndex, bar) {
    const position = {};
    const [barX, barY] = map(['x', 'y'], (field) => bar[field] - barChart.current.getClientRects()[0][field]);
    const leftTooltipX = barX - TOOLTIP_WIDTH / 2 + bar.width / 2;

    if (bar.x < TOOLTIP_EDGE_OFFSET) {
      position.x = TOOLTIP_EDGE_OFFSET;
    } else if (bar.x + TOOLTIP_WIDTH / 2 > window.innerWidth + TOOLTIP_EDGE_OFFSET) {
      position.x = barX - (bar.x + TOOLTIP_WIDTH - window.innerWidth) - TOOLTIP_EDGE_OFFSET;
    } else {
      position.x = leftTooltipX;
    }
    position.y = isUpsideDown ? UPSIDE_DOWN_TOOLTIP_TOP_POSITION : barY - TOOLTIP_TOP_OFFSET;
    return position;
  }

  function closeTooltip() {
    const tooltip = barChart.current.querySelector('.bar-chart-static-tooltip');
    if (tooltip) {
      barChart.current.removeChild(tooltip);
    }
  }

  return (
    <div
      className="bar-chart-component"
      ref={barChart}
      onMouseLeave={() => setTimeout(closeTooltip, 100)}
      onMouseMove={(event) => hasTooltip && onMouseMove(event)}
    >
      <ResponsiveContainer width={width} height={height}>
        <RechartsBarChart data={data} margin={{ top: 30, right: 0, left: 0, bottom: 10 }}>
          <XAxis
            dataKey="label"
            stroke={themeStyle.axisLineColor}
            tick={customizedTick}
            minTickGap={0}
            tickLine={false}
            interval="preserveStartEnd"
            hide={hideXAxisLabels}
          />
          <YAxis domain={isUpsideDown ? [-maxValue, 0] : [0, maxValue]} hide />
          {(referenceLine || referenceLine === 0) && (
            <ReferenceLine y={referenceLine} stroke={themeStyle.referenceLineColor} strokeWidth={1} />
          )}
          {
            /* Show only XAxis line */
            hideXAxisLabels && <ReferenceLine y={0} stroke={themeStyle.axisLineColor} strokeWidth={1} />
          }
          <Bar dataKey="value" barSize={themeStyle.barWidth} isAnimationActive={false} minPointSize={1}>
            <LabelList dataKey="displayValue" content={customizedLabel} />
            {data.map((entry, i) => (
              <Cell
                key={`cell-${i}`}
                fill={
                  shouldHighlightMax && some(highlightedObjects, data[i])
                    ? themeStyle.barHighlightedColor
                    : themeStyle.barColor
                }
              />
            ))}
          </Bar>
        </RechartsBarChart>
      </ResponsiveContainer>
    </div>
  );
};

const CustomizedLabel = ({
  x,
  y,
  value,
  index,
  highlightedObjects,
  shouldHighlightMax,
  barWidth,
  barLabelFont,
  barHighlightedLabelFont,
  barLabelColor,
  barHighlightedLabelColor,
  isUpsideDown,
}) => {
  const [labelFont, fillColor] =
    shouldHighlightMax && some(highlightedObjects, { index })
      ? [barHighlightedLabelFont, barHighlightedLabelColor]
      : [barLabelFont, barLabelColor];

  return (
    <text
      {...labelFont}
      x={x + barWidth / 2}
      y={y + (isUpsideDown ? 40 : 0)}
      dy="-.7em"
      fill={fillColor}
      textAnchor="middle"
    >
      {value}
    </text>
  );
};

const CustomizedTick = ({
  x,
  y,
  payload,
  highlightedObjects,
  shouldHighlightMax,
  axisLabelFont,
  axisHighlightedLabelFont,
  axisLabelColor,
  axisHighlightedLabelColor,
  tickOffset,
}) => {
  const label = payload.value;
  const [labelFont, fillColor] =
    shouldHighlightMax && some(highlightedObjects, { label })
      ? [axisHighlightedLabelFont, axisHighlightedLabelColor]
      : [axisLabelFont, axisLabelColor];

  return (
    <text
      {...labelFont}
      x={x}
      y={y + tickOffset}
      dy="-.7em"
      fill={fillColor}
      alignmentBaseline="hanging"
      textAnchor="middle"
    >
      {label}
    </text>
  );
};

BarChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
      displayValue: PropTypes.string,
    })
  ),
  theme: PropTypes.oneOf(Object.keys(THEMES)),
  styleOptions: PropTypes.shape({
    barWidth: PropTypes.number,
    barColor: PropTypes.string,
    barLabelColor: PropTypes.string,
    barLabelFont: PropTypes.shape({ fontFamily: PropTypes.string, fontSize: PropTypes.number }),
    barHighlightedColor: PropTypes.string,
    barHighlightedLabelColor: PropTypes.string,
    barHighlightedLabelFont: PropTypes.shape({ fontFamily: PropTypes.string, fontSize: PropTypes.number }),
    axisLineColor: PropTypes.string,
    axisLabelColor: PropTypes.string,
    axisLabelFont: PropTypes.shape({ fontFamily: PropTypes.string, fontSize: PropTypes.number }),
    axisHighlightedLabelFont: PropTypes.shape({ fontFamily: PropTypes.string, fontSize: PropTypes.number }),
    axisHighlightedLabelColor: PropTypes.string,
    referenceLineColor: PropTypes.string,
  }),
  shouldHighlightMax: PropTypes.bool,
  referenceLine: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hideXAxisLabels: PropTypes.bool,
  tickOffset: PropTypes.number,
  isUpsideDown: PropTypes.bool,
  hasTooltip: PropTypes.bool,
  tooltipFunc: PropTypes.func,
  maxValueForChart: PropTypes.number,
};

BarChart.defaultProps = {
  data: [],
  theme: 'default',
  styleOptions: {},
  shouldHighlightMax: true,
  width: '100%',
  height: '100%',
  hideXAxisLabels: false,
  tickOffset: 15,
  isUpsideDown: false,
  hasTooltip: false,
  tooltipFunc: noop,
};

CustomizedLabel.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  value: PropTypes.string,
  index: PropTypes.number,
  highlightedObjects: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
      displayValue: PropTypes.string,
    })
  ),
  shouldHighlightMax: PropTypes.bool,
  barWidth: PropTypes.number,
  barLabelFont: PropTypes.shape({ fontFamily: PropTypes.string, fontSize: PropTypes.number }),
  barHighlightedLabelFont: PropTypes.shape({ fontFamily: PropTypes.string, fontSize: PropTypes.number }),
  barLabelColor: PropTypes.string,
  barHighlightedLabelColor: PropTypes.string,
  isUpsideDown: PropTypes.bool,
};

CustomizedTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.shape({
    value: PropTypes.string,
  }),
  highlightedObjects: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
      displayValue: PropTypes.string,
    })
  ),
  shouldHighlightMax: PropTypes.bool,
  barWidth: PropTypes.number,
  axisLabelFont: PropTypes.shape({ fontFamily: PropTypes.string, fontSize: PropTypes.number }),
  axisHighlightedLabelFont: PropTypes.shape({ fontFamily: PropTypes.string, fontSize: PropTypes.number }),
  axisLabelColor: PropTypes.string,
  axisHighlightedLabelColor: PropTypes.string,
  tickOffset: PropTypes.number,
};

CustomizedTick.defaultProps = {
  tickOffset: 15,
};

export { BarChart, CustomizedLabel, CustomizedTick };
