module.exports = angular.module(__filename, []).directive('selectedColumn', function () {
    return {
        restrict: 'A',
        require: '^amTable',
        template: require('./selected-column.html'),
        scope: {
            selectedModel: '=',
            rowObj: '=selectedColumn'
        },
        link: function (scope, element, attr, amTableCtrl) {
            scope.toggleSelected = amTableCtrl.toggleSelected;
        }
    }
});