import React from 'react';
import { any, arrayOf, bool, func, object, oneOf, shape, string } from 'prop-types';
import './TabSelector.scss';
import Tooltip from '../Tooltip/Tooltip';
import classNames from 'classnames';

function Tab({ isDisabled, isSelected, label, tooltip, onClick }) {
  return (
    <Tooltip content={tooltip} options={{ placement: 'bottom', duration: 2000 }}>
      <div className={classNames('tab', { selected: isSelected, disabled: isDisabled })} onClick={onClick}>
        <span className="label">{label}</span>
      </div>
    </Tooltip>
  );
}

Tab.propTypes = {
  isDisabled: bool.isRequired,
  isSelected: bool.isRequired,
  label: string.isRequired,
  tooltip: string,
  onClick: func.isRequired,
};

Tab.defaultProps = {
  isDisabled: false,
  isSelected: false,
};

function TabSelector({ tabs, selectedTab, onSelect, isDarkTheme, tabLocation }) {
  return (
    <div
      className={classNames('tab-selector-component', isDarkTheme ? 'dark' : 'light')}
      style={{ justifyContent: tabLocation }}
    >
      {tabs.map((tab) => (
        <Tab
          {...tab}
          isSelected={tab.value === selectedTab.value}
          onClick={() => !tab.isDisabled && onSelect(tab)}
          key={tab.value.toString()}
        />
      ))}
    </div>
  );
}

TabSelector.propTypes = {
  tabs: arrayOf(
    shape({
      label: string.isRequired,
      isDisabled: bool,
      tooltip: string,
      value: any.isRequired,
    })
  ).isRequired,
  selectedTab: object.isRequired,
  tabLocation: oneOf([
    'center',
    'start',
    'end',
    'flex-start',
    'flex-end',
    'left',
    'right',
    'normal',
    'space-between',
    'space-around',
    'space-evenly',
    'stretch',
    'safe center',
    'unsafe center',
    'inherit',
    'initial',
    'unset',
  ]).isRequired,
  onSelect: func.isRequired,
  isDarkTheme: bool.isRequired,
};

TabSelector.defaultProps = {
  isDarkTheme: true,
  tabLocation: 'center',
};

export default TabSelector;
