var tmpl = require('./dropdown.html')

// TODO: Should be replaced with am-dropdown-select
module.exports = angular.module(__filename, [])
  .component("dropdownSelector", {
    bindings: {
      selections: '<',
      selected: '<',
      onSelect: '&',
      filterName: '@',
      radio: '<',
      selectedText: '@'
    },
    template: tmpl,
    controller: ['$scope',function($scope) {

      this.onSelected = function() {
        this.onSelect({$event: this.selected});
      }

    }]
  });
