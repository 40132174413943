import React from 'react';
import PropTypes from 'prop-types';
import AppTopBar from '../../../../common/AppNavigation/AppTopBar/AppTopBar';
import Separator from '../../../../common/Separator/Separator';
import ClickableIcon from '../../../../common/ClickableIcon/ClickableIcon';
import './AudienceExplorerTopBar.scss';

const AudienceExplorerTopBar = ({ audienceName, audienceInfoTooltipContent }) => (
  <div className="audience-explorer-top-bar-component">
    <AppTopBar>
      <React.Fragment>
        <Separator />
        <span className="audience-name">{audienceName}</span>
        <ClickableIcon
          className="audience-info"
          iconId="info-italic"
          tooltip={audienceInfoTooltipContent}
          tooltipOptions={{ maxWidth: '590px', placement: 'bottom-start', distance: -8 }}
        ></ClickableIcon>
      </React.Fragment>
    </AppTopBar>
  </div>
);

export default AudienceExplorerTopBar;

AudienceExplorerTopBar.propTypes = {
  audienceName: PropTypes.string.isRequired,
  audienceInfoTooltipContent: PropTypes.string.isRequired,
};
