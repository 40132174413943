/*
cross feature GDPR+NAI compliance
to use:
=======

1. import ComplianceService

2. import ComplianceModal
   * see its "to use" in compliance-modal.js

3. call ComplianceService.checkPhrases

*/
import _ from 'lodash';
import { executeHttpRequest } from '../infra/HttpClient';
import ComplianceApi from '../api/ComplianceApi';

const AN_FEATURES = ['audience'];

export default {
  checkPhrases: async function ({ phrases, feature, isTitle, checkGDPR = false, checkNAI = false }) {
    /*
    returns
    =======
    passed: true | false
    GDPR:   [all phrases who failed GDPR]
    NAI:    [all phrases who failed NAI],
    text:   for popup,
    results: {
      phrase1: {gdpr: 0|1, nai: 0|1},
      phrase2: {gdpr: 0|1, nai: 0|1}
    }

    cache
    =====
    phrasesCache stores compliance query results.
    then before each query,
    try to fetch 1st from phrasesCache
    */

    if (!phrases || (Array.isArray(phrases) && !phrases.length)) {
      return { passed: true, results: {} };
    }

    const response = await executeHttpRequest(ComplianceApi.checkPhrases(phrases), { displayRedBannerOnError: false });
    const results = response.results;

    /*
    returns
    =======
    phrase1: {gdpr: 0|1, nai: 0|1},
    phrase2: {gdpr: 0|1, nai: 0|1}
    (1 = phrase isnt compliant)
    */

    phrases = Object.keys(results);

    const GDPR = phrases.filter((p) => results[p].gdpr == 1);
    const NAI = phrases.filter((p) => results[p].nai == 1);

    let passed = true;
    if (GDPR.length && checkGDPR) {
      passed = false;
    }
    if (NAI.length && checkNAI) {
      passed = false;
    }

    const getTitleText = () => {
      if (passed) {
        return '';
      } else if (isTitle) {
        const phrases = _.uniq(GDPR.concat(NAI));
        return this.sensitiveTitleText({ phrases, feature });
      } else if (GDPR.length && NAI.length && checkGDPR && checkNAI) {
        const phrases = _.uniq(GDPR.concat(NAI));
        return this.GDPRAndNAIText({ phrases, feature });
      } else if (GDPR.length && checkGDPR) {
        const phrases = GDPR;
        return this.GDPRText({ phrases, feature });
      } else {
        const phrases = NAI;
        return this.NAIText({ phrases, feature });
      }
    };

    const text = getTitleText();

    return { passed, GDPR, NAI, text, results };
  },

  sensitiveTitleText: ({ phrases, feature }) => {
    const s = phrases.length > 1 ? 's' : '';
    const words = phrases.join(', ');
    const verb = phrases.length > 1 ? 'are' : 'is';
    const indefiniteArticle = AN_FEATURES.includes(feature) ? 'an' : 'a';
    return `The word${s} "${words}" ${verb} considered sensitive and cannot be used for naming ${indefiniteArticle} ${feature}. Please rename your ${feature}.`;
  },

  GDPRText: ({ feature }) =>
    `Your ${feature} will result in no delivery for EU users due to GDPR regulations on the below keywords. By selecting "Remove & Save", this will remove the following keywords and allow delivery in the EU. By selecting "Save", this will allow for delivery of all keywords outside of the EU.`,

  NAIText: ({ feature }) =>
    `Your ${feature} will result in no delivery for US users due to NAI Code regulations on the below keywords. By selecting "Remove & Save", this will remove the following keywords and allow delivery in the US. By selecting "Save Audience", this will allow for delivery of all keywords outside of the US.`,

  GDPRAndNAIText: ({ feature }) =>
    `Your ${feature} will result in no delivery for EU and/or US users due to GDPR and/or NAI Code regulations on the below keywords. By selecting "Remove & Save", this will remove the following keywords and allow delivery in the chosen regions. By selecting "Save Audience", this will allow for delivery of all keywords outside of the EU and/or US.`,

  getSensitivityDataForAudience(audience) {
    return executeHttpRequest(ComplianceApi.getSensitivityDataForAudience(audience), {
      displayRedBannerOnError: false,
    });
  },
};
