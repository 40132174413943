'use strict';

module.exports = angular.module(__filename, [])
    .service('audienceLinkedinInterestsExportService', ['Excel', 'segmentInterestExportService',
        function (Excel, segmentInterestExportService) {

            function createSummaryWorksheet(workbook, formatter, segment) {
                const worksheet = workbook.createWorksheet({name: 'Summary'});
                worksheet.setData(segmentInterestExportService.segmentDefsRowsUtil.drawDataTable(segment, 'linkedin', formatter));
                let valuesColumnSize = worksheet.data.reduce(function (longest, currentSegment) {
                    if (!currentSegment[1]) return longest;
                    if ((currentSegment[1].value || {}).length > longest)
                        return currentSegment[1].value.length;
                    return longest;
                }, 25);
                worksheet.setColumns([{width: 25}, {width: valuesColumnSize}]);
                workbook.addWorksheet(worksheet);
            }

            function createLinkedinWorksheet(workbook, interests, formatter, type) {
                const worksheet = workbook.createWorksheet({name: type});
                let firstColumnSize = 25;

                if (type == "Content") {
                    let longestUrl = interests.reduce(function (longest, currentUrl) {
                        if (currentUrl.value.length > longest)
                            return currentUrl.value.length;
                        return longest;
                    }, 0);
                    firstColumnSize = longestUrl * 0.85;
                }

                let titleLine = [
                    formatter(type, 'bold'),
                    formatter('Engagements', 'bold'),
                    formatter('Impressions', 'bold')
                ];

                let data = _.sortBy(interests, interest => -interest.engagementRate).map(interest => [
                    formatter(interest.value, 'normal'),
                    formatter(interest.engagementPercentage && interest.engagementPercentage / 100, 'percent'),
                    formatter(interest.impressionPercentage && interest.impressionPercentage / 100, 'percent')
                ]);

                data.unshift(titleLine);
                worksheet.setData(data);
                worksheet.setColumns([{width: firstColumnSize}, {width: 18}, {width: 18}]);
                workbook.addWorksheet(worksheet);
            }

            const fileNamer = segmentInterestExportService.momentBasedFileNamerGen('Audience Linkedin Interests');

            function exportToExcel(data, segment) {
                const workbook = Excel.builder.createWorkbook();
                const formatter = Excel.formater(workbook);

                createSummaryWorksheet(workbook, formatter, segment);
                if (data.domains) createLinkedinWorksheet(workbook, data.domains, formatter, "Websites");
                if (data.topics) createLinkedinWorksheet(workbook, data.topics, formatter, "Phrases");
                if (data.urls) createLinkedinWorksheet(workbook, data.urls, formatter, "Content");

                workbook.fileName = fileNamer();
                return workbook;
            }

            function downloadExcel(excel) {
                return segmentInterestExportService.excelDownloader(excel, fileNamer);
            }

            return {exportToExcel, downloadExcel};
        }
    ]);
