"use strict";

import config from 'infra/config';
 
dynamicTrendsCtrl.$inject = ['$scope', '$q', '$sce', '$http', 'filtersPartition', '$timeout', 'parentScope', 'context', 'util', 'TargetsCommon', 'geoService', 'close'];
function dynamicTrendsCtrl($scope, $q , $sce, $http, filtersPartition, $timeout, parentScope, context, util,TargetsCommon  , geoService , close) {

    $scope.editTargetCtrl = parentScope.editTargetCtrl;
    $scope.editTargetCtrl.dynamicTrendsEditHelper.origQuery = $.extend(true, {}, parentScope.currentTarget.query);
    parentScope.dynamicTrendsCtrl = this;
    $scope.query = $.extend(true, {}, parentScope.queries.dynamic);
    $scope.query.largeHeader = true;

    $scope.dataTrees = { 
        'topicsSummary': $.extend(true, {} , 
            {children: $scope.$root.topicsFilter.slice(0), 
             allSelectedLabel: "All Topics"
            })
    };

    $scope.init = function () {
        var target = parentScope.currentTarget;
        $scope.treeLoaded = true;
        $scope.dynamicTempData = {
            currentTarget: target,
            queries:{
                dynamic: { dataArray : {}}
            }
        };
        
        if($scope.editTargetCtrl.isDynamicTempDataEmpty()){
            var dataArray = $scope.editTargetCtrl.dynamicTrendsEditHelper.origQueriesDataArray = parentScope.queries.dynamic.dataArray.slice(0);
        }else if($scope.editTargetCtrl.dynamicTrendsEditHelper.dynamicTempData.queries){
            var dataArray = $scope.editTargetCtrl.dynamicTrendsEditHelper.dynamicTempData.queries.dynamic.dataArray;
        }
        
        $timeout(function(){
            $scope.dataArray = $.extend(true, [], dataArray);
            $scope.query.show(dataArray);
            $scope.dataTrees.topicsSummary.checkedArray = getTopics();
            $scope.dataTrees.topicsSummary.show();
         
            $scope.targetInfo = {
                seeds: $scope.editTargetCtrl.dynamicTrendsEditHelper.getSeedsData(util).map(function(seed){return seed.text}),
                timeframe: getTimeframe(),
                channel: target.channel,  
                demographics: $sce.trustAsHtml(getAudience(target.query.audience)),
                topics: $scope.dataTrees.topicsSummary.summaryStr,
                geos: geoService.genSummaryByIds(target.query.geos) ,
                keyTrends: getKeyTrendsValue(getUpdatedKeyTrends(), 2)
            }
            
            $scope.dynamicKeyTrends = $scope.targetInfo.keyTrends;
        });
    };

    $scope.mixpanelTrackSort = function (params) {
        parentScope.mixpanelTrackSort(params);
    }; 

    function getQuery(){
        return $scope.editTargetCtrl.isDynamicTempDataEmpty()? parentScope.currentTarget.query : $scope.editTargetCtrl.dynamicTrendsEditHelper.dynamicTempData.currentTarget.query;
    }

    function getTopics(){
        let query = $scope.editTargetCtrl.isDynamicTempDataEmpty()? parentScope.currentTarget.query : $scope.editTargetCtrl.dynamicTrendsEditHelper.dynamicTempData.currentTarget.query;
        let topics = _.isEmpty(query.topics)? [] : query.topics;
        var checkedTopics = topics.slice(0);
        return checkedTopics = checkedTopics.map(function(t){return {id: t}});
    }

    function getAudience(aud){
        if(!aud || !aud.length){
            return 'all Audience';
        }

        aud = aud.map(function(seg){return getAudienceSegment(seg)});
        return aud.join(" , ");
    }

    function getAudienceSegment(seg){
        var i , j , filter , len;
        for(i in filtersPartition){
            filter = filtersPartition[i], len = filter.length;
            for(j=0;j<len;j++){
                if(filter[j].value==seg){
                    return i+": "+ '<span class="info-sub">'+filter[j].label+'</span>';
                }
            }
        }
        return '';
    }

    function getTimeframe(){
       
        //timeframe set in targets creation, like "1 day"
            let query = getQuery();
            if(query.timeframe && query.timeframe != "custom"){
                return query.timeframe;
            }

            return moment(query.timeframe_start).format("MMM Do, YYYY") + 
                " - " + 
                moment(query.timeframe_end).format("MMM Do, YYYY");
    }

    parentScope.$on('updateDynamicTrends', function (event, obj) {
        $scope.init();
    });

    function getUpdatedKeyTrends(){
        return $scope.editTargetCtrl.dynamicTrendsEditHelper.getUpdatedQuery().key_trends;
    }


    function getKeyTrendsValue(keyTrends, strOrNum){
        //strOrNum: default is: null, undefined, "str" ,0 will return a string 'on' / 'off'
        //          1 will return num 0 or 1
        //          2 will check the keyTrends num value
        if(strOrNum == 1){
            return keyTrends == 'On' ? 1 : 0 ;
        }else if(strOrNum == 2){
            return keyTrends == 1 ? 'On' : 'Off'
        }else{
            return keyTrends == 'On' ? 'Off' : 'On';
        }
    }

    $scope.updateKeyTrends = function(){
        $scope.dynamicKeyTrends = getKeyTrendsValue($scope.dynamicKeyTrends);
        $scope.dynamicTempData.currentTarget.query.key_trends = getKeyTrendsValue($scope.dynamicKeyTrends, 1);
        $scope.editTargetCtrl.dynamicTrendsEditHelper.dynamicTempData = $scope.dynamicTempData;
        $scope.editTargetCtrl.dynamicTrendsEditHelper.dynamicTempData.queries.dynamic.dataArray = parentScope.queries.dynamic.dataArray;
        $scope.dynamicSeeds = $scope.editTargetCtrl.dynamicTrendsEditHelper.getSeedsData(util);

         let params = parentScope.currentTarget;
            params.action = 'editDynamicTrends';
            params.customSeeds = $scope.dynamicSeeds;

         let queryAdditions = TargetsCommon.TargetData.targetToServer({target: params});

         TargetsCommon.TargetData.targetToUI({
           target: $scope.dynamicTempData.currentTarget, context: context, action: 'edit-target'
         });

         $scope.refreshView({"seeds": queryAdditions.phrases});
    }

    $scope.getDynamicTrendsBySeed = function (updatedQuery) {

        var url = config.USER_MGMT_API + '/targets/dynamic_by_query';

        $scope.query.loading = true;

        var queryParam = {query : $scope.editTargetCtrl.dynamicTrendsEditHelper.getUpdatedQuery()}

        $http.post(url, queryParam).then(function(res){
                var dynamic = [],
                    query = res.data

                    $.each(query, function (i, cell) {
                        if(cell.dynamic){
                            dynamic.push(cell);
                        }
                    });

                $scope.editTargetCtrl.dynamicTrendsEditHelper.dynamicTempData.queries = parentScope.queries;
                $scope.editTargetCtrl.dynamicTrendsEditHelper.dynamicTempData.queries.dynamic.dataArray = dynamic.slice(0);
                $scope.init();
        });
    }


    $scope.refreshView = function (updatedQuery) {
         $scope.getDynamicTrendsBySeed(updatedQuery);
    };

    $scope.done = function () {
        parentScope.$broadcast("dynamic-trends-window", {phrases: $scope.dataArray, tab: parentScope.tab});
        close({});
    };

    $scope.close = function () {
        $scope.editTargetCtrl.dynamicTrendsOpened = false;
        close({});
    };

    $scope.init();
}

module.exports = dynamicTrendsCtrl;
 
