"use strict";
 
removeAllFromTargetCtrl.$inject = ['$scope', 'parentScope', 'TargetsCommon', 'target', 'close'];
function removeAllFromTargetCtrl($scope,  parentScope, TargetsCommon, target, close) {

    $scope.target = target;

    $scope.close = () =>{
        close({});
    };

    $scope.targetTabName = ()=>{
        return TargetsCommon.targetTabName(target);
    }
    

    $scope.removeAllFromTargetCB = ()=>{
        parentScope.removeAllFromTargetCB();
        $scope.close();
    }

}

module.exports = removeAllFromTargetCtrl;
 
