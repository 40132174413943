import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import $ from 'jquery';
import './Menu.scss';
import OutsideClickHandler from 'react-outside-click-handler'; //'react-onclickoutside' has issues with multiple hooks components
import { noop } from 'lodash';
import { Icon } from '@amobee/component-library';

const MenuItem = ({ label, disabled, disabledText, onClick }) => (
  <div className="menu-item-wrapper">
    {disabledText && <div className="disabled-text">{disabledText}</div>}
    <div className="menu-item-row">
      <div
        className={classNames('menu-item', label.replace(/\s/g, '_'), { disabled: disabled })}
        onClick={(e) => {
          if (!disabled) onClick(e);
        }}
      >
        <span className="item-label">{label}</span>
        {disabled && <Icon className="item-icon" name="StatusWarning" size={10} />}
      </div>
    </div>
  </div>
);

MenuItem.propTypes = {
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  disabledText: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

const Menu = ({ isMenuOpen, closeMenu, headerLabel, menuActions, className, attachToParent }) => {
  const [menuStyle, setMenuStyle] = useState(null);

  const menuRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (!attachToParent || !menuRef.current) return;
      const parentElement = $(menuRef.current.parentNode.parentNode);
      const offset = parentElement.offset();
      setMenuStyle({
        bottom: $(window).height() - offset.top + 4 + 'px',
        right: $(window).width() - (offset.left + parentElement.outerWidth()) + 'px',
        position: 'fixed',
      });
    };

    if (!menuStyle) {
      handleResize();
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return (
    <OutsideClickHandler onOutsideClick={closeMenu}>
      {isMenuOpen && (
        <div ref={menuRef} className={classNames('menu-component', className)} style={menuStyle}>
          {headerLabel && (
            <div className="menu-header">
              <span className="header-label">{headerLabel}</span>
            </div>
          )}
          {menuActions.map(
            (action) =>
              !action.hide && (
                <MenuItem
                  key={action.label}
                  label={action.label}
                  disabled={action.disabled}
                  disabledText={action.disabledText}
                  onClick={(e) => {
                    closeMenu();
                    action.onActionClick();
                    e.stopPropagation();
                  }}
                />
              )
          )}
        </div>
      )}
    </OutsideClickHandler>
  );
};

Menu.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
  closeMenu: PropTypes.func.isRequired,
  headerLabel: PropTypes.string,
  menuActions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
      disabledText: PropTypes.string,
      hide: PropTypes.bool,
      onActionClick: PropTypes.func.isRequired,
    })
  ).isRequired,
  className: PropTypes.string,
  attachToParent: PropTypes.bool,
};

Menu.defaultProps = {
  closeMenu: noop,
  isMenuOpen: false,
};

export default Menu;
