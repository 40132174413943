export const isTimeframeHidden = (stateName, stateDisplay) => {
    return stateDisplay.toLowerCase() === 'dashboard'
        || stateName.startsWith('audience-')
        || stateName.startsWith('settings.')
        || stateName.startsWith('alerts.');
} 

export const showDatePicker = (stateName, stateDisplay, hasPermission, APP_NAME)  => {
    return (stateDisplay.toLowerCase() === APP_NAME.INTERESTS && hasPermission(['interests custom timeframe']))
    || stateDisplay.toLowerCase() === APP_NAME.INSIGHTS;
}