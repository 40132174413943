import config from '../../infra/config';
const HOST = config.USER_MGMT_API;

export default {
  getGenres: () => ({
    url: `${HOST}/tv/genres`,
  }),
  getNetworks: (channel) => ({
    url: `${HOST}/tv/networks/${channel}`,
  }),
  getShows: (channel) => ({
    url: `${HOST}/tv/shows/${channel}`,
  }),
  getCommercials: (channel) => ({
    url: `${HOST}/tv/commercials/${channel}`,
  }),
  getCommercialsV2: (channel, queryParams) => ({
    url: `${HOST}/tv/v2/commercials/${channel}?${queryParams}`,
  }),
  getHierarchy: (channel) => ({
    url: `${HOST}/tv/hierarchy/${channel}`,
  }),
  getShowsMetadataByids: (ids, channel) => ({
    url: `${HOST}/tv/shows_by_ids/${channel}`,
    payload: { ids },
  }),
  getNielsenNetworksMap: () => ({
    url: `${HOST}/tv/nielsen_networks_map`,
  }),
  getNetworksShows: (networkIds, channel) => ({
    url: `${HOST}/tv/networks_shows/${channel}`,
    payload: { network_ids: networkIds },
  }),
  getKeyWords: (channel, queryParam, geo) => ({
    url: `${HOST}/tv/${channel}${geo ? `/${geo}` : ''}/commercials_keywords?${queryParam}`,
  }),
  getAdvancedGenres: () => ({
    url: `${HOST}/tv/advanced-genres`,
  }),
  getAdvancedNetworks: (channel, geo) => ({
    url: `${HOST}/tv/advanced-networks/${channel}${geo ? `/${geo}` : ''}`,
  }),
  getAdvancedShows: (channel, networks, genres, geo) => ({
    url: `${HOST}/tv/advanced/${channel}`,
    payload: { networks, genres, ...(geo ? { geo } : {}) },
  }),
};
