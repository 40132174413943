export const SIGNS = {
  POST: 'u:',
  MENTION: '@',
  PROGRAM_INTEREST: 'pi:',
  EDITED: '*',
};

export const getTermTextByDisplay = (display) => {
  //@handle mentions  => @handle
  //@handle posts     => u:@handle

  let text = display.trim();

  if (text.startsWith(SIGNS.MENTION)) {
    if (text.indexOf(' mentions') != -1) {
      text = text.replace(' mentions', '');
    } else if (text.indexOf(' posts') != -1) {
      text = SIGNS.POST + text.replace(' posts', '');
    }
  }

  return text;
};
