import React from 'react';
import PropTypes from 'prop-types';
import './TargetForecast.scss';
import Tooltip from '../../../common/Tooltip/Tooltip';
import Icon from '../../../common/Icon/Icon';
import { formatNumberToUnits } from '../../../../utils/NumberUtils';

const TargetForecast = ({ reach, impressions, incOrExc, phrases, topics }) => (
  <div>
    {incOrExc !== 'exclude' ? (
      <div className="target-forecast-component">
        <div id="forecast-title">
          <div id="title-text"> Target Size </div>
          <Tooltip content="This is a 30 day estimation of the size of the audience that may see your ad, and the number of times it may be seen. It is based on the geographic region and the phrases you chose, and the bidable web pages containing those phrases. This estimation may change with time.">
            <Icon iconId="info-italic" className="forecast-info" />
          </Tooltip>
        </div>
        <div>
          <div id="reach">
            <div className="forecast-title"> REACH </div>
            {reach && ((phrases || []).length !== 0 || (topics || []).length !== 0) && (
              <div className="number"> {formatNumberToUnits(reach, 1)} </div>
            )}
          </div>
          <div id="impressions">
            <div className="forecast-title"> IMPRESSIONS </div>
            {impressions && ((phrases || []).length !== 0 || (topics || []).length !== 0) && (
              <div className="number"> {formatNumberToUnits(impressions, 1)} </div>
            )}
          </div>
        </div>
      </div>
    ) : null}
  </div>
);

TargetForecast.propTypes = {
  reach: PropTypes.number.isRequired,
  impressions: PropTypes.number.isRequired,
  incOrExc: PropTypes.string.isRequired,
  phrases: PropTypes.array,
  topics: PropTypes.array,
};

TargetForecast.defaultProps = {
  phrases: [],
  topics: [],
};

export default TargetForecast;
