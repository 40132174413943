import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ iconId, className, height, width }) => (
  <svg className={className} style={{ height, width }}>
    <use
      aria-label={`${iconId} icon`}
      role="img"
      href={`images/images/sprite.svg#${iconId}`}
      style={{ fill: 'currentColor', ':focus': { outline: 'none' } }}
    ></use>
  </svg>
);

Icon.propTypes = {
  iconId: PropTypes.string.isRequired,
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default Icon;
