import config from 'infra/config';
import c from 'infra/utils/common';

module.exports = angular.module(__filename, []).
    service('programService', ['$http', 'mgmtServiceFactory', 'programBooleanLogicService', function ($http, mgmtServiceFactory, programBooleanLogicService) {
        var HOST = config.USER_MGMT_API;
        var service = mgmtServiceFactory.createService('program');
        var currentProgram = null;

        return {
            list: service.list,
            program: service.get,
            sharedUsers: sharedUsers,
            usersToShareWith: usersToShareWith,
            create: createProgram,
            update: updateProgram,
            share: shareProgram,
            remove: removeProgram,
            deleteProgram: service.delete,
            cloneProgram: cloneProgram,
            userProgramsList: getUserPrograms,
            buildProgram: buildProgram,
            current: {
                get: function () {
                    return currentProgram
                }
            }
        };

        ////////////////////////////////

        function buildProgram(obj) {
            return {
                id: obj.id,
                name: c.getProgramName(obj.name),
                brand: {name: obj.brand_name},
                sensitive_content: obj.sensitive_content,
                competitors: obj.competitors.map(function (val) {
                    return {name: val}
                }),
                blacklist_geos: obj.blacklist_geos.map(function (val) {
                    return val['country_code']
                }),
                whitelist_geos: obj.whitelist_geos.map(function (val) {
                    return val['country_code']
                }),
                boolean_logics: obj.program_boolean_logics.map(function (val) {
                    programBooleanLogicService.fixProperties(val.data);
                    return val.data;
                }),
                trending_topics: obj.trending_topics.map(function (val) {
                    return val['topic_id'];
                }),
                program_audience_mappings: obj.program_audience_mapping.map(function (val){
                  return val;
                }),
                program_sources: (obj.program_sources || []).map(function (val) {
                    return _.extend({sources: val.data}, _.pick(val, ['id', 'name', 'updated_at', 'created_at']));
                }),
                program_theme: obj.program_theme
            }
        }

        function getUserPrograms(userId) {
            return $http.get(HOST + '/user_programs/' + userId).then(function (res) {
                return currentProgram = res.data;
            });
        }

        function createProgram(program) {
            return service.create(program).then(buildProgram);
        }

        function updateProgram(id, params) {
            return service.update(id, params).then(buildProgram);
        }

        function sharedUsers(id) {
            return service.customRequest('GET', "/" + id + '/shared_users');
        }

        function usersToShareWith(id) {
            return service.customRequest('GET', "/" + id + '/can_share_with');
        }

        function shareProgram(id, users) {
            return service.customRequest('PUT', "/" + id + '/share', users);
        }

        function removeProgram(id) {
            return service.customRequest('POST', "/" + id + '/remove_myself');
        }

        function cloneProgram(id, newProgramName) {
          return service.customRequest('POST', "/" + id + '/clone', {p_name: newProgramName});
        }
    }]
);
