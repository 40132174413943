const d3 = require("d3"),
      c = require("infra/utils/common"),
      $ = require("jquery"),
      _ = require("lodash");
const moment = require('moment');

const PAD = {
    left: 50,
    right: 20,
    top: 20,
    bottom: 30
};
const HOVER_PAD = 0;
const HOVER_OVERLAY = 6;
const HOVER_HEIGHT = 26;
const HOVER_WIDTH = 45;
const HOVER_MINUTES_WIDTH = 65;
const HOVER_WIDE_WIDTH = 76;
const HOVER_TEXT_PAD = 13;
const HOVER_WIDE_TEXT_PAD = 20;
const HOVER_POINT_RADIUS = 6;
const DEFAULT_POINT_RADIUS = 3;
const DEBUG_MARKER = false;
const DEFAULT_TREND_CLASS = 'trending-default';
const DEFAULT_INTERPOLATION = 'monotone';
const MIN_X_TICK_SPACE = 80;
const MAX_X_TICK_SPACE = 130;
const AXIS_COLOR = 'rgba(255,255,255,0.1)';
const Y_AXIS_DEFAULT_MAX = 103;
const DEFAULT_Y_AXIS = false;
const TERM_CLASSES = ["term-A", "term-B", "term-C", "term-D", "term-E", "term-F", "term-G"];

const getIdString = (id) => '#' + id.replace('*', "\\*");

// Proper solution for the graph boundaries mouse listener
// http://stackoverflow.com/questions/24189217/d3-mouse-move-cross-hair-boundary
// http://stackoverflow.com/questions/16918194/d3-js-mouseover-event-not-working-properly-on-svg-group
// http://jsfiddle.net/BWW66/
// Fixed: http://jsfiddle.net/BWW66/5/
function TrendChart(containing_object, id, configuration) {
    const self = this;

    this.id = id;
    this.containing_object = containing_object || {};
    this.configuration = c.is(configuration) ? configuration : {};
    this.shouldDetectTimeOrDate = this.configuration.detectTimeOrDate === true;
    this.minutes = 'minutes' === this.configuration.type;
    this.days = {isDays: true};
    this.range = [];
    this.wide = true;
    this.letterClicked = '';
    this.Y_AXIS_LINES = 10;
    this.isTimeModeDetected = false;
    this.withSimWebDate = false;
    this.withDottedLine = true;

    this.clean = function() {
        this.infoMap = {};
        this.currentInfo = null;
        this.delta = 0;
        this.move = null;
        this.range = [];
        if (c.is(this.svg)) {
            this.svg.selectAll(".line, g.y-axis, circle, text[letter], .chart-index-rect, .chart-index-text, .timezone").remove();
        }
        if (c.is(this.info)) {
            this.info.selectAll("*").remove();
        }
    };

    this.init = function() {
        this.clean();
        setDefaultTemplates();

        let chart_height = this.configuration.chartHeight || (this.configuration.marginTop ? "calc(100% - " + this.configuration.marginTop + "px)" : "100%");
        let root = $(document.getElementById(this.id));
        root.mouseleave(function() {
            if (c.is(self.currentInfo))
                self.currentInfo.style("opacity", 1e-6);
        });
        this.container = d3.select(getIdString(this.id));
        this.envelop = this.container.append("svg")
            .attr("width", "100%")
            .attr("height", chart_height);
        this.container.select("svg")
            .attr("style","position:absolute;");
        this.svg = this.envelop.append("g")
            .attr("id", "canvas")
            .attr("height", chart_height)
            .attr("width", this.container.style("width"));
        this.svg.append("g")
            .attr("class", "x-axis axis");
        this.domain = this.svg.append("g")
            .attr("stroke", "none")
            .attr("class", "y-axis axis");
        let gy = this.svg.select("g.y-axis.axis");
        gy.select("path.domain").attr("id", "domain");
        this.info = this.svg.append("g")
            .attr("id", "info");
        if (DEBUG_MARKER) {
            this.marker = this.svg.append("line")
                .attr("id", "marker-line")
                .attr("stroke", "red")
                .attr("stroke-width", "1px")
                .attr("x1", 0)
                .attr("x2", 0)
                .attr("y1", 0);
        }
    };

    function setDefaultTemplates() {
        self.configuration.templates = self.configuration.templates || {};
    }

    this.draw = function(data, max, titleTemplateName, wide, with_avg) {
        let self = this;
        this.clean();
        this.wide = wide;
        if (c.is(data) && c.isArray(data.range)) {
            this.range = data.range;
            if(this.shouldDetectTimeOrDate){
                detectTimeOrDate(data.range);
            }
            this.updateLayout(data.range, max, data.midnight);
            this.addLines(data);
            this.addInfo(data, titleTemplateName, with_avg);
            setTimeout(() => self.addInfoTooltips(), 0);
        }
    };

    function detectTimeOrDate (dateRange){
        self.isTimeModeDetected = dateRange.some((date,i) => date.getHours() > 0);
        self.minutes = self.isTimeModeDetected;
        self.days = {isDays: !self.minutes};
    }

    this.updateLayout = function(range, max, minutes) {
        let root = $(document.getElementById(this.id));
        let self = this;
        // left: 50, right: 20, top: 20, bottom: 30
        this.width = root.width() - PAD.left - PAD.right - (this.wide ? 17 : 5);
        this.height = root.height() - PAD.top - PAD.bottom - (this.configuration.marginTop || 0);
        if (DEBUG_MARKER) {
            this.marker.attr("y2", this.height);
        }
        this.svg.attr("transform", "translate(" + PAD.left + "," + PAD.top + ")");
        this.xScale = d3.time.scale().range([0, this.width]);
        this.yScale = d3.scale.linear().range([this.height, HOVER_PAD]);
        this.svg.select("g.x-axis").attr("transform", "translate(0," + this.height + ")");
        this.svg.append("g").attr("class", "y-axis axis");

        const uniqueDateRange = range.map((date) => date.getTime())
                                   .filter((date, i, array) => array.indexOf(date) === i);
        const rangeLength = uniqueDateRange.length;

        let x_tick_space = self.wide ? MAX_X_TICK_SPACE : MIN_X_TICK_SPACE;
        let ticks = (this.width / x_tick_space) | 0;
        ticks = (self.days.isDays && self.days.number < ticks) ? self.days.number : ticks;
        ticks = (ticks > rangeLength) ? rangeLength : ticks;

        if(self.customTimeZone) {
            this.svg.append("text")
              .attr("class", "timezone x label")
              .attr("x", -12)
              .attr("y", self.height + 40)
              .text(self.customTimeZone.label);
        }

        let usedDates = [];
        let prev_date = null;
        let xAxis = d3.svg.axis()
            .scale(this.xScale)
            .orient("bottom")
            .ticks(ticks)
            .tickSize(0)
            .tickPadding(10)
            .tickFormat(function (d, index) {
                if (c.isString(self.configuration.type) && self.minutes && !self.days.isDays) {
                    if (self.wide) {
                        return (d.getMonth() + 1) + '/' + d.getDate() + ' ' + c.getHoursLabel(d, /*minutes*/true);
                    }
                    return c.getHoursLabel(d);
                }
                if(self.isTimeModeDetected && self.minutes){
                    if (!prev_date || prev_date.getUTCDate() < d.getUTCDate()) {
                        prev_date = d;
                        return getDateLabel(d, false, true, true);
                    } else {
                        return getDateLabel(d, false, false, true);
                    }
                }
                const formatted = (d.getMonth() + 1) + '/' + d.getDate();
                if (usedDates.indexOf(formatted) == -1) {
                    usedDates.push(formatted);
                    return formatted;
                }
                return '';
            });

        this.xScale.domain(d3.extent(uniqueDateRange));
        let g = this.svg.selectAll("g.x-axis").call(xAxis);
        let y_axis_max = Y_AXIS_DEFAULT_MAX;
        if (c.isNumber(max) && max > 0) {
            if (self.configuration.force_max || max <= 100) {
                y_axis_max = max + (max / 100) * 3;
            }
        }
        this.yScale.domain([this.configuration.minValue || HOVER_PAD, y_axis_max]);
        var yAxis = d3.svg.axis()
            .scale(this.yScale)
            .orient("left")
            .ticks(this.Y_AXIS_LINES)
            .tickFormat((d) => {
                if (d >= 1000000){
                    return d/1000000 + "M";
                }
                if (d >= 10000) {
                    return d/1000 + 'K';
                }
                return (d == 0 && !this.configuration.minValue) ? '' : d;
            })
            .tickSize(-this.width);
        this.svg.selectAll("g.y-axis").call(yAxis);
        if (!DEFAULT_Y_AXIS) {
            this.svg.selectAll("g.y-axis text")
                .attr("y", -10)
                .attr("x", -11);
            this.svg.selectAll("g.y-axis .tick line")
                .attr("x1", -10)
                .attr("y1", 0);
            this.svg.selectAll("g.y-axis .tick").append("svg:line")
                .attr("x1", (d) => (d == 0 && !this.configuration.minValue) ? 0 : -2)
                .attr("y1", 0)
                .attr("x2", (d) => (d == 0 && !this.configuration.minValue) ? 0 : -30)
                .attr("y2", 0)
                .style("stroke", AXIS_COLOR)
                .attr("class", "small-ticks");
        }
    };

    function getTermClassKey(termClass) {
        if (c.isString(termClass) && TERM_CLASSES.indexOf(termClass) > -1) {
            return termClass;
        }
        return (new Date()).getTime().toString();
    }

    this.addLines = function(data) {
        let self = this;
        let line = d3.svg.line()
            .interpolate(DEFAULT_INTERPOLATION)
            .x((d) => self.xScale(d.date))
            .y((d) => self.yScale(d.value));
        const estimationPoint =  getEstimationPoint(data);

        if(estimationPoint && self.withDottedLine) {
            const splitData = splitDataByPoint(estimationPoint, data);
            addLineBase(splitData[0], line, "Continuousline");
            addLineBase(splitData[1], line, "DashLine").style("stroke-dasharray", "5,5");
        } else {
            addLineBase(data.chart, line, "Continuousline");
        }
    };

    function getEstimationPoint(data) {
        if(_.isEmpty(data.last_closed_time_mark)){
            return null;
        }
        return new Date(data.last_closed_time_mark);
    }

    function splitDataByPoint(point, data) {
        let beforePointData = $.extend(true, [], data.chart);
        let afterPointData = $.extend(true, [], data.chart);

        _.each(data.chart, function(entry, i) {
            afterPointData[i]["series"] = data.chart[i]["series"].filter((d) => d["date"] >= point);
            beforePointData[i]["series"] = data.chart[i]["series"].filter((d) => d["date"] <= point);

            const firstBeforePoint = beforePointData[i]["series"][0];
            const lastAfterPoint = afterPointData[i]["series"][afterPointData[0]["series"].length - 1];
            if(firstBeforePoint && lastAfterPoint && firstBeforePoint.date !== lastAfterPoint.date) {
                beforePointData[i]["series"].unshift(lastAfterPoint);
            }
        });

        return new Array(beforePointData, afterPointData);
    }

    function getDateKeys(date) {
        const pointDate = c.getDateKey(date, self.minutes, '/', true);
        const dateKey = c.getDateKey(date, self.isTimeModeDetected, '-', false);
        const datePkey = c.getDateKey(date, self.isTimeModeDetected, '/', true);
        return {pointDate, dateKey, datePkey};
    }

    function getDateLabel(date, exclude, include, skipUTCSuffix) {
        if(self.customTimeZone) {
            const dateLabel = c.getCustomTimezoneHoursLabel(date, exclude, include, self.customTimeZone.value);
            return skipUTCSuffix ? dateLabel : dateLabel + ` ${self.customTimeZone.label}`;
        }
        return c.getUTCHoursLabel(date, exclude, include);
    }

    function addLineBase(chart, line, name) {
        let path = self.svg.selectAll("path.line" + name).data(chart);
        let pathObject = path.enter().append("path")
            .attr("class", (d) => "line " + (c.is(d.term) && c.isString(d.term.class) ? d.term.class : DEFAULT_TREND_CLASS))
            .attr("id", (d) => getTermClassKey(d.term.class))
            .attr("d", (d) => line(d.series))
            .attr("tkey", (d) => getTermClassKey(d.term.class))
            .on('mouseover', function(evt, node, i) {
                const oc = this.getAttribute('class');
                const cc = 'hover-chart-line ' + oc; // light
                this.setAttribute('class', cc);
                this.setAttribute('class', cc);
                this.setAttribute('oc', oc);
                if (self.configuration.agg_examples_mode) return;
                if (c.is(self.currentInfo)) {
                    var key = this.getAttribute('tkey');
                    self.currentInfo.selectAll("circle").attr("r", function(d) {
                        const p = this.getAttribute('pkey');
                        return key == p ? HOVER_POINT_RADIUS : DEFAULT_POINT_RADIUS;
                    });
                }
            })
            .on('mouseout', function() {
                const cc = this.getAttribute('oc');
                this.setAttribute('class', cc);
                if (self.configuration.agg_examples_mode) return;
                if (c.is(self.currentInfo)) {
                    self.currentInfo.selectAll("circle").attr("r", DEFAULT_POINT_RADIUS);
                }
            })
            .on("click", function() {
                if (self.configuration.agg_examples_mode) return;
                let element = null;
                let value = 0;
                let date = null;
                if (c.is(self.currentInfo)) {
                    const key = this.getAttribute('tkey');
                    if (c.isString(key)) {
                        self.currentInfo.selectAll("circle").attr("pkey", function(d) {
                            const p = this.getAttribute('pkey');
                            if (key == p) {
                                const id = this.getAttribute('id');
                                value = this.getAttribute('consumption');
                                date = this.getAttribute('pdate');
                                if (c.isString(id)) {
                                    element = $(document.getElementById(id));
                                }
                            }
                            return p;
                        });
                    }
                }
                if (value > 0) {
                    if (!c.is(element)) {
                        element = $('#canvas');
                    }
                    self.containing_object.showPopup(element, c.getKey(element.attr("term_id"), element.attr("term_text")), value, date);
                }
            });
        if (!self.configuration.agg_examples_mode) {
            pathObject.style("cursor","pointer")
        }
        return pathObject;
    }

    function calcHoverXLocation(caption, cx ,maxCx) {
        let hoverXLocation = Math.ceil(caption.length * 7 / 2);
        if (cx < hoverXLocation) {
            hoverXLocation = caption.length > 6 ? 40 : 25;
        } else if (parseFloat(cx + hoverXLocation) > maxCx) {
            hoverXLocation = -(maxCx - cx - caption.length * 7 - 5);
        }
        return hoverXLocation;
    }

    function isCutoffDate(tickDateKey, dates) {
        return self.withFbCutoffDate && tickDateKey === c.FACEBOOK_CUTOFF_DATE ||
               self.withSimWebDate  && tickDateKey === c.getSimWebCutoffDate(dates);
    }

    function getHoverLabelWidth(isCutoffDate, cutoffType, widthAppendix, date) {
        let width;
        let hoverWidth;

        if(self.minutes) {
            hoverWidth = HOVER_MINUTES_WIDTH;
        } else if(self.wide) {
            hoverWidth = HOVER_WIDE_WIDTH;
        } else {
            hoverWidth = HOVER_WIDTH * _.min([date.length, 4]) / 4;
        }

        width = isCutoffDate ? c.getCutoffHoverWidth(cutoffType) : hoverWidth + widthAppendix + 4;

        if(self.customTimeZone?.value) {
            const widthOffset = Math.abs(self.customTimeZone?.value/1000/60/60) > 10 ? 13 : 11;
            width = width + widthOffset;
        }

        return width;
    }

    this.addInfo = function(data, titleTemplateName, with_avg) {
        let previous_x_pos = 0, num_dates = 0;
        _.each(data.chart, function(entry, i) {
            const key = getTermClassKey(entry.term.class);
            let avgData = _.find(data.averages, ['class', key]) || {};
            _.each(entry.series, function(tick, j) {
                if (c.isDate(tick.date)) {
                    let infoGroup = c.getMapItemByDate(self.infoMap, tick.date, self.minutes, false), max_date_x_pos;
                    const tickDateKey = c.getDateKey(tick.date, self.minutes, '-', false);
                    if (!c.is(infoGroup)) {
                        infoGroup = self.info.append("g")
                            .attr("id", 'group-' + tickDateKey)
                            .style("opacity", 1e-6);
                        //TODO Confirm the item is not empty; infoGroup
                        c.addMapItemByDate(self.infoMap, infoGroup, tick.date, self.minutes);
                    }
                    if (i == 0) {
                        num_dates++;
                        const date_x_pos = self.xScale(tick.date);
                        self.delta = previous_x_pos > 0 ? (self.minutes ? (date_x_pos - previous_x_pos) : (previous_x_pos - date_x_pos)) + self.delta : self.delta;
                        previous_x_pos = date_x_pos;

                        var date;
                        var widthAppendix = 0;
                        if(self.days.isDays){
                            date = (1 + tick.date.getMonth()) + '/' + tick.date.getDate();
                        }
                        else if(self.isTimeModeDetected) {
                            date = getDateLabel(tick.date, false, true);
                            widthAppendix = date.length * 2 + 8;
                        }
                        else{
                            date = getDateLabel(tick.date, false, self.wide);
                        }

                        const is_cutoff_date = isCutoffDate(tickDateKey, entry.series);
                        const cutoffType = c.getCutoffType(tickDateKey, entry.series);
                        max_date_x_pos = parseInt(self.xScale(_.max(_.map(entry.series, "date")))) + 20 - date.length * 8;
                        const hover_width = is_cutoff_date ? c.getCutoffHoverWidth(cutoffType) : (self.wide ? HOVER_WIDE_WIDTH : HOVER_WIDTH);
                        let tooltipX = date_x_pos - (hover_width + widthAppendix * 2)/2 + widthAppendix/2;
                        if (tooltipX >= max_date_x_pos){
                            tooltipX = max_date_x_pos;
                        }

                        const ls = (date.length - 2) * (self.minutes && !self.days.isDays ? 1.5 : 2) + widthAppendix / 4 + 3;
                        const text_pad = is_cutoff_date || self.wide ? HOVER_WIDE_TEXT_PAD : HOVER_TEXT_PAD;
                        let text_x_pos = date_x_pos - (hover_width/2 - text_pad) - ls;
                        if (text_x_pos >= max_date_x_pos) {
                            text_x_pos = max_date_x_pos;
                        }

                        if(is_cutoff_date) {
                            if(text_x_pos + hover_width > max_date_x_pos) {
                                tooltipX -= (text_x_pos + hover_width - max_date_x_pos - c.CUTOFF_EDGE_OFFSET);
                                text_x_pos -= (text_x_pos + hover_width - max_date_x_pos - c.CUTOFF_EDGE_OFFSET);
                            }

                            if(date_x_pos - hover_width/2 < 0) {
                                tooltipX = 5;
                                text_x_pos = 15;
                            }
                        }

                        let line;
                        if(is_cutoff_date) {
                            line = self.info.append("line")
                                .attr("stroke", "#ababab")
                                .attr("stroke-dasharray", "10,5")
                                .attr("x1", date_x_pos)
                                .attr("x2", date_x_pos)
                                .attr("stroke-width", 1)
                                .attr("y1", 0)
                                .attr("y2", self.height);
                        } else {
                            line = infoGroup.append("line")
                                .attr("stroke", "#ababab")
                                .attr("x1", date_x_pos)
                                .attr("x2", date_x_pos)
                                .attr("stroke-width", 1)
                                .attr("y1", 0)
                                .attr("y2", self.height);
                        }
                        let tooltip = infoGroup.append("rect")
                            .attr("id", "#date-label-background" + date)
                            .attr("x", tooltipX)
                            .attr("rx", 4)
                            .attr("y", HOVER_OVERLAY - HOVER_HEIGHT)
                            .attr("ry", 4)
                            .attr("width", getHoverLabelWidth(is_cutoff_date, cutoffType, widthAppendix, date))
                            .attr("height", HOVER_HEIGHT)
                            .attr("class", "hover-date");

                        let text = infoGroup.append("text")
                            .attr("id", "#date-label-" + date)
                            .attr("x", text_x_pos)
                            .attr("y", -2)
                            .attr("class", "hover-text")
                            .text(is_cutoff_date ? c.getCutoffText(cutoffType) : date);
                    }

                    if (c.isNumber(tick.value)) {
                        const {pointDate, dateKey} = getDateKeys(tick.date);
                        let point = infoGroup.append("circle")
                            .attr("class", entry.term.class)
                            .attr("id", (d) => self.id + '-point-' + key + '-' + c.getDateKey(tick.date, self.minutes, '-', false))
                            .attr("pkey", key)
                            .attr("consumption", tick.value)
                            .attr("term_id", entry.term.id)
                            .attr("term_text", entry.term.text)
                            .attr("pdate", pointDate)
                            .attr("r", DEFAULT_POINT_RADIUS)
                            .attr("cx", (d) => self.xScale(tick.date))
                            .attr("cy", (d) => self.yScale(tick.value))
                            .on("mouseover", function() {
                                d3.select(document.getElementById("date-label-" + date)).style("display", "none");
                                d3.select(document.getElementById("date-label-background" + date)).style("display", "none");
                                if(self.configuration.agg_examples_mode) {
                                    d3.select("#chart-index-rect-" + entry.term.class + "-" + dateKey).style("display", "block");
                                    d3.select("#chart-index-text-" + entry.term.class + "-" + dateKey).style("display", "block");
                                }
                                point.attr("r", HOVER_POINT_RADIUS);
                            })
                            .on("mousemove", function() {
                            })
                            .on("mouseout", function() {
                                if(self.configuration.agg_examples_mode) {
                                    d3.select("#chart-index-rect-" + entry.term.class + "-" + dateKey).style("display", "none");
                                    d3.select("#chart-index-text-" + entry.term.class + "-" + dateKey).style("display", "none");
                                }
                                point.attr("r", DEFAULT_POINT_RADIUS);
                            })
                            .on("click", function(tick) {
                                if(self.configuration.agg_examples_mode) return;

                                var element  = $(document.getElementById(this.id));
                                if (!c.is(element)) {
                                    element = $('#canvas');
                                }
                                var date = this.getAttribute('pdate');
                                var value = this.getAttribute('consumption');
                                if (value > 0) {
                                    self.containing_object.showPopup(element, c.getKey(entry.term.id, entry.term.text), value, date);
                                }
                            });
                        let consumption = point.attr('consumption');
                        let circlex = parseInt(point.attr('cx'));

                        if(!self.configuration.agg_examples_mode){
                            point.style("cursor","pointer")
                        }

                        let consumptionAvg = Math.round((consumption / (avgData.original || avgData.value || 1)) * 100 - 100);
                        let title = '';
                        if (with_avg != false) {
                          const titleTemplate = self.configuration.templates[titleTemplateName];
                          title = titleTemplate({consumption: parseFloat(consumption), consumptionAvg: parseInt(consumptionAvg)});
                        }
                        max_date_x_pos = parseInt(self.xScale(_.max(_.map(entry.series, "date")))) + 20;
                        let hoverXLocation = calcHoverXLocation(title, circlex, max_date_x_pos);

                        self.svg.append("rect")
                            .attr("id", "chart-index-rect-" + entry.term.class + "-" + dateKey)
                            .attr("class", "chart-index-rect")
                            .attr("x", circlex - hoverXLocation)
                            .attr("y", -20)
                            .attr("rx", 4)
                            .attr("ry", 4)
                            .attr("width", title.length * 7 - 10)
                            .attr("height", 26)
                            .style("display", "none");

                        self.svg.append("text")
                            .attr("id", "chart-index-text-" + entry.term.class + "-" + dateKey)
                            .attr("class", "chart-index-text")
                            .attr("x", circlex - hoverXLocation + 5)
                            .attr("y", -2)
                            .text(title)
                            .style("display", "none");
                    }
                } else {
                    console.log('Invalid date: ' + JSON.stringify(tick));
                }
            });
            if (DEBUG_MARKER) {
                console.log('[Tooltip points] map: ' + JSON.stringify(self.infoMap, function(key, value) {
                    if (c.isArray(value) || c.isString(value) || c.isNumber(value))
                        return value;
                    else if (key == 'undefined' || key == '' || key == null)
                        return value;
                    else if (!isNaN(parseInt(key))) {
                        if (key > 2012 && key < 2016) {
                            return value;
                        } else if (key > 0 && key < 13) {
                            var valid = true;
                            _.each(value, function(entry, index) {
                                valid = value && (c.isArray(entry) && !isNaN(parseInt(index)) && key > 0 && key < 32);
                            });
                            if (valid) return value;
                        }
                    }
                    return '...';
                }));
            }
        });
        this.delta = (num_dates > 0) ? (this.delta/num_dates)/2 : this.delta;
    };

    this.findDateItem = function(date, later) {
        let result_item = c.getMapItemByDate(this.infoMap, date, this.minutes, false);
        if (!c.is(result_item)) {
            var previous_date, next_date;
            _.each (this.range, function(entry, index) {
                if (date > entry) {
                    previous_date = (previous_date && previous_date > entry) ? previous_date : entry;
                } else {
                    next_date = (next_date && next_date < entry) ? next_date : entry;
                }
            });
            previous_date = c.is(previous_date) ? previous_date : next_date;
            next_date = c.is(next_date) ? next_date : previous_date;
            if (c.is(next_date) && c.is(previous_date)) {
                let previous_date_offset = date.getTime() - previous_date.getTime();
                let next_date_offset = next_date.getTime() - date.getTime();
                var closest_date = previous_date_offset > next_date_offset ? next_date : previous_date;
                result_item = c.getMapItemByDate(this.infoMap, closest_date, this.minutes, false);
            }
        }
        return result_item;
    };

    this.updateTooltip = function(infoGroup) {
        const infoGroupExists = c.is(infoGroup);
        if (infoGroupExists) {
            infoGroup.style("opacity", 1);
            if (infoGroup != this.currentInfo) {
                if (c.is(this.currentInfo)) {
                    this.currentInfo.style("opacity", 1e-6);
                }
                this.currentInfo = infoGroup;
            }
        }
        return infoGroupExists;
    };

    this.addInfoTooltips = function() {
        let self = this;
        this.container.on("mousemove", function(e) {
            try {
                const fx = d3.mouse(this)[0] - PAD.left;
                if (DEBUG_MARKER) {
                    self.marker.attr("x1", d3.mouse(this)[0] - PAD.left).attr("x2", fx);
                }
                var date = self.xScale.invert(fx + self.delta/2);
                self.updateTooltip(self.findDateItem(date, (fx - self.move) > 0));
                self.move = fx;
            } catch (e) {}
        });
    };

    this.addCircles = function(data, circle_text){
        let self = this;
        let previous_letter = '';
        _.each(data,function(entry,k){
            if(entry.letter == previous_letter){
                return;
            }
            previous_letter = entry.letter;
            const entryDate = new Date(entry.date);
            if(!self.isTimeModeDetected){
                entryDate.setTime(entryDate.getTime() + (entryDate.getTimezoneOffset() + 60) * 60000);
            }
            const {dateKey,datePkey} = getDateKeys(entryDate);
            let currentCircle = d3.select("circle[pdate=\"" + datePkey + "\"][pkey=\"" + entry.class + "\"]");
            if (!currentCircle.empty()) {
                currentCircle.attr("display", "none");
                const circlex = parseFloat(currentCircle.attr("cx"));
                const circley = parseFloat(currentCircle.attr("cy"));
                const circleClass = currentCircle.attr("class");
                const consumption = currentCircle.attr("consumption");
                self.svg.append("circle")
                    .attr("id", "trend-chart-circle-" + entry.class + "-" + dateKey)
                    .attr("class", circleClass + " trend-chart-circle")
                    .attr("stroke-width", 2)
                    .attr("cx", circlex)
                    .attr("cy", circley)
                    .attr("letter", entry.letter)
                    .attr("consumption", consumption)
                    .on("mouseover", function () {
                        let element = d3.select(this);
                        self.highlightSwitchIdValues(element, true, "circle", "text");
                        const currentLetter = element.attr("letter");
                        d3.select("#chart-index-rect-" + entry.class + "-" + dateKey).style("display", "block");
                        d3.select("#chart-index-text-" + entry.class + "-" + dateKey).style("display", "block");
                        self.emitEvent("chartCircleMouseover",currentLetter);
                        self.moveCircleToFront(currentLetter);
                    }).on("mouseout", function () {
                        let element = d3.select(this);
                        const currentLetter = element.attr("letter");
                        if (currentLetter != self.letterClicked) {
                            self.highlightSwitchIdValues(element, false, "circle", "text");
                        }
                        d3.select("#chart-index-rect-" + entry.class + "-" + dateKey).style("display", "none");
                        d3.select("#chart-index-text-" + entry.class + "-" + dateKey).style("display", "none");
                        self.emitEvent("chartCircleMouseout",currentLetter);
                    }).on("click", function () {
                        self.highlightCircleFromLetter(self.letterClicked, false);
                        let element = d3.select(this);
                        const currentLetter = element.attr("letter");
                        self.letterClicked = currentLetter;
                        self.highlightSwitchIdValues(element, true, "circle", "text");
                        self.moveCircleToFront(currentLetter);
                        self.emitEvent("chartCircleClick",{letter: currentLetter, class: entry.class});
                        d3.event.stopPropagation();
                    });

                if (circle_text != false) {
                    self.svg.append("text")
                        .attr("id", "trend-chart-text-" + entry.class + "-" + dateKey)
                        .attr("class", circleClass + " trend-chart-text")
                        .attr("x", circlex)
                        .attr("y", circley)
                        .text(entry.letter)
                        .attr("letter", entry.letter)
                        .attr("consumption", consumption)
                        .on("mouseover", function () {
                            let element = d3.select(this);
                            const currentLetter = element.attr("letter");
                            self.highlightSwitchIdValues(element, true, "text", "circle");
                            d3.select("#chart-index-rect-" + entry.class + "-" + dateKey).style("display", "block");
                            d3.select("#chart-index-text-" + entry.class + "-" + dateKey).style("display", "block");
                            self.emitEvent("chartCircleMouseover",currentLetter);
                            self.moveCircleToFront(currentLetter);
                        }).on("mouseout", function () {
                            let element = d3.select(this);
                            const currentLetter = element.attr("letter");
                            if (currentLetter != self.letterClicked) {
                                self.highlightSwitchIdValues(element, false, "text", "circle");
                            }
                            d3.select("#chart-index-rect-" + entry.class + "-" + dateKey).style("display", "none");
                            d3.select("#chart-index-text-" + entry.class + "-" + dateKey).style("display", "none");
                            self.emitEvent("chartCircleMouseout",currentLetter);
                        }).on("click", function () {
                            self.highlightCircleFromLetter(self.letterClicked, false);
                            let element = d3.select(this);
                            const currentLetter = element.attr("letter");
                            self.letterClicked = currentLetter;
                            self.highlightSwitchIdValues(element, true, "text", "circle");
                            self.moveCircleToFront(currentLetter);
                            self.emitEvent("chartCircleClick",{letter: currentLetter, class: entry.class});
                            d3.event.stopPropagation();
                        });
                }

                if (self.letterClicked) {
                    self.highlightCircleFromLetter(self.letterClicked, true);
                }
            }
        });
    };

    this.resetHighlightedCircle = function() {
        this.highlightCircleFromLetter(this.letterClicked, false);
        this.letterClicked = '';
    };

    this.highlightSwitchIdValues = function(element, highlightOn, searchValue, replaceValue) {
        element.classed("highlighted", highlightOn);
        const elementId = element.attr("id").replace(searchValue, replaceValue);
        d3.select("#" + elementId).classed("highlighted", highlightOn);
    };

    this.highlightCircleFromLetter = function(letter, highlightOn) {
        if(letter) {
            d3.selectAll(".trend-chart-circle[letter=" + letter + "], .trend-chart-text[letter=" + letter + "]").classed("highlighted", highlightOn);
        }
    };

    this.moveCircleToFront = function(letter) {
        d3.select(".trend-chart-circle[letter=" + letter + "]").moveToFront();
        d3.select(".trend-chart-text[letter=" + letter + "]").moveToFront();
    };

    this.setDays = function(unit) {
        this.days = {isDays: unit >= 7, number: unit};
    };

    this.emitEvent = function(eventType,data){
        if(self.containing_object.hasOwnProperty('emitEventForChartCircle') &&
           typeof self.containing_object.emitEventForChartCircle == 'function'){
            self.containing_object.emitEventForChartCircle(eventType,data);
        }
    };

    this.setWithSimWebDate = function(value) {
        self.withSimWebDate = value;
    };

    this.setWithDottedLine = function(value) {
        self.withDottedLine = value;
    };

    this.setCustomTimezone = function(value) {
        self.customTimeZone = value;
    };

    this.init();
}

module.exports = TrendChart;
