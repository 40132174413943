import React, { useState } from 'react';
import { arrayOf, bool, func, object, shape, string } from 'prop-types';
import './ReviewSensitivityModal.scss';
import Checkbox from '../../../../../common/Checkbox/Checkbox';
import Separator from '../../../../../common/Separator/Separator';
import TextArea from '../../../../../common/TextArea/TextArea';
import TabSelector from '../../../../../common/TabSelector/TabSelector';
import ModalWithConfirmationButtons from '../../../../../common/Modal/ModalWithConfirmationButtons/ModalWithConfirmationButtons';

const SensitiveSegmentSection = ({ type, phrases }) => (
  <div className="sensitive-segment">
    <div className="type">Sensitive content in {type}:</div>
    <TextArea text={[...phrases].join(', ')} lineHeight="1.2" lines={2} className="phrases" />
  </div>
);

SensitiveSegmentSection.propTypes = {
  type: string.isRequired,
  phrases: arrayOf(string).isRequired,
};

const ReviewSensitivityModal = ({
  isOpen,
  data,
  modalTitle,
  keepPhrasesText,
  removePhrasesText,
  onCancel,
  onSubmit,
  segmentMap,
}) => {
  const multipleTypes = data.length > 1;
  const [selectedTab, setSelectedTab] = useState(data[0]);
  const [checkedTypes, setCheckedTypes] = useState(
    data.reduce((res, { value }) => {
      res[value] = true;
      return res;
    }, {})
  );
  const anyChecked = Object.values(checkedTypes).some((v) => v);
  const handleKeepPhrases = () => {
    const res = data.reduce((res, { value }) => {
      res[value] = false;
      return res;
    }, {});
    onSubmit(res);
  };
  const handleRemovePhrases = () => {
    const res = multipleTypes ? checkedTypes : { [selectedTab.value]: true };
    onSubmit(res);
  };
  const regions = data.map(({ region }) => region);
  const reasons = data.map(({ label }) => label);
  return (
    <div className="review-sensitivity-modal-component">
      <ModalWithConfirmationButtons
        cancelLabel={keepPhrasesText}
        isLightTheme={true}
        isOpen={isOpen}
        isCancelDisabled={multipleTypes && anyChecked}
        isCancelTransparent={false}
        isSubmitDisabled={multipleTypes && !anyChecked}
        isXDisabled={false}
        modalTitle={modalTitle}
        submitLabel={removePhrasesText}
        width={562}
        onCancel={handleKeepPhrases}
        onEscKeyPress={onCancel}
        onEnterKeyPress={handleRemovePhrases}
        onSubmit={handleRemovePhrases}
        onXClick={onCancel}
        confirmationButtonsWidth="auto"
      >
        <div className="text">
          Your audience will result in no delivery for <span className="bold">{regions.join(' and/or ')}</span> users
          due to {reasons.join(' and/or ')} regulations on the below keywords. By selecting &quot;{removePhrasesText}
          &quot;, this will remove the following keywords and allow delivery in the chosen regions. By selecting &quot;
          {keepPhrasesText}&quot;, this will allow for delivery of all keywords outside of the{' '}
          {regions.join(' and/or ')}.
        </div>
        <div className="body">
          {multipleTypes ? (
            <TabSelector
              isDarkTheme={false}
              tabs={data}
              selectedTab={selectedTab}
              tabLocation={'start'}
              onSelect={setSelectedTab}
            />
          ) : (
            <Separator className="content-separator" />
          )}
          <div className="panel">
            {Object.entries(selectedTab.segments).map(([type, phrases]) => (
              <SensitiveSegmentSection
                key={`${selectedTab.value}-${type}`}
                type={segmentMap[type].label}
                phrases={[...phrases]}
              />
            ))}
          </div>
          {multipleTypes
            ? [
                <Separator className="content-separator" key="separator" />,
                <div key="options" className="options">
                  {data.map(({ value, region }) => (
                    <Checkbox
                      key={value}
                      label={`Remove sensitive attributes to allow delivery for ${region} users`}
                      isSelected={checkedTypes[value]}
                      onToggle={(newValue) =>
                        setCheckedTypes({
                          ...checkedTypes,
                          [value]: newValue,
                        })
                      }
                    />
                  ))}
                </div>,
              ]
            : ''}
        </div>
      </ModalWithConfirmationButtons>
    </div>
  );
};

ReviewSensitivityModal.propTypes = {
  isOpen: bool.isRequired,
  data: arrayOf(
    shape({
      value: string.isRequired,
      label: string.isRequired,
      region: string.isRequired,
      segments: object.isRequired,
    })
  ).isRequired,
  modalTitle: string.isRequired,
  keepPhrasesText: string.isRequired,
  removePhrasesText: string.isRequired,
  onCancel: func.isRequired,
  onSubmit: func.isRequired,
  segmentMap: object.isRequired,
};

export default ReviewSensitivityModal;
