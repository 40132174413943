import { getUserAllActivatedSegments } from '../../react/services/AudienceService.js';

angular.module(__filename, []).controller('ActivatedAudienceSegmentsController',
    ['$scope', 'util',
        function ($scope, util) {
            $scope.columns = [
                {label: 'Segment', field: 'name', sortable: true},
                {label: 'Program', field: 'program.name', sortable: true},
                {label: 'Created By', field: 'created_by', sortable: true},
                {label: 'Created On', field: 'created_at', sortable: true},
                {label: 'Market', field: 'market.label', sortable: true},
                {label: 'Advertiser', field: 'advertiser.label', sortable: true},
                {label: 'Activated On', field: 'deterministic_activated_date', sortable: true},
                {label: 'Amplified On', field: 'amplified_activated_date', sortable: true},
                {label: 'Amplified Threshold', field: 'amplified_threshold', sortable: true},
            ];

            const populateSegmentsTable = async () => {
                const {segments} = await getUserAllActivatedSegments($scope.$root.user.id);
                $scope.segments = segments;
                $scope.gotData = true;
                $scope.$apply();
            }

            populateSegmentsTable();

            $scope.programText = function (program) {
                if(util.isMyInterestsProgram(program)) return 'My interests';
                return util.isMySegmentsProgram(program) ? 'My segments' : program.name;
            };

            $scope.predicate = $scope.columns[0].field;
            $scope.reverse = false;
            $scope.order = function (predicate) {
                $scope.reverse = ($scope.predicate === predicate) ? !$scope.reverse : false;
                $scope.predicate = predicate;
            };
            
        }
    ]).stateConfig = {
    name: 'activated_audience_segments',
    url: '/activated_audience_segments',
    template: require('./activated_audience_segments.html'),
    controller: 'ActivatedAudienceSegmentsController',
    data: {
        permissions: []
    },
    display: 'Activated Audience Segments'
};

module.exports = angular.module(__filename);
