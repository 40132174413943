import config from 'infra/config';

module.exports = angular.module(__filename, [])
    .service('snapshot', ['$http', '$rootScope', function ($http, $rootScope) {
        var base_url = config.SNAPSHOT_API + '/snapshot';
        return {
            get: function (user, program) {
                var url = base_url + '/' + user.id;
                if (program) {
                    url += '/' + program.id;
                }
                return $http.get(url).then(function (res) {
                    return res.data;
                });
            },
            set: function (user, current) {
                if (current == {}) {
                    return {};
                }

                var params = current;
                params.u_id = user.id;
                if (current.program) {
                    params.p_id = current.program.id;
                }else{
                    try{
                        params.p_id = $rootScope.context.program.id;
                    }catch(er){}
                }
                
                return $http.post(base_url, params).then(function (res) {
                    return res.data;
                });
            }
        }
    }]
);
