import * as MixpanelCommon from '../../../react/infra/mixpanel/MixpanelCommon.js';

module.exports = angular.module(__filename, [
    require('common/radiogroup-slider/radiogroup-slider').name
]).service('interestsModal', ['ModalService', '$rootScope', '$state', 'emailService',
    function (ModalService, $rootScope, $state, emailService) {
    this.showModal = showModal;

    function showModal(interests, isNewSegment, disableGeo, channel, params) {
        return ModalService.showModal({
            template: require('./interests-modal.html'),
            inputs: {interests: interests, isNewSegment: isNewSegment, channel: channel, params: params},
            controller: ['$scope', 'close', 'interests', 'TermsMold', 'isNewSegment', 'channel',  'params', InterestsModalCtrl]
        });
    }

    function InterestsModalCtrl($scope, close, interests, TermsMold, isNewSegment, channel, params) {
        $scope.isNewSegment = interests == null;
        $scope.interests = _.extend({required: null, included: null, excluded: null}, interests);
        $scope.close = close;
        $scope.termsMold = new TermsMold();
        $scope.debugLimit = params.debugUser && channel === 'au_telco';
        $scope.validate = $scope.termsMold.validate;

        $scope.isValid = function () {
            return $scope.interests && _.reduce(
              ['required', 'included', 'excluded'],
              (res, field) => res || ($scope.interests[field] && getValidInterests($scope.interests[field]).length > 0), false);
        };

        function onInterestsChanged(interestKey, tags){
            $scope.interests[interestKey] = tags;
        }

        function getValidInterests(interestsArray){
            return (interestsArray || []).filter(val => !val.invalid);
        }

        $scope.onTagPlusButtonClick = (interestKey, tagValue) => {
            $scope.interests[interestKey] = _.reject($scope.interests[interestKey], {text: tagValue});
            $scope.trackRemovedPhrase();
            const language = $rootScope.context.current._language_mold.getLanguage($state, $rootScope.context).value;
            emailService.trackPhrase($rootScope.user.id, tagValue, language);
        }

        $scope.trackRemovedPhrase = (removedPhrase) => {
            const eventName = 'Overview - Input Bar Phrase Deleted';
            MixpanelCommon.trackInputBar(eventName, removedPhrase);
        }

        $scope.save = function () {
            this.interests.required = getValidInterests(this.interests.required);
            this.interests.included = getValidInterests(this.interests.included);
            this.interests.excluded = getValidInterests(this.interests.excluded);

            close(_.extend(this.interests, {
                type: "interests",
                levelOfIntent: this.interests.levelOfIntent,
                operand: this.interests.operand
            }));
        }

        $scope.showTagPlusButton = (tag) => tag.invalid;

        $scope.onRequiredTagsChange = (tags) => {
            onInterestsChanged('required', tags);
        }

        $scope.onIncludedTagsChange = (tags) => {
            onInterestsChanged('included', tags);
        }

        $scope.onExcludedTagsChange = (tags) => {
            onInterestsChanged('excluded', tags);
        }

        $scope.onRequiredAddTag = (tags) => {
            $scope.onTagPlusButtonClick('required', tags);
        }

        $scope.onIncludedAddTag = (tags) => {
            $scope.onTagPlusButtonClick('included', tags);
        }

        $scope.onExcludedAddTag = (tags) => {
            $scope.onTagPlusButtonClick('excluded', tags);
        }
    }
}]);
