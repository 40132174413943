import { showDatePicker } from '../../common/app-common-elements/timeframe/timeframe-utils';
function BoardController($timeout, $scope, $rootScope, $state, abiPermissions, discoveryStreamService, APP_NAME) {
    $rootScope.createExcelWorkbook = discoveryStreamService.getWorkbook;

    $scope.sortByValues = [
        {value:'interest', label: 'Top'},
        {value:'time', label: 'Recent'}
    ];
    $scope.sortBy = $scope.sortByValues[0].value;

    $scope.toggleSortBy =(sortBy)=>{
        $scope.sortBy = sortBy;
        $timeout(()=>$scope.$apply());
    }

    $scope.isCustomTimeframeHidden = () => !showDatePicker($state.current.name, $state.current.display, abiPermissions.hasPermission, APP_NAME);

}

BoardController.$inject = ["$timeout", "$scope" , "$rootScope", "$state", "abiPermissions", "discoveryStreamService", "APP_NAME"];

module.exports.stateConfig = {
    name: "board",
    url: "/board",
    template: require("./board.interests.html"),
    display: "Interests",
    data: {
      permissions: ['content board']
    },
    controller: BoardController,
    context:{}
};
