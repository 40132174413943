/*all backend <-> UI data conversions

main functions
==============
* targetToServer
* targetToUI
* targetToCurrentTarget - UI <-> UI conversion for target right pane


target_type: inventory_lists
============================

not saved as targets in DB
converted to target for UI

*/
import * as TargetComplianceService from 'react/services/TargetComplianceService';

module.exports = angular.module(__filename, [])
    .service('TargetData', ['CHANNEL', '$http', '$timeout', 'notificator', 'TARGET_CONSTS', 'MARKET_CONTEXT',
    function (CHANNEL, $http, $timeout, notificator, TARGET_CONSTS, MARKET_CONTEXT) {

    return {
        markets: null,

        targetToServer: function(params){
            //change data to fit server model

            let {action, target} = params;

            //topical
            if(typeof target.topics==="string"){
                target.topics = target.topics.split(",").map(function(cell){return Number(cell)});
            }

            target.limitTopics = (target.topics && target.topics.length) || target.limitTopics ? true : false;
            target.topical = target.limitTopics;

            //activated_to
            target.activated_to = target.activated_to || [];

            //dynamic
            target.dynamic = target.dynamic ? true : false;

            //source
            target.source = 'bi_target';

            this.saveSeedsData(target);

            // first_party_audience filter
            if(target.query && target.query.user_first_party_audience){
                target.query.user_first_party_audience = target.query.user_first_party_audience.map( function (val) { return val.value? val.value : val});
            }

            //remove phrase params
            if(action != "called-by-target-to-ui"){
                for(let i in target.results||{}){
                    let arr = target.results[i];
                    arr.forEach((p)=>{
                        this.removeParams.phrases.forEach((param)=>{
                            delete p[param];
                        });
                    });
                }

                //remove target display params
                this.removeParams.target.forEach((param)=>{
                    delete target[param];
                });

                this.removeParams.targetType[target.target_type].forEach((param)=>{
                    delete target[param];
                });

            }


            return target;

        },

        targetToUI: function(params){

            //convert backend values to UI

            let {action, target, context } = params;

            if(action==='edit-target'){ //calculate inner values like phrases

                this.targetToServer({target: target, action: 'called-by-target-to-ui'});
                this.convertManualPhrases(target.results?.phrases || []);
                this.convertAudience(target, context);
            }

            this.addDisplayValues(target);
        },

        addDisplayValues: function(target){

            //add target display values

            let activated_to = Array.isArray(target.activated_to) ? target.activated_to[0] : target.activated_to;

            /*activated_to - complex, as we need to sort by:
            1 - market A
            2 - market B
            x - market x
            All Markets
            Demo
            Not Activated
            */
            var MAX_ID=10000;
            if (activated_to === undefined){
                target.activated_to_display = "Not Activated";
                target.activated_to_sorted = MAX_ID+2;
            }else {
                let market_id = target.market_id;
                if (market_id === undefined || market_id === ""){
                    target.activated_to_display = activated_to;
                    target.activated_to_sorted = MAX_ID+1;
                }else if (market_id === MARKET_CONTEXT.ALL_MARKETS_ID){
                    target.activated_to_display = "All Markets";
                    target.activated_to_sorted = MAX_ID+0;
                }else {
                    target.activated_to_display = market_id + " - " + (this.markets ? this.markets[market_id] : activated_to);
                    target.activated_to_sorted = 1*market_id;
                }
            }

            target.id = target._id; //for quick-table

            target.full_name = target.name;
            target.full_id = target._id;
            target.channel_display = CHANNEL.nameDisplay(target);

            target.date_added_formated = dateFormat(target.date_added);
            target.date_updated_formated = dateFormat(target.date_updated);
            target.target_type_display = this.targetTypeDisplay(target);
            target.updated_by = target.updated_by || ' ';

            function dateFormat(d){
                return moment(d).format("MMM DD, YYYY (HH:mm:ss A)");
            }
        },

        targetTypeDisplay: function(target){
            var target_type = target.target_type || TARGET_CONSTS.DEFAULT_TARGET_TYPE;

            if(target.dynamic){
                if(this.isTypeAll(target)){
                    return "Trends";
                }else{
                    return "Custom Trends";
                }
            }else{
                if(target_type===TARGET_CONSTS.AUDIENCE_TARGET_TYPE){
                    return "Audience Interests";
                }else if(target_type===TARGET_CONSTS.INVENTORY_LISTS_TARGET_TYPE){
                    return "Inventory List";
                }else if(this.isTypeAll(target)){
                    return "Keywords";
                }else{
                    return "Custom Keywords";
                }
            }

        },

        getTargetType: function($state, tab){

            if(!$state){//all = default type
                return TARGET_CONSTS.DEFAULT_TARGET_TYPE;
            }

            var page = $state.current.name.toLowerCase();

            if(page.includes("audience")){
                return tab=='websites' ?
                    TARGET_CONSTS.INVENTORY_LISTS_TARGET_TYPE :
                    TARGET_CONSTS.AUDIENCE_TARGET_TYPE;
            }else{
                return TARGET_CONSTS.DEFAULT_TARGET_TYPE;
            }
        },

        isTypeAll: function(target) {
            return target && target.market_type && target.market_type.toLowerCase()==="all";
        },

        supportDynamic: function(target){
            return target.channel.includes("web") && target.target_type==TARGET_CONSTS.DEFAULT_TARGET_TYPE;
        },

        supportManualTopics: function(target){
            return (target.channel.includes("web") || target.channel.includes("social"));
        },

        hasPhrases: function(target) {
            return !(_.isEmpty(target.results?.phrases));
        },

        getQueryPhrases: function(target) {
            let bl_phrases, phrases = "";
            if(!target.query.phrases){
                return phrases;
            }
            phrases = target.query.phrases.map(function(seed){
                    return seed
            }).toString();

            bl_phrases = target.query.phrases_bl.map(function(obj){
                    return obj.text
            }).toString();

            if(bl_phrases.length>0){
                phrases = phrases.length>0? phrases += " ," + bl_phrases : bl_phrases;
            }
            return phrases
        },



        //will prepare phrases, phrases_bl, kwd_ids from seed to query
        //this is relevat only when a new target is created or dynamic trends are edited
        //basicly each time a seed for a target is created/updated.
        //sample pf the OUTPUT:
        //kwd_ids: {jerusalem: 456630, rock: 1076494, tel aviv: 322373, haifa: 456650, david: 32258587}
        //phrases :["Jerusalem", "Rock", "Tel Aviv"]
        //phrases_bl [{excluded:[] included:["David"] required:["Haifa"] id:"fc8b6784-8cf8-4db5-844e-dfa58d4b37c2" text:"Haifa*" type:"booleanLogic"}]
        saveSeedsData: function(target) {

            let seeds = target.customSeeds? target.customSeeds : target.seeds,
                query = target.query,
                action = target.action;

            if((action != 'createNewTarget' && action != 'editDynamicTrends')  || _.isEmpty(seeds)){return target.query};

            delete target.action;
            delete target.customSeeds;

            let BLArr = _.isEmpty(query.phrases_bl)? [] : query.phrases_bl,
                phraseArr = [],
                tempPhraseArr = [],
                kwdIdsObj = {};

             //$scope.dynamicSeeds
             seeds.forEach(function(seed){
                 if(seed.type !== "booleanLogic"){
                     phraseArr.push(seed.text);
                     kwdIdsObj[seed.text.toLowerCase()] = seed.id;
                 }
                 tempPhraseArr.push(seed.text);
            });

             //booleanLogic
             let tempBLArr = []

             if(_.isEmpty(BLArr)){
                //NEW target - does not have phrases_bl, we need to take the data from the seeds outside the query.
                let s = _.isEmpty(seeds)? currentTarget.seeds : seeds;

                let transformSeedToBl = this.transformSeedToBl;
                s.filter(function (seed) {
                    return seed.type == "booleanLogic";
                }).forEach(function(seed){
                    tempPhraseArr.forEach(function(phrase){
                         if(seed.text.toLowerCase() === phrase.toLowerCase()){
                            tempBLArr.push(transformSeedToBl(seed));
                            ;
                         }
                     });
                 });

                //when creating a new target we do NOT need to send phrases, phrases_bl !!!
                if(action != 'createNewTarget'){
                    query.phrases = phraseArr;
                    query.phrases_bl = tempBLArr;
                }
                query.kwd_ids = kwdIdsObj;

             }else{
                 //edit - we have the phrases_bl
                 BLArr.forEach(function(seed){
                    tempPhraseArr.forEach(function(phrase){
                         if(seed.text.toLowerCase() === phrase.toLowerCase()){
                            tempBLArr.push(seed)
                         }
                     });
                 });

                query = {phrases_bl: tempBLArr, kwd_ids: kwdIdsObj, phrases: phraseArr};
            }
        },

        transformSeedToBl: function(BL){
            let obj = {id: BL.id, text: BL.text, type: BL.type };
            if(BL.excluded) {obj.excluded = BL.excluded};
            if(BL.included) {obj.included = BL.included};
            if(BL.required) {obj.required = BL.required};
            return obj;
        },

        convertAudience: function(target, context){
            //audience name
            let target_type = target.target_type||'';

            if(target_type.includes("audience") || target_type.includes('inventory_lists')){
                var current_channel = context.current.audience_app.current_channel.value,
                    audience_ids = context.current.audience_app[current_channel].audience_ids || context.current.audience_ids || {};
                for(var i in target.results){
                    var dataArray = target.results[i];
                    dataArray.forEach((cell) =>{
                        cell.audience_name = audience_ids[cell.audience_id] || "Untitled Audience";
                    });
                }
            }
        },

        isPrefetchTarget: function (scope, action) {
            return scope.currentTarget.target_type ==
                TARGET_CONSTS.INVENTORY_LISTS_TARGET_TYPE &&
                _.includes(['update-target-list', 'switch-target'], action);
        },

        targetToCurrentTarget: function (scope, url, action) {
            //when loading context, targets just have id.
            //here we load their "results": phrases,hastags,etc.
            //*** websites inventory will always load from server

            if(scope.currentTarget._id &&
                (!scope.currentTarget.results || this.isPrefetchTarget(scope, action))){

                $http.get(url).then((res) =>{
                    scope.currentTarget = $.extend(true, {}, res.data);
                    this.targetToCurrentTargetCB(scope);
                    TargetComplianceService.loadTarget(scope.currentTarget);
                }, (err) => {
                  console.log(err);
                  notificator.error({body: 'error loading target'});
                  scope.targetLoading = false;
                });
            } else {
              this.targetToCurrentTargetCB(scope);
            }
        },

        targetToCurrentTargetCB: function (scope) {
            //more target params for content-drivers

            scope.currentTarget.noResults = true;
            scope.currentTarget.dynamicResults = 0;

            var ids = {}, results = scope.currentTarget.results,
                dynamic = scope.currentTarget.dynamic &&
                    scope.currentTarget.activated_to &&
                    scope.currentTarget.activated_to.length;

            for (var i in results) {
                results[i].forEach(function (cell) {
                    cell.display = cell.term ? (cell.term.display || cell.term.text) : cell.seed;

                    if (cell.dynamic) {

                        if (dynamic) {
                            scope.currentTarget.dynamicResults++;
                        }

                    } else {
                        scope.currentTarget.noResults = false;
                        ids[cell.id + i] = true;
                    }
                });
            }

            if (scope.currentTarget.noResults) {
                ids = null;
            }

            scope.disabledIds = ids;

            scope.currentTargetLoaded = true;
            $timeout(()=>{
              scope.targetLoading = false;
              scope.$apply();

              scope.$broadcast("current-target-changed", scope.currentTarget);
            });

        }, //end of targetToCurrentTargetCB


        convertManualPhrase: function (phrase) {
            phrase.display = phrase.text;
            phrase.phrase = phrase.text;
            phrase.highlighted = !!phrase.manual;
            phrase.highlightMarkTooltip = 'Manually added';
        },

        convertManualPhrases: function (phrases) {
            phrases.forEach((phrase) => {
                if(phrase.manual){
                    this.convertManualPhrase(phrase);
                }
            });
        },

        typePlural: function(type, length){
            let title =  type; //type = phrases | hashtags | etc ...

            if(title.endsWith('s')){
                title = title.slice(0, -1);
            }

            if(length > 1){
              title+='s';
            };

            return title;
        },

        removeParams: {

            target: [
                'action',
                'full_name',
                'full_id',
                'channel_display',
                'activated_to_display',
                'activated_to_sorted',
                'date_added_formated',
                'date_updated_formated',
                'target_type_display',
                'noResults',
                'dynamicResults',
                'action',
                'customSeeds',
                'loaded'
            ],

            targetType: {
                [TARGET_CONSTS.DEFAULT_TARGET_TYPE]  : [],
                [TARGET_CONSTS.AUDIENCE_TARGET_TYPE] : [],
                [TARGET_CONSTS.INVENTORY_LISTS_TARGET_TYPE] : [
                    'advertiser_id',
                    'topics',
                    'limitTopics',
                    'topical',
                    'dynamic',
                    'seeds'
                ]
            },

            phrases: [
                'examples',
                'display-uniqueness-index',
                'title-uniqueness-index']
        },

        isSupportForecast: (target) =>{
            return target.channel == "web" &&
                   target.target_type == TARGET_CONSTS.DEFAULT_TARGET_TYPE;
        }
    }

}]);
