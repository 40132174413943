import { react2angular } from 'react2angular';
import SettingsTopBar from 'react/components/apps/settings/SettingsTopBar/SettingsTopBar';
import SettingsSecondaryBar from 'react/components/apps/settings/SettingsSecondaryBar/SettingsSecondaryBar';

var programs = require('../programs/programs.js'),
    first_party = require('../first_party/first_party.js'),
    users = require('../users/users.js'),
    phrases = require('../phrases/phrases.js'),
    organizations = require('../organizations/organizations.js'),
    domains = require('../domains/domains.js'),
    targets = require('../targets/targets-settings.js'),
    activatedAudienceSegments = require('../activated_audience_segments/activated_audience_segments.js');

var settingsModule = angular.module(__filename, [
    programs.name,
    first_party.name,
    users.name,
    organizations.name,
    domains.name,
    targets.name,
    activatedAudienceSegments.name
]);

settingsModule.component('settingsTopBar', react2angular(SettingsTopBar, []))
              .component('settingsSecondaryBar', react2angular(SettingsSecondaryBar, ['clickTab', 'permissions', 'selectedTab', 'visibleTabs', 'onExportClick']));

SettingsController.$inject = ["$scope", "$state", "$window", "abiPermissions"];
function SettingsController($scope, $state, $window, abiPermissions) {

    $scope.tabRoles = settingsModule.stateConfig.children.reduce(
        (tabs, tab) => {
            tabs[tabName(tab.name)] = tab.data.roles || [];
            return tabs;
    }, {});


    $scope.clickTab = (name) => {
        if($scope.setSelectedTabRoles(name)){
            $state.go('^.'+name);
        }
    };

    $scope.setSelectedTabRoles =(name) =>{
        $scope.selectedTabRoles = $scope.tabRoles[tabName(name)];
        if (name.indexOf('settings') !== -1) { // omit updating "selectedTab" on routes different from 'settings'
            $scope.selectedTab = tabName(name);
        }
        if(false == abiPermissions.hasRoles($scope, $scope.selectedTabRoles)){
            sessionStorage['login_message'] = 'Your account is not authorized for this page. <br/>Please contact your account manager today.<br/>';
            $window.location.replace('/login_message');
        } else {
            $scope.setVisibleTabs();
            $scope.setPermissions();
        }
        return true;
    };

    $scope.setVisibleTabs = () => {
        var tabsList = Object.keys($scope.tabRoles);
        var userType = $scope.$root.user.userType;
        $scope.visibleTabs = tabsList.reduce((visible, tab) => {
            if (!$scope.tabRoles[tab] || !$scope.tabRoles[tab].length || $scope.tabRoles[tab].indexOf(userType) !== -1) {
                visible.push(tab);
            }
            return visible;
        }, []);
    };

    $scope.setPermissions = () => {
        $scope.permissions = abiPermissions.getPermissions();
    };

    $scope.$on("$stateChangeStart", function (evnt, toState, toParams) {
        $scope.setSelectedTabRoles(toState.name);
    });

    $scope.setSelectedTabRoles($state.current.name);

    function tabName(name){
        return name.replace('settings.','');
    }

}

settingsModule.stateConfig = {
    name: 'settings',
    url: '/settings',
    template: require('./settings.html'),
    controller: SettingsController,
    display: 'Settings',
    family: 'settings',
    redirectTo: 'settings.programs',
    children: [
        programs.stateConfig,
        first_party.stateConfig,
        users.stateConfig,
        phrases.stateConfig,
        organizations.stateConfig,
        targets.stateConfig,
        domains.stateConfig,
        activatedAudienceSegments.stateConfig
    ],
    data: {
      permissions: []
    }
};

module.exports = settingsModule;
