'use strict';
import { uniq } from 'lodash';

function hasRoles(scope, roles){
    //roles can be undefined, [], or [...]
    var userType = scope.$root.user.userType;
    return !roles || 0 === roles.length ||
        roles.indexOf(userType) !== -1;
}

module.exports = angular.module(__filename, []).config(["authenticationConfig",
    function (authenticationConfig) {
        authenticationConfig.doAfterAuthentication.push(["user", "abiPermissions", function (user, abiPermissions) {
            abiPermissions.init(user);
        }]);
    }
]).service('abiPermissions', function () {
    var userPermissions = [];

    return {
        init: initPermissions,
        hasRoles: hasRoles,
        hasPermission: hasPermission,
        getPermissions: getPermissions,
        hasPermissionForState: hasPermissionForState,
        addGeosPermissions: addGeosPermissions,
        hasSomePermissions: hasSomePermissions,
        removePermission: removePermission
    };

    function initPermissions(user) {
        userPermissions = user.permissions.map(function (permission) {
            return permission.name;
        });
    }

    function removePermission(permission){
        var index = userPermissions.indexOf(permission);
        if (index !== -1) userPermissions.splice(index, 1);
    }

    function addGeosPermissions(geos) {
      userPermissions = uniq(userPermissions.concat(geos));
    }

    function getPermissions() {
      return angular.copy(userPermissions);
    }

    function hasPermission(permissions) {
        if (permissions === undefined) {
          return true;
        }
        const arr = _.castArray(permissions);
        const intersection = _.intersection(userPermissions, arr);
        return intersection.length === arr.length;
    }

    function hasSomePermissions(permissions) {
        if (permissions === undefined) {
          return true;
        }
        const arr = _.castArray(permissions);
        const intersection = _.intersection(userPermissions, arr);
        return !_.isEmpty(intersection);
    }

    function hasPermissionForState(state) {
        if (!state.permissions || state.permissions.length === 0) {
            return true;
        }

        return hasPermission(state.permissions);
    }
}).directive('permissions', ['abiPermissions', function (abiPermissions) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            var permissions = scope.$eval(attrs.permissions);
            if (!abiPermissions.hasPermission(permissions)) {
                element.remove();
            }
        }
    }
}]).directive('roles', function() {
    return {
        restrict: 'A',
        scope: {
            roles: '='
        },
        link: function (scope, element, attrs) {
            if(false === hasRoles(scope, scope.roles)) {
                element.remove();
            }
        }
    }
});
