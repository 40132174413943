import React from 'react';
import { compact } from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import BaseTag from '../BaseTag/BaseTag';
import './InputTag.scss';

const InputTag = ({ label, value, onClickLabel, onRemove, onEdit, onAdd, tooltipOptions, showLoader }) => {
  const addBtn = {
    iconId: '+',
    position: 1,
    onClick: onAdd,
    tooltip: 'Add to tracking',
    className: classNames('button', 'add-button', 'opacity'),
  };

  const removeBtn = {
    iconId: 'x',
    position: 2,
    onClick: (label, event) => onRemove(label, event),
    tooltip: 'Remove interest',
    className: classNames('button', 'remove-button'),
  };
  const editBtn = {
    iconId: 'edit',
    position: -1,
    onClick: onEdit,
    tooltip: 'Refine interest',
    className: classNames('button', 'right-separator'),
  };

  const buttons = compact([onAdd && addBtn, onRemove && removeBtn, onEdit && editBtn]);

  return (
    <BaseTag
      className={classNames('input-tag-component')}
      label={label}
      value={value}
      buttons={buttons}
      onClick={onClickLabel}
      tooltipOptions={tooltipOptions}
      showLoader={showLoader}
    />
  );
};

InputTag.propTypes = {
  label: PropTypes.any.isRequired,
  labelAdditionalMessage: PropTypes.string,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  onClickLabel: PropTypes.func,
  value: PropTypes.any,
  tooltipOptions: PropTypes.object,
  showLoader: PropTypes.bool,
};

export default InputTag;
