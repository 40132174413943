"use strict";
import config from 'infra/config';

(function() {
    module.exports = angular.module(__filename, [])
        .service('keywordSuggester', ["$http", "$q", "topicsTree", function ($http, $q, topicsTree) {
            var MINIMUM_WORD_LENGTH = 1;
            var KS_TIMEOUT = 10 * 1000;
            var SERVER_URL = config.KEYWORDS_SUGGESTIONS_API + '/api/0.2/suggest';
            var TEXT_PARAMETER = "txt";
            /*******
             * API
             ******/
            this.getSuggestions = getSuggestions;

            /**
             * @param keyword
             * @param topics
             * @returns {promise}
             */
            function getSuggestions(keyword, topics) {
                return $q(function (resolve, reject) {
                    if (keyword.length < MINIMUM_WORD_LENGTH) {
                        resolve([]);
                    }
                    $http({
                        method: "GET",
                         url: SERVER_URL + "?" + TEXT_PARAMETER + "=" + keyword,
                    }, {timeout: KS_TIMEOUT}).then(function (res) {
                        if (!Array.isArray(res.data)) {
                            resolve([]);
                        } else {
                            resolve(res.data);
                        }
                    }, function(err){
                        resolve([]);   
                    });
                });
            }
        }]
    );
}());
