/*
updates nonCompliantPhrases
so UI can decide weather to show the
"non compliant alert" when you add phrases.

to use
======
1. import * as TargetComplianceService from 'react/services/TargetComplianceService';
2. use the functions to update nonCompliantPhrases

*/

import ComplianceService from './ComplianceService';

export let nonCompliantPhrases = {};

export function hasNonCompliantPhrases() {
  return !!Object.keys(nonCompliantPhrases).length;
}

export async function loadTarget(target) {
  const phrases = getComplianceQueryPhrases(target);
  const response = await ComplianceService.checkPhrases({
    phrases,
    feature: '',
    checkGDPR: true,
    checkNAI: true,
  });

  nonCompliantPhrases = {};
  if (!response.passed) {
    nonCompliantPhrases = getNonCompliantPhrases(response.results);
  }
}

export function unloadTarget() {
  nonCompliantPhrases = {};
}

export function removeAllPhrases() {
  nonCompliantPhrases = {};
}

export function removePhrase({ phraseObj, tab, results }) {
  const phrase = entityText(phraseObj, tab);

  let numOfOcurrences = 0;
  for (const [tab, array] of Object.entries(results)) {
    const phrases = array.map((o) => entityText(o, tab));

    if (phrases.includes(phrase)) {
      numOfOcurrences++;
    }
  }
  if (nonCompliantPhrases[phrase] && numOfOcurrences === 1) {
    delete nonCompliantPhrases[phrase];
  }
}

export function addNonCompliantPhrases(newNonCompliantPhrases) {
  nonCompliantPhrases = { ...nonCompliantPhrases, ...newNonCompliantPhrases };
}

export function saveTarget(newNonCompliantPhrases) {
  nonCompliantPhrases = { ...newNonCompliantPhrases };
}

export function getNonCompliantPhrases(complianceResults) {
  const res = {};
  for (const [phrase, value] of Object.entries(complianceResults)) {
    if (isPhraseObjCompliance(value) === false) {
      res[phrase] = true;
    }
  }
  return res;
}

export function getComplianceQueryPhrases(target, phrasesData) {
  let compliancePhrases = [];
  for (const tab in target.results) {
    if (isTabRequireCompliance(tab)) {
      const phrases = target.results[tab].map((e) => entityText(e, tab));

      if (phrasesData) {
        phrases.forEach((phrase) => {
          if (phrasesData[phrase]) {
            if (!phrasesData[phrase].tabs.includes(tab)) {
              phrasesData[phrase].tabs.push(tab);
            }
          } else {
            phrasesData[phrase] = { tabs: [tab] };
          }
        });
      }
      compliancePhrases = compliancePhrases.concat(phrases);
    }
  }

  return compliancePhrases;
}

export function isTabRequireCompliance(tab) {
  return ['websites', 'emojis'].includes(tab) === false;
}

export function isTargetRequireCompliance(target, wasAlreadyFlaggedForComplianceCheck) {
  if (target.incOrExc === 'exclude') {
    return false;
  }

  /* eslint-disable-next-line camelcase */
  return !!(
    isActivated(target.activated_to) &&
    (wasAlreadyFlaggedForComplianceCheck === false || Object.keys(nonCompliantPhrases).length === 0)
  );
}

/* eslint-disable-next-line camelcase */
export function isActivated(activated_to) {
  /* eslint-disable-next-line camelcase */
  return !!(Array.isArray(activated_to) && activated_to.length);
}

export function isPhraseObjCompliance(phraseObj) {
  //target phrases check only for gdpr for now
  return phraseObj && phraseObj.gdpr === 0;
}

export function entityText(entity, tab) {
  switch (tab) {
    case 'phrases':
    case 'websites':
      return entity.phrase;
    case 'hashtags':
      return entity.hashtag;
    case 'influencers':
      return entity.handle;
    case 'audience':
      return entity.handle;
    case 'emojis':
      return entity.eid;
    case 'interests':
      return entity.id;
    case 'pages':
      return entity.name;
    default:
      throw new Error('entityText failed for tab ' + tab);
  }
}
