import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../Icon/Icon';
import Tooltip from '../Tooltip/Tooltip';
import './SingleSelectToggle.scss';

const SingleSelectToggle = ({
  values,
  selectedValue,
  onToggle,
  disabled,
  isLightTheme,
  isModalTheme,
  isAudienceInline,
}) => {
  values.forEach((value) => {
    if (undefined == value.label && undefined == value.icon) {
      throw new Error("Single Select Toggle 'values' needs 'label' or 'icon' for each cell");
    }
  });

  const theme = isAudienceInline ? 'audience-inline' : isLightTheme ? 'light' : isModalTheme ? 'modal-window' : 'dark';

  return (
    <div className={classNames('single-select-toggle-component', theme)}>
      {values.map((value, i) => (
        <Tooltip content={value.tooltip} key={'tooltip' + i}>
          <span>
            <span
              role="button"
              className={classNames('toggle-button', {
                selected: selectedValue == value.value,
                disabled: disabled || value.disabled,
              })}
              onClick={() => {
                if (!disabled && !value.disabled) {
                  onToggle(value.value);
                }
              }}
              key={'button' + i}
            >
              {value && value.icon && <Icon iconId={value.icon || ''} className="icon" />}
              {value && value.icon && value.label && <span className="label-icon-margin" />}
              {value && value.label && <span className="with-text">{value.label}</span>}
            </span>
          </span>
        </Tooltip>
      ))}
    </div>
  );
};

SingleSelectToggle.propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
    })
  ).isRequired,
  selectedValue: PropTypes.any,
  onToggle: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isLightTheme: PropTypes.bool,
  isModalTheme: PropTypes.bool,
  isAudienceInline: PropTypes.bool,
};

SingleSelectToggle.defaultProps = {
  disabled: false,
  isLightTheme: false,
  isModalTheme: false,
  isAudienceInline: false,
};

export default SingleSelectToggle;
