//target popup window from discovery/audience
import config from 'infra/config';
import c from 'infra/utils/common';

require("pages/targets/targets-common");

const queries = {};

queries.targetlist = {
  title: 'targets',
  dataArray: [],
  columns: [

          {id: 'name', title: 'Target', ellipsis: true, tooltip:'Target' , alwaysShown:true,  template: 'partials/targets-select.partial.html'},
          {id: '_id', title: 'ID', search: true, ellipsis: true, tooltip:'ID', flex:'0-3', alwaysShown:true, displayValue: "full_id"},
          {id: 'target_type_display', title: 'Type', ellipsis: true, tooltip:'Type', flex:'0-7', search: true},
          {id: '_user_name', title: 'Created by', search: true, tooltip:'Created by', ellipsis: true, flex:'0-5'},
          {id: 'date_added', title: 'Date Created', search: true, ellipsis: true, tooltip:'Date Created',  displayValue: "date_added_formated"},
          {id: 'updated_by', title: 'Updated by', search: true, ellipsis: true, tooltip:'Updated by', flex:'0-5'},
          {id: 'date_updated', title: 'Date Updated', search: true, ellipsis: true, tooltip:'Date Updated', displayValue: "date_updated_formated" },
          {id: 'activated_to_sorted', title: 'Activated To', displayValue: 'activated_to_display', search: true, ellipsis: true, tooltip:'Activated To', flex:'0-7'}
          ],

  crud: true,
  fixedSize: false,
  largeHeader: true,
  order: 'name',
  reverse: false,
  excel: false
};

targetsController.$inject = ['$scope', '$http', '$state', 'context', '$filter', 'parent', 'TargetsCommon', 'CHANNEL', 'TARGET_CONSTS', 'ModalService', 'notificator', '$timeout', 'close', 'confirmAction', 'mgmtServiceFactory'];
function targetsController($scope, $http, $state, context, $filter, parent, TargetsCommon , CHANNEL, TARGET_CONSTS, ModalService, notificator, $timeout, close , confirmAction, mgmtServiceFactory) {

    $scope.queries = queries;
    $scope.editTargetOpened = false;

    $scope.currentTargetName = () => parent.currentTarget.name ? 'Current target: ' + parent.currentTarget.name :
                                                                 'No target selected';

    $scope.isCurrentTarget = (id) => id == parent.currentTarget._id;

    $scope.init = function () {
      $scope.targetLoading = true;
      TargetsCommon.initTargets(parent.targets);
      setTimeout(function(){
        $scope.queries['targetlist'].show(parent.targets);
        $scope.targetLoading = false;
      });
    };

    $scope.close = close;

    //events;
    var updateTargetsCBWatch = $scope.$root.$on('updateTargetsCB', function (event, obj) {
        if (obj.action === 'create-target') {
          $scope.close();
        } else if(obj.action === 'remove-target' ||
          obj.action === 'update-target'){
          TargetsCommon.showUpdatedList($scope, obj.targets);
        }
    });

    $scope.$on('$destroy', updateTargetsCBWatch); //clears watch

    $scope.switchTarget = function(target){
      $scope.$root.$broadcast('updateTargets', {
          action: 'switch-target',
          target: target
      });
      if(target.target_type === TARGET_CONSTS.AUDIENCE_TARGET_TYPE){
        $scope.edit(target);
      }
      $scope.close();
    };

    $scope.edit = (target) => {
      TargetsCommon.edit({target, tab: parent.tab, $scope});
    }

    $scope.delete = (target) => $scope.deleteTarget = target;

    $scope.deleteConfirm = () => TargetsCommon.deleteConfirm($scope);

    $scope.queries.targetlist.clickRowHandler = $scope.switchTarget;
    $scope.init();
}

module.exports = targetsController;
