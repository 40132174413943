import { setDisplayErrorBannerIfNeededService } from '../../react/middleware/AngularExportedMiddleware';

module.exports = angular.module(__filename, [])
    .service('userErrorNotifications', ['errorMgmt', '$q', function (errorMgmt, $q) {
        function shouldNotify(rejection) {
            if (rejection.config.url.match('snapshot|suggest|session|disable_notification')) return false;
            if (rejection.config.timeout && rejection.config.timeout.$$state && rejection.config.timeout.$$state.value === 'AMOBEE_CANCEL_OK') return false;
            return true;
        }

        function getCustomSuccessBannerMessage(error) {
            if (error.config.url.match('demographics|ui-related-keywords') && error.config.data.match('start-time') &&
                ![205, 400, 401, 422, 500, 502, 503].includes(error.status)) {
                return 'A large query has been detected. Your query is being processed, please try again in 5 minutes.';
            }
            return null;
        }

        function displayErrorBannerIfNeeded(error) {
            const customSuccessMessage = getCustomSuccessBannerMessage(error);
            if (customSuccessMessage) {
                errorMgmt.customSuccessMessage(customSuccessMessage);
                return;
            }
            if (shouldNotify(error)) {
                errorMgmt.httpError(error);
            }
        }       

        setDisplayErrorBannerIfNeededService(displayErrorBannerIfNeeded);

        return {
            'responseError': function (rejection) {
                displayErrorBannerIfNeeded(rejection);
                return $q.reject(rejection)
            }
        }
    }])
    .config(['$httpProvider', function ($httpProvider) {
        $httpProvider.interceptors.push('userErrorNotifications');
    }]
);
