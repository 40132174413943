import React from 'react';
import PropTypes from 'prop-types';
import './WidgetErrorView.scss';
import Icon from '../Icon/Icon';

const ErrorMessage = () => (
  <div className="error-message">
    <Icon iconId="exclamation-in-circle" className="exclamation-icon" width="10px" height="10px" />
    <div className="error-message-first-row">An error has occurred,&nbsp;</div>
    <div className="error-message-second-row">please try again later.</div>
  </div>
);

const WidgetErrorView = ({ emptyStateRender }) => (
  <div className="widget-error-view-component">
    <ErrorMessage></ErrorMessage>
    {emptyStateRender()}
  </div>
);

WidgetErrorView.propTypes = {
  emptyStateRender: PropTypes.func.isRequired,
};

export default WidgetErrorView;
