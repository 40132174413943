"use strict";

module.exports = angular.module(__filename, [
    require('data/filters-partition.srv').name,
    require('./filter-mold-service').name
]).factory("SgTelcoAudienceMold", ['filtersPartition', 'filterMoldService', function (filtersPartition, filterMoldService) {
    return function SgTelcoAudienceMold() {
        this._value = [];
        this.default = [];

        const collection = filtersPartition.genders.concat(filtersPartition.age, filtersPartition.sgEthnicity);

        filterMoldService.getDefaultFilterMoldFunction.call(this, collection);
    }
}]);