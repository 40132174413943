var d3 = require("d3"),
    c = require("infra/utils/common");

module.exports = require("angular").module(__filename, [])
  .directive("amSeedHeatmapChart", [function() {
    return {
      restrict: 'E',
      scope: {
        label: '<',
        data: '<',
        dayTotals: '<',
        hourTotals: '<',
        colors: '<',
        dayTotalsColors: '<',
        hourTotalsColors: '<',
        chartHeight: '<',
        chartWidth: '<',
        topLabels: '<',
        sideLabels: '<'
      },
      template: require('./seed-heatmap-chart.html'),
      link: function ($scope, $element, $attrs, $ctrl) {
        var MARGIN = { height: 35, width: 175 },
            GRID_SPACE = 1,
            AVG_SPACE = 12,
            PANEL_HEIGHT = 60,
            ADDED_MARGIN = 50,
            MIN_CARD_SIZE = 25;

        var days = c.getWeekDays(),
            hours = c.getHeatmapHours(),
            hourNumbers = c.getHeatmapHourNumbers(),
            denoms = Array(12).fill('am').concat(Array(12).fill('pm'));

        $scope.$watch('[chartWidth,chartHeight]', function () {
          $scope.update();
        });

        $scope.update = function () {
          if ($scope.chartWidth === undefined) {
            return;
          }
          var isSmallResolution = $scope.chartWidth < 1100 || $scope.chartHeight < 480;
          var cardSizeByWidth = Math.floor(($scope.chartWidth - MARGIN.width - (24 * GRID_SPACE) - AVG_SPACE) / 25);
          var cardSizeByHeight = Math.floor(($scope.chartHeight - MARGIN.height - (7 * GRID_SPACE) - (2 * AVG_SPACE) - PANEL_HEIGHT - ADDED_MARGIN) / 8);
          $scope.cardSize = Math.max(MIN_CARD_SIZE, Math.min(cardSizeByWidth, cardSizeByHeight));
          $scope.heatmapData = _.sortBy($scope.data, (rec) => (rec.vals[0][0] * 24 + rec.vals[0][1]));
          $scope.heatmapColors = _.map(_.sortBy($scope.colors, function(rec){return rec[0][0] * 24 + rec[0][1]}), _.last);
          $scope.heatmapDayTotals = _.sortBy($scope.dayTotals, (rec) => _.first(rec.vals));
          $scope.heatmapHourTotals = _.sortBy($scope.hourTotals, (rec) => _.first(rec.vals));
          $scope.heatmapDataColors = _.map(_.sortBy($scope.colors, function(rec){return rec[0][0] * 24 + rec[0][1]}), _.last);
          $scope.heatmapDayTotalsColors = _.map(_.sortBy($scope.dayTotalsColors, _.first), _.last);
          $scope.heatmapHourTotalsColors = _.map(_.sortBy($scope.hourTotalsColors, _.first), _.last);
          $scope.dayLabels = days;
          $scope.hourLabels = isSmallResolution ? denoms : hours;
          $scope.addedHourLabels = isSmallResolution ? hourNumbers : null;
          $scope.posTop = isSmallResolution ? 50 : 35;
          $scope.posLeft = 145;
          if($scope.cardSize < cardSizeByWidth){
            $scope.posLeft += (25 / 2) * (cardSizeByWidth - $scope.cardSize - 1); //Bringing heatmap to center of widget
          }
          $scope.titleMargin = $scope.posLeft - 107;
        };

        $scope.update();
      }
    }
  }]);
