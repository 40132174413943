import html2canvas from 'html2canvas';

export async function captureScreenshot() {
  return new Promise((resolve) => {
    html2canvas(document.body, {
      onrendered: (canvas) => {
        resolve(canvas.toDataURL('image/jpeg').substr('data:image/jpeg;base64,'.length));
      },
    });
  });
}

export function clampLines(el, lines, text, buttonText) {
  if (!el) {
    return text;
  }

  const { lineHeight } = window.getComputedStyle(el);
  const maxHeight = Math.ceil(parseFloat(lineHeight)) * lines;
  const words = text.split(' ');
  let start = 0;
  let middle = 0;
  let end = words.length;
  while (start <= end) {
    middle = Math.floor((start + end) / 2);
    if (middle === words.length) {
      el.innerText = text;
      return text;
    }

    const tempText = words.slice(0, middle).join(' ');
    el.innerText = `${tempText} ${buttonText}`;
    if (el.offsetHeight <= maxHeight) {
      start = middle + 1;
    } else {
      end = middle - 1;
    }
  }

  const t = words.slice(0, middle - 1).join(' ');
  el.innerText = t;
  return t;
}
