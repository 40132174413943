import React from 'react';
import './ClickableIcon.scss';
import Icon from '../Icon/Icon';
import Tooltip from '../Tooltip/Tooltip';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { noop } from 'lodash';

const ClickableIcon = ({ iconId, isDisabled, tooltip, tooltipOptions, onClick, className, width, height }) => (
  <Tooltip content={isDisabled ? null : tooltip} options={tooltipOptions}>
    <div
      className={classNames('clickable-icon-component', className, { disabled: isDisabled, noop: onClick === noop })}
      onClick={() => !isDisabled && onClick()}
    >
      <Icon iconId={iconId} width={width} height={height}></Icon>
    </div>
  </Tooltip>
);

ClickableIcon.propTypes = {
  iconId: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  tooltip: PropTypes.string,
  tooltipOptions: PropTypes.object,
  onClick: PropTypes.func,
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

ClickableIcon.defaultProps = {
  isDisabled: false,
  width: '16px',
  height: '16px',
  onClick: noop,
};

export default ClickableIcon;
