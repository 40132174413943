require('qtip2');

const HIDE_SETTINGS = {delay: 100, event: 'mouseleave click'};

const INFO_TT_SETTINGS = {show: {solo: true}, hide: {delay: 200}, position: {my: 'bottom center', at: 'top center'}};
// no solo - when tooltip displayed, don't hide all other tooltips.
// This is useful in bubbles screen, as the bubbles example popup is a tooltip, and we don't want to close it when other tooltips are shown
// const INFO_NO_SOLO_TT_SETTINGS = {hide: {delay: 100, event: 'mouseleave click'}, position: {my: 'bottom center', at: 'top center'}};
const INFO_NO_SOLO_TT_SETTINGS = {position: {my: 'bottom center', at: 'top center'}};
// adjust screen - change position of tooltip if part of it goes out of the screen
const INFO_NO_SOLO_ADJUST_SCREEN_TT_SETTINGS = {
    hide: HIDE_SETTINGS,
    position: {my: 'bottom center', at: 'top center', viewport: $(window), adjust: {screen: true, method: 'shift'}}
};
// tooltip types defined by basic settings sets
const TT_TYPES = {'info': INFO_TT_SETTINGS, 'info no solo': INFO_NO_SOLO_TT_SETTINGS, 'info no solo adjust screen': INFO_NO_SOLO_ADJUST_SCREEN_TT_SETTINGS};

function add_tooltip(element, tt_type, settings_override, remove_qtip) {
    if (!element.qtip) element = angular.element(element);
    const default_settings = tt_type ? TT_TYPES[tt_type] : INFO_TT_SETTINGS;
    const settings = $.extend(true, {hide: HIDE_SETTINGS}, default_settings, settings_override);
    const qtip = element.qtip(settings);
    if(remove_qtip) qtip.removeData("qtip");
    return qtip;
}

module.exports = add_tooltip;
