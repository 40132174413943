var angularUtils = require('common/angularUtils');

module.exports = angular.module(__filename, [
]).component('amDropdownSelect', {
  template: require('./am-dropdown-select.html'),
  require: {
    ngModel: 'ngModel'
  },
  transclude: true,
  /* options format:
   * [ { id: number
   *     label: string
   *     selected: boolean
   *     indeterminate: boolean
   *     } ]
   */
  /* texts format:
   * { noneSelected: string (default: "None Selected"),
   *   allSelected: string (default: "All Selected"),
   *   selectedFn: function(selected: list, options: list)
   *                (default: when multi: "${selected.length} Selected", e.g. "7 Selected"
   *                          when single: selected.label, e.g. "Australia"))
   *  }
   */
  bindings: {
    options: '<',
    multi: '<',
    fullModel: '=?',
    texts: '=?',
    displayAsLabel: '<?',
    alwaysSelectOne: '<?'
    // required and ng-changed are handled by ngModel, no need to handle internally
  },
  controller: ['$scope', function($scope) {
    var self = this;

    // if gulp marks these as errors, it's a bug on gulp's side, just comment, get the real line number and uncomment.
    const NONE_SELECTED = Symbol.for('None Selected');
    const ALL_SELECTED = Symbol.for('All Selected');
    const SOME_SELECTED = Symbol.for('Some Selected');
    const ALL_SELECTED_OR_INDETERMINATED = Symbol.for('All Selected or Indeterminated');
    const DISPLAY_TEXTS = { 'noneSelected' : 'None Selected',
                            'allSelected'  : 'All Selected' };

    self.$onInit = function() {
      self.opened = false;
      self.selectAllChecked = false;
      self.selectAllIndeterminate = false;
      self.setDisplayTexts();
      self.summaryText = self.displayTexts["noneSelected"];
      self.viewOptions = copyOptions(self.options);
      if (self.fullModel == undefined) self.fullModel = {};
      self.ngModel.$render = function() {
        var newSet = _.castArray(self.ngModel.$viewValue);
        _.each(self.viewOptions, function(option) {
          self.toggleAndUpdate(option, _.includes(newSet, option.id), false);
        });
      };
      self.ngModel.$isEmpty = function(value) {
        if (self.multi) {
          if (value === undefined) {
            return false;
          }
          if (!self.allMarkedStatus) {
            self.allMarkedStatus = self.allMarked();
          }
          return (self.allMarkedStatus.status === NONE_SELECTED);
        } else {
          return (value === null);
        }
      }
    };

    self.setDisplayTexts = function() {
      var singleSelectedFn = (selected, options) => selected.label;
      var multiSelectedFn = (selected, options) => `${selected.length} Selected`;
      var selectedFunction = { 'selectedFn' : self.multi ? multiSelectedFn : singleSelectedFn };
      self.displayTexts = _.defaults({}, self.texts, selectedFunction, DISPLAY_TEXTS);
    };

    self.$onChanges = function(changesObj) {
      if (changesObj.options && !changesObj.options.isFirstChange()) {
        self.viewOptions = copyOptions(self.options);
        if (!optionsHasSelections()) {
          self.ngModel.$render(); // make sure that ngModel values are also in viewOptions (but use viewOptions if it defines them).
        }
        if (self.ngModel.$pristine) {
          angularUtils.ngModelSkipDirty(self.ngModel, self.updateSelectedCounts)();
        } else {
          self.updateSelectedCounts();
        }
      }
    };

    var copyOptions = function(options) {
      return angular.copy(options);
    };

    var optionsHasSelections = function() {
      return _.some(self.viewOptions, _.overSome(['selected', 'indeterminate']));
    };

    self.ngModelTypeCast = function(model) {
      if (!self.multi) {
        if (model.length == 0) return null;
        return model[0];
      }
      return model;
    };

    self.allMarked = function() {
      if (!_.isObject(self.viewOptions)) {
        return NONE_SELECTED;
      }
      var displayAmount = self.viewOptions.length;
      var markedSelected = _(self.viewOptions).reduce(function(prev, curr) { return prev + !!curr.selected - !!curr.indeterminate; }, 0);
      var markedIndeterminate = _(self.viewOptions).reduce(function(prev, curr) { return prev + !!curr.indeterminate; }, 0);

      var result = {size: displayAmount, selected: markedSelected, indeterminate: markedIndeterminate};

      if (markedSelected == 0 && markedIndeterminate == 0) {
        result.status = NONE_SELECTED;
      } else if (markedSelected == displayAmount) {
        result.status = ALL_SELECTED;
      } else if (markedSelected + markedIndeterminate == displayAmount) {
        result.status = ALL_SELECTED_OR_INDETERMINATED;
      } else {
        result.status = SOME_SELECTED;
      }
      return result;
    };

    self.toggleSelectAll = function() {
      var selected = self.selectAllChecked = !self.selectAllChecked;
      var indeterminate = self.selectAllIndeterminate = false;
      _.each(self.viewOptions, function(item) {
          item.selected = selected;
          item.indeterminate = indeterminate;
      });
      self.updateSelectedCounts();
    };

    self.updateSelectedCounts = function(updateNgModel = true) {
      var allMarked = self.allMarkedStatus = self.allMarked();
      self.selectAllChecked = (allMarked.status === ALL_SELECTED || allMarked.status === SOME_SELECTED || allMarked.status === ALL_SELECTED_OR_INDETERMINATED);
      self.selectAllIndeterminate = (allMarked.status === SOME_SELECTED || allMarked.status === ALL_SELECTED_OR_INDETERMINATED);
      self.fullModel = _.reduce( self.viewOptions, function(h, option) {
        var key;
        if (option.indeterminate) {
          key = "indeterminate";
        } else if (option.selected) {
          key = "selected";
        } else {
          key = "unselected";
        }
        h[key] = _.concat(h[key] || [], option.id);
        return h} , {});
      self.fullModel.status = allMarked.status;
      self.summaryText = self.getSummaryText();
      if (updateNgModel) self.updateNgModel();
    };

    self.updateNgModel = function() {
      self.ngModel.$setViewValue(self.ngModelTypeCast(_(self.viewOptions).filter("selected").reject("indeterminate").map("id").value()));
      self.ngModel.$validate();
    };

    self.getSummaryText = function() {
      if (self.multi) {
        switch (self.allMarkedStatus.status) {
          case NONE_SELECTED:
            return self.displayTexts["noneSelected"];
          case ALL_SELECTED:
          case ALL_SELECTED_OR_INDETERMINATED:
            return self.displayTexts["allSelected"];
          default:
            if (self.allMarkedStatus.selected == 0) return self.displayTexts["noneSelected"];
            var selected = _.filter(self.viewOptions, (o) => !!o.selected || !!o.indeterminate);
            return self.displayTexts['selectedFn'](angular.copy(selected), angular.copy(self.viewOptions));
        }
      } else {
        var selected = _.find(self.viewOptions, (o) => !!o.selected && !o.indeterminate);
        var selectedCount = _.filter(self.viewOptions, {selected: true}).length;
        if (selected && (selectedCount == 1)) {
          return self.displayTexts['selectedFn'](angular.copy(selected), angular.copy(self.viewOptions));
        } else {
          return self.displayTexts["noneSelected"];
        }
      }
    };

    self.toggleOpen = function() {
      self.opened = !self.opened;
    };

    self.close = function() {
      self.opened = false;
    };

    self.toggleAndUpdate = function(item, value, updateNgModel = true, fromClick = false) {
      if (item.selected && self.alwaysSelectOne && !self.multi && fromClick) return;
      if (value === undefined) {
        item.selected = !item.selected;
      } else {
        item.selected = value;
      }
      self.toggle(item, updateNgModel);
    };

    self.toggle = function(item, updateNgModel = true) {
      if ((item.selected || item.indeterminate) && !self.multi) {
        _.each(self.viewOptions, function(other) {
          other.selected = (item.id === other.id);
        });
        self.close();
      }
      item.indeterminate = false;
      self.updateSelectedCounts(updateNgModel);
    };

  }]
});
